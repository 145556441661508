import React from "react";
import "../../components/landing/styles.css";
import { Form, Button, ButtonToolbar, FlexboxGrid } from "rsuite";
import { HttpService } from "../../services/http.service";
import {  ATTENDANCE_SESSIONS_API_URLS } from "../../services/API";
import CustomNotification from "../../components/CustomNotification";
import { Color } from "../../utils/Colors";

const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

export default function AddSession({ onSubmitSuccess ,selectedDate,setCount}) {
  // console.log(setCount)
  // const [teams, setTeams] = React.useState();
  // const [team,setTeam]= React.useState()
  // const [date, setDate] = React.useState();
  const [errorCount, setErrorCount] = React.useState(0);
  const [startTime, setStartTime] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const formRef = React.useRef();
  const [formValue, setFormValue] = React.useState({
    name: "",
    description: "",
  });
  const inputDate = new Date(selectedDate);

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');
  
  const formattedDate = `${year}-${month}-${day}`;
  const handleSubmit = async () => {
   

    try {
      const formData = {
        ...formValue,
        date: formattedDate,
        start_time: startTime,
        end_time: endTime,
      };
      const response = await HttpService.post(
        ATTENDANCE_SESSIONS_API_URLS,
        {},
        formData,false
      );

      setResponse(response);

      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setOpenModal(true);
        setError("Form Submitted");
        onSubmitSuccess();
      }
    }  catch (error) {
      console.warn(error)
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        <Form ref={formRef} onChange={setFormValue} formValue={formValue}>
          <TextField name="name" label="Name" />
          <TextField name="description" label="Description" />

              <label htmlFor="timePicker" style={{ marginRight: "10px" }}>
            Select Start time:
          </label>
          <input
            type="time"
            id="timePicker"
            name="timePicker"
            onChange={(e) => setStartTime(e.target.value)}
            style={{
              padding: "5px",
              borderRadius: "3px",
              border: "1px solid #ccc",
            }}
          />

          <p style={{ marginTop: "10px" }}>
            Selected Start Time: <span>{startTime}</span>
          </p>
          <br />
          <br />

          <label htmlFor="timePicker" style={{ marginRight: "10px" }}>
            Select End time:
          </label>
          <input
            type="time"
            id="timePicker"
            name="timePicker"
            onChange={(e) => setEndTime(e.target.value)}
            style={{
              padding: "5px",
              borderRadius: "3px",
              border: "1px solid #ccc",
            }}
          />

          <p style={{ marginTop: "10px" }}>
            Selected End Time: <span>{endTime}</span>
          </p>
          <br />
          <br />

          <Button appearance="primary" onClick={handleSubmit} style={{background:Color.Color_C,color:Color.Color_A,width:300}}>
              Submit
            </Button>
          <CustomNotification
           errorCount={errorCount}
            open={openModal}
            setOpen={setOpenModal}
            message={
              error ? error : (res && res?.data && res?.data?.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
