import React, { useEffect } from "react";
import { Button } from "rsuite";
import { HttpService } from "../../services/http.service";
import UserCustomDrawer from "../../components/CustomDrawer";
// import CustomSideNavbar from "../../components/CustomSideNav";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CustomNotification from "../../components/CustomNotification";
// import DashBoardTopNav from "../../components/DashBoardTopNav";
// import { GetViewAttendanceRecordData } from "../../components/custmomhooks/CustomHooks";
import { Container, Typography } from "@mui/material";
// import Sidebar from "../../components/CustomSideNav";
import {  ATTENDANCE_RECORDS_API_URLS, ATTENDANCE_SESSIONS_API_URLS, USER_API_URL } from "../../services/API";
import { Color } from "../../utils/Colors";
export default function RecordAttendance({session}) {
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [recordAttendance, setAttendanceRecord] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1); 
  const [count,setCount]=React.useState(0)
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${ATTENDANCE_RECORDS_API_URLS}?page=${currentPage}`,{}, false
        );
        // console.log(response.data);
        const modifiedResult = await Promise.allSettled(
          response.data.results.map(async (result) => {
            let session_id = ""
            if (session === result.session) {
              session_id = session
            }
            try {
              // Fetch athlete data for each result
              const sessionResponse = await HttpService.get(
                ATTENDANCE_SESSIONS_API_URLS + session_id + "/",{}, false
              );
              const session_name = sessionResponse.data.name;
              const recordResponse = await HttpService.get(
                USER_API_URL + result.recorded_by + "/",{}, true
              );
              const record_name = recordResponse.data.first_name + " "+ recordResponse.data.last_name;
              const userResponse = await HttpService.get(
                USER_API_URL + result.user + "/",{}, true
              );
              const user_name = userResponse.data.first_name + " "+ userResponse.data.last_name;
              // console.log(sessionResponse.data.name);
              return {
                id: result.id,
                session_name: session_name,
                record: record_name,
                user_name:user_name,

                ...result,
              };
            } catch (error) {
              // Handle individual request error
              console.error(
                `Error fetching data for result ${result.id}:`,
                error
              );
              return null; // or handle the error in an appropriate way
            }
          })
        );

        // Extracting successful results and maintaining the order
        const filteredResults = modifiedResult
          .filter(
            (result) => result.status === "fulfilled" && result.value !== null
          )
          .map((result) => result.value);

        setAttendanceRecord(filteredResults);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10));
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [currentPage,session,count]);

  // console.log(error)
  const handleOpen = (key) => {
    setOpen(true);
    setPlacement(key);
  };
  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1); // Page is 0-based, API expects 1-based
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const status = params.row.status;
  
        if (status === "present") {
         
          
          return <Typography variant="body" fontWeight={"bold"} color="green" >Present</Typography>
        } else {
          return <Typography variant="body" fontWeight={"bold"} color="red">Absent</Typography>;
        }
      },
    },
    {
      field: "user_name",
      headerName: "User",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "record",
      headerName: "Recorded by",
      type: "number",
      width: 260,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "session_name",
      headerName: "Session",
      sortable: true,
      width: 110,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <>
   
      <Container maxWidth="lg">
        <Button
          style={{
            margin:"1rem",
          backgroundColor:Color.Color_C
          }}
          appearance="primary"
          onClick={() => handleOpen("right")}
        >
          Record Attendance
        </Button>

        <div
          style={{
            margin: "1rem",
            transition: "margin-left 0.3s",
            // marginLeft: isSidebarExpanded ? "15rem" : "5rem",
          }}
        >
          {recordAttendance === undefined ? (
            <>
              <Typography>No data</Typography>
              {error?.response?.status === 200 ? null : (
                <CustomNotification
                  open={openModal}
                  setOpen={setOpenModal}
                  message={
                    error?.response?.status === 200 ? "Loading" : error?.message
                  }
                  title={error?.response?.status === 200 ? "Loading" : "Error"}
                />
              )}
            </>
          ) : (
            <Box>
              <DataGrid
                rows={recordAttendance}
                columns={columns}
                pagination
                paginationMode="server"
                rowCount={totalPages * 10} // Total number of rows (assuming 10 per page)
                onPageChange={handlePageChange} // Handle page changes
                pageSize={10} // Number of rows per page
                page={currentPage - 1} // Current page (0-based)
                paginationComponentProps={{
                  // Customize the appearance of the pagination component
                  disableNext: currentPage === totalPages,
                  disablePrev: currentPage === 1,
                }}
              />
            </Box>
          )}
        </div>
      </Container>

      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={placement}
        mode={"Record"}
        session={session}
        setCount={setCount}
      />
    </>
  );
}
