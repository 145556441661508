import React, { useEffect, useState, useContext } from "react";
import styles from "./../sportsscience/science.module.css";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import { ATHLETE_PROFILE_API_URL } from "../../services/API";
import { HttpService } from "../../services/http.service";
// import { Card } from '@mui/material'
import AthleteDetails from "./AthleteDetails";
import CalendarPeriodisation from "./CalendarPeriodisation";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import Sidebar from "../../components/CustomSideNav";
import { Button, SelectPicker } from "rsuite";
import CreatePeriodisation from "./CreatePeriodisation";
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CreateIcon from '@mui/icons-material/Create';
import PreviewIcon from '@mui/icons-material/Preview';
export default function Periodisation() {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const [athletes, setAthletes] = useState([]);
  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const [contain, setContain] = useState(0);
  const actions = [
    { icon: <CreateIcon />, name: 'Create', func:()=>() => {
      setContain(1);
      setSelectedAthlete(null);
    }  },
    { icon: <PreviewIcon />, name: 'View' ,func:()=>() => {
      setContain(2);
      
    } },
   
  ];
  useEffect(() => {
    const fetchAthlete = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}`,
          {},
          false
        );

        setAthletes(response.data.results);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthlete();
  }, []);

  const handleOpenAthlete = (athleteId) => {
    const selectedAthlete = athletes?.find(
      (athlete) => athlete.id === athleteId
    );
    if (selectedAthlete) {
      setSelectedAthlete({
        name: selectedAthlete.name,
        id: selectedAthlete.id,
        profile_photo: selectedAthlete.profile_photo_url,
        gender: selectedAthlete.gender,
        sports: selectedAthlete.sports,
        injury_status: selectedAthlete.injury,
        height: selectedAthlete.height,
      });
    } else {
      console.warn(`Athlete with ID ${athleteId} not found`);
      setSelectedAthlete(null);
    }
  };

  return (
    <div> 
     
 <DashBoardTopNav header={"Periodisation Planning"} />
    
      <Sidebar />

      <div style={{ marginTop: "50px" }}></div>

     
      {contain === 1 ? (
          <div className={styles.main_container}>
          <div className={styles.card_container}>
            <SelectPicker
              className={styles.storypicker}
              style={{ width: 200, zIndex: 1,  }}
              data={athletes?.map((val) => ({
                value: val.id,
                label: val.name,
              }))}
              value={selectedAthlete ? selectedAthlete?.id : null}
              onSelect={handleOpenAthlete}
              onClean={() => setSelectedAthlete(null)}
            />
          </div>
          <div>
            <div>
              <AthleteDetails athlete={selectedAthlete} />
            </div>
            {selectedAthlete && (
              <div
                
                className={styles.box_science_sports6}
              >
                <CreatePeriodisation
                  athlete={selectedAthlete?.id}
                  created_by={decode.user_id}
                />
              </div>
            )}
          </div>
        </div>
       
      ) : contain == 2? (
        <div className={styles.main_container}>
          <div className={styles.card_container}>
            <SelectPicker
              className={styles.storypicker}
              style={{ width: 180, zIndex: 1,  }}
              data={athletes?.map((val) => ({
                value: val.id,
                label: val.name,
              }))}
              value={selectedAthlete ? selectedAthlete?.id : null}
              onSelect={handleOpenAthlete}
              onClean={() => setSelectedAthlete(null)}
            />
          </div>
          <div>
            <div>
              <AthleteDetails athlete={selectedAthlete} />
            </div>
            {selectedAthlete && (
              <div
                
                className={styles.box_science_sports6}
              >
                <CalendarPeriodisation
                  athlete={selectedAthlete?.id}
                  created_by={decode.user_id}
                />
              </div>
            )}
          </div>
        </div>
      ):null}
        <Box > <SpeedDial
        ariaLabel="SpeedDial basic example"
        
        sx={{ position: 'absolute', bottom: "10px", left: 120 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.func()}
          />
        ))}
      </SpeedDial>
        </Box>
      
  
    </div>
  );
}
