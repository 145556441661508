import React, { useContext } from "react";
import "./../landing/styles.css";
import {
  Form,
  Button,
  SelectPicker,
  Schema,
  Loader,
  Radio,
  RadioGroup,
} from "rsuite";
import { HttpService } from "../../services/http.service";
import { CREATE_ADMINS, TEAM_API_URL } from "../../services/API";
import CustomNotification from "../CustomNotification";
import { useEffect } from "react";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import { Color } from "../../utils/Colors";

const { StringType } = Schema.Types;

const model = Schema.Model({
  first_name: StringType().isRequired("This field is required."),
  last_name: StringType().isRequired("This field is required."),
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),

  password: StringType().isRequired("This field is required."),
  verifyPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }
      return true;
    }, "The two passwords do not match")
    .isRequired("This field is required."),
});
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group style={{ margin: "20px" }} controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

export default function AdminForm({  onSubmitSuccess,setCount}) {
  const [errorCount, setErrorCount] = React.useState(0);
  const [formError, setFormError] = React.useState({});
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [team, setTeam] = React.useState();
  const [teams, setTeams] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [date, setDate] = React.useState();
  const [role,setRole] = React.useState()
  const [isTeamManager,setIsTeamManager] = React.useState(false)

  const formRef = React.useRef();
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  // console.log(decode)
  const [formValue, setFormValue] = React.useState({
    first_name: "",
    last_name: "",
  
    email: "",
    mobile_no: "",
    password: "",
    verifyPassword: "",
    department: "",
    designation: "",
  });
 const DEPARTMENT_ROLE_PERMISSION_MAPPING = [
    'Physiotherapist',
    'Doctor',
    'Strength and Conditioning Specialist',
    'Anthropometrist',
    'Psychologist',
    'Exercise Physiology',
    'Biomechanics Specialist',
    'Nutritionist',
    'Athlete',
    'Coach',
    'Admin',
 ].map((item) => ({ label: item, value: item }));
 const teamManger = [
  'Yes',
  'No',
  
].map((item) => ({ label: item, value: item }));
  // console.log(teams)
  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const response = await HttpService.get(
          `${TEAM_API_URL}?page=${currentPage}`,
          {},
          false
        );

        const modifiedTeam = response.data.results.map((t) => ({
          value: t.id,
          label: t.name,
        }));

        setTeams(modifiedTeam);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchTeam();
  }, [currentPage]);

  // console.log(role);

  const handleSubmit = async () => {
    // if (!selectedFile || !(selectedFile instanceof File)) {
    //   console.error("Invalid file selected");
    //   return;
    // }
    if (!formRef.current.check()) {
      console.error("Form Error", formError);
      return;
    }
    if (!formRef.current.check()) {
      console.error("Form Error", formError);
      return;
    }
    for (const key in formValue) {
      if (formValue.hasOwnProperty(key) && !formValue[key]) {
        setError(`${key} is required`);
        setErrorCount(prevCount => prevCount + 1);
        setOpenModal(true);
        return;
      }
    }

    // Ensure other necessary values are filled
    if (date ===undefined || !team || !selectedFile) {
      setError("Please fill all required fields");
      setErrorCount(prevCount => prevCount + 1);
      setOpenModal(true);
      return;
    }
    const {
      email,
      first_name,
      last_name,
      mobile_no,
      password,
      verifyPassword,
      ...admindata
    } = formValue;
   
    try {
      const adminProfile = {
        department: admindata.department,
        designation: admindata.designation,
      };
      const formData = new FormData();
      formData.append("email", email);
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("mobile_no", mobile_no);
      formData.append("password", password);
      formData.append("role", role);
      formData.append("team_manager", isTeamManager === "Yes"?true:false);
      formData.append("date_of_birth", date);
      formData.append("profile_photo", selectedFile);
      formData.append("teams", [team]);
      formData.append("admin_profile.department", adminProfile.department);
      formData.append("admin_profile.designation", adminProfile.designation);
      formData.append("account", decode.account);
      const response = await HttpService.post(
        CREATE_ADMINS,
        { "Content-Type": "multipart/form-data" },
        formData,
        false
      );

      setResponse(response);

      if (response.status === 201) {
        setErrorCount((prevCount) => prevCount + 1);
        setCount((prevCount) => prevCount + 1)
        setError("Form Submitted"); // Update error state to indicate success
        setOpenModal(true);
        setFormValue({
          // Clear the form data
          first_name: "",
          last_name: "",
          email: "",
          mobile_no: "",
          password: "",
          verifyPassword: "",
          department: "",
          designation: "",
        });
        setSelectedFile(null); // Clear selected file
        setDate(null); // Clear selected date
        setTeam(null); // Clear selected team
        onSubmitSuccess();
        return; // Exit early to prevent further execution
      }
    } catch (error) {
      if ((error.response && error.response?.data )|| error.response?.data?.detail) {
        if (typeof error.response?.data === "object") {
          const errorMessages = Object.values(error.response?.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response?.data?.message || error.response?.data?.detail ||"An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        // If there's a response indicating a server error (status other than 201), open the modal
        setErrorCount((prevCount) => prevCount + 1);
        setOpenModal(true);
      }
    }
  };
  const changeHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  // console.log(date)

  return decode.account === undefined ? (
    <Loader size="lg"></Loader>
  ) : (
    <div
     
    >
      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formValue={formValue}
        model={model}
        encType="multipart/form-data"
      >
        <div
         
        >
          <TextField name="first_name" label="First Name" />
          <TextField name="last_name" label="Last Name" />
        </div>

        <div
         
        >
          <div style={{ margin: "20px" }}>
            <input type="file" onChange={changeHandler} />
            <br />
            {selectedFile ? null: (
              <><br /><p>Upload a photo</p></>
            )}
          </div>
          {teams === undefined ? (
            <p>Team: Loading</p>
          ) : (
            <div style={{margin: "20px"}}>
              <SelectPicker
                label="Select Team"
                data={teams}
                onSelect={(value) => setTeam(value)}
                style={{ width: 300 }}
              />
            </div>
          )}
        </div>
        {decode.is_account_admin === true ?
        <div style={{ margin: "20px" }}>
          <label> Team Manager</label>
          <RadioGroup
            name="team_manager"
            
            style={{ width: 300 }}
            appearance="default"
            value={isTeamManager}
            onChange={value => setIsTeamManager(value)}
          >
            {teamManger.map(option => (
              <Radio color={option.value==="Yes"?"green":"red"} key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </RadioGroup>
        </div>:null}
        <div style={{margin: "20px"}}>
              <SelectPicker
                label="Select Role"
                data={DEPARTMENT_ROLE_PERMISSION_MAPPING}
                onSelect={(value) => setRole(value)}
                style={{ width: 300 }}
              />
            </div>
        <div style={{ margin: "20px" }}>
          <label>Date of Birth</label>
          <br />
          <br />
          <input type="date" onChange={(e) => setDate(e.target.value)} style={{ width: 300, height: 30 }} />
          <br />
          <br />
        </div>

        <div
         
        >
          <TextField name="email" label="Email" />

          <TextField name="mobile_no" label="Mobile Number" />
        </div>

        <div
         
        >
          <TextField name="password" label="Password" />

          <TextField name="verifyPassword" label="Verify Password" />
        </div>

        <div
         
        >
          <TextField name="department" label="Department" />
          <TextField name="designation" label="Designation" />
        </div>

        <br />
        <br />

        <Button
          appearance="primary"
          onClick={handleSubmit}
          style={{
            background: Color.Color_C,
            color: Color.Color_A,
            width: 300,marginLeft: "20px"
          }}
        >
           Create Admin
        </Button>

        <CustomNotification
          errorCount={errorCount}
          open={openModal}
          setOpen={setOpenModal}
          nav={"/profile_management"}
          message={error ? error : (res && res.data && res.data.message) || ""}
          title={error === "Form Submitted" ? "Success" : "Error"}
        />
      </Form>
    </div>
  );
}
