import React, { useState, useEffect, useMemo } from "react";
import { SelectPicker } from "rsuite";
import { HttpService } from "../services/http.service";
import {
  EVALUATION_DEPARTMENTS,
  EVALUATION_CATEGORIES,
  ATHLETE_PROFILE_API_URL,
  EVALUATION_FIELDS,
} from "../services/API";
import styles from "./../pages/sportsscience/science.module.css";
function CascadingDropDown({
  selectedCategory,
  setSelectedCategory,
  selectedDepartment,
  setSelectedDepartment,
  departments,
  setDepartments,
  categories,
  setCategories,
  athletes,
  setAthletes,
  results,
  selectedAthlete,
  setSelectedAthlete,
  fetchResult,
  onClean,
}) {
  const [filteredCategories, setFilteredCategories] = useState([]);
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(
          ATHLETE_PROFILE_API_URL,
          {},
          false
        );

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await HttpService.get(
          EVALUATION_DEPARTMENTS,
          {},
          false
        );

        const modifiedDept = response.data.results.map((dept) => ({
          value: dept.id,
          label: dept.name,
        }));

        setDepartments(modifiedDept);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchDepartment();
  }, [setDepartments]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await HttpService.get(
          EVALUATION_CATEGORIES,
          {},
          false
        );

        const modifiedCategories = response.data.results.map((cat) => ({
          value: cat.id,
          label: cat.name,
          department: cat.department,
        }));

        setCategories(modifiedCategories);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchCategories();
  }, [setCategories]);

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    // Filter categories based on the selected department
    const filteredCategories = categories?.filter(
      (category) => category.department === value
    );
    setFilteredCategories(filteredCategories); // Store filtered categories in a separate state
    // Reset the selected category
    setSelectedCategory(null);
  };

  return (
    <div className={styles.select_cat}>
      <div>
        {/* <Typography textAlign={"center"} fontWeight={"bold"}>
          Department:
        </Typography>{" "} */}
        <SelectPicker
          data={departments}
          value={selectedDepartment}
          onSelect={handleDepartmentChange}
          placeholder="Select Department"
          style={{
            width: 200,
            marginLeft: "2rem",
            marginRight: "3rem",
            marginTop: "10px",
          }}
        />
      </div>
      {selectedDepartment === null ? null : (
        <>
          <div>
            {/* <Typography textAlign={"center"} fontWeight={"bold"}>
              Category:
            </Typography>{" "} */}
            <SelectPicker
              onClean={onClean}
              data={filteredCategories}
              value={selectedCategory}
              onSelect={(value) => setSelectedCategory(value)}
              placeholder="Select Category"
              style={{ width: 200, marginTop: "10px" }}
            />
          </div>
        </>
      )}
      {/* {results?.length <= 0 ? null : (
        <div >
          <SelectPicker
            data={athletes}
            value={selectedAthlete}
            onSelect={(value) => setSelectedAthlete(value)}
            placeholder="Select Athlete"
            style={{ width: 200, marginLeft:"3rem" ,marginTop:"10px"}}
          />
        </div>
      )} */}
    </div>
  );
}

function CascadingDropDownNumeric({
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  selectedDepartment,
  setSelectedDepartment,
  departments,
  setDepartments,
  subCategories,
  setSubCategories,
  categories,
  setCategories,
  athletes,
  setAthletes,
  results,
  selectedAthlete,
  setSelectedAthlete,
  fetchResult,
  onClean,
}) {
  const [filteredCategories, setFilteredCategories] = useState([]);
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(
          ATHLETE_PROFILE_API_URL,
          {},
          false
        );

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await HttpService.get(
          EVALUATION_DEPARTMENTS,
          {},
          false
        );

        const modifiedDept = response.data.results.map((dept) => ({
          value: dept.id,
          label: dept.name,
        }));

        setDepartments(modifiedDept);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchDepartment();
  }, [setDepartments]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await HttpService.get(
          EVALUATION_CATEGORIES,
          {},
          false
        );

        const modifiedCategories = response.data.results.map((cat) => ({
          value: cat.id,
          label: cat.name,
          department: cat.department,
        }));

        setCategories(modifiedCategories);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchCategories();
  }, [setCategories]);
  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        if (selectedCategory) {
          // Fetch sub-categories only if a category is selected
          const response = await HttpService.get(
            `${EVALUATION_FIELDS}?categories=${selectedCategory}`,
            {},
            false
          );

          const numericFields = response.data.results.filter(
            (subCat) =>
              subCat.field_type === "integer" ||
              subCat.field_type === "Integer" ||
              subCat.field_type === "Int" ||
              subCat.field_type === "Range" ||
              subCat.field_type === "Float"
          );

          if (numericFields.length > 0) {
            const modifiedSubCategories = numericFields.map((subCat) => ({
              value: subCat.id,
              label: subCat.name,
            }));

            setSubCategories(modifiedSubCategories);
          } else {
            // No numeric fields found, display "No numeric data available"
            setSubCategories([
              {
                value: "no_numeric_data",
                label: "No numeric data available",
              },
            ]);
          }
        } else {
          // Reset sub-categories when no category is selected
          setSubCategories([]);
        }
      } catch (error) {
        console.warn(error);
      }
    };

    fetchSubCategories();
  }, [selectedCategory, setSubCategories]);

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    // Filter categories based on the selected department
    const filteredCategories = categories?.filter(
      (category) => category.department === value
    );
    setFilteredCategories(filteredCategories); // Store filtered categories in a separate state
    // Reset the selected category
    setSelectedCategory(null);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", marginLeft: "30px" }}>
      <div>
        <SelectPicker
          data={departments}
          value={selectedDepartment}
          onSelect={handleDepartmentChange}
          placeholder="Select Department"
          style={{ width: 200, marginRight: "10px", marginTop: "10px" }}
        />
      </div>
      {selectedDepartment === null ? null : (
        <>
          <div>
            <SelectPicker
              data={filteredCategories}
              value={selectedCategory}
              onSelect={(value) => setSelectedCategory(value)}
              placeholder="Select Category"
              style={{ width: 200, marginRight: "12px", marginTop: "10px" }}
            />
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {selectedCategory === null ? null : (
          <div>
            <SelectPicker
              onClean={onClean}
              data={subCategories}
              value={selectedSubCategory}
              onSelect={(value) => setSelectedSubCategory(value)}
              placeholder="Select Fields"
              style={{ width: 190, marginTop: "10px", marginRight: "10px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function CascadingDropDownCategorical({
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  selectedDepartment,
  setSelectedDepartment,
  departments,
  setDepartments,
  subCategories,
  setSubCategories,
  categories,
  setCategories,
  athletes,
  setAthletes,
  results,
  selectedAthlete,
  setSelectedAthlete,
  fetchResult,
  onClean,
}) {
  const [filteredCategories, setFilteredCategories] = useState([]);
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(
          ATHLETE_PROFILE_API_URL,
          {},
          false
        );

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await HttpService.get(
          EVALUATION_DEPARTMENTS,
          {},
          false
        );

        const modifiedDept = response.data.results.map((dept) => ({
          value: dept.id,
          label: dept.name,
        }));

        setDepartments(modifiedDept);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchDepartment();
  }, [setDepartments]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await HttpService.get(
          EVALUATION_CATEGORIES,
          {},
          false
        );

        const modifiedCategories = response.data.results.map((cat) => ({
          value: cat.id,
          label: cat.name,
          department: cat.department,
        }));

        setCategories(modifiedCategories);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchCategories();
  }, [setCategories]);
  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        if (selectedCategory) {
          // Fetch sub-categories only if a category is selected
          const response = await HttpService.get(
            `${EVALUATION_FIELDS}?categories=${selectedCategory}`,
            {},
            false
          );

          const numericFields = response.data.results.filter(
            (subCat) =>
              subCat.field_type === "Choice" || subCat.field_type === "choice"
          );

          if (numericFields.length > 0) {
            const modifiedSubCategories = numericFields.map((subCat) => ({
              value: subCat.id,
              label: subCat.name,
            }));

            setSubCategories(modifiedSubCategories);
          } else {
            // No numeric fields found, display "No numeric data available"
            setSubCategories([
              {
                value: "no_categorical_data",
                label: "No categorical data available",
              },
            ]);
          }
        } else {
          // Reset sub-categories when no category is selected
          setSubCategories([]);
        }
      } catch (error) {
        console.warn(error);
      }
    };

    fetchSubCategories();
  }, [selectedCategory, setSubCategories]);

  useEffect(() => {
    // Filter categories based on the selected department
    const filteredCategories = categories?.filter(
      (category) => category.department === selectedDepartment
    );
    setFilteredCategories(filteredCategories); // Store filtered categories in a separate state
    // Reset the selected category
    setSelectedCategory(null);
  }, [categories, selectedDepartment, setSelectedCategory]);

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", marginLeft: "30px" }}>
      <div>
        <SelectPicker
          data={departments}
          value={selectedDepartment}
          onSelect={handleDepartmentChange}
          placeholder="Select Department"
          style={{ width: 190, marginTop: "10px", marginRight: "12px" }}
        />
      </div>
      {selectedDepartment === null ? null : (
        <>
          <div>
            <SelectPicker
              data={filteredCategories}
              value={selectedCategory}
              onSelect={(value) => setSelectedCategory(value)}
              placeholder="Select Category"
              style={{ width: 190, marginTop: "10px", marginRight: "12px" }}
            />
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {selectedCategory === null ? null : (
          <div>
            <SelectPicker
              onClean={onClean}
              data={subCategories}
              value={selectedSubCategory}
              onSelect={(value) => setSelectedSubCategory(value)}
              placeholder="Select Fields"
              style={{ width: 190, marginTop: "10px", marginRight: "12px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function CascadingDropDownBivariate({
  selectedCategory,
  setSelectedCategory,
  selectedDepartment,
  setSelectedDepartment,
  departments,
  setDepartments,
  subCategories,
  setSubCategories,
  categories,
  setCategories,
  athletes,
  setAthletes,
  results,
  selectedAthlete,
  setSelectedAthlete,
  selectedFields,
  setSelectedFields,
  availableFieldsForFirstDropdown,
  setAvailableFieldsForFirstDropdown,
  availableFieldsForSecondDropdown,
  setAvailableFieldsForSecondDropdown,
  fetchResult,
  onClean,
}) {
  const [filteredCategories, setFilteredCategories] = useState([]);

  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(
          ATHLETE_PROFILE_API_URL,
          {},
          false
        );

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await HttpService.get(
          EVALUATION_DEPARTMENTS,
          {},
          false
        );

        const modifiedDept = response.data.results.map((dept) => ({
          value: dept.id,
          label: dept.name,
        }));

        setDepartments(modifiedDept);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchDepartment();
  }, [setDepartments]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await HttpService.get(
          EVALUATION_CATEGORIES,
          {},
          false
        );

        const modifiedCategories = response.data.results.map((cat) => ({
          value: cat.id,
          label: cat.name,
          department: cat.department,
        }));

        setCategories(modifiedCategories);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchCategories();
  }, [setCategories]);

  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        if (selectedCategory) {
          // Fetch sub-categories only if a category is selected
          const response = await HttpService.get(
            `${EVALUATION_FIELDS}?categories=${selectedCategory}`,
            {},
            false
          );

          const numericFields = response.data.results.filter(
            (subCat) =>
              subCat.field_type === "integer" ||
              subCat.field_type === "Integer" ||
              subCat.field_type === "Int" ||
              subCat.field_type === "Range" ||
              subCat.field_type === "Float"
          );

          const numericFieldIds = numericFields.map((subCat) => subCat.id);

          setAvailableFieldsForFirstDropdown(numericFieldIds); // Update available fields for the first dropdown
          setAvailableFieldsForSecondDropdown(numericFieldIds); // Update available fields for the second dropdown

          if (numericFields.length > 0) {
            const modifiedSubCategories = numericFields.map((subCat) => ({
              value: subCat.id,
              label: subCat.name,
            }));

            setSubCategories(modifiedSubCategories);
          } else {
            // No numeric fields found, display "No numeric data available"
            setSubCategories([
              {
                value: "no_numeric_data",
                label: "No numeric data available",
              },
            ]);
          }
        } else {
          // Reset sub-categories when no category is selected
          setSubCategories([]);
        }
      } catch (error) {
        console.warn(error);
      }
    };

    fetchSubCategories();
  }, [
    selectedCategory,
    setSubCategories,
    setAvailableFieldsForFirstDropdown,
    setAvailableFieldsForSecondDropdown,
  ]);

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    // Filter categories based on the selected department
    const filteredCategories = categories?.filter(
      (category) => category.department === value
    );
    setFilteredCategories(filteredCategories); // Store filtered categories in a separate state
    // Reset the selected category and fields
    setSelectedCategory(null);
    setSelectedFields([]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div style={{ marginLeft: "30px" }}>
        <SelectPicker
          data={departments}
          value={selectedDepartment}
          onSelect={handleDepartmentChange}
          placeholder="Select Department"
          style={{ width: 160, marginRight: "10px", marginTop: "10px" }}
        />
      </div>
      {selectedDepartment === null ? null : (
        <>
          <div>
            <SelectPicker
              data={filteredCategories}
              value={selectedCategory}
              onSelect={(value) => {
                setSelectedCategory(value);
                // Reset selected fields when the category changes
                setSelectedFields([]);
              }}
              placeholder="Select Category"
              style={{ width: 150, marginRight: "10px", marginTop: "10px" }}
            />
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {selectedCategory === null ? null : (
          <>
            <div>
              <SelectPicker
                data={subCategories.filter((field) =>
                  availableFieldsForFirstDropdown.includes(field.value)
                )}
                value={selectedFields[0]}
                onSelect={(value) =>
                  setSelectedFields([value, selectedFields[1]])
                }
                placeholder="Select Field 1"
                style={{ width: 150, marginRight: "10px", marginTop: "10px" }}
              />
            </div>
            <div>
              <SelectPicker
                onClean={onClean}
                data={subCategories.filter(
                  (field) =>
                    availableFieldsForSecondDropdown.includes(field.value) &&
                    field.value !== selectedFields[0]
                )}
                value={selectedFields[1]}
                onSelect={(value) =>
                  setSelectedFields([selectedFields[0], value])
                }
                placeholder="Select Field 2"
                style={{ width: 150, marginRight: "10px", marginTop: "10px" }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
function CascadingDropDownMultivariate({
  selectedCategory,
  setSelectedCategory,
  selectedDepartment,
  setSelectedDepartment,
  departments,
  setDepartments,
  categories,
  setCategories,
  athletes,
  setAthletes,
  results,
  selectedAthlete,
  setSelectedAthlete,
  fetchResult,
  onClean,
}) {
  const [filteredCategories, setFilteredCategories] = useState([]);
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(
          ATHLETE_PROFILE_API_URL,
          {},
          false
        );

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await HttpService.get(
          EVALUATION_DEPARTMENTS,
          {},
          false
        );

        const modifiedDept = response.data.results.map((dept) => ({
          value: dept.id,
          label: dept.name,
        }));

        setDepartments(modifiedDept);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchDepartment();
  }, [setDepartments]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await HttpService.get(
          EVALUATION_CATEGORIES,
          {},
          false
        );

        const modifiedCategories = response.data.results.map((cat) => ({
          value: cat.id,
          label: cat.name,
          department: cat.department,
        }));

        setCategories(modifiedCategories);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchCategories();
  }, [setCategories]);

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    // Filter categories based on the selected department
    const filteredCategories = categories?.filter(
      (category) => category.department === value
    );
    setFilteredCategories(filteredCategories); // Store filtered categories in a separate state
    // Reset the selected category
    setSelectedCategory(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div style={{ marginLeft: "30px" }}>
        <SelectPicker
          data={departments}
          value={selectedDepartment}
          onSelect={handleDepartmentChange}
          placeholder="Select Department"
          style={{ width: 200, marginRight: "12px", marginTop: "10px" }}
        />
      </div>
      {selectedDepartment === null ? null : (
        <>
          <div>
            <SelectPicker
              onClean={onClean}
              data={filteredCategories}
              value={selectedCategory}
              onSelect={(value) => setSelectedCategory(value)}
              placeholder="Select Category"
              style={{ width: 200, marginRight: "12px", marginTop: "10px" }}
            />
          </div>
        </>
      )}
    </div>
  );
}
export {
  CascadingDropDown,
  CascadingDropDownNumeric,
  CascadingDropDownCategorical,
  CascadingDropDownBivariate,
  CascadingDropDownMultivariate,
};
