import React, { useState } from 'react';
import styles from './videoupload.module.css';
import { useNavigate } from 'react-router-dom';

const VideoUpload = ({selectedFile, setSelectedFile}) => {
  
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate()


  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  const handleRemove = () => {
    setSelectedFile(null);
  };

  return (
    <div className={styles["formbold-form-wrapper"]}>
      <div className={styles["formbold-mb-5"]} />
      <div className={`${styles["mb-6"]} ${styles["pt-4"]}`}>
        <label className={styles["formbold-form-label-2"]}>
          Upload File
        </label>
        <div className={styles["formbold-file-input"]}>
          <input
            type="file"
            name="file"
            id="file"
            className={styles["file-input"]}
            onChange={handleFileChange}
            accept="video/*"
          />
          <label htmlFor="file" className={styles["formbold-file-label"]}>
            <div>
              <span className={styles["formbold-drop-file"]}>Drop files here</span>
              <span className={styles["formbold-or"]}>Or</span>
              <span className={styles["formbold-browse"]}>Browse</span>
            </div>
          </label>
        </div>
      </div>
      {selectedFile && (
        <div className={styles["formbold-file-list"]}>
          <div className={styles["formbold-file-item"]}>
            <span className={styles["formbold-file-name"]}>{selectedFile.name}</span>
            <button className={styles["formbold-file-remove"]} onClick={handleRemove}>
              <svg
                width={10}
                height={10}
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                  fill="currentColor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {selectedFile && uploadProgress > 0 && (
        <div className={styles["formbold-progress-bar"]}>
          <div className={styles["formbold-progress"]} style={{ width: `${uploadProgress}%` }} />
        </div>
      )}
      <div>
        {/* <button className={styles["formbold-btn"]} onClick={handleUpload}>Next</button> */}
      </div>
    </div>
  );
}

export default VideoUpload;
