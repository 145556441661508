import React, { useEffect } from "react";
import "./../../components/landing/styles.css";
import {
  Form,
  Button,
  // ButtonToolbar,
  // Schema,
  FlexboxGrid,
  SelectPicker,
  // Placeholder,
  // Message,
} from "rsuite";
import {
  // ACCOUNTS_API_URL,
  NOTIFICATION_API_URLS,
  NOTIFICATION_RECIPENTS,
  // TEAM_API_URL,
  USER_API_URL,
} from "../../services/API";
import { HttpService } from "../../services/http.service";
import CustomNotification from "../../components/CustomNotification";
import { Color } from "../../utils/Colors";

// const model = Schema.Model({
//   content: StringType().isRequired("This field is required."),
// });

// const TextField = React.forwardRef((props, ref) => {
//   const { name, label, accepter, ...rest } = props;
//   return (
//     <Form.Group controlId={`${name}-4`} ref={ref}>
//       <Form.ControlLabel>{label} </Form.ControlLabel>
//       <Form.Control name={name} accepter={accepter} {...rest} />
//     </Form.Group>
//   );
// });

export default function SendNotification({ onSubmitSuccess,setCount }) {
  const formRef = React.useRef();
  const [users, setUser] = React.useState();
  const [record, setRecord] = React.useState();
  const [notification, setNotification] = React.useState();
  const [notifications, setNotifications] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [formError, setFormError] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await HttpService.get(
          `${USER_API_URL}?page=${currentPage}`,{}, true
        );

        const modifiedUsers = response.data.results.map((user) => ({
          value: user.id,
          label: user.first_name + " " + user.last_name,
        }));

        setUser(modifiedUsers);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchUser();
  }, [currentPage]);

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const response = await HttpService.get(
          `${NOTIFICATION_API_URLS}?page=${currentPage}`,{}, false
        );

        const modifiedNotification = response.data.results.map((n) => ({
          value: n.id,
          label: n.content,
        }));

        setNotifications(modifiedNotification);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchNotification();
  }, [currentPage]);
 
  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      console.error("Form Error", formError);
      return;
    }

    try {
      const formData = {
        notification: notification,
        recipient: record,
      };
      // console.log(formData)
      const response = await HttpService.post(
        NOTIFICATION_RECIPENTS,
        {},
        formData,false
      );

      setResponse(response);

      if (response.status === 201) {
        setOpenModal(true);
        setCount(prev=>prev+1)
        setError("Form Submitted");
        onSubmitSuccess();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      setOpenModal(true);
    }
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        <Form ref={formRef} formError={setFormError}>
          <SelectPicker
            label="Select Notification"
            data={notifications}
            onSelect={(value) => setNotification(value)}
            style={{ width: 300 }}
          />
          <br />
          <br />

          <SelectPicker
            label="Select Recipient"
            data={users}
            onSelect={(value) => setRecord(value)}
            style={{ width: 300 }}
          />
          <br />
          <br />
         
            <Button
              appearance="primary"
              onClick={handleSubmit}
              disabled={notification === undefined}
              style={{background:Color.Color_C,color:Color.Color_A,width:300}}
            >
              Submit
            </Button>
         
          <CustomNotification
            open={openModal}
            setOpen={setOpenModal}
            message={
              error ? error : (res && res.data && res.data.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
