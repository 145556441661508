// InternetStatus.js
import React, { useEffect, useState } from "react";

function InternetStatus() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    // Add event listeners to check for online/offline status changes
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      // Remove event listeners when the component is unmounted
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  // Define inline CSS styles
  const internetStatusStyle = {
    position: "fixed",
    top: "10%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    backgroundColor: "white",
    border: "1px solid #ccc",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
    zIndex: "9999",
    display: isOnline ? "none" : "block", // Conditionally show/hide the component
  };

  return !isOnline ? (
    <div style={internetStatusStyle}>
     
      <h1> You are Offline 🙁</h1>
      <div>Go back online to use Athlete Management System </div>
    </div>
  ) : null;
}

export default InternetStatus;
