import React, { useEffect } from "react";
import styles from "./../../sportsscience/science.module.css"
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CustomNotification from "../../../components/CustomNotification";
// import { GetVenueData } from "../../../components/custmomhooks/CustomHooks";
import { Container, Typography } from "@mui/material";
import { VENUE_API_URLS } from "../../../services/API";
import { HttpService } from "../../../services/http.service";
import { Button } from "rsuite";
import UserCustomDrawer from "../../../components/CustomDrawer";
export default function ViewVenue() {
  const [open, setOpen] = React.useState(false);
  const [venueData, setVenueData] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1); // Total number of pages
  const [count,setCount]= React.useState(0)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${VENUE_API_URLS}?page=${currentPage}`,{}, false
        );
        setVenueData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setVenueData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,count
  ])


  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1); // Page is 0-based, API expects 1-based
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "location",
      headerName: "Location",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "capacity",
      headerName: "Capacity",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
  ];
  
  return (
    <>
     
     <div className={styles.belownav}>
       
          <Button
            appearance="primary"
            onClick={() => setOpen(true)}
            className={styles.btn}
            size="small"
            style={{ marginLeft: "22px", marginRight: "20px", marginTop: "6px" ,background:"cadetblue"}}
          >
            Create Venue
          </Button>
         
      </div>
      <Container maxWidth="lg">
      

        <div  style={{
            margin: "1rem",
            transition: "margin-left 0.3s",
            
          }}>
          {venueData === undefined || venueData?.length <= 0 ? (
            <>
              <Typography>No data</Typography>
              {error?.response?.status === 200 ? null : (
                <CustomNotification
                  open={openModal}
                  setOpen={setOpenModal}
                  message={
                    error?.response?.status === 200 ? "Loading" : error?.message
                  }
                  title={error?.response?.status === 200 ? "Loading" : "Error"}
                />
              )}
            </>
          ) : (
            <Box>
              <DataGrid
                rows={venueData}
                columns={columns}
                pagination
                paginationMode="server"
                rowCount={totalPages * 10} // Total number of rows (assuming 10 per page)
                onPageChange={handlePageChange} // Handle page changes
                pageSize={10} // Number of rows per page
                page={currentPage - 1} // Current page (0-based)
                paginationComponentProps={{
                  // Customize the appearance of the pagination component
                  disableNext: currentPage === totalPages,
                  disablePrev: currentPage === 1,
                }}
              />
            </Box>
          )}
        </div>
      </Container>
      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={"right"}
        mode={"Add Venue"}
        setCount={setCount}
      />
     
    </>
  );
}
