import React, { useEffect } from "react";
import { HttpService } from "../../../services/http.service";
import { Button } from "rsuite";
import UserCustomDrawer from "../../../components/CustomDrawer";
// import CustomSideNavbar from "../../../components/CustomSideNav";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CustomNotification from "../../../components/CustomNotification";
// import DashBoardTopNav from "../../../components/DashBoardTopNav";
// import { GetSCSession } from "../../../components/custmomhooks/CustomHooks";
import { Container, Typography } from "@mui/material";
import { SC_WORKOUT_API_URLS, WOKRKOUT_SESSION_LOGS_API_URLS, USER_API_URL } from "../../../services/API";
import { Color } from "../../../utils/Colors";
// import Sidebar from "../../../components/CustomSideNav";
export default function ViewScWorkout({ session, athleteView }) {
  const [open, setOpen] = React.useState(false);
  // const [isSidebarExpanded, setIsSidebarExpanded] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [scSession, setSCSession] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1); // Total number of pages
  const [count,setCount] = React.useState(0)
  // console.log(session)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${SC_WORKOUT_API_URLS}?page=${currentPage}`,{}, false
        );

        const modifiedResult = await Promise.allSettled(
          response.data.results.map(async (result) => {
            let session_id = ""
            if (session === result.session) {
              session_id = session
            }
            try {
              // Fetch athlete data for each result
              const sessionResponse = await HttpService.get(
                WOKRKOUT_SESSION_LOGS_API_URLS + session_id + "/",{}, false
              );
              const sessionName = sessionResponse.data.name;
              if (athleteView === "athleteView") {
                return {
                  id: result.id,
                  session_name: sessionName,
                  ...result,
                };
              } else {
                const userResponse = await HttpService.get(
                  USER_API_URL + result.recorded_by + "/",{}, true
                );
                return {
                  id: result.id,
                  session_name: sessionName,
                  user: userResponse.data.first_name,
                  ...result,
                };
              }

            } catch (error) {
              // Handle individual request error
              console.error(
                `Error fetching data for result ${result.id}:`,
                error
              );
              return null; // or handle the error in an appropriate way
            }
          })
        );

        // Extracting successful results and maintaining the order
        const filteredResults = modifiedResult
          .filter(
            (result) => result.status === "fulfilled" && result.value !== null
          )
          .map((result) => result.value);

        setSCSession(filteredResults);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10));
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [currentPage,athleteView,session,count]);

  // console.log(eventData);
  const handleOpen = (key) => {
    setOpen(true);
    setPlacement(key);
  };

  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1); // Page is 0-based, API expects 1-based
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "workout_type",
      headerName: "Exercise Name",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "heart_rate",
      headerName: "HR",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "rate_of_percieved_exertion",
      headerName: "RPE",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "distance",
      headerName: "Distance",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "calories_burned",
      headerName: "Calories Burned",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "session_name",
      headerName: "Session Name",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "frequency",
      headerName: "Sets",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "intensity",
      headerName: "Intensity",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "repetition",
      headerName: "Repetition",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "volume",
      headerName: "Weight in Kg",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "user",
      headerName: "Recorded By",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
  ];
  
  const actualColumns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "workout_type",
      headerName: "Exercise Name",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_heart_rate",
      headerName: "HR",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_rate_of_percieved_exertion",
      headerName: "RPE",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_distance",
      headerName: "Distance",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_duration",
      headerName: "Duration",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "achieved_calories_burned",
      headerName: "Calories Burned",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "session_name",
      headerName: "Session Name",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_frequency",
      headerName: "Sets",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_intensity",
      headerName: "Intensity",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_repetition",
      headerName: "Repetition",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_volume",
      headerName: "Weight in Kg",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "user",
      headerName: "Recorded By",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
  ];
  return (
    <>
    
    
  
      <Container maxWidth="lg">
        {athleteView === "athleteView" ? null :
          <><Button
            style={{
              marginTop: "2rem", marginLeft: "1rem",
              backgroundColor: Color.Color_C, color: Color.Color_AA
            }}
            appearance="primary"
            onClick={() => handleOpen("View SnC")}
          >
            Record Strength & Conditioning Workout
          </Button><Button
            style={{
              marginTop: "2rem", marginLeft: "1rem",
              backgroundColor: Color.Color_C, color: Color.Color_AA
            }}
            appearance="primary"
            onClick={() => handleOpen("View SnCActual")}
          >
              Actual Strength & Conditioning Workout
            </Button></>
          
          }

        <div style={{
          margin: "1rem",
          
         
        }}>
          {scSession === undefined || scSession?.length <= 0 ? (
              <>
              <Typography>No data</Typography>
              {error?.response?.status === 200 ? null : (
                <CustomNotification
                  open={openModal}
                  setOpen={setOpenModal}
                  message={
                    error?.response?.status === 200 ? "Loading" : error?.message
                  }
                  title={error?.response?.status === 200 ? "Loading" : "Error"}
                />
              )}
            </>
          ) : (
            <><Box>
              <Typography marginTop={5} marginBottom={5}>Planned</Typography>
                <DataGrid
                  rows={scSession}
                  columns={columns}
                  pagination
                  paginationMode="server"
                  rowCount={totalPages * 10} // Total number of rows (assuming 10 per page)
                  onPageChange={handlePageChange} // Handle page changes
                  pageSize={10} // Number of rows per page
                  page={currentPage - 1} // Current page (0-based)
                  paginationComponentProps={{
                    // Customize the appearance of the pagination component
                    disableNext: currentPage === totalPages,
                    disablePrev: currentPage === 1,
                  }} />
              </Box>
              
              <Box>
              <Typography marginTop={5} marginBottom={5}>Actuals</Typography>
                  <DataGrid
                    rows={scSession}
                    columns={actualColumns}
                    pagination
                    paginationMode="server"
                    rowCount={totalPages * 10} // Total number of rows (assuming 10 per page)
                    onPageChange={handlePageChange} // Handle page changes
                    pageSize={10} // Number of rows per page
                    page={currentPage - 1} // Current page (0-based)
                    paginationComponentProps={{
                      // Customize the appearance of the pagination component
                      disableNext: currentPage === totalPages,
                      disablePrev: currentPage === 1,
                    }} />
                </Box></>
          )}
        </div>
      </Container>

      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={"right"}
        mode={placement}
        session={session}
        setCount={setCount}
      />
    </>
  );
}
