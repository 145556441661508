import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/AuthContext";
import { SUBSCRIPTION_API_URLS, USER_API_URL } from "../../services/API";
import { Typography } from "@mui/material";
import jwt_decode from "jwt-decode";
import { HttpService } from "../../services/http.service";
import { DataGrid } from "@mui/x-data-grid";

export default function SubscriptionDetails() {
    const [subscriptionData, setSubscriptionData] = useState([]);
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;

    const [adminData, setAdminData] = useState([]);
    let decode = "";
    if (token) {
        decode = jwt_decode(token);
    } else {
        decode = "";
    }

    function CustomFooterStatusComponent() {
      return null
      
    }
    useEffect(() => {
        const fetchData_ = async (url, setData, bool) => {
            try {
                const response = await HttpService.get(`${url}`, {}, bool);
                setData(response.data);
            } catch (error) {}
        };

        fetchData_(
            USER_API_URL + decode.user_id + "/account_details",
            setAdminData,
            true
        );
    }, [decode.user_id]);

    useEffect(() => {
        const fetchSubscriptionData = async () => {
            try {
                if (!token) return; // Exit if token is not available
                const response = await HttpService.get(
                    SUBSCRIPTION_API_URLS,
                    {},
                    true
                );
                setSubscriptionData(response.data.results);
            } catch (error) {
                console.error("Error fetching subscription data:", error);
            }
        };
        fetchSubscriptionData();
    }, [token]);
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    const columns = [
        { field: "created_at", headerName: "Created Date", width: 250, 
        valueGetter: (params) => formatDate(params.row.created_at)
      },
        { field: "max_users", headerName: "Maximum Users", width: 150 },
        { field: "max_admin_profiles", headerName: "Maximum Admin Profiles", width: 200 },
        { field: "max_athlete_profiles", headerName: "Maximum Athlete Profiles", width: 200 },
        { field: "max_teams", headerName: "Maximum Teams", width: 150 },
       
        { field: "start_date", headerName: "Start Date", width: 150 },
        { field: "expiration_date", headerName: "Expiration Date", width: 150 },
    ];

    return (
        <div style={{ fontWeight: "bolder",   }}>
            {subscriptionData?.length <= 0 ? <Typography variant={"h6"} color={"red"}>Permission Denied</Typography>  : (
                <div>
                   
                    <div >
                        <DataGrid
                            rows={subscriptionData}
                            columns={columns}
                         
                            disableSelectionOnClick
                            slots={{
                                footer: CustomFooterStatusComponent,
                              }}
                              
                        />
                    </div>
                 
                </div>
            )}
        </div>
    );
}
