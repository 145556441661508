import React from "react";
import "./UserInfo.css"; // Import your custom CSS file here
import { Button, Divider } from "rsuite";
import UserCustomDrawer from "../../components/CustomDrawer";
// import CustomSideNavbar from "../../components/CustomSideNav";
import { Box, Container, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomNotification from "../../components/CustomNotification";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import { GetUserData } from "../../components/custmomhooks/CustomHooks";
export default function UserInfo() {
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [userData, setUserData] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1); // Total number of pages

  GetUserData({
    setUserData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  });

  // console.log(userData);
  const handleOpen = (key) => {
    setOpen(true);
    setPlacement(key);
  };
  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1); // Page is 0-based, API expects 1-based
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "first_name",
      headerName: "First name",
      width: 200,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "last_name",
      headerName: "Last name",
      width: 200,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mobile_no",
      headerName: "Mobile Number",
      type: "number",
      width: 200,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "E-mail",
      type: "number",
      width: 260,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "role",
      headerName: "Role",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <>
      <DashBoardTopNav />
      <Divider style={{ border: "2rem" }} />
      <Container maxWidth="lg">
        <Button
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
          appearance="primary"
          onClick={() => handleOpen("right")}
        >
          Add User
        </Button>

        <div>
          {userData === undefined ? (
            <>
              <Typography>No Data</Typography>
              {error?.response?.status === 200 ? null : (
                <CustomNotification
                  open={openModal}
                  setOpen={setOpenModal}
                  message={
                    error?.response?.status === 200 ? "Loading" : error?.message
                  }
                  title={error?.response?.status === 200 ? "Loading" : "Error"}
                />
              )}
            </>
          ) : (
            <Box sx={{ height: "100%", width: "100%" }}>
              <DataGrid
                rows={userData}
                columns={columns}
                pagination
                paginationMode="server"
                rowCount={totalPages * 10} // Total number of rows (assuming 10 per page)
                onPageChange={handlePageChange} // Handle page changes
                pageSize={10} // Number of rows per page
                page={currentPage - 1} // Current page (0-based)
                paginationComponentProps={{
                  // Customize the appearance of the pagination component
                  disableNext: currentPage === totalPages,
                  disablePrev: currentPage === 1,
                }}
              />
            </Box>
          )}
        </div>
      </Container>

      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"full"}
        placement={placement}
        mode={"User"}
      />
    </>
  );
}
