import React, { useState } from "react";
import styles from "./../../science.module.css";
import EvaluationFormView from "../../../../components/EvaluationFormView";

export default function Subjective({ mode }) {
  const [open, setOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState();
  const [dept, setDept] = useState();
  const [cat, setCat] = useState();


  const data = [
    { cat: 1, name: "Demographic", dept: 1, cardColor: "#c5cae9" },
    { cat: 2, name: "History", dept: 1, cardColor: "#c5cae9" },

  ];

  const handleOpen = ({ name, key, dept, cat }) => {
    setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
    setDept(dept);
    setCat(cat);
  };

  return (
    <  >
   
      <div className={styles.sports_main_inner}>
          <select
            className={styles.dropdown}
            onChange={(e) => {
              const selectedValue = e.target.value;
              const selectedItem = data.find(
                (item) => item.name === selectedValue
              );

              if (selectedItem) {
                handleOpen({
                  name: selectedItem.name,
                  dept: selectedItem.dept,
                  cat: selectedItem.cat,
                });
              }
            }}
          >
            <option value="">{mode}</option>
            {data.map((item) => (
              <option key={item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
     


      <EvaluationFormView
        open={open}
        setOpen={setOpen}
        size={"lg"}
        placement={"left"}
        mode={drawerTitle}
        dept={dept}
        cat={cat}
      />

    </>



  );
}
