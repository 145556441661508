import React from "react";
import styles from "./landing.module.css";

import Lottie from "lottie-react";

export default function DataDriven() {

  return (
    
    <div className={styles.datadriven}>
     
      <div className={styles.lottie} >
        <Lottie
          animationData={require("../../assets/json/datadriven.json")}
          loop={true} />


      </div><h2 className={styles.title}>
        Data Driven Sports Science and Rehab
      </h2></div>
  );
}
