import React, { useEffect, useState } from "react";
// import { Button } from "rsuite"; // Import SelectPicker
import { Card, CardContent, Typography } from "@mui/material";
import Chart from "chart.js/auto";
import { Pie } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridToolbarExport, } from "@mui/x-data-grid";
// import { Color } from "../../utils/Colors";
import { CascadingDropDownCategorical } from "../../components/CascadingDropDown";
import { ATHLETE_PROFILE_API_URL, EVALUATION_RESULTS } from "../../services/API";
import { HttpService } from "../../services/http.service";
import styles from "./../../pages/sportsscience/science.module.css";

Chart.register(CategoryScale);

export default function Categorical() {

  const [results, setResultCat] = useState([]);
  const [filterResults, setFilterResultsCat] = useState([]);
  const [selectedDepartmentCat, setSelectedDepartmentCat] = useState(null);
  const [selectedCategoryCat, setSelectedCategoryCat] = useState(null);
  const [selectedAthleteCat, setSelectedAthleteCat] = useState(null); // Add state for selected athlete
  const [departmentsCat, setDepartmentsCat] = useState([]);
  const [categoriesCat, setCategoriesCat] = useState([]);
  const [athletesCat, setAthletesCat] = useState(); // State to store athlete options
  // const [showGenerateButtonCat, setShowGenerateButtonCat] = useState(true);
  const [filter, setFilterCat] = useState(0);
  const [subCategoriesCat, setSubCategoriesCat] = useState([]);
  const [selectedSubCategoryCat, setSelectedSubCategoryCat] = useState(null);

  useEffect(() => {
    const fetchResultCat = async () => {
      if (selectedDepartmentCat === null || selectedCategoryCat === null || selectedSubCategoryCat === null) { return null }
      try {
        const response = await HttpService.get(
          `${EVALUATION_RESULTS}?department=${selectedDepartmentCat}&categories=${selectedCategoryCat}&field=${selectedSubCategoryCat}`,
          {},
          false
        );

        const modifiedResult = await Promise.all(
          response.data.results.map(async (result, index) => {
            try {
              // Fetch athlete data for each result
              const athleteResponse = await HttpService.get(
                ATHLETE_PROFILE_API_URL + result.athlete + "/",
                {},
                false
              );

              const athleteName = athleteResponse.data.name;
              const athleteId = athleteResponse.data.id;
              const value = determineValueCat(result);

              // Only include the result if athlete details are available
              if (athleteName !== "N/A") {
                return {
                  id: index,
                  athlete: athleteName,
                  athlete_id: athleteId,
                  value,
                  date: result.created_at.slice(0, 10),
                  field_name: result.field_name,
                  field_id: result.field,
                };
              }

              return null; // Exclude the result when athlete is "N/A"
            } catch (athleteError) {
              console.warn(
                "Error fetching athlete details:",
                athleteError.message
              );
              // If athlete details cannot be fetched, return null to exclude the result
              return null;
            }
          })
        );

        // Filter out null results caused by failed athlete fetch or when athlete is "N/A"
        const filteredResults = modifiedResult.filter(
          (result) => result !== null
        );

        setResultCat(filteredResults);
        setFilterCat(1);
      } catch (error) {
        console.warn(error);
      }
    };
    fetchResultCat()
  }, [selectedDepartmentCat, selectedSubCategoryCat, selectedCategoryCat])


  const determineValueCat = (result) => {
    return result.boolean_value !== false
      ? result.boolean_value
      : result.choice_value !== null
        ? result.choice_value
        : result.date_value !== null
          ? result.date_value
          : result.float_value !== null
            ? result.float_value
            : result.integer_value !== null
              ? result.integer_value
              : result.remarks !== null
                ? result.remarks
                : result.string_value !== null
                  ? result.string_value
                  : null;
  };
  // console.log(results)
  // const filterResultsByAthleteCat = () => {
  //   if (selectedAthleteCat === null) {
  //     // If no athlete is selected, reset the filterResults to the original results
  //     setFilterResultsCat(resultsCat);
  //   } else {
  //     // Filter the original results based on the selected athlete
  //     const filteredResults = resultsCat.filter(
  //       (result) => result.athlete_id === selectedAthleteCat
  //     );
  //     setFilterResultsCat(filteredResults);
  //   }
  //   setFilterCat(2);
  // };

  const resetSelectionCat = () => {
    setSelectedDepartmentCat(null);
    setSelectedCategoryCat(null);
    setSelectedAthleteCat(null);
    setResultCat([]);
    setFilterResultsCat([]);

    setFilterCat(0);
  };
  // console.log(results)

  const columns = [
    {
      field: "athlete",
      headerName: "Name",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "field_name",
      headerName: "Test Name",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      headerName: "Result",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }

  function countResult(data) {
    const valueCount = {};
    data?.forEach((item) => {
      const value = item.value;
      if (value) {
        valueCount[value] = (valueCount[value] || 0) + 1;
      }
    });
    return valueCount;
  }

  function ChartSection({ title, data, backgroundColor }) {
    return (
      <div>
        <Pie
          data={{
            labels: Object.keys(data),
            datasets: [
              {
                data: Object.values(data),
                backgroundColor,
              },
            ],
          }}
          options={{
            plugins: {
              title: {
                display: true,
                text: title,
              },
              legend: {
                display: true,
              },
            },
          }}
        />
      </div>
    );
  }


  return (
    <div>

      <div className={styles.belownav}>
        <CascadingDropDownCategorical
          selectedDepartment={selectedDepartmentCat}
          setSelectedDepartment={setSelectedDepartmentCat}
          selectedCategory={selectedCategoryCat}
          setSelectedCategory={setSelectedCategoryCat}
          departments={departmentsCat}
          setDepartments={setDepartmentsCat}
          categories={categoriesCat}
          setCategories={setCategoriesCat}
          athletes={athletesCat}
          setAthletes={setAthletesCat}
          results={results}
          selectedAthlete={selectedAthleteCat}
          setSelectedAthlete={setSelectedAthleteCat}
          subCategories={subCategoriesCat}
          setSubCategories={setSubCategoriesCat}
          selectedSubCategory={selectedSubCategoryCat}
          setSelectedSubCategory={setSelectedSubCategoryCat}
          onClean={resetSelectionCat}

        />
      </div>


      {filter === 1 ? (
        <>
          {results?.length === 0 ? (
            <Typography variant="h6" textAlign={"center"}>
              No Data
            </Typography> // Use results.length to check if there are no results
          ) : (
            <div
              style={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              <Card
                sx={{
                  width: "40rem",
                  height: "40rem",
                  marginBottom: "2rem",
                  margin: "1rem",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <ChartSection
                    title="Categorical Data"
                    data={countResult(results)}
                    backgroundColor={["pink", "skyblue"]}
                  />
                </CardContent>
              </Card>
              <div
                style={{ marginLeft: "30px", marginTop: "30px" }}   >
                <Box key={Math.random().toLocaleString()}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={results}
                    columns={columns}
                    pagination
                    paginationMode="client"
                    pageSize={10}
                    slots={{ toolbar: CustomToolbar }}
                  />
                </Box>

              </div>

            </div>
          )}
        </>
      ) : filter === 2 ? (
        <>
          {filterResults.length === 0 ? (
            <Typography variant="h6" textAlign={"center"}>
              No Data
            </Typography> // Use filterResults.length to check if there are no results
          ) : (
            <div
              style={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              {" "}
              <Container
                maxWidth="lg"
                style={{ marginTop: "4rem", marginBottom: "2rem" }}
              >
                <Box key={Math.random().toLocaleString()}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={filterResults}
                    columns={columns}
                    pagination
                    paginationMode="client"
                    pageSize={10}
                  />
                </Box>

              </Container>
              <Card
                sx={{
                  width: "40rem",
                  height: "40rem",
                  marginBottom: "2rem",
                  margin: "1rem",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <ChartSection
                    title="Categorical Data"
                    data={countResult(filterResults)}
                    backgroundColor={["pink", "skyblue"]}
                  />
                </CardContent>
              </Card>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}
