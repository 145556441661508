import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./../store/AuthContext";
import { login } from "./../utils/Auth";
import AuthContent from "./AuthContent";
import "./spinner.css";

function LoginScreen() {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  // console.log("nav",navigate)
  async function loginHandler({ email, password }) {
    setIsAuthenticating(true);
    try {
      const { token, refreshToken } = await login(email, password);
      authCtx.authenticate(token);
      setLoggedIn(true);

      // Store the refresh token in local storage
      localStorage.setItem("refreshToken", refreshToken);
     
    } catch (error) {
      // console.log(error.response);
      error?.response?.status === 401
        ? navigate("/error",{state:{
          link: "/",
          tabname:"Go To Home",
          message: "No active account found with the given credentials or Credentials may be wrong..!!",
          description: "Contact Admin",
        }})
        : alert("Authentication failed! " + error?.data?.detail);
    }
    setIsAuthenticating(false);
  }

  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard"); // Redirect to dashboard
    }
  }, [loggedIn, navigate]);

  if (isAuthenticating) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 999,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="spinner-border" role="status"></div>
        </div>
      </div>
    );
  }

  return <AuthContent isLogin onAuthenticate={loginHandler} />;
}

export default LoginScreen;
