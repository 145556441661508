import React, { useState, useEffect } from "react";
import {  FetchResultsByField } from "../../components/custmomhooks/CustomHooks";
import CustomBarChart from "./BarChart";
import styles from "./injury.module.css";
import { Button } from "rsuite";


import NODATA from "./../../assets/image/noChart.svg"
import { Typography } from "@mui/material";
export default function MonitoringDashboard() {
  const currentUrl = window.location.href;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const [filter, setFilter] = useState(1);
  const [dataResults, setDataResults] = useState({
    sleep: { data: [], title: "Sleep" },
    fatigue: { data: [], title: "Fatigue" },
    hydration: { data: [], title: "Hydration" },
    soreness: { data: [], title: "Soreness" },
    mood: { data: [], title: "Mood" },
    stress: { data: [], title: "Stress" },
    willingness: { data: [], title: "Willingness To Train" },
    hr: { data: [], title: "Heart Rate" },
    rpe: { data: [], title: "RPE" },
    duration: { data: [], title: "Duration" },
    bout: { data: [], title: "Bout" },
    distance: { data: [], title: "Distance" },
    parts: { data: [], title: "Parts" },
    reps: { data: [], title: "Repitition" },
    sets: { data: [], title: "Sets" },
    type: { data: [], title: "Type" },
    durationSC: { data: [], title: "Duration" },
  });
 
  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true);
        setError(false);
  
        const fetchPromises = [
          FetchResultsByField({ dept: 9, cat: 99, field: 514 }),
          FetchResultsByField({ dept: 9, cat: 99, field: 515 }),
          FetchResultsByField({ dept: 9, cat: 99, field: 516 }),
          FetchResultsByField({ dept: 9, cat: 99, field: 517 }),
          FetchResultsByField({ dept: 9, cat: 99, field: 518 }),
          FetchResultsByField({ dept: 9, cat: 99, field: 519 }),
          FetchResultsByField({ dept: 9, cat: 99, field: 520 }),
          FetchResultsByField({ dept: 9, cat: 97, field: 506 }),
          FetchResultsByField({ dept: 9, cat: 97, field: 507 }),
          FetchResultsByField({ dept: 9, cat: 97, field: 508 }),
          FetchResultsByField({ dept: 9, cat: 96, field: 505 }),
          FetchResultsByField({ dept: 9, cat: 96, field: 504 }),
          FetchResultsByField({ dept: 9, cat: 98, field: 509 }),
          FetchResultsByField({ dept: 9, cat: 98, field: 510 }),
          FetchResultsByField({ dept: 9, cat: 98, field: 511 }),
          FetchResultsByField({ dept: 9, cat: 98, field: 512 }),
          FetchResultsByField({ dept: 9, cat: 98, field: 513 }),
        ];
  
        const results = await Promise.all(fetchPromises);
  
        setDataResults({
          sleep: { data: results[0], title: "Sleep" },
          fatigue: { data: results[1], title: "Fatigue" },
          soreness: { data: results[2], title: "Soreness" },
          hydration: { data: results[3], title: "Hydration" },
          mood: { data: results[4], title: "Mood" },
          stress: { data: results[5], title: "Stress" },
          willingness: { data: results[6], title: "Willingness To Train" },
          distance: { data: results[7], title: "Distance" },
          duration: { data: results[8], title: "Duration" },
          bout: { data: results[9], title: "Bout" },
          hr: { data: results[10], title: "Heart Rate" },
          rpe: { data: results[11], title: "RPE" },
          parts: { data: results[12], title: "Parts" },
          reps: { data: results[13], title: "Repitition" },
          sets: { data: results[14], title: "Sets" },
          type: { data: results[15], title: "Type" },
          durationSC: { data: results[16], title: "S & C Duration" },
        });
  
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
  
    fetchResults();
  }, []);

 
    const openComponentInNewTab = () => {
     
     
      window.open(currentUrl+"/monitoring", "_blank");
    };

  return loading === true ? <div
  style={{
    marginTop: "100px",
    marginLeft:"240px",
    transition: "margin-left 0.3s",
    
  }}
>
  <Typography style={{cursor:"progress"}} variant="h4" color="darkviolet" textAlign="center">
    Loading......

  </Typography>
 
</div>:(
    <div>
      <div className={styles.belownav}>
       <Button appearance="primary"
        disabled={dataResults?.sleep?.data?.length <= 0}
        style={{backgroundColor:"cadetblue"}}
          className={styles.btn}
          onClick={openComponentInNewTab}>Drill</Button>
      </div>
      {error ? (
        <h6 style={{ textAlign: "center" }}>Error</h6>
      ) : loading ? (
        <h6 style={{ textAlign: "center" }}>Loading...</h6>
      ) : (
        <div className={styles.box_science_sports7}>
          <div className={styles.chartcontent}>
            <div className={styles.chartwrapper}>
              {Object.entries(dataResults).map(([key, data]) => (

                <div className={styles.chartitem} key={key}>

                  <div className={styles.chartbox}>
                    {data?.data?.length <= 0 ? (
                      <div style={{ width: "250px", height: "250px", margin: "0 auto" }}>
                        <img src={NODATA} alt="No data" />
                        <h6 style={{ textAlign: "center" }}>
                        No Records Available:{data?.title}
                        </h6>
                      </div>
                    ) : (
                      <CustomBarChart
                        data={data?.data}
                        title={data?.title}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}