import React from "react";
import styles from "./landing.module.css";
import Video from "././../../assets/image/SportsPromo.mp4"
export default function AboutUs() {
    return (


        <div>
            <div className={styles.aboutUstitleContainer}>
                <h2>Features</h2>
                <p>Our state-of-the-art platform transforms sports and rehab, empowering players, coaches, and therapists with actionable insights</p>

            </div>



           
              
                <div className={styles.imageContainer}>
                    <video width="750" height="400" controls >
                        <source src={Video} type="video/mp4" />
                    </video>

                </div>

                <h2 className={styles.headerAboutUs}>Informed Decision: Sport Science and Rehabilitation</h2>

           
        </div>
    );
}
