import React, { useEffect, useState,useContext } from "react";

import { HttpService } from "../services/http.service";
import {
  EVALUATION_RESULTS,
} from "../services/API";
import {  Typography } from "@mui/material";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Label from "../components/Label";
import { AuthContext } from "../store/AuthContext";
import jwt_decode from "jwt-decode";
export default function SomatotypeAthlete() {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const [groupedResults, setGroupedResults] = useState([]);
  const [loading, setLoading] = useState(true);
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }

  const athleteId = decode.athlete_id;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await HttpService.get(
          `${EVALUATION_RESULTS}?department=5&categories=73&athlete=${athleteId}`,{},false
        );
        const evaluationData = responses.map((response) => response.data.results).flat();

        const groupedData = {};

        evaluationData.forEach((result, index) => {
          const values = {
            [result.field_name]: [
              result.boolean_value,
              result.choice_value,
              result.date_value,
              result.float_value,
              result.integer_value,
              result.remarks,
              result.string_value,
            ].filter(Boolean),
          };

          const rowId = `${athleteId}-${result.created_at}-${index}`;

          if (!groupedData[rowId]) {
            groupedData[rowId] = {
              id: rowId,
              index,  // Add an index property
              athlete_id: athleteId,
              athlete_name: result.athlete_name, // Assuming this field is present in the response
              date: result.created_at.slice(0, 10),
              ...values,
            };
          } else {
            // Merge values for existing row
            Object.keys(values).forEach((key) => {
              if (!groupedData[rowId][key]) {
                groupedData[rowId][key] = [];
              }
              groupedData[rowId][key].push(...values[key]);
            });
          }
        });

        const processedData = Object.values(groupedData);
        setGroupedResults(processedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [athleteId]);

  const columns = [
    {
      field: "athlete_name",
      headerName: "Name",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "date",
      headerName: "Date",
      width: 100,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Inference",
      headerName: "Inference",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let TSF = parseFloat(params.row["Triceps"]);
        let SSF = parseFloat(params.row["Subscapular"]);
        let SsF = parseFloat(params.row["Supraspinale"]);
        let skinfold = TSF + SSF + SsF;
        let endo =
          -0.7182 +
          0.1451 * skinfold -
          0.00068 * Math.pow(skinfold, 2) +
          0.0000014 * Math.pow(skinfold, 3);

        let MSF = parseFloat(params.row["Medial calf"]);
        let HB = parseFloat(params.row["Biepicondylar humerus breadth"]);
        let FB = parseFloat(params.row["Biepycondylar femur breadth"]);
        let AG = parseFloat(params.row["Upper arm girth"]);
        let CG = parseFloat(params.row["Standing calf girth"]);
        let H = parseFloat(params.row["height"]);

        let CAG = AG - TSF;
        let CCG = CG - MSF;
        let meso =
          0.858 * HB + 0.601 * FB + 0.188 * CAG + 0.161 * CCG - 0.131 * H + 4.5;
        let W = parseFloat(params.row["weight"]);
        let PI = H / Math.pow(W, 1 / 3);
        let ecto = 0;

        if (PI > 40.74) {
          ecto = 0.732 * PI - 28.58;
        } else if (PI > 39.65 && PI <= 40.74) {
          ecto = 0.463 * PI - 17.615;
        } else if (PI <= 39.65) {
          ecto = 0.5;
        }
        let result = "";
        if (endo >= meso && endo >= ecto) {
          result += "Endomorphic ";
          if (meso >= ecto) {
            result += "Mesomorph";
          } else {
            result += "Ectomorph";
          }
        } else if (meso >= endo && meso >= ecto) {
          result += "Mesomorphic ";
          if (endo >= ecto) {
            result += "Endomorph";
          } else {
            result += "Ectomorph";
          }
        } else if (ecto >= endo && ecto >= meso) {
          result += "Ectomorphic ";
          if (endo >= meso) {
            result += "Endomorph";
          } else {
            result += "Mesomorph";
          }
        }
        return (
          <div className={`cellWithStatus ${result}`}>
            <Label color={"primary"}>{result}</Label>
          </div>
        );
      },
    },

    {
      field: "Somatotype",
      headerName: "Somatotype",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let TSF = parseFloat(params.row["Triceps"]);
        let SSF = parseFloat(params.row["Subscapular"]);
        let SsF = parseFloat(params.row["Supraspinale"]);
        let skinfold = TSF + SSF + SsF;
        let endo =
          -0.7182 +
          0.1451 * skinfold -
          0.00068 * Math.pow(skinfold, 2) +
          0.0000014 * Math.pow(skinfold, 3);

        let MSF = parseFloat(params.row["Medial calf"]);
        let HB = parseFloat(params.row["Biepicondylar humerus breadth"]);
        let FB = parseFloat(params.row["Biepycondylar femur breadth"]);
        let AG = parseFloat(params.row["Upper arm girth"]);
        let CG = parseFloat(params.row["Standing calf girth"]);
        let H = parseFloat(params.row["height"]);

        let CAG = AG - TSF;
        let CCG = CG - MSF;
        let meso =
          0.858 * HB + 0.601 * FB + 0.188 * CAG + 0.161 * CCG - 0.131 * H + 4.5;
        let W = parseFloat(params.row["weight"]);
        let PI = H / Math.pow(W, 1 / 3);
        let ecto = 0;

        if (PI > 40.74) {
          ecto = 0.732 * PI - 28.58;
        } else if (PI > 39.65 && PI <= 40.74) {
          ecto = 0.463 * PI - 17.615;
        } else if (PI <= 39.65) {
          ecto = 0.5;
        }
        return (
          <div
            className={`cellWithStatus ${endo.toString()} ${ecto.toString()} ${meso.toString()}`}
          >
            <Label color={"success"}>
              {[endo.toFixed(2), meso.toFixed(2), ecto.toFixed(2)].join(", ")}
            </Label>
          </div>
        );
      },
    },
    {
      field: "Endomorphy",
      headerName: "Endomorphy",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let TSF = parseFloat(params.row["Triceps"]);
        let SSF = parseFloat(params.row["Subscapular"]);
        let SsF = parseFloat(params.row["Supraspinale"]);
        let skinfold = TSF + SSF + SsF;
        let endo =
          -0.7182 +
          0.1451 * skinfold -
          0.00068 * Math.pow(skinfold, 2) +
          0.0000014 * Math.pow(skinfold, 3);

        return (
          <div className={`cellWithStatus ${endo}`}>
            <Label color={"success"}>{endo.toFixed(2)}</Label>
          </div>
        );
      },
    },

    {
      field: "Mesomorphy",
      headerName: "Mesomorphy",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let TSF = parseFloat(params.row["Triceps"]);
        let MSF = parseFloat(params.row["Medial calf"]);
        let HB = parseFloat(params.row["Biepicondylar humerus breadth"]);
        let FB = parseFloat(params.row["Biepycondylar femur breadth"]);
        let AG = parseFloat(params.row["Upper arm girth"]);
        let CG = parseFloat(params.row["Standing calf girth"]);
        let H = parseFloat(params.row["height"]);

        let CAG = AG - TSF;
        let CCG = CG - MSF;
        let meso =
          0.858 * HB + 0.601 * FB + 0.188 * CAG + 0.161 * CCG - 0.131 * H + 4.5;

        return (
          <div className={`cellWithStatus ${meso}`}>
            <Label color={"warning"}>{meso.toFixed(2)}</Label>
          </div>
        );
      },
    },
    {
      field: "Ectomorphy",
      headerName: "Ectomorphy",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let H = parseFloat(params.row["height"]);
        let W = parseFloat(params.row["weight"]);
        let PI = H / Math.pow(W, 1 / 3);
        let ecto = 0;

        if (PI > 40.74) {
          ecto = 0.732 * PI - 28.58;
        } else if (PI > 39.65 && PI <= 40.74) {
          ecto = 0.463 * PI - 17.615;
        } else if (PI <= 39.65) {
          ecto = 0.5;
        }

        return (
          <div className={`cellWithStatus ${ecto}`}>
            <Label color={"primary"}>{ecto.toFixed(2)}</Label>
          </div>
        );
      },
    },
    {
      field: "Triceps",
      headerName: "Triceps",
      sortable: true,
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "Subscapular",
      headerName: "Subscapular",
      sortable: true,
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Supraspinale",
      headerName: "Supraspinale",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Medial calf",
      headerName: "Medial calf",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Biepicondylar humerus breadth",
      headerName: "Humerus Breadth",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Biepycondylar femur breadth",
      headerName: "Femur breadth",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Upper arm girth",
      headerName: "Upper Arm Girth",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Standing calf girth",
      headerName: "Standing Calf Girth",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "height",
      headerName: "Height",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
  ];
  const groupedData = {};

  groupedResults.forEach((item) => {
    const { athlete_id, athlete_name, height, weight, date, ...fields } = item;
    if (!groupedData[date]) {
      groupedData[date] = {
        athlete_id,
        height,
        weight,
        athlete_name,
        date,
        ...fields,
      };
    } else {
      Object.keys(fields).forEach((field) => {
        if (!groupedData[date][field]) {
          groupedData[date][field] = [];
        }
        if (Array.isArray(groupedData[date][field])) {
          groupedData[date][field].push(
            ...(Array.isArray(fields[field]) ? fields[field] : [fields[field]])
          );
        } else {
          groupedData[date][field] = [groupedData[date][field]];
          groupedData[date][field].push(
            ...(Array.isArray(fields[field]) ? fields[field] : [fields[field]])
          );
        }
      });
    }
  });

  const finalGroupedResults = Object.values(groupedData);
  // console.log(finalGroupedResults)
  return (
    <div>
      <Typography variant="h6" textAlign={"center"}>
        Somatotype Outcome - Heath Carter Methodology
      </Typography>

      {loading ? (
        <Typography variant="h6" textAlign={"center"}>
          Loading...
        </Typography>
      ) :groupedResults?.length === 0 ? (
        <Typography variant="h6" textAlign={"center"}>
          No Data
        </Typography>
      ) : (
        <div
          style={{
            display: "flex",

            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          <Container
            maxWidth="lg"
            style={{ marginTop: "4rem", marginBottom: "2rem" }}
          >
            <Box key={Math.random().toLocaleString()}>
              <DataGrid
                rows={finalGroupedResults}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 50]}
                disableSelectionOnClick
                autoHeight
                getRowId={(row) => `${row.athlete_id}-${row.date}-${row.index}`}
              />
            </Box>
          </Container>
        </div>
      )}
    </div>
  );
}
