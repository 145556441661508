import React, { useState } from "react";
import styles from "./../../science.module.css";
import ROM from "./ROM";
import MMT from "./MMT";
import SpecialTest from "./SpecialTest";
import RIC from "./RIC";
import EvaluationFormView from "../../../../components/EvaluationFormView";

export default function Objective({ mode }) {
  const [open, setOpen] = useState(false);
  // const [placement, setPlacement] = useState();
  const [drawerTitle, setDrawerTitle] = useState();
  const [dept, setDept] = useState();
  const [cat, setCat] = useState();

  
  const data = [
    {
      cat: 3,
      name: "Pain Assessment",
      dept: 1,
      cardColor: "#c5cae9",
    },
    {
      cat: 4,
      name: "On Observation",
      dept: 1,
      cardColor: "#c5cae9",
    },
    { cat: 5, name: "On Palpation", dept: 1, cardColor: "#c5cae9" },
    {
      cat: 6,
      name: "Sensory Examination",
      dept: 1,
      cardColor: "#c5cae9",
    },
    {
     
      name: "Range Of Motion",
     
      cardColor: "#c5cae9",
    },
    
    {
     
      name: "Manual Muscle Testing",
    
      cardColor: "#c5cae9",
    },
   
    {
      cat: 23,
      name: "Spinal Examination",
      dept: 1,
      cardColor: "#c5cae9",
    },
    {
      cat: 24,
      name: "Resisted Isometric Contraction",
      dept: 1,
      cardColor: "#c5cae9",
    },
    
    {
      cat: 32,
      name: "Mckenzie Examination",
      dept: 1,
      cardColor: "#c5cae9",
    },
    {
      
      name: "Special Test",
     
      cardColor: "#c5cae9",
    },
    
    {
      cat: 40,
      name: "Functional Examination",
      dept: 1,
      cardColor: "#c5cae9",
    },
    {
      cat: 41,
      name: "Functional Movement Screening",
      dept: 1,
      cardColor: "#c5cae9",
    },
    { cat: 42, name: "Tightness", dept: 1, cardColor: "#c5cae9" },
  ];

  const handleOpen = ({ name, key, dept, cat }) => {
    setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
    setDept(dept);
    setCat(cat);
  };

  return (
    <>
   
      <div className={styles.sports_main_inner}>
          <select
            className={styles.dropdown}
            onChange={(e) => {
              const selectedValue = e.target.value;
              const selectedItem = data.find(
                (item) => item.name === selectedValue
              );

              if (selectedItem) {
                handleOpen({
                  name: selectedItem.name,
                  dept: selectedItem.dept,
                  cat: selectedItem.cat,
                });
              }
            }}
          >
            <option value="">{mode}</option>
            {data.map((item) => (
              <option key={item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        
      </div>

      {drawerTitle === "Range Of Motion" ? (
        <ROM mode={drawerTitle} />
      ) : drawerTitle === "Manual Muscle Testing" ? (
        <MMT mode={drawerTitle}/>
      ) :drawerTitle === "Special Test" ? (
        <SpecialTest mode={drawerTitle} />
      ) :drawerTitle === "Resisted Isometric Contraction" ? (
        <RIC mode={drawerTitle}/>
      ) :(
        <EvaluationFormView
          open={open}
          setOpen={setOpen}
          size={"lg"}
          placement={"left"}
          mode={drawerTitle}
          dept={dept}
          cat={cat}
        />
      )}
    </>
  );
}
