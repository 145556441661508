import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/AuthContext";
import {
    TEAM_API_URL,
    SUBSCRIPTION_API_URLS,
    USER_API_URL,
} from "../../services/API";
import { Typography } from "@mui/material";
import jwt_decode from "jwt-decode";
import { HttpService } from "../../services/http.service";
import { DataGrid } from '@mui/x-data-grid'; // Importing DataGrid component from Material-UI
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import { Box } from "@mui/material";

export default function Utilisation() {
    const [subscriptionData, setSubscriptionData] = useState([]);
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;

    const [data, setData] = useState([]);
    const [team, setTeam] = useState([]);
    const [adminData, setAdminData] = useState([]);

    let decode = "";
    if (token) {
        decode = jwt_decode(token);
    } else {
        decode = "";
    }

    function countRoles(data) {
        const roleCounts = {};
        data?.forEach((item) => {
            const role = item.role;
            if (role) {
                roleCounts[role] = (roleCounts[role] || 0) + 1;
            }
        });
        return roleCounts;
    }
   
    function CustomFooterStatusComponent() {
      return null
    }
    useEffect(() => {
        const fetchData = async (url, setData, bool) => {
            try {
                const response = await HttpService.get(
                    `${url}`,
                    {},
                    bool
                );
                setData(response.data.results);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(USER_API_URL, setData, true);
        fetchData(TEAM_API_URL, setTeam, false);
        fetchData(USER_API_URL + decode.user_id + "/account_details", setAdminData, true);
    }, [decode.user_id]);

    useEffect(() => {
        const fetchSubscriptionData = async () => {
            try {
                if (!token) return; // Exit if token is not available
                const response = await HttpService.get(SUBSCRIPTION_API_URLS, {}, true);
                setSubscriptionData(response.data.results);
            } catch (error) {
                console.error("Error fetching subscription data:", error);
            }
        };
        fetchSubscriptionData();
    }, [token]);

    const roleCounts = countRoles(data);

    const columns = [
        { field: 'subscriptionName', headerName: 'Subscription Name', width: 200 },
        { field: 'usersUtilized', headerName: 'Users Utilized', width: 150 },
        { field: 'adminProfilesUtilized', headerName: 'Admin Profiles Utilized', width: 200 },
        { field: 'athleteProfilesUtilized', headerName: 'Athlete Profiles Utilized', width: 200 },
        { field: 'teamsUtilized', headerName: 'Teams Utilized', width: 150 },
    ];

    const rows = subscriptionData.map((item, index) => {
        return {
            id: index,
            subscriptionName: "Basic Plan",
            usersUtilized: parseInt(item.max_users) - data.length,
            adminProfilesUtilized: item.max_admin_profiles - roleCounts["Admin"] || 0,
            athleteProfilesUtilized: item.max_athlete_profiles - roleCounts["Athlete"] || 0,
            teamsUtilized: item.max_teams - team.length,
        };
    });

    return (
       
            <div style={{ fontWeight: "bolder",  marginBottom: "30px" }}>
                {subscriptionData?.length <= 0 ?<Typography variant={"h6"} color={"red"}>Permission Denied</Typography>  :
                    <>
                        
                        <div >
                            <DataGrid rows={rows} columns={columns} slots={{
            footer: CustomFooterStatusComponent,
          }}
            />
                        </div>
                    </>
                }
            </div>
        
    );
}
