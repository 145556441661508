import React, { useEffect, useContext } from "react";
import styles from "./../sportsscience/science.module.css"
import { Button, IconButton, Modal } from "rsuite";
import UserCustomDrawer from "../../components/CustomDrawer";
import Box from "@mui/material/Box";
import { DataGrid,GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import CustomNotification from "../../components/CustomNotification";
// import { GetAdminData } from "../../components/custmomhooks/CustomHooks";
import {  Typography } from "@mui/material";
import { HttpService } from "../../services/http.service";
import SampleAdminData from "./SampleAdminData";
import { ADMIN_API_URL, BASE_URL } from "../../services/API";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import EditAdminProfile from "./EditAdminProfile";
import DeleteAdminProfile from "./DeleteAdminProfile";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../store/AuthContext";
import { Color } from "../../utils/Colors";

export default function AdminProfile() {
  // const [isSidebarExpanded, setIsSidebarExpanded] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [imageUrlMap, setImageUrlMap] = React.useState({});
  const [mode, setMode] = React.useState(null);
  const [adminID, setAdminID] = React.useState();
  const [emailId, setEmailID] = React.useState()
  const [open, setOpen] = React.useState(false);
  // const [placement, setPlacement] = React.useState();
  const [adminData, setAdminData] = React.useState([]);
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1); // Total number of pages
  const [isButtonEnabled, setIsButtonEnabled] = React.useState(false);
  const [isButtonEnabled1, setIsButtonEnabled1] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState();
  const [filter, setFilter] = React.useState(null);
  const [csv, setCsv] = React.useState();
  const [count, setCount] = React.useState(0)
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const decode = token ? jwt_decode(token) : "";
  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  useEffect(() => {
    const delay = setTimeout(() => setIsButtonEnabled(true), 30000);
    return () => clearTimeout(delay);
  }, []);
  useEffect(() => {
    const delay = setTimeout(() => setIsButtonEnabled1(true), 35000);
    return () => clearTimeout(delay);
  }, []);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("csv_file", selectedFile);

    try {
      const response = await HttpService.post(
        ADMIN_API_URL,
        { "Content-Type": "multipart/form-data" },
        formData, false
      );

      if (response.data.error === false) {
        alert("Upload Successful");
      } else {
        // Display error message from the server if available
        const errorMessage = response.data.message || "Failure: Not Uploaded!!";
        alert(errorMessage);
      }
    } catch (error) {
      // Handle network or other errors
      console.warn(error);
      alert("An error occurred during the upload. Please try again.");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${ADMIN_API_URL}?page=${currentPage}`, {}, false
        );
        setAdminData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setAdminData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
    count
  ])


  useEffect(() => {
    if (adminData.length > 0) {
      const fetchImageUrls = async () => {
        const promises = adminData.map(async (item) => {
          try {
            const baseURL = BASE_URL(decode.account);
            const mediaURL = `${baseURL}/media`;
            const imageUrl = `${mediaURL}${item.profile_photo_url}`;

            const response = await fetch(imageUrl, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });

            if (response.ok) {
              const blob = await response.blob();
              const objectURL = URL.createObjectURL(blob);
              return { [item.id]: objectURL };
            } else {
              throw new Error('Network response was not ok.');
            }
          } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            return { [item.id]: null };
          }
        });

        const imageUrlMap = await Promise.all(promises);
        const mergedImageUrlMap = Object.assign({}, ...imageUrlMap);
        setImageUrlMap(mergedImageUrlMap);
      };
      fetchImageUrls()
    }

  }, [adminData, decode.account, token]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1); // Page is 0-based, API expects 1-based
  };
  const handleOpen2 = (id, status, mode, email) => {
    setAdminID(id);
    setEditModal(status);
    setMode(mode);
    setEmailID(email);

  };
  const handleClose2 = () => {
    setAdminID(null);
    setEditModal(false);
    setMode(null);
    setEmailID(null)
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "profile_photo_url",
      headerClassName: 'super-app-theme--header',
      headerName: "Image",
      width: 100,
      renderCell: (params) => (
        imageUrlMap[params.row.id] ?
          <img src={imageUrlMap[params.row.id]} alt="Profile" height={50} width={50} style={{ borderRadius: "50%" }} /> :
          null
      ),
    },

    {
      field: "designation",
      headerName: "Designation",
      width: 150,
      editable: true,
      headerAlign: "center",
      headerClassName: 'super-app-theme--header',
      align: "center",
    },
    {
      field: "email",
      headerName: "E-mail",
      type: "number",
      width: 300,
      editable: true,
      headerAlign: "center",
      headerClassName: 'super-app-theme--header',
      align: "center",
    },
    {
      field: "department",
      headerName: "Department",
      sortable: true,
      width: 250,
      headerAlign: "center",
      headerClassName: 'super-app-theme--header',
      align: "center",
    },
    {
      field: "action",
      headerName: "Edit",
      sortable: true,
      width: 150,
      headerAlign: "center",
      headerClassName: 'super-app-theme--header',
      align: "center",
      renderCell: (params) => (
        <IconButton
          onClick={() => handleOpen2(params.row.id, true, "edit")}
          color="violet"
          appearance="primary"
          icon={<EditIcon />}
        ></IconButton>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <IconButton
          onClick={() => handleOpen2(params.row.id, true, "delete", params.row.email)}
          color="red"
          appearance="primary"
          icon={<TrashIcon />}
        ></IconButton>
      ),
    },
  ];
  
  return (
    <>


      <div >
     <div>

          <div className={styles.belownav}>
            <Button
              appearance="primary"
              onClick={() => console.log("clicked")}
              className={styles.btn}
              style={{ background: "cadetblue" }}

            >
              Upload Bulk
            </Button>
           <Button

              appearance="primary"
              onClick={()=>setOpen(true)}
              className={styles.btn}
              style={{ background: "cadetblue" }}

            >  
              Create Admin
            </Button> 
          </div>
          {filter === 1 ? (
            <div
              style={{
                // marginLeft: isSidebarExpanded ? "15rem" : "5rem",
                marginBottom: 20,
                marginTop: 30,
              }}
            >
              <SampleAdminData />
              <br />

              <input type="file" accept=".csv" onChange={fileSelectedHandler} />
              {selectedFile === undefined ? null : (
                <Button
                  color="red"
                  appearance="primary"
                  onClick={() => handleSubmit()}
                >
                  Bulk Upload
                </Button>
              )}
              <br />
            </div>
          ) : null}
        </div>

        <div
          className={styles.tablular}
        >
          {adminData === undefined ? (
            <>
              <Typography>No data</Typography>
              {error?.response?.status === 200 ? null : (
                <CustomNotification
                  open={openModal}
                  setOpen={setOpenModal}
                  message={
                    error?.response?.status === 200 ? "Loading" : error?.message
                  }
                  title={error?.response?.status === 200 ? "Loading" : "Error"}
                />
              )}
            </>
          ) : (
            <Box>
              <DataGrid
                sx={{

                  '& .super-app-theme--header': {
                    color: Color.Color_C

                  },
                }}
                slots={{
                  toolbar: CustomToolbar,
                }}
                getRowId={() => Math.random().toLocaleString()}
                rows={adminData}
                columns={columns}
                pagination
                paginationMode="server"
                rowCount={totalPages * 10} // Total number of rows (assuming 10 per page)
                onPageChange={handlePageChange} // Handle page changes
                pageSize={10} // Number of rows per page
                page={currentPage - 1} // Current page (0-based)
                paginationComponentProps={{
                  // Customize the appearance of the pagination component
                  disableNext: currentPage === totalPages,
                  disablePrev: currentPage === 1,
                }}
              />
            </Box>
          )}
        </div>
      </div>

      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={"right"}
        mode={"Admin"}
        setCount={setCount}
      />
      <Modal size="lg" open={editModal} onClose={handleClose2}>
        {mode === "edit" ? (
          <EditAdminProfile id={adminID} />
        ) : mode === "delete" ? (
          <DeleteAdminProfile id={adminID} email={emailId} onSucess={handleClose2} />
        ) : null}
      </Modal>

    </>
  );
}
