import React, { useState } from "react";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import { SelectPicker, Button, Divider } from "rsuite";
import { Card, CardContent, Typography } from "@mui/material";
import StrengthTrainingTemplate from "./StrengthTraining";
import Rehabilitation from "./Rehabilitation";
import DietPlanning from "./DietPlanning";
import PsychologyWellnessTemplate from "./PsychologyWellnessTemplate";
import Sidebar from "../../components/CustomSideNav";
export default function Template() {
  const [type, setType] = useState(null);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
 

  const handleToggle = (selectedType) => {
    const numericType = parseInt(selectedType, 10);

    if (numericType === 1) {
      setType(1);
    } else if (numericType === 2) {
      setType(2);
    } else if (numericType === 3) {
      setType(3);
    } else if (numericType === 4) {
      setType(4);
    } else if (numericType === 5) {
      setType(5);
    } else if (numericType === 6) {
      setType(6);
    }
  };

  const template = [
    {
      value: 1,
      label: "Strength & Conditioning",
    },
    {
      value: 2,
      label: "Rehabilitation",
    },
    {
      value: 3,
      label: "Diet Planning",
    },
    {
      value: 4,
      label: "Psychology & Wellness Counselling",
    },
    {
      value: 5,
      label: "Return To Sports",
    },
  ];
  const handleSidebarToggle = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };
  return (
    <>
      <DashBoardTopNav />
      <Sidebar onToggle={handleSidebarToggle} expanded={isSidebarExpanded} />

      <Divider style={{border:"2rem",marginTop:-8}}/>
      <br />
      <div  style={{
            margin: "1rem",
            transition: "margin-left 0.3s",
            marginLeft: isSidebarExpanded ? "15rem" : "5rem",
          }}>

<Card
        sx={{
          // height: "8rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "2rem",
          marginRight: "1rem",
          marginLeft: "1rem",
          border: "0.1px solid black",

          backgroundColor: "white",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent>
          <Typography variant="h6">Builder 🛠️</Typography>
          <Typography variant="body">
            Welcome to our comprehensive wellness hub, where fitness meets
            personalized care. Explore tailored Strength & Conditioning,
            Rehabilitation, and Diet Planning templates, coupled with expert
            Psychology and Wellness Counselling.
          </Typography>
        </CardContent>
      </Card>
      {type === null ? null : (
        <Button
          appearance="primary"
          color="cyan"
          onClick={() => setType(null)}
          style={{ width: 200, margin: "1rem" }}
        >
          Reset
        </Button>
      )}
      <br />
      {type === null ? (
        <SelectPicker
          data={template}
          value={type} // Use the 'type' state as the value here
          onSelect={(selectedValue) => handleToggle(selectedValue)} // Pass the selected value to handleToggle
          placeholder="Select Template"
          style={{ width: 200, marginTop: "0.5rem", marginLeft: "1rem" }}
        />
      ) : type === 1 ? (
        <StrengthTrainingTemplate />
      ) : type === 2 ? (
        <Rehabilitation />
      ) : type === 3 ? (
        <DietPlanning />
      ) : type === 4 ? (
        <PsychologyWellnessTemplate />
      ) : (
        <Typography variant="h6" textAlign={"center"}>
          Working on it...!!
        </Typography>
      )}
          </div>
    
    </>
  );
}
