import React from "react";
import { Link } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import BarChartIcon from '@mui/icons-material/BarChart';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SummarizeIcon from '@mui/icons-material/Summarize';
import LineAxisIcon from '@mui/icons-material/LineAxis';
// import AlbumIcon from '@mui/icons-material/Album';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import { Color } from "../utils/Colors";
import Brand from "./../assets/image/brand2.png";
const SidebarItem = ({ to, icon, label, onClick }) => (
  
  <ListItem
    sx={{
      display: 'flex',
      flexDirection: 'column',
      color: Color.Color_AA,
      alignItems: 'center',
      justifyContent: 'center',
      "&:hover": {
       
        color: Color.Color_AA, // Background color on hover
        cursor: 'pointer', // Change cursor to pointer on hover
      },
    }}
    component={Link}
    to={to}
    onClick={onClick}
  >
    <ListItemIcon sx={{ color: Color.Color_AA, alignItems: 'center', justifyContent: 'center' , "&:hover": {
       
       color: Color.Color_AA, // Background color on hover
       cursor: 'pointer', // Change cursor to pointer on hover
     },}}>
      {icon}
    </ListItemIcon>
    <label style={{ fontSize: 8 }}>{label}</label>
  </ListItem>
);


const Sidebar = () => {
  
  const sidebarItems = [
    { to: "/dashboard",icon: <img src={Brand} style={{ width: 30, height: 30 }} alt="brand" />},
    { to: "/dashboard", icon: <DashboardIcon />, label: "Dashboard" },
    { to: "/profile_management", icon: <AdminPanelSettingsIcon />, label: "Profile" },
    
    { to: "/sportscience", icon: <SportsBasketballIcon />, label: "Evaluation" },
    { to: "/sports_science_dashboard", icon: <SummarizeIcon />, label: "Overview" },
    { to: "/story_telling", icon: <BarChartIcon />, label: "Plotting" },
    { to: "/event/setup", icon: <EmojiEventsIcon />, label: "Event" },
    { to: "/logs", icon: <TroubleshootIcon />, label: "Metrics" },
    { to: "/overall", icon: <LineAxisIcon />, label: "Overall" },
    // { to: "/periodisation", icon: <AlbumIcon />, label: "Periodisation" },
    { to: "/biomechanics", icon: <ForkRightIcon />, label: "Biomechanics" },


    
    
    

  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 50,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 50,
          // marginTop: "52px",
          boxSizing: 'border-box',
          backgroundColor: Color.Color_C,
   overflowX:"unset",overflowY:"unset"
        },
      }}
    >
      <List sx={{ display: 'flex', flexDirection: 'column' }}>
        {sidebarItems.map((item, index) => (
          <SidebarItem key={index} {...item} />
        ))}
       
      </List>
    </Drawer>
  );
};

export default Sidebar;
