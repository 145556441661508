import React, { useEffect, useState, useContext } from "react";
import { HttpService } from "../../services/http.service";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import PsychologyIcon from "@mui/icons-material/Psychology";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HealingIcon from "@mui/icons-material/Healing";
import BoyIcon from "@mui/icons-material/Boy";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { ATHLETE_PROFILE_API_URL, USER_API_URL } from "../../services/API";
import { Typography } from "@mui/material";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Chart from "chart.js/auto";
// import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Label from "../../components/Label";
import {  Loader } from "rsuite";
import Papa from "papaparse";
import Sidebar from "../../components/CustomSideNav";
import Summary from "./Summary";
import PainMonitoring from "./PainMonitoring";
import Progression from "./Progression";
import Statistics from "./Statistics";
import GroupAnalytics from "./GroupAnalytics";
import CompletDashboard from "./CompletDashboard";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import styles from "./injury.module.css";
import SCProfiling from "./SCProfiling";
import AnthropometryProfiling from "./AnthropometryProfiling";
import PsychologyProfiling from "./PsychologyProfiling";
import BiomechanicsProfiling from "./BiomechanicsProfiling";
import ExercisePhysiologyProfiling from "./ExercisePhysiologyProfiling";
import NutritionProfiling from "./NutritionProfiling";
import {  Line } from "react-chartjs-2";
// import { Color } from "../../utils/Colors";
// import GroupIndividualStats from "./GroupIndividualStats";
Chart.register(CategoryScale);

export default function Dashboard() {
  const [user, setUser] = useState([]);
  const [selectedItem, setSelectedItem] = useState(1);
  const [filter, setFilter] = useState(1);
  const [chartData, setChartData] = useState({});
  const [info, setInfo] = useState([]);
  const [athlete, setAthlete] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null); // Add an error state
  const [loading, setLoading] = useState(true);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${USER_API_URL}${decode.user_id}/`,
          {},
          true
        );

        setInfo(response.data);
        setLoading(false);
      } catch (error) {
        setError(error); // Handle errors properly
        setLoading(false);
      }
    };

    fetchData();
  }, [decode.user_id]);
  useEffect(() => {
    const fetchData = async (url, setData, bool) => {
      try {
        const response = await HttpService.get(
          `${url}?page=${currentPage}`,
          {},
          bool
        );
        setData(response.data.results);
        setError(null); // Reset error state on successful fetch
      } catch (error) {
        setError(error); // Set error state on fetch error
      }
    };

    fetchData(USER_API_URL, setUser, true);
    fetchData(ATHLETE_PROFILE_API_URL, setAthlete, false);
  }, [currentPage]);
  // console.log(error);
  const roleCounts = countRoles(user);
  const genderCounts = countGender(athlete);
  const injuryCounts = countInjury(athlete);

  function countRoles(data) {
    const roleCounts = {};
    data?.forEach((item) => {
      const role = item.role;
      if (role) {
        roleCounts[role] = (roleCounts[role] || 0) + 1;
      }
    });
    return roleCounts;
  }

  function countGender(data) {
    const genderCounts = {};
    data?.forEach((item) => {
      const gender = item.gender;
      if (gender) {
        genderCounts[gender] = (genderCounts[gender] || 0) + 1;
      }
    });
    return genderCounts;
  }

  function countInjury(data) {
    const injuryCounts = {};
    data?.forEach((item) => {
      const injury = item.injury_status;
      if (injury !== undefined) {
        const injuryString = injury ? "Yes" : "No";
        injuryCounts[injuryString] = (injuryCounts[injuryString] || 0) + 1;
      }
    });
    return injuryCounts;
  }

  function calculateMeanHeight(data) {
    const heights = data?.map((item) => parseFloat(item.height));
    const totalHeight = heights.reduce((acc, height) => acc + height, 0);
    return (totalHeight / heights.length).toFixed(2);
  }

  function calculateMeanWeight(data) {
    const weights = data?.map((item) => parseFloat(item.weight));
    const totalWeight = weights.reduce((acc, weight) => acc + weight, 0);
    return (totalWeight / weights.length).toFixed(2);
  }

  function calculateMeanAge(data) {
    const ages = data?.map((item) => {
      const dob = new Date(item.date_of_birth);
      const ageDiff = Date.now() - dob.getTime();
      const ageDate = new Date(ageDiff);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    });

    const totalAge = ages.reduce((acc, age) => acc + age, 0);
    return (totalAge / ages.length).toFixed(2);
  }

  const meanHeight = calculateMeanHeight(athlete);
  const meanWeight = calculateMeanWeight(athlete);
  const meanAge = calculateMeanAge(user);
  const userColumns = [
    {
      field: "first_name",
      headerName: "Name",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "is_active",
      headerName: "Active Status",
      width: 100,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.is_active}`}>
            <Label color={params.row.is_active === true ? "success" : "error"}>
              {params.row.is_active === true ? "Yes" : "No"}
            </Label>
          </div>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",

      width: 200,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "email",
      headerName: "Email",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
  ];

  function handleExportCSV() {
    const csvData = Papa.unparse(user); // Convert data to CSV format using PapaParse
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "exported_data.csv";
    a.click();
    URL.revokeObjectURL(url);
  }

  const data = [
    { id: 1, title: "Total User", count: user.length },
    { id: 2, title: "Total Roles", count: Object.keys(roleCounts).length },
    { id: 3, title: "Mean Height", count: meanHeight },
    { id: 4, title: "Mean Weight", count: meanWeight },
    { id: 5, title: "Mean Age", count: meanAge },
    {
      id: 6,
      title: "Athlete Injured",
      count: injuryCounts["Yes"],
    },
    { id: 7, title: "Male Athletes", count: genderCounts["Male"] || 0 },
    { id: 8, title: "Female Athletes", count: genderCounts["Female"] || 0 },
  ];

  const data3 = [
    { id: 1, title: "Total User", count: user.length },
    // { id: 2, title: "Total Roles", count: Object.keys(roleCounts).length },
    { id: 3, title: "Admin", count: roleCounts["Admin"] || 0 },
    { id: 4, title: "Athlete", count: roleCounts["Athlete"] || 0 },

    { id: 7, title: "Male Athletes", count: genderCounts["Male"] || 0 },
    { id: 8, title: "Female Athletes", count: genderCounts["Female"] || 0 },
  ];
  const injuryData = [
    {
      id: 5,
      title: "Athlete Non - Injured",
      count: injuryCounts["No"],
      total:roleCounts["Athlete"] || 0,
      color:"green"
    },
    {
      id: 6,
      title: "Athlete Injured",
      count: injuryCounts["Yes"],
      total:roleCounts["Athlete"] || 0,
      color:"red"
    },
    
  ];
  const columns = [
    { field: "title", headerName: "Title", width: 200 },
    { field: "count", headerName: "Count", width: 200 },
  ];

  function countSports(data) {
    const sportCount = {};
    data?.forEach((item) => {
      const sport = item.sports;
      if (sport) {
        sportCount[sport] = (sportCount[sport] || 0) + 1;
      }
    });
    return sportCount;
  }

  const sportsCounts = countSports(athlete);

  const data4 = [
    { id: 1, title: "Archery", count: sportsCounts["Archery"] || 0 ,color:"#ffcdd2"},
    { id: 2, title: "Athletics", count: sportsCounts["Athletics"] || 0,color:"#f8bbd0" },
    { id: 3, title: "Cricket", count: sportsCounts["Cricket"] || 0,color:"#e1bee7" },
    { id: 4, title: "Badminton", count: sportsCounts["Badminton"] || 0,color:"#b39ddb" },
    { id: 5, title: "Boxing", count: sportsCounts["Boxing"] || 0,color:"#9fa8da" },
    { id: 6, title: "Swimming", count: sportsCounts["Swimming"] || 0,color:"#90caf9" },
    { id: 7, title: "Shooting", count: sportsCounts["Shooting"] || 0,color:"#4fc3f7" },
    { id: 8, title: "Table Tennis", count: sportsCounts["Table Tennis"] || 0,color:"#4dd0e1" },
    { id: 9, title: "Lawn Tennis", count: sportsCounts["Lawn Tennis"] || 0,color:"#4db6ac" },
    {
      id: 10,
      title: "Weightlifting",
      count: sportsCounts["Weightlifting"] || 0,color:"#a5d6a7",
    },
    { id: 11, title: "Wrestling", count: sportsCounts["Wrestling"] || 0,color:"#69f0ae" },
    { id: 12, title: "Football", count: sportsCounts["Football"] || 0,color:"#ffb74d" },
  ];

  const countUsersByCreationDateAndRole = (users, roleFilter) => {
    const filteredUsers = roleFilter
      ? users.filter((user) => user.role === roleFilter)
      : users;

    const userCounts = [];

    filteredUsers.forEach((user) => {
      const creationDate = user.created;
      const existingEntry = userCounts.find(
        (entry) => entry.label === creationDate
      );

      if (existingEntry) {
        existingEntry.data++;
      } else {
        userCounts.push({ label: creationDate, data: 1 });
      }
    });

    return userCounts;
  };

  useEffect(() => {
    // Get user counts for all users
    const userCounts = countUsersByCreationDateAndRole(user);

    // Get user counts for athletes
    const athleteCounts = countUsersByCreationDateAndRole(user, "Athlete");

    // Get user counts for admins
    const adminCounts = countUsersByCreationDateAndRole(user, "Admin");

    // Extract labels and data for the chart
    const labels = userCounts.map((entry) => entry.label);
    const userData = userCounts.map((entry) => entry.data);
    const athleteData = athleteCounts.map((entry) => entry.data);
    const adminData = adminCounts.map((entry) => entry.data);

    // Set chart data
    setChartData({
      labels: labels,
      datasets: [
        {
          label: "User Creation Count",
          data: userData,
          borderColor: "#ce93d8",
          backgroundColor: "#ce93d8",
          borderWidth: 2,
          fill: false,
          pointRadius: 5,
          tension:"0.5"

        },
        {
          label: "Athlete Creation Count",
          data: athleteData,
          borderColor: "green",
          borderWidth: 2,
          fill: true,
          backgroundColor: "green",
          pointRadius: 5,
          tension:"0.5"
        },
        {
          label: "Admin Creation Count",
          data: adminData,
          borderColor: "orange",
          borderWidth: 2,
          backgroundColor: "orange",
          fill: true,
          pointRadius: 5,
          tension:"0.5"
        },
      ],
      options: {
        scales: {
          x: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Date', // Label for the x-axis
              font: {
                size: 16,
                weight: 'bold'
              }
            }
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Value', // Label for the y-axis
              font: {
                size: 16,
                weight: 'bold'
              }
            }
          }
        },
        plugins: {
          title: {
            display: true,
            text: "User Growth Over Time",
          },
          legend: {
            display: true,
          },
        },

        maintainAspectRatio: false,
        responsive: true,
      },
    });
  }, [user]);

  const filtersdata = [
    { title: "Summary", icon: <SummarizeIcon />, filter: 1 },
    { title: "Monitoring", icon: <LegendToggleIcon />, filter: 2 },
    { title: "Injury Profiling", icon: <HealingIcon />, filter: 3 },
    { title: "Training Progression", icon: <RotateRightIcon />, filter: 4 },
    {
      title: "Individual Statistics",
      icon: <DataThresholdingIcon />,
      filter: 5,
    },
    { title: "Group Statistics", icon: <AnalyticsIcon />, filter: 6 },
    {
      title: "Strength & Conditioning",
      icon: <FitnessCenterIcon />,
      filter: 7,
    },
    { title: "Anthropometry", icon: <BoyIcon />, filter: 8 },
    { title: "Psychology", icon: <PsychologyIcon />, filter: 9 },
    { title: "Nutrition", icon: <RestaurantIcon />, filter: 10 },
  ];
  const handleFilterClick = (filter) => {
    setFilter(filter);
    setSelectedItem(filter);
  };
 
  return (
    <div>
      <DashBoardTopNav header={"Dashboard"}/>
      <Sidebar />

     
        <div className={styles.box_science_sports}>
          {filtersdata.map(({ title, icon, filter }, index) => (
            <label
              style={{ justifyContent: "space-between" }}
              className={`${styles.labelItem_sports} ${
                filter === selectedItem ? styles.selected : ""
              }`}
              key={index}
              onClick={() => handleFilterClick(filter)}
            >
              {title}
              {icon}
            </label>
          ))}
        </div>
      

      {filter === 1 ? (
        user?.length > 0 || athlete?.length > 0 ? (
          <div className={styles.download}>
           
           

            <div>
              <CompletDashboard
                tableData={data}
                data={data3}
                data2={data4}
                label={"Summary"}
                label2={"Number of Athletes Per Sports"}
                tableColumn={columns}
                user={user}
                userColumns={userColumns}
                densitydata={chartData}
                injuryData={injuryData}
                downloadData = {handleExportCSV}
                LineChart = {  <Line data={chartData} height={"120px"}/>}
              />
            </div>
            {/* <Divider /> */}

           
          </div>
        ) : loading === true ? (
          <Loader
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "8rem",
            }}
            size="lg"
            content={"🌀 Loading..."}
          />
        ) : (
          <h2
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "8rem",
            }}
          >
            No Data
          </h2>
        )
      ) : filter === 2 ? (
        <Summary />
      ) : filter === 3 ? (
        <PainMonitoring />
      ) : filter === 4 ? (
        <Progression />
      ) : filter === 5 ? (
        <Statistics />
      ) : filter === 6 ? (
        <GroupAnalytics />
      ) : filter === 7 ? (
        <SCProfiling />
      ) : filter === 8 ? (
        <AnthropometryProfiling />
      ) : filter === 9 ? (
        <PsychologyProfiling />
      ) : filter === 12 ? (
        <BiomechanicsProfiling />
      ) : filter === 11 ? (
        <ExercisePhysiologyProfiling />
      ) : filter === 10 ? (
        <NutritionProfiling />
      ) : null}
    </div>
  );
}
