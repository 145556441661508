import { createContext, useState } from "react";
import jwt_decode from "jwt-decode";
import { HttpService } from "../services/http.service";
import { LOGOUT_API_URL } from "../services/API";

export const AuthContext = createContext({
  token: "",
  role: "",
  isAuthenticated: false,
  authenticate: (token) => {},
  logout: () => {},
  // timerValue: 0,
 
});

function AuthContextProvider({ children }) {
  const [authToken, setAuthToken] = useState(() => localStorage.getItem("token"));
  const [authRole, setAuthRole] = useState("");
  // const [timerValue, setTimerValue] = useState(900);

//   useEffect(() => {
//     let inactivityTimer;
//     let startTime; 
//     function startInactivityTimer() {
//         startTime = Date.now();
//         inactivityTimer = setInterval(() => {
//             const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
//             const remainingTime = Math.max(900 - elapsedTime, 0); // Maximum time is 900 seconds (15 minutes)
//             setTimerValue(remainingTime);
//             if (remainingTime === 0) {
//                 logout(); // Logout when the timer reaches 0
//                 clearInterval(inactivityTimer); // Stop the timer after logout
//             }
//         }, 1000); // Update timer value every second
//     }

//     function resetInactivityTimer() {
//         clearInterval(inactivityTimer);
//         setTimerValue(900); // Reset timer to 15 minutes when activity detected
//         startInactivityTimer()
//     }

//     if (authToken) {
//         startInactivityTimer(); // Start the timer whenever there's activity
//     }

//     const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
//     events.forEach(event => {
//         window.addEventListener(event, resetInactivityTimer);
//     });

//     return () => {
//         clearInterval(inactivityTimer);
//         events.forEach(event => {
//             window.removeEventListener(event, resetInactivityTimer);
//         });
//     };
// }, [authToken]);


  async function authenticate(token, refreshToken) {
    // console.log(refreshToken,"ds")
    setAuthToken(token);
    HttpService.accessToken = token;

    const decoded = jwt_decode(token);
    const roles = JSON.stringify(decoded.roles);
    setAuthRole(roles);

    localStorage.setItem("token", token);
    // localStorage.setItem("refreshToken", refreshToken);
  }

  async function logout() {
    setAuthToken(null);
    setAuthRole(null);

   
    try {
      await HttpService.post(LOGOUT_API_URL);
     
      window.history.pushState(null, "", "/");
      window.addEventListener("popstate", () => {
        window.history.forward();
      });
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.clear();
      
      
    
    } catch (error) {
      console.error("Logout error:", error);
    }
  }

  const value = {
    role: authRole,
    token: authToken,
    isAuthenticated: !!authToken,
    authenticate,
    logout,
    // timerValue,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
