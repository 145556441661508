import React, { useState, useEffect } from "react";
import { Button, List, Modal, IconButton, SelectPicker, Loader } from "rsuite";
import { Typography, Tooltip, Card, CardContent } from "@mui/material";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import TrashIcon from "@rsuite/icons/Trash";
import { ATHLETE_PROFILE_API_URL } from "../../services/API";
import { HttpService } from "../../services/http.service";
import {
  Cereals,
  Fats,
  Milk_Milk_Products,
  Vegetables,
  VegetablesCooked,
  Fruits,
  PusleUncooked,
  NonVeg,
  Desserts,
  Drinks,
  FastFood,
} from "../storytelling/NutritionList";
const ExerciseCard = ({
  exerciseList,
  show,
  setShow,
  type,
  exerciseSelected,
  setExerciseSelected,
  exerciseCart,
  setExerciseCart,
  addToCartModalVisible,
  setAddToCartModalVisible,
  exerciseCartModalVisible,
  setExerciseCartModalVisible,
  selectedIndices,
  setSelectedIndices,
  clearExerciseCart,
  handleRemoveFromCart,
}) => {
  const [athletes, setAthletes] = useState([]);
  const [athlete, setAthlete] = useState([]);
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(ATHLETE_PROFILE_API_URL);

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);
  const handleShowMore = () => {
    setShow(show + 20);
  };

  const handleShowLess = () => {
    setShow(20);
  };

  const handleExercise = (selectedItem, index) => {
    const indexInArray = selectedIndices.indexOf(index);

    // If the index is in the array, remove it; otherwise, add it
    if (indexInArray !== -1) {
      const updatedIndices = [...selectedIndices];
      updatedIndices.splice(indexInArray, 1); // Remove the index from the array
      setSelectedIndices(updatedIndices);
    } else {
      setSelectedIndices([...selectedIndices, index]);
    }
    setExerciseSelected(selectedItem);
    setAddToCartModalVisible(true);
  };

  const addToExerciseCart = (selectedItem, household, wt, calories) => {
    setExerciseCart((prevExerciseCart) => {
      const updatedCart = [
        ...prevExerciseCart,
        {
          exercise: selectedItem,
          household_measures: household,
          weight: wt,
          calories: calories,
        },
      ];
      return updatedCart;
    });
    setAddToCartModalVisible(false);
  };

  const showExerciseCart = () => {
    setExerciseCartModalVisible(true);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ margin: "2rem" }}>
        {exerciseCart?.length <= 0 ? null : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              icon={<RemoveShoppingCartIcon />}
              appearance="primary"
             
              color="red"
              onClick={clearExerciseCart}
            >
              Clear Diet Cart
            </Button>

            {/* <div style={{ position: "relative", display: "inline-block" }}>
              <Tooltip title="Show Diet Cart" placement="top-start">
                <IconButton
                  icon={<ShoppingCartIcon />}
                  appearance="primary"
                  size="xs"
                  color="cyan"
                  onClick={showExerciseCart}
                />
              </Tooltip>

              <div
                style={{
                  position: "absolute",
                  top: "-10px",
                  left: "16px",
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "4px 8px",
                  fontSize: "12px",
                }}
              >
                {exerciseCart.length}
              </div>
            </div> */}
          </div>
        )}
        {athlete?.length < 0 ? (
          <Loader size="xs" content="Loading...!!" />
        ) : (
          <div style={{ marginTop: "2rem" }}>
            <SelectPicker
              label="Select Athlete"
              data={athletes}
              value={athlete}
              onSelect={(value) => setAthlete(value)}
              style={{ width: 260 }}
            />
          </div>
        )}
        {exerciseList.slice(0, show).map((item, index) => (
          <Card
            key={index}
            sx={{
              marginTop: "1rem",
              width: "40rem",
            }}
          >
            <CardContent
              onClick={() => handleExercise(item, index)}
              style={{
                backgroundColor: selectedIndices.includes(index)
                  ? type === 1
                    ? "lightgreen"
                    : type === 2
                    ? "pink"
                    : type === 3
                    ? "yellow"
                    : type === 4
                    ? "skyblue"
                    : type === 5
                    ? "orange"
                    : type === 6
                    ? "lightgreen"
                    : type === 7
                    ? "pink"
                    : type === 8
                    ? "yellow"
                    : type === 9
                    ? "skyblue"
                    : type === 10
                    ? "orange"
                    : "violet"
                  : "transparent",
                cursor: "pointer",
                fontWeight: "bold", // Add cursor style to indicate clickable items
              }}
            >
              {type === 1
                ? item["Cereals"]
                : type === 2
                ? item["Fats"]
                : type === 3
                ? item["Milk & Milk Products"]
                : type === 4
                ? item["Vegetables Cooked"]
                : type === 5
                ? item["Fruits"]
                : type === 6
                ? item["Pulse [uncooked]"]
                : type === 7
                ? item["Flesh Foods"]
                : type === 8
                ? item["Vegetables"]
                : type === 9
                ? item["Drinks"]
                : type === 10
                ? item["Fast Food"]
                : type === 11
                ? item["Desserts"]
                : null}
            </CardContent>
          </Card>
        ))}
        {exerciseList.length > 21 ? (
          <>
            {show < exerciseList.length ? (
              <Button
                style={{ margin: "2rem" }}
                appearance="ghost"
                onClick={handleShowMore}
              >
                Show More
              </Button>
            ) : (
              <Button
                style={{ margin: "2rem" }}
                appearance="ghost"
                onClick={handleShowLess}
              >
                Show Less
              </Button>
            )}
          </>
        ) : null}
      </div>

      <Modal
        open={addToCartModalVisible}
        onClose={() => setAddToCartModalVisible(false)}
      >
        <Modal.Header>
          <Modal.Title>Add Diet to Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to add{" "}
          {exerciseSelected
            ? type === 1
              ? exerciseSelected["Cereals"]
              : type === 2
              ? exerciseSelected["Fats"]
              : type === 3
              ? exerciseSelected["Milk & Milk Products"]
              : type === 4
              ? exerciseSelected["Vegetables Cooked"]
              : type === 5
              ? exerciseSelected["Fruits"]
              : type === 6
              ? exerciseSelected["Pulse [uncooked]"]
              : type === 7
              ? exerciseSelected["Flesh Foods"]
              : type === 8
              ? exerciseSelected["Vegetables"]
              : type === 9
              ? exerciseSelected["Drinks"]
              : type === 10
              ? exerciseSelected["Fast Food"]
              : type === 11
              ? exerciseSelected["Desserts"]
              : null
            : null}{" "}
          to the diet cart?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => addToExerciseCart(exerciseSelected)}
            appearance="primary"
          >
            Add to Cart
          </Button>
          <Button
            onClick={() => setAddToCartModalVisible(false)}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to show selected exercises */}
      {exerciseCart?.length <= 0 ? null : (
        <Card
          sx={{
            width: "60rem",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
            marginRight: "1rem",
            marginLeft: "1rem",
            marginTop: "8rem",
            // border: "0.1px solid black",
            backgroundColor: "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography>Diet Cart</Typography>

          <CardContent>
            {exerciseCart?.map((cartItem, index) => (
              <List key={index}>
                <List.Item style={{ fontWeight: "bold" }}>
                  {cartItem.exercise["Cereals"] ||
                    cartItem.exercise["Fats"] ||
                    cartItem.exercise["Milk & Milk Products"] ||
                    cartItem.exercise["Vegetables Cooked"] ||
                    cartItem.exercise["Fruits"] ||
                    cartItem.exercise["Pulse [uncooked]"] ||
                    cartItem.exercise["Flesh Foods"] ||
                    cartItem.exercise["Vegetables"] ||
                    cartItem.exercise["Drinks"] ||
                    cartItem.exercise["Fast Food"] ||
                    cartItem.exercise["Desserts"]}
                  <Tooltip
                    title={`Delete Selected Exercise${" "}${[
                      cartItem.exercise["Cereals"],
                      cartItem.exercise["Fats"],
                      cartItem.exercise["Milk & Milk Products"],
                      cartItem.exercise["Vegetables Cooked"],
                      cartItem.exercise["Fruits"],
                      cartItem.exercise["Pulse [uncooked]"],
                      cartItem.exercise["Flesh Foods"],
                      cartItem.exercise["Vegetables"],
                      cartItem.exercise["Drinks"],
                      cartItem.exercise["Fast Food"],
                      cartItem.exercise["Desserts"],
                    ]
                      .filter(Boolean)
                      .join(" ")}`}
                    placement="top-start"
                  >
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "40rem",
                      }}
                      appearance="primary"
                      size="xs"
                      icon={<TrashIcon />}
                      color="red"
                      onClick={() => handleRemoveFromCart(index)}
                    />
                  </Tooltip>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div>
                      <Typography
                        margin={"0.5rem"}
                        textAlign={"center"}
                        fontWeight={"bold"}
                        fontSize={12}
                      >
                        Household Measures: {cartItem.household}
                      </Typography>
                      <input
                        style={{ marginRight: "1rem", width: "8rem" }}
                        value={cartItem.household || ""} // Set the input value to the description property
                        onChange={(e) => {
                          const updatedCart = [...exerciseCart];
                          updatedCart[index].household = e.target.value; // Update remarks in the specific cart item
                          setExerciseCart(updatedCart);
                        }}
                      />
                    </div>
                    <div>
                      <Typography
                        margin={"0.5rem"}
                        marginLeft={"0.01rem"}
                        textAlign={"center"}
                        fontWeight={"bold"}
                        fontSize={12}
                      >
                        Wt/Vol.: {cartItem.wt}
                      </Typography>
                      <input
                        style={{ marginRight: "1rem", width: "8rem" }}
                        value={cartItem.wt || ""} // Set the input value to the description property
                        onChange={(e) => {
                          const updatedCart = [...exerciseCart];
                          updatedCart[index].wt = e.target.value; // Update remarks in the specific cart item
                          setExerciseCart(updatedCart);
                        }}
                      />
                    </div>
                    <div>
                      <Typography
                        margin={"0.5rem"}
                        textAlign={"center"}
                        fontWeight={"bold"}
                        fontSize={12}
                      >
                        Calories: {cartItem.calories}
                      </Typography>
                      <input
                        style={{ marginRight: "1rem", width: "8rem" }}
                        value={cartItem.calories || ""} // Set the input value to the description property
                        onChange={(e) => {
                          const updatedCart = [...exerciseCart];
                          updatedCart[index].calories = e.target.value; // Update remarks in the specific cart item
                          setExerciseCart(updatedCart);
                        }}
                      />
                    </div>
                  </div>
                </List.Item>
              </List>
            ))}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default function DietPlanning() {
  const [showList0, setShowList0] = useState(10);
  const [showList1, setShowList1] = useState(10);
  const [showList2, setShowList2] = useState(10);
  const [showList3, setShowList3] = useState(10);
  const [showList4, setShowList4] = useState(10);
  const [showList5, setShowList5] = useState(10);
  const [showList6, setShowList6] = useState(10);
  const [showList7, setShowList7] = useState(10);
  const [showList8, setShowList8] = useState(10);
  const [showList9, setShowList9] = useState(10);
  const [showList10, setShowList10] = useState(10);

  const [exerciseSelected, setExerciseSelected] = useState([]);
  const [exerciseCart, setExerciseCart] = useState([]);
  const [type, setType] = useState(null);
  const [addToCartModalVisible, setAddToCartModalVisible] = useState(false);
  const [exerciseCartModalVisible, setExerciseCartModalVisible] =
    useState(false);
  const [clicked, setClicked] = useState([]);
  const [clicked1, setClicked1] = useState([]);
  const [clicked2, setClicked2] = useState([]);
  const [clicked3, setClicked3] = useState([]);
  const [clicked4, setClicked4] = useState([]);
  const [clicked5, setClicked5] = useState([]);
  const [clicked6, setClicked6] = useState([]);
  const [clicked7, setClicked7] = useState([]);
  const [clicked8, setClicked8] = useState([]);
  const [clicked9, setClicked9] = useState([]);
  const [clicked10, setClicked10] = useState([]);

  const handleToggle = (selectedType) => {
    const numericType = parseInt(selectedType, 10);

    if (numericType === 1) {
      setType(1);
    } else if (numericType === 2) {
      setType(2);
    } else if (numericType === 3) {
      setType(3);
    } else if (numericType === 4) {
      setType(4);
    } else if (numericType === 5) {
      setType(5);
    } else if (numericType === 6) {
      setType(6);
    } else if (numericType === 7) {
      setType(7);
    } else if (numericType === 8) {
      setType(8);
    } else if (numericType === 9) {
      setType(9);
    } else if (numericType === 10) {
      setType(10);
    } else if (numericType === 11) {
      setType(11);
    }
  };
  const clearExerciseCart = () => {
    setExerciseCart([]);
    setClicked([]);
    setClicked1([]);
    setClicked2([]);
    setClicked3([]);
    setClicked4([]);
    setClicked5([]);
    setClicked6([]);
    setClicked7([]);
    setClicked8([]);
    setClicked9([]);
    setClicked10([]);
  };
  const handleRemoveFromCart = (indexToRemove) => {
    const updatedCart = exerciseCart.filter(
      (_, index) => index !== indexToRemove
    );
    setExerciseCart(updatedCart);
    // Reset background color for the removed item based on its type
    if (type === 1) {
      setClicked(clicked.filter((i) => i !== indexToRemove));
    } else if (type === 2) {
      setClicked1(clicked1.filter((i) => i !== indexToRemove));
    } else if (type === 3) {
      setClicked2(clicked2.filter((i) => i !== indexToRemove));
    } else if (type === 4) {
      setClicked3(clicked3.filter((i) => i !== indexToRemove));
    } else if (type === 5) {
      setClicked4(clicked4.filter((i) => i !== indexToRemove));
    } else if (type === 6) {
      setClicked5(clicked5.filter((i) => i !== indexToRemove));
    } else if (type === 7) {
      setClicked6(clicked6.filter((i) => i !== indexToRemove));
    } else if (type === 8) {
      setClicked7(clicked7.filter((i) => i !== indexToRemove));
    } else if (type === 9) {
      setClicked8(clicked8.filter((i) => i !== indexToRemove));
    } else if (type === 10) {
      setClicked9(clicked9.filter((i) => i !== indexToRemove));
    } else if (type === 11) {
      setClicked10(clicked10.filter((i) => i !== indexToRemove));
    }
  };

  return (
    <>
      <Button
        onClick={() => handleToggle(1)}
        appearance="ghost"
        color="green"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Cereals
      </Button>
      <Button
        onClick={() => handleToggle(2)}
        appearance="ghost"
        color="red"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Fats
      </Button>
      <Button
        onClick={() => handleToggle(3)}
        appearance="ghost"
        color="yellow"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Milk & Milk Products
      </Button>
      <Button
        onClick={() => handleToggle(4)}
        appearance="ghost"
        color="cyan"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Cooked Vegetables
      </Button>
      <Button
        onClick={() => handleToggle(5)}
        appearance="ghost"
        color="orange"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Fruits
      </Button>
      <Button
        onClick={() => handleToggle(6)}
        appearance="ghost"
        color="violet"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Uncooked Pulses
      </Button>

      <Button
        onClick={() => handleToggle(7)}
        appearance="ghost"
        color="green"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Non - Veg
      </Button>
      <Button
        onClick={() => handleToggle(8)}
        appearance="ghost"
        color="red"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Vegetables
      </Button>
      <Button
        onClick={() => handleToggle(9)}
        appearance="ghost"
        color="yellow"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Drinks
      </Button>
      <Button
        onClick={() => handleToggle(10)}
        appearance="ghost"
        color="cyan"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Fast Food
      </Button>
      <Button
        onClick={() => handleToggle(11)}
        appearance="ghost"
        color="orange"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Desserts
      </Button>

      {type === null ? null : type === 1 ? (
        <ExerciseCard
          exerciseList={Cereals}
          show={showList0}
          setShow={setShowList0}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked}
          setSelectedIndices={setClicked}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 2 ? (
        <ExerciseCard
          exerciseList={Fats}
          show={showList1}
          setShow={setShowList1}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked1}
          setSelectedIndices={setClicked1}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 3 ? (
        <ExerciseCard
          exerciseList={Milk_Milk_Products}
          show={showList2}
          setShow={setShowList2}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked2}
          setSelectedIndices={setClicked2}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 4 ? (
        <ExerciseCard
          exerciseList={VegetablesCooked}
          show={showList3}
          setShow={setShowList3}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked3}
          setSelectedIndices={setClicked3}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 5 ? (
        <ExerciseCard
          exerciseList={Fruits}
          show={showList4}
          setShow={setShowList4}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked4}
          setSelectedIndices={setClicked4}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 6 ? (
        <ExerciseCard
          exerciseList={PusleUncooked}
          show={showList5}
          setShow={setShowList5}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked5}
          setSelectedIndices={setClicked5}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 7 ? (
        <ExerciseCard
          exerciseList={NonVeg}
          show={showList6}
          setShow={setShowList6}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked6}
          setSelectedIndices={setClicked6}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 8 ? (
        <ExerciseCard
          exerciseList={Vegetables}
          show={showList7}
          setShow={setShowList7}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked7}
          setSelectedIndices={setClicked7}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 9 ? (
        <ExerciseCard
          exerciseList={Drinks}
          show={showList8}
          setShow={setShowList8}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked8}
          setSelectedIndices={setClicked8}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 10 ? (
        <ExerciseCard
          exerciseList={FastFood}
          show={showList9}
          setShow={setShowList9}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked9}
          setSelectedIndices={setClicked9}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 11 ? (
        <ExerciseCard
          exerciseList={Desserts}
          show={showList10}
          setShow={setShowList10}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked10}
          setSelectedIndices={setClicked10}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : null}
    </>
  );
}
