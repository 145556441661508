import React from "react";
import "./../landing/styles.css";
import { Form, Button, SelectPicker, FlexboxGrid } from "rsuite";
import { HttpService } from "../../services/http.service";
import { EVENT_TYPE_API_URLS, TEAM_API_URL } from "../../services/API";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode"
import CustomNotification from "../CustomNotification";
import { useEffect, useContext } from "react";
import { Color } from "../../utils/Colors";
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} style={{width:"240px"}}{...rest} />
    </Form.Group>
  );
});

export default function AddEventTypeForm({  onSubmitSuccess,setCount}) {
  const [errorCount, setErrorCount] = React.useState(0);
 
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [teams, setTeams] = React.useState();
  const [team, setTeam] = React.useState();
  // const [user, setUser] = React.useState();
  // const [createdBy, setCreatedBy] = React.useState();
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const formRef = React.useRef();

  const [formValue, setFormValue] = React.useState({
    name: "",
    description: ""
  });
  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const response = await HttpService.get(
          `${TEAM_API_URL}?page=${currentPage}`, {}, false
        );

        const modifiedTeams = response.data.results.map((t) => ({
          value: t.id,
          label: t.name,
        }));

        setTeams(modifiedTeams);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchTeam();
  }, [currentPage]);



  // console.log(user);

  const handleSubmit = async () => {
    try {
      const formData = {
        ...formValue,
        account: decode.account,
        team,
        created_by: decode.user_id
      };
      const response = await HttpService.post(EVENT_TYPE_API_URLS, {}, formData, false);

      setResponse(response);

      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setError("Form Submitted"); // Update error state to indicate success
        setOpenModal(true);
        setFormValue({ // Clear the form values
          name: "",
          description: "",
        });
        onSubmitSuccess()
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
    <div>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={12}>
          <Form ref={formRef} onChange={setFormValue} formValue={formValue}>
            <TextField name="name" label="Event Type Name" />
            <TextField name="description" label="Description" />


            <SelectPicker
              label="Team"
              data={teams}
              onSelect={(value) => setTeam(value)}
              style={{ width: 300 }}
            />
            <br />
            <br />

            <Button appearance="primary" onClick={handleSubmit} style={{ background: Color.Color_C, color: Color.Color_A, width: 240 }}>
            Create Event Type
            </Button>

            <CustomNotification
              open={openModal}
              setOpen={setOpenModal}
              nav={"/event/setup"}
              errorCount={errorCount}
              message={
                error ? error : (res && res.data && res.data.message) || ""
              }
              title={error === "Form Submitted" ? "Success" : "Error"}
            />
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>

    </div>

  );
}
