import axios from "axios";
import jwt_decode from "jwt-decode";
import { PUBLIC_URL, BASE_URL } from "./API";

export class HttpService {
  static accessToken = "";
  static refreshToken = "";
  static isRefreshing = false;
  static decode = "";

  static getToken = async () => {
    if (this.accessToken) return this.accessToken;

    const token = localStorage.getItem("token");
    this.accessToken = token;
    return token;
  };

  static getRefreshToken = async () => {
    if (this.refreshToken) return this.refreshToken;

    const refreshToken = localStorage.getItem("refreshToken");
    this.refreshToken = refreshToken;
    return refreshToken;
  };
  static isRefreshingToken = false;

  // Modify updateToken function
  static updateToken = async () => {
    // Check if token refresh is already in progress
    if (HttpService.isRefreshingToken) {
      // If token refresh is in progress, wait for it to complete and return the updated token
      await new Promise(resolve => {
        const interval = setInterval(() => {
          if (!HttpService.isRefreshingToken) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
      return HttpService.accessToken;
    }
    HttpService.isRefreshingToken = true;

    try {
      const refreshToken = await this.getRefreshToken();
      if (refreshToken) {
        try {
          const response = await axios.post(
            `${PUBLIC_URL}/token/refresh/`,
            { refresh: refreshToken }
          );
          this.accessToken = response.data.access;
          this.refreshToken = response.data.refresh;
          localStorage.setItem("token", this.accessToken); // Store updated token
          localStorage.setItem("refreshToken", this.refreshToken); // Store updated refresh token
          // console.log("Token updated successfully in update.");
          return this.accessToken;
        } catch (error) {
          console.error("Error refreshing token:", error);
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          throw error; // Throw error instead of redirecting
        }
      }
    } catch (error) {
      console.error("Error getting refresh token:", error);
      throw error; // Throw error for handling outside
    } finally {
      // Reset flag to indicate token refresh is completed
      HttpService.isRefreshingToken = false;
    }
  };

  static async verifyAccessToken() {
    const token = await this.getToken();
    
    // If token is not present, attempt to refresh using refresh token
    if (!token) {
      await this.updateToken();
      return !!this.accessToken; // Return true if access token is present after refresh
    }
  
    // Access token is present, no need for verification, return true
    return true;
  }
  
  

  static getHeaders = async () => {
    const token = await this.getToken();
    this.decode = jwt_decode(token);
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  };

  static getServerUrl = (isUserApi) => {


    if (isUserApi === false && this.decode) {
      
      const baseURL = BASE_URL(this.decode.account);
     
      return baseURL
      
    } else {
      return PUBLIC_URL;
    }
  };

  static setupResponseInterceptor = () => {
    if (!axios.interceptors.response.handlers.length) {
      axios.interceptors.response.use(
        async (response) => response,
        async (error) => {
          if (error.response.status === 401 && !error.config._retry) {
            error.config._retry = true;
            try {
              await this.updateToken();
              console.log("Token refreshed successfully.");
              // Retry the original request with updated token
              error.config.headers.Authorization = `Bearer ${this.accessToken}`;
              return axios(error.config);
            } catch (refreshError) {
              console.error("Error refreshing token:", refreshError);
              // Handle token refresh error, logout or redirect user
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              throw refreshError;
            }
          }
          return Promise.reject(error);
        }
      );
    }
  };

  static get = async (endpointUrl, customHeaders, isUserApi) => {
    
    const defaultHeaders = await this.getHeaders();
    const config = {
      headers: {
        ...defaultHeaders,
        ...customHeaders,
      },
    };
    const serverUrl = this.getServerUrl(isUserApi);
    return axios.get(`${serverUrl}${endpointUrl}`, config);
  };

  static post = async (endpointUrl, customHeaders, body, isUserApi) => {
    
    const defaultHeaders = await this.getHeaders();
    const config = {
      headers: {
        ...defaultHeaders,
        ...customHeaders,
      },
    };
    const serverUrl = this.getServerUrl(isUserApi);
    return axios.post(`${serverUrl}${endpointUrl}`, body, config);
  };

  static delete = async (endpointUrl, customHeaders, isUserApi) => {
    const defaultHeaders = await this.getHeaders();
    const config = {
      headers: {
        ...defaultHeaders,
        ...customHeaders,
      },
    };
    const serverUrl = this.getServerUrl(isUserApi);
    return axios.delete(`${serverUrl}${endpointUrl}`, config);
  };

  static update = async (endpointUrl, customHeaders, body, isUserApi) => {
    const defaultHeaders = await this.getHeaders();
    const config = {
      headers: {
        ...defaultHeaders,
        ...customHeaders,
      },
    };
    const serverUrl = this.getServerUrl(isUserApi);
    return axios.put(`${serverUrl}${endpointUrl}`, body, config);
  };

  static patch = async (endpointUrl, customHeaders, body, isUserApi) => {
    const defaultHeaders = await this.getHeaders();
    const config = {
      headers: {
        ...defaultHeaders,
        ...customHeaders,
      },
    };
    const serverUrl = this.getServerUrl(isUserApi);
    return axios.patch(`${serverUrl}${endpointUrl}`, body, config);
  };
}

// Call this to setup the response interceptor when your app starts
HttpService.setupResponseInterceptor();
