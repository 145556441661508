import React from "react";
import "./../userinfo/UserInfo.css";
import { Placeholder, Button, Divider } from "rsuite";
import UserCustomDrawer from "../../components/CustomDrawer";
// import CustomSideNavbar from "../../components/CustomSideNav";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CustomNotification from "../../components/CustomNotification";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import { GetSubscriptionData } from "../../components/custmomhooks/CustomHooks";
import { Container } from "@mui/material";
export default function SubscriptionDetails() {
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [subscriptionData, setSubscriptionData] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1); // Total number of pages

  GetSubscriptionData({
    setSubscriptionData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  });
  // console.log(subscriptionData);
  const handleOpen = (key) => {
    setOpen(true);
    setPlacement(key);
  };

  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1); // Page is 0-based, API expects 1-based
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "account",
      headerName: "Account ID",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "expiration_date",
      headerName: "Expiration Date",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "max_users",
      headerName: "Max Users",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "max_teams",
      headerName: "Max Teams",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "max_athlete_profiles",
      headerName: "Max Athlete",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "max_admin_profiles",
      headerName: "Max Admin",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <>
      <DashBoardTopNav />
      <Divider style={{ border: "2rem" }} />
      <Container maxWidth="lg">
        <Button
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
          appearance="primary"
          onClick={() => handleOpen("right")}
        >
          Add Subscription
        </Button>

        <div>
          {subscriptionData === undefined || subscriptionData?.length <= 0 ? (
            <>
              <Placeholder.Grid rows={10} columns={6} active />
              {error?.response?.status === 200 ? null : (
                <CustomNotification
                  open={openModal}
                  setOpen={setOpenModal}
                  message={
                    error?.response?.status === 200 ? "Loading" : error?.message
                  }
                  title={error?.response?.status === 200 ? "Loading" : "Error"}
                />
              )}
            </>
          ) : (
            <Box>
              <DataGrid
                rows={subscriptionData}
                columns={columns}
                pagination
                paginationMode="server"
                rowCount={totalPages * 10} // Total number of rows (assuming 10 per page)
                onPageChange={handlePageChange} // Handle page changes
                pageSize={10} // Number of rows per page
                page={currentPage - 1} // Current page (0-based)
                paginationComponentProps={{
                  // Customize the appearance of the pagination component
                  disableNext: currentPage === totalPages,
                  disablePrev: currentPage === 1,
                }}
              />
            </Box>
          )}
        </div>
      </Container>

      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"full"}
        placement={placement}
        mode={"Subscription"}
      />
    </>
  );
}
