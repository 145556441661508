import React, { useEffect, useContext } from "react";
import { Button, Divider, IconButton, Modal } from "rsuite";
import UserCustomDrawer from "../../components/CustomDrawer";
import styles from "./../sportsscience/science.module.css"
import CustomNotification from "../../components/CustomNotification";
import {  Typography } from "@mui/material";
import SampleAthleteData from "./SampleAthleteData";
import { HttpService } from "../../services/http.service";
import { ATHLETE_PROFILE_API_URL, BASE_URL } from "../../services/API";
import { DataGrid,GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import EditAthleteProfile from "./EditAthleteProfile";
import DeleteAthleteProfile from "./DeleteAthleteProfile";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../store/AuthContext";
import { Color } from "../../utils/Colors";

export default function AthleteProfile() {
  const [filterData, setFilterData] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [imageUrlMap, setImageUrlMap] = React.useState({});

  const [editModal, setEditModal] = React.useState(false);
  const [mode, setMode] = React.useState(null);
  const [emailID, setEmailID] = React.useState();
  const [athleteID, setAthleteID] = React.useState();
  const [placement, setPlacement] = React.useState();
  const [athleteData, setAthleteData] = React.useState([]);
  const [selectedAthlete, setSelectedAthlete] = React.useState([]);
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1); // Total number of pages
  const [modalprofile, setModalProfile] = React.useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = React.useState(false);
  const [isButtonEnabled1, setIsButtonEnabled1] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState();
  const [filter, setFilter] = React.useState(null);
  const [csv, setCsv] = React.useState();
  const [count, setCount] = React.useState(0)

  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const decode = token ? jwt_decode(token) : "";
  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  useEffect(() => {
    const delay = setTimeout(() => setIsButtonEnabled(true), 30000);
    return () => clearTimeout(delay);
  }, []);
  useEffect(() => {
    const delay = setTimeout(() => setIsButtonEnabled1(true), 35000);
    return () => clearTimeout(delay);
  }, []);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("csv_file", selectedFile);

    try {
      const response = await HttpService.post(
        ATHLETE_PROFILE_API_URL,
        { "Content-Type": "multipart/form-data" },
        formData,
        false
      );

      if (response.data.error === false) {
        alert("Upload Successful");
      } else {
        // Display error message from the server if available
        const errorMessage = response.data.message || "Failure: Not Uploaded!!";
        alert(errorMessage);
      }
    } catch (error) {
      // Handle network or other errors
      console.warn(error);
      alert("An error occurred during the upload. Please try again.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`,
          {},
          false
        );

        setAthleteData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setAthleteData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
    count
  ]); // Fetch new data when currentPage changes
  useEffect(() => {
    if (athleteData.length > 0) {
      const fetchImageUrls = async () => {
        const promises = athleteData.map(async (item) => {
          try {
            const baseURL = BASE_URL(decode.account);
            const mediaURL = `${baseURL}/media`;
            const imageUrl = `${mediaURL}${item.profile_photo_url}`;

            const response = await fetch(imageUrl, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (response.ok) {
              const blob = await response.blob();
              const objectURL = URL.createObjectURL(blob);
              return { [item.id]: objectURL };
            } else {
              throw new Error("Network response was not ok.");
            }
          } catch (error) {
            console.error(
              "There was a problem with the fetch operation:",
              error
            );
            return { [item.id]: null };
          }
        });

        const imageUrlMap = await Promise.all(promises);
        const mergedImageUrlMap = Object.assign({}, ...imageUrlMap);
        setImageUrlMap(mergedImageUrlMap);
      };
      fetchImageUrls();
    }
  }, [athleteData, decode.account, token]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  

  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1); // Page is 0-based, API expects 1-based
  };
  const handleOpen2 = (id, status, mode, email) => {
    setAthleteID(id);
    setEditModal(status);
    setMode(mode);
    setEmailID(email);
  };
  const handleClose2 = () => {
    setAthleteID(null);
    setEditModal(false);
    setMode(null);
  };
  const columns = [
    {
      field: "profile_photo_url",
      headerName: "Image",
      width: 100,
      renderCell: (params) =>
        imageUrlMap[params.row.id] ? (
          <img
            src={imageUrlMap[params.row.id]}
            alt="Profile"
            height={50}
            width={50}
            style={{ borderRadius: "50%" }}
          />
        ) : null,
      headerClassName: 'super-app-theme--header',
    },

    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
      headerClassName: 'super-app-theme--header',
    },

    {
      field: "height",
      headerName: "Height",
      sortable: true,
      width: 110,
      headerAlign: "center",
      align: "center",
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "weight",
      headerName: "Weight",
      sortable: true,
      width: 110,
      headerAlign: "center",
      align: "center",
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "sports",
      headerName: "Sports",
      sortable: true,
      width: 110,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "injury_status",
      headerName: "Injury Status",
      sortable: true,
      width: 110,
      headerAlign: "center",
      headerClassName: 'super-app-theme--header',
      align: "center",
      renderCell: (params) => {
        return params.row.injury_status === true ? (
          <Typography fontWeight={"bold"} color="green">
            Yes
          </Typography>
        ) : (
          <Typography fontWeight={"bold"} color="red">
            No
          </Typography>
        );
      },
    },
    {
      field: "occupation",
      headerName: "Occupation",
      sortable: true,
      width: 110,
      headerAlign: "center",
      headerClassName: 'super-app-theme--header',
      align: "center",
    },
    {
      field: "action",
      headerName: "Edit",
      sortable: true,
      width: 150,
      headerAlign: "center",
      headerClassName: 'super-app-theme--header',
      align: "center",
      renderCell: (params) => (
        <IconButton
          onClick={() => handleOpen2(params.row.id, true, "edit")}
          color="violet"
          appearance="primary"
          icon={<EditIcon />}
        ></IconButton>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <IconButton
          onClick={() =>
            handleOpen2(params.row.id, true, "delete", params.row.email)
          }
          color="red"
          appearance="primary"
          icon={<TrashIcon />}
        ></IconButton>
      ),
    },
  ];

  const handleClick = (item) => {
    setSelectedAthlete(item);
    setModalProfile(!modalprofile);
  };

  return (
    <div>
      <div>
        <div className={styles.belownav}>
          <Button
            appearance="primary"
            onClick={() => console.log("clicked")}
            className={styles.btn}
            style={{ background: "cadetblue" }}

          >
            Upload Bulk
          </Button>
         <Button

            appearance="primary"
            onClick={()=>setOpen(true)}
            className={styles.btn}
            style={{ background: "cadetblue" }}

          >
            Create Athlete
          </Button> 
          <Button
            appearance="primary"
            onClick={() => setFilterData(!filterData)}
            className={styles.btn}
            style={{ background: "cadetblue" }}

          >
            {filterData === true ? "Non-tabular" : "Tabular"}
          </Button></div>
        {filter === 1 ? (
          <div
            style={{
              // marginLeft: isSidebarExpanded ? "15rem" : "5rem",
              marginBottom: 20,
              marginTop: 30,
            }}
          >
            <SampleAthleteData />
            <br />

            <input type="file" accept=".csv" onChange={fileSelectedHandler} />
            {selectedFile === undefined ? null : (
              <Button
                color="red"
                appearance="primary"
                onClick={() => handleSubmit()}
              >
                Bulk Upload
              </Button>
            )}
            <br />
          </div>
        ) : null}

        <div
          className={styles.tablular}

        >
          {filterData === true ? (
            <div>
              {athleteData === undefined || athleteData?.length <= 0 ? (
                <>
                  <Typography>No Data</Typography>
                  {error?.response?.status === 200 ? null : (
                    <CustomNotification
                      open={openModal}
                      setOpen={setOpenModal}
                      message={
                        error?.response?.status === 200
                          ? "Loading"
                          : error?.message
                      }
                      title={
                        error?.response?.status === 200 ? "Loading" : "Error"
                      }
                    />
                  )}
                </>
              ) : (

                <DataGrid
                  sx={{

                    '& .super-app-theme--header': {
                      color: Color.Color_C

                    },
                  }}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  getRowId={(row) => row.id}
                  rows={athleteData}
                  columns={columns}
                  pagination
                  paginationMode="server"
                  rowCount={totalPages * 10} // Total number of rows (assuming 10 per page)
                  onPageChange={handlePageChange} // Handle page changes
                  pageSize={10} // Number of rows per page
                  page={currentPage - 1} // Current page (0-based)
                  paginationComponentProps={{
                    // Customize the appearance of the pagination component
                    disableNext: currentPage === totalPages,
                    disablePrev: currentPage === 1,
                  }}
                />

              )}
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {athleteData
                ?.reduce((rows, item, index) => {
                  if (index % 8 === 0) {
                    rows.push([]);
                  }
                  rows[rows.length - 1].push(item);
                  return rows;
                }, [])
                .map((row, rowIndex) => (
                  <div
                    key={rowIndex}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      // marginLeft: 20,
                    }}
                  >
                    {row.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: 20,
                          padding: 10,
                          cursor: "pointer"
                        }}
                      >
                        <img
                          src={imageUrlMap[item.id]} // Use item.id instead of params.row.id
                          alt="Profile"
                          height={100}
                          width={100}
                          style={{ borderRadius: "10%" }}
                          onClick={() => handleClick(item)}
                        />
                        <label
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: 10,
                            background:
                              item.injury_status === true
                                ? "pink"
                                : "lightgreen",
                            color: Color.Color_C,
                            borderRadius: "2rem",
                            marginTop: 5,
                          }}
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          )}
        </div>

        {selectedAthlete === undefined ? null : (
          <Modal
            style={{
              top: "10px",
            }}
            open={modalprofile}
            onClose={() => setModalProfile(false)}
            size={"sm"}
          >
            <Modal.Header>
              <Modal.Title>
                {selectedAthlete?.name} : Profile Details
              </Modal.Title>
            </Modal.Header>
            <Divider />
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 8,
                    }}
                  >
                    <Typography marginRight={1}>Email:</Typography>
                    <Typography>{selectedAthlete?.email}</Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 8,
                    }}
                  >
                    <Typography marginRight={1}>Gender:</Typography>
                    <Typography>{selectedAthlete?.gender}</Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 8,
                    }}
                  >
                    <Typography marginRight={1}>Sports:</Typography>
                    <Typography>{selectedAthlete?.sports}</Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 8,
                    }}
                  >
                    <Typography marginRight={1}>Height:</Typography>
                    <Typography>{selectedAthlete?.height} cm</Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 8,
                    }}
                  >
                    <Typography marginRight={1}>Weight:</Typography>
                    <Typography>{selectedAthlete?.weight} kg</Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 8,
                    }}
                  >
                    <Typography marginRight={1}>Injury Status:</Typography>
                    <Typography
                      fontWeight={"600"}
                      color={
                        selectedAthlete?.injury_status === false
                          ? "green"
                          : "red"
                      }
                    >
                      {selectedAthlete?.injury_status === false ? "No" : "Yes"}
                    </Typography>
                  </div>
                </div>
                <div>
                  <img
                    src={imageUrlMap[selectedAthlete.id]}
                    alt="Profile"
                    height={200}
                    width={200}
                    style={{ borderRadius: "10%" }}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>

      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={placement}
        mode={"Athlete"}
        setCount={setCount}
      />
      <Modal size="lg" open={editModal} onClose={handleClose2}>
        {mode === "edit" ? (
          <EditAthleteProfile id={athleteID} />
        ) : mode === "delete" ? (
          <DeleteAthleteProfile
            id={athleteID}
            email={emailID}
            onSucess={handleClose2}
          />
        ) : null}
      </Modal>
    </div>
  );
}
