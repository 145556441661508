import React, { useState } from "react";
import styles from "./science.module.css";
import PlaceHolder from "./PlaceHolder";
import EvaluationFormView from "../../components/EvaluationFormView";
export default function Anthropometry({label}) {
  const [open, setOpen] = useState(false);
  // const [placement, setPlacement] = useState();
  const [drawerTitle, setDrawerTitle] = useState();
  const [dept,setDept] = useState()
  const [cat,setCat] = useState()
  
  const data = [
    {
      cat: 73,
      name: "Somatotype",
      dept: 5,
      cardColor: "#c5cae9",
    },
    {
      cat: 74,
      name: "Growth & Devlopment",
      dept: 5,
      cardColor: "#c5cae9",
    },
    {
      cat: 75,
      name: "Body Composition",
      dept: 5,
      cardColor: "#c5cae9",
    },
    
  ];

  const handleOpen = ({ name, key, dept, cat }) => {
    setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
    setDept(dept);
    setCat(cat)
  };

  return (
    <div className={styles.sport_main} >
   
      <div className={styles.sports_main_inner}>
          <select
            className={styles.dropdown}
            onChange={(e) => {
              const selectedValue = e.target.value;
              const selectedItem = data.find(
                (item) => item.name === selectedValue
              );

              if (selectedItem) {
                handleOpen({
                  name: selectedItem.name,
                  dept: selectedItem.dept,
                  cat: selectedItem.cat,
                });
              }
            }}
          >
            <option value="">{label}</option>
            {data.map((item) => (
              <option key={item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
   
   {drawerTitle===undefined?<PlaceHolder/> : <EvaluationFormView
        open={open}
        setOpen={setOpen}
        size={"lg"}
        placement={"left"}
        mode={drawerTitle}
        dept={dept}
        cat={cat}
      />}
     
   
  </div>
);
}
