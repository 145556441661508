import React, { useEffect,useContext } from "react";
import {
  Form,
  Button,
  // SelectPicker,
  FlexboxGrid,
  RadioGroup,
  Radio,
  // DatePicker,
} from "rsuite";
import { HttpService } from "../../services/http.service";
import {
  
  USER_API_URL,
  TREATMENT_LOGS_API_URLS,
} from "../../services/API";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import CustomNotification from "../../components/CustomNotification";
import { Color } from "../../utils/Colors";
// const TextField = React.forwardRef((props, ref) => {
//   const { name, label, accepter, ...rest } = props;
//   return (
//     <Form.Group controlId={`${name}-4`} ref={ref}>
//       <Form.ControlLabel>{label} </Form.ControlLabel>
//       <Form.Control name={name} accepter={accepter} {...rest} />
//     </Form.Group>
//   );
// });

export default function AddTreatmentLogs({ onSubmitSuccess,athlete_id,selectedDate,setCount }) {
  const formRef = React.useRef();
  const [users, setUser] = React.useState();
  const [startTime, setStartTime] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const [treatmentType, setTreatmentType] = React.useState();

  // const [date, setDate] = React.useState();
  // const [record, setRecord] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [formValue, setFormValue] = React.useState({
    treatment_short_description: "",
  });
  const data = ["Sports Medicine", "Physiotherapy", "Psychology"].map(
    (item) => ({
      label: item,
      value: item,
    })
  );
  const inputDate = new Date(selectedDate);

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');
  
  const formattedDate = `${year}-${month}-${day}`;
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  // console.log("wok",athlete_id)
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await HttpService.get(
          `${USER_API_URL}?page=${currentPage}`,{}, true
        );

        const modifiedUsers = response.data.results.map((user) => ({
          value: user.id,
          label: user.email,
        }));

        setUser(modifiedUsers);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchUser();
  }, [currentPage]);

  

  const handleSubmit = async () => {
   
    try {
      const formData = {
        ...formValue,
        date: formattedDate,
        treatment_type:
          treatmentType === "Sports Medicine"
            ? "sports_medicine"
            : treatmentType.toLowerCase(),
        start_time: startTime,
        end_time: endTime,
        athlete:athlete_id,
        recorded_by: decode.user_id,
      };
      const response = await HttpService.post(
        TREATMENT_LOGS_API_URLS,
        {},
        formData,false
      );

      setResponse(response);

      if (response.status === 201) {
        setOpenModal(true);
        setCount(prevCount => prevCount + 1);
        setError("Form Submitted");
        onSubmitSuccess();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      setOpenModal(true);
    }
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        {/* {users ||athletes === undefined ? (
          <Placeholder.Paragraph style={{ marginTop: 30 }} />
        ) : ( */}
        <Form ref={formRef} onChange={setFormValue} formValue={formValue}>

          <label style={{fontWeight:"bolder"}}> Treatment Description</label>
          <br/><br/>
          <textarea
          style={{width:"400px",height:"200px"}}
          value={formValue.treatment_short_description}
          onChange={(e)=>setFormValue({...formValue,treatment_short_description:e.target.value})}
          placeholder="Write the Treatment Description"
          
          />

          <br/><br/>
<div >
          <label style={{fontWeight:"bolder"}}> Treatement Mode</label>
          <RadioGroup
            name="treatmentType"
            
            style={{ width: 300, display:"flex",flexDirection:"row" , justifyContent:"space-between",  }}
            appearance="default"
            value={treatmentType}
            onChange={value => setTreatmentType(value)}
          >
            {data.map(option => (
              <Radio style={{marginRight:"10px"}} color={option.value==="Sports Medicine"?"violet":option.value==="Physiotherapy"?"green":"orange"} key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </RadioGroup>
        </div>
         
        <br/><br/>

          
          
          
          <label htmlFor="timePicker" style={{ marginRight: "10px" }}>
            Select Start time:
          </label>
          <input
            type="time"
            id="timePicker"
            name="timePicker"
            onChange={(e) => setStartTime(e.target.value)}
            style={{
              padding: "5px",
              borderRadius: "3px",
              border: "1px solid #ccc",
            }}
          />

          <p style={{ marginTop: "10px" }}>
            Selected Start Time: <span>{startTime}</span>
          </p>
          <br />
          <br />

          <label htmlFor="timePicker" style={{ marginRight: "10px" }}>
            Select End time:
          </label>
          <input
            type="time"
            id="timePicker"
            name="timePicker"
            onChange={(e) => setEndTime(e.target.value)}
            style={{
              padding: "5px",
              borderRadius: "3px",
              border: "1px solid #ccc",
            }}
          />

          <p style={{ marginTop: "10px" }}>
            Selected End Time: <span>{endTime}</span>
          </p>
          <br />
          <br />
        
         

       
            <Button appearance="primary" onClick={handleSubmit} style={{background:Color.Color_C,color:Color.Color_A,width:300}}>
              Submit
            </Button>
          <CustomNotification
            open={openModal}
            setOpen={setOpenModal}
            message={
              error ? error : (res && res.data && res.data.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </Form>
        {/* )} */}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
