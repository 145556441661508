import React, {  useEffect, useState } from "react";
import { ACCOUNTS_API_URL } from "../../services/API";

import { HttpService } from "../../services/http.service";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
export default function AccountDetails() {
  const [accountData, setAccountData] = useState([]);
 
 
  function CustomFooterStatusComponent() {
    return null
  }
  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const response = await HttpService.get(ACCOUNTS_API_URL, {}, true);
        setAccountData(response.data.results);
      } catch (error) {
        console.warn("Error fetching account data:", error);
      }
    };
    fetchAccountData();
  }, []);

 
 

  const capitalize = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

 


  const accountColumns = [
    { field: "schema_name", headerName: "Organization Name", width: 200 },
    { field: "name", headerName: "Account Name", width: 200 },
    { field: "created_at", headerName: "Created Date", width: 150 },
    { field: "is_active", headerName: "Is Active", width: 150, renderCell: (params) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FiberManualRecordIcon
          fontSize="small"
          sx={{
            mr: 1,
            color: params.value === 'Yes' ? '#4caf50' : '#d9182e',
          }}
        />
        {params.value}
      </Box>
    )
  },
    // { field: "is_deleted", headerName: "Is Deleted", width: 120 },
    { field: "on_trial", headerName: "On Trial", width: 120 },
  ];

  const accountRows = accountData.map((item) => ({
    id: item.id,
    schema_name: capitalize(item.schema_name),
    name: capitalize(item.name),
    created_at: item.created_at.slice(0, 10),
    is_active: item.is_active ? "Yes" : "No",
    is_deleted: item.is_deleted ? "Yes" : "No",
    on_trial: item.on_trial ? "Yes" : "No",
  }));
  return  accountData?.length <=0 ? <Typography variant={"h6"} color={"red"}>Permission Denied</Typography> :(
    <>
      <div>
        
        <div >
          <DataGrid rows={accountRows} columns={accountColumns} slots={{
            footer: CustomFooterStatusComponent,
          }}
           />
        </div>
      </div>
   
      
    </>
  );
}
