import React from 'react';
import Root from "./routes/Route";
import AuthContextProvider from "./store/AuthContext";
import InternetStatus from "./components/InternetStatus";

function App() {
  const handleContextMenu = (e) => {
    // e.preventDefault(); 
  };

  return (
    <AuthContextProvider>
      <div onContextMenu={handleContextMenu} onCopy={handleContextMenu}>
        <InternetStatus/>
        <Root  />
      </div>
    </AuthContextProvider>
  );
}

export default App;
