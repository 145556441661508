import React, { useState } from "react";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import Sidebar from "../../components/CustomSideNav";
import Utilisation from "./Utilisation";
import SubscriptionDetails from "./SubscriptionDetails";
import UserDetails from "./UserDetails";
import { Accordion, Typography } from "@mui/material";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountDetails from "./AccountDetails";
import Permission from "./Permission";

export default function AccountInfo() {
  const [expandedPanel, setExpandedPanel] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  const data = [
    { id: "user-details", name: "User Details", component: <UserDetails /> },
    { id: "account-details", name: "Account Details" , component: <AccountDetails /> },
   
  
    { id: "subscription-details", name: "Subscription", component: <SubscriptionDetails /> },
    { id: "utilisation", name: "Utilisation", component: <Utilisation /> },
    
    { id: "permission", name: "Following Permission is granted to you:", component: <Permission /> },
    
  ];

  return (
    <>
      <DashBoardTopNav header={"My Account"} />
      <Sidebar />
      <div style={{  width: '90%', marginLeft: '80px', marginTop: "80px" }}>
        {data.map((item) => (
          <Accordion key={item.id} expanded={expandedPanel === item.id} onChange={handleChange(item.id)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${item.id}-content`}
              id={`${item.id}-header`}
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                {item.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {item.component}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
}
