import React from "react";

import MonitoringDashboard from "./MonitoringDashboard";

export default function Summary() {
 
  return (
   
      
      <MonitoringDashboard />
    
    
     
    
   
  );
}
