import React, { useState, useEffect, useContext } from "react";
import {  Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import styles from "./science.module.css"
import { SelectPicker, Modal } from "rsuite";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { HttpService } from "../../services/http.service";
import {
  ATHLETE_PROFILE_API_URL,
  EVALUATION_RESULTS,
  EVALUATION_REPORTS,
  USER_API_URL,
  BASE_URL,
} from "../../services/API";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../store/AuthContext";
import { Color } from "../../utils/Colors";
import PageIcon from "@rsuite/icons/Page";
import CustomNotification from "../../components/CustomNotification";
import Label from "../../components/Label";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
export default function UploadDocuments() {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [count, setCount] =useState(0)
  const [photoError, setPhotoError] = useState([]);
  const [remark, setRemarks] = useState("");
  const [reports, setReports] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [res, setResponse] = useState();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [evaluations, setEvaluations] = useState([]);
  const [evaluation, setEvaluation] = useState();
  const [athletes, setAthletes] = useState([]);
  const [athlete, setAthlete] = useState(null);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const decode = token ? jwt_decode(token) : "";
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(
          ATHLETE_PROFILE_API_URL,
          {},
          false
        );

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, []);
  const fetchReport = async () => {
    try {
      const response = await HttpService.get(
        `${EVALUATION_REPORTS}`,
        {},
        false
      );

      const modifiedResult = await Promise.allSettled(
        response.data.results.map(async (result) => {
          try {
            // Fetch athlete data for each result
            const athleteResponse = await HttpService.get(
              ATHLETE_PROFILE_API_URL + result.athlete + "/",
              {},
              false
            );

            const athleteName = athleteResponse.data.name;
            const userResponse = await HttpService.get(
              USER_API_URL + result.created_by + "/",
              {},
              true
            );

            let evaluation_name = "No Evaluation Data"; // Default value
            if (result.evaluation !== null) {
                const evaluationResponse = await HttpService.get(
                    EVALUATION_RESULTS + result.evaluation + "/", {}, false
                );
                evaluation_name = evaluationResponse.data.field_name;
            }


            return {
              id: result.id,
              athlete_name: athleteName,
              user: userResponse.data.first_name,
              evaluation_name: evaluation_name,
              created_at: result.created_at.slice(0, 10),
              ...result,
            };
          } catch (error) {
            // Handle individual request error
            console.error(
              `Error fetching data for result ${result.id}:`,
              error
            );
            return null; // or handle the error in an appropriate way
          }
        })
      );

      // Extracting successful results and maintaining the order
      const filteredResults = modifiedResult
        .filter(
          (result) => result.status === "fulfilled" && result.value !== null
        )
        .map((result) => result.value);

      setReports(filteredResults);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    // Fetch evaluations based on the selected athlete
    const fetchEvaluations = async () => {
      if (athlete) {
        try {
          const response = await HttpService.get(
            `${EVALUATION_RESULTS}?athlete=${athlete}`,
            {},
            false
          );

          const modifiedEvaluations = response.data.results.map(
            (evaluation) => ({
              value: evaluation.id,
              label: evaluation.field_name,
            })
          );
          const filteredEvaluations = modifiedEvaluations.filter(
            (evaluation) => evaluation !== null
          );
          const allOption = { value: null, label: "No Evaluation Data" };

          setEvaluations([allOption, ...filteredEvaluations]);
        } catch (error) {
          console.warn(error);
        }
      }
    };

    fetchEvaluations();
  }, [athlete]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });



  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
  
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword']; // Adding 'application/msword' for DOC files
  
    const hasInvalidFiles = selectedFiles.some(
      (file) => !allowedTypes.includes(file.type)
    );
    if (hasInvalidFiles) {
      setPhotoError("Invalid file type. Allowed types are JPEG, PNG, PDF, and DOC.");
      setFiles([]);
    } else {
      setPhotoError("");
      setFiles(selectedFiles);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("athlete", athlete);
    formData.append("remarks", remark);
    formData.append("evaluation", evaluation === null ? "" : evaluation);
    files.forEach((file, index) => {
      formData.append("report", file);
    });

    // setIsSubmitting(true);

    try {
      const response = await HttpService.post(
        EVALUATION_REPORTS,
        { "Content-Type": "multipart/form-data" },
        formData,
        false
      );

      setResponse(response);

      if (response.status === 201) {
        setCount(prev => prev +1)
        setOpenModal(true);
        setError("Form Submitted");
        setFiles([]);
        setRemarks("");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setCount(prev=>prev+1)
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };
  const handleReport = () => {
    fetchReport();
    setReportModal(true);
  };
  // console.log(reports);

  const handleDownload = (fileUrl) => {
    // Assuming the URL is known or fetched from somewhere
    if (fileUrl) {
      const baseURL = BASE_URL(decode.account);
      const mediaURL = `${baseURL}/media`;
      const fullURL = `${mediaURL}${fileUrl}`;
  
      // Fetching file with authentication headers
      fetch(fullURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        if (response.status === 401){
        
        setCount(prev=>prev+1)
        setError("Permission Denied")
        setOpenModal(true);}
        throw new Error("Network response was not ok.");
      })
      .then((blob) => {
        // Create a download link for the blob and trigger a click
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        // Extract filename from URL (optional)
        const filename = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        link.download = filename; // You can adjust the filename as needed
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        // Reset checker after successful download
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
    } else {
      console.error("No file URL provided.");
    }
  };

  return (
    <div style={{
    marginLeft: "20px",}}>
     
        <div
        className={styles.belownav}
          style={{
          marginLeft:"20px",
          marginTop:"10px"
          }}
        >
          <SelectPicker
            label="Select Athlete"
            data={athletes}
            value={athlete}
            onSelect={(value) => setAthlete(value)}
            onClean={(()=>setAthlete(null))}
            style={{ width: 300 }}
          />
       { athlete&& <div   onClick={handleReport} style={{ cursor: "pointer", marginLeft:"20px" }}>
        <Label  sx={{marginTop:"8px",cursor: "pointer"}} color="primary">Reports
            <PageIcon
             
              style={{ fontSize: 12, cursor: "pointer", marginLeft:"20px" }}
             
            /></Label>
          </div>}
        </div>
     

      {athlete && (
        <>
          <div style={{ marginTop: "2rem" }}>
            <SelectPicker
              label="Select Evaluation"
              data={evaluations}
              value={evaluation}
              onSelect={(value) => setEvaluation(value)}
              style={{ width: 300 }}
            />
          </div>
          <Typography style={{ marginTop: "2rem" }}>
            Upload your reports or documents
          </Typography>{" "}
          <Typography
            style={{ marginTop: "2rem" }}
            color="darkred"
            fontSize={18}
          >
            Files supported are jpeg, png, pdf and doc
          </Typography>
          {photoError ? (
            <Typography style={{ marginTop: "2rem" }} color="red">
              {photoError}
            </Typography>
          ) : (
            <div>
              <Typography style={{ marginTop: "2rem" }}>
                Uploaded Files:
              </Typography>
              <ul>
                {files.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
          )}
          <br />
          <label htmlFor="file-upload">
            <Button
              component="span"
              variant="contained"
              color={photoError !== "" ? "error" : "primary"}
              startIcon={<CloudUploadIcon />}
            >
              Upload file
            </Button>
            <VisuallyHiddenInput
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              multiple
            />
          </label>
          <h6 style={{ marginTop: "2rem" }}>Remarks</h6>
          <br />
         
          <textarea
            // rows={10}
            multiple = {true}
            type="text"
            value={remark}
            style={{ marginTop: "1rem", width: "600px",  }}
            placeholder="Type a remark..."
            onChange={(e) => setRemarks(e.target.value)}
          ></textarea>
          <br />
          <Button
            style={{
              marginTop: "2rem",
              backgroundColor: Color.Color_C,
              width: 300,
            }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            // disabled={error !== "" || isSubmitting}
          >
            Submit
          </Button>
          <CustomNotification
          errorCount={count}
            open={openModal}
            setOpen={setOpenModal}
            nav={"/sportscience"}
            message={
              error ? error : (res && res.data && res.data.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </>
      )}
      <Modal size="sm" open={reportModal} onClose={() => setReportModal(false)}>
        <Modal.Header style={{fontWeight:"bold"}}>Reports</Modal.Header>
        <Modal.Body>
          {reports === undefined ? <Label color="error">No Data</Label>:reports?.map((item, index) => {
            return (
              <div
                
                key={index}
              >
                {item.report_url === "" ? null : (
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems:"center",
                   
                    
                  }}>
                    <div  style={{
                    display: "flex",
                    flexDirection: "row",

                    
                  }}>
                       <Label sx={{ marginTop: "1rem", }} color="primary">
                      Reports: 
                    </Label>
                    <Label sx={{ marginLeft: "1rem",marginTop: "1rem" }} color="primary">
                     {item.athlete_name}
                    </Label>
                    <Label sx={{ marginLeft: "1rem",marginTop: "1rem" }} color="primary">
                     {item.evaluation_name}
                    </Label>
                    </div>
                   <div    onClick={() => handleDownload(item.report_url)} style={{marginLeft:"10px",marginTop:"10px",cursor: "pointer",}}> 
                   <Label sx={{marginRight:"12px",cursor: "pointer",}} color="secondary">Download Report</Label>
                   <FileDownloadIcon
                    
                    style={{
                      fontSize: 20,
                     
                      cursor: "pointer",
                      color: Color.Color_C,
                    }}
                  
                  />
                   </div>
                   
                  </div>
                )}
              </div>
            );
          })}
           
        </Modal.Body>
    
      </Modal>
      
    </div>
  );
}
