import React from "react";
import { Doughnut } from "react-chartjs-2";
// import styles from "./injury.module.css"
const CustomPieChart = ({ data, title }) => {
 const dataset = data || [];
  const labels = dataset.map((item) => `${item.date} - ${item.value}`);
  const values = dataset.map((item) => item.value);

  // Count occurrences of each unique value
  const valueCounts = values.reduce((acc, value) => {
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {});

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: Object.values(valueCounts),
       
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ width: "250px", height: "250px", margin: "0 auto" }}>
      {/* Added margin: 0 auto to center the div horizontally */}
      <h6 style={{ textAlign: "center" }}>{title}</h6>
      <Doughnut data={chartData} width={250} height={250} />
    </div>
  );
};

export default CustomPieChart;
