import React from "react";
import { Bar } from "react-chartjs-2";
// import styles from "./injury.module.css"
const CustomBarChart = ({ data, title }) => {
  const uniqueAthleteNames = [...new Set(data.map((entry) => entry.athlete))];
 
  const datasets = [];
uniqueAthleteNames.forEach((athleteName) => {
  const athleteData = data.filter((entry) => entry.athlete === athleteName);

  athleteData.forEach((entry) => {
    // Convert date string to JavaScript Date object
    const dateObject = new Date(entry.date);
    const formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')}`;
    
    const index = datasets.findIndex((dataset) => dataset.label === entry.athlete);
    if (index === -1) {
      datasets.push({
        label: entry.athlete,
        data: [{ x: formattedDate, y: entry.value }],
        borderWidth: 1,
        barThickness: 20,
      
      });
    } else {
      datasets[index].data.push({ x: formattedDate, y: entry.value });
    }
  });
});

const chartData = {
  datasets: datasets.map((dataset) => ({
    ...dataset,
    data: dataset.data.sort((a, b) => a.x.localeCompare(b.x)),
    borderRadius: 30,
    borderSkipped: "bottom",
      
  })),
};

const chartOptions = {
  scales: {
    x: {
      stacked: false,
      title: {
        display: true,
        text: 'Date', // Label for the x-axis
        font: {
          size: 16,
          weight: 'bold'
        }
      }
    },
    y: {
      stacked: false,
      title: {
        display: true,
        text: 'Value', // Label for the y-axis
        font: {
          size: 16,
          weight: 'bold'
        }
      }
    }
  },
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
  },
};


  return (
    <div >
      <h6 style={{ textAlign: "center" }}>{title}</h6>
      <Bar data={chartData} options={chartOptions} />
     
    </div>
  );
};

export default CustomBarChart;
