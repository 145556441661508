import React from "react";
import { Form, Button, FlexboxGrid } from "rsuite";
import { HttpService } from "../../services/http.service";
import { GENERALISED_WORKOUT_LOGS_API_URLS } from "../../services/API";
import { AuthContext } from "../../store/AuthContext";
import CustomNotification from "../CustomNotification";
import { useContext } from "react";
import jwt_decode from "jwt-decode";
import { Color } from "../../utils/Colors";
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

export default function RecordGenWorkoutForm({ onSubmitSuccess, session_id , setCount}) {
  const [errorCount, setErrorCount] = React.useState(0);
  
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  // const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const formRef = React.useRef();

  const [formValue, setFormValue] = React.useState({
    heart_rate: "",
    rate_of_percieved_exertion: "",
    distance: "",
    duration: "",
    calories_burned: "",
    workout_type: "",
  });

  // console.log(user);

  const handleSubmit = async () => {
    try {
      const formData = {
        heart_rate: formValue.heart_rate || 0,
        rate_of_percieved_exertion: formValue.rate_of_percieved_exertion || 0,
        distance: formValue.distance || 0,
        duration: formValue.duration || 0,
        calories_burned: formValue.calories_burned || 0,
        workout_type: formValue.workout_type || "",
        session: session_id,
        recorded_by: decode.user_id,
      };
      const response = await HttpService.post(
        GENERALISED_WORKOUT_LOGS_API_URLS,
        {},
        formData,
        false
      );

      setResponse(response);

      if (response.status === 201) {
        setOpenModal(true);
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setError("Form Submitted");
        onSubmitSuccess();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        <Form ref={formRef} onChange={setFormValue} formValue={formValue}>
          <TextField name="workout_type" label="Exercise Name" />
          <TextField name="heart_rate" label="Heart Rate" />
          <TextField name="rate_of_percieved_exertion" label="RPE" />
          <TextField name="distance" label="Distance" />
          <TextField name="duration" label="Duration" />
          <TextField name="calories_burned" label="Calories Burned" />
          <br />
          <br />

          <Button
            appearance="primary"
            onClick={handleSubmit}
            style={{
              background: Color.Color_C,
              color: Color.Color_A,
              width: 300,
            }}
          >
            Submit
          </Button>

          <CustomNotification
          errorCount={errorCount}
            open={openModal}
            setOpen={setOpenModal}
            message={
              error ? error : (res && res.data && res.data.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
