import React, { useState,useContext } from "react";
import styles from "./../pages/sportsscience/science.module.css";
import DashBoardTopNav from "../components/DashBoardTopNav";
import Sidebar from "../components/CustomSideNav";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";
import Groups2Icon from "@mui/icons-material/Groups2";
import Team from "./team/Team";
import AthleteProfile from "./athleteprofile/AthleteProfile";
import AdminProfile from "./adminprofile/AdminProfile";
// import { Button } from "rsuite";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../store/AuthContext"; 

export default function PageContainer() {
  const [selectedItem, setSelectedItem] = useState(1);
  const [filter, setFilter] = useState(1);
  const [prevClicked, setPrevClicked] = useState(null);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
 
  let filtersdata = [
   
  ];
  
  if (decode.is_account_admin === true ){
    filtersdata = [
      {
        title: "Athlete",
        icon: <SportsKabaddiIcon />,
        filter: 1,
      },
      {
        title: "Admin",
        icon: <AdminPanelSettingsIcon />,
        filter: 2,
      },
      {
        title: "Team",
        icon: <Groups2Icon />,
        filter: 3,
      },
    ];
  } else {
    filtersdata = [
      {
        title: "Athlete",
        icon: <SportsKabaddiIcon />,
        filter: 1,
      },
     
      {
        title: "Team",
        icon: <Groups2Icon />,
        filter: 3,
      },
    ];
  }

  // const handlePlusIconClick = (formFilter, event) => {
  //   event.stopPropagation();
  //   setFilter(formFilter);
  //   setShowForm(true);
  //   setPrevClicked(formFilter);
  // };

  const handleLabelClick = (labelFilter, event) => {
    event.stopPropagation();
    setFilter(labelFilter);
    setSelectedItem(labelFilter);
    // setShowForm(false);
    if (prevClicked !== null && prevClicked !== labelFilter) {
      setPrevClicked(null);
    }
  };

  return (
    <>
      <DashBoardTopNav header={"Profile"} />
      <Sidebar />
     

      <div className={styles.box_science_sports}>
        {filtersdata.map(({ title, icon, filter }) => (
          <label
            style={{ justifyContent: "space-between" }}
            className={`${styles.labelItem_sports} ${
              selectedItem === filter ? styles.selected : ""
            }`}
            key={filter}
            onClick={(event) => handleLabelClick(filter, event)}
          >
            {title}
            {icon}
          </label>
        ))}
      </div>
      <div className={styles.box_science_sports6}>
        {filter === 1 ? (
          <AthleteProfile  />
        ) : filter === 2 ? (
          <AdminProfile />
        ) : filter === 3 ? (
          <Team />
        ) : null
        }
      </div>
     
    </>
  );
}
