import React, { useEffect, useState, useContext } from "react";
import { BASE_URL } from "../../services/API";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../store/AuthContext";
// import { Loader } from "rsuite";
import Label from "../../components/Label";
export default function AthleteDetails({ athlete }) {
 
  const [imageProfile, setImageProfile] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const decode = token ? jwt_decode(token) : "";

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const baseURL = BASE_URL(decode.account);
        const mediaURL = `${baseURL}/media`;
        const imageUrl = `${mediaURL}${athlete.profile_photo}`;
        const response = await fetch(imageUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const blob = await response.blob();
          const objectURL = URL.createObjectURL(blob);
          setImageProfile(objectURL);
          setIsImageLoaded(true);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };
    fetchImageUrls();
  }, [athlete, decode.account, token]);

if (athlete === null) {return null}

  return isImageLoaded === true ? (
    <div style={{ flexDirection: "column", display: "flex" ,marginLeft: "80px",}}>
      <div style={{ flexDirection: "row", display: "flex" }}>
        <img
          src={imageProfile} // Use item.id instead of params.row.id
          alt="Profile"
          height={120}
          width={120}
          style={{ borderRadius: "10%", }}
        />
        <Label sx={{ marginTop: "125px", fontSize:"16px",
          marginLeft:"-120px",}}>{athlete?.name}</Label>
      </div>
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          marginTop: "5px",
          marginLeft:"-15px",
          justifyContent: "center",
          
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Label>Gender : </Label>
          <Label> {athlete?.gender} </Label>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Label>Sports : </Label>
          <Label> {athlete?.sports} </Label>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Label>Injury : </Label>
          <Label>
            
            {athlete?.injury_status === false ? "No Injury" : "Injury"}
          </Label>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Label>Height: </Label>
          <Label>
            
            {athlete?.height }
          </Label>
          
        </div>
      </div>
     
        
    
    
      
    </div>
  ) : (
    <div style={{padding:"20px",margin:"20px"}}>
      <label style={{padding:"20px",margin:"20px"}}>   No data</label>
    </div>

  );
}
