import React, { useEffect } from "react";
import { HttpService } from "../../services/http.service";
import { Button, Loader, Modal } from "rsuite";
import UserCustomDrawer from "../../components/CustomDrawer";
import CustomNotification from "../../components/CustomNotification";
import { Container, Typography } from "@mui/material";
import {
  ATHLETE_PROFILE_API_URL,
  TREATMENT_LOGS_API_URLS,
  USER_API_URL,
} from "../../services/API";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Color } from "../../utils/Colors";
import ViewExerciseTherapy from "./ViewExerciseTherapy";
import ViewElectrotherapy from "./ViewElectrotherapy";
export default function ViewTreatmentLogs({ athlete }) {
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [viewTreatmentLogs, setTreatmentLogs] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [date, setDate] = React.useState();
  const [selectedEventInfo, setSelectedEventInfo] = React.useState(null);
  const [openModal2, setOpenModal2] = React.useState(false);
  const [rehabModal, setRehabModal] = React.useState(false)
  const [rehab, setRehab] = React.useState(1)
  const [count,setCount] = React.useState(0)
  const handleOpenModal = (eventInfo) => {
    setSelectedEventInfo(eventInfo);
    setOpenModal2(true);
  };

  const handleCloseModal = () => {
    setSelectedEventInfo(null);
    setOpenModal2(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${TREATMENT_LOGS_API_URLS}?page=${currentPage}`,
          {},
          false
        );
        // console.log(response.data);
        const modifiedResult = await Promise.allSettled(
          response.data.results.map(async (result) => {
            let athlete_id = "";
            if (athlete === result.athlete) {
              athlete_id = athlete;
            }
            try {
              // Fetch athlete data for each result
              const athleteResponse = await HttpService.get(
                ATHLETE_PROFILE_API_URL + athlete_id + "/",
                {},
                false
              );
              const athlete_name = athleteResponse.data.name;
              const recordResponse = await HttpService.get(
                USER_API_URL + result.recorded_by + "/",
                {},
                true
              );
              const record_name = recordResponse.data.first_name;
              // console.log(sessionResponse.data.name);
              return {
                id: result.id,
                athlete_name: athlete_name,
                record: record_name,

                ...result,
              };
            } catch (error) {
              // Handle individual request error
              console.error(
                `Error fetching data for result ${result.id}:`,
                error
              );
              return null; // or handle the error in an appropriate way
            }
          })
        );

        // Extracting successful results and maintaining the order
        const filteredResults = modifiedResult
          .filter(
            (result) => result.status === "fulfilled" && result.value !== null
          )
          .map((result) => result.value);

        setTreatmentLogs(filteredResults);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        // setTotalPages(Math.ceil(response.data.count / 10));
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [currentPage, athlete,count]);

  // console.log(error)
  const handleOpen = (date, key) => {
    setDate(date);
    setOpen(true);
    setPlacement(key);
  };


  return (
    <>
      <Container
        maxWidth="lg"
        style={{
          margin: "1rem",
          transition: "margin-left 0.3s",
          // marginLeft: isSidebarExpanded ? "15rem" : "5rem",
        }}
      >
        <h1>Treatment Logs</h1>
        {viewTreatmentLogs === undefined ? (
          <>
            <Loader content={"Loading"} />
            {error?.response?.status === 200 ? null : (
              <CustomNotification
                open={openModal}
                setOpen={setOpenModal}
                message={
                  error?.response?.status === 200 ? "Loading" : error?.message
                }
                title={error?.response?.status === 200 ? "Loading" : "Error"}
              />
            )}
          </>
        ) : (
          <div style={{ height: "90%" }}>
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
              initialView="dayGridMonth"
              weekends={true}
              // headerToolbar={{
              //   left: 'prev,next today',
              //   center: 'title',
              //   right: 'dayGridMonth,timeGridWeek,timeGridDay'
              // }}
              events={viewTreatmentLogs?.map((event) => ({
                title: event.treatment_type,
                start: new Date(event.date),
                start_time: event.start_time,
                end_time: event.end_time,
                // end: new Date(event.date),
                description: event.treatment_short_description,
                athlete: event.athlete_name,
                treatment_type: event.treatment_type,
                recordedBy: event.record,
                id: event.id,
              }))}
              eventContent={(eventInfo) => {
                return (
                  <div>
                    <p>

                      <Typography variant="body" fontWeight={"bold"}>
                        {eventInfo.event.extendedProps?.treatment_type === "physiotherapy" ? "Physiotherapy" : eventInfo.event.extendedProps?.treatment_type === "sports_medicine" ? "Sports Medicine" : "Psychology"}
                      </Typography>{" "}
                    </p>
                    <p></p>
                  </div>
                );
              }}
              eventClick={(info) => handleOpenModal(info)}
              dateClick={(arg) => handleOpen(arg.date, "right")}
              height={"500px"}
            />
          </div>
        )}
      </Container>

      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={placement}
        mode={"AddTreatment"}
        athlete={athlete}
        date={date}
        setCount={setCount}
      />
      <Modal size="lg" open={openModal2} onClose={handleCloseModal}>
        <Modal.Header>
       {selectedEventInfo?.event?.extendedProps?.treatment_type === "physiotherapy" ?    <Button
            onClick={() => setRehabModal(true)}
            appearance="default"
            style={{
              backgroundColor: Color.Color_C,
              color: Color.Color_AA,
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            Plan
          </Button>:null}
        </Modal.Header>
        {selectedEventInfo && (
          <>
            <h4>
              Treatment Details:{" "}
              {selectedEventInfo.event.extendedProps?.athlete}
            </h4>

            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>
                    Description:
                  </th>
                  <td style={{ padding: "8px" }}>
                    {selectedEventInfo.event.extendedProps?.description}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>
                    Start Time:
                  </th>
                  <td style={{ padding: "8px" }}>
                    {selectedEventInfo.event.extendedProps?.start_time}
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>
                    End Time:
                  </th>
                  <td style={{ padding: "8px" }}>
                    {selectedEventInfo.event.extendedProps?.end_time}
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>
                    Recorded By:
                  </th>
                  <td style={{ padding: "8px" }}>
                    {selectedEventInfo.event.extendedProps?.recordedBy}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        <br />
      </Modal>
      <Modal size={"lg"} open={rehabModal} onClose={() => setRehabModal(false)}>
        <Modal.Header>
          <Button
            appearance="default"
            onClick={() => setRehab(1)}
            style={{
              backgroundColor: Color.Color_C,
              color: Color.Color_AA,
              marginTop: "1rem",
           
              marginBottom: "1rem",
            }}
          >
            Exercise Therapy
          </Button>{" "}
          <Button
            onClick={() => setRehab(2)}
            appearance="default"
            style={{
              backgroundColor: Color.Color_C,
              color: Color.Color_AA,
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            Electrotherapy
          </Button>
        </Modal.Header>
        <Modal.Body>
          {rehab === 1 ? <ViewExerciseTherapy /> : rehab === 2 ? <ViewElectrotherapy /> : null}
        </Modal.Body>
      </Modal>
    </>
  );
}
