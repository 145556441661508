import React, { useState, useEffect, useContext } from "react";
import { Form, Button, SelectPicker, ButtonToolbar, FlexboxGrid } from "rsuite";
import { GetEvaluationfield } from "../../components/custmomhooks/CustomHooks";
import { HttpService } from "../../services/http.service";
import {
  EVALUATION_RESULTS,
  ATHLETE_PROFILE_API_URL,
} from "../../services/API";
import styles from "./science.module.css";
import CustomNotification from "../../components/CustomNotification";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import { Color } from "../../utils/Colors";
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, type, min, max, value, error, ...rest } =
    props;

  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label}</Form.ControlLabel>
      {type === "range" ? (
        <>
          <input
            name={name}
            type={type}
            min={min}
            max={max}
            {...rest}
            className={styles.slide}
            value={value || ""}
            onChange={(event) => props.onChange(event.target.value)}
          />
          <br />
          <span>{value || ""}</span>
        </>
      ) : type === "date" ? ( // Handle date input type
        <Form.Control
          name={name}
          accepter={accepter}
          type={type}
          {...rest}
          value={value || ""}
          onChange={(event) => props.onChange(event.target.value)}
        />
      ) : (
        <Form.Control
          name={name}
          accepter={accepter}
          {...rest}
          value={value || ""}
          // onChange={(event) => props.onChange(event.target.value)}
        />
      )}
      <br />
      {error === undefined ? null : (
        <span style={{ color: "red" }}>{error}</span>
      )}
    </Form.Group>
  );
});

export default function EvaluationForm({ dept, cat }) {
  const [errorCount, setErrorCount] = React.useState(0);
  const [fields, setFields] = useState([]);
  const [formValue, setFormValue] = useState({}); // Initialize with default values
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [res, setResponse] = useState();
  const [athlete, setAthlete] = useState();
  const [athletes, setAthletes] = useState();
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const athleteId = decode.athlete_id;
  // console.log(athleteId?.length)
  GetEvaluationfield({
    dept,
    cat,
    currentPage,
    setFields,
    fields,
    setFormValue,
  });

  useEffect(() => {
    const fetchAthlete = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`,
          {},
          false
        );
        // console.log(response);
        const modifiedUsers = response.data.results.map((ath) => ({
          value: ath.id,
          label: ath.name,
        }));

        setAthletes(modifiedUsers);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthlete();
  }, [currentPage]);

  const generateValidationRules = () => {
    const rules = {};
    fields.forEach((field) => {
     
      switch (field.field_type.toLowerCase()) {
        
        case "string":
        
          rules[`field_${field.id}`] = (value) => {
           
            if (value === undefined || value.trim() === "") {
              return "Field is required";
            } else if (typeof value === "number") {
              return "Field must be a String";
            }
            return "";
          };
          break;
        case "choice":
          rules[`field_${field.id}`] = (value) => {
           
            if (value === undefined || value.trim() === "") {
              return "Field is required";
            } 
            return "";
          };
          break;

        case "integer":
          rules[`field_${field.id}`] = (value) => {
            
            if (value === undefined ) {
              return "Field is required";
            } else if (!Number.isInteger(Number(value))) {
              return "Field must be a number";
            }
            return "";
          };
          break;
        case "int":
          rules[`field_${field.id}`] = (value) => {
           
            if (value === undefined ) {
              return "Field is required";
            } else if (!Number.isInteger(Number(value))) {
              return "Field must be a number";
            }
            return "";
          };
          break;
        case "float":
          rules[`field_${field.id}`] = (value) => {
           
            if (value === undefined ) {
              return "Field is required";
            } else if (isNaN(value)) {
              return "Field must be a number";
            }
            return "";
          };
          break;
        case "range":
          rules[`field_${field.id}`] = (value) => {
            if (value === undefined ) {
              return "Field is required";
            } else if (isNaN(value)) {
              return "Field must be a number";
            }
            return "";
          };
          break;
       
        default:
          break;
      }
    });
    return rules;
  };

  const validationRules = generateValidationRules();
  const handleSubmit = async () => {
    try {
      const formErrors = {};
      Object.keys(validationRules).forEach((fieldName) => {
        const rule = validationRules[fieldName];
        const value = formValue[fieldName];
        const error = rule(value);
        if (error) {
          formErrors[fieldName] = error;
        }
      });

      // If there are errors, set them and prevent form submission
      if (Object.keys(formErrors).length > 0) {
        setError(formErrors);
        return;
      }

      // Create an array of data objects for the POST request
      const postData = fields.map((field) => {
        const fieldValue = formValue[`field_${field.id}`];
        const fieldType = field.field_type.toLowerCase(); // Convert field type to lowercase
        // console.log(fieldType, fieldValue);
        // Initialize an empty data object with all possible values
        const data = {
          integer_value: null,
          boolean_value: false,
          string_value: null,
          choice_value: null,
          float_value: null,
          date_value: null,
          athlete: athleteId?.length === 0 ? athlete : athleteId,
          field: field.id, // Change this based on field structure
          department:dept
        };

        // Set the value based on the field type
        switch (fieldType) {
          case "string":
            data.string_value = fieldValue || "";
            break;
          case "datefield":
            data.date_value = fieldValue || "";
            break;
          case "int":
            data.integer_value = fieldValue || "";
            break;
          case "integer":
            data.integer_value = fieldValue || "";
            break;

          case "range":
            data.integer_value = fieldValue || "";
            break;
          case "boolean":
            data.boolean_value = fieldValue || false;
            break;
          case "choice":
            data.choice_value = fieldValue || null;
            break;
          case "float":
            data.float_value = fieldValue || null;
            break;
          case "date":
            data.date_value = fieldValue || null;
            break;
          default:
            break;
        }

        return data;
      });
      // console.log(postData);
      const response = await HttpService.post(
        EVALUATION_RESULTS,
        {},
        postData,
        false
      );

      setResponse(response);

      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setError("Form Submitted"); // Update error state to indicate success
        setOpenModal(true);
        setFormValue({});
        return; // Exit early to prevent further execution
      }
    } catch (error) {
      console.warn(error);
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      }
      if (error.response && error.response.status !== 201) {
        // If there's a response indicating a server error (status other than 201), open the modal
        setErrorCount(prevCount => prevCount + 1);
        
        setOpenModal(true);
      }
    }
  };
  

  return (
    <div style={{ padding: "2rem" }}>
      <FlexboxGrid>
        <FlexboxGrid.Item>
          <Form onChange={setFormValue} formValue={formValue}>
            {athleteId?.length === 0 ? (
              <SelectPicker
                loading={athletes === undefined}
                label={
                  athletes === undefined ? "Select Athlete" : athlete?.name
                }
                data={athletes || []}
                onSelect={(value) => setAthlete(value)}
                style={{ width: 300 }}
              />
            ) : null}

            <br />
            <br />

            {/* <Typography>Evaluation Form</Typography> */}
            {fields?.map((field) => {
              if (
                field.field_type === "string" ||
                field.field_type === "String"
              ) {
                return (
                  <TextField
                    key={field.id}
                    name={`field_${field.id}`}
                    label={field.name}
                    value={formValue[`field_${field.id}`] || ""}
                    onChange={(value) => {
                      setFormValue({
                        ...formValue,
                        [`field_${field.id}`]: value,
                      });
                    }}
                    error={error[`field_${field.id}`]}
                  />
                );
              } else if (
                field.field_type === "choice" ||
                field.field_type === "Choice"
              ) {
                return (
                  <Form.Group
                    key={`field_${field.id}`}
                    controlId={`field_${field.id}`}
                  >
                    <Form.ControlLabel>{field.name}</Form.ControlLabel>
                    <SelectPicker
                      style={{ width: 300 }}
                      name={`field_${field.id}`}
                      data={field.field_options.map((option) => ({
                        label: option,
                        value: option,
                      }))}
                      searchable={false}
                      value={formValue[`field_${field.id}`] || ""}
                      onChange={(value) => {
                        setFormValue({
                          ...formValue,
                          [`field_${field.id}`]: value,
                        });
                      }}
                      
                    />
                    <br/>
                     {error === undefined ? null : (
        <span style={{ color: "red" }}>{error[`field_${field.id}`]}</span>
      )}
                  </Form.Group>
                );
              } else if (
                field.field_type === "range" ||
                field.field_type === "Range"
              ) {
                return (
                  <TextField
                    key={field.id}
                    name={`field_${field.id}`}
                    label={field.name}
                    accepter="input"
                    type="range"
                    min={field.field_options[0]}
                    max={field.field_options[1]}
                    value={formValue[`field_${field.id}`] || ""}
                    onChange={(value) => {
                      setFormValue({
                        ...formValue,
                        [`field_${field.id}`]: value,
                      });
                    }}
                    error={error[`field_${field.id}`]}
                  />
                );
              } else if (
                field.field_type === "date" ||
                field.field_type === "datefield" ||
                field.field_type === "DateField"
              ) {
                return (
                  <TextField
                    key={field.id}
                    name={`field_${field.id}`}
                    label={field.name}
                    accepter="input"
                    type="date" // Change the input type to "date"
                    value={formValue[`field_${field.id}`] || ""}
                    onChange={(value) => {
                      setFormValue({
                        ...formValue,
                        [`field_${field.id}`]: value,
                      });
                    }}
                    error={error[`field_${field.id}`]}
                  />
                );
              } else {
                return (
                  <TextField
                    key={field.id}
                    name={`field_${field.id}`}
                    label={field.name}
                    value={formValue[`field_${field.id}`] || ""}
                    onChange={(value) => {
                      setFormValue({
                        ...formValue,
                        [`field_${field.id}`]: value,
                      });
                    }}
                    error={error[`field_${field.id}`]}
                  />
                );
              }
            })}

            <ButtonToolbar>
              {decode.roles?.includes("Athlete") ? (
                <Button
                  appearance="primary"
                  style={{
                    background: Color.Color_C,
                    color: Color.Color_A,
                    // width: "40%",
                  }}
                  onClick={handleSubmit}
                  disabled={athlete === undefined}
                >
                  Submit Your Data
                </Button>
              ) : (
                <Button
                  appearance="primary"
                  style={{
                    background: Color.Color_C,
                    color: Color.Color_A,
                    width: "40%",
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              )}
            </ButtonToolbar>
            <CustomNotification
            errorCount={errorCount}
              open={openModal}
              setOpen={setOpenModal}
              nav={"/sportscience"}
              message={
                error ? error : (res && res.data && res.data.message) || ""
              }
              title={error === "Form Submitted" ? "Success" : "Error"}
            />
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
}
