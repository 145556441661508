import React, { useState } from "react";
import { HttpService } from "../../services/http.service";
import { TEAM_API_URL } from "../../services/API";
import { Typography } from "@mui/material";
import { Button } from "rsuite";

export default function DeleteTeam({ id, name ,onSucess,setCount}) {
    const [confirmName, setConfirmName] = useState();
    const [error, setError] = useState(null);

    const deleteTeam = async () => {
        try {
            const response = await HttpService.delete(TEAM_API_URL + id + "/",{}, false);

            // Show success alert
            setCount(prev=>prev +1)
            alert("Team deleted successfully");
            onSucess()


        } catch (e) {
            console.warn(e);
            // Set error state for the error boundary to catch
            setError(e);
        }
    };

    // Error boundary component
    const ErrorBoundary = ({ error }) => {
        if (error) {
            return (

                alert("An error occurred while deleting the Team.")

            );
        }
        return null;
    };

    return (
        <div>
            <ErrorBoundary error={error} />
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography>
                    Are you sure you want to delete the team: {name}?
                </Typography>
                <Typography>To delete, confirm the team name:</Typography>
                <br />
                <input type="text" onChange={(e) => setConfirmName(e.target.value)} onPaste={(e) => e.preventDefault()} />
            </div>
            <br />
            <Button
                appearance="primary"
                color={"red"}
                disabled={name !== confirmName}
                onClick={deleteTeam}
            >
                Delete
            </Button>
        </div>
    );
}
