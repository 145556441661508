import React, { useEffect, useState } from "react";
// import { Button } from "rsuite"; // Import SelectPicker
import { Card, CardContent, Typography } from "@mui/material";
import Chart from "chart.js/auto";
import { Scatter } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
// import { Color } from "../../utils/Colors";
import styles from "./../../pages/sportsscience/science.module.css";
import { CascadingDropDownBivariate } from "../../components/CascadingDropDown";
import { ATHLETE_PROFILE_API_URL, EVALUATION_RESULTS } from "../../services/API";
import { HttpService } from "../../services/http.service";

Chart.register(CategoryScale);

export default function Bivariate() {

  const [results, setResultBi] = useState([]);
  const [filterResults, setFilterResultsBi] = useState([]);
  const [selectedDepartmentBi, setSelectedDepartmentBi] = useState(null);
  const [selectedCategoryBi, setSelectedCategoryBi] = useState(null);
  const [selectedAthleteBi, setSelectedAthleteBi] = useState(null); // Add state for selected athlete
  const [departmentsBi, setDepartmentsBi] = useState([]);
  const [categoriesBi, setCategoriesBi] = useState([]);
  const [athletesBi, setAthletesBi] = useState(); // State to store athlete options
  // const [showGenerateButtonBi, setShowGenerateButtonBi] = useState(true);
  const [filter, setFilterBi] = useState(0);
  const [subCategoriesBi, setSubCategoriesBi] = useState([]);
  const [selectedFieldsBi, setSelectedFieldsBi] = useState([]);
  const [
    availableFieldsForFirstDropdownBi,
    setAvailableFieldsForFirstDropdownBi,
  ] = useState([]);
  const [
    availableFieldsForSecondDropdownBi,
    setAvailableFieldsForSecondDropdownBi,
  ] = useState([]);
useEffect(()=>{
   const fetchResultBi = async () => {
    try {
      // Make the first API call for Field 1
      const response = await HttpService.get(
        `${EVALUATION_RESULTS}?department=${selectedDepartmentBi}&categories=${selectedCategoryBi}`,
        {},
        false
      );

      const modifiedResult1 = await Promise.all(
        response.data.results.map(async (result, index) => {
          try {
            // Fetch athlete data for each result
            const athleteResponse = await HttpService.get(
              ATHLETE_PROFILE_API_URL + result.athlete + "/",
              {},
              false
            );

            const athleteName = athleteResponse.data.name;
            const athleteId = athleteResponse.data.id;
            const value = determineValueBi(result);

            // Only include the result if athlete details are available
            if (athleteName !== "N/A") {
              return {
                id: index,
                athlete: athleteName,
                athlete_id: athleteId,
                field_id: result.field,
                value,
                date: result.created_at.slice(0, 10),
                field_name: result.field_name,
              };
            }

            return null; // Exclude the result when athlete is "N/A"
          } catch (athleteError) {
            console.warn(
              "Error fetching athlete details:",
              athleteError.message
            );
            // If athlete details cannot be fetched, return null to exclude the result
            return null;
          }
        })
      );

      // Filter out null results caused by failed athlete fetch or when athlete is "N/A"
      const filteredResults1 = modifiedResult1.filter(
        (result) => result !== null
      );

      setResultBi(filteredResults1);
      // setShowGenerateButtonBi(false);
      setFilterBi(1);
    } catch (error) {
      console.warn(error);
    }
  };
  if (selectedCategoryBi !==null || selectedDepartmentBi !==null ){
    fetchResultBi()}
  
},[selectedDepartmentBi,selectedCategoryBi])
 

  const determineValueBi = (result) => {
    return result.boolean_value !== false
      ? result.boolean_value
      : result.choice_value !== null
        ? result.choice_value
        : result.date_value !== null
          ? result.date_value
          : result.float_value !== null
            ? result.float_value
            : result.integer_value !== null
              ? result.integer_value
              : result.remarks !== null
                ? result.remarks
                : result.string_value !== null
                  ? result.string_value
                  : null;
  };

  // const filterResultsByAthleteBi = () => {
  //   if (selectedAthleteBi === null) {
  //     // If no athlete is selected, reset the filterResults to the original results
  //     setFilterResultsBi(resultsBi);
  //   } else {
  //     // Filter the original results based on the selected athlete
  //     const filteredResults = resultsBi.filter(
  //       (result) => result.athlete_id === selectedAthleteBi
  //     );
  //     setFilterResultsBi(filteredResults);
  //   }
  //   setFilterBi(2);
  // };
 
  const resetSelectionBi = () => {
    setSelectedDepartmentBi(null);
    setSelectedCategoryBi(null);
    setSelectedAthleteBi(null);
    setResultBi([]);
    setFilterResultsBi([]);
    // setShowGenerateButtonBi(true);
    setFilterBi(0);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }

  const customColors = [
    "rgba(75, 192, 192, 0.6)",
    "rgba(255, 99, 132, 0.6)",
    "rgba(255, 159, 64, 0.6)",
    "rgba(255, 205, 86, 0.6)",
    "rgba(54, 162, 235, 0.6)",
    "rgba(153, 102, 255, 0.6)",
    "rgba(201, 203, 207, 0.6)",
  ];

  function filterScatterData(data, selectedFields) {
    try {
      if (data === undefined) throw new Error("Data is undefined");
      
      const datasets = [];
      const filteredData = data?.filter((result) =>
        selectedFields.includes(result.field_id)
      );
      const fieldDataMap = new Map();
  
      filteredData?.forEach((result) => {
        const { field_id, field_name, value } = result;
        if (!fieldDataMap.has(field_id)) {
          fieldDataMap.set(field_id, { label: field_name, data: [] });
        }
        fieldDataMap.get(field_id).data.push(value);
      });
  
      if (selectedFields.length === 2) {
        const [xField, yField] = selectedFields;
        const xData = fieldDataMap?.get(xField)?.data;
        const yData = fieldDataMap?.get(yField)?.data;
  
        if (!xData || !yData) throw new Error("X or Y data is undefined");
  
        const backgroundColor =
          customColors[datasets.length % customColors.length];
  
        const scatterData = xData.map((xValue, index) => ({
          x: xValue,
          y: yData[index],
        }));
  
        datasets.push({
          label: `${fieldDataMap.get(xField).label} vs ${fieldDataMap.get(yField).label}`,
          data: scatterData,
          backgroundColor,
        });
      }
  
      return datasets;
    } catch (error) {
      console.error("An error occurred in filterScatterData:", error);
      return []; // Return an empty array in case of error
    }
  }
  
  const columns = [
    {
      field: "athlete",
      headerName: "Name",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "field_name",
      headerName: "Test Name",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      headerName: "Result",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
  ];
  
  
  return (
    <div>
          <div className={styles.belownav}>
 <CascadingDropDownBivariate
                  selectedDepartment={selectedDepartmentBi}
                  setSelectedDepartment={setSelectedDepartmentBi}
                  selectedCategory={selectedCategoryBi}
                  setSelectedCategory={setSelectedCategoryBi}
                  departments={departmentsBi}
                  setDepartments={setDepartmentsBi}
                  categories={categoriesBi}
                  setCategories={setCategoriesBi}
                  athletes={athletesBi}
                  setAthletes={setAthletesBi}
                  results={results}
                  selectedAthlete={selectedAthleteBi}
                  setSelectedAthlete={setSelectedAthleteBi}
                  subCategories={subCategoriesBi}
                  setSubCategories={setSubCategoriesBi}
                  selectedFields={selectedFieldsBi}
                  setSelectedFields={setSelectedFieldsBi}
                  availableFieldsForFirstDropdown={
                    availableFieldsForFirstDropdownBi
                  }
                  setAvailableFieldsForFirstDropdown={
                    setAvailableFieldsForFirstDropdownBi
                  }
                  availableFieldsForSecondDropdown={
                    availableFieldsForSecondDropdownBi
                  }
                  setAvailableFieldsForSecondDropdown={
                    setAvailableFieldsForSecondDropdownBi
                  }
                 
                  onClean={resetSelectionBi}
                />
            </div>
          
     
      {filter === 1 ? (
        <div>
          {selectedFieldsBi[1]?.length === 0 ? (
            <Typography variant="h6" textAlign={"center"}>
              No Data
            </Typography> // Use results.length to check if there are no results
          ) : (
            <div
              style={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              {selectedFieldsBi[1]===undefined ? null:  <><Card
                  sx={{
                    width: "40rem",
                    height: "20rem",
                    marginBottom: "2rem",
                    margin: "1rem",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "10px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <CardContent>
                    <Scatter
                      data={{
                        datasets: filterScatterData(results, selectedFieldsBi),
                      }}
                      options={{
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: selectedFieldsBi.length > 0
                                ? results.find(
                                  (result) => result.field_id === selectedFieldsBi[0]
                                )?.field_name
                                : "X-Axis Label",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: selectedFieldsBi.length > 1
                                ? results.find(
                                  (result) => result.field_id === selectedFieldsBi[1]
                                )?.field_name
                                : "Y-Axis Label",
                            },
                          },
                        },
                        plugins: {
                          title: {
                            display: true,
                            text: selectedFieldsBi.length === 2
                              ? `${results.find(
                                (result) => result.field_id === selectedFieldsBi[0]
                              )?.field_name} vs ${results.find(
                                (result) => result.field_id === selectedFieldsBi[1]
                              )?.field_name}`
                              : "Scatter Plot Title",
                          },
                          legend: {
                            display: true,
                            position: "bottom",
                          },
                        },
                      }} />
                  </CardContent>
                </Card><div
                  style={{ marginLeft: "30px", marginTop: "30px" }}>
                    <Box key={Math.random().toLocaleString()}>
                      <DataGrid
                        getRowId={(row) => row.id}
                        rows={results}
                        columns={columns}
                        pagination
                        paginationMode="client"
                        pageSize={10}
                        slots={{toolbar:CustomToolbar}}
                        />
                    </Box>
                    {/* <Button
                      style={{
                        marginTop: "2rem",

                        background: Color.Color_C,
                        color: Color.Color_A,
                      }}
                      appearance="primary"
                      onClick={() => handleExportCSV(results, "Results")}
                    >
                      Export Data
                    </Button> */}
                  </div></>}
             
             
            </div>
          )}
        </div>
      ) : filter === 2 ? (
        <>
          {filterResults.length === 0 ? (
            <Typography variant="h6" textAlign={"center"}>
              No Data
            </Typography> // Use filterResults.length to check if there are no results
          ) : (
            <div
              style={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              {" "}
              <Container
                maxWidth="lg"
                style={{ marginTop: "4rem", marginBottom: "2rem" }}
              >
                <Box key={Math.random().toLocaleString()}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={filterResults}
                    columns={columns}
                    pagination
                    paginationMode="client"
                    pageSize={10}
                  />
                </Box>
               
              </Container>
              <Card
                sx={{
                  width: "40rem",
                  height: "20rem",
                  marginBottom: "2rem",
                  margin: "1rem",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Scatter
                    data={{
                      datasets: filterScatterData(
                        filterResults,
                        selectedFieldsBi
                      ),
                    }}
                    options={{
                      scales: {
                        x: {
                          title: {
                            display: true,
                            text:
                              selectedFieldsBi.length > 0
                                ? results.find(
                                    (result) =>
                                      result.field_id === selectedFieldsBi[0]
                                  )?.field_name
                                : "X-Axis Label",
                          },
                        },
                        y: {
                          title: {
                            display: true,
                            text:
                              selectedFieldsBi.length > 1
                                ? results.find(
                                    (result) =>
                                      result.field_id === selectedFieldsBi[1]
                                  )?.field_name
                                : "Y-Axis Label",
                          },
                        },
                      },
                      plugins: {
                        title: {
                          display: true,
                          text:
                            selectedFieldsBi.length === 2
                              ? `${
                                  results.find(
                                    (result) =>
                                      result.field_id === selectedFieldsBi[0]
                                  )?.field_name
                                } vs ${
                                  results.find(
                                    (result) =>
                                      result.field_id === selectedFieldsBi[1]
                                  )?.field_name
                                }`
                              : "Scatter Plot Title",
                        },
                        legend: {
                          display: true,
                          position: "bottom",
                        },
                      },
                    }}
                  />
                </CardContent>
              </Card>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}
