import React, { useEffect } from "react";
import { Message } from "rsuite";
// import { GetNotificationRecipents } from "../../components/custmomhooks/CustomHooks";
import { HttpService } from "../../services/http.service";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import {
  NOTIFICATION_API_URLS,
  NOTIFICATION_RECIPENTS,
  USER_API_URL,
} from "../../services/API";

export default function ViewNotificationRecipents({ onSubmitSuccess }) {
  // const [open, setOpen] = React.useState(false);
  // const [placement, setPlacement] = React.useState();
  const [notification, setNotificationRecipents] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1); // Total number of pages

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${NOTIFICATION_RECIPENTS}?page=${currentPage}`,{}, false
        );
        // console.log(response.data);
        const modifiedResult = await Promise.allSettled(
          response.data.results.map(async (result) => {
            try {
              // Fetch athlete data for each result
              const notificationResponse = await HttpService.get(
                NOTIFICATION_API_URLS + result.notification + "/",{}, false
              );

              const notification_name = notificationResponse.data.content;
              const recipientResponse = await HttpService.get(
                USER_API_URL + result.recipient + "/",{}, true
              );
              const recipient_name = recipientResponse.data.first_name;
              // console.log(sessionResponse.data.name);
              return {
                id: result.id,
                notification_name: notification_name,
                recipient_name: recipient_name,
                date: result.created_at.slice(0, 10),

                ...result,
              };
            } catch (error) {
              // Handle individual request error
              console.error(
                `Error fetching data for result ${result.id}:`,
                error
              );
              return null; // or handle the error in an appropriate way
            }
          })
        );

        // Extracting successful results and maintaining the order
        const filteredResults = modifiedResult
          .filter(
            (result) => result.status === "fulfilled" && result.value !== null
          )
          .map((result) => result.value);

        setNotificationRecipents(filteredResults);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10));
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [currentPage]);

  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "notification_name",
      headerName: "Notification Content",
      width: 450,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "recipient_name",
      headerName: "Send To",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "is_delivered",
      headerName: "Delivery",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "is_read",
      headerName: "Read",
      type: "time",
      width: 110,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "is_deleted",
      headerName: "Deleted",
      sortable: true,
      width: 110,
      headerAlign: "center",
      align: "center",
    },
  ];
  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1); // Page is 0-based, API expects 1-based
  };
  // console.log(notification);
  return (
    <>
      {notification === undefined ? (
        <Message showIcon type="error">
          No Data
        </Message>
      ) : (
        <Box>
          <DataGrid
            rows={notification}
            columns={columns}
            pagination
            paginationMode="server"
            rowCount={totalPages * 10} // Total number of rows (assuming 10 per page)
            onPageChange={handlePageChange} // Handle page changes
            pageSize={10} // Number of rows per page
            page={currentPage - 1} // Current page (0-based)
            paginationComponentProps={{
              // Customize the appearance of the pagination component
              disableNext: currentPage === totalPages,
              disablePrev: currentPage === 1,
            }}
          />
        </Box>
      )}
    </>
  );
}
