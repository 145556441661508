import React from "react";
import "./../landing/styles.css";
import {
  Form,
  Button,
  SelectPicker,
  FlexboxGrid,
  // DatePicker,
} from "rsuite";
import { HttpService } from "../../services/http.service";
import {
  ATHLETE_PROFILE_API_URL,
  RECORD_INJURY_API_URLS,
  // USER_API_URL,
} from "../../services/API";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../store/AuthContext";
import CustomNotification from "../CustomNotification";
import { useEffect,useContext } from "react";
import { Color } from "../../utils/Colors";
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

export default function RecordInjuryForm({ onSubmitSuccess,selectedDate,setCount }) {
  const [errorCount, setErrorCount] = React.useState(0);
 
  const [athlete, setAthlete] = React.useState();
  const [athletes, setAthletes] = React.useState();
  // const [user, setUser] = React.useState();
  // const [users, setUsers] = React.useState();
  // const [date, setDate] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number

  const formRef = React.useRef();
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const [formValue, setFormValue] = React.useState({
    injury_type: "",
    description: "",
    recovery_status: "",
  });
  const inputDate = new Date(selectedDate);

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');
  
  const formattedDate = `${year}-${month}-${day}`;
 
  useEffect(() => {
    const fetchAthlete = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`,{}, false
        );

        const modifiedAthlete = response.data.results.map((ath) => ({
          value: ath.id,
          label: ath.name,
        }));

        setAthletes(modifiedAthlete);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthlete();
  }, [currentPage]);

  // console.log(user);

  const handleSubmit = async () => {
    
    try {
      const formData = {
        injury_type: formValue.injury_type || "",
        description: formValue.description || "",
        recovery_status: formValue.recovery_status || "",
        date: formattedDate,
        athlete,
        injury_recorded_by: decode.user_id,
      };
      const response = await HttpService.post(
        RECORD_INJURY_API_URLS,
        {},
        formData,false
      );

      setResponse(response);

      if (response.status === 201) {
        setOpenModal(true);
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setError("Form Submitted");
        onSubmitSuccess();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        <Form ref={formRef} onChange={setFormValue} formValue={formValue}>
          <SelectPicker
            label="Select Athlete"
            data={athletes}
            onSelect={(value) => setAthlete(value)}
            style={{ width: 224 }}
          />
           <br />
          <br />
          <TextField name="injury_type" label="Injury Type" />
          <TextField name="description" label="Description" />
          <TextField name="recovery_status" label="Recovery Status" />
         
            <Button appearance="primary" onClick={handleSubmit} style={{background:Color.Color_C,color:Color.Color_A,width:300}}>
              Submit
            </Button>
        
          <CustomNotification
            open={openModal}
            errorCount={errorCount}
            setOpen={setOpenModal}
            message={
              error ? error : (res && res.data && res.data.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
