
import { styled } from "@mui/material/styles";
import { Button, Typography, Container } from "@mui/material";
import Label from "./Label";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { HttpService } from "../services/http.service";
import { useContext } from "react";
import { AuthContext } from "../store/AuthContext";
import jwt_decode from "jwt-decode";
import { USER_API_URL } from "../services/API";
import LoginScreen from "../utils/Login";
import { useNavigate } from "react-router-dom";
const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
export default function DeleteAccount() {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
const navigate = useNavigate()
  useEffect(() => {
    if (token) {
      const decode = jwt_decode(token); // Move decode variable declaration inside useEffect
      const fetchData = async () => {
        try {
          const response = await HttpService.get(USER_API_URL + decode.user_id + "/");
          setUser(response.data.email);
        } catch (error) {
          console.error("Error fetching user:", error);
          // Optionally, provide feedback to the user about the error
        }
      };

      fetchData();
    }
  }, [token]); // Update the effect dependency to include token

  const handleDelete = async () => {
    if (user === email) {
      const decode =jwt_decode(token)
      try {
        await HttpService.delete(USER_API_URL + decode.user_id + "/").then(()=>{
          alert("Account Delete is Successfull")
          navigate("/")
        })
        // Optionally, provide feedback to the user that account deletion was successful
      } catch (error) {
        console.error("Error deleting account:", error);
        // Optionally, provide feedback to the user about the error
      }
    } else {
      alert("Invalid email")
    }
  };

  if (token === null) {
    return <LoginScreen />;
  }

  return (
    <div>
      <Container>
        <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
          <Label>Enter Your Email</Label>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Typography variant="h3" paragraph></Typography>

          <br />

          <Button disabled={email !== user} onClick={handleDelete} size="large" variant="contained">
            Delete Account
          </Button>
        </ContentStyle>
      </Container>
    </div>
  );
}

