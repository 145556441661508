import React, { useState } from "react";
import styles from "./science.module.css";
import Health from "./subcategories/strengthandconditioning/Health";
import Skill from "./subcategories/strengthandconditioning/Skill";
import PlaceHolder from "./PlaceHolder";

export default function StrengthConditioning({label}) {
  // const [open, setOpen] = useState(false);
  // const [placement, setPlacement] = useState();
  const [drawerTitle, setDrawerTitle] = useState();
  // const [dept, setDept] = useState();
  // const [cat, setCat] = useState();
  
  const data = [
    {
      name: "Health & Fitness",

      cardColor: "#c5cae9",
    },
    {
      name: "Skill",

      cardColor: "#c5cae9",
    },
  ];

  const handleOpen = ({ name, key, dept, cat }) => {
    // setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
    // setDept(dept);
    // setCat(cat);
  };

  return (
    <div className={styles.sport_main} >
    
      <div className={styles.sports_main_inner}>
          <select
            className={styles.dropdown}
            onChange={(e) => {
              const selectedValue = e.target.value;
              const selectedItem = data.find(
                (item) => item.name === selectedValue
              );

              if (selectedItem) {
                handleOpen({
                  name: selectedItem.name,
                  dept: selectedItem.dept,
                  cat: selectedItem.cat,
                });
              }
            }}
          >
            <option value="">{label}</option>
            {data.map((item) => (
              <option key={item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
       
      </div>

      {drawerTitle===undefined?<PlaceHolder/> :drawerTitle === "Health & Fitness" ? (
        <Health mode={drawerTitle} />
      ) : drawerTitle === "Skill" ? (
        <Skill mode={drawerTitle} />
      ) : null
      }
    </div>
  );
}
