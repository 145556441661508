import React, { useState, useEffect } from "react";
import { FetchResultsByFieldAthlete } from "../../components/custmomhooks/CustomHooks";
import CustomBarChart from "./BarChart";
import styles from "./injury.module.css";
import { SelectPicker } from "rsuite";
import { ATHLETE_PROFILE_API_URL } from "../../services/API";
import { HttpService } from "../../services/http.service";

import { Typography } from "@mui/material";
import NODATA from "./../../assets/image/noChart.svg";
import NOTSELECTED from "./../../assets/image/notSelected.jpg";
import CustomPieChart from "./CustomPieChart";

export default function PainMonitoring() {
  const [athlete, setAthlete] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);  
  const [dataResults, setDataResults] = useState({
    occuranceDate: { data: [], title: "Occurance Date" },
    rehabDate: { data: [], title: "Rehab Start Date" },
    nprs: { data: [], title: "NPRS" },
    matchTime: { data: [], title: "Match Time Loss Injuries" },
    generalTime: { data: [], title: "General Time Loss Injuries" },
    medicalTime: { data: [], title: "Medical Time Loss Injuries" },
    playerTime: { data: [], title: "Player Time Loss Injuries" },
    variation: { data: [], title: "Diurnal Variation" },
    nature: { data: [], title: "Nature" },
    duration: { data: [], title: "Duration" },
    severity: { data: [], title: "Severity" },
    type: { data: [], title: "Type" },
    primaryDiagnosis: { data: [], title: "Primary Diagnosis" },
    side: { data: [], title: "Side" },
    bodyParts: { data: [], title: "Body Parts" },
    typeoOfInjury: { data: [], title: "Type of Injury" },
    injuryStatus: { data: [], title: "Injury Status" },
    evaluation: { data: [], title: "Evaluation" },
  });

  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true);

        const fetchPromises = [
          FetchResultsByFieldAthlete({ dept: 1, cat: 3, field: 12, athlete }),
          FetchResultsByFieldAthlete({ dept: 1, cat: 3, field: 20, athlete }),
          FetchResultsByFieldAthlete({ dept: 1, cat: 3, field: 15, athlete }),
          FetchResultsByFieldAthlete({ dept: 1, cat: 3, field: 13, athlete }),
          FetchResultsByFieldAthlete({ dept: 1, cat: 3, field: 19, athlete }),
          FetchResultsByFieldAthlete({ dept: 1, cat: 45, field: 268, athlete }),
          FetchResultsByFieldAthlete({ dept: 1, cat: 45, field: 269, athlete }),
          FetchResultsByFieldAthlete({ dept: 1, cat: 45, field: 270, athlete }),
          FetchResultsByFieldAthlete({ dept: 1, cat: 45, field: 271, athlete }),
          FetchResultsByFieldAthlete({
            dept: 13,
            cat: 115,
            field: 622,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 13,
            cat: 115,
            field: 623,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 13,
            cat: 115,
            field: 624,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 13,
            cat: 115,
            field: 625,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 13,
            cat: 116,
            field: 626,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 13,
            cat: 116,
            field: 627,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 13,
            cat: 116,
            field: 632,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 13,
            cat: 116,
            field: 633,
            athlete,
          }),
          FetchResultsByFieldAthlete({ dept: 1, cat: 3, field: 14, athlete }),
        ];

        const results = await Promise.all(fetchPromises);

        setDataResults((prevData) => ({
          ...prevData,
          nature: { data: results[0], title: "Nature" },
          duration: { data: results[1], title: "Duration" },
          severity: { data: results[2], title: "Severity" },
          type: { data: results[3], title: "Type" },
          nprs: { data: results[4], title: "NPRS" },
          matchTime: { data: results[5], title: "Match Time Loss Injuries" },
          generalTime: {
            data: results[6],
            title: "General Time Loss Injuries",
          },
          medicalTime: {
            data: results[7],
            title: "Medical Time Loss Injuries",
          },
          playerTime: { data: results[8], title: "Player Time Loss Injuries" },
          primaryDiagnosis: { data: results[9], title: "Primary Diagnosis" },
          side: { data: results[10], title: "Side" },
          bodyParts: { data: results[11], title: "Body Parts" },
          typeoOfInjury: { data: results[12], title: "Type of Injury" },
          injuryStatus: { data: results[13], title: "Injury Status" },
          evaluation: { data: results[14], title: "Evaluation" },
          occuranceDate: { data: results[15], title: "Occurance Date" },
          rehabDate: { data: results[16], title: "Rehab Start Date" },
          variation: { data: results[17], title: "Diurnal Variation" },
        }));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (athlete) {
      fetchResults();
    }
  }, [athlete]);

  useEffect(() => {
    const fetchAthlete = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`,
          {},
          false
        );
        const modifiedAthlete = response.data.results.map((ath) => ({
          value: ath.id,
          label: ath.name,
        }));
        setAthletes(modifiedAthlete);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthlete();
  }, [currentPage]);

  const ScrollableLabels = ({ data }) => {
    const labelValues = {};

    // Collect values for each label
    Object.entries(data).forEach(([key, value]) => {
      const labelKey = value.field_name;

      if (!labelValues[labelKey]) {
        labelValues[labelKey] = [];
      }

      labelValues[labelKey].push(value.value);
    });

    return (
      <div className={styles.scroll_container}>
        {Object.entries(labelValues).map(([label, values], index) => (
          <div key={index} className={styles.label}>
            <strong>{label}:</strong> {values.join(", ")}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className={styles.belownav}>
        <SelectPicker
          label={ "Select Athlete" }
          data={athletes}
          onSelect={(value) => setAthlete(value)}
          className={styles.selectpicker}
          onClean={() => {
            setAthlete([]);
          }}
        />
      </div>
      {loading === true ? (
        <div
          style={{
            marginTop: "100px",
            marginLeft:"240px",
            transition: "margin-left 0.3s",
          }}
        >
          <Typography variant="h4" color="darkviolet" textAlign="center">
            {athlete === null ? "Select Athlete" : "Loading...."}
          </Typography>
        </div>
      ) : (
        <div className={styles.box_science_sports7}>
          <div className={styles.chartcontent}>
            <div className={styles.chartwrapper}>
              {Object.entries(dataResults).map(([key, data]) => (
                <div className={styles.chartitem} key={key}>
                  <div className={styles.chartbox}>
                    {data?.data?.length <= 0 ? (
                      <div
                        style={{
                          width: "250px",
                          height: "250px",
                          margin: "0 auto",
                        }}
                      >
                       
                        {athlete!== null ? (
                          <>
                            <img src={NODATA} alt="No data" />
                            <h6 style={{ textAlign: "center" }}>
                              No Records Available:{data?.title}
                            </h6>
                          </>
                        ) : (
                          <>
                            <h6> Athlete Not Selected</h6>
                            <img
                              src={NOTSELECTED}
                              style={{ width: "200px", height: "200px" }}
                              alt="No data"
                            />
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        {data?.title === "Nature" ||
                        data?.title === "Duration" ||
                        data?.title === "Severity" ||
                        data?.title === "Type" ||
                        data?.title === "Diurnal Variation" ? (
                          <CustomPieChart
                            data={data?.data}
                            title={data?.title}
                          />
                        ) : data?.title === "NPRS" ||
                          data?.title === "Match Time Loss Injuries" ||
                          data?.title === "General Time Loss Injuries" ||
                          data?.title === "Medical Time Loss Injuries" ||
                          data?.title === "Player Time Loss Injuries" ? (
                          <CustomBarChart
                            data={data?.data}
                            title={data?.title}
                          />
                        ) : (
                          <ScrollableLabels data={data?.data} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
