import React, { useEffect, useState }  from "react";
// import { Button } from "rsuite"; // Import SelectPicker
import styles from "./../../pages/sportsscience/science.module.css";

import { Card, CardContent, Typography } from "@mui/material";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
// import { Color } from "../../utils/Colors";
import { ATHLETE_PROFILE_API_URL, EVALUATION_RESULTS } from "../../services/API";
import { HttpService } from "../../services/http.service";
import { CascadingDropDownMultivariate } from "../../components/CascadingDropDown";

Chart.register(CategoryScale);

export default function Multivariate() {

  const [results, setResultMv] = useState([]);
  const [filterResults, setFilterResultsMv] = useState([]);
  const [selectedDepartmentMv, setSelectedDepartmentMv] = useState(null);
  const [selectedCategoryMv, setSelectedCategoryMv] = useState(null);
  const [selectedAthleteMv, setSelectedAthleteMv] = useState(null); // Add state for selected athlete
  const [departmentsMv, setDepartmentsMv] = useState([]);
  const [categoriesMv, setCategoriesMv] = useState([]);
  const [athletesMv, setAthletesMv] = useState(); // State to store athlete options
  // const [showGenerateButtonMv, setShowGenerateButtonMv] = useState(true);
  const [filter, setFilterMv] = useState(0);
useEffect(()=>{ const fetchResultMv = async () => {
    try {
      const response = await HttpService.get(
        `${EVALUATION_RESULTS}?department=${selectedDepartmentMv}&categories=${selectedCategoryMv}`,
        {},
        false
      );

      const modifiedResult = await Promise.all(
        response.data.results.map(async (result, index) => {
          try {
            // Fetch athlete data for each result
            const athleteResponse = await HttpService.get(
              ATHLETE_PROFILE_API_URL + result.athlete + "/",
              {},
              false
            );

            const athleteName = athleteResponse.data.name;
            const athleteId = athleteResponse.data.id;
            const value = determineValueMv(result);

            // Only include the result if athlete details are available
            if (athleteName !== "N/A") {
              return {
                id: index,
                athlete: athleteName,
                athlete_id: athleteId,
                value,
                date: result.created_at.slice(0, 10),
                field_name: result.field_name,
                field_id: result.field,
              };
            }

            return null; // Exclude the result when athlete is "N/A"
          } catch (athleteError) {
            console.warn(
              "Error fetching athlete details:",
              athleteError.message
            );
            // If athlete details cannot be fetched, return null to exclude the result
            return null;
          }
        })
      );
      // console.log(modifiedResult,"mr")
      // Filter out null results caused by failed athlete fetch or when athlete is "N/A"
      const filteredResults = modifiedResult.filter(
        (result) => result !== null
      );

      setResultMv(filteredResults);
      // setShowGenerateButtonMv(false);
      setFilterMv(1);
    } catch (error) {
      console.warn(error);
    }
  };
  if (selectedDepartmentMv!==null || selectedCategoryMv!==null){

  fetchResultMv()
  }},[selectedDepartmentMv,selectedCategoryMv])
 
 
  const determineValueMv = (result) => {
    return result.boolean_value !== false
      ? result.boolean_value
      : result.choice_value !== null
        ? result.choice_value
        : result.date_value !== null
          ? result.date_value
          : result.float_value !== null
            ? result.float_value
            : result.integer_value !== null
              ? result.integer_value
              : result.remarks !== null
                ? result.remarks
                : result.string_value !== null
                  ? result.string_value
                  : null;
  };

  // const filterResultsByAthleteMv = () => {
  //   if (selectedAthleteMv === null) {
  //     // If no athlete is selected, reset the filterResults to the original results
  //     setFilterResultsMv(resultsMv);
  //   } else {
  //     // Filter the original results based on the selected athlete
  //     const filteredResults = resultsMv.filter(
  //       (result) => result.athlete_id === selectedAthleteMv
  //     );
  //     setFilterResultsMv(filteredResults);
  //   }
  //   setFilterMv(2);
  // };

  const resetSelectionMv = () => {
    setSelectedDepartmentMv(null);
    setSelectedCategoryMv(null);
    setSelectedAthleteMv(null);
    setResultMv([]);
    setFilterResultsMv([]);
    // setShowGenerateButtonMv(true);
    setFilterMv(0);
  };
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }

  const customColors = [
    "rgba(75, 192, 192, 0.2)",
    "rgba(255, 99, 132, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(255, 205, 86, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(201, 203, 207, 0.2)",
  ];
  const generateBarDatasets = (data) => {
    const uniqueFieldNames = Array.from(
      new Set(data.map((result) => result.field_name))
    );

    return uniqueFieldNames
      .map((fieldName, index) => {
        const datasetData = data
          .filter((result) => result.field_name === fieldName)
          .map((result) => {
            const value = result.value;
            const isIntegerOrRange =
              Number.isInteger(value) ||
              (typeof value === "object" && "min" in value && "max" in value);

            return {
              x: result.date.slice(0, 10),
              y: result.value,
              label: isIntegerOrRange ? result.value.toString() : "",
            };
          });

        // Filter out the dataset if it doesn't contain any integer or range values
        if (datasetData.some((dataPoint) => dataPoint.label !== "")) {
          const backgroundColor = customColors[index % customColors.length]; // Assign a unique color to each dataset
          return {
            label: fieldName,
            data: datasetData,
            backgroundColor: backgroundColor,
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          };
        }
        return null; // Return null for datasets with no integer or range values
      })
      .filter((dataset) => dataset !== null); // Remove null datasets
  };
  const columns = [
    {
      field: "athlete",
      headerName: "Name",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "field_name",
      headerName: "Test Name",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      headerName: "Result",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
  ];

// console.log("m")
  return (
    <div>

<div className={styles.belownav}>
   <CascadingDropDownMultivariate
                  selectedDepartment={selectedDepartmentMv}
                  setSelectedDepartment={setSelectedDepartmentMv}
                  selectedCategory={selectedCategoryMv}
                  setSelectedCategory={setSelectedCategoryMv}
                  departments={departmentsMv}
                  setDepartments={setDepartmentsMv}
                  categories={categoriesMv}
                  setCategories={setCategoriesMv}
                  athletes={athletesMv}
                  setAthletes={setAthletesMv}
                  results={results}
                  selectedAthlete={selectedAthleteMv}
                  setSelectedAthlete={setSelectedAthleteMv}
                  
                  onClean={resetSelectionMv}
                />
            </div>

      {filter === 1 ? (
        <>
          {selectedCategoryMv?.length === 0 ? (
            <Typography variant="h6" textAlign={"center"}>
              No Data
            </Typography> // Use results.length to check if there are no results
          ) : (
            <div
              style={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}

            >   
            <Card
                sx={{
                  width: "40rem",
                  height: "20rem",
                  marginBottom: "2rem",
                  margin: "1rem",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Bar
                    data={{
                      labels: Array.from(
                        new Set(results.map((result) => result.date))
                      ),
                      datasets: generateBarDatasets(results), // Pass customColors array
                    }}
                    options={{
                      scales: {
                        x: {
                          stacked: false,
                          title: {
                            display: true,
                            text: 'Date', // Label for the x-axis
                            font: {
                              size: 16,
                              weight: 'bold'
                            }
                          }
                        },
                        y: {
                          stacked: false,
                          title: {
                            display: true,
                            text: 'Value', // Label for the y-axis
                            font: {
                              size: 16,
                              weight: 'bold'
                            }
                          }
                        }
                      },
                      plugins: {
                        title: {
                          display: true,
                          text: "Bar Chart Title",
                        },
                        legend: {
                          display: true,
                          position: "bottom",
                        },
                      },
                    }}
                  />
                </CardContent>
              </Card> <div
            style={{marginLeft:"30px",marginTop:"30px"}}     >
                <Box key={Math.random().toLocaleString()}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={results}
                    columns={columns}
                    pagination
                    paginationMode="client"
                    pageSize={10}
                    slots={{toolbar:CustomToolbar}}
                  />
                </Box>
               
              </div>
              

            </div>
          )}
        </>
      ) : filter === 2 ? (
        <>
          {filterResults.length === 0 ? (
            <Typography variant="h6" textAlign={"center"}>
              No Data
            </Typography> // Use filterResults.length to check if there are no results
          ) : (
            <div
              style={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            > <Container
              maxWidth="lg"
              style={{ marginTop: "4rem", marginBottom: "2rem" }}
            >
                <Box key={Math.random().toLocaleString()}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={filterResults}
                    columns={columns}
                    pagination
                    paginationMode="client"
                    pageSize={10}
                  />
                </Box>
               
              </Container>
              <Card
                sx={{
                  width: "40rem",
                  height: "20rem",
                  marginBottom: "2rem",
                  margin: "1rem",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Bar
                    data={{
                      labels: Array.from(
                        new Set(filterResults.map((result) => result.date))
                      ),
                      datasets: generateBarDatasets(filterResults), // Pass customColors array
                    }}
                    options={{
                      plugins: {
                        title: {
                          display: true,
                          text: "Bar Chart Title (Filtered)",
                        },
                        legend: {
                          display: true,
                          position: "bottom",
                        },
                      },
                    }}
                  />
                </CardContent>
              </Card>

            </div>
          )}
        </>
      ) : null}
    </div>
  );
}
