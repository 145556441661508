import axios from "axios";
import { LOGIN_API_URL } from "../services/API";
// import { redirect } from 'react-router-dom';

async function authenticate(mode, email, password) {
  try {
    const response = await axios.post(LOGIN_API_URL, {
      email: email,
      password: password,
      returnSecureToken: true,
    });

    const token = response.data.access;
    const refreshToken = response.data.refresh;

    // Ensure both access token and refresh token are present in the response
    if (!token || !refreshToken) {
      throw new Error("Access token or refresh token missing in response");
    }

    return { token, refreshToken }; // Return both access token and refresh token
  } catch (error) {
    throw error;
  }
}

export function login(email, password) {
  return authenticate("signInWithPassword", email, password);
}
