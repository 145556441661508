import React, { useState } from "react";

import styles from "./../../science.module.css";
import EvaluationFormView from "../../../../components/EvaluationFormView";
export default function SportSpecific({ mode }) {
  const [open, setOpen] = useState(false);
  // const [placement, setPlacement] = useState();
  const [drawerTitle, setDrawerTitle] = useState();
  const [dept, setDept] = useState();
  const [cat, setCat] = useState();

  

  const data = [
    {cat:105,
      name: "Athletics Talent Identification",
      dept:12,
      cardColor: "#c5cae9",
    },
    {
      cat: 114,
      name: "Archery Talent Identification",
      dept: 12,
      cardColor: "#c5cae9",
    },
    {
      cat: 106,
      name: "Badminton Talent Identification",
      dept: 12,
      cardColor: "#c5cae9",
    },
    {
      cat: 107,
      name: "Boxing Talent Identification",
      dept: 12,
      cardColor: "#c5cae9",
    },
    {
      cat: 112,
      name: "Swimming Talent Identification",
      dept: 12,
      cardColor: "#c5cae9",
    },
    {
      cat: 108,
      name: "Shooting Talent Identification",
      dept: 12,
      cardColor: "#c5cae9",
    },
    {
      cat: 113,
      name: "Lawn Tennis Talent Identification",
      dept: 12,
      cardColor: "#c5cae9",
    },
    {
      cat: 109,
      name: "Table Tennis Talent Identification",
      dept: 12,
      cardColor: "#c5cae9",
    },
    {
      cat: 110,
      name: "Weight Lifting Talent Identification",
      dept: 12,
      cardColor: "#c5cae9",
    },
    {
      cat: 111,
      name: "Wrestling Talent Identification",
      dept: 12,
      cardColor: "#c5cae9",
    },
    
  ];

  const handleOpen = ({ name, key, dept, cat }) => {
    setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
    setDept(dept);
    setCat(cat);
  };

  return (
    <>
     
    <div className={styles.sports_main_inner}>
          <select
            className={styles.dropdown}
            onChange={(e) => {
              const selectedValue = e.target.value;
              const selectedItem = data.find(
                (item) => item.name === selectedValue
              );

              if (selectedItem) {
                handleOpen({
                  name: selectedItem.name,
                  dept: selectedItem.dept,
                  cat: selectedItem.cat,
                });
              }
            }}
          >
            <option value="">{mode}</option>
            {data.map((item) => (
              <option key={item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
     
  
      <EvaluationFormView
        open={open}
        setOpen={setOpen}
        size={"lg"}
        placement={"left"}
        mode={drawerTitle}
        dept={dept}
        cat={cat}
      />
    </>
  );
}
