import React from "react";
import {
    Form,
    Button,
    ButtonToolbar,
    Schema,
    FlexboxGrid,
    SelectPicker,
    // DatePicker,
} from "rsuite";
import { HttpService } from "../../services/http.service";
import { ATHLETE_PROFILE_API_URL } from "../../services/API";
import { useEffect } from "react";
const { NumberType } = Schema.Types;

const model = Schema.Model({
    height: NumberType("Please enter a valid number.").range(
        100,
        210,
        "Please enter a height from 100 cm to 200 cm"
    ),
    weight: NumberType("Please enter a valid number.").range(
        30,
        120,
        "Please enter a weight from 30 cm to 120 kg"
    ),

});

const TextField = React.forwardRef((props, ref) => {
    const { name, label, accepter, placeholder, ...rest } = props;
    return (
        <Form.Group controlId={`${name}-4`} ref={ref}>
            <Form.ControlLabel>{label} </Form.ControlLabel>
            <Form.Control
                name={name}
                accepter={accepter}
                placeholder={placeholder}
                {...rest}
            />
        </Form.Group>
    );
});

export default function EditAthleteProfile({ id }) {
    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});
    const [sports, setSports] = React.useState();
    const [injury, setInjury] = React.useState();
    const [data, setData] = React.useState();
    const [res, setResponse] = React.useState();

    const [formValue, setFormValue] = React.useState({
       
        height: "",
        weight: "",
        city: "",
        state: "",
        pincode: "",
        occupation: "",
       
    });

 
    const injury_data = ["Injured", "Non-Injured"].map((item) => ({
        label: item,
        value: item,
    }));
    const sports_data = [
        "Athletics",
        "Archery",
        "Badminton",
        "Boxing",
        "Swimming",
        "Shooting",
        "Table Tennis",
        "Lawn Tennis",
        "Weightlifting",
        "Wrestling",
        "Cricket",
        "Football",
    ].map((item) => ({ label: item, value: item }));
    useEffect(() => {
        const fetchAthlete = async () => {
            try {
                const response = await HttpService.get(
                    `${ATHLETE_PROFILE_API_URL}${id}/`,{}, false
                );
                setData(response.data);
            } catch (error) {
                console.warn(error);
            }
        };

        fetchAthlete();
    }, [id]);
    // console.log(data)
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formRef.current.check()) {
            console.error("Form Error", formError);
            return;
        }

        try {
            const { email, first_name, last_name, mobile_no, ...athletedata } =
                formValue;

            const athleteProfile = {
                height: athletedata.height || "", // Provide default value or handle undefined
                weight: athletedata.weight || "", // Provide default value or handle undefined
                city: athletedata.city || "",
                state: athletedata.state || "",
                pincode: athletedata.pincode || "",
                occupation: athletedata.occupation || "",
                sports: sports || "",
                injury_status: injury === "Injured" ? true : false,
            };

            const formData = new FormData();

          
            formData.append("athlete_profile.gender", data?.gender);
            formData.append(
                "athlete_profile.height",
                athleteProfile.height?.length <= 0
                    ? data?.height
                    : athleteProfile.height
            );
            formData.append(
                "athlete_profile.weight",
                athleteProfile.weight?.length <= 0
                    ? data?.weight
                    : athleteProfile.weight
            );
            formData.append(
                "athlete_profile.city",
                athleteProfile.city?.length <= 0 ? data?.city : athleteProfile.city
            );
            formData.append(
                "athlete_profile.state",
                athleteProfile.state?.length <= 0 ? data?.state : athleteProfile.state
            );
            formData.append(
                "athlete_profile.pincode",
                athleteProfile.pincode?.length <= 0
                    ? data?.pincode
                    : athleteProfile.pincode
            );
            formData.append(
                "athlete_profile.occupation",
                athleteProfile.occupation?.length <= 0
                    ? data?.occupation
                    : athleteProfile.occupation
            );
            formData.append(
                "athlete_profile.sports",
                athleteProfile.sports?.length <= 0
                    ? data?.sports
                    : athleteProfile.sports
            );
            formData.append(
                "athlete_profile.injury_status",
                athleteProfile.injury_status?.length <= 0
                    ? data?.injury_status
                    : athleteProfile.injury_status
            );

            const response = await HttpService.patch(
                ATHLETE_PROFILE_API_URL+data.id+"/",
                { "Content-Type": "multipart/form-data" },
                formData,false
            );

            setResponse(response);

            if (response.status === 201) {
                alert("Form Submitted");
            }
        } catch (error) {
            if (error?.response && error?.response?.data) {
                if (typeof error?.response?.data === "object") {
                    const errorMessages = Object.values(error?.response?.data).join("\n");
                    alert(errorMessages || "An error occurred");
                } else {
                    alert(error?.response?.data?.message || "An error occurred");
                }
            } else {
                alert("An error occurred");
            }
        }
    };

    return (
        <FlexboxGrid>
            <FlexboxGrid.Item colspan={12}>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={model}
                    encType="multipart/form-data"
                >
                    <TextField name="height" label="Height" placeholder={data?.height} />
                    <TextField name="weight" label="Weight" placeholder={data?.weight} />

                    <label>Select Sports</label>
                    <br />
                    <SelectPicker
                        // label="Sports"
                        placeholder={data?.sports}
                        data={sports_data}
                        style={{ width: 224 }}
                        onSelect={(value) => setSports(value)}
                    />
                    <br />
                    <br />
                    <label>Select Injury Status</label>
                    <br />
                    <SelectPicker
                        // label="Injury Status"
                        placeholder={data?.injury_status}
                        data={injury_data}
                        style={{ width: 224 }}
                        onSelect={(value) => setInjury(value)}
                    />

                    <TextField name="city" label="City" placeholder={data?.city} />
                    <TextField name="state" label="State" placeholder={data?.state} />

                    <TextField
                        name="pincode"
                        label="Pincode"
                        placeholder={data?.pincode}
                    />
                    <TextField
                        name="occupation"
                        label="Occupation"
                        placeholder={data?.occupation}
                    />

                    <br />
                    <br />
                    <ButtonToolbar>
                        <Button appearance="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </ButtonToolbar>
                </Form>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
}
