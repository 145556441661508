import React from "react";
import styles from "./science.module.css";


export default function PlaceHolder() {
 
  return (
    <div className={styles.box_science_sports6}></div>
    
     
     
   
  );
}
