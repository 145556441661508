import React, { useState } from "react";
import styles from "./science.module.css";
// import FormModal from "../../components/FormModal";
import Kinetics from "./subcategories/biomechanics/Kinetics";
import Kinematics from "./subcategories/biomechanics/Kinematics";
import PlaceHolder from "./PlaceHolder";
export default function Biomechanics({label}) {
  // const [open, setOpen] = useState(false);
  // const [placement, setPlacement] = useState();
  const [drawerTitle, setDrawerTitle] = useState();
  // const [dept, setDept] = useState();
  // const [cat, setCat] = useState();

  const data = [
    {
      name: "Kinetics",

      cardColor: "#c5cae9",
    },
    {
      name: "Kinematics",

      cardColor: "#c5cae9",
    },
  ];

  const handleOpen = ({ name, key, dept, cat }) => {
    // setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
    // setDept(dept);
    // setCat(cat);
  };

  return (
    <div className={styles.sport_main} >
   
      <div className={styles.sports_main_inner}>
        <select
          className={styles.dropdown}
          onChange={(e) => {
            const selectedValue = e.target.value;
            const selectedItem = data.find(
              (item) => item.name === selectedValue
            );

            if (selectedItem) {
              handleOpen({
                name: selectedItem.name,
                dept: selectedItem.dept,
                cat: selectedItem.cat,
              });
            }
          }}
        >
          <option value="">{label}</option>
          {data.map((item) => (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
   

      {drawerTitle === undefined ? <PlaceHolder /> : drawerTitle === "Kinetics" ? (
        <Kinetics mode = {drawerTitle} />
      ) : drawerTitle === "Kinematics" ? (
        <Kinematics mode={drawerTitle} />
      ) : null}
    </div>
  );
}
