import React,{useState} from 'react'
// Function to estimate max %1RM using Epley's equation
const maxPerc1RMEpley = (reps, k = 0.0333) => {
    return 1 / (k * reps + 1);
};

// Function to estimate max %1RM using Modified Epley's equation
const maxPerc1RMModifiedEpley = (reps, kmod = 0.0353) => {
    return 1 / (kmod * (reps - 1) + 1);
};

// Function to estimate max %1RM using Linear equation
const maxPerc1RMLinear = (reps, klin = 33) => {
    return (klin - reps + 1) / klin;
};

// React component for max_perc_1RM estimation
const MaxPerc1RM = () => {
    const [reps, setReps] = useState(1);
    const [resultEpley, setResultEpley] = useState(0);
    const [resultModifiedEpley, setResultModifiedEpley] = useState(0);
    const [resultLinear, setResultLinear] = useState(0);

    const handleRepsChange = (event) => {
        const newReps = parseInt(event.target.value, 10);
        setReps(newReps);

        // Calculate results when reps change
        setResultEpley(maxPerc1RMEpley(newReps));
        setResultModifiedEpley(maxPerc1RMModifiedEpley(newReps));
        setResultLinear(maxPerc1RMLinear(newReps));
    };

    return (
        <div>
            <label>
                Repetitions:
                <input type="number" value={reps} onChange={handleRepsChange} />
            </label>
<br/> <br/>
            <table style={{ borderCollapse: 'collapse', width: '90%' }}>
            <thead>
                <tr>
                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Equation</th>
                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Estimate max %1RM</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Epley's equation</td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{resultEpley?.toFixed(2) * 100}%</td>
                </tr>
                <tr>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Modified Epley's equation</td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{resultModifiedEpley?.toFixed(2) * 100}%</td>
                </tr>
                <tr>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Linear equation</td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{resultLinear?.toFixed(2) * 100}%</td>
                </tr>
            </tbody>
        </table>
        </div>
    );
};

// Function to adjust max %1RM using the Reps In Reserve (RIR) approach
const adjPerc1RMRIR = (reps, adjustment = 0, mfactor = 1, maxPerc1RMFunc, ...params) => {
    // Adjust the reps
    const adjReps = (reps + adjustment) * mfactor;
  
    // Call the provided maxPerc1RMFunc with adjusted reps and other parameters
    return maxPerc1RMFunc(adjReps, ...params);
  };
  
  // Function to adjust max %1RM using the Deducted Intensity (DI) approach
  const adjPerc1RMDI = (reps, adjustment = 0, mfactor = 1, maxPerc1RMFunc, ...params) => {
    // Adjust the reps
    const adjReps = reps * mfactor;
  
    // Call the provided maxPerc1RMFunc with adjusted reps and other parameters
    return maxPerc1RMFunc(adjReps, ...params) + adjustment;
  };
  
  // Function to adjust max %1RM using the Relative Intensity (RelInt) approach
  const adjPerc1RMRelInt = (reps, adjustment = 1, mfactor = 1, maxPerc1RMFunc, ...params) => {
    // Adjust the reps
    const adjReps = reps * mfactor;
  
    // Call the provided maxPerc1RMFunc with adjusted reps and other parameters
    return adjustment * maxPerc1RMFunc(adjReps, ...params);
  };
  
  // Function to adjust max %1RM using the %Max Reps (%MR) approach
  const adjPerc1RMPercMR = (reps, adjustment = 1, mfactor = 1, maxPerc1RMFunc, ...params) => {
    // Adjust the reps
    const adjReps = (reps * mfactor) / adjustment;
  
    // Call the provided maxPerc1RMFunc with adjusted reps and other parameters
    return maxPerc1RMFunc(adjReps, ...params);
  };
  
  // React component for adjusted perc 1RM estimation
  const AdjustedPerc1RM = () => {
    const [reps, setReps] = useState(1);
    const [adjustment, setAdjustment] = useState(0);
    const [resultRIR, setResultRIR] = useState(0);
    const [resultDI, setResultDI] = useState(0);
    const [resultRelInt, setResultRelInt] = useState(0);
    const [resultPercMR, setResultPercMR] = useState(0);
  
    const handleRepsChange = (event) => {
      const newReps = parseInt(event.target.value, 10);
      setReps(newReps);
  
      // Calculate results when reps change
      setResultRIR(adjPerc1RMRIR(newReps, adjustment, 1, maxPerc1RMEpley));
      setResultDI(adjPerc1RMDI(newReps, adjustment, 1, maxPerc1RMEpley));
      setResultRelInt(adjPerc1RMRelInt(newReps, adjustment, 1, maxPerc1RMEpley));
      setResultPercMR(adjPerc1RMPercMR(newReps, adjustment, 1, maxPerc1RMEpley));
    };
  
    const handleAdjustmentChange = (event) => {
      const newAdjustment = parseFloat(event.target.value);
      setAdjustment(newAdjustment);
  
      // Calculate results when adjustment changes
      setResultRIR(adjPerc1RMRIR(reps, newAdjustment, 1, maxPerc1RMEpley));
      setResultDI(adjPerc1RMDI(reps, newAdjustment, 1, maxPerc1RMEpley));
      setResultRelInt(adjPerc1RMRelInt(reps, newAdjustment, 1, maxPerc1RMEpley));
      setResultPercMR(adjPerc1RMPercMR(reps, newAdjustment, 1, maxPerc1RMEpley));
    };
  
    return (
      <div>
        <div style={{display:"flex", justifyContent:"space-evenly"}}>
             <label>
          Repetitions:
          <input type="number" value={reps} onChange={handleRepsChange} />
        </label>
  
        <label>
          Adjustment:
          <input type="number" value={adjustment} onChange={handleAdjustmentChange} />
        </label>
        </div>
       <br/>
  
        <table style={{ borderCollapse: 'collapse', width: '90%' }}>
            <tbody>
                <tr>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>RIR Result</td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{resultRIR?.toFixed(2) * 100}%</td>
                </tr>
                <tr>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>DI Result</td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{resultDI?.toFixed(2) }%</td>
                </tr>
                <tr>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>RelInt Result</td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{resultRelInt?.toFixed(2) }%</td>
                </tr>
                <tr>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>PercMR Result</td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{resultPercMR?.toFixed(2) * 100}%</td>
                </tr>
            </tbody>
        </table>
      </div>
    );
  };

    const EquationResultsTable = () => {
        const [weight, setWeight] = useState(100);
        const [repetitions, setRepetitions] = useState(10);
      
        // Brzycki (1993) equation
        const brzycki = (weight, repetitions) => {
          return weight / (1.0278 - (0.0278 * repetitions));
        };
      
        // Baechle (2000) equation
        const baechle = (weight, repetitions) => {
          return weight * (1 + (0.033 * repetitions));
        };
      
        // Epley (1985) equation
        const epley = (weight, repetitions) => {
          return (0.033 * repetitions * weight) + weight;
        };
      
        // Landers (1985) equation
        const landers = (weight, repetitions) => {
          return (100 * weight) / (101.3 - (2.67123 * repetitions));
        };
      
        // Calculate results
        const resultBrzycki = brzycki(weight, repetitions);
        const resultBaechle = baechle(weight, repetitions);
        const resultEpley = epley(weight, repetitions);
        const resultLanders = landers(weight, repetitions);
      
        return (
          <div>
            <div style={{display:"flex", justifyContent:"space-evenly"}}>
              <label>Weight:</label>
              <input type="number" value={weight} onChange={(e) => setWeight(Number(e.target.value))} />
            
              <label>Repetitions:</label>
              <input type="number" value={repetitions} onChange={(e) => setRepetitions(Number(e.target.value))} />
            </div>
      <br/>
            <table style={{ borderCollapse: 'collapse', width: '90%' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Equation</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Result</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Brzycki</td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{resultBrzycki?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Baechle</td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{resultBaechle?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Epley</td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{resultEpley?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Landers</td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{resultLanders?.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      };
  


export default function StrengthConditioningAlgo({ isSidebarExpanded }) {
    return (
        <div style={{
            marginTop: "2rem",
            marginBottom: "2rem",
            transition: "margin-left 0.3s",
            marginLeft: isSidebarExpanded ? "16.5rem" : "6.5rem",
        }}>

<EquationResultsTable/>
<br/>
<MaxPerc1RM/>
<br/>
<AdjustedPerc1RM/>
        </div>
    )
}
