import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import UserCustomDrawer from "./CustomDrawer";
import styles from "./../components/landing/styles.module.css";
import Notification from "../pages/notifications/Notification";
import { Drawer, Modal } from "rsuite";
import { Avatar, Badge, Tooltip } from "@mui/material";
import PlusIcon from "@rsuite/icons/Plus";
import { Color } from "../utils/Colors";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../store/AuthContext";
import {
  BASE_URL,
  USER_API_URL,
  WEBSOCKET_NOTIFICATION,
} from "../services/API";
import { HttpService } from "../services/http.service";
import HomeIcon from "@mui/icons-material/Home";
import Ava from "./../assets/image/avatar1.jpg";
import Information from "../pages/userinformaton/Information";
import CustomNotification from "./CustomNotification";
import HelpIcon from "@mui/icons-material/Help";
// import { toast,ToastContainer } from "react-toastify";
// import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
const DashBoardTopNav = ({ header }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openlogs, setOpenLogs] = React.useState(false);
  const [openAppointment, setOpenAppointment] = React.useState(false);
  const [websocketData, setWebSocketData] = React.useState([]);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [typeModal, setTypeModal] = React.useState();
  const [imageProfile, setImageProfile] = React.useState(null);
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  // const timer = authCtx.timerValue
  const decode = token ? jwt_decode(token) : "";
  const [user, setUser] = React.useState();
  const [copied, setCopied] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [count2, setCount2] = React.useState(0);

  // if (timer === 10){
  //   toast.error("Your session is going to expire. We are logging you out due to inactivity.")
  // }
  // console.log(timer)

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${USER_API_URL}${decode.user_id}/`,
          {},
          true
        );
        setUser(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [decode.user_id]);
  // console.log(initialNotificationLength,notification.length,hasNewNotification )

  React.useEffect(() => {
    const websocket = new WebSocket(
      WEBSOCKET_NOTIFICATION(decode.account) + decode.user_id + "/"
    );

    websocket.onopen = () => {
      console.log("connected");
    };

    websocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("Received WebSocket data:", data); // Log the received data
      setWebSocketData(data);
    };

    websocket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      websocket.close();
    };
  }, [decode.account, decode.user_id]);

  // Ensure that the websocketData state is observed in a separate useEffect
  React.useEffect(() => {
    // console.log("Updated WebSocket data:", websocketData);
  }, [websocketData]);

  const handleOpen = () => {
    setTypeModal(1);
    setOpen(true);
  };
  // const handleOpenAppointment = () => {
  //   setTypeModal(10);
  //   setOpenAppointment(true);
  // };
  const handleOpenCreateNotification = (bool) => {
    setTypeModal(2);
    setOpenLogs(bool);
  };
  React.useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const baseURL = BASE_URL(decode.account);
        const mediaURL = `${baseURL}/media`;
        const imageUrl = `${mediaURL}${user.profile_photo_url}`;
        const response = await fetch(imageUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const blob = await response.blob();
          const objectURL = URL.createObjectURL(blob);
          setImageProfile(objectURL);
          setIsImageLoaded(true);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };
    if(
      user?.profile_photo_url?.length > 0
    )
    {
      fetchImageUrls();
    }
  }, [user, decode.account, token]);
  const items = [
    {
      icon: <HelpIcon />,
      action: () => navigate("/dashboard"),
      className: styles.navItem2,
    },

    // {
    //   icon: <AccessAlarmIcon />,
    //   action: () => handleOpenAppointment("right"),
    //   className: styles.navItem2,
    // },
    {
      icon: <HomeIcon />,
      action: () => navigate("/dashboard"),
      className: styles.navItem2,
    },
    {
      icon: <NotificationsIcon />,
      action: () => handleOpen("right"),
      className: styles.navItem2,
    },
  ];

  const handleItemClick = (action) => {
    action();
  };
  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
   
    return name?.length <= 0 || name === undefined
      ? null
      : {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
        };
  }
  
  function stringAvatarFirst(name) {
   
   
    return name?.length <= 0 || name === undefined
      ? null
      : {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(" ")[0][0]}`,
        };
  }

  let marginLeft = header?.includes("👋")
    ? "10px"
    : header === "Human Body Performance Dashboard"
    ? "20px"
    : header === "Periodisation Planning"
    ? "60px"
    : "60px";
  return (
    <>
      {/* <ToastContainer/> */}
      <div className={styles.topnav2}>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            flexGrow: 1,
            marginTop: "2px",
            marginLeft: "4px",
          }}
        >
          <h5 style={{ marginLeft: marginLeft, marginTop: "10px" }}>
            {header}
          </h5>
        </div>
        <div
          style={{
            order: 1,
            marginTop: "2px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {items.slice(0, -1).map((item, index) => (
            <div
              key={index}
              className={`${styles.navItemItalic2} ${styles.iconWrapper}`}
              onClick={() => handleItemClick(item.action)}
            >
              <>{item.icon && <span>{item.icon}</span>}</>
            </div>
          ))}
          {items.slice(-1).map((item, index) => (
            <Tooltip key={index} placement="bottom" title="Show Notification">
              <div
                className={`${styles.navItemItalic2} ${styles.iconWrapper}`}
                onClick={() => handleItemClick(item.action)}
              >
                {websocketData?.message !== undefined ? (
                  <Badge color="warning" variant="dot">
                    {item.icon && <span>{item.icon}</span>}
                  </Badge>
                ) : (
                  <>{item.icon && <span>{item.icon}</span>}</>
                )}
              </div>
            </Tooltip>
          ))}
          {user === undefined ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setOpenInfo(!openInfo)}
            >
              <Tooltip placement="bottom" title={user?.email}>
                <Avatar
                  sx={{ marginTop: "4px", marginRight: "4px" }}
                  src={Ava}
                />{" "}
              </Tooltip>
            </div>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setOpenInfo(!openInfo)}
            >
              <Tooltip
                placement="bottom"
                title={user?.first_name + " " + user?.last_name}
              >
                {isImageLoaded === true ? (
                  <Avatar
                    src={imageProfile}
                    sx={{
                      width: 40,
                      height: 40,
                      borderRadius: "50%",
                      marginTop: "2px",
                      marginRight: "2px",
                    }}
                    alt={`${user.first_name} ${user.last_name}`}
                  />
                ) : (
                  <>
                  {user?.last_name?.length !== 0? <Avatar
                
                {...stringAvatar(`${user.first_name} ${user.last_name}`)}
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  marginTop: "2px",
                  marginRight: "2px",
                }}
              />: <Avatar
                
              {...stringAvatarFirst(`${user.first_name} ${user.last_name}`)}
              sx={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                marginTop: "2px",
                marginRight: "2px",
              }}
            />}
                  </>

                 
                )}
              </Tooltip>
            </div>
          )}
        </div>
      </div>

      <Modal
        style={{
          position: "fixed",
          top: "24px",
          right: "2px",
        }}
        overflow={true}
        size={"xs"}
        open={open}
        onClose={() => {
          setOpen(false);
          setWebSocketData([]);
        }}
      >
        <Modal.Header
          closeButton={false}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0.2rem",
            borderBottom: "2px solid black",
          }}
        >
          <h5>Notification</h5>
          {decode?.roles?.includes("Athlete") ? null : (
            <Tooltip placement="bottom" title="Create Notification">
              <PlusIcon
                style={{
                  marginLeft: "14rem",
                  color: Color.Color_C,
                  fontSize: "1rem",
                  cursor: "pointer",
                }}
                appearance="primary"
                onClick={() => handleOpenCreateNotification(true, "left")}
              />
            </Tooltip>
          )}
        </Modal.Header>
        <div
          style={{
            overflowY: "auto",
            height: "100vh",
            // maxHeight: "600px",
            overflowX: "auto",
          }}
        >
          <Notification count={count2} websocketData={websocketData} />
        </div>
      </Modal>
      <Drawer
        overflow={true}
        size={"xs"}
        open={openInfo}
        onClose={() => {
          setOpenInfo(false);
        }}
      >
        <Drawer.Header
          style={{
            backgroundColor: Color.Color_A,
            height: "150px",
            position: "relative",
          }}
        >
          <Information setCopied={setCopied} setCount={setCount} />
        </Drawer.Header>

        <div
          style={{
            overflowY: "auto",
          }}
        ></div>
      </Drawer>
      <UserCustomDrawer
        open={
          typeModal === 2 ? openlogs : typeModal === 10 ? openAppointment : null
        }
        setOpen={
          typeModal === 2
            ? setOpenLogs
            : typeModal === 10
            ? setOpenAppointment
            : null
        }
        size={typeModal === 2 ? "sm" : typeModal === 10 ? "xs" : null}
        placement={"right"}
        mode={
          typeModal === 2
            ? "AddNotification"
            : typeModal === 10
            ? "Appointment"
            : null
        }
        setCount={setCount2}
      />
      {copied && (
        <CustomNotification
          message={"Copied to Clipboard"}
          errorCount={count}
        />
      )}
    </>
  );
};

export default DashBoardTopNav;
