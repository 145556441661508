import React, { useContext, useState, useEffect } from "react";
import {  Divider,  } from "rsuite";
// import { Card, CardContent, CardActions, Typography } from "@mui/material";
import { AuthContext } from "../store/AuthContext";
import jwt_decode from "jwt-decode";
// import { useNavigate } from "react-router-dom";
// import InfoRoundIcon from "@rsuite/icons/InfoRound";
import styles from "./../pages/sportsscience/science.module.css";
import { HttpService } from "../services/http.service";
import { ATHLETE_PROFILE_API_URL, BASE_URL } from "../services/API";
// import UserCustomDrawer from "../components/CustomDrawer";
import WellnessInsightsAthlete from "./WellnessInsightsAthlete";
import LoadMonitoringInsightsAthlete from "./LoadMonitoringInshightsAthlete";
import FMSInsightsAthlete from "./FMSInsightsAthlete";
import PainInsightsAthlete from "./PainInsightsAthlete";
import SomatotypeAthlete from "./SomatotypeAthlete";
import UnivariateAthlete from "./UnivariateAthlete";
import BivariateAthlete from "./BivariateAthlete";
import CategoricalAthlete from "./CategoricalAthlete";
import MultivariateAthlete from "./MultivariateAthlete";
import TrainingProgram from "./TrainingProgram";

// import AthleteSidebar from "./AthleteCustomSideNav";
import DashBoardTopNav from "../components/DashBoardTopNav";
import EvaluationForm from "../pages/sportsscience/EvaluationForm";
// import EvaluationFormView from "../components/EvaluationFormView";
export default function AthleteDashboard() {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const [selectedItem, setSelectedItem] = useState(1);
  const [filter, setFilter] = useState(1);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [notice, setNotice] = useState(false);
  // const [open, setOpen] = useState(false);
  // const [drawerTitle, setDrawerTitle] = useState();
  const [dept, setDept] = useState();
  const [cat, setCat] = useState();



  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  //   console.log(decode);
  const [imageProfile, setImageProfile] = useState(null);
  const [user, setUser] = useState([]);
  const [userData, setUserData] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}${decode.athlete_id}/`,
          {},
          false
        );
        setUser(response.data);
        setUserData(true)
      } catch (error) { }
    };

    fetchData();
  }, [decode.athlete_id]);


  const fetchImageUrls = async () => {
    try {
      const baseURL = BASE_URL(decode.account);
      const mediaURL = `${baseURL}/media`;
      const imageUrl = `${mediaURL}${user.profile_photo_url}`;
      const response = await fetch(imageUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);
        setImageProfile(objectURL);
        setIsImageLoaded(true);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };



  const handleOpen = () => {

    fetchImageUrls()
    setOpenModal(true)
  };


  const handleFilterClick = (filter) => {
    setFilter(filter);
    setSelectedItem(filter);
  };
  const dashboardSections = [
    {
      title: "Monitoring",
      filter: 2,
    },
    // {
    //   title: "Upcoming Events",
    //   filter: 3,
    // },
    {
      title: "Training Programs",
      filter: 4,
    },
    {
      title: "Wellness Snapshot",
      filter: 5,
    },
    {
      filter: 1,
      title: "Univariate Analytics",
    },
    {
      filter: 6,
      title: "Categorical Analytics",
    },
    {
      filter: 7,
      title: "Bivariate Analytics",
    },
    {
      filter: 8,
      title: "Multivariate Analytics",
    },
    {
      filter: 9,
      title: "Load Monitoring Analysis",
    },
    {
      filter: 10,
      title: "FMS Outcome",
    },
    {
      filter: 11,
      title: "Pain Outcome",
    },
    {
      filter: 12,
      title: "Somatotype Outcome",
    },
  ];
 

  return (
    <div>
      <DashBoardTopNav header={`Hi ${user?.name} 👋`} />
      {/*      
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop:"100px",
          justifyContent: "space-between",
          paddingTop: "2rem",
          paddingRight: "2rem",
          paddingLeft: "2rem",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
        <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ flex: 1 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <Typography marginRight={1}>Email:</Typography>
                <Typography>{user?.email}</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <Typography marginRight={1}>Gender:</Typography>
                <Typography>{user?.gender}</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <Typography marginRight={1}>Sports:</Typography>
                <Typography>{user?.sports}</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <Typography marginRight={1}>Height:</Typography>
                <Typography>{user?.height} cm</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <Typography marginRight={1}>Weight:</Typography>
                <Typography>{user?.weight} kg</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <Typography marginRight={1}>Injury Status:</Typography>
                <Typography
                  fontWeight={"600"}
                  color={user?.injury_status === false ? "green" : "red"}
                >
                  {user?.injury_status === false ? "No" : "Yes"}
                </Typography>
              </div>
            </div>
            <div>
              <img
                src={imageProfile}
                alt="Profile"
                height={200}
                width={200}
                style={{ borderRadius: "10%" }}
              />
            </div>
          </div>
        </div>
      </div> */}

      <div className={styles.box_science_sports_ath}>
        {dashboardSections.map(({ title, icon, filter }, index) => (
          <label
            style={{ justifyContent: "space-between" }}
            className={`${styles.labelItem_sports} ${filter === selectedItem ? styles.selected : ""
              }`}
            key={index}
            onClick={() => handleFilterClick(filter)}
          >
            {title}
            {/* {icon} */}
          </label>
        ))}
      </div>
    


      <Divider />

      {filter === 2 ? 
       <div className={styles.box_science_sports6_ath}>  <EvaluationForm
       
        dept={dept}
        cat={cat}
      /> 

       </div>
    
        : filter === 1 ? (
        <div className={styles.box_science_sports6_ath}>
          <UnivariateAthlete />
        </div>
      ) : filter === 6 ? (
        <div className={styles.box_science_sports6_ath}>
          <CategoricalAthlete />
        </div>
      ) : filter === 7 ? (
        <div className={styles.box_science_sports6_ath}>
          <BivariateAthlete />
        </div>
      ) : filter === 8 ? (
        <div className={styles.box_science_sports6_ath}>
          <MultivariateAthlete />
        </div>
      ) : filter === 9 ? (
        <div className={styles.box_science_sports6_ath}>
          <LoadMonitoringInsightsAthlete />
        </div>
      ) : filter === 10 ? (
        <div className={styles.box_science_sports6_ath}>
          <FMSInsightsAthlete />
        </div>
      ) : filter === 11 ? (
        <div className={styles.box_science_sports6_ath}>
          <PainInsightsAthlete />
        </div>
      ) : filter === 12 ? (
        <div className={styles.box_science_sports6_ath}>
          <SomatotypeAthlete />
        </div>
      ) : filter === 5 ? (
        <div className={styles.box_science_sports6_ath}>
          <WellnessInsightsAthlete />
        </div>
      ) : filter === 4 ? (
        <div className={styles.box_science_sports6_ath}>
          <TrainingProgram athlete={decode.athlete_id}></TrainingProgram>
        </div>
      ) : null}
    </div>
  );
}
