import React from "react";
import DashBoardTopNav from "../../components/DashBoardTopNav";
// import { Divider } from "rsuite";
import { Container } from "@mui/material";
import Sidebar from "../../components/CustomSideNav";
// import { Typography } from "@mui/material";
import { SelectPicker } from "rsuite";
import StrengthConditioningAlgo from "./StrengthConditioningAlgo";
import ExercisePhysiologyAlgo from "./ExercisePhysiologyAlgo";

export default function Algotrithm() {
    const [isSidebarExpanded, setIsSidebarExpanded] = React.useState(false);
const [selected,setSelected] = React.useState()
    const algo =
        [{
            value: 1,
            label: "Strength & Conditioning",
        },
        {
            value: 2,
            label: "Exercise Physiology",
        }]



    const handleSidebarToggle = () => {
        setIsSidebarExpanded(!isSidebarExpanded);
    };
    return (
        <>
            <DashBoardTopNav />
            <Sidebar onToggle={handleSidebarToggle} expanded={isSidebarExpanded} />
           
            <Container maxWidth="lg" style={{
                marginTop: "2rem",
                marginBottom: "2rem",
                transition: "margin-left 0.3s",
                marginLeft: isSidebarExpanded ? "15rem" : "5rem",
            }}>
             
                <SelectPicker
                    label={selected === undefined?"Select Department":null}
                    data={algo}
                    onSelect={(value) => setSelected(value)}
                    style={{ width: 260 }}
                />

            </Container>
            {selected === 1 ? <StrengthConditioningAlgo isSidebarExpanded={isSidebarExpanded}/>:selected === 2 ? <ExercisePhysiologyAlgo isSidebarExpanded={isSidebarExpanded}/>:null}
        </>
    );
}
