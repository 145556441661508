import React from "react";
import "./../landing/styles.css";
import {
  Form,
  Button,
  Schema,
  // FlexboxGrid,
  SelectPicker,
  RadioGroup,
  Radio,
} from "rsuite";
import { HttpService } from "../../services/http.service";
import { TEAM_API_URL, CREATE_ATHLETE } from "../../services/API";
import CustomNotification from "../CustomNotification";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import { useEffect, useContext } from "react";
import { Color } from "../../utils/Colors";
const { NumberType, StringType } = Schema.Types;

const model = Schema.Model({
  height: NumberType("Please enter a valid number.").range(
    100,
    210,
    "Please enter a height from 100 cm to 200 cm"
  ),
  weight: NumberType("Please enter a valid number.").range(
    30,
    120,
    "Please enter a weight from 30 cm to 120 kg"
  ),
  first_name: StringType().isRequired("This field is required."),
  last_name: StringType().isRequired("This field is required."),
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),

  password: StringType().isRequired("This field is required."),
  verifyPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }
      return true;
    }, "The two passwords do not match")
    .isRequired("This field is required."),
});

const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group style={{ margin: "20px" }} controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

export default function AthleteForm({  onSubmitSuccess,setCount}) {
  const formRef = React.useRef();
  const [errorCount, setErrorCount] = React.useState(0)
  const [selectedFile, setSelectedFile] = React.useState();
  // const [isFilePicked, setIsFilePicked] = React.useState(false);
  const [formError, setFormError] = React.useState({});
  const [gender, setGender] = React.useState();
  const [sports, setSports] = React.useState();
  const [injury_status, setInjury] = React.useState();
  const [date, setDate] = React.useState();
  const [team, setTeam] = React.useState();
  const [teams, setTeams] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const [formValue, setFormValue] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    password: "",
    verifyPassword: "",
    height: "",
    weight: "",
    city: "",
    state: "",
    pincode: "",
    occupation: "",
  });

  const data = ["Male", "Female"].map((item) => ({ label: item, value: item }));
  const injury_data = ["Injured", "Non-Injured"].map((item) => ({
    label: item,
    value: item,
  }));
  const sports_data = [
    "Athletics",
    "Archery",
    "Badminton",
    "Boxing",
    "Swimming",
    "Shooting",
    "Table Tennis",
    "Lawn Tennis",
    "Weightlifting",
    "Wrestling",
    "Cricket",
    "Football",
  ].map((item) => ({ label: item, value: item }));
  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const response = await HttpService.get(
          `${TEAM_API_URL}?page=${currentPage}`,
          {},
          false
        );

        const modifiedTeam = response.data.results.map((t) => ({
          value: t.id,
          label: t.name,
        }));

        setTeams(modifiedTeam);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchTeam();
  }, [currentPage]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formRef.current.check()) {
      console.error("Form Error", formError);
      return;
    }
    for (const key in formValue) {
      if (formValue.hasOwnProperty(key) && !formValue[key]) {
        setError(`${key} is required`);
        setErrorCount(prevCount => prevCount + 1);
        setOpenModal(true);
        return;
      }
    }

    // Ensure other necessary values are filled
    if (date ===undefined || !gender || !sports || !injury_status || !team || !selectedFile) {
      setError("Please fill all required fields");
      setErrorCount(prevCount => prevCount + 1);
      setOpenModal(true);
      return;
    }
    try {
      const {
        email,
        first_name,
        last_name,
        mobile_no,
        password,
        verifyPassword,
        ...athletedata
      } = formValue;
    

      const athleteProfile = {
        gender: gender || "", // Provide default value or handle undefined
        height: athletedata.height || "", // Provide default value or handle undefined
        weight: athletedata.weight || "", // Provide default value or handle undefined
        city: athletedata.city || "",
        state: athletedata.state || "",
        pincode: athletedata.pincode || "",
        occupation: athletedata.occupation || "",
        sports: sports || "",
        injury_status: injury_status === "Injured" ? true : false,
      };


      const formData = new FormData();

      formData.append("email", email);
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("mobile_no", mobile_no);
      formData.append("password", password);
      formData.append("role", "Athlete");
      formData.append("date_of_birth", date);
      formData.append("profile_photo", selectedFile);
      formData.append("teams", [team]);
      formData.append("athlete_profile.gender", athleteProfile.gender);
      formData.append("athlete_profile.height", athleteProfile.height);
      formData.append("athlete_profile.weight", athleteProfile.weight);
      formData.append("athlete_profile.city", athleteProfile.city);
      formData.append("athlete_profile.state", athleteProfile.state);
      formData.append("athlete_profile.pincode", athleteProfile.pincode);
      formData.append("athlete_profile.occupation", athleteProfile.occupation);
      formData.append("athlete_profile.sports", athleteProfile.sports);
      formData.append(
        "athlete_profile.injury_status",
        athleteProfile.injury_status
      );
      formData.append("account", decode.account);
      const response = await HttpService.post(
        CREATE_ATHLETE,
        { "Content-Type": "multipart/form-data" },
        formData,
        false
      );

    
      setResponse(response);
      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setError("Form Submitted"); // Update error state to indicate success
        setOpenModal(true);
        setFormValue({ // Clear the form data
          first_name: "",
          last_name: "",
          email: "",
          mobile_no: "",
          password: "",
          verifyPassword: "",
          height: "",
          weight: "",
          city: "",
          state: "",
          pincode: "",
          occupation: "",
        });
        setSelectedFile(null); // Clear selected file
        setDate(null); // Clear selected date
        setGender(null); // Clear selected gender
        setSports(null); // Clear selected sports
        setInjury(null); // Clear selected injury status
        setTeam(null); // Clear selected team
        onSubmitSuccess();
        return; // Exit early to prevent further execution
      }
    } catch (error) {

      let errorMessages = "An error occurred";
      if (error.response && error.response.data && error.response.data.athlete_profile) {
        errorMessages = Object.values(error.response.data.athlete_profile).join("\n");
      } else if (error.response && error.response.data && typeof error.response.data === "object") {
        errorMessages = Object.values(error.response.data).join("\n");
      } else if ((error.response && error.response.data && error.response?.data?.message )|| e.response?.data?.detail) {
        errorMessages = error.response?.data?.message || e.response?.data?.detail;
      }
      setError(errorMessages);
      if (error.response && error.response.status !== 201) {
        // If there's a response indicating a server error (status other than 201), open the modal
        setErrorCount(prevCount => prevCount + 1);

        setOpenModal(true);
      }
    }
  };
  const handleChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  // console.log(typeof selectedFile);
  // console.log(date)
  return (
    <div >


      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formValue={formValue}
        model={model}
        encType="multipart/form-data"
      >

        <div
         
        >

          <TextField name="first_name" label="First Name" />

          <TextField name="last_name" label="Last Name" />

        </div>
        <div
         
        >
          <div style={{ margin: "20px" }}>
            {selectedFile && (
              <div >
                {/* <img
                    height="25%"
                    width="25%"
                    src={URL.createObjectURL(selectedFile)}
                    alt=""
                  />
                  <br /> */}
                <span>{selectedFile.name}</span>
              </div>
            )}
            <label>Upload Photo</label>
            <br />
            <br />
            <input
              type="file"
              name="profile_photo"
              onChange={(e) => handleChange(e)}
            />

          </div>

          {teams === undefined ? (
            <p>Team: Loading</p>
          ) : (
            <div style={{ margin: "20px" }}>
              <SelectPicker
                label="Select Team"
                data={teams}
                onSelect={(value) => setTeam(value)}
                style={{ width: 300 }}
              />
            </div>
          )}

        </div>
        <div style={{ margin: "20px" }}>
          <label>Date of Birth</label>
          <br />
          <br />
          <input type="date" onChange={(e) => setDate(e.target.value)} style={{ width: 300, height: 30 }} />
          <br />
          <br />
        </div>
        <div
         
        > <TextField name="email" label="Email" />

          <TextField name="mobile_no" label="Mobile Number" />
        </div>
        <div
         
        >
          <TextField name="password" label="Password" />

          <TextField name="verifyPassword" label="Verify Password" />
        </div>
        <div style={{ margin: "20px" }}>
          <label> Gender</label>
          <RadioGroup
            name="gender"
            
            style={{ width: 300 }}
            appearance="default"
            value={gender}
            onChange={value => setGender(value)}
          >
            {data.map(option => (
              <Radio color={option.value==="Male"?"violet":"orange"} key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </RadioGroup>
        </div>

        <div style={{ margin: "20px" }}>
        <label> Injury Status</label>
          <RadioGroup
            name="injury_status"
            style={{ width: 300 }}
           
            value={injury_status}
            onChange={value => setInjury(value)}
          >
            {injury_data.map(option => (
              <Radio color={option.value==="Injured"?"red":"green"} key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </RadioGroup>
       <br />
          <br />
          <SelectPicker
            label="Sports"
            data={sports_data}
            style={{ width: 300 }}
            onSelect={(value) => setSports(value)}
          />
        </div>

        <div
         
        >
           <TextField name="height" label="Height" />
        <TextField name="weight" label="Weight" />
          </div>

          <div
         
        > <TextField name="city" label="City" />
        <TextField name="state" label="State" /></div>

      
        <div
         
        > <TextField name="pincode" label="Pincode" />
        <TextField name="occupation" label="Occupation" /></div>
       

       

       

        <br />
      

        <Button
          appearance="primary"
          onClick={handleSubmit}
          style={{
            background: Color.Color_C,
            color: Color.Color_A,
            width: 300,marginLeft:"20px"
          }}
        >
           Create Athlete
        </Button>

        <CustomNotification
          errorCount={errorCount}
          open={openModal}
          setOpen={setOpenModal}
          nav={"/profile_management"}
          message={
            error ? error : (res && res.data && res.data.message) || ""
          }
          title={error === "Form Submitted" ? "Success" : "Error"}
        />
      </Form>
    </div>

  );
}
