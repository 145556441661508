export const CardioExercises = [
  {
    id: 1,
    "Cardio Exercises": "Jumping jacks",
  },
  {
    id: 2,
    "Cardio Exercises": "High knees",
  },
  {
    id: 3,
    "Cardio Exercises": "Butt kicks",
  },
  {
    id: 4,
    "Cardio Exercises": "Jump rope",
  },
  {
    id: 5,
    "Cardio Exercises": "Running in place",
  },
  {
    id: 6,
    "Cardio Exercises": "Burpees",
  },
  {
    id: 7,
    "Cardio Exercises": "Mountain climbers",
  },
  {
    id: 8,
    "Cardio Exercises": "Jump squats",
  },
  {
    id: 9,
    "Cardio Exercises": "Box jumps",
  },
  {
    id: 10,
    "Cardio Exercises": "Bicycle crunches",
  },
  {
    id: 11,
    "Cardio Exercises": "Jump lunges",
  },
  {
    id: 12,
    "Cardio Exercises": "Side plank with leg lifts",
  },
  {
    id: 13,
    "Cardio Exercises": "Skaters",
  },
  {
    id: 14,
    "Cardio Exercises": "Plank jacks",
  },
  {
    id: 15,
    "Cardio Exercises": "Punches (air boxing)",
  },
  {
    id: 16,
    "Cardio Exercises": "Cross-body mountain climbers",
  },
  {
    id: 17,
    "Cardio Exercises": "Tuck jumps",
  },
  {
    id: 18,
    "Cardio Exercises": "Side shuffle",
  },
  {
    id: 19,
    "Cardio Exercises": "Jumping oblique twists",
  },
  {
    id: 20,
    "Cardio Exercises": "Stair running",
  },
  {
    id: 21,
    "Cardio Exercises": "Speed skaters",
  },
  {
    id: 22,
    "Cardio Exercises": "Bear crawls",
  },
  {
    id: 23,
    "Cardio Exercises": "Running stairs",
  },
  {
    id: 24,
    "Cardio Exercises": "Kettlebell swings",
  },
  {
    id: 25,
    "Cardio Exercises": "Rowing machine",
  },
  {
    id: 26,
    "Cardio Exercises": "Elliptical machine",
  },
  {
    id: 27,
    "Cardio Exercises": "Swimming laps",
  },
  {
    id: 28,
    "Cardio Exercises": "Cycling",
  },
  {
    id: 29,
    "Cardio Exercises": "Jumping switch lunges",
  },
  {
    id: 30,
    "Cardio Exercises": "Power skips",
  },
  {
    id: 31,
    "Cardio Exercises": "Sprint intervals",
  },
  {
    id: 32,
    "Cardio Exercises": "Jumping high fives",
  },
  {
    id: 33,
    "Cardio Exercises": "Dancing",
  },
  {
    id: 34,
    "Cardio Exercises": "Hula hooping",
  },
  {
    id: 35,
    "Cardio Exercises": "Zumba",
  },
  {
    id: 36,
    "Cardio Exercises": "Kickboxing",
  },
  {
    id: 37,
    "Cardio Exercises": "Jumping elbow strikes",
  },
  {
    id: 38,
    "Cardio Exercises": "Jumping knee strikes",
  },
  {
    id: 39,
    "Cardio Exercises": "Jumping side kicks",
  },
  {
    id: 40,
    "Cardio Exercises": "Jumping front kicks",
  },
  {
    id: 41,
    "Cardio Exercises": "Running backwards",
  },
  {
    id: 42,
    "Cardio Exercises": "Jumping back kicks",
  },
  {
    id: 43,
    "Cardio Exercises": "Sprints",
  },
  {
    id: 44,
    "Cardio Exercises": "CrossFit workouts",
  },
  {
    id: 45,
    "Cardio Exercises": "Rowing sprints",
  },
  {
    id: 46,
    "Cardio Exercises": "Bodyweight squats",
  },
  {
    id: 47,
    "Cardio Exercises": "Front kicks",
  },
  {
    id: 48,
    "Cardio Exercises": "Side kicks",
  },
  {
    id: 49,
    "Cardio Exercises": "Roundhouse kicks",
  },
  {
    id: 50,
    "Cardio Exercises": "Double unders (jump rope)",
  },
  {
    id: 51,
    "Cardio Exercises": "Triple unders (jump rope)",
  },
  {
    id: 52,
    "Cardio Exercises": "Jumping jack planks",
  },
  {
    id: 53,
    "Cardio Exercises": "Star jumps",
  },
  {
    id: 54,
    "Cardio Exercises": "Spiderman push-ups",
  },
  {
    id: 55,
    "Cardio Exercises": "Kettlebell goblet squats",
  },
  {
    id: 56,
    "Cardio Exercises": "Sprinting in water",
  },
  {
    id: 57,
    "Cardio Exercises": "Sledgehammer hits (on a tire)",
  },
  {
    id: 58,
    "Cardio Exercises": "Battle ropes",
  },
  {
    id: 59,
    "Cardio Exercises": "Biking uphill",
  },
  {
    id: 60,
    "Cardio Exercises": "Agility ladder drills",
  },
  {
    id: 61,
    "Cardio Exercises": "Jumping lunges with weights",
  },
  {
    id: 62,
    "Cardio Exercises": "Russian twists with medicine ball",
  },
  {
    id: 63,
    "Cardio Exercises": "Wall ball shots",
  },
  {
    id: 64,
    "Cardio Exercises": "Plank to push-up",
  },
  {
    id: 65,
    "Cardio Exercises": "Lateral bounds",
  },
  {
    id: 66,
    "Cardio Exercises": "Slam ball exercises",
  },
  {
    id: 67,
    "Cardio Exercises": "Treadmill sprints",
  },
  {
    id: 68,
    "Cardio Exercises": "Trampoline jumping",
  },
  {
    id: 69,
    "Cardio Exercises": "Resistance band sprints",
  },
  {
    id: 70,
    "Cardio Exercises": "Medicine ball slams",
  },
  {
    id: 71,
    "Cardio Exercises": "Deadlifts",
  },
  {
    id: 72,
    "Cardio Exercises": "Boxer's jump",
  },
  {
    id: 73,
    "Cardio Exercises": "Resistance band jumping jacks",
  },
  {
    id: 74,
    "Cardio Exercises": "Medicine ball tosses",
  },
  {
    id: 75,
    "Cardio Exercises": "Running with resistance bands",
  },
  {
    id: 76,
    "Cardio Exercises": "Rowing with resistance bands",
  },
  {
    id: 77,
    "Cardio Exercises": "Battle rope waves with squats",
  },
  {
    id: 78,
    "Cardio Exercises": "Jumping jack push-ups",
  },
  {
    id: 79,
    "Cardio Exercises": "Running with ankle weights",
  },
  {
    id: 80,
    "Cardio Exercises": "Handstand push-ups",
  },
  {
    id: 81,
    "Cardio Exercises": "Frog jumps",
  },
  {
    id: 82,
    "Cardio Exercises": "Bear hug squats (holding a weight to your chest)",
  },
  {
    id: 83,
    "Cardio Exercises": "Dumbbell step-ups",
  },
  {
    id: 84,
    "Cardio Exercises": "Dumbbell thrusters",
  },
  {
    id: 85,
    "Cardio Exercises": "Jumping knee tucks",
  },
  {
    id: 86,
    "Cardio Exercises": "Reverse plank leg lifts",
  },
  {
    id: 87,
    "Cardio Exercises": "Lunge jumps with dumbbells",
  },
  {
    id: 88,
    "Cardio Exercises": "Tricep dips with knee raises",
  },
  {
    id: 89,
    "Cardio Exercises": "Bicycle crunches with a medicine ball",
  },
  {
    id: 90,
    "Cardio Exercises": "Plank with shoulder taps",
  },
  {
    id: 91,
    "Cardio Exercises": "Plank with leg lifts",
  },
  {
    id: 92,
    "Cardio Exercises": "Side plank dips",
  },
  {
    id: 93,
    "Cardio Exercises": "Crab walk",
  },
  {
    id: 94,
    "Cardio Exercises": "Push-up with row (dumbbell or kettlebell)",
  },
  {
    id: 95,
    "Cardio Exercises": "Dumbbell snatch",
  },
  {
    id: 96,
    "Cardio Exercises": "Jumping rope with high knees",
  },
  {
    id: 97,
    "Cardio Exercises": "Bodyweight walking lunges",
  },
  {
    id: 98,
    "Cardio Exercises": "Swimming with resistance paddles",
  },
  {
    id: 99,
    "Cardio Exercises": "High plank with knee to elbow",
  },
  {
    id: 100,
    "Cardio Exercises": "Jumping jack squats",
  },
  {
    id: 101,
    "Cardio Exercises": "Plank to push-up with rotation",
  },
  {
    id: 102,
    "Cardio Exercises": "Inchworms",
  },
  {
    id: 103,
    "Cardio Exercises": "Mountain climbers with sliders",
  },
  {
    id: 104,
    "Cardio Exercises": "Jumping jack push-ups with clap",
  },
  {
    id: 105,
    "Cardio Exercises": "Sprinter lunges",
  },
  {
    id: 106,
    "Cardio Exercises": "Bodyweight squats with jump",
  },
  {
    id: 107,
    "Cardio Exercises": "Side lunges",
  },
  {
    id: 108,
    "Cardio Exercises": "Kettlebell goblet lunges",
  },
  {
    id: 109,
    "Cardio Exercises": "Dumbbell step-back lunges",
  },
  {
    id: 110,
    "Cardio Exercises": "Dumbbell sumo squats",
  },
  {
    id: 111,
    "Cardio Exercises": "Bulgarian split squats with dumbbells",
  },
  {
    id: 112,
    "Cardio Exercises": "Jumping lunges with twists",
  },
  {
    id: 113,
    "Cardio Exercises": "Resistance band lateral lunges",
  },
  {
    id: 114,
    "Cardio Exercises": "Bodyweight reverse lunges",
  },
  {
    id: 115,
    "Cardio Exercises": "Kettlebell step-ups",
  },
  {
    id: 116,
    "Cardio Exercises": "Dumbbell lateral lunges",
  },
  {
    id: 117,
    "Cardio Exercises": "Box jumps with dumbbells",
  },
  {
    id: 118,
    "Cardio Exercises": "Resistance band jump squats",
  },
];

export const FlexibilityExercise = [
  {
    id: 1,
    "Flexibility Exercises": "Neck stretches",
  },
  {
    id: 2,
    "Flexibility Exercises": "Shoulder rolls",
  },
  {
    id: 3,
    "Flexibility Exercises": "Triceps stretch",
  },
  {
    id: 4,
    "Flexibility Exercises": "Wrist stretches",
  },
  {
    id: 5,
    "Flexibility Exercises": "Cat-Cow stretch",
  },
  {
    id: 6,
    "Flexibility Exercises": "Child's pose",
  },
  {
    id: 7,
    "Flexibility Exercises": "Seated forward bend",
  },
  {
    id: 8,
    "Flexibility Exercises": "Butterfly stretch",
  },
  {
    id: 9,
    "Flexibility Exercises": "Quadriceps stretch",
  },
  {
    id: 10,
    "Flexibility Exercises": "Hamstring stretch",
  },
  {
    id: 11,
    "Flexibility Exercises": "Calf stretch",
  },
  {
    id: 12,
    "Flexibility Exercises": "Hip flexor stretch",
  },
  {
    id: 13,
    "Flexibility Exercises": "Pigeon pose",
  },
  {
    id: 14,
    "Flexibility Exercises": "Seated spinal twist",
  },
  {
    id: 15,
    "Flexibility Exercises": "Side bend stretch",
  },
  {
    id: 16,
    "Flexibility Exercises": "Standing forward bend",
  },
  {
    id: 17,
    "Flexibility Exercises": "Seated twist",
  },
  {
    id: 18,
    "Flexibility Exercises": "Shoulder stretch",
  },
  {
    id: 19,
    "Flexibility Exercises": "Trunk rotation",
  },
  {
    id: 20,
    "Flexibility Exercises": "Ankle circles",
  },
  {
    id: 21,
    "Flexibility Exercises": "Glute stretch",
  },
  {
    id: 22,
    "Flexibility Exercises": "Inner thigh stretch",
  },
  {
    id: 23,
    "Flexibility Exercises": "Outer thigh stretch",
  },
  {
    id: 24,
    "Flexibility Exercises": "Seated side stretch",
  },
  {
    id: 25,
    "Flexibility Exercises": "Yoga stretches",
  },
  {
    id: 26,
    "Flexibility Exercises": "Pilates stretches",
  },
  {
    id: 27,
    "Flexibility Exercises": "Dynamic stretching",
  },
  {
    id: 28,
    "Flexibility Exercises": "Tai Chi",
  },
  {
    id: 29,
    "Flexibility Exercises": "Resistance band stretches",
  },
  {
    id: 30,
    "Flexibility Exercises": "Ballistic stretching",
  },
  {
    id: 31,
    "Flexibility Exercises": "Full body stretches",
  },
  {
    id: 32,
    "Flexibility Exercises": "Yoga poses (e.g., Downward Dog)",
  },
  {
    id: 33,
    "Flexibility Exercises": "Quadratus lumborum stretch",
  },
  {
    id: 34,
    "Flexibility Exercises": "Groin stretch",
  },
  {
    id: 35,
    "Flexibility Exercises": "Pectoral stretch",
  },
  {
    id: 36,
    "Flexibility Exercises": "Wrist circles",
  },
  {
    id: 37,
    "Flexibility Exercises": "Arm circles",
  },
  {
    id: 38,
    "Flexibility Exercises": "Standing side bend",
  },
  {
    id: 39,
    "Flexibility Exercises": "Resistance band bicep curls",
  },
  {
    id: 40,
    "Flexibility Exercises": "Resistance band tricep extensions",
  },
  {
    id: 41,
    "Flexibility Exercises": "Resistance band shoulder press",
  },
  {
    id: 42,
    "Flexibility Exercises": "Resistance band chest press",
  },
  {
    id: 43,
    "Flexibility Exercises": "Resistance band flyes",
  },
  {
    id: 44,
    "Flexibility Exercises": "Resistance band face pulls",
  },
  {
    id: 45,
    "Flexibility Exercises": "Resistance band lateral raises",
  },
  {
    id: 46,
    "Flexibility Exercises": "Resistance band bent-over rows",
  },
  {
    id: 47,
    "Flexibility Exercises": "Resistance band tricep kickbacks",
  },
  {
    id: 48,
    "Flexibility Exercises": "Resistance band seated rows",
  },
  {
    id: 49,
    "Flexibility Exercises": "Resistance band leg curls",
  },
  {
    id: 50,
    "Flexibility Exercises": "Resistance band hamstring curls",
  },
  {
    id: 51,
    "Flexibility Exercises": "Resistance band pull-aparts",
  },
  {
    id: 52,
    "Flexibility Exercises": "Resistance band crunches",
  },
  {
    id: 53,
    "Flexibility Exercises": "Resistance band mountain climbers",
  },
  {
    id: 54,
    "Flexibility Exercises": "Resistance band leg raises",
  },
  {
    id: 55,
    "Flexibility Exercises": "Resistance band plank rotations",
  },
  {
    id: 56,
    "Flexibility Exercises": "Resistance band woodchops",
  },
  {
    id: 57,
    "Flexibility Exercises": "Resistance band oblique twists",
  },
  {
    id: 58,
    "Flexibility Exercises": "Resistance band seated twists",
  },
  {
    id: 59,
    "Flexibility Exercises": "Resistance band standing twists",
  },
  {
    id: 60,
    "Flexibility Exercises": "Resistance band squat pulses",
  },
  {
    id: 61,
    "Flexibility Exercises": "Resistance band hip thrusts",
  },
  {
    id: 62,
    "Flexibility Exercises": "Resistance band clamshells",
  },
  {
    id: 63,
    "Flexibility Exercises": "Resistance band lateral walks",
  },
  {
    id: 64,
    "Flexibility Exercises": "Resistance band side leg raises",
  },
  {
    id: 65,
    "Flexibility Exercises": "Resistance band glute bridges",
  },
  {
    id: 66,
    "Flexibility Exercises": "Resistance band side plank",
  },
  {
    id: 67,
    "Flexibility Exercises": "Resistance band plank with leg lifts",
  },
  {
    id: 68,
    "Flexibility Exercises": "Resistance band bicycle crunches",
  },
  {
    id: 69,
    "Flexibility Exercises": "Resistance band seated Russian twists",
  },
  {
    id: 70,
    "Flexibility Exercises": "Resistance band side plank with leg lifts",
  },
  {
    id: 71,
    "Flexibility Exercises": "Resistance band plank with shoulder taps",
  },
  {
    id: 72,
    "Flexibility Exercises":
      "Resistance band plank with alternating knee to elbow and twist",
  },
  {
    id: 73,
    "Flexibility Exercises": "Resistance band plank with alternating leg lifts",
  },
  {
    id: 74,
    "Flexibility Exercises": "Resistance band plank with toe taps",
  },
  {
    id: 75,
    "Flexibility Exercises": "Resistance band plank with alternating toe taps",
  },
  {
    id: 76,
    "Flexibility Exercises":
      "Resistance band plank with knee to opposite elbow and twist",
  },
  {
    id: 77,
    "Flexibility Exercises":
      "Resistance band plank with shoulder taps and leg lifts",
  },
  {
    id: 78,
    "Flexibility Exercises": "Resistance band plank with seated Russian twists",
  },
  {
    id: 79,
    "Flexibility Exercises": "Resistance band plank with side plank dips",
  },
  {
    id: 80,
    "Flexibility Exercises": "Resistance band plank with crab walk",
  },
  {
    id: 81,
    "Flexibility Exercises": "Resistance band plank with push-up",
  },
  {
    id: 82,
    "Flexibility Exercises": "Resistance band plank with mountain climbers",
  },
  {
    id: 83,
    "Flexibility Exercises":
      "Resistance band plank with high plank to low plank",
  },
  {
    id: 84,
    "Flexibility Exercises": "Resistance band plank with plank jacks",
  },
  {
    id: 85,
    "Flexibility Exercises": "Resistance band plank with side plank",
  },
  {
    id: 86,
    "Flexibility Exercises": "Resistance band plank with plank twists",
  },
  {
    id: 87,
    "Flexibility Exercises": "Resistance band plank with plank to push-up",
  },
  {
    id: 88,
    "Flexibility Exercises": "Resistance band plank with inchworms",
  },
  {
    id: 89,
    "Flexibility Exercises":
      "Resistance band plank with row (dumbbell or kettlebell)",
  },
];
export const UpperBodyStrengthExercise = [
  {
    id: 1,
    "Upper Body Strength Exercises": "Push-ups",
  },
  {
    id: 2,
    "Upper Body Strength Exercises": "Dumbbell chest press",
  },
  {
    id: 3,
    "Upper Body Strength Exercises": "Bench press",
  },
  {
    id: 4,
    "Upper Body Strength Exercises": "Dumbbell flyes",
  },
  {
    id: 5,
    "Upper Body Strength Exercises": "Pull-ups",
  },
  {
    id: 6,
    "Upper Body Strength Exercises": "Chin-ups",
  },
  {
    id: 7,
    "Upper Body Strength Exercises": "Dumbbell rows",
  },
  {
    id: 8,
    "Upper Body Strength Exercises": "Lat pulldowns",
  },
  {
    id: 9,
    "Upper Body Strength Exercises": "Barbell rows",
  },
  {
    id: 10,
    "Upper Body Strength Exercises": "Seated cable rows",
  },
  {
    id: 11,
    "Upper Body Strength Exercises": "Face pulls",
  },
  {
    id: 12,
    "Upper Body Strength Exercises": "Bicep curls",
  },
  {
    id: 13,
    "Upper Body Strength Exercises": "Hammer curls",
  },
  {
    id: 14,
    "Upper Body Strength Exercises": "Tricep dips",
  },
  {
    id: 15,
    "Upper Body Strength Exercises": "Tricep kickbacks",
  },
  {
    id: 16,
    "Upper Body Strength Exercises": "Tricep pushdowns",
  },
  {
    id: 17,
    "Upper Body Strength Exercises": "Overhead press",
  },
  {
    id: 18,
    "Upper Body Strength Exercises": "Dumbbell shoulder press",
  },
  {
    id: 19,
    "Upper Body Strength Exercises": "Lateral raises",
  },
  {
    id: 20,
    "Upper Body Strength Exercises": "Front raises",
  },
  {
    id: 21,
    "Upper Body Strength Exercises": "Upright rows",
  },
  {
    id: 22,
    "Upper Body Strength Exercises": "Barbell shoulder press",
  },
  {
    id: 23,
    "Upper Body Strength Exercises": "Dumbbell shrugs",
  },
  {
    id: 24,
    "Upper Body Strength Exercises": "Bench dips",
  },
  {
    id: 25,
    "Upper Body Strength Exercises": "Push press",
  },
  {
    id: 26,
    "Upper Body Strength Exercises": "Arnold press",
  },
  {
    id: 27,
    "Upper Body Strength Exercises": "Push-up variations",
  },
  {
    id: 28,
    "Upper Body Strength Exercises": "Diamond push-ups",
  },
  {
    id: 29,
    "Upper Body Strength Exercises": "Pike push-ups",
  },
  {
    id: 30,
    "Upper Body Strength Exercises": "Hindu push-ups",
  },
  {
    id: 31,
    "Upper Body Strength Exercises": "Clapping push-ups",
  },
  {
    id: 32,
    "Upper Body Strength Exercises": "Archer push-ups",
  },
  {
    id: 33,
    "Upper Body Strength Exercises": "Typewriter push-ups",
  },
  {
    id: 34,
    "Upper Body Strength Exercises": "Spiderman push-ups",
  },
  {
    id: 35,
    "Upper Body Strength Exercises": "T push-ups",
  },
  {
    id: 36,
    "Upper Body Strength Exercises": "Staggered push-ups",
  },
  {
    id: 37,
    "Upper Body Strength Exercises": "Pike push-ups with feet on a bench",
  },
  {
    id: 38,
    "Upper Body Strength Exercises": "One-arm push-ups",
  },
  {
    id: 39,
    "Upper Body Strength Exercises": "Wall push-ups",
  },
  {
    id: 40,
    "Upper Body Strength Exercises": "Handstand push-ups",
  },
  {
    id: 41,
    "Upper Body Strength Exercises": "Ring push-ups",
  },
  {
    id: 42,
    "Upper Body Strength Exercises": "Planche push-ups",
  },
  {
    id: 43,
    "Upper Body Strength Exercises": "Knuckle push-ups",
  },
  {
    id: 44,
    "Upper Body Strength Exercises": "Archer push-ups with hands on a ball",
  },
  {
    id: 45,
    "Upper Body Strength Exercises": "Pike push-ups with feet on a ball",
  },
  {
    id: 46,
    "Upper Body Strength Exercises": "Push-up to side plank",
  },
  {
    id: 47,
    "Upper Body Strength Exercises": "Push-up with rotation",
  },
  {
    id: 48,
    "Upper Body Strength Exercises": "Knuckle push-ups with feet on a ball",
  },
  {
    id: 49,
    "Upper Body Strength Exercises": "Pike push-ups with rotation",
  },
  {
    id: 50,
    "Upper Body Strength Exercises": "BB Chest Press",
  },
  {
    id: 51,
    "Upper Body Strength Exercises": "Declined BB Chest Press",
  },
  {
    id: 52,
    "Upper Body Strength Exercises": "Inclined BB Chest Press",
  },
  {
    id: 53,
    "Upper Body Strength Exercises": "Close Grip BB Chest Press",
  },
  {
    id: 54,
    "Upper Body Strength Exercises": "DB Chest Press",
  },
  {
    id: 55,
    "Upper Body Strength Exercises": "Inclined DB Chest Press",
  },
  {
    id: 56,
    "Upper Body Strength Exercises": "DB Chest Flys",
  },
  {
    id: 57,
    "Upper Body Strength Exercises": "Inclined DB Flys",
  },
  {
    id: 58,
    "Upper Body Strength Exercises": "Parallel Bar Dips",
  },
  {
    id: 59,
    "Upper Body Strength Exercises": "Chest Flys Machine",
  },
  {
    id: 60,
    "Upper Body Strength Exercises": "Chest Press Machine",
  },
  {
    id: 61,
    "Upper Body Strength Exercises": "Cable Flys",
  },
  {
    id: 62,
    "Upper Body Strength Exercises": "DB Pullover",
  },
  {
    id: 63,
    "Upper Body Strength Exercises": "BB Floor Press",
  },
  {
    id: 64,
    "Upper Body Strength Exercises": "Alternately DB Chest Press",
  },
  {
    id: 65,
    "Upper Body Strength Exercises": "Alternately DB Chest Press ISO hold",
  },
  {
    id: 66,
    "Upper Body Strength Exercises": "Swiss Ball DB Chest Press",
  },
  {
    id: 67,
    "Upper Body Strength Exercises": "CrossFit Push Up",
  },
  {
    id: 68,
    "Upper Body Strength Exercises": "Kneeling Push Up",
  },
  {
    id: 69,
    "Upper Body Strength Exercises": "TRX Push Up",
  },
  {
    id: 70,
    "Upper Body Strength Exercises": "TRX Push Up on MB Balls",
  },
  {
    id: 71,
    "Upper Body Strength Exercises": "TRX Push Up on BOSU",
  },
  {
    id: 72,
    "Upper Body Strength Exercises": "TRX Chest Press",
  },
  {
    id: 73,
    "Upper Body Strength Exercises": "TRX Chest Flys",
  },
  {
    id: 74,
    "Upper Body Strength Exercises": "KB Floor Chest Press",
  },
  {
    id: 75,
    "Upper Body Strength Exercises": "KB Floor Chest Press One Arm",
  },
  {
    id: 76,
    "Upper Body Strength Exercises": "Bodyweight Push Up",
  },
  {
    id: 77,
    "Upper Body Strength Exercises": "BB Row",
  },
  {
    id: 78,
    "Upper Body Strength Exercises": "BB Bench Row",
  },
  {
    id: 79,
    "Upper Body Strength Exercises": "Pull Up",
  },
  {
    id: 80,
    "Upper Body Strength Exercises": "Chin Up",
  },
  {
    id: 81,
    "Upper Body Strength Exercises": "Mixed Grip Pull Up",
  },
  {
    id: 82,
    "Upper Body Strength Exercises": "Lat Pull Down",
  },
  {
    id: 83,
    "Upper Body Strength Exercises": "Lat Pull Down underhand grip",
  },
  {
    id: 84,
    "Upper Body Strength Exercises": "Inverted Row",
  },
  {
    id: 85,
    "Upper Body Strength Exercises": "Pullover Machine",
  },
  {
    id: 86,
    "Upper Body Strength Exercises": "Low Row Machine",
  },
  {
    id: 87,
    "Upper Body Strength Exercises": "Close Grip Lat Pull Down",
  },
  {
    id: 88,
    "Upper Body Strength Exercises": "One Arm DB Row with knee support",
  },
  {
    id: 89,
    "Upper Body Strength Exercises": "One Arm DB Row",
  },
  {
    id: 90,
    "Upper Body Strength Exercises": "BB Row underhand grip",
  },
  {
    id: 91,
    "Upper Body Strength Exercises": "Seated Low Row Close Grip",
  },
  {
    id: 92,
    "Upper Body Strength Exercises": "Seated Low Row Wide Grip",
  },
  {
    id: 93,
    "Upper Body Strength Exercises": "Straight Arm Lat Pull Down",
  },
  {
    id: 94,
    "Upper Body Strength Exercises": "T-Bar Row with Abdominal Support",
  },
  {
    id: 95,
    "Upper Body Strength Exercises": "Assisted Pull Ups Machine",
  },
  {
    id: 96,
    "Upper Body Strength Exercises": "Trap Bar Shrugs",
  },
  {
    id: 97,
    "Upper Body Strength Exercises": "BB Shrugs",
  },
  {
    id: 98,
    "Upper Body Strength Exercises": "GHD Hip Extensions",
  },
  {
    id: 99,
    "Upper Body Strength Exercises": "GHD BB Hip Extensions",
  },
  {
    id: 100,
    "Upper Body Strength Exercises": "GHD Hip Extensions with OH press",
  },
  {
    id: 101,
    "Upper Body Strength Exercises": "GHD DB Alternate row",
  },
  {
    id: 102,
    "Upper Body Strength Exercises": "TRX Low Row",
  },
  {
    id: 103,
    "Upper Body Strength Exercises": "TRX Single Arm Row",
  },
  {
    id: 104,
    "Upper Body Strength Exercises": "TRX High Row",
  },
  {
    id: 105,
    "Upper Body Strength Exercises": "TRX Power Pull",
  },
  {
    id: 106,
    "Upper Body Strength Exercises": "KB Bend Over Row",
  },
  {
    id: 107,
    "Upper Body Strength Exercises": "Half Kneeling SA Banded Row",
  },
  {
    id: 108,
    "Upper Body Strength Exercises": "Banded Row",
  },
  {
    id: 109,
    "Upper Body Strength Exercises": "Single Arm Banded Row",
  },
  {
    id: 110,
    "Upper Body Strength Exercises": "Half Kneeling SA Banded Pull Down",
  },
  {
    id: 111,
    "Upper Body Strength Exercises": "Tall Kneeling Banded SA Pull Down",
  },
  {
    id: 112,
    "Upper Body Strength Exercises": "Banded Straight Arm Lat Pull Down",
  },
  {
    id: 113,
    "Upper Body Strength Exercises": "Back Extensions",
  },
  {
    id: 114,
    "Upper Body Strength Exercises": "DB Shrugs",
  },
  {
    id: 115,
    "Upper Body Strength Exercises": "BB Mlitary Press",
  },
  {
    id: 116,
    "Upper Body Strength Exercises": "BB Seated Front Shoulder Press",
  },
  {
    id: 117,
    "Upper Body Strength Exercises": "BB Seated Back Shoulder Press",
  },
  {
    id: 118,
    "Upper Body Strength Exercises": "Seated DB Shoulder Press",
  },
  {
    id: 119,
    "Upper Body Strength Exercises": "Seated Front DB Shoulder Press",
  },
  {
    id: 120,
    "Upper Body Strength Exercises": "DB Military Press",
  },
  {
    id: 121,
    "Upper Body Strength Exercises": "Single Arm DB Press",
  },
  {
    id: 122,
    "Upper Body Strength Exercises": "Rotational DB Shoulder Press",
  },
  {
    id: 123,
    "Upper Body Strength Exercises": "Rotational Alternately DB Shoulder Press",
  },
  {
    id: 124,
    "Upper Body Strength Exercises": "Half Kneeling DB Shoulder Press",
  },
  {
    id: 125,
    "Upper Body Strength Exercises": "BB Push Press",
  },
  {
    id: 126,
    "Upper Body Strength Exercises": "DB Push Press",
  },
  {
    id: 127,
    "Upper Body Strength Exercises": "Single Arm DB Push Press",
  },
  {
    id: 128,
    "Upper Body Strength Exercises": "Rotational Single Arm DB Push Press",
  },
  {
    id: 129,
    "Upper Body Strength Exercises": "Rotational Alternately DB Push Press",
  },
  {
    id: 130,
    "Upper Body Strength Exercises": "BB Front Raises",
  },
  {
    id: 131,
    "Upper Body Strength Exercises": "Alternately DB Front Raises",
  },
  {
    id: 132,
    "Upper Body Strength Exercises": "DB Bend Over Lateral Raises",
  },
  {
    id: 133,
    "Upper Body Strength Exercises": "DB Front Raises",
  },
  {
    id: 134,
    "Upper Body Strength Exercises": "DB Lateral Raises",
  },
  {
    id: 135,
    "Upper Body Strength Exercises": "High Pulley Lateral Extensions",
  },
  {
    id: 136,
    "Upper Body Strength Exercises": "Low Pulley Bend Over Lateral Raises",
  },
  {
    id: 137,
    "Upper Body Strength Exercises": "Low Pulley Front Raises",
  },
  {
    id: 138,
    "Upper Body Strength Exercises": "Low Pulley Lateral Raises",
  },
  {
    id: 139,
    "Upper Body Strength Exercises": "Machine Lateral Raises",
  },
  {
    id: 140,
    "Upper Body Strength Exercises": "Prone DB Front Raises",
  },
  {
    id: 141,
    "Upper Body Strength Exercises": "Prone DB Lateral Raises",
  },
  {
    id: 142,
    "Upper Body Strength Exercises": "Side Lying Lateral Raises",
  },
  {
    id: 143,
    "Upper Body Strength Exercises": "Alternately DB Shoulder Press ISO Hold",
  },
  {
    id: 144,
    "Upper Body Strength Exercises": "Swiss Ball DB Shoulder Press",
  },
  {
    id: 145,
    "Upper Body Strength Exercises": "TRX Y Deltoid Raises",
  },
  {
    id: 146,
    "Upper Body Strength Exercises": "TRX T Deltoid Flys",
  },
  {
    id: 147,
    "Upper Body Strength Exercises": "TRX Scarecrow",
  },
  {
    id: 148,
    "Upper Body Strength Exercises": "KB Upright Row",
  },
  {
    id: 149,
    "Upper Body Strength Exercises": "KB Push Press",
  },
  {
    id: 150,
    "Upper Body Strength Exercises": "Single Arm KB Shoulder Press",
  },
  {
    id: 151,
    "Upper Body Strength Exercises": "KB Military Press",
  },
  {
    id: 152,
    "Upper Body Strength Exercises": "KB Bottom Up Shoulder Press",
  },
  {
    id: 153,
    "Upper Body Strength Exercises": "Landmine SH Press Half Kneeling",
  },
  {
    id: 154,
    "Upper Body Strength Exercises": "Cuban Press",
  },
  {
    id: 155,
    "Upper Body Strength Exercises": "BB Biceps Curls",
  },
  {
    id: 156,
    "Upper Body Strength Exercises": "Wide Grip BB Bceps Curls",
  },
  {
    id: 157,
    "Upper Body Strength Exercises": "Narrow Grip BB Bceps Curls",
  },
  {
    id: 158,
    "Upper Body Strength Exercises": "Alternately Seated DB Biceps Curls",
  },
  {
    id: 159,
    "Upper Body Strength Exercises": "Seated DB Biceps Curls",
  },
  {
    id: 160,
    "Upper Body Strength Exercises": "Preacher Curls",
  },
  {
    id: 161,
    "Upper Body Strength Exercises": "Machine Preacher Curls",
  },
  {
    id: 162,
    "Upper Body Strength Exercises": "Machine Biceps Curls",
  },
  {
    id: 163,
    "Upper Body Strength Exercises": "Low Pulley Biceps Curls",
  },
  {
    id: 164,
    "Upper Body Strength Exercises": "Low Pulley Single Arm Biceps Curls",
  },
  {
    id: 165,
    "Upper Body Strength Exercises": "Low Pulley Rope Biceps Curls",
  },
  {
    id: 166,
    "Upper Body Strength Exercises": "High Pulley Biceps Curls",
  },
  {
    id: 167,
    "Upper Body Strength Exercises": "DB Hammer Curls",
  },
  {
    id: 168,
    "Upper Body Strength Exercises": "DB Concentration Curls",
  },
  {
    id: 169,
    "Upper Body Strength Exercises": "E-Z Bar Bceps Curls",
  },
  {
    id: 170,
    "Upper Body Strength Exercises": "TRX Biceps Curls",
  },
  {
    id: 171,
    "Upper Body Strength Exercises": "TRX Inward Biceps Curls",
  },
  {
    id: 172,
    "Upper Body Strength Exercises": "TRX Single Arm Biceps Curls",
  },
  {
    id: 173,
    "Upper Body Strength Exercises": "BB Triceps Extensions",
  },
  {
    id: 174,
    "Upper Body Strength Exercises": "E-Z Bar Triceps Extensions",
  },
  {
    id: 175,
    "Upper Body Strength Exercises": "DB Triceps Extensions",
  },
  {
    id: 176,
    "Upper Body Strength Exercises": "E-Z Bar OH Triceps Extensions",
  },
  {
    id: 177,
    "Upper Body Strength Exercises": "Triceps Press Downs",
  },
  {
    id: 178,
    "Upper Body Strength Exercises": "Rope Triceps Press Downs",
  },
  {
    id: 179,
    "Upper Body Strength Exercises": "Reverse Grip Triceps Press Downs",
  },
  {
    id: 180,
    "Upper Body Strength Exercises": "Reverse Grip Single Arm Press Downs",
  },
  {
    id: 181,
    "Upper Body Strength Exercises": "Triceps Kickbacks",
  },
  {
    id: 182,
    "Upper Body Strength Exercises": "Triceps Dips",
  },
  {
    id: 183,
    "Upper Body Strength Exercises": "OH DB Triceps Extensions",
  },
  {
    id: 184,
    "Upper Body Strength Exercises": "TRX Triceps Press",
  },
  {
    id: 185,
    "Upper Body Strength Exercises": "TRX Triceps Press Reverse Grip",
  },
  {
    id: 186,
    "Upper Body Strength Exercises": "Wrist Curls",
  },
  {
    id: 187,
    "Upper Body Strength Exercises": "Reverse Wrist Curls",
  },
  {
    id: 188,
    "Upper Body Strength Exercises": "Reverse Curls",
  },
  {
    id: 189,
    "Upper Body Strength Exercises": "Zotman Curls",
  },
];
export const LowerBodyStrengthExercise = [
  {
    id: 1,
    "Lower Body Strength Exercises": "Squats",
  },
  {
    id: 2,
    "Lower Body Strength Exercises": "Lunges",
  },
  {
    id: 3,
    "Lower Body Strength Exercises": "Deadlifts",
  },
  {
    id: 4,
    "Lower Body Strength Exercises": "Leg press",
  },
  {
    id: 5,
    "Lower Body Strength Exercises": "Leg curls",
  },
  {
    id: 6,
    "Lower Body Strength Exercises": "Leg extensions",
  },
  {
    id: 7,
    "Lower Body Strength Exercises": "Calf raises",
  },
  {
    id: 8,
    "Lower Body Strength Exercises": "Glute bridges",
  },
  {
    id: 9,
    "Lower Body Strength Exercises": "Bulgarian split squats",
  },
  {
    id: 10,
    "Lower Body Strength Exercises": "Step-ups",
  },
  {
    id: 11,
    "Lower Body Strength Exercises": "Box jumps",
  },
  {
    id: 12,
    "Lower Body Strength Exercises": "Romanian deadlifts",
  },
  {
    id: 13,
    "Lower Body Strength Exercises": "Leg raises",
  },
  {
    id: 14,
    "Lower Body Strength Exercises": "Squat jumps",
  },
  {
    id: 15,
    "Lower Body Strength Exercises": "Sumo squats",
  },
  {
    id: 16,
    "Lower Body Strength Exercises": "Bulgarian split jumps",
  },
  {
    id: 17,
    "Lower Body Strength Exercises": "Resistance band leg press",
  },
  {
    id: 18,
    "Lower Body Strength Exercises": "Resistance band lateral walks",
  },
  {
    id: 19,
    "Lower Body Strength Exercises": "Resistance band leg curls",
  },
  {
    id: 20,
    "Lower Body Strength Exercises": "Resistance band hip thrusts",
  },
  {
    id: 21,
    "Lower Body Strength Exercises": "Resistance band side leg raises",
  },
  {
    id: 22,
    "Lower Body Strength Exercises": "Resistance band clamshells",
  },
  {
    id: 23,
    "Lower Body Strength Exercises": "Resistance band squat pulses",
  },
  {
    id: 24,
    "Lower Body Strength Exercises": "Kettlebell squats",
  },
  {
    id: 25,
    "Lower Body Strength Exercises": "Goblet squats",
  },
  {
    id: 26,
    "Lower Body Strength Exercises": "Dumbbell lunges",
  },
  {
    id: 27,
    "Lower Body Strength Exercises": "Bulgarian split squats with dumbbells",
  },
  {
    id: 28,
    "Lower Body Strength Exercises": "Wall sit",
  },
  {
    id: 29,
    "Lower Body Strength Exercises": "Reverse lunges",
  },
  {
    id: 30,
    "Lower Body Strength Exercises": "Step-ups with weights",
  },
  {
    id: 31,
    "Lower Body Strength Exercises": "Curtsy lunges",
  },
  {
    id: 32,
    "Lower Body Strength Exercises": "Leg press machine",
  },
  {
    id: 33,
    "Lower Body Strength Exercises": "Resistance band hamstring curls",
  },
  {
    id: 34,
    "Lower Body Strength Exercises": "Resistance band glute bridges",
  },
  {
    id: 35,
    "Lower Body Strength Exercises": "Resistance band seated leg press",
  },
  {
    id: 36,
    "Lower Body Strength Exercises": "Dumbbell squats",
  },
  {
    id: 37,
    "Lower Body Strength Exercises": "Bodyweight squats",
  },
  {
    id: 38,
    "Lower Body Strength Exercises": "Jump squats",
  },
  {
    id: 39,
    "Lower Body Strength Exercises": "Squat jumps with weights",
  },
  {
    id: 40,
    "Lower Body Strength Exercises": "Reverse lunges with weights",
  },
  {
    id: 41,
    "Lower Body Strength Exercises": "Dumbbell lunges with weights",
  },
  {
    id: 42,
    "Lower Body Strength Exercises": "Curtsy lunges with weights",
  },
  {
    id: 43,
    "Lower Body Strength Exercises": "Leg press machine with weights",
  },
  {
    id: 44,
    "Lower Body Strength Exercises":
      "Resistance band hamstring curls with weights",
  },
  {
    id: 45,
    "Lower Body Strength Exercises":
      "Resistance band glute bridges with weights",
  },
  {
    id: 46,
    "Lower Body Strength Exercises":
      "Resistance band side leg raises with weights",
  },
  {
    id: 47,
    "Lower Body Strength Exercises":
      "Resistance band seated leg press with weights",
  },
  {
    id: 48,
    "Lower Body Strength Exercises": "Resistance band clamshells with weights",
  },
  {
    id: 49,
    "Lower Body Strength Exercises":
      "Resistance band squat pulses with weights",
  },
  {
    id: 50,
    "Lower Body Strength Exercises": "Dumbbell squats with weights",
  },
  {
    id: 51,
    "Lower Body Strength Exercises": "Bodyweight squats with weights",
  },
  {
    id: 52,
    "Lower Body Strength Exercises": "BB Back Squat",
  },
  {
    id: 53,
    "Lower Body Strength Exercises": "BB Back Squat with pause",
  },
  {
    id: 54,
    "Lower Body Strength Exercises": "BB Back Squat with heels on plates",
  },
  {
    id: 55,
    "Lower Body Strength Exercises": "BB Front Squat",
  },
  {
    id: 56,
    "Lower Body Strength Exercises": "BB Front Squat wth pause",
  },
  {
    id: 57,
    "Lower Body Strength Exercises": "DB Squat",
  },
  {
    id: 58,
    "Lower Body Strength Exercises": "BB Bulgarian Squat",
  },
  {
    id: 59,
    "Lower Body Strength Exercises": "DB Bulgarian Squat",
  },
  {
    id: 60,
    "Lower Body Strength Exercises": "Front Rack BB Bulgarian Squat",
  },
  {
    id: 61,
    "Lower Body Strength Exercises": "Overhead BB Bulgarian Squat",
  },
  {
    id: 62,
    "Lower Body Strength Exercises": "BB Lunge",
  },
  {
    id: 63,
    "Lower Body Strength Exercises": "BB Reverse Lunge to Step Up",
  },
  {
    id: 64,
    "Lower Body Strength Exercises": "DB Reverse Lunge to Step Up",
  },
  {
    id: 65,
    "Lower Body Strength Exercises": "DB Alternate Lunge",
  },
  {
    id: 66,
    "Lower Body Strength Exercises": "BB Overhead Lunge",
  },
  {
    id: 67,
    "Lower Body Strength Exercises": "Overhead Lunge with plate",
  },
  {
    id: 68,
    "Lower Body Strength Exercises": "DB Split Squat",
  },
  {
    id: 69,
    "Lower Body Strength Exercises": "BB Deadlift",
  },
  {
    id: 70,
    "Lower Body Strength Exercises": "BB Romanian Deadlift (knee level)",
  },
  {
    id: 71,
    "Lower Body Strength Exercises": "BB Romanian Deadlift (mid shin level)",
  },
  {
    id: 72,
    "Lower Body Strength Exercises": "Trap Bar Deadlft",
  },
  {
    id: 73,
    "Lower Body Strength Exercises": "Leg Extensions Machine",
  },
  {
    id: 74,
    "Lower Body Strength Exercises": "Single Leg Extension Machine",
  },
  {
    id: 75,
    "Lower Body Strength Exercises": "Seated Leg Curls Machine",
  },
  {
    id: 76,
    "Lower Body Strength Exercises": "Declined Leg Press",
  },
  {
    id: 77,
    "Lower Body Strength Exercises": "Single Leg Declied Leg Press",
  },
  {
    id: 78,
    "Lower Body Strength Exercises": "Hack Squats",
  },
  {
    id: 79,
    "Lower Body Strength Exercises": "BB Lateral Lunge",
  },
  {
    id: 80,
    "Lower Body Strength Exercises": "BB Hip Thrust",
  },
  {
    id: 81,
    "Lower Body Strength Exercises": "BB Split Squat",
  },
  {
    id: 82,
    "Lower Body Strength Exercises": "Single Leg Box Squat",
  },
  {
    id: 83,
    "Lower Body Strength Exercises": "BB Squat Jump",
  },
  {
    id: 84,
    "Lower Body Strength Exercises": "Standing Calf Raises",
  },
  {
    id: 85,
    "Lower Body Strength Exercises": "Standing Calf Raises Single Leg",
  },
  {
    id: 86,
    "Lower Body Strength Exercises": "Seated Calf Raises",
  },
  {
    id: 87,
    "Lower Body Strength Exercises": "BB Floor Glute Bridge",
  },
  {
    id: 88,
    "Lower Body Strength Exercises": "Lying Hamstring Curls",
  },
  {
    id: 89,
    "Lower Body Strength Exercises": "TRX Squat",
  },
  {
    id: 90,
    "Lower Body Strength Exercises": "TRX Jump Squat",
  },
  {
    id: 91,
    "Lower Body Strength Exercises": "TRX Step Back Lunge",
  },
  {
    id: 92,
    "Lower Body Strength Exercises": "TRX Split Jumps",
  },
  {
    id: 93,
    "Lower Body Strength Exercises": "TRX Suspended Lunge",
  },
  {
    id: 94,
    "Lower Body Strength Exercises": "TRX Suspended Lunge Arms OH",
  },
  {
    id: 95,
    "Lower Body Strength Exercises": "TRX Hamstring Curls",
  },
  {
    id: 96,
    "Lower Body Strength Exercises": "TRX Hamstring Runner",
  },
  {
    id: 97,
    "Lower Body Strength Exercises": "TRX Hip Press",
  },
  {
    id: 98,
    "Lower Body Strength Exercises": "TRX Lateral Lunge",
  },
  {
    id: 99,
    "Lower Body Strength Exercises": "TRX Lateral Lunge with Medicine Ball",
  },
  {
    id: 100,
    "Lower Body Strength Exercises": "TRX ISO Lunge with MB Slam",
  },
  {
    id: 101,
    "Lower Body Strength Exercises": "TRX Leg Extension",
  },
  {
    id: 102,
    "Lower Body Strength Exercises": "TRX Single Leg Squat",
  },
  {
    id: 103,
    "Lower Body Strength Exercises": "TRX Single Leg Deadlift",
  },
  {
    id: 104,
    "Lower Body Strength Exercises": "Swiss Ball Wall Squat",
  },
  {
    id: 105,
    "Lower Body Strength Exercises": "Swiss Ball Single Leg Squat",
  },
  {
    id: 106,
    "Lower Body Strength Exercises": "Swiss Ball Hamstring Curls",
  },
  {
    id: 107,
    "Lower Body Strength Exercises": "Swiss Ball Single Leg Hamstrng Curls",
  },
  {
    id: 108,
    "Lower Body Strength Exercises": "KB Goblet Squat",
  },
  {
    id: 109,
    "Lower Body Strength Exercises": "KB Single Leg Squat",
  },
  {
    id: 110,
    "Lower Body Strength Exercises": "KB Single Leg Deadlift",
  },
  {
    id: 111,
    "Lower Body Strength Exercises": "KB Swing",
  },
  {
    id: 112,
    "Lower Body Strength Exercises": "KB Squat 2 KBs",
  },
  {
    id: 113,
    "Lower Body Strength Exercises": "KB Squat 1KB",
  },
  {
    id: 114,
    "Lower Body Strength Exercises": "KB Deadlift",
  },
  {
    id: 115,
    "Lower Body Strength Exercises": "KB Lateral Lunge",
  },
  {
    id: 116,
    "Lower Body Strength Exercises": "KB Forward Lunge",
  },
  {
    id: 117,
    "Lower Body Strength Exercises": "KB Split Squat",
  },
  {
    id: 118,
    "Lower Body Strength Exercises": "KB Half Kneeling SH Press",
  },
  {
    id: 119,
    "Lower Body Strength Exercises": "KB Suitcase Squat",
  },
  {
    id: 120,
    "Lower Body Strength Exercises": "Kneeling Leg Abduction/ Adduction",
  },
  {
    id: 121,
    "Lower Body Strength Exercises": "Standing Leg Abduction/Adduction",
  },
  {
    id: 122,
    "Lower Body Strength Exercises": "Slideboard Rotational Lunge",
  },
  {
    id: 123,
    "Lower Body Strength Exercises": "Slideboard Forward Lunge",
  },
  {
    id: 124,
    "Lower Body Strength Exercises": "Slideboard Reverse Lunge",
  },
  {
    id: 125,
    "Lower Body Strength Exercises": "Slideboard Lateral Lunge with MB",
  },
  {
    id: 126,
    "Lower Body Strength Exercises": "Slideboard Lateral Lunge",
  },
  {
    id: 127,
    "Lower Body Strength Exercises": "Slideboard/ Slide Mat Hamstring Curls",
  },
  {
    id: 128,
    "Lower Body Strength Exercises": "MB Squat",
  },
  {
    id: 129,
    "Lower Body Strength Exercises": "Russian Belt Squat",
  },
  {
    id: 130,
    "Lower Body Strength Exercises": "Bosu Squat",
  },
  {
    id: 131,
    "Lower Body Strength Exercises": "Bosu Squat Bottom Up",
  },
  {
    id: 132,
    "Lower Body Strength Exercises": "GHD Hamstring Russian Curls",
  },
  {
    id: 133,
    "Lower Body Strength Exercises": "Nordic Curls",
  },
  {
    id: 134,
    "Lower Body Strength Exercises": "Assisted Nordic Curls",
  },
  {
    id: 135,
    "Lower Body Strength Exercises": "BW Squat MB Touch",
  },
  {
    id: 136,
    "Lower Body Strength Exercises": "BW Squat",
  },
  {
    id: 137,
    "Lower Body Strength Exercises": "BB Step Up",
  },
  {
    id: 138,
    "Lower Body Strength Exercises": "DB Step Up",
  },
  {
    id: 139,
    "Lower Body Strength Exercises": "Step Up with Plate OH",
  },
  {
    id: 140,
    "Lower Body Strength Exercises": "Back Extensions",
  },
  {
    id: 141,
    "Lower Body Strength Exercises": "Single Leg Back Extensions",
  },
  {
    id: 142,
    "Lower Body Strength Exercises": "Single Leg Barbell Hip Thrust",
  },
  {
    id: 143,
    "Lower Body Strength Exercises": "Single Leg Barbell Step Up",
  },
  {
    id: 144,
    "Lower Body Strength Exercises": "Single Leg DB Step Up",
  },
  {
    id: 145,
    "Lower Body Strength Exercises": "Single Leg Step up with DB SH Press",
  },
  {
    id: 146,
    "Lower Body Strength Exercises": "Isometric Heel Up Straight Knee",
  },
  {
    id: 147,
    "Lower Body Strength Exercises": "Isometric Heel Up Bent Knee",
  },
  {
    id: 148,
    "Lower Body Strength Exercises": "Zercher Squat",
  },
  {
    id: 149,
    "Lower Body Strength Exercises": "Trap Bar Jump",
  },
  {
    id: 150,
    "Lower Body Strength Exercises": "Safety Bar Squat",
  },
  {
    id: 151,
    "Lower Body Strength Exercises": "Isometric Swiss Ball Groin Squeeze",
  },
  {
    id: 152,
    "Lower Body Strength Exercises": "Cable Lateral Lunge",
  },
  {
    id: 153,
    "Lower Body Strength Exercises": "Dynamic Single Leg Calf Raises",
  },
  {
    id: 154,
    "Lower Body Strength Exercises": "BB Single Leg Deadlift",
  },
  {
    id: 155,
    "Lower Body Strength Exercises": "RIP TRAINER Steer Squat",
  },
  {
    id: 156,
    "Lower Body Strength Exercises":
      "Single Leg Deadlift with DB Shoulder Press",
  },
];
export const CoreExercises = [
  {
    id: 1,
    "Core Exercises": "Crunches",
  },
  {
    id: 2,
    "Core Exercises": "Bicycle crunches",
  },
  {
    id: 3,
    "Core Exercises": "Russian twists",
  },
  {
    id: 4,
    "Core Exercises": "Plank",
  },
  {
    id: 5,
    "Core Exercises": "Side plank",
  },
  {
    id: 6,
    "Core Exercises": "Mountain climbers",
  },
  {
    id: 7,
    "Core Exercises": "Reverse crunches",
  },
  {
    id: 8,
    "Core Exercises": "Leg raises",
  },
  {
    id: 9,
    "Core Exercises": "V-ups",
  },
  {
    id: 10,
    "Core Exercises": "Russian twists with weight",
  },
  {
    id: 11,
    "Core Exercises": "Flutter kicks",
  },
  {
    id: 12,
    "Core Exercises": "Sit-ups",
  },
  {
    id: 13,
    "Core Exercises": "Russian twists with medicine ball",
  },
  {
    id: 14,
    "Core Exercises": "Plank with shoulder taps",
  },
  {
    id: 15,
    "Core Exercises": "Dead bug exercise",
  },
  {
    id: 16,
    "Core Exercises": "Bicycle crunches with a twist",
  },
  {
    id: 17,
    "Core Exercises": "Scissor kicks",
  },
  {
    id: 18,
    "Core Exercises": "Jackknife sit-ups",
  },
  {
    id: 19,
    "Core Exercises": "Russian twists with ball",
  },
  {
    id: 20,
    "Core Exercises": "Reverse crunches with leg extension",
  },
  {
    id: 21,
    "Core Exercises": "Russian twists with weight and rotation",
  },
  {
    id: 22,
    "Core Exercises": "Plank with knee to opposite elbow",
  },
  {
    id: 23,
    "Core Exercises": "Standing side crunches",
  },
  {
    id: 24,
    "Core Exercises": "Standing bicycle crunches",
  },
  {
    id: 25,
    "Core Exercises": "Side plank with hip dip",
  },
  {
    id: 26,
    "Core Exercises": "Oblique crunches",
  },
  {
    id: 27,
    "Core Exercises": "Hanging leg raises",
  },
  {
    id: 28,
    "Core Exercises": "Plank with leg lifts",
  },
  {
    id: 29,
    "Core Exercises": "Side plank with leg raise",
  },
  {
    id: 30,
    "Core Exercises": "Russian twists with resistance band",
  },
  {
    id: 31,
    "Core Exercises": "Bicycle crunches with a twist and rotation",
  },
  {
    id: 32,
    "Core Exercises": "Plank with alternating knee to elbow",
  },
  {
    id: 33,
    "Core Exercises": "Seated Russian twists with weight",
  },
  {
    id: 34,
    "Core Exercises": "Plank with side taps",
  },
  {
    id: 35,
    "Core Exercises": "Standing Russian twists with weight",
  },
  {
    id: 36,
    "Core Exercises": "Hanging leg raises with a twist",
  },
  {
    id: 37,
    "Core Exercises": "Ab rollouts",
  },
  {
    id: 38,
    "Core Exercises": "Bird dogs",
  },
  {
    id: 39,
    "Core Exercises": "Superman exercise",
  },
  {
    id: 40,
    "Core Exercises": "Hollow body hold",
  },
  {
    id: 41,
    "Core Exercises": "Side plank with leg lift and rotation",
  },
  {
    id: 42,
    "Core Exercises": "Plank with toe taps",
  },
  {
    id: 43,
    "Core Exercises": "Plank with knee to opposite elbow and twist",
  },
  {
    id: 44,
    "Core Exercises": "Russian twists with ball and rotation",
  },
  {
    id: 45,
    "Core Exercises": "Plank with shoulder taps and leg lifts",
  },
  {
    id: 46,
    "Core Exercises": "Plank with alternating knee to elbow and twist",
  },
  {
    id: 47,
    "Core Exercises": "Seated Russian twists with weight and rotation",
  },
  {
    id: 48,
    "Core Exercises": "Ab rollouts with stability ball",
  },
  {
    id: 49,
    "Core Exercises": "Superman exercise with stability ball",
  },
  {
    id: 50,
    "Core Exercises": "Hollow body hold with stability ball",
  },
  {
    id: 51,
    "Core Exercises": "Plank with toe taps on stability ball",
  },
  {
    id: 52,
    "Core Exercises": "Bicycle crunches with a twist on stability ball",
  },
  {
    id: 53,
    "Core Exercises": "Russian twists with ball and rotation on stability ball",
  },
  {
    id: 54,
    "Core Exercises": "Hanging leg raises with a twist on stability ball",
  },
  {
    id: 55,
    "Core Exercises": "Side plank with leg lift and rotation on stability ball",
  },
  {
    id: 56,
    "Core Exercises":
      "Plank with shoulder taps and leg lifts on stability ball",
  },
  {
    id: 57,
    "Core Exercises":
      "Bicycle crunches with a twist and rotation on stability ball",
  },
  {
    id: 58,
    "Core Exercises":
      "Plank with alternating knee to elbow and twist on stability ball",
  },
  {
    id: 59,
    "Core Exercises":
      "Seated Russian twists with weight and rotation on stability ball",
  },
  {
    id: 60,
    "Core Exercises": "Plank with leg lifts on stability ball",
  },
  {
    id: 61,
    "Core Exercises": "Pike push-ups with rotation",
  },
  {
    id: 62,
    "Core Exercises": "Curtsy lunges with weights",
  },
  {
    id: 63,
    "Core Exercises": "Plank with rotation",
  },
  {
    id: 64,
    "Core Exercises": "Trunk rotation",
  },
  {
    id: 65,
    "Core Exercises": "Leg press machine with weights",
  },
  {
    id: 66,
    "Core Exercises": "Plank ( forearms )",
  },
  {
    id: 67,
    "Core Exercises": "Plank (knees on the floor)",
  },
  {
    id: 68,
    "Core Exercises": "Straight Arms Plank",
  },
  {
    id: 69,
    "Core Exercises": "SB  Elbow Plank Single Leg Hip Extens.",
  },
  {
    id: 70,
    "Core Exercises": "SB Straight Arms Plank Single Leg Hip Extens.",
  },
  {
    id: 71,
    "Core Exercises": "SB Elbow Plank Feet Elevated",
  },
  {
    id: 72,
    "Core Exercises": "SB Straight Arms Plank Feet Elevated",
  },
  {
    id: 73,
    "Core Exercises": "SB Straight Arm Plank",
  },
  {
    id: 74,
    "Core Exercises": "SB Elbow Plank",
  },
  {
    id: 75,
    "Core Exercises": "Elbow Plank Unstable LB and UB",
  },
  {
    id: 76,
    "Core Exercises": "Straight Arm Plank Unstable LB and UB",
  },
  {
    id: 77,
    "Core Exercises": "Straight Arm Plank on MB Leg Abduction",
  },
  {
    id: 78,
    "Core Exercises": "Bosu Elbow Plank Leg Abduction",
  },
  {
    id: 79,
    "Core Exercises": "Bosu Straight Arm Plank SL Hip Extension",
  },
  {
    id: 80,
    "Core Exercises": "Bosu Straight Arm Plank",
  },
  {
    id: 81,
    "Core Exercises": "Bosu Elbow Plank",
  },
  {
    id: 82,
    "Core Exercises": "Straight Arms Plank Leg Abduction",
  },
  {
    id: 83,
    "Core Exercises": "Straight Arms Plank SL Hip Extension",
  },
  {
    id: 84,
    "Core Exercises": "Elbow Plank Leg Abduction",
  },
  {
    id: 85,
    "Core Exercises": "Elbow Plank SL Hip Extension",
  },
  {
    id: 86,
    "Core Exercises": "TRX Plank",
  },
  {
    id: 87,
    "Core Exercises": "TRX Body Saw",
  },
  {
    id: 88,
    "Core Exercises": "TRX Crunch",
  },
  {
    id: 89,
    "Core Exercises": "TRX Crunch on hands",
  },
  {
    id: 90,
    "Core Exercises": "TRX Pike",
  },
  {
    id: 91,
    "Core Exercises": "Swiss Ball Pike",
  },
  {
    id: 92,
    "Core Exercises": "Swiss Ball Jackknife",
  },
  {
    id: 93,
    "Core Exercises": "Slideboard/ Slide Mat Crunch",
  },
  {
    id: 94,
    "Core Exercises": "Slideboard/ Slide Mat Pike",
  },
  {
    id: 95,
    "Core Exercises": "Mountain Climber",
  },
  {
    id: 96,
    "Core Exercises": "Crunch Knee UP",
  },
  {
    id: 97,
    "Core Exercises": "Banded Hip Flexor Pull",
  },
  {
    id: 98,
    "Core Exercises": "Straight Arm Plank with DB Row",
  },
  {
    id: 99,
    "Core Exercises": "Lateral Elbow Plank",
  },
  {
    id: 100,
    "Core Exercises": "Lateral Elbow Plank ( knee on the floor )",
  },
  {
    id: 101,
    "Core Exercises": "Lateral Straight Arm Plank",
  },
  {
    id: 102,
    "Core Exercises": "Lateral Straight Arm Plank Leg Abduction",
  },
  {
    id: 103,
    "Core Exercises": "Lateral Straight Arm Plank Leg Adduction",
  },
  {
    id: 104,
    "Core Exercises": "Lateral Straight Arm Plank Unstable UB and LB",
  },
  {
    id: 105,
    "Core Exercises": "Lateral Bosu Elbow Plank",
  },
  {
    id: 106,
    "Core Exercises": "Lateral Bosu Straight Arm Plank",
  },
  {
    id: 107,
    "Core Exercises": "Lateral Bosu Straight Arm Plank Leg Abduction",
  },
  {
    id: 108,
    "Core Exercises": "Lateral Elbow Plank Hip Flexion",
  },
  {
    id: 109,
    "Core Exercises": "Lateral Elbow Plank Leg Abduction",
  },
  {
    id: 110,
    "Core Exercises": "Copenhagen Plank",
  },
  {
    id: 111,
    "Core Exercises": "Lateral Elbow Plank Leg Abduction with Mini Band",
  },
  {
    id: 112,
    "Core Exercises": "Lateral Elbow Plank on MB Leg Abduction",
  },
  {
    id: 113,
    "Core Exercises": "Lateral Elbow Plank on MB",
  },
  {
    id: 114,
    "Core Exercises": "Lateral Elbow Plank Unstable LB and UB",
  },
  {
    id: 115,
    "Core Exercises": "Hip Press Pallof Press",
  },
  {
    id: 116,
    "Core Exercises": "Standing Pallof Press",
  },
  {
    id: 117,
    "Core Exercises": "Standing Pallof Press with Shoulder Flexion",
  },
  {
    id: 118,
    "Core Exercises": "Half Kneeling Pallof Press",
  },
  {
    id: 119,
    "Core Exercises": "Tall Kneeling Pallof Press",
  },
  {
    id: 120,
    "Core Exercises": "Pallof Press Split Squat Position",
  },
  {
    id: 121,
    "Core Exercises": "Dead Bug",
  },
  {
    id: 122,
    "Core Exercises": "Dead Bug Opposite Arm Leg",
  },
  {
    id: 123,
    "Core Exercises": "Dead Bug Hands Against Wall",
  },
  {
    id: 124,
    "Core Exercises": "Dead Bug Hands Against Wall Level 2",
  },
  {
    id: 125,
    "Core Exercises": "Bird Dog Level 1",
  },
  {
    id: 126,
    "Core Exercises": "Bird Dog Level 2 Alternate Arms",
  },
  {
    id: 127,
    "Core Exercises": "Bird Dog Level 3 Alternate Legs",
  },
  {
    id: 128,
    "Core Exercises": "Bird Dog Level 4 Opposite Arm and Leg",
  },
  {
    id: 129,
    "Core Exercises": "Bird Dog Level 5 Elbow to Knee",
  },
  {
    id: 130,
    "Core Exercises": "Bird Dog Level 6 with resistance",
  },
];
export const PlyometricExercises = [
  {
    id: 1,
    "Plyometric Exercises": "Box Jumps",
  },
  {
    id: 2,
    "Plyometric Exercises": "Broad Jumps",
  },
  {
    id: 3,
    "Plyometric Exercises": "Jump Squats",
  },
  {
    id: 4,
    "Plyometric Exercises": "Burpees",
  },
  {
    id: 5,
    "Plyometric Exercises": "Jump Lunges",
  },
  {
    id: 6,
    "Plyometric Exercises": "Tuck Jumps",
  },
  {
    id: 7,
    "Plyometric Exercises": "Plyometric Push-ups",
  },
  {
    id: 8,
    "Plyometric Exercises": "Power Skipping",
  },
  {
    id: 9,
    "Plyometric Exercises": "Split Jumps",
  },
  {
    id: 10,
    "Plyometric Exercises": "Depth Jumps",
  },
  {
    id: 11,
    "Plyometric Exercises": "Medicine Ball Throws",
  },
  {
    id: 12,
    "Plyometric Exercises": "Jumping Jacks",
  },
  {
    id: 13,
    "Plyometric Exercises": "Skater Jumps",
  },
  {
    id: 14,
    "Plyometric Exercises": "Clapping Push-ups",
  },
  {
    id: 15,
    "Plyometric Exercises": "Agility Exercises",
  },
  {
    id: 16,
    "Plyometric Exercises": "Agility Ladder Drills",
  },
  {
    id: 17,
    "Plyometric Exercises": "Cone Drills",
  },
  {
    id: 18,
    "Plyometric Exercises": "Dot Drills",
  },
  {
    id: 19,
    "Plyometric Exercises": "Zigzag Runs",
  },
  {
    id: 20,
    "Plyometric Exercises": "CMJ Countermovement jump",
  },
  {
    id: 21,
    "Plyometric Exercises": "SJ Squat Jump",
  },
  {
    id: 22,
    "Plyometric Exercises": "Single Leg Box Jump",
  },
  {
    id: 23,
    "Plyometric Exercises": "Stifness Jump",
  },
  {
    id: 24,
    "Plyometric Exercises": "Tuck Jump",
  },
  {
    id: 25,
    "Plyometric Exercises": "Split Squat Jump",
  },
  {
    id: 26,
    "Plyometric Exercises": "Alternately Split Squat Jump",
  },
  {
    id: 27,
    "Plyometric Exercises": "Assisted Split Squat Jump",
  },
  {
    id: 28,
    "Plyometric Exercises": "Assisted Squat Jump",
  },
  {
    id: 29,
    "Plyometric Exercises": "Split Squat Jump rear foot elevated",
  },
  {
    id: 30,
    "Plyometric Exercises":
      "Split Squat Jump rear foot elevated with arm swing",
  },
  {
    id: 31,
    "Plyometric Exercises": "Hurdle Jump",
  },
  {
    id: 32,
    "Plyometric Exercises": "Power Step Up",
  },
  {
    id: 33,
    "Plyometric Exercises": "Alternately Power Step Up",
  },
  {
    id: 34,
    "Plyometric Exercises": "Drop Jump",
  },
  {
    id: 35,
    "Plyometric Exercises": "Kneeling Jump",
  },
  {
    id: 36,
    "Plyometric Exercises": "Kneeling Jump with Vertical Jump",
  },
  {
    id: 37,
    "Plyometric Exercises": "Reactive Drop Squat Jump",
  },
  {
    id: 38,
    "Plyometric Exercises": "Mini Hurdle Jumps",
  },
  {
    id: 39,
    "Plyometric Exercises": "Single Leg Mini Hurdles Jumps",
  },
];
export const MobilityExercise = [
  {
    id: 1,
    "Mobility Exercise": "Standing Wall Drill",
  },
  {
    id: 2,
    "Mobility Exercise": "Half Kneeling Wall Drill",
  },
  {
    id: 3,
    "Mobility Exercise": "3 way Dorsi Flexion from Half Kneeling",
  },
  {
    id: 4,
    "Mobility Exercise": "Classic Wall Calf Stretch",
  },
  {
    id: 5,
    "Mobility Exercise": "Calf Stretch",
  },
  {
    id: 6,
    "Mobility Exercise": "Half Kneeling Hip Flexor Stretch A",
  },
  {
    id: 7,
    "Mobility Exercise": "Half Kneeling Hip Flexor Stretch B",
  },
  {
    id: 8,
    "Mobility Exercise": "Bretzel Stretch",
  },
  {
    id: 9,
    "Mobility Exercise": "Wall Rectus Femoris Stretch",
  },
  {
    id: 10,
    "Mobility Exercise": "Short Adductors Stretch",
  },
  {
    id: 11,
    "Mobility Exercise": "Hip External Rotation Stretch",
  },
  {
    id: 12,
    "Mobility Exercise": "Hip External Rotation Stretch Half Kneeling",
  },
  {
    id: 13,
    "Mobility Exercise": "Banded Hip Flexor Moblization",
  },
  {
    id: 14,
    "Mobility Exercise": "Banded Floor Deep Squat",
  },
  {
    id: 15,
    "Mobility Exercise": "TS Extension",
  },
  {
    id: 16,
    "Mobility Exercise": "TS Rotation",
  },
  {
    id: 17,
    "Mobility Exercise": "TS Mobilization with Double Ball",
  },
  {
    id: 18,
    "Mobility Exercise": "Quadruped TS Rotation",
  },
  {
    id: 19,
    "Mobility Exercise": "TS Rotation with Medicine Ball",
  },
  {
    id: 20,
    "Mobility Exercise": "90/90 Stretch",
  },
  {
    id: 21,
    "Mobility Exercise": "TS Foamrolling with cross friction",
  },
  {
    id: 22,
    "Mobility Exercise": "TS Plate Smash",
  },
  {
    id: 23,
    "Mobility Exercise": "TS Smash with double ball and BB OH",
  },
  {
    id: 24,
    "Mobility Exercise": "Shoulder Floor Slide",
  },
  {
    id: 25,
    "Mobility Exercise": "Prone Rocking Y Raises",
  },
  {
    id: 26,
    "Mobility Exercise": "Prone Rocking Y raises unilateral",
  },
  {
    id: 27,
    "Mobility Exercise": "Banded Overhead Distracton",
  },
  {
    id: 28,
    "Mobility Exercise": "Posterior Deltois Smash and Floss",
  },
  {
    id: 29,
    "Mobility Exercise": "Lying Posterior Shoulder Capsule Stretch",
  },
  {
    id: 30,
    "Mobility Exercise": "Posterior Shoulder Stretch Kneeling",
  },
  {
    id: 31,
    "Mobility Exercise": "BB Pass Through",
  },
  {
    id: 32,
    "Mobility Exercise": "Shoulder Manipulation with BB",
  },
  {
    id: 33,
    "Mobility Exercise": "Chest Manipulation with ball",
  },
  {
    id: 34,
    "Mobility Exercise": "Overhead Positioning with ball",
  },
];

export const OlympicLifts = [
  {
    id: 1,
    "Olympic Lifts": "Clean",
  },
  {
    id: 2,
    "Olympic Lifts": "Power Clean",
  },
  {
    id: 3,
    "Olympic Lifts": "Power Clean from Hang",
  },
  {
    id: 4,
    "Olympic Lifts": "Clean from Hang",
  },
  {
    id: 5,
    "Olympic Lifts": "Clean and Split Jerk",
  },
  {
    id: 6,
    "Olympic Lifts": "Power Clean From Hang & Split Jerk",
  },
  {
    id: 7,
    "Olympic Lifts": "Clean From Hang & Split Jerk",
  },
  {
    id: 8,
    "Olympic Lifts": "Power Clean to Split Squat",
  },
  {
    id: 9,
    "Olympic Lifts": "Overhead Squat",
  },
  {
    id: 10,
    "Olympic Lifts": "Overhead Squat with Pause",
  },
  {
    id: 11,
    "Olympic Lifts": "Snatch",
  },
  {
    id: 12,
    "Olympic Lifts": "Power Snatch",
  },
  {
    id: 13,
    "Olympic Lifts": "Snatch from hang (knee level)",
  },
  {
    id: 14,
    "Olympic Lifts": "Power Snatch from hang (knee level)",
  },
  {
    id: 15,
    "Olympic Lifts": "Power Snatch from High Hang",
  },
  {
    id: 16,
    "Olympic Lifts": "Squat Snatch",
  },
  {
    id: 17,
    "Olympic Lifts": "Snatch Grip Push Press",
  },
  {
    id: 18,
    "Olympic Lifts": "Snatch Grip Shoulder Press",
  },
  {
    id: 19,
    "Olympic Lifts": "Snatch Balance",
  },
  {
    id: 20,
    "Olympic Lifts": "Heaving Snatch Balance",
  },
  {
    id: 21,
    "Olympic Lifts": "Drop Snatch",
  },
  {
    id: 22,
    "Olympic Lifts": "BB Push Press",
  },
  {
    id: 23,
    "Olympic Lifts": "DB Push Press",
  },
  {
    id: 24,
    "Olympic Lifts": "KB Push Press",
  },
  {
    id: 25,
    "Olympic Lifts": "BB Thruster",
  },
  {
    id: 26,
    "Olympic Lifts": "DB Thruster",
  },
  {
    id: 27,
    "Olympic Lifts": "Trap Bar Jump",
  },
];
export const LumboPelvicHip = [
  {
    id: 1,
    "Lumbo Pelvic Hip Exercises": "Floor Hip Bridge",
  },
  {
    id: 2,
    "Lumbo Pelvic Hip Exercises":
      "Single Leg Floor Hip Bridge with core activation",
  },
  {
    id: 3,
    "Lumbo Pelvic Hip Exercises": "Floor Hip Bridge with mini band",
  },
  {
    id: 4,
    "Lumbo Pelvic Hip Exercises": "Floor Hip Bridge with MB",
  },
  {
    id: 5,
    "Lumbo Pelvic Hip Exercises": "Swiss Ball Hip Bridge",
  },
  {
    id: 6,
    "Lumbo Pelvic Hip Exercises": "Swiss Ball SL Hip Bridge",
  },
  {
    id: 7,
    "Lumbo Pelvic Hip Exercises": "Swiss Ball Hip Bridge Feet Elevated",
  },
  {
    id: 8,
    "Lumbo Pelvic Hip Exercises": "Swiss Ball SL Hip Brdge Feet Elevated",
  },
  {
    id: 9,
    "Lumbo Pelvic Hip Exercises": "Banded Single Leg Hip Bridge",
  },
  {
    id: 10,
    "Lumbo Pelvic Hip Exercises": "Hip Press Feet Elevated",
  },
  {
    id: 11,
    "Lumbo Pelvic Hip Exercises": "Single Leg Hip Press Foot Elevated",
  },
  {
    id: 12,
    "Lumbo Pelvic Hip Exercises": "Hip Press Pallof Press",
  },
  {
    id: 13,
    "Lumbo Pelvic Hip Exercises": "Hip Press Leg Lock",
  },
  {
    id: 14,
    "Lumbo Pelvic Hip Exercises": "Hip Press Straight Leg",
  },
];
export const PosturalControls = [
  {
    id: 1,
    "Postural Controls": "KB Unilateral Waiters Walk",
  },
  {
    id: 2,
    "Postural Controls": "KB Bilateral Waiters Walk",
  },
  {
    id: 3,
    "Postural Controls": "KB Cross Walk",
  },
  {
    id: 4,
    "Postural Controls": "KB Unilateral Farmer's Walk",
  },
  {
    id: 5,
    "Postural Controls": "BB Offset Hold",
  },
];
export const SwissBall = [
  {
    id: 1,
    "Swiss Ball": "Swiss Ball Wall Squat",
  },
  {
    id: 2,
    "Swiss Ball": "Swiss Ball Single Leg Squat",
  },
  {
    id: 3,
    "Swiss Ball": "Swiss Ball Hamstring Curls",
  },
  {
    id: 4,
    "Swiss Ball": "Swiss Ball Single Leg Hamstring Curls",
  },
  {
    id: 5,
    "Swiss Ball": "Swiss Ball DB Shoulder Press",
  },
  {
    id: 6,
    "Swiss Ball": "Swiss Ball DB Chest Press",
  },
  {
    id: 7,
    "Swiss Ball": "Swiss Ball Pike",
  },
  {
    id: 8,
    "Swiss Ball": "Swiss Ball Jackknife",
  },
  {
    id: 9,
    "Swiss Ball": "Swiss Ball Hip Bridge",
  },
  {
    id: 10,
    "Swiss Ball": "Swiss Ball SL Hip Bridge",
  },
  {
    id: 11,
    "Swiss Ball": "Swiss Ball Hip Bridge Feet Elevated",
  },
  {
    id: 12,
    "Swiss Ball": "Swiss Ball SL Hip Bridge Feet Elevated",
  },
  {
    id: 13,
    "Swiss Ball": "Swiss Ball Single Leg Hip Press",
  },
  {
    id: 14,
    "Swiss Ball": "Swiss Ball Hip Press",
  },
  {
    id: 15,
    "Swiss Ball": "Isometric Swiss Ball Groin Squeeze",
  },
];
export const TRX = [
  {
    id: 1,
    TRX: "TRX Squat",
  },
  {
    id: 2,
    TRX: "TRX Jump Squat",
  },
  {
    id: 3,
    TRX: "TRX Step Back Lunge",
  },
  {
    id: 4,
    TRX: "TRX Split Jumps",
  },
  {
    id: 5,
    TRX: "TRX Forward Lunge with Chest Stretch",
  },
  {
    id: 6,
    TRX: "TRX Forward Lunge with Reach OH",
  },
  {
    id: 7,
    TRX: "TRX Supended Lunge",
  },
  {
    id: 8,
    TRX: "TRX Suspended Lunge Arms OH",
  },
  {
    id: 9,
    TRX: "TRX Hamstring Curls",
  },
  {
    id: 10,
    TRX: "TRX Hamstring Runner",
  },
  {
    id: 11,
    TRX: "TRX Hip Press",
  },
  {
    id: 12,
    TRX: "TRX Lateral Lunge",
  },
  {
    id: 13,
    TRX: "TRX Lateral Lunge with MB",
  },
  {
    id: 14,
    TRX: "TRX ISO Lunge with MB Slam",
  },
  {
    id: 15,
    TRX: "TRX Low Row",
  },
  {
    id: 16,
    TRX: "TRX Single Arm Row",
  },
  {
    id: 17,
    TRX: "TRX Single Arm Row with Kettlebell Press",
  },
  {
    id: 18,
    TRX: "TRX Swimmers Pull",
  },
  {
    id: 19,
    TRX: "TRX Y Deltoid Raises",
  },
  {
    id: 20,
    TRX: "TRX T Deltoid Flys",
  },
  {
    id: 21,
    TRX: "TRX Biceps Curls",
  },
  {
    id: 22,
    TRX: "TRX Triceps Press",
  },
  {
    id: 23,
    TRX: "TRX Triceps Press Reverse Grip",
  },
  {
    id: 24,
    TRX: "TRX High Row",
  },
  {
    id: 25,
    TRX: "TRX Inward Biceps Curls",
  },
  {
    id: 26,
    TRX: "TRX Single Arm Biceps Curls",
  },
  {
    id: 27,
    TRX: "TRX Push Up",
  },
  {
    id: 28,
    TRX: "TRX Push Up on MB Ball",
  },
  {
    id: 29,
    TRX: "TRX Push Up on BOSU",
  },
  {
    id: 30,
    TRX: "TRX Chest Press",
  },
  {
    id: 31,
    TRX: "TRX Chest Flys",
  },
  {
    id: 32,
    TRX: "TRX Power Pull",
  },
  {
    id: 33,
    TRX: "TRX Single Leg Squat",
  },
  {
    id: 34,
    TRX: "TRX Hip Hinge",
  },
  {
    id: 35,
    TRX: "TRX Single Leg Deadlift",
  },
  {
    id: 36,
    TRX: "TRX Scarecrow",
  },
  {
    id: 37,
    TRX: "TRX Plank",
  },
  {
    id: 38,
    TRX: "TRX Body Saw",
  },
  {
    id: 39,
    TRX: "TRX Crunch",
  },
  {
    id: 40,
    TRX: "TRX Crunch on hands",
  },
  {
    id: 41,
    TRX: "TRX Pike",
  },
];
export const JLT = [
  {
    id: 1,
    "Jumping - Landing Techniques": "Drop Squat - Landing technique",
  },
  {
    id: 2,
    "Jumping - Landing Techniques": "Jump Squat Stick the Landing",
  },
  {
    id: 3,
    "Jumping - Landing Techniques": "Drop Squat Single Leg - Landing technique",
  },
  {
    id: 4,
    "Jumping - Landing Techniques": "Single Leg Forward Hop Stick the Landing",
  },
  {
    id: 5,
    "Jumping - Landing Techniques": "Single Leg Lateral Hop Sitck the Landing",
  },
  {
    id: 6,
    "Jumping - Landing Techniques": "Single Leg Backward Hop Stick the Landing",
  },
  {
    id: 7,
    "Jumping - Landing Techniques": "Single Leg Jump Stick the Landing",
  },
  {
    id: 8,
    "Jumping - Landing Techniques": "Double Leg Forward Jump Stick the Landing",
  },
  {
    id: 9,
    "Jumping - Landing Techniques":
      "Double Leg Backward Jump Stick the Landing",
  },
  {
    id: 10,
    "Jumping - Landing Techniques": "Double Leg Lateral Jump Stick the Landing",
  },
  {
    id: 11,
    "Jumping - Landing Techniques":
      "Multidirectional Jumps Stick the Landing Level 1",
  },
  {
    id: 12,
    "Jumping - Landing Techniques":
      "Multidirectional Jumps Stick the Landing Level 2",
  },
  {
    id: 13,
    "Jumping - Landing Techniques": "Multidirectional Jumps Level 3",
  },
];
export const MBE = [
  {
    id: 1,
    "Medicine Ball Exercises": "Tall Kneeling MB Rotational Throw",
  },
  {
    id: 2,
    "Medicine Ball Exercises": "MB First Step Accel. Drill",
  },
  {
    id: 3,
    "Medicine Ball Exercises": "MB Long Jump & Chest Throw",
  },
  {
    id: 4,
    "Medicine Ball Exercises": "MB Lateral Shuffle & Rotational Throw",
  },
  {
    id: 5,
    "Medicine Ball Exercises": "Standing MB Chest Throw",
  },
  {
    id: 6,
    "Medicine Ball Exercises": "Split Squat MB Single Arm Throw",
  },
  {
    id: 7,
    "Medicine Ball Exercises": "Half Kneeling Rotational MB Throw",
  },
  {
    id: 8,
    "Medicine Ball Exercises": "Half Kneeling MB Single Arm Throw",
  },
  {
    id: 9,
    "Medicine Ball Exercises": "Half Kneeling MB Chest Throw",
  },
  {
    id: 10,
    "Medicine Ball Exercises": "MB Chest Throw & Hip Extension",
  },
  {
    id: 11,
    "Medicine Ball Exercises": "Tall Kneeling MB Single Arm Throw",
  },
  {
    id: 12,
    "Medicine Ball Exercises": "Tall Kneeling MB Chest Throw",
  },
  {
    id: 13,
    "Medicine Ball Exercises": "MB Rotational Throw",
  },
  {
    id: 14,
    "Medicine Ball Exercises": "Supine OH MB Throw",
  },
  {
    id: 15,
    "Medicine Ball Exercises": "MB Slam",
  },
  {
    id: 16,
    "Medicine Ball Exercises": "MB Slam Lateral",
  },
  {
    id: 17,
    "Medicine Ball Exercises": "Seated MB Slam",
  },
  {
    id: 18,
    "Medicine Ball Exercises": "Explosive Single Leg MB Deadlift",
  },
  {
    id: 19,
    "Medicine Ball Exercises": "Overhead MB Throw",
  },
  {
    id: 20,
    "Medicine Ball Exercises": "MB Diagonal Chop",
  },
  {
    id: 21,
    "Medicine Ball Exercises": "MB Chop",
  },
  {
    id: 22,
    "Medicine Ball Exercises": "MB Rotational Lunge",
  },
  {
    id: 23,
    "Medicine Ball Exercises": "MB OH Lunge",
  },
  {
    id: 24,
    "Medicine Ball Exercises": "MB Lunge and Pass",
  },
  {
    id: 25,
    "Medicine Ball Exercises": "MB Lateral Lunge and Pass",
  },
  {
    id: 26,
    "Medicine Ball Exercises": "MB V Crunch",
  },
  {
    id: 27,
    "Medicine Ball Exercises": "MB Triceps Push Ups",
  },
  {
    id: 28,
    "Medicine Ball Exercises": "MB Thruster",
  },
  {
    id: 29,
    "Medicine Ball Exercises": "MB Sweeping Lateral Lunge",
  },
  {
    id: 30,
    "Medicine Ball Exercises": "MB Squat",
  },
  {
    id: 31,
    "Medicine Ball Exercises": "MB Squat and Pass",
  },
  {
    id: 32,
    "Medicine Ball Exercises": "MB Squat and Biceps Curl",
  },
  {
    id: 33,
    "Medicine Ball Exercises": "MB Sit Up",
  },
  {
    id: 34,
    "Medicine Ball Exercises": "MB Rotation",
  },
  {
    id: 35,
    "Medicine Ball Exercises": "Push Up with feet on MB",
  },
  {
    id: 36,
    "Medicine Ball Exercises": "Push Up with hands on MB",
  },
  {
    id: 37,
    "Medicine Ball Exercises": "MB Alternate Push Up",
  },
  {
    id: 38,
    "Medicine Ball Exercises": "MB Crunch Straight Legs",
  },
  {
    id: 39,
    "Medicine Ball Exercises": "SL Deadlift MB Drop Catch",
  },
];
export const AE = [
  {
    id: 1,
    "Activation Exercises": "VMO - Terminal Knee Extension",
  },
  {
    id: 2,
    "Activation Exercises": "VMO and Gluteus Medius Level 1 ISO",
  },
  {
    id: 3,
    "Activation Exercises": "VMO and Gluteus Medius Level 2",
  },
  {
    id: 4,
    "Activation Exercises": "Glutes - Mini Band Lateral Walk L1",
  },
  {
    id: 5,
    "Activation Exercises": "Glutes - Mini Band Lateral Walk L2",
  },
  {
    id: 6,
    "Activation Exercises": "Glutes - Mini Band Lateral Walk L3",
  },
  {
    id: 7,
    "Activation Exercises": "Glutes - Mini Band Side Lying Clam Shells",
  },
  {
    id: 8,
    "Activation Exercises": "Glutes - Mini Band Hip Bridge",
  },
  {
    id: 9,
    "Activation Exercises": "Glutes - Mini Band Hip External Rotation",
  },
  {
    id: 10,
    "Activation Exercises": "Hip 3-way Hip Activation",
  },
  {
    id: 11,
    "Activation Exercises": "Mini Band Linear Walk",
  },
  {
    id: 12,
    "Activation Exercises": "Banded Internal Rotation",
  },
  {
    id: 13,
    "Activation Exercises": "Single Arm External Rotation A",
  },
  {
    id: 14,
    "Activation Exercises": "Single Arm External Rotation B",
  },
  {
    id: 15,
    "Activation Exercises": "Single Arm Internal Rotation A",
  },
  {
    id: 16,
    "Activation Exercises": "Single Arm Internal Rotation B",
  },
  {
    id: 17,
    "Activation Exercises": "Half Kneeling Banded External Rotation Press",
  },
  {
    id: 18,
    "Activation Exercises": "Banded External Rotation",
  },
  {
    id: 19,
    "Activation Exercises": "Serratus Anteror Activation",
  },
  {
    id: 20,
    "Activation Exercises":
      "Serratus Anterior and Scapla Retractors Activation",
  },
  {
    id: 21,
    "Activation Exercises": "Banded Single Arm Scapula Retraction",
  },
  {
    id: 22,
    "Activation Exercises": "Scapulothoracic - Prone Scaption Y",
  },
  {
    id: 23,
    "Activation Exercises": "Scapulothoracc - Shoulder Abducton  T",
  },
  {
    id: 24,
    "Activation Exercises":
      "Scapulothoracc Prone Scapulat Depresion and Retraction",
  },
  {
    id: 25,
    "Activation Exercises": "Scapulothoracic - Resisted Band Pull Apart",
  },
  {
    id: 26,
    "Activation Exercises":
      "Banded Single Arm Scapula Depression and Retraction",
  },
  {
    id: 27,
    "Activation Exercises": "Banded Internal Rotation Press",
  },
  {
    id: 28,
    "Activation Exercises": "Banded External Rotation Press",
  },
  {
    id: 29,
    "Activation Exercises": "Multirirectional IR / ER",
  },
  {
    id: 30,
    "Activation Exercises": "Supine DB IR/ER",
  },
  {
    id: 31,
    "Activation Exercises": "Scapula Pull Ups",
  },
  {
    id: 32,
    "Activation Exercises": "Rotator Cuff - Prone External Rotation",
  },
  {
    id: 33,
    "Activation Exercises": "Drop Catch in Scapton",
  },
  {
    id: 34,
    "Activation Exercises": "Bend Over Overhead Drop Catch",
  },
  {
    id: 35,
    "Activation Exercises": "Swiss Ball Wall Pulses",
  },
  {
    id: 36,
    "Activation Exercises": "Glute Medius Activation A - Lateral Elbow Plank",
  },
  {
    id: 37,
    "Activation Exercises": "Glute Medius Activation B - Lateral Elbow Plank",
  },
  {
    id: 38,
    "Activation Exercises": "Scapular Push Ups",
  },
  {
    id: 39,
    "Activation Exercises":
      "Quadruped Hip Extension Gluteus Maximus Activation",
  },
  {
    id: 40,
    "Activation Exercises":
      "Quadruped Hip Extension with Mini Band Gluteus Max Activation",
  },
  {
    id: 41,
    "Activation Exercises": "Hip Extension Gluteus Maximus Activation Advanced",
  },
  {
    id: 42,
    "Activation Exercises": "Quadruped Hip Rotation Gluteus Medius Activation",
  },
  {
    id: 43,
    "Activation Exercises":
      "Quadruped Hip Rotation with Mini Band Gluteus Medius Activation",
  },
  {
    id: 44,
    "Activation Exercises": "Hip Rotation Gluteus Medius Activation Advanced",
  },
];
export const KB = [
  {
    id: 1,
    "Kettlebell Exercises": "KB Goblet Squat",
  },
  {
    id: 2,
    "Kettlebell Exercises": "KB Single Leg Squat",
  },
  {
    id: 3,
    "Kettlebell Exercises": "KB Single Leg Deadlift",
  },
  {
    id: 4,
    "Kettlebell Exercises": "KB Swing",
  },
  {
    id: 5,
    "Kettlebell Exercises": "KB Clean 2 KBs",
  },
  {
    id: 6,
    "Kettlebell Exercises": "KB Clean 1 KB",
  },
  {
    id: 7,
    "Kettlebell Exercises": "KB Squat 2 KBs",
  },
  {
    id: 8,
    "Kettlebell Exercises": "KB Squat 1KB",
  },
  {
    id: 9,
    "Kettlebell Exercises": "KB Deadlift",
  },
  {
    id: 10,
    "Kettlebell Exercises": "KB Single Arm Single Leg Row",
  },
  {
    id: 11,
    "Kettlebell Exercises": "KB Snatch",
  },
  {
    id: 12,
    "Kettlebell Exercises": "KB Upright Row",
  },
  {
    id: 13,
    "Kettlebell Exercises": "KB Push Press",
  },
  {
    id: 14,
    "Kettlebell Exercises": "Single Arm KB Push Press",
  },
  {
    id: 15,
    "Kettlebell Exercises": "Single Arm KB Shoulder Press",
  },
  {
    id: 16,
    "Kettlebell Exercises": "KB Military Press",
  },
  {
    id: 17,
    "Kettlebell Exercises": "KB Thruster",
  },
  {
    id: 18,
    "Kettlebell Exercises": "KB Thruster 1KB",
  },
  {
    id: 19,
    "Kettlebell Exercises": "KB Floor Chest Press",
  },
  {
    id: 20,
    "Kettlebell Exercises": "KB Floor Chest Press One Arm",
  },
  {
    id: 21,
    "Kettlebell Exercises": "KB Bottom Up Shoulder Press",
  },
  {
    id: 22,
    "Kettlebell Exercises": "KB Lateral Lunge",
  },
  {
    id: 23,
    "Kettlebell Exercises": "KB Sumo Deadlift Upright Row",
  },
  {
    id: 24,
    "Kettlebell Exercises": "KB Forward Lunge",
  },
  {
    id: 25,
    "Kettlebell Exercises": "KB Forward Lunge KB OH",
  },
  {
    id: 26,
    "Kettlebell Exercises": "KB Forward Lunge with SH Press",
  },
  {
    id: 27,
    "Kettlebell Exercises": "KB Split Squat",
  },
  {
    id: 28,
    "Kettlebell Exercises": "KB Split Squat with SH Press",
  },
  {
    id: 29,
    "Kettlebell Exercises": "KB Split Squat with KB OH",
  },
  {
    id: 30,
    "Kettlebell Exercises": "KB Snatch 2 KBs",
  },
  {
    id: 31,
    "Kettlebell Exercises": "KB Half Kneeling Windmill",
  },
  {
    id: 32,
    "Kettlebell Exercises": "KB Half Kneeling SH Press",
  },
  {
    id: 33,
    "Kettlebell Exercises": "KB Bend Over Row",
  },
  {
    id: 34,
    "Kettlebell Exercises": "KB Suitcase Squat",
  },
  {
    id: 35,
    "Kettlebell Exercises": "KB Turkish Get Up",
  },
  {
    id: 36,
    "Kettlebell Exercises": "KB Get Up",
  },
  {
    id: 37,
    "Kettlebell Exercises": "KB Bend Press",
  },
  {
    id: 38,
    "Kettlebell Exercises": "KB Windmill",
  },
  {
    id: 39,
    "Kettlebell Exercises": "KB Unilateral Farmer's Walk",
  },
  {
    id: 40,
    "Kettlebell Exercises": "KB Unilateral Waiters Walk",
  },
  {
    id: 41,
    "Kettlebell Exercises": "KB Bilateral Waiters Walk",
  },
  {
    id: 42,
    "Kettlebell Exercises": "KB Cross Walk",
  },
];
export const CET = [
  {
    id: 1,
    "Corrective Exercise Training (CET)": "Unsupported Leg Drops",
  },
  {
    id: 2,
    "Corrective Exercise Training (CET)": "Supported Leg Drops",
  },
  {
    id: 3,
    "Corrective Exercise Training (CET)": "Mt Climbers Prone",
  },
  {
    id: 4,
    "Corrective Exercise Training (CET)": "Mt Climbers Prone with Val Slide",
  },
  {
    id: 5,
    "Corrective Exercise Training (CET)": "Mt Climbers Supine with Band",
  },
  {
    id: 6,
    "Corrective Exercise Training (CET)": "Stanky Leg Base Stance",
  },
  {
    id: 7,
    "Corrective Exercise Training (CET)": "Stanky Leg Split Stance",
  },
  {
    id: 8,
    "Corrective Exercise Training (CET)": "Monster Walk Linear",
  },
  {
    id: 9,
    "Corrective Exercise Training (CET)": "Monster Walk Lateral",
  },
  {
    id: 10,
    "Corrective Exercise Training (CET)": "Posterior Pelvic Tilts",
  },
  {
    id: 11,
    "Corrective Exercise Training (CET)": "Cook Bridge",
  },
  {
    id: 12,
    "Corrective Exercise Training (CET)": "Rolling",
  },
  {
    id: 13,
    "Corrective Exercise Training (CET)": "Scapular Retraction",
  },
  {
    id: 14,
    "Corrective Exercise Training (CET)":
      "Scapular Retraction wit hip extension",
  },
  {
    id: 15,
    "Corrective Exercise Training (CET)": "Prone Shoulder Press",
  },
  {
    id: 16,
    "Corrective Exercise Training (CET)": "Phiffer Scissors",
  },
  {
    id: 17,
    "Corrective Exercise Training (CET)": "Quadruped Plank Position",
  },
  {
    id: 18,
    "Corrective Exercise Training (CET)": "Quadruped Push Up Position",
  },
  {
    id: 19,
    "Corrective Exercise Training (CET)": "Quadruped Donkey Kicks",
  },
  {
    id: 20,
    "Corrective Exercise Training (CET)": "Quadruped Hip Circles",
  },
  {
    id: 21,
    "Corrective Exercise Training (CET)": "Quadruped Same Arm-Same Leg",
  },
  {
    id: 22,
    "Corrective Exercise Training (CET)": "Quadruped Opp Arm-Leg",
  },
  {
    id: 23,
    "Corrective Exercise Training (CET)": "Quadruped Hip Ext with Knee Flexion",
  },
  {
    id: 24,
    "Corrective Exercise Training (CET)": "Quadriped Series on Foam Roll",
  },
  {
    id: 25,
    "Corrective Exercise Training (CET)": "Y, T, W's Prone on Sball",
  },
  {
    id: 26,
    "Corrective Exercise Training (CET)": "Y's and I's",
  },
  {
    id: 27,
    "Corrective Exercise Training (CET)": "Sball Wall Squat with ABduction",
  },
  {
    id: 28,
    "Corrective Exercise Training (CET)": "Sball Wall Squat with ADduction",
  },
  {
    id: 29,
    "Corrective Exercise Training (CET)": "Stability Pull Down",
  },
  {
    id: 30,
    "Corrective Exercise Training (CET)": "Stability Roll Out Kneeling",
  },
  {
    id: 31,
    "Corrective Exercise Training (CET)": "Stability Roll Out Push up position",
  },
  {
    id: 32,
    "Corrective Exercise Training (CET)": "Monster Squat",
  },
  {
    id: 33,
    "Corrective Exercise Training (CET)": "High Box Psoas Lift",
  },
  {
    id: 34,
    "Corrective Exercise Training (CET)": "In Line Lunge w Adduction",
  },
  {
    id: 35,
    "Corrective Exercise Training (CET)": "In Line Lunge w ABduction",
  },
  {
    id: 36,
    "Corrective Exercise Training (CET)": "In Line Lunge with W",
  },
  {
    id: 37,
    "Corrective Exercise Training (CET)": "W Squat",
  },
  {
    id: 38,
    "Corrective Exercise Training (CET)": "Half Angel Seated on wall",
  },
];
export const NMP = [
  {
    id: 1,
    "Neuromuscular Movement Preparation": "MD Skip Series",
  },
  {
    id: 2,
    "Neuromuscular Movement Preparation": "Side Sweep (high position)",
  },
  {
    id: 3,
    "Neuromuscular Movement Preparation": "Side Sweep (high to low)",
  },
  {
    id: 4,
    "Neuromuscular Movement Preparation": "Crossover Skip",
  },
  {
    id: 5,
    "Neuromuscular Movement Preparation": "Dropstep Skip",
  },
  {
    id: 6,
    "Neuromuscular Movement Preparation": "Twist Skip Fwd",
  },
  {
    id: 7,
    "Neuromuscular Movement Preparation": "Backpedal Skip",
  },
  {
    id: 8,
    "Neuromuscular Movement Preparation": "Dropstep Skip",
  },
  {
    id: 9,
    "Neuromuscular Movement Preparation": "Gallop Fwd/Bwd",
  },
  {
    id: 10,
    "Neuromuscular Movement Preparation": "PB Standing Trunk Twist Base Stance",
  },
  {
    id: 11,
    "Neuromuscular Movement Preparation": "PB Standing Trunk Twist Split Stance",
  },
  {
    id: 12,
    "Neuromuscular Movement Preparation": "PB Standing Trunk Twist Lunge Stance",
  },
  {
    id: 13,
    "Neuromuscular Movement Preparation": "PB OH Squat and Chop",
  },
  {
    id: 14,
    "Neuromuscular Movement Preparation": "RDL to OH Press - 2 Feet",
  },
  {
    id: 15,
    "Neuromuscular Movement Preparation": "RDL to OH Press - 1 Leg",
  },
  {
    id: 16,
    "Neuromuscular Movement Preparation": "Reach-and-touch - 1 Leg",
  },
  {
    id: 17,
    "Neuromuscular Movement Preparation": "Lateral Bridge",
  },
  {
    id: 18,
    "Neuromuscular Movement Preparation": "Lateral Bridge with PB",
  },
  {
    id: 19,
    "Neuromuscular Movement Preparation": "Hurdle Over-Unders (R/L)",
  },
  {
    id: 20,
    "Neuromuscular Movement Preparation": "Hurdle Over-Unders (R/L) with PB",
  },
  {
    id: 21,
    "Neuromuscular Movement Preparation": "Supine Hip IR/ER",
  },
  {
    id: 22,
    "Neuromuscular Movement Preparation": "Supine 1/2 Eagles",
  },
  {
    id: 23,
    "Neuromuscular Movement Preparation": "Supine Eagles",
  },
  {
    id: 24,
    "Neuromuscular Movement Preparation": "Sidelying Quadrant Rotations",
  },
  {
    id: 25,
    "Neuromuscular Movement Preparation": "Prone Scorpions",
  },
  {
    id: 26,
    "Neuromuscular Movement Preparation": "Prone Trail Leg",
  },
  {
    id: 27,
    "Neuromuscular Movement Preparation": "Quadruped Hip Circles",
  },
  {
    id: 28,
    "Neuromuscular Movement Preparation": "Quadruped Opp Arm/Leg Raise",
  },
  {
    id: 29,
    "Neuromuscular Movement Preparation": "Quadruped Same Arm/Leg Raise",
  },
  {
    id: 30,
    "Neuromuscular Movement Preparation": "Quadruped Dog-and-Hydrant",
  },
  {
    id: 31,
    "Neuromuscular Movement Preparation": "Sidelying Iso Ab",
  },
  {
    id: 32,
    "Neuromuscular Movement Preparation": "Sidelying Iso Ab w/ Hip Abduction",
  },
  {
    id: 33,
    "Neuromuscular Movement Preparation":
      "Sidelying Iso Ab w/ Hip Flexion-Extension",
  },
  {
    id: 34,
    "Neuromuscular Movement Preparation":
      "Sidelying Iso-Ab w/ Bottom Hip Flexion",
  },
  {
    id: 35,
    "Neuromuscular Movement Preparation": "Stank Leg (Hip ER)",
  },
  {
    id: 36,
    "Neuromuscular Movement Preparation": "Monster Walk Fwd/Bwd",
  },
  {
    id: 37,
    "Neuromuscular Movement Preparation": "Monster Walk Lateral",
  },
];
