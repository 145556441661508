import React, { useEffect, useState } from "react";
// import { Button } from "rsuite";
import { HttpService } from "../../services/http.service";
import {
  EVALUATION_RESULTS,
  ATHLETE_PROFILE_API_URL,
} from "../../services/API";
import { Typography } from "@mui/material";
// import { Container } from "@mui/material";
// import Box from "@mui/material/Box";
import { DataGrid,GridToolbarContainer,GridToolbarExport } from "@mui/x-data-grid";
import Label from "../../components/Label";

export default function Somatotype() {
  const [groupedResults, setGroupedResults] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${EVALUATION_RESULTS}?department=5&categories=73`, {}, false
        );
        const data = response.data.results;

        const processedData = await Promise.all(
          data.map(async (result, index) => {
            try {
              const athleteResponse = await HttpService.get(
                ATHLETE_PROFILE_API_URL + result.athlete + "/", {}, false
              );

              const athleteName = athleteResponse.data.name;

              // Check if athlete details are available and not "N/A"
              if (athleteName !== "N/A") {
                const values = {
                  [result.field_name]: [
                    result.boolean_value,
                    result.choice_value,
                    result.date_value,
                    result.float_value,
                    result.integer_value,
                    result.remarks,
                    result.string_value,
                  ].filter(Boolean),
                };

                return {
                  id: index,
                  athlete_id: athleteResponse.data.id,
                  athlete_name: athleteName,
                  height: athleteResponse.data.height,
                  weight: athleteResponse.data.weight,
                  date: result.created_at.slice(0, 10),
                  ...values,
                };
              }

              return null; // Exclude the result when athlete is "N/A"
            } catch (athleteError) {
              console.warn("Error fetching athlete details:", athleteError.message);
              // If athlete details cannot be fetched, return null to exclude the result
              return null;
            }
          })
        );

        // Filter out null results caused by failed athlete fetch or when athlete is "N/A"
        const filteredResults = processedData.filter(result => result !== null);

        setGroupedResults(filteredResults);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchData();
  }, []);


  const columns = [
    {
      field: "athlete_name",
      headerName: "Name",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "date",
      headerName: "Date",
      width: 100,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Inference",
      headerName: "Inference",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let TSF = parseFloat(params.row["Triceps"]);
        let SSF = parseFloat(params.row["Subscapular"]);
        let SsF = parseFloat(params.row["Supraspinale"]);
        let skinfold = TSF + SSF + SsF;
        let endo =
          -0.7182 +
          0.1451 * skinfold -
          0.00068 * Math.pow(skinfold, 2) +
          0.0000014 * Math.pow(skinfold, 3);

        let MSF = parseFloat(params.row["Medial calf"]);
        let HB = parseFloat(params.row["Biepicondylar humerus breadth"]);
        let FB = parseFloat(params.row["Biepycondylar femur breadth"]);
        let AG = parseFloat(params.row["Upper arm girth"]);
        let CG = parseFloat(params.row["Standing calf girth"]);
        let H = parseFloat(params.row["height"]);

        let CAG = AG - TSF;
        let CCG = CG - MSF;
        let meso =
          0.858 * HB + 0.601 * FB + 0.188 * CAG + 0.161 * CCG - 0.131 * H + 4.5;
        let W = parseFloat(params.row["weight"]);
        let PI = H / Math.pow(W, 1 / 3);
        let ecto = 0;

        if (PI > 40.74) {
          ecto = 0.732 * PI - 28.58;
        } else if (PI > 39.65 && PI <= 40.74) {
          ecto = 0.463 * PI - 17.615;
        } else if (PI <= 39.65) {
          ecto = 0.5;
        }
        let result = "";
        if (endo >= meso && endo >= ecto) {
          result += "Endomorphic ";
          if (meso >= ecto) {
            result += "Mesomorph";
          } else {
            result += "Ectomorph";
          }
        } else if (meso >= endo && meso >= ecto) {
          result += "Mesomorphic ";
          if (endo >= ecto) {
            result += "Endomorph";
          } else {
            result += "Ectomorph";
          }
        } else if (ecto >= endo && ecto >= meso) {
          result += "Ectomorphic ";
          if (endo >= meso) {
            result += "Endomorph";
          } else {
            result += "Mesomorph";
          }
        }
        return (
          <div className={`cellWithStatus ${result}`}>
            <Label color={"primary"}>{result}</Label>
          </div>
        );
      },
    },

    {
      field: "Somatotype",
      headerName: "Somatotype",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let TSF = parseFloat(params.row["Triceps"]);
        let SSF = parseFloat(params.row["Subscapular"]);
        let SsF = parseFloat(params.row["Supraspinale"]);
        let skinfold = TSF + SSF + SsF;
        let endo =
          -0.7182 +
          0.1451 * skinfold -
          0.00068 * Math.pow(skinfold, 2) +
          0.0000014 * Math.pow(skinfold, 3);

        let MSF = parseFloat(params.row["Medial calf"]);
        let HB = parseFloat(params.row["Biepicondylar humerus breadth"]);
        let FB = parseFloat(params.row["Biepycondylar femur breadth"]);
        let AG = parseFloat(params.row["Upper arm girth"]);
        let CG = parseFloat(params.row["Standing calf girth"]);
        let H = parseFloat(params.row["height"]);

        let CAG = AG - TSF;
        let CCG = CG - MSF;
        let meso =
          0.858 * HB + 0.601 * FB + 0.188 * CAG + 0.161 * CCG - 0.131 * H + 4.5;
        let W = parseFloat(params.row["weight"]);
        let PI = H / Math.pow(W, 1 / 3);
        let ecto = 0;

        if (PI > 40.74) {
          ecto = 0.732 * PI - 28.58;
        } else if (PI > 39.65 && PI <= 40.74) {
          ecto = 0.463 * PI - 17.615;
        } else if (PI <= 39.65) {
          ecto = 0.5;
        }
        return (
          <div
            className={`cellWithStatus ${endo.toString()} ${ecto.toString()} ${meso.toString()}`}
          >
            <Label color={"success"}>
              {[endo.toFixed(2), meso.toFixed(2), ecto.toFixed(2)].join(", ")}
            </Label>
          </div>
        );
      },
    },
    {
      field: "Endomorphy",
      headerName: "Endomorphy",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let TSF = parseFloat(params.row["Triceps"]);
        let SSF = parseFloat(params.row["Subscapular"]);
        let SsF = parseFloat(params.row["Supraspinale"]);
        let skinfold = TSF + SSF + SsF;
        let endo =
          -0.7182 +
          0.1451 * skinfold -
          0.00068 * Math.pow(skinfold, 2) +
          0.0000014 * Math.pow(skinfold, 3);

        return (
          <div className={`cellWithStatus ${endo}`}>
            <Label color={"success"}>{endo.toFixed(2)}</Label>
          </div>
        );
      },
    },

    {
      field: "Mesomorphy",
      headerName: "Mesomorphy",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let TSF = parseFloat(params.row["Triceps"]);
        let MSF = parseFloat(params.row["Medial calf"]);
        let HB = parseFloat(params.row["Biepicondylar humerus breadth"]);
        let FB = parseFloat(params.row["Biepycondylar femur breadth"]);
        let AG = parseFloat(params.row["Upper arm girth"]);
        let CG = parseFloat(params.row["Standing calf girth"]);
        let H = parseFloat(params.row["height"]);

        let CAG = AG - TSF;
        let CCG = CG - MSF;
        let meso =
          (0.858 * HB) + (0.601 * FB) + (0.188 * CAG) + (0.161 * CCG) - (0.131 * H) + 4.5;

        return (
          <div className={`cellWithStatus ${meso}`}>
            <Label color={"warning"}>{meso.toFixed(2)}</Label>
          </div>
        );
      },
    },
    {
      field: "Ectomorphy",
      headerName: "Ectomorphy",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let H = parseFloat(params.row["height"]);
        let W = parseFloat(params.row["weight"]);
        let PI = H / Math.pow(W, 1 / 3);
        let ecto = 0;

        if (PI > 40.74) {
          ecto = 0.732 * PI - 28.58;
        } else if (PI > 39.65 && PI <= 40.74) {
          ecto = 0.463 * PI - 17.615;
        } else if (PI <= 39.65) {
          ecto = 0.5;
        }

        return (
          <div className={`cellWithStatus ${ecto}`}>
            <Label color={"primary"}>{ecto.toFixed(2)}</Label>
          </div>
        );
      },
    },
    {
      field: "Triceps",
      headerName: "Triceps",
      sortable: true,
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "Subscapular",
      headerName: "Subscapular",
      sortable: true,
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Supraspinale",
      headerName: "Supraspinale",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Medial calf",
      headerName: "Medial calf",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Biepicondylar humerus breadth",
      headerName: "Humerus Breadth",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Biepycondylar femur breadth",
      headerName: "Femur breadth",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Upper arm girth",
      headerName: "Upper Arm Girth",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Standing calf girth",
      headerName: "Standing Calf Girth",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "height",
      headerName: "Height",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
  ];
  const groupedData = {};

  groupedResults.forEach((item) => {
    const { athlete_id, athlete_name, date, ...fields } = item;
    const key = `${athlete_id}-${date}`;
    if (!groupedData[key]) {
      groupedData[key] = {
        athlete_id,
        athlete_name,
        date,
        ...fields,
      };
    } else {
      Object.keys(fields).forEach((field) => {
        if (!groupedData[key][field]) {
          groupedData[key][field] = [];
        }
        if (Array.isArray(groupedData[key][field])) {
          groupedData[key][field].push(
            ...(Array.isArray(fields[field]) ? fields[field] : [fields[field]])
          );
        } else {
          groupedData[key][field] = [groupedData[key][field]];
          groupedData[key][field].push(
            ...(Array.isArray(fields[field]) ? fields[field] : [fields[field]])
          );
        }
      });
    }
  });

  const finalGroupedResults = Object.values(groupedData);
  // console.log(finalGroupedResults)
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div   style={{marginLeft:"30px",marginTop:"30px"}} >
     
      {groupedResults?.length === 0 ? (
        <Typography variant="h6" textAlign={"center"}>
          No Data
        </Typography>
      ) : (
       
          <div
            >
           
              <DataGrid
                rows={finalGroupedResults}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 50]}
                disableSelectionOnClick
                autoHeight
                getRowId={(row) => `${row.athlete_id}-${row.date}-${row.index}`}
                slots={{toolbar:CustomToolbar}}
              />
            
        </div>
      )}
    </div>
  );
}
