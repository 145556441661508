import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@mui/material";
import Lottie from "lottie-react";
import UserCustomDrawer from "../../components/CustomDrawer";
export default function CreateNotification({ onSubmitSuccess }) {
  const [createNotification, setCreateNotification] = React.useState(false);
  const [postNotification, setPostNotification] = React.useState(false);
  const [viewNotificationLogs, setViewNotificationLogs] = React.useState(false);
  const [typeModal, setTypeModal] = React.useState();

  const handleCreateNotification = () => {
    setTypeModal(1);
    setCreateNotification(true);
    
  };
  const handlePostNotification = () => {
    setTypeModal(2);
    setPostNotification(true);
    
  };
  const handleViewNotification = () => {
    setTypeModal(3);
    setViewNotificationLogs(true);
    
  };
  const data = [
    {
      name: "Create Notification",
      text: "This Tab enables user to Create Notification.",
      handler: handleCreateNotification ,
      animation: require("./../../assets/json/createnotification.json"),
      animationSize: 1, // Adjust the size here
    },
    {
      name: "Send Notification",
      text: "This Tab enables user to Send Notification.",
      handler: handlePostNotification,
      animation: require("./../../assets/json/sendnotification.json"),
      animationSize: 1, // Adjust the size here
    },
    {
      name: "Notication Recipients Logs",
      text: "This Tab enables user to View Notication Recipients Logs.",
      handler:handleViewNotification ,
      animation: require("./../../assets/json/notificationlogs.json"),
      animationSize: 1, // Adjust the size here
    },
    
  ];

  return (
    <>
      {data.map((item) => (
      <Card
        key={item.name} // Add a unique key
        sx={{
          width: "90%",
          // height: "40%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft:"1rem",
          alignContent:"center",
          cursor: "pointer",
          marginBottom: "2rem",
          marginTop: "1rem",
        }}
      >
        {" "}
        <CardActions  onClick={() => item.handler()}>
          <CardContent>
            <Typography
              variant="h4"
              fontSize={18}
              fontWeight={"bold"}
              textAlign={"center"}
              color={"#4dabf5"}
            >
              {item.name}
            </Typography>
            <br/>
            <Typography variant="span" textAlign={"center"}>
              {item.text}
            </Typography>
            <Lottie
              animationData={item.animation}
              loop={true}
              size={item.animationSize}
            />
          </CardContent>
        </CardActions>
      </Card>
    ))}
      <UserCustomDrawer
        open={
          typeModal === 1
            ? createNotification
            : typeModal === 2
            ? postNotification
            : typeModal === 3
            ? viewNotificationLogs
            : null
        }
        setOpen={
          typeModal === 1
            ? setCreateNotification
            : typeModal === 2
            ? setPostNotification
            : typeModal === 3
            ? setViewNotificationLogs
            : null
        }
        size={"md"}
        placement={"right"}
        mode={
          typeModal === 1
            ? "AddNotification"
            : typeModal === 2
            ? "PostNotification"
            : typeModal === 3
            ? "ViewLogs"
            : null
        }
      />
    </>
  );
}
