import { HttpService } from "../../services/http.service";
import {
  USER_API_URL,
  ACCOUNTS_API_URL,
  ADMIN_API_URL,
  ATHLETE_PROFILE_API_URL,
  TEAM_API_URL,
  SUBSCRIPTION_API_URLS,
  ATTENDANCE_SESSIONS_API_URLS,
  ATTENDANCE_RECORDS_API_URLS,
  NUTRITION_LOGS_API_URLS,
  TREATMENT_LOGS_API_URLS,
  NOTIFICATION_API_URLS,
  NOTIFICATION_RECIPENTS,
  VENUE_API_URLS,
  VENUE_SETUP_API_URLS,
  EVENT_API_URLS,
  EVENT_TYPE_API_URLS,
  EVENT_RESULT_API_URLS,
  GENERALISED_WORKOUT_LOGS_API_URLS,
  SC_WORKOUT_API_URLS,
  SLEEP_LOGS,
  RECORD_INJURY_API_URLS,
  WOKRKOUT_SESSION_LOGS_API_URLS,
  EVALUATION_FIELDS_DEPARTMENT_QUERY_URLS,
  EVALUATION_RESULTS,
} from "../../services/API";
import { useEffect } from "react";

function GetUserData({
  setUserData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${USER_API_URL}?page=${currentPage}`,{},true
        );
        setUserData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setUserData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetAccountData({
  setAccountData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${ACCOUNTS_API_URL}?page=${currentPage}`,{},true
        );
        setAccountData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setAccountData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetAdminData({
  setAdminData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${ADMIN_API_URL}?page=${currentPage}`,{}, false
        );
        setAdminData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setAdminData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetAthleteData({
  setAthleteData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`,{}, false
        );
        setAthleteData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setAthleteData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetTeamData({
  setTeamData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${TEAM_API_URL}?page=${currentPage}`,{}, false
        );
        const modifiedTeamData = response.data.results.map(team => ({
          id: team.id,
          name: team.name,
          date : team.created_at.slice(0,10)
        }));
        setTeamData(modifiedTeamData);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setTeamData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetSubscriptionData({
  setSubscriptionData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${SUBSCRIPTION_API_URLS}?page=${currentPage}`,{}, true
        );
        setSubscriptionData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setSubscriptionData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetViewSessionData({
  setViewSession,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${ATTENDANCE_SESSIONS_API_URLS}?page=${currentPage}`,{}, false
        );
        setViewSession(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setViewSession,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetViewAttendanceRecordData({
  setAttendanceRecord,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${ATTENDANCE_RECORDS_API_URLS}?page=${currentPage}`,{}, false
        );
        setAttendanceRecord(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setAttendanceRecord,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetNutritionLogsData({
  setNutritionLogs,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${NUTRITION_LOGS_API_URLS}?page=${currentPage}`,{}, false
        );
        setNutritionLogs(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setNutritionLogs,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetTreatmentLogsData({
  setTreatmentLogs,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${TREATMENT_LOGS_API_URLS}?page=${currentPage}`,{}, false
        );
        setTreatmentLogs(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setTreatmentLogs,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

////////

function GetNotification({
  setNotification,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${NOTIFICATION_API_URLS}?page=${currentPage}`,{}, false
        );
        setNotification(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setNotification,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetNotificationRecipents({
  setNotificationRecipents,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${NOTIFICATION_RECIPENTS}?page=${currentPage}`,{}, false
        );
        setNotificationRecipents(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setNotificationRecipents,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetVenueData({
  setVenueData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${VENUE_API_URLS}?page=${currentPage}`,{}, false
        );
        setVenueData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setVenueData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetVenueSetupData({
  setVenueSetupData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${VENUE_SETUP_API_URLS}?page=${currentPage}`,{}, false
        );
        setVenueSetupData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setVenueSetupData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetEventData({
  setEventData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${EVENT_API_URLS}?page=${currentPage}`,{}, false
        );
        setEventData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setEventData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetEventTypeData({
  setEventTypeData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${EVENT_TYPE_API_URLS}?page=${currentPage}`,{}, false
        );
        setEventTypeData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setEventTypeData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}
function GetEventResultData({
  setEventResultData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${EVENT_RESULT_API_URLS}?page=${currentPage}`,{}, false
        );
        setEventResultData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setEventResultData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

//////

function GetGeneralisedWorkoutData({
  setGenWorkoutData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${GENERALISED_WORKOUT_LOGS_API_URLS}?page=${currentPage}`,{}, false
        );
        setGenWorkoutData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setGenWorkoutData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetInjuryRecord({
  setInjuryRecord,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${RECORD_INJURY_API_URLS}?page=${currentPage}`,{}, false
        );
        setInjuryRecord(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setInjuryRecord,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetSession({
  setSession,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${WOKRKOUT_SESSION_LOGS_API_URLS}?page=${currentPage}`,{}, false
        );
        setSession(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setSession,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}
function GetSCSession({
  setSCSession,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${SC_WORKOUT_API_URLS}?page=${currentPage}`,{}, false
        );
        setSCSession(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setSCSession,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetSleepData({
  setSleepData,
  setCurrentPage,
  setTotalPages,
  setError,
  setOpenModal,
  currentPage,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${SLEEP_LOGS}?page=${currentPage}`,{}, false
        );
        setSleepData(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setSleepData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
  ]); // Fetch new data when currentPage changes
}

function GetEvaluationfield({
  dept,
  cat,
  currentPage,
  fields,
  setFields,
  setFormValue,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${`${EVALUATION_FIELDS_DEPARTMENT_QUERY_URLS}${dept}&categories=${cat}&page=${currentPage}`}`,{}, false
        );
        setFields(response.data.results);
      } catch (error) {
        console.error("Error fetching fields:", error);
      }
    };

    fetchData();
  }, [dept, cat, currentPage,setFields]);

  useEffect(() => {
    // Initialize formValue with default values based on fields data
    const initialFormValues = {};

    // Define default values for each field type
    const defaultValues = {
      string: "",
      choice: "",
      range: (field) => field.field_options[0],
      int: 0, // Default value for int type
      float: 0.0, // Default value for float type
    };

    fields.forEach((field) => {
      const defaultValue = defaultValues[field.field_type];

      if (defaultValue !== undefined) {
        initialFormValues[`field_${field.id}`] =
          typeof defaultValue === "function"
            ? defaultValue(field)
            : defaultValue;
      }
    });

    setFormValue(initialFormValues);
  }, [fields,setFormValue]);
}
const FetchResultsByField = async ({ dept, cat, field }) => {
  try {
    const response = await HttpService.get(
      `${EVALUATION_RESULTS}?department=${dept}&categories=${cat}&field=${field}`,{}, false
    );

    const modifiedResult = await Promise.all(
      response.data.results.map(async (result, index) => {
        try {
          // Fetch athlete data for each result

        
          const athleteResponse = await HttpService.get(
            ATHLETE_PROFILE_API_URL + result.athlete + "/",{}, false
          );

          const athleteName = athleteResponse.data.name;
          const athleteId = athleteResponse.data.id;
          const value = determineValue(result);

          // Only include the result if athlete details are available
          if (athleteName !== "N/A") {
            return {
              id: index,
              athlete: athleteName,
              athlete_id: athleteId,
              value,
              date: result.created_at.slice(0, 10),
              field_name: result.field_name,
              field_id: result.field,
            };
          }

          return null; // Exclude the result when athlete is "N/A"
        } catch (athleteError) {
          console.warn("Error fetching athlete details:", athleteError.message);
          // If athlete details cannot be fetched, return null to exclude the result
          return null;
        }
      })
    );

    // Filter out null results caused by failed athlete fetch or when athlete is "N/A"
    const filteredResults = modifiedResult.filter(result => result !== null);

    return filteredResults;
  } catch (error) {
    console.warn("Error in Fetching - Monitoring", error.message, error.stack);
    return [];
  }
};
const FetchResultsByFieldAthlete = async ({ dept, cat, field,athlete }) => {
  try {
    const response = await HttpService.get(
      `${EVALUATION_RESULTS}?department=${dept}&categories=${cat}&field=${field}`,{}, false
    );

    const modifiedResult = await Promise.all(
      response.data.results.map(async (result, index) => {
        try {
          // Fetch athlete data for each result

          let athlete_id = ""
          if (athlete === result.athlete){
            athlete_id = athlete
          }
          else if (athlete === "all"){
            athlete_id = result.athlete
          }
          const athleteResponse = await HttpService.get(
            ATHLETE_PROFILE_API_URL + athlete_id + "/",{}, false
          );

          const athleteName = athleteResponse.data.name;
          const athleteId = athleteResponse.data.id;
          const value = determineValue(result);

          // Only include the result if athlete details are available
          if (athleteName !== "N/A") {
            return {
              id: index,
              athlete: athleteName,
              athlete_id: athleteId,
              value,
              date: result.created_at.slice(0, 10),
              field_name: result.field_name,
              field_id: result.field,
            };
          }

          return null; // Exclude the result when athlete is "N/A"
        } catch (athleteError) {
          console.warn("Error fetching athlete details:", athleteError.message);
          // If athlete details cannot be fetched, return null to exclude the result
          return null;
        }
      })
    );

    // Filter out null results caused by failed athlete fetch or when athlete is "N/A"
    const filteredResults = modifiedResult.filter(result => result !== null);

    return filteredResults;
  } catch (error) {
    console.warn("Error in Fetching - Monitoring", error.message, error.stack);
    return [];
  }
};
const FetchResultsByCatAthlete = async ({ dept, cat,athlete }) => {
  try {
    const response = await HttpService.get(
      `${EVALUATION_RESULTS}?department=${dept}&categories=${cat}`,{}, false
    );

    const modifiedResult = await Promise.all(
      response.data.results.map(async (result, index) => {
        try {
          // Fetch athlete data for each result

          let athlete_id = ""
          if (athlete === result.athlete){
            athlete_id = athlete
          }
          else if (athlete === "all"){
            athlete_id = result.athlete
          }
          const athleteResponse = await HttpService.get(
            ATHLETE_PROFILE_API_URL + athlete_id + "/",{}, false
          );

          const athleteName = athleteResponse.data.name;
          const athleteId = athleteResponse.data.id;
          const value = determineValue(result);

          // Only include the result if athlete details are available
          if (athleteName !== "N/A") {
            return {
              id: index,
              athlete: athleteName,
              athlete_id: athleteId,
              value,
              date: result.created_at.slice(0, 10),
              field_name: result.field_name,
              field_id: result.field,
            };
          }

          return null; // Exclude the result when athlete is "N/A"
        } catch (athleteError) {
          console.warn("Error fetching athlete details:", athleteError.message);
          // If athlete details cannot be fetched, return null to exclude the result
          return null;
        }
      })
    );

    // Filter out null results caused by failed athlete fetch or when athlete is "N/A"
    const filteredResults = modifiedResult.filter(result => result !== null);

    return filteredResults;
  } catch (error) {
    console.warn("Error in Fetching - Monitoring", error.message, error.stack);
    return [];
  }
};

const determineValue = (result) => {
  return (
    result.boolean_value !== false
      ? result.boolean_value
      : result.choice_value !== null
      ? result.choice_value
      : result.date_value !== null
      ? result.date_value
      : result.float_value !== null
      ? result.float_value
      : result.integer_value !== null
      ? result.integer_value
      : result.remarks !== null
      ? result.remarks
      : result.string_value !== null
      ? result.string_value
      : null
  );
};


export {
  GetUserData,
  GetAccountData,
  GetAdminData,
  GetAthleteData,
  GetTeamData,
  GetSubscriptionData,
  GetViewAttendanceRecordData,
  GetViewSessionData,
  GetTreatmentLogsData,
  GetNutritionLogsData,
  GetNotification,
  GetNotificationRecipents,
  GetEventData,
  GetEventTypeData,
  GetVenueData,
  GetVenueSetupData,
  GetEventResultData,
  GetGeneralisedWorkoutData,
  GetInjuryRecord,
  GetSession,
  GetSCSession,
  GetSleepData,
  GetEvaluationfield,
  FetchResultsByField,
  FetchResultsByFieldAthlete,
  FetchResultsByCatAthlete
};
