import React, { useState } from "react";
import styles from "./../../pages/sportsscience/science.module.css";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import Sidebar from "../../components/CustomSideNav";
import StoryTelling from "./StoryTelling";
import Univariate from "./Univariate";
import Categorical from "./Categorical";
import Bivariate from "./Bivariate";
import Multivariate from "./Multivariate";
import PainInsights from "./PainInsights";
import Somatotype from "./Somatotype";
import LoadMonitoringInsights from "./LoadMonitoringInshights";
import FMSInsights from "./FMSInsights";
import WellnessInsights from "./WellnessInsights";
export default function Story() {
  const [selectedItem, setSelectedItem] = useState(1);
  const [filterAnalytic, setFilterAnalytics] = useState(1);
  //Textual Analytics

  const filtersdata = [
    {
      title: "Textual Analytics",
      // icon: <AbcIcon />,
      filterAnalytic: 1,
    },
    {
      title: "Univariate Analytics",
      // icon: <NumbersIcon />,
      filterAnalytic: 2,
    },
    {
      filterAnalytic: 3,
      title: "Categorical Analysis",
    },
    {
      filterAnalytic: 4,
      title: " Bivariate Analysis",
    },
    {
      filterAnalytic: 5,
      title: "Multivariate Analysis",
    },
    {
      title: "Pain Insights",

      filterAnalytic: 6,
    },
    {
      title: "Functional Movement Screening Insights",

      filterAnalytic: 9,
    },
    {
      title: "Somatotype Insights",

      filterAnalytic: 7,
    },
    {
      title: "Training Load Monitoring Insights",

      filterAnalytic: 8,
    },
    {
      title: "Wellness Insights",

      filterAnalytic: 10,
    },
  ];

  const handleLabelClick = (labelFilter) => {
    setFilterAnalytics(labelFilter);
    setSelectedItem(labelFilter);
  };

  return (
    <div>
      <DashBoardTopNav header={"Plotting"} />
      <Sidebar />
      <div className={styles.box_science_sports}>
        {filtersdata.map(({ title, icon, filterAnalytic }) => (
          <label
            style={{ justifyContent: "space-between" }}
            className={`${styles.labelItem_sports} ${
              selectedItem === filterAnalytic ? styles.selected : ""
            }`}
            key={filterAnalytic}
            onClick={() => handleLabelClick(filterAnalytic)}
          >
            {title}
            {icon}
          </label>
        ))}
      </div>

      <div className={styles.box_science_sports6}>
        {filterAnalytic === 1 ? (
          <StoryTelling />
        ) : filterAnalytic === 2 ? (
          <Univariate />
        ) : filterAnalytic === 3 ? (
          <Categorical />
        ) : filterAnalytic === 4 ? (
          <Bivariate />
        ) : filterAnalytic === 5 ? (
          <Multivariate />
        ) : filterAnalytic === 6 ? (
          <PainInsights />
        ) : filterAnalytic === 7 ? (
          <Somatotype />
        ) : filterAnalytic === 8 ? (
          <LoadMonitoringInsights />
        ) : filterAnalytic === 9 ? (
          <FMSInsights />
        ) : filterAnalytic === 10 ? (
          <WellnessInsights />
        ) : null}
      </div>
    </div>
  );
}
