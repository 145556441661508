import React, { useState } from "react";
import styles from "./../../pages/sportsscience/science.module.css";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import Sidebar from "../../components/CustomSideNav";
// import PlusIcon from "@rsuite/icons/Plus";
import ViewVenue from "./venue/ViewVenue";
import ViewVenueSetup from "./venue/ViewVenueSetup";
import ViewEvent from "./eventtype/ViewEvent";
import ViewEventType from "./eventtype/ViewEventType";
import ViewEventResult from "./eventtype/ViewEventResult";
import StadiumIcon from '@mui/icons-material/Stadium';
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CelebrationIcon from '@mui/icons-material/Celebration';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { Button } from "rsuite";
import UserCustomDrawer from "../../components/CustomDrawer";
export default function CreateEvent() {
  const [selectedItem, setSelectedItem] = useState(1);
  const [filter, setFilter] = useState(1);
  // const [showForm, setShowForm] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [title, setDrawerTitle] = useState([]);

  const [prevClicked, setPrevClicked] = useState(null);

  const filtersdata = [
    {
      title: "Venue",
      icon: <StadiumIcon />,
      filter: 1,

    },
    {
      title: "Venue Setup",
      icon: <WifiProtectedSetupIcon />,
      filter: 2,

    },
    {
      title: "Event",
      icon: <EmojiEventsIcon />,
      filter: 3

    },
    {
      title: "Event Type",
      icon: <LocalActivityIcon />,
      filter: 4

    },
    {
      title: "Event Result",
      icon: <CelebrationIcon />,
      filter: 5

    },
  ];

 

  const handleLabelClick = (labelFilter, event) => {
    event.stopPropagation();
    setFilter(labelFilter);
    setSelectedItem(labelFilter);
    // setShowForm(false);
    if (prevClicked !== null && prevClicked !== labelFilter) {
      setPrevClicked(null);
    }
  };
  const handleOpen = ( title) => {
    
    setOpen(true);
    setDrawerTitle(title);
  };
  return (
    <>
      <DashBoardTopNav header={"Event"} />
      <Sidebar />
      <div className={styles.belownav}>
        {filter === 1 ?
          <Button
            appearance="primary"
            onClick={() => handleOpen("Add Venue")}
            className={styles.btn}
            size="small"
            style={{ marginLeft: "22px", marginRight: "20px", marginTop: "6px" ,background:"cadetblue"}}
          >
            Create Venue
          </Button>
          : filter === 2 ?

            <Button
              appearance="primary"
              onClick={() => handleOpen("Setup Venue")}
              className={styles.btn}
              size="small"
              style={{ marginLeft: "22px", marginRight: "20px", marginTop: "6px" ,background:"cadetblue"}}
            >
              Create Venue Setup
            </Button>



            : filter === 3 ? <Button
              appearance="primary"
              onClick={() => handleOpen("Add Event")}
              className={styles.btn}
              size="small"
              style={{ marginLeft: "22px", marginRight: "20px", marginTop: "6px",background:"cadetblue" }}
            >
              Create Event
            </Button> : filter === 4 ?

              <Button
                appearance="primary"
                onClick={() => handleOpen("Add Event Type")}
                className={styles.btn}
                size="small"
                style={{ marginLeft: "22px", marginRight: "20px", marginTop: "6px" ,background:"cadetblue"}}
              >
                Create Event Type
              </Button>



              : filter === 5 ? <Button
                appearance="primary"
                onClick={() => handleOpen("Add Event Result")}
                className={styles.btn}
                size="small"
                style={{ marginLeft: "22px", marginRight: "20px", marginTop: "6px",background:"cadetblue" }}
              >
                Create Event Result
              </Button> : null}
      </div>

      <div className={styles.box_science_sports}>
        {filtersdata.map(({ title, icon, filter }) => (
          <label
            style={{ justifyContent: "space-between" }}
            className={`${styles.labelItem_sports} ${selectedItem === filter ? styles.selected : ""
              }`}
            key={filter}
            onClick={(event) => handleLabelClick(filter, event)}
          >
          {title}  {icon}
            
           
          </label>
        ))}
      </div>
      <div className={styles.box_science_sports6}>


        
           {filter === 1 ? (
            <ViewVenue  />
          ) : filter === 2 ? (
            <ViewVenueSetup />
          ) : filter === 3 ? (
            <ViewEvent />
          ) : filter === 4 ? (
            <ViewEventType />
          ) : filter === 5 ? (
            <ViewEventResult />
          ) : null
        }
       
      </div>
      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={"right"}
        mode={title}
       
      />
    </>
  );
}












