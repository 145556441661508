import React, { useState, useEffect } from "react";
import { FetchResultsByFieldAthlete } from "../../components/custmomhooks/CustomHooks";
import CustomBarChart from "./BarChart";
import styles from "./injury.module.css";
import {  SelectPicker } from "rsuite";
import { ATHLETE_PROFILE_API_URL } from "../../services/API";
import { HttpService } from "../../services/http.service";
import { Typography } from "@mui/material";
import NODATA from "./../../assets/image/noChart.svg";
import NOTSELECTED from "./../../assets/image/notSelected.jpg";

export default function PsychologyProfiling() {
  const [athlete, setAthlete] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataResults, setDataResults] = useState({
    intrinsicMotivation: { data: [], title: "Intrinsic Motivation" },
    extrinsicMotivation: { data: [], title: "Extrinsic Motivation" },
    cognitiveStateAnxiety: { data: [], title: "Cognitive State Anxiety" },
    somaticStateAnxiety: { data: [], title: "Somatic State Anxiety" },
    selfConfidence: { data: [], title: "Self  Confidence" },
    concentration: { data: [], title: "Concentration" },
    negativeEnergy: { data: [], title: "Negative Energy" },
    attentionControl: { data: [], title: "Attention Control" },
    visualImaginary: { data: [], title: "Visual Imaginary" },
    postiveEnergy: { data: [], title: "Positive Energy" },
    attitudeControl: { data: [], title: "Attitude Control" },
   
  });
 

  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true)
       
  
        const fetchPromises = [
   FetchResultsByFieldAthlete({
        dept: 7,
        cat: 77,
        field: 413,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 7,
        cat: 77,
        field: 414,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 7,
        cat: 78,
        field: 415,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 7,
        cat: 78,
        field: 416,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 7,
        cat: 79,
        field: 417,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 7,
        cat: 79,
        field: 418,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 7,
        cat: 79,
        field: 419,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 7,
        cat: 79,
        field: 420,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 7,
        cat: 79,
        field: 421,
        athlete,
      }),
    FetchResultsByFieldAthlete({
        dept: 7,
        cat: 79,
        field: 422,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 7,
        cat: 79,
        field: 423,
        athlete,
      })]
     
      const results = await Promise.all(fetchPromises);

      setDataResults((prevData) => ({
        ...prevData,
        intrinsicMotivation: { data: results[0], title: "Intrinsic Motivation" },
        extrinsicMotivation: { data: results[1], title: "Extrinsic Motivation" },
        cognitiveStateAnxiety: { data: results[2], title: "Cognitive State Anxiety" },
        somaticStateAnxiety: { data: results[3], title: "Somatic State Anxiety" },
        selfConfidence: { data: results[4], title: "Self  Confidence" },
        concentration: { data: results[5], title: "Concentration" },
        negativeEnergy: { data: results[6], title: "Negative Energy" },
        attentionControl: { data: results[7], title: "Attention Control" },
        visualImaginary: { data: results[8], title: "Visual Imaginary" },
        postiveEnergy: { data: results[9], title: "Positive Energy" },
        attitudeControl: { data: results[10], title: "Attitude Control" },
       
      }));
  
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  
  if (athlete) {
    fetchResults();
  }
  }, [athlete]);

  useEffect(() => {
    const fetchAthlete = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`,{}, false
        );
        const modifiedAthlete = response.data.results.map((ath) => ({
          value: ath.id,
          label: ath.name,
        }));
        setAthletes(modifiedAthlete);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthlete();
  }, [currentPage]);




  return (
    <div>
    <div className={styles.belownav}>
      <SelectPicker
        label={ "Select Athlete" }
        data={athletes}
        onSelect={(value) => setAthlete(value)}
        className={styles.selectpicker}
        onClean={() => {
          setAthlete([]);
        }}
      />
    </div>
    {loading === true ? (
      <div
        style={{
          marginTop: "100px",
          marginLeft:"240px",
          transition: "margin-left 0.3s",
        }}
      >
        <Typography variant="h4" color="darkviolet" textAlign="center">
          {athlete===null ? "Select Athlete" : "Loading...."}
        </Typography>
      </div>
    ) : (
      <div className={styles.box_science_sports7}>
        <div className={styles.chartcontent}>
          <div className={styles.chartwrapper}>
            {Object.entries(dataResults).map(([key, data]) => (
              <div className={styles.chartitem} key={key}>
                <div className={styles.chartbox}>
                  {data?.data?.length <= 0 ? (
                    <div
                      style={{
                        width: "250px",
                        height: "250px",
                        margin: "0 auto",
                      }}
                    >
                     
                      {athlete!==null ? (
                        <>
                          <img src={NODATA} alt="No data" />
                          <h6 style={{ textAlign: "center" }}>
                            No Records Available:{data?.title}
                          </h6>
                        </>
                      ) : (
                        <>
                          <h6> Athlete Not Selected</h6>
                          <img
                            src={NOTSELECTED}
                            style={{ width: "200px", height: "200px" }}
                            alt="No data"
                          />
                        </>
                      )}
                    </div>
                  ) : (
                    <div>
                     
                        
                        <CustomBarChart
                        data={data?.data}
                        title={data?.title}
                      />
                     
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
         
        </div>
      
      </div>
    )}
  </div>
);
}