import React, { useState, useContext,useEffect } from "react";
import { HttpService } from "../../../services/http.service";
import { BASE_URL, ATHLETE_PROFILE_API_URL } from "../../../services/API";
import { Button, CheckPicker } from "rsuite"; 
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../../store/AuthContext";
import { Color } from "../../../utils/Colors";
// import PDFGenerator from "./pdf_logic/PDFTemplate";
// import NewPDF from "./pdf_logic/NewPDFTemplate";

export default function Report({ athlete, departments, res ,result,physio,medicine,strength}) {
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const decode = token ? jwt_decode(token) : "";
  const [athleteData,setAthleteData] = useState([])
  useEffect(() => {
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(ATHLETE_PROFILE_API_URL+athlete+"/", {}, false);
       
        setAthleteData(response.data);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [athlete]);
    const fetchPdf = async () => {
      try {
        if (athlete && selectedDepartments.length > 0) {
          const departmentsString = selectedDepartments.join(",");
          const response = await HttpService.get(
            `${ATHLETE_PROFILE_API_URL}${athlete}/assessment_reports/?departments=${departmentsString}`,
            {},
            false
          );
          setIsDownloading(true);
          setPdfUrl(response.data.url);
          setTimeout(() => {
            setIsDownloading(false);
          }, 20000);
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);

      }
    };

   
  const downloadPdf = () => {
   
     // Assuming the URL is known or fetched from somewhere
    if (pdfUrl) {
        const baseURL = BASE_URL(decode.account);
        const mediaURL = `${baseURL}/media/`;
        const fullURL = `${mediaURL}${pdfUrl}`;
        
        // Fetching PDF with authentication headers
        fetch(fullURL, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.ok) {
                return response.blob();
            }
            throw new Error('Network response was not ok.');
        })
        .then(blob => {
            // Create a download link for the blob and trigger a click
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${athleteData?.name}_sportsscience_report.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
       // Reset checker after successful download
       setIsDownloading(false);
       setSelectedDepartments([]);
     })
     .catch((error) => {
       console.error("Error downloading PDF:", error);
       setIsDownloading(false);
     });
    }
}

// console.log(result,physio)

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", }}>
        {athlete && (
          <CheckPicker
         
            label="Select Departments"
            disabledItemValues={departments?.filter((dept) => !res[dept.value]).map((dept) => dept.value)}
            data={departments}
            onClose={fetchPdf}
            value={selectedDepartments}
            onChange={(value) => setSelectedDepartments(value)}
            style={{ width: 300, marginRight: "3rem" }}
          />
        )}

{selectedDepartments?.length<=0 ? null :
            <Button disabled={isDownloading} appearance="primary" onClick={downloadPdf} style={{ marginRight: "3rem", background: Color.Color_C, color: Color.Color_A }}>
              {  isDownloading ? "Downloading..." :  "Click to Download"}
            </Button>}
           
       
          {/* <NewPDF athlete={athleteData} result={result} physio={physio} medicine={medicine} strength={strength}/> */}
       
      </div>
    </>
  );
}
