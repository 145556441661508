import React from "react";
import styles from "./landing.module.css";
import Lottie from "lottie-react";
export default function ContactUs() {

  const contact = [
    {
      name: "support@thesportsanalytics.com",
      animation: require("./../../assets/json/email.json"),
      animationSize: 1, // Adjust the size here
    },
    // {
    //   name: "+91-9681xxxxxx",
    //   animation: require("./../../assets/json/call.json"),
    //   animationSize: 1, // Adjust the size here
    // },
    
  ];
  return (
    <div>
      <div className={styles.contactUstitleContainer}>
        <h2>Contact Us</h2>

      </div>

      <div className={styles.leftSideContent}>

        <div className={styles.lottie_contact} >
          <Lottie
            animationData={require("../../assets/json/contact.json")}
            loop={true} />
        </div>
       <div className={styles.card_contact}>
          {contact.map((item) => (
            <div key={item.name} className={styles.selector_contact}>
              <div>
                <label className={styles.title_contact}>{item.name}</label>

                <Lottie
                  animationData={item.animation}
                  loop={true}
                  size={item.animationSize}
                />
              </div>
            </div>
          ))}</div>
      </div>
    </div>
  );
}
