import * as React from 'react';
import TrainingObjectives from './TrainingObjectives';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PeriodisationPlan from './PeriodisationPlan';


export default function CreatePeriodisation() {
    const [submittedTrainingData,setSubmittedTrainingData]=React.useState([])

    const [value, setValue] = React.useState('one');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <div style={{ width: '80%', marginLeft: "90px" }}>
            <Box sx={{ width: '100%' , position:"fixed",top:60}}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="one" label="Training Objectives" />
        <Tab value="two" label="Periodisation Plan" />
        <Tab value="three" label="Training factors" />
      </Tabs>
    </Box>
           
            <div   >
                {value === "one"?<TrainingObjectives setSubmittedTrainingData={setSubmittedTrainingData} />: value === "two"?<PeriodisationPlan/>:null}

            </div>
          
        </div>
    );
}