import React, { useState } from "react";
import styles from "./../../science.module.css";
import Dynamic from "./Dynamic";
import Isometric from "./Isometric";
import EvaluationFormView from "../../../../components/EvaluationFormView";
export default function Kinetics({ mode }) {
  const [open, setOpen] = useState(false);
  // const [placement, setPlacement] = useState();
  const [drawerTitle, setDrawerTitle] = useState();
  const [dept, setDept] = useState();
  const [cat, setCat] = useState();


  const data = [
    { name: "Dynamic", cardColor: "#c5cae9" },
    { name: "Isometric", cardColor: "#c5cae9" },
    { dept: 8, name: "Isokinetic", cat: 93, cardColor: "#c5cae9" },
  ];

  const handleOpen = ({ name, key, dept, cat }) => {
    setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
    setDept(dept);
    setCat(cat);
  };

  return (
    <>
     
    <div className={styles.sports_main_inner}>
        <select
          className={styles.dropdown}
          onChange={(e) => {
            const selectedValue = e.target.value;
            const selectedItem = data.find(
              (item) => item.name === selectedValue
            );

            if (selectedItem) {
              handleOpen({
                name: selectedItem.name,
                dept: selectedItem.dept,
                cat: selectedItem.cat,
              });
            }
          }}
        >
          <option value="">{mode}</option>
          {data.map((item) => (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
   

    {drawerTitle === "Dynamic" ? (
      <Dynamic mode={drawerTitle} />
    ) : drawerTitle === "Isometric" ? (
      <Isometric mode={drawerTitle}/>
    ):(
      <EvaluationFormView
        open={open}
        setOpen={setOpen}
        size={"lg"}
        placement={"left"}
        mode={drawerTitle}
        dept={dept}
        cat={cat}
      />
    )}
  </>
  );
}
