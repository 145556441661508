import React, { useState,useEffect } from "react";
import styles from "./science.module.css";
import PlaceHolder from "./PlaceHolder";
import { EVALUATION_CATEGORIES, } from "../../services/API";
// import EvaluationFormView from "../../components/EvaluationFormView";
import { HttpService } from "../../services/http.service";
import EvaluationForm from "./EvaluationForm";

export default function MyCustomBattery({ label }) {

  // console.log(props)
  const [open, setOpen] = useState(false);
  // const [placement, setPlacement] = useState();
  const [result,setResult]=useState([])
  const [drawerTitle, setDrawerTitle] = useState();
 const dept =15
  const [cat, setCat] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
         EVALUATION_CATEGORIES,{}, false
        );
        const filteredObjects = response.data.results
        .filter(obj => obj.id > 132)
        .map(obj => {
          return {
            cat: obj.id,
            name: obj.name,
            dept: obj.department
          };
        });
      
      setResult(filteredObjects);
      } catch (error) {
        console.error("Error fetching fields:", error);
      }
    };

    fetchData();
  },[dept]);


  

  const handleOpen = ({ name, key, dept, cat }) => {
    // props.onSubmitSuccess()
    setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
  
    setCat(cat);
  };

  return (
    <div className={styles.sport_main} >
  
      <div className={styles.sports_main_inner}>
          <select
            className={styles.dropdown}
            onChange={(e) => {
              const selectedValue = e.target.value;
              const selectedItem = result.find(
                (item) => item.name === selectedValue
              );

              if (selectedItem) {
                handleOpen({
                  name: selectedItem.name,
                  dept: selectedItem.dept,
                  cat: selectedItem.cat,
                });
              }
            }}
          >
            <option value=""> {label}</option>
            {result.map((item) => (
              <option key={item.cat} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
     
      {drawerTitle === undefined ? (
        <PlaceHolder />
      ) : (
        <div className={styles.box_science_sports6}>
      
        
      <EvaluationForm
         
          dept={dept}
          cat={cat}
        />

       
    
    </div>
      )}
    </div>
  );
}
