import React, { useEffect, useContext, useState } from "react";
import "../../components/landing/styles.css";
import {
  Form,
  Button,
  SelectPicker,
  FlexboxGrid,
  // Placeholder,
  Modal,
  Schema
} from "rsuite";
import { HttpService } from "../../services/http.service";
import {
  ATHLETE_PROFILE_API_URL,
  USER_API_URL,
  NUTRITION_LOGS_API_URLS,
} from "../../services/API";

import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import CustomNotification from "../../components/CustomNotification";
import { Color } from "../../utils/Colors";
const {  StringType } = Schema.Types;
const model = Schema.Model({
 
  calories_consumed: StringType().isRequired("This field is required."),
  
  carbohydrates: StringType().isRequired("This field is required."),
  protein: StringType().isRequired("This field is required."),
  fat: StringType().isRequired("This field is required."),
 
});
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

// Component to display each food item in the cart
const FoodCartItem = ({ foodItem }) => {
  return (
    <div>
      <p>Name: {foodItem.name}</p>
      <p>Serving Size: {foodItem.serving_size}</p>
      <p>Is Vegetarian: {foodItem.is_vegetarian ? "Yes" : "No"}</p>
      <hr />
    </div>
  );
};

export default function AddNutritionLogs({ onSubmitSuccess, selectedDate,setCount }) {
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const [errorCount, setErrorCount] = React.useState(0);
  const [users, setUser] = React.useState();
  const [addedFoodItems, setAddedFoodItems] = useState([]);
  const [athletes, setAthletes] = React.useState();
  const [athlete, setAthlete] = React.useState();
  const [mealType, setMealType] = React.useState();
  const [isVeg, setIsVeg] = React.useState();
  const [foodItemOpen, setFoodItemOpen] = React.useState(false);
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [formValue, setFormValue] = React.useState({
    calories_consumed: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    name: "",
    serving_size: "",
  });
  const data = ["Breakfast", "Lunch", "Snack", "Dinner"].map((item) => ({
    label: item,
    value: item,
  }));
  const is_vegetarian = ["Yes", "No"].map((item) => ({
    label: item,
    value: item,
  }));
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const inputDate = new Date(selectedDate);

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await HttpService.get(
          `${USER_API_URL}?page=${currentPage}`,
          {},
          true
        );

        const modifiedUsers = response.data.results.map((user) => ({
          value: user.id,
          label: user.email,
        }));

        setUser(modifiedUsers);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchUser();
  }, [currentPage]);

  useEffect(() => {
    const fetchAthlete = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`,
          {},
          false
        );

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthlete();
  }, [currentPage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formRef.current.check()) {
      console.error("Form Error", formError);
      for (const key in formError) {
      setError(`${key} is required`);
      setErrorCount(prevCount => prevCount + 1);
      setOpenModal(true);}
      return;
    }
    for (const key in formValue) {
      if (formValue.hasOwnProperty(key) && !formValue[key]) {
        setError(`${key} is required`);
        setErrorCount(prevCount => prevCount + 1);
        setOpenModal(true);
        return;
      }
    }

    // Ensure other necessary values are filled
    if ( !mealType ) {
      setError("Please fill all required fields");
      setErrorCount(prevCount => prevCount + 1);
      setOpenModal(true);
      return;
    }
    try {
      const formData = {
        ...formValue,
        date: formattedDate,
        meal_type: mealType.toLowerCase(),
        athlete,
        recorded_by: decode.user_id,
        food_items: addedFoodItems, // Include added food items in the form data
      };
      
      const response = await HttpService.post(
        NUTRITION_LOGS_API_URLS,
        {},
        formData,
        false
      );

      setResponse(response);

      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1);
        
        setError("Form Submitted"); // Update error state to indicate success
        setOpenModal(true);
        setFormValue({ name: "" ,calories_consumed:"",carbohydrates:"",serving_size:"",protein:"",fat:""});
        onSubmitSuccess()
        return; // Exit early to prevent further execution
      }
    }  catch (e) {

      let errorMessage = "An error occurred"; // Default error message

      if (e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setError(errorMessage);

      if (e.response && e.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  const handleSubmitModal = async () => {
    try {
      const foodItemData = {
        name: formValue.name,
        serving_size: formValue.serving_size,
        is_vegetarian: isVeg.toLowerCase() === "yes" ? true : false,
      };

      setAddedFoodItems([...addedFoodItems, foodItemData]);

      setFormValue({
        ...formValue,
      
      });
      setIsVeg("");
    } catch (error) {
      console.error("Error submitting food item:", error);
    }
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
      
          <Form ref={formRef} onCheck={setFormError} onChange={setFormValue} formValue={formValue}  model={model}>
            <SelectPicker
              label="Select Meal Type"
              data={data}
              onSelect={(value) => setMealType(value)}
              style={{ width: 300 }}
            />
            <br />
            <br />
            <SelectPicker
              label="Select Athlete"
              data={athletes}
              onSelect={(value) => setAthlete(value)}
              style={{ width: 300 }}
            />
            <br />
            <br />
            <TextField name="calories_consumed" label="Calories Consumed" />
            <TextField name="carbohydrates" label="Carbohdrates" />
            <TextField name="protein" label="Protein" />
            <TextField name="fat" label="Fat" />
            <Button
              onClick={() => setFoodItemOpen(!foodItemOpen)}
              appearance="primary"
              size="xs"
              style={{ background: Color.Color_C, color: Color.Color_A, width: 300 }}
            >
              Add Food Items
            </Button>
            <br /> <br />
            <Button
              appearance="primary"
              onClick={handleSubmit}
              style={{ background: Color.Color_C, color: Color.Color_A, width: 300 }}
            >
              Submit
            </Button>

            <CustomNotification
              open={openModal}
              errorCount={errorCount} 
              setOpen={setOpenModal}
             
              message={
                error ? error : (res && res.data && res.data.message) || ""
              }
              title={error === "Form Submitted" ? "Success" : "Error"}
            />

            <Modal open={foodItemOpen} onClose={() => setFoodItemOpen(!foodItemOpen)}>
              <Modal.Header>Add Food Items</Modal.Header>
              <br />
              <TextField name="name" label="Food Name" />
              <br />
              <TextField name="serving_size" label="Serving Size" />
              <br />
              <SelectPicker
                label="Food is Veg"
                data={is_vegetarian}
                onSelect={(value) => setIsVeg(value)}
                style={{ width: 300 }}
              />
              <br /> <br />
              <Button
                appearance="primary"
                onClick={handleSubmitModal}
                style={{ background: Color.Color_C, color: Color.Color_A, width: 300 }}
              >
                Add Food Item
              </Button>

              {/* Display added food items */}
              {addedFoodItems.length > 0 && (
                <div style={{ marginTop: 20 }}>
                  <h5>Added Food Items</h5>
                  {addedFoodItems.map((foodItem, index) => (
                    <FoodCartItem key={index} foodItem={foodItem} />
                  ))}
                </div>
              )}
              <Modal.Footer>
                <Button appearance="default" onClick={()=>setFoodItemOpen(!foodItemOpen)} >Close</Button>
              </Modal.Footer>
              
            </Modal>
          </Form>
        
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
