import React, { useState } from "react";
import Label from "../../components/Label";
import { Button, Modal } from "rsuite";

export default function CalendarPeriodisation({ athlete, created_by }) {
  const [showModal, setShowModal] = useState(false);
  const [comp, setComp] = useState(Array(12).fill(""));
  const [strength, setStrength] = useState(Array(12).fill(""));
  const [endurance, setEndurance] = useState(Array(12).fill(""));
  const [speed, setSpeed] = useState(Array(12).fill(""));
  const [skill, setSkill] = useState(Array(12).fill(""));
  const [assessment, setAssessment] = useState(Array(12).fill(""));
  const [testing, setTesting] = useState(Array(12).fill(""));
  const [volume, setVolume] = useState(Array(12).fill(0));
  const [intensity, setIntensity] = useState(Array(12).fill(0));
  const [physicalPreparation, setPhysicalPreparation] = useState(
    Array(12).fill(0)
  );
  const [peaking, setPeaking] = useState(Array(12).fill(0));
  const [technicalPreparation, setTechnicalPreparation] = useState(
    Array(12).fill(0)
  );
  const [tacticalPreparation, setTacticalPreparation] = useState(
    Array(12).fill(0)
  );
  const [psychologicalPreparation, setPsychologicalPreparation] = useState(
    Array(12).fill(0)
  );
  const [weeklySessionsFirst, setWeeklySessionsFirst] = useState(
    Array(12).fill(0)
  );
  const [weeklySessionsSecond, setWeeklySessionsSecond] = useState(
    Array(12).fill(0)
  );
  const [weeklySessionsThird, setWeeklySessionsThird] = useState(
    Array(12).fill(0)
  );
  const [weeklySessionsFourth, setWeeklySessionsFourth] = useState(
    Array(12).fill(0)
  );
  const [months, setMonths] = useState([
    { id: 1, value: "03-01-2024" },
    { id: 2, value: "03-01-2024" },
    { id: 3, value: "03-01-2024" },
    { id: 4, value: "03-01-2024" },
    { id: 5, value: "03-01-2024" },
    { id: 6, value: "03-01-2024" },
    { id: 7, value: "03-01-2024" },
    { id: 8, value: "03-01-2024" },
    { id: 9, value: "03-01-2024" },
    { id: 10, value: "03-01-2024" },
    { id: 11, value: "03-01-2024" },
    { id: 12, value: "03-01-2024" },
  ]);
  const [submittedData, setSubmittedData] = useState(null);

  const [developmentPlanFields, setDevelopmentPlanFields] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
  ]);
  const [mesoFields, setMesoFields] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
  ]);
  const [trainingData, setTrainingData] = useState([
    {
      performance: "",
      testsStandards: "",
      physicalPrep: "",
      technicalPrep: "",
      tacticalPrep: "",
      psychologicalPrep: "",
    },
  ]);
  const handleInputChange = (index, field, value) => {
    const newData = [...trainingData];
    newData[index][field] = value;
    setTrainingData(newData);
  };

  const handleChangeStrength = (e, index) => {
    const { value } = e.target;
    const updatedComp = [...strength];
    updatedComp[index] = value;
    setStrength(updatedComp);
  };
  const handleChangeSpeed = (e, index) => {
    const { value } = e.target;
    const updatedComp = [...speed];
    updatedComp[index] = value;
    setSpeed(updatedComp);
  };
  const handleChangeEndurance = (e, index) => {
    const { value } = e.target;
    const updatedComp = [...endurance];
    updatedComp[index] = value;
    setEndurance(updatedComp);
  };
  const handleChangeSkill = (e, index) => {
    const { value } = e.target;
    const updatedComp = [...skill];
    updatedComp[index] = value;
    setSkill(updatedComp);
  };
  const [parent, setParent] = useState("");

  const [counselingStatus, setCounselingStatus] = useState(
    Array.from({ length: 12 }, () => "Yes")
  );
  const [periodisation, setPeriodisation] = useState(
    Array.from({ length: 1 }, () => "Block")
  );
  const handlePeriodisationChange = (index, value) => {
    const updatedStatus = [...periodisation];
    updatedStatus[index] = value;
    setPeriodisation(updatedStatus);
  };
  const handleCounselingChange = (index, value) => {
    const updatedStatus = [...counselingStatus];
    updatedStatus[index] = value;
    setCounselingStatus(updatedStatus);
  };
  const [nutritionStatus, setNutritionStatus] = useState(
    Array.from({ length: 12 }, () => "Yes")
  );
  const handleNutritionChange = (index, value) => {
    const updatedStatus = [...nutritionStatus];
    updatedStatus[index] = value;
    setNutritionStatus(updatedStatus);
  };
  const [saunaSteamStatus, setSaunaSteamStatus] = useState(
    Array.from({ length: 12 }, () => "Yes")
  );
  const handleSaunaSteamChange = (index, value) => {
    const updatedStatus = [...saunaSteamStatus];
    updatedStatus[index] = value;
    setSaunaSteamStatus(updatedStatus);
  };
  const [iceBathSwimming, setIceBathSwimmingStatus] = useState(
    Array.from({ length: 12 }, () => "Yes")
  );
  const handleIceBathSwimmingChange = (index, value) => {
    const updatedStatus = [...iceBathSwimming];
    updatedStatus[index] = value;
    setIceBathSwimmingStatus(updatedStatus);
  };
  const [massage, setMassageStatus] = useState(
    Array.from({ length: 12 }, () => "Yes")
  );
  const handleMassageChange = (index, value) => {
    const updatedStatus = [...massage];
    updatedStatus[index] = value;
    setMassageStatus(updatedStatus);
  };
  const [recreational, setRecreationalStatus] = useState(
    Array.from({ length: 12 }, () => "Yes")
  );
  const handleRecreationalChange = (index, value) => {
    const updatedStatus = [...recreational];
    updatedStatus[index] = value;
    setRecreationalStatus(updatedStatus);
  };
  const handleDevelopmentPlanChange = (id, value) => {
    const updatedFields = developmentPlanFields.map((field) => {
      if (field.id === id) {
        return { ...field, value };
      }
      return field;
    });
    setDevelopmentPlanFields(updatedFields);
  };
  const handleMesoChange = (id, value) => {
    const updatedFields = mesoFields.map((field) => {
      if (field.id === id) {
        return { ...field, value };
      }
      return field;
    });
    setMesoFields(updatedFields);
  };
  const handleChangeVolume = (e, index) => {
    const { value } = e.target;
    const updatedVolume = [...volume];
    updatedVolume[index] = value;
    setVolume(updatedVolume);
  };
  const handleChangeIntensity = (e, index) => {
    const { value } = e.target;
    const updatedIntensity = [...intensity];
    updatedIntensity[index] = value;
    setIntensity(updatedIntensity);
  };
  const handleChangePhysicalPreparation = (e, index) => {
    const { value } = e.target;
    const updatedPhysicalPreparation = [...physicalPreparation];
    updatedPhysicalPreparation[index] = value;
    setPhysicalPreparation(updatedPhysicalPreparation);
  };
  const handleChangePeaking = (e, index) => {
    const { value } = e.target;
    const updatedPeaking = [...peaking];
    updatedPeaking[index] = value;
    setPeaking(updatedPeaking);
  };
  const handleChangeTechnicalPreparation = (e, index) => {
    const { value } = e.target;
    const updatedTechnical = [...technicalPreparation];
    updatedTechnical[index] = value;
    setTechnicalPreparation(updatedTechnical);
  };
  const handleChangeTacticalPreparation = (e, index) => {
    const { value } = e.target;
    const updatedTacticalPreparation = [...tacticalPreparation];
    updatedTacticalPreparation[index] = value;
    setTacticalPreparation(updatedTacticalPreparation);
  };
  const handleChangePsychologicalPrep = (e, index) => {
    const { value } = e.target;
    const updatedPsychologicalPrep = [...psychologicalPreparation];
    updatedPsychologicalPrep[index] = value;
    setPsychologicalPreparation(updatedPsychologicalPrep);
  };
  const handleChangeFirst = (e, index) => {
    const { value } = e.target;
    const updatedSessions = [...weeklySessionsFirst];
    updatedSessions[index] = value;
    setWeeklySessionsFirst(updatedSessions);
  };
  const handleChangeSecond = (e, index) => {
    const { value } = e.target;
    const updatedSessions = [...weeklySessionsSecond];
    updatedSessions[index] = value;
    setWeeklySessionsSecond(updatedSessions);
  };
  const handleChangeThird = (e, index) => {
    const { value } = e.target;
    const updatedSessions = [...weeklySessionsThird];
    updatedSessions[index] = value;
    setWeeklySessionsThird(updatedSessions);
  };
  const handleChangeFourth = (e, index) => {
    const { value } = e.target;
    const updatedSessions = [...weeklySessionsFourth];
    updatedSessions[index] = value;
    setWeeklySessionsFourth(updatedSessions);
  };
  const handleChangeComp = (e, index) => {
    const { value } = e.target;
    const updatedComp = [...comp];
    updatedComp[index] = value;
    setComp(updatedComp);
  };
  const handleChangeTesting = (e, index) => {
    const { value } = e.target;
    const updatedTesting = [...testing];
    updatedTesting[index] = value;
    setTesting(updatedTesting);
  };
  const handleChangeAssessment = (e, index) => {
    const { value } = e.target;
    const updatedAssessment = [...assessment];
    updatedAssessment[index] = value;
    setAssessment(updatedAssessment);
  };
  const handleMonthChange = (id, value) => {
    const updatedMonths = months.map((month) => {
      if (month.id === id) {
        return { ...month, value };
      }
      return month;
    });
    setMonths(updatedMonths);
  };

  const handleSubmit = () => {
    const data = {
      8: comp,
      13: strength,
      14: endurance,
      15: speed,
      16: skill,
      9: assessment,
      10: testing,
      23: volume,
      29: intensity,
      25: physicalPreparation,
      24: peaking,
      26: technicalPreparation,
      27: tacticalPreparation,
      28: psychologicalPreparation,
      7: weeklySessionsFirst,
      71: weeklySessionsSecond,
      72: weeklySessionsThird,
      73: weeklySessionsFourth,
      months: months,
      12: developmentPlanFields,
      11: mesoFields,
      1: trainingData[0].performance,
      3: trainingData[0].physicalPrep,
      6: trainingData[0].psychologicalPrep,
      5: trainingData[0].tacticalPrep,
      4: trainingData[0].technicalPrep,
      2: trainingData[0].testsStandards,
      22: parent,
      17: counselingStatus,
      30: periodisation,
      18: nutritionStatus,
      31: saunaSteamStatus,
      19: iceBathSwimming,
      20: massage,
      21: recreational,
    };

    setSubmittedData(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSubmitServer = () => {
    // console.log(submittedData)

    const categoryMappings = {
      1: "Performance",
      2: "Tests/Standards",
      3: "Physical Prep",
      4: "Technical Prep",
      5: "Tactical Prep",
      6: "Psychological Prep",
      7: "Weekly Training Session",
      8: "Competitions",
      9: "Training Assessments",
      10: "Testing Type",
      11: "Mesocycles",
      12: "Development Plan",
      13: "Strength",
      14: "Endurance",
      15: "Speed",
      16: "Skill",
      17: "Counselling",
      18: "Counselling",
      19: "Ice Bath/Swimming",
      20: "Massage",
      21: "Recreation Ground Play",
      22: "Interatction with Parents",
      23: "Volume",
      24: "Peaking",
      25: "Physical Preparation",
      26: "Technical Preparation",
      27: "Tactical Preparation",
      28: "Psychological Preparation",
      29: "Volume",
      30: "Periodisation",
      31: "Sauna",
      71: "Weekly Training Session",
      72: "Weekly Training Session",
      73: "Weekly Training Session",
    };
    const Period = {
      1: "No Period",
      2: "Preparatory",
      3: "Competitive",
      4: "Transition",
    };

    const Phase = {
      1: "No Phase",
      2: "General Preparation Phase",
      3: "Specific Preparation Phase",
      4: "Pre Competition Phase",
      5: "Main Competition Phase",
      6: "Transition Phase",
    };
    const typeMappings = {
      string: null, // Assuming null for string type
      int: 0, // Assuming default value 0 for int type
      boolean: false, // Assuming default value false for boolean type
    };
    const PERIOIDISATION_MICROCYCLE_CHOICES = [
      (4, "4"),
      (8, "8"),
      (12, "12"),
      (16, "16"),
      (20, "20"),
      (24, "24"),
      (28, "28"),
      (32, "32"),
      (36, "36"),
      (40, "40"),
      (44, "44"),
      (48, "48"),
    ];
    function mapDataToPoint(dataPoint, i) {
      if (dataPoint === undefined || dataPoint === null) {
        return null;
      }
      // console.log(dataPoint)
      let start_date = null;
      let end_date = null;
      let target_value = null;
      let period = null;
      let phase = null;
      let microcycle = null;
      let category = null;
      let template = null;

      category = Object.keys(dataPoint)[0];
      const mappedPoints = []; // Array to accumulate mapped points

      for (const key in dataPoint) {
        if (dataPoint.hasOwnProperty(key)) {
          category = key;
          console.log(category)
          switch (category) {
           
            case "1":
              //   console.log("im in 1");
              period = parseInt(Object.keys(Period)[1]);
              phase = parseInt(Object.keys(Phase)[1]);
              microcycle = parseInt(PERIOIDISATION_MICROCYCLE_CHOICES[11]);
              template = parseInt(Object.keys(categoryMappings)[0]);
              start_date = months[0]?.value;
              end_date = months[11]?.value;
              target_value = dataPoint[category].value;

              break;
            case "2":
              //   console.log("im in 2");
              period = parseInt(Object.keys(Period)[1]);
              phase = parseInt(Object.keys(Phase)[1]);
              microcycle = parseInt(PERIOIDISATION_MICROCYCLE_CHOICES[11]);
              template = parseInt(Object.keys(categoryMappings)[0]);
              start_date = months[0]?.value;
              end_date = months[11]?.value;
              target_value = dataPoint[category].value;
              break;

            case "3":
              //   console.log("im in 3");
              period = parseInt(Object.keys(Period)[1]);
              phase = parseInt(Object.keys(Phase)[1]);
              microcycle = parseInt(PERIOIDISATION_MICROCYCLE_CHOICES[11]);
              template = parseInt(Object.keys(categoryMappings)[0]);
              start_date = months[0]?.value;
              end_date = months[11]?.value;
              target_value = dataPoint[category].value;
              break;
            case "4":
              //   console.log("im in 4");
              period = parseInt(Object.keys(Period)[1]);
              phase = parseInt(Object.keys(Phase)[1]);
              microcycle = parseInt(PERIOIDISATION_MICROCYCLE_CHOICES[11]);
              template = parseInt(Object.keys(categoryMappings)[0]);
              start_date = months[0]?.value;
              end_date = months[11]?.value;
              target_value = dataPoint[category].value;
              break;
            case "5":
              //   console.log("im in 5");
              period = parseInt(Object.keys(Period)[1]);
              phase = parseInt(Object.keys(Phase)[1]);
              microcycle = parseInt(PERIOIDISATION_MICROCYCLE_CHOICES[11]);
              template = parseInt(Object.keys(categoryMappings)[0]);
              start_date = months[0]?.value;
              end_date = months[11]?.value;
              target_value = dataPoint[category].value;
            // Add cases for other categories as needed
            case "6":
              //   console.log("im in 6");
              period = parseInt(Object.keys(Period)[1]);
              phase = parseInt(Object.keys(Phase)[1]);
              microcycle = parseInt(PERIOIDISATION_MICROCYCLE_CHOICES[11]);
              template = parseInt(Object.keys(categoryMappings)[0]);
              start_date = months[0]?.value;
              end_date = months[11]?.value;
              target_value = dataPoint[category].value;
            case "7" :
            case "71" :
            case "72" :
            case "73" :
              // Set period, phase, microcycle, and template for these cases
              console.log("im in 7")
              period = parseInt(Object.keys(Period)[1]);
              phase = parseInt(Object.keys(Phase)[1]);
              microcycle = parseInt(PERIOIDISATION_MICROCYCLE_CHOICES[11]);
              template = parseInt(Object.keys(categoryMappings)[0]);

              // Calculate target_value by adding values from the specified keys
              const monthIndex = parseInt(category) - 7; // Adjusting category to month index
              target_value = parseInt(Object.values(dataPoint["7"])[monthIndex]) +
                parseInt(Object.values(dataPoint["71"])[monthIndex]) +
                parseInt(Object.values(dataPoint["72"])[monthIndex]) +
                parseInt(Object.values(dataPoint["73"])[monthIndex]);

              // Set start_date as the start date from the months array for the respective month index
              start_date = new Date(months[monthIndex]?.value);
              // Set end_date as 7 days ahead of the start_date
              end_date = new Date(start_date);
              end_date.setDate(end_date.getDate() + 7);
              break;

            default:

          }
          mappedPoints.push({
            period,
            phase,
            microcycle,
            target_value,
            achieved_value: "",
            start_date,
            end_date,
            athlete: athlete,
            template,
            created_by: created_by,
          });
        }
      }

      return mappedPoints;

    }

    // console.log("submittedData", submittedData);

    // Iterate through each data point and map it to the desired format
    if (submittedData && submittedData.months) {
      for (let i = 0; i < submittedData.months.length; i++) {
        const mappedDataPoint = {};
        for (const categoryKey in submittedData) {
          if (submittedData.hasOwnProperty(categoryKey)) {
            const category = categoryKey;

            let value = submittedData[categoryKey];
            // console.log(value)
            let type =
              typeof value === "string"
                ? "string"
                : typeof value === "number"
                  ? "int"
                  : "boolean";

            if (typeMappings[type] !== null) {
              value = typeMappings[type];
            }

            mappedDataPoint[categoryKey] = {
              category,
              type,
              value,
            };
          }
        }
        const mappedPoint = mapDataToPoint(mappedDataPoint, i);
        console.log(JSON.stringify(mappedPoint));
        // Make request here
        // fetch('your_backend_api_url', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(mappedPoint),
        // })
        // .then(response => {
        //     if (response.ok) {
        //         return response.json();
        //     }
        //     throw new Error('Network response was not ok.');
        // })
        // .then(data => {
        //     console.log('Success:', data);
        // })
        // .catch(error => {
        //     console.error('Error:', error);
        // });
      }
    } else {
      console.error("submittedData or submittedData.assessment is undefined");
    }
  };
  const thStyle = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "left",
  };

  const tdStyle = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "left",
  };

  const tableStyle = {
    borderCollapse: "collapse",
    width: "100%",
    marginBottom: "20px",
    marginLeft: "10px",
    marginRight: "10px",
  };

  const trStyle = {
    border: "1px solid black",
  };
  const tableContainerStyle = {
    overflowX: "auto",
  };

  return (
    <div style={tableContainerStyle}>
      <table style={tableStyle}>
        <tbody>
          <tr style={trStyle}>
            <td
              style={{ ...thStyle, backgroundColor: "#ffebee" }}
              rowSpan="2"
              colSpan={2}
            >
              Training Objectives
            </td>
            <td style={{ ...tdStyle, backgroundColor: "#ffebee" }} colSpan="2">
              Performance
            </td>
            <td style={{ ...tdStyle, backgroundColor: "#ffebee" }} colSpan="2">
              Tests/Standards
            </td>
            <td style={{ ...tdStyle, backgroundColor: "#ffebee" }} colSpan="2">
              Physical prep
            </td>
            <td style={{ ...tdStyle, backgroundColor: "#ffebee" }} colSpan="2">
              Technical prep
            </td>
            <td style={{ ...tdStyle, backgroundColor: "#ffebee" }} colSpan="2">
              Tactical prep
            </td>
            <td style={{ ...tdStyle, backgroundColor: "#ffebee" }} colSpan="2">
              Psychological prep
            </td>
          </tr>

          {trainingData.map((data, index) => (
            <tr key={index} style={trStyle}>
              <td
                style={{ ...tdStyle, backgroundColor: "#ffebee" }}
                colSpan={2}
              >
                <textarea
                  value={data.performance}
                  onChange={(e) =>
                    handleInputChange(index, "performance", e.target.value)
                  }
                  style={{
                    width: "90%",
                    textAlign: "center",
                    backgroundColor: "#ffebee",
                  }}
                />
              </td>
              <td
                style={{ ...tdStyle, backgroundColor: "#ffebee" }}
                colSpan={2}
              >
                <textarea
                  value={data.testsStandards}
                  onChange={(e) =>
                    handleInputChange(index, "testsStandards", e.target.value)
                  }
                  style={{
                    width: "90%",
                    textAlign: "center",
                    backgroundColor: "#ffebee",
                  }}
                />
              </td>
              <td
                style={{ ...tdStyle, backgroundColor: "#ffebee" }}
                colSpan={2}
              >
                <textarea
                  value={data.physicalPrep}
                  onChange={(e) =>
                    handleInputChange(index, "physicalPrep", e.target.value)
                  }
                  style={{
                    width: "90%",
                    textAlign: "center",
                    backgroundColor: "#ffebee",
                  }}
                />
              </td>
              <td
                style={{ ...tdStyle, backgroundColor: "#ffebee" }}
                colSpan={2}
              >
                <textarea
                  value={data.technicalPrep}
                  onChange={(e) =>
                    handleInputChange(index, "technicalPrep", e.target.value)
                  }
                  style={{
                    width: "90%",
                    textAlign: "center",
                    backgroundColor: "#ffebee",
                  }}
                />
              </td>
              <td
                style={{ ...tdStyle, backgroundColor: "#ffebee" }}
                colSpan={2}
              >
                <textarea
                  value={data.tacticalPrep}
                  onChange={(e) =>
                    handleInputChange(index, "tacticalPrep", e.target.value)
                  }
                  style={{
                    width: "90%",
                    textAlign: "center",
                    backgroundColor: "#ffebee",
                  }}
                />
              </td>
              <td
                style={{ ...tdStyle, backgroundColor: "#ffebee" }}
                colSpan={2}
              >
                <textarea
                  value={data.psychologicalPrep}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "psychologicalPrep",
                      e.target.value
                    )
                  }
                  style={{
                    width: "90%",
                    textAlign: "center",
                    backgroundColor: "#ffebee",
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <thead>
          <tr style={trStyle}>
            <th style={{ ...thStyle, backgroundColor: "#e0f2f1" }}>Calendar</th>
            <th style={{ ...thStyle, backgroundColor: "#e0f2f1" }}>
              Monthly Activities
            </th>
            {months.map((month) => (
              <th
                key={month.id}
                style={{ ...thStyle, backgroundColor: "#e0f2f1" }}
              >
                <input
                  type="date"
                  id={`start-${month.id}`}
                  name={`start-${month.id}`}
                  value={month.value}
                  style={{ backgroundColor: "#e0f2f1" }}
                  onChange={(e) => handleMonthChange(month.id, e.target.value)}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr style={trStyle}>
            <td style={{ ...tdStyle, backgroundColor: "#f9fbe7" }} rowSpan="10">
              Annual training Plan
            </td>
            <td style={{ ...tdStyle, backgroundColor: "#e8f5e9" }} rowSpan="4">
              Weekly Training Sessions
            </td>
            {Array.from({ length: 12 }, (_, index) => (
              <td
                key={index}
                style={{ ...tdStyle, backgroundColor: "#e8f5e9" }}
              >
                <input
                  type="number"
                  min="1"
                  max="20"
                  value={weeklySessionsFirst[index]}
                  onChange={(e) => handleChangeFirst(e, index)}
                  style={{
                    width: "90%",
                    textAlign: "center",
                    backgroundColor: "#e8f5e9",
                  }}
                />
              </td>
            ))}
          </tr>
          <tr style={{ ...trStyle, backgroundColor: "#e8f5e9" }}>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <input
                  type="number"
                  min="1"
                  max="20"
                  value={weeklySessionsSecond[index]}
                  onChange={(e) => handleChangeSecond(e, index)}
                  style={{
                    width: "90%",
                    textAlign: "center",
                    backgroundColor: "#e8f5e9",
                  }}
                />
              </td>
            ))}
          </tr>
          <tr style={{ ...trStyle, backgroundColor: "#e8f5e9" }}>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <input
                  type="number"
                  min="1"
                  max="20"
                  value={weeklySessionsThird[index]}
                  onChange={(e) => handleChangeThird(e, index)}
                  style={{
                    width: "90%",
                    textAlign: "center",
                    backgroundColor: "#e8f5e9",
                  }}
                />
              </td>
            ))}
          </tr>
          <tr style={{ ...trStyle, backgroundColor: "#e8f5e9" }}>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <input
                  type="number"
                  min="1"
                  max="20"
                  value={weeklySessionsFourth[index]}
                  onChange={(e) => handleChangeFourth(e, index)}
                  style={{
                    width: "90%",
                    textAlign: "center",
                    backgroundColor: "#e8f5e9",
                  }}
                />
              </td>
            ))}
          </tr>
          <tr style={{ ...trStyle, backgroundColor: "#fff9c4" }}>
            <td style={tdStyle}>Periods</td>
            <td style={tdStyle} colSpan="4">
              Preparatory
            </td>
            <td style={tdStyle} colSpan="4">
              Competitive
            </td>
            <td style={tdStyle} colSpan="4">
              Transition
            </td>
          </tr>
          <tr style={{ ...trStyle, backgroundColor: "#ffccbc" }}>
            <td style={tdStyle}>Phase</td>
            <td style={tdStyle} colSpan="2">
              General Preparation Phase
            </td>
            <td style={tdStyle} colSpan="2">
              Specific Preparation Phase
            </td>
            <td style={tdStyle} colSpan="2">
              Pre Competition Phase
            </td>
            <td style={tdStyle} colSpan="2">
              Main Competition Phase
            </td>
            <td style={tdStyle} colSpan="4">
              Transition Phase
            </td>
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Competitions</td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <textarea
                  placeholder="Enter Competition Details"
                  value={comp[index]}
                  onChange={(e) => handleChangeComp(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Training Assessments</td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <textarea
                  placeholder="Enter Assessment Details"
                  value={assessment[index]}
                  onChange={(e) => handleChangeAssessment(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Testing Type</td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <textarea
                  placeholder="Enter Testing Details"
                  value={testing[index]}
                  onChange={(e) => handleChangeTesting(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={{ ...trStyle, backgroundColor: "grey", color: "white" }}>
            <td style={tdStyle}>Microcycle</td>
            {months.map((month, index) => (
              <td key={index} style={{ ...tdStyle, textAlign: "center" }}>
                {4 + index * 4}
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            {periodisation.map((status, index) => (
              <td
                key={index}
                rowSpan="6"
                style={{ ...tdStyle, backgroundColor: "#a5d6a7" }}
              >
                <select
                  style={{
                    width: "98%",
                    textAlign: "center",
                    backgroundColor: "#a5d6a7",
                  }}
                  value={status}
                  onChange={(e) =>
                    handlePeriodisationChange(index, e.target.value)
                  }
                >
                  <option value="Block">Block</option>
                  <option value="Linear">Linear</option>
                  <option value="Undulating">Undulating</option>
                  <option value="Concurrent">Concurrent</option>
                  <option value="Tapering">Tapering</option>
                </select>
              </td>
            ))}

            <td style={tdStyle}>Meso cycles</td>
            {mesoFields.map((field) => (
              <td key={field.id} style={tdStyle} colSpan="4">
                <textarea
                  placeholder="Enter Mesocycle Details"
                  value={field.value}
                  onChange={(e) => handleMesoChange(field.id, e.target.value)}
                  style={{ width: "98%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Development plan</td>
            {developmentPlanFields.map((field) => (
              <td key={field.id} style={tdStyle} colSpan="4">
                <textarea
                  placeholder="Enter Development Plan"
                  value={field.value}
                  onChange={(e) =>
                    handleDevelopmentPlanChange(field.id, e.target.value)
                  }
                  style={{ width: "98%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Strength</td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <textarea
                  placeholder="Enter Strength Training Details"
                  value={strength[index]}
                  onChange={(e) => handleChangeStrength(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Endurance</td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <textarea
                  placeholder="Enter Endurance Training Details"
                  value={endurance[index]}
                  onChange={(e) => handleChangeEndurance(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Speed</td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <textarea
                  placeholder="Enter Speed Training Details"
                  value={speed[index]}
                  onChange={(e) => handleChangeSpeed(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Skill</td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <textarea
                  placeholder="Enter Skill Training Details"
                  value={skill[index]}
                  onChange={(e) => handleChangeSkill(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={{ ...trStyle, backgroundColor: "#e1bee7" }}>
            <td style={{ ...tdStyle, backgroundColor: "#e1bee7" }}>
              Psychology sessions
            </td>
            <td style={tdStyle}>Counseling</td>
            {counselingStatus.map((status, index) => (
              <td key={index} style={tdStyle}>
                <select
                  style={{
                    width: "98%",
                    textAlign: "center",
                    backgroundColor: "#e1bee7",
                  }}
                  value={status}
                  onChange={(e) =>
                    handleCounselingChange(index, e.target.value)
                  }
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </td>
            ))}
          </tr>
          <tr style={{ ...trStyle, backgroundColor: "#b3e5fc" }}>
            <td style={{ ...tdStyle, backgroundColor: "#b3e5fc" }}>
              Nutrition
            </td>
            <td style={tdStyle}>Counseling/ Nutrition checkup</td>
            {nutritionStatus.map((status, index) => (
              <td key={index} style={tdStyle}>
                <select
                  style={{
                    width: "98%",
                    textAlign: "center",
                    backgroundColor: "#b3e5fc",
                  }}
                  value={status}
                  onChange={(e) => handleNutritionChange(index, e.target.value)}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </td>
            ))}
          </tr>
          <tr style={{ ...trStyle, backgroundColor: "#ffcdd2" }}>
            <td style={{ ...tdStyle, backgroundColor: "#ffcdd2" }} rowSpan="4">
              Recovery & Regeneration
            </td>
            <td style={tdStyle}>Sauna / Steam </td>
            {saunaSteamStatus.map((status, index) => (
              <td key={index} style={tdStyle}>
                <select
                  style={{
                    width: "98%",
                    textAlign: "center",
                    backgroundColor: "#ffcdd2",
                  }}
                  value={status}
                  onChange={(e) =>
                    handleSaunaSteamChange(index, e.target.value)
                  }
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </td>
            ))}
          </tr>
          <tr style={{ ...trStyle, backgroundColor: "#ffcdd2" }}>
            <td style={tdStyle}>Ice Bath/ Swimming</td>
            {iceBathSwimming.map((status, index) => (
              <td key={index} style={tdStyle}>
                <select
                  style={{
                    width: "98%",
                    textAlign: "center",
                    backgroundColor: "#ffcdd2",
                  }}
                  value={status}
                  onChange={(e) =>
                    handleIceBathSwimmingChange(index, e.target.value)
                  }
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </td>
            ))}
          </tr>
          <tr style={{ ...trStyle, backgroundColor: "#ffcdd2" }}>
            <td style={tdStyle}>Massage</td>
            {massage.map((status, index) => (
              <td key={index} style={tdStyle}>
                <select
                  style={{
                    width: "98%",
                    textAlign: "center",
                    backgroundColor: "#ffcdd2",
                  }}
                  value={status}
                  onChange={(e) => handleMassageChange(index, e.target.value)}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </td>
            ))}
          </tr>
          <tr style={{ ...trStyle, backgroundColor: "#ffcdd2" }}>
            <td style={tdStyle}>Recreation Ground play</td>
            {recreational.map((status, index) => (
              <td key={index} style={tdStyle}>
                <select
                  style={{
                    width: "98%",
                    textAlign: "center",
                    backgroundColor: "#ffcdd2",
                  }}
                  value={status}
                  onChange={(e) =>
                    handleRecreationalChange(index, e.target.value)
                  }
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Interaction with Parents</td>
            <td style={tdStyle} colSpan={13}>
              {" "}
              <input
                type="text"
                placeholder="Remarks"
                value={parent}
                onChange={(e) => setParent(e.target.value)}
                style={{ width: "98%", textAlign: "center" }}
              />{" "}
            </td>
          </tr>
          <tr style={trStyle}>
            <td style={{ ...tdStyle, backgroundColor: "#b9f6ca" }} rowSpan="7">
              Training Factors
            </td>
            <td style={tdStyle}>Volume </td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <input
                  type="number"
                  min="1"
                  max="100"
                  value={volume[index]}
                  onChange={(e) => handleChangeVolume(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Intensity </td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <input
                  type="number"
                  min="1"
                  max="100"
                  value={intensity[index]}
                  onChange={(e) => handleChangeIntensity(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Peaking</td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <input
                  type="number"
                  min="1"
                  max="100"
                  value={peaking[index]}
                  onChange={(e) => handleChangePeaking(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Physical Preparation</td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <input
                  type="number"
                  min="1"
                  max="100"
                  value={physicalPreparation[index]}
                  onChange={(e) => handleChangePhysicalPreparation(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Technical Preparation</td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <input
                  type="number"
                  min="1"
                  max="100"
                  value={technicalPreparation[index]}
                  onChange={(e) => handleChangeTechnicalPreparation(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Tactical Preparation</td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <input
                  type="number"
                  min="1"
                  max="100"
                  value={tacticalPreparation[index]}
                  onChange={(e) => handleChangeTacticalPreparation(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
          <tr style={trStyle}>
            <td style={tdStyle}>Psychological Preparation</td>
            {Array.from({ length: 12 }, (_, index) => (
              <td key={index} style={tdStyle}>
                <input
                  type="number"
                  min="1"
                  max="100"
                  value={psychologicalPreparation[index]}
                  onChange={(e) => handleChangePsychologicalPrep(e, index)}
                  style={{ width: "90%", textAlign: "center" }}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <Button appearance="primary" onClick={handleSubmit}>
        Preview
      </Button>
      <Modal open={showModal} onClose={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{JSON.stringify(submittedData, null, 2)}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmitServer} appearance="primary">
            Submit
          </Button>
          <Button onClick={handleCloseModal} appearance="primary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
