import React, { useEffect } from "react";
import { HttpService } from "../../../services/http.service";
import { Button , Loader, Modal} from "rsuite";
import { Container, Typography } from "@mui/material";
import UserCustomDrawer from "../../../components/CustomDrawer";
// import CustomSideNavbar from "../../../components/CustomSideNav";
// import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
import CustomNotification from "../../../components/CustomNotification";
// import DashBoardTopNav from "../../../components/DashBoardTopNav";
// import { GetInjuryRecord } from "../../../components/custmomhooks/CustomHooks";
// import Sidebar from "../../../components/CustomSideNav";
import {
  RECORD_INJURY_API_URLS,
  USER_API_URL,
  ATHLETE_PROFILE_API_URL,
} from "../../../services/API";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; 
import timeGridPlugin from '@fullcalendar/timegrid'
// import PlusIcon from "@rsuite/icons/Plus";
import ViewTreatmentLogs from "../../logs/ViewTreatmentLogs";
export default function ViewInjury() {
  const [selectedEventInfo, setSelectedEventInfo] = React.useState(null);
  const [date, setDate] = React.useState()
  const [open, setOpen] = React.useState(false);
  const [selectedAthleteId, setSelectedAthleteId] = React.useState(null);
  const [openModal2, setOpenModal2] = React.useState([]);
  // const [isSidebarExpanded, setIsSidebarExpanded] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [injuryRecord, setInjuryRecord] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [count, setCount] = React.useState(0); // Total number of pages
  const handleOpenModal2 = (eventInfo, selected) => {
    setSelectedEventInfo(eventInfo);
    setOpenModal2(selected);
  };

  const handleCloseModal2 = () => {
    setSelectedEventInfo(null);
    setOpenModal2(null);
  };
  const handleOpenModal = (athleteId,selected) => {
    setSelectedAthleteId(athleteId);
    setOpenModal2(selected);
  };
  const handleCloseModal = () => {
    setSelectedAthleteId(null);
    setOpenModal2(null);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${RECORD_INJURY_API_URLS}?page=${currentPage}`,{}, false
        );
        // console.log(response.data);
        const modifiedResult = await Promise.allSettled(
          response.data.results.map(async (result) => {
            try {
              // Fetch athlete data for each result
              const athleteResponse = await HttpService.get(
                ATHLETE_PROFILE_API_URL + result.athlete + "/",{}, false
              );
              const athlete_name = athleteResponse.data.name;
              const recordResponse = await HttpService.get(
                USER_API_URL + result.injury_recorded_by + "/",{}, true
              );
              const record_name = recordResponse.data.first_name;
              // console.log(sessionResponse.data.name);
              return {
                id: result.id,
                athlete_name: athlete_name,
                record: record_name,
              

                ...result,
              };
            } catch (error) {
              // Handle individual request error
              console.error(
                `Error fetching data for result ${result.id}:`,
                error
              );
              return null; // or handle the error in an appropriate way
            }
          })
        );

        // Extracting successful results and maintaining the order
        const filteredResults = modifiedResult
          .filter(
            (result) => result.status === "fulfilled" && result.value !== null
          )
          .map((result) => result.value);

        setInjuryRecord(filteredResults);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        // setTotalPages(Math.ceil(response.data.count / 10));
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [currentPage,count]);
  const handleOpen = (date,key) => {
    setDate(date)
    setOpen(true);
    setPlacement(key);
  };

  return (
    <>
     

   
      <Container maxWidth="lg"  >
       

     
       {injuryRecord === undefined ? (
          <>
            <Loader content={"Loading"} />
            {error?.response?.status === 200 ? null : (
              <CustomNotification
                open={openModal}
                setOpen={setOpenModal}
                message={
                  error?.response?.status === 200 ? "Loading" : error?.message
                }
                title={error?.response?.status === 200 ? "Loading" : "Error"}
              />
            )}
         </>
          ) :  <FullCalendar
          
          plugins={[dayGridPlugin, interactionPlugin,timeGridPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          // headerToolbar={{
          //   left: 'prev,next today',
          //   center: 'title',
          //   right: 'dayGridMonth,timeGridWeek,timeGridDay'
          // }}
          events={injuryRecord?.map((event) => ({
            title: event.injury_type,
            injuryType: event.injury_type,
            start: new Date(event.date),
            description: event.description,
            athlete: event.athlete_name,
            recordedBy: event.record,
            recoveryStatus: event.recovery_status,
            id: event.athlete,
          }))}
          eventContent={(eventInfo) => {
            return (
              <div>
                <p>
                  <Typography variant="body" fontWeight={"bold"}>
                    Illness Type:
                  </Typography>{" "}
                  {eventInfo.event.title}
                </p>
                <p>
                  <Typography
                    variant="body"
                    fontWeight={"bold"}
                    color={"red"}
                    textAlign={"center"}
                    onMouseEnter={() => {
                      setTimeout(() => handleOpenModal2(eventInfo, 2), 500); // Adjust the delay time as needed
                    }}

                  >
                    Description
                  </Typography>
                </p>
              </div>
            );
          }}
          eventClick={(info) => handleOpenModal(info.event.id, 1)}
          dateClick={(arg) => handleOpen(arg.date, "right")}
          height="600px"
        />}
       
      </Container>

      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={placement}
        mode={"View Injury"}
        date={date}
        setCount={setCount}
      />
        <Modal size="lg" open={openModal2===1} onClose={handleCloseModal}>
        <ViewTreatmentLogs athlete = {selectedAthleteId} />
      </Modal>
      <Modal size="lg" open={openModal2 === 2} onClose={handleCloseModal2}  >
        {selectedEventInfo && (
          <>
            <h4>Injury Details: {selectedEventInfo.event.extendedProps?.athlete}</h4>

            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
              <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Injury Type:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.injuryType}</td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Description:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.description}</td>
                </tr>

                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Recovery Status:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.recoveryStatus}</td>
                </tr>
               
               
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Recorded By:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.recordedBy}</td>
                </tr>

              </tbody>
            </table>
          </>
        )}
        <br />
        <Modal.Footer>
          <Button onClick={handleCloseModal2} appearance="primary">
            Ok
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}
