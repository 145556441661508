import React from "react";
import styles from "./landing.module.css";
import Logo from "./../../assets/image/brand.png";

export default function LandingNav() {
 
  return (
   
      <nav className={styles.navbar_custom}>
        <ul className={styles.navbar_nav}>
          <li>
            <img src={Logo} alt="Logo" className={styles.logo} />
          </li>
          <li>
            <a href="#home" className={styles.nav_link}>
              Home
            </a>
          </li>
          <li>
            <a href="#feature" className={styles.nav_link}>
              Features
            </a>
          </li>
          <li>
            <a href="#services" className={styles.nav_link}>
              Services
            </a>
          </li>
        
          <li>
            <a href="#contact" className={styles.nav_link}>
              Contact Us
            </a>
          </li>
        </ul>
      </nav>

  );
}
