import React, { useEffect, useState, useContext } from "react";
import { HttpService } from "../services/http.service";
import { EVALUATION_RESULTS } from "../services/API";
import {  Typography } from "@mui/material";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Label from "../components/Label";
import { AuthContext } from "../store/AuthContext";
import jwt_decode from "jwt-decode";
export default function PainInsightsAthlete() {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const [groupedResults, setGroupedResults] = useState([]);
  const [loading, setLoading] = useState(true);
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }

  const athleteId = decode.athlete_id;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await HttpService.get(
          `${EVALUATION_RESULTS}?department=1&categories=3&athlete=${athleteId}`,{},false
        );
      

        const evaluationData = responses
          .map((response) => response.data.results)
          .flat();

        const groupedData = {};

        evaluationData.forEach((result, index) => {
          const values = {
            [result.field_name]: [
              result.boolean_value,
              result.choice_value,
              result.date_value,
              result.float_value,
              result.integer_value,
              result.remarks,
              result.string_value,
            ].filter(Boolean),
          };

          const rowId = `${athleteId}-${result.created_at}-${index}`;

          if (!groupedData[rowId]) {
            groupedData[rowId] = {
              id: rowId,
              index, // Add an index property
              athlete_id: athleteId,
              athlete_name: result.athlete_name, // Assuming this field is present in the response
              date: result.created_at.slice(0, 10),
              ...values,
            };
          } else {
            // Merge values for existing row
            Object.keys(values).forEach((key) => {
              if (!groupedData[rowId][key]) {
                groupedData[rowId][key] = [];
              }
              groupedData[rowId][key].push(...values[key]);
            });
          }
        });

        const processedData = Object.values(groupedData);
        setGroupedResults(processedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [athleteId]);
  const columns = [
    {
      field: "athlete_name",
      headerName: "Name",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Insight",
      headerName: "Insight",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let vasValue = parseFloat(params.row["Visual Analog Scale"]);
        let severity =
          params.row["Severity"] === "Severe"
            ? 3
            : params.row["Severity"] === "Moderate"
            ? 2
            : 1;
        let duration = params.row["Duration"] === "Continous" ? 2 : 1;
        let diurnalVariation =
          params.row["Diurnal Variation"] === "Yes" ? 2 : 1;
        let type =
          params.row["Type"] === "Visceral"
            ? 3
            : params.row["Type"] === "Neuropathic"
            ? 2
            : 1;

        let score =
          ((vasValue + severity + duration + diurnalVariation + type) / 20) *
          100;

        let insight = "No Attention";
        let color = "primary";

        if (score > 75) {
          insight = "High Attention";
          color = "error";
        } else if (score >= 50 && score <= 75) {
          insight = "Moderate Attention";
          color = "warning";
        } else if (score >= 25 && score < 50) {
          insight = "Mild Attention";
          color = "warning";
        }

        return (
          <div className={`cellWithStatus ${insight}`}>
            <Label color={color}>{insight}</Label>
          </div>
        );
      },
    },
    {
      field: "Nature",
      headerName: "Nature",
      sortable: true,
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Type",
      headerName: "Type",
      sortable: true,
      width: 250,
      editable: true,
      headerAlign: "center",
      renderCell: (params) => {
        let color = "success";

        if (Array.isArray(params.row["Type"])) {
          // Handle if Type is an array
          if (
            params.row["Type"].includes("Somatic Pain") &&
            params.row["Type"].includes("Neuropathic pain")
          ) {
            color = "secondary"; // Set a custom color if both values are in the array
          } else if (params.row["Type"].includes("Somatic Pain")) {
            color = "error"; // Set color to error if "Somatic Pain" is in the array
          } else if (params.row["Type"].includes("Neuropathic pain")) {
            color = "warning"; // Set color to warning if "Neuropathic pain" is in the array
          }
        } else if (params.row["Type"].includes("Somatic Pain")) {
          color = "error"; // Set color to error if Type is a string "Somatic Pain"
        } else if (params.row["Type"].includes("Neuropathic pain")) {
          color = "warning"; // Set color to warning if Type is a string "Neuropathic pain"
        }

        return (
          <div className={`cellWithStatus ${params.row["Type"]}`}>
            <Label color={color}>
              {Array.isArray(params.row["Type"])
                ? params.row["Type"].join(", ") // Join array elements if Type is an array
                : params.row["Type"]}
            </Label>
          </div>
        );
      },
    },
    {
      field: "Diurnal Variation",
      headerName: "Diurnal Variation",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const color = params.row["Diurnal Variation"].includes("Yes")
          ? "error"
          : "success";

        return (
          <div className={`cellWithStatus ${params.row["Diurnal Variation"]}`}>
            <Label color={color}>
              {Array.isArray(params.row["Diurnal Variation"])
                ? params.row["Diurnal Variation"].join(", ")
                : params.row["Diurnal Variation"]}
            </Label>
          </div>
        );
      },
    },
    {
      field: "Severity",
      headerName: "Severity",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let color = "success";

        if (Array.isArray(params.row["Severity"])) {
          // Handle if Severity is an array
          if (
            params.row["Severity"].includes("Moderate") &&
            params.row["Severity"].includes("Severe")
          ) {
            color = "secondary"; // Set a custom color if both values are in the array
          } else if (
            params.row["Severity"].includes("Mild") &&
            params.row["Severity"].includes("Moderate")
          ) {
            color = "primary"; // Set a custom color if both values are in the array
          } else if (params.row["Severity"].includes("Severe")) {
            color = "error"; // Set color to error if "Somatic Pain" is in the array
          } else if (params.row["Severity"].includes("Moderate")) {
            color = "warning"; // Set color to warning if "Neuropathic pain" is in the array
          }
        } else if (params.row["Severity"].includes("Severe")) {
          color = "error"; // Set color to error if Severity is a string "Somatic Pain"
        } else if (params.row["Severity"].includes("Moderate")) {
          color = "warning"; // Set color to warning if Severity is a string "Neuropathic pain"
        }

        return (
          <div className={`cellWithStatus ${params.row["Severity"]}`}>
            <Label color={color}>
              {Array.isArray(params.row["Severity"])
                ? params.row["Severity"].join(", ") // Join array elements if Severity is an array
                : params.row["Severity"]}
            </Label>
          </div>
        );
      },
    },
    {
      field: "Visual Analog Scale",
      headerName: "VAS",
      sortable: true,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let color = "error";
        const vasValue = parseFloat(params.row["Visual Analog Scale"]);

        if (!isNaN(vasValue)) {
          if (vasValue < 3) {
            color = "success"; // If VAS is less than 3, set color to green
          } else if (vasValue >= 4 && vasValue <= 6) {
            color = "warning"; // If VAS is between 4 and 6 (inclusive), set color to yellow
          }
        }

        return (
          <div
            className={`cellWithStatus ${params.row["Visual Analog Scale"]}`}
          >
            <Label color={color}>
              {Array.isArray(params.row["Visual Analog Scale"])
                ? params.row["Visual Analog Scale"].join(", ")
                : params.row["Visual Analog Scale"]}
            </Label>
          </div>
        );
      },
    },
    {
      field: "Duration",
      headerName: "Duration",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const color = params.row["Duration"].includes("Continous")
          ? "error"
          : "success";

        return (
          <div className={`cellWithStatus ${params.row["Duration"]}`}>
            <Label color={color}>
              {Array.isArray(params.row["Duration"])
                ? params.row["Duration"].join(", ")
                : params.row["Duration"]}
            </Label>
          </div>
        );
      },
    },
  ];
  const groupedData = {};

  groupedResults.forEach((item) => {
    const { athlete_id, athlete_name, date, ...fields } = item;
    if (!groupedData[date]) {
      groupedData[date] = {
        athlete_id,
        athlete_name,
        date,
        ...fields,
      };
    } else {
      Object.keys(fields).forEach((field) => {
        if (!groupedData[date][field]) {
          groupedData[date][field] = [];
        }
        if (Array.isArray(groupedData[date][field])) {
          groupedData[date][field].push(
            ...(Array.isArray(fields[field]) ? fields[field] : [fields[field]])
          );
        } else {
          groupedData[date][field] = [groupedData[date][field]];
          groupedData[date][field].push(
            ...(Array.isArray(fields[field]) ? fields[field] : [fields[field]])
          );
        }
      });
    }
  });

  const finalGroupedResults = Object.values(groupedData);
  // console.log(finalGroupedResults);

  return (
    <div>
      <Typography variant="h6" textAlign={"center"}>
        Pain Outcome
      </Typography>

      {loading ? (
        <Typography variant="h6" textAlign={"center"}>
          Loading...
        </Typography>
      ) : groupedResults?.length === 0 ? (
        <Typography variant="h6" textAlign={"center"}>
          No Data
        </Typography> // Use results.length to check if there are no results
      ) : (
        <div
          style={{
            display: "flex",

            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          <Container
            maxWidth="lg"
            style={{ marginTop: "4rem", marginBottom: "2rem" }}
          >
            <Box key={Math.random().toLocaleString()}>
              <DataGrid
                rows={finalGroupedResults}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 50]}
                disableSelectionOnClick
                autoHeight
                getRowId={(row) => `${row.athlete_id}-${row.date}-${row.index}`}
              />
            </Box>
          </Container>
        </div>
      )}
    </div>
  );
}
