import React, { useState } from 'react'

import styles from "./../sportsscience/science.module.css";
export default function AssessmentType({drawerTitle,setDrawerTitle,selectedItem,setSelectedItem}) {
  
    const analysis = [
        { name: "Gait", value: 0 },
        { name: "Running", value: 1 },
        { name: "Archery", value: 2 },
        { name: "Sprinting", value: 3 },
        { name: "Swimming", value: 4 },
        { name: "Jumping", value: 5 },
        { name: "Throwing", value: 6 },
        { name: "Cycling", value: 7 },
        { name: "Badminton", value: 8 },
        { name: "Table Tennis", value: 9 },
        { name: "Lawn Tennis", value: 10 },
        { name: "Shooting", value: 11 },
      ];
    const handleOpen = ({ name, key }) => {
      setDrawerTitle(name);
      setSelectedItem(name);
    };
  return (
    <div><div
    style={{
      margin: "2rem",
    }}
  >
    <div className={styles.box_science_sports}>
      {analysis.map((item, index) => (
        <label
          className={`${styles.labelItem_sports} ${selectedItem === item.name ? styles.selected : ""}`}
          onClick={() => handleOpen({
            name: item.name,
            key: "left",
          })}
          key={item.name}
        >
          {item.name}
        </label>
      ))}
    </div>
  </div></div>
  )
}
