import React, { useState,useContext } from "react";
import { Button } from "rsuite"; // Import SelectPicker
import { HttpService } from "../services/http.service";
import {
  EVALUATION_RESULTS,
  // ATHLETE_PROFILE_API_URL,
} from "../services/API";
import { Card, CardContent, Typography } from "@mui/material";
import Chart from "chart.js/auto";
import { Pie } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { AuthContext } from "../store/AuthContext";
import jwt_decode from "jwt-decode";

import Papa from "papaparse";
import { CascadingDropDownCategoricalAthlete } from "./CascadingDropDownAthlete";
Chart.register(CategoryScale);

export default function CategoricalAthlete() {
  const [results, setResult] = useState([]);
  // const [filterResults, setFilterResults] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAthlete, setSelectedAthlete] = useState(null); // Add state for selected athlete
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [athletes, setAthletes] = useState(); // State to store athlete options
  // const [showGenerateButton, setShowGenerateButton] = useState(true);
  const [filter, setFilter] = useState(0);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const athleteId = decode.athlete_id;
  const fetchResult = async () => {
    try {
      const response = await HttpService.get(
        `${EVALUATION_RESULTS}?department=${selectedDepartment}&categories=${selectedCategory}&field=${selectedSubCategory}&athlete=${athleteId}`,{},false
      );
  
      const modifiedResult = response.data.results.map((result, index) => ({
        id: index,
        athlete_id: athleteId, // Use the athleteId directly
        value:
          result.boolean_value !== false
            ? result.boolean_value
            : result.choice_value !== null
            ? result.choice_value
            : result.date_value !== null
            ? result.date_value
            : result.float_value !== null
            ? result.float_value
            : result.integer_value !== null
            ? result.integer_value
            : result.remarks !== null
            ? result.remarks
            : result.string_value !== null
            ? result.string_value
            : null,
        date: result.created_at.slice(0, 10),
        field_name: result.field_name,
        field_id: result.field,
      }));
  
      setResult(modifiedResult);
      // setShowGenerateButton(false);
      setFilter(1);
    } catch (error) {
      console.warn(error);
    }
  };

 

  const resetSelection = () => {
    setSelectedDepartment(null);
    setSelectedCategory(null);
    setSelectedAthlete(null);
    setResult([]);
    // setFilterResults([]);
    // setShowGenerateButton(true);
    setFilter(0);
  };
  // console.log(results)
  function countResult(data) {
    const valueCount = {};
    data?.forEach((item) => {
      const value = item.value;
      if (value) {
        valueCount[value] = (valueCount[value] || 0) + 1;
      }
    });
    return valueCount;
  }

  function ChartSection({ title, data, backgroundColor }) {
    return (
      <div>
        <Pie
          data={{
            labels: Object.keys(data),
            datasets: [
              {
                data: Object.values(data),
                backgroundColor,
              },
            ],
          }}
          options={{
            plugins: {
              title: {
                display: true,
                text: title,
              },
              legend: {
                display: true,
              },
            },
          }}
        />
      </div>
    );
  }
  const columns = [
   
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "field_name",
      headerName: "Test Name",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      headerName: "Result",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
  ];
  function handleExportCSV(data, title) {
    const csvData = Papa.unparse(data); // Convert data to CSV format using PapaParse
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${title}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  }
  return (
    <div>
      
          <Typography variant="h6" textAlign={"center"}>
            Categorical Analytics
          </Typography>
       
      <div>
        <CascadingDropDownCategoricalAthlete
          selectedDepartment={selectedDepartment}
          setSelectedDepartment={setSelectedDepartment}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          departments={departments}
          setDepartments={setDepartments}
          categories={categories}
          setCategories={setCategories}
          athletes={athletes}
          setAthletes={setAthletes}
          results={results}
          selectedAthlete={selectedAthlete}
          setSelectedAthlete={setSelectedAthlete}
          subCategories={subCategories}
          setSubCategories={setSubCategories}
          selectedSubCategory={selectedSubCategory}
          setSelectedSubCategory={setSelectedSubCategory}
        />
      </div>
      {selectedCategory === null ? null : (
        <>
        
            <Button
              style={{ marginLeft: "2rem", marginTop: "1rem" }}
              appearance="primary"
              color="green"
              onClick={() => fetchResult()}
              disabled={
                selectedSubCategory === "no_numeric_data" ||
                selectedSubCategory?.length <= 0
              }
            >
              Generate Results
            </Button>
         
            <Button
              style={{ marginLeft: "2rem", marginTop: "1rem" }}
              appearance="primary"
              color="yellow"
              onClick={() => resetSelection()}
            >
              Reset Results
            </Button>
        
        </>
      )}

     
      {filter === 1 ? (
        <>
          {results?.length === 0 ? (
            <Typography variant="h6" textAlign={"center"}>
              No Data
            </Typography> // Use results.length to check if there are no results
          ) : (
            <div
              style={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              <Container
                maxWidth="lg"
                style={{ marginTop: "4rem", marginBottom: "2rem" }}
              >
                <Box key={Math.random().toLocaleString()}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={results}
                    columns={columns}
                    pagination
                    paginationMode="client"
                    pageSize={10}
                  />
                </Box>
                <Button
                  style={{ marginTop: "2rem" }}
                  color="cyan"
                  appearance="primary"
                  onClick={() => handleExportCSV(results, "Results")}
                >
                  Export Data
                </Button>
              </Container>
              <Card
                sx={{
                  width: "40rem",
                  height: "40rem",
                  marginBottom: "2rem",
                  margin: "1rem",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <ChartSection
                    title="Categorical Data"
                    data={countResult(results)}
                    backgroundColor={["pink", "skyblue"]}
                  />
                </CardContent>
              </Card>
            </div>
          )}
        </>
      ) :  null}
    </div>
  );
}
