import React, { useState } from "react";
import { Button } from "rsuite";
function HeartRateZones() {
  const [age, setAge] = useState(0);

  // Function to calculate heart rate zones
  const calculateHeartRateZones = (age) => {
    // Constants for percentage limits of heart rate zones
    const zoneLimits = {
      Zone1: { min: 0.5, max: 0.6 },
      Zone2: { min: 0.6, max: 0.7 },
      Zone3: { min: 0.7, max: 0.8 },
      Zone4: { min: 0.8, max: 0.9 },
      Zone5: { min: 0.9, max: 1.0 },
    };

    // Calculate maximum heart rate using "220 minus age" formula
    const estimatedMHR = 220 - age;

    // Calculate heart rate zones
    const heartRateZones = {};
    for (const [zone, { min, max }] of Object.entries(zoneLimits)) {
      const minBPM = Math.round(estimatedMHR * min);
      const maxBPM = Math.round(estimatedMHR * max);
      heartRateZones[zone] = { min: minBPM, max: maxBPM };
    }

    return heartRateZones;
  };

  const handleAgeChange = (event) => {
    setAge(parseInt(event.target.value, 10));
  };

  const heartRateZones = calculateHeartRateZones(age);
  const getColorForZone = (index) => {
    const colors = ["pink", "yellow", "lightblue", "orange", "lightgreen"];
    return colors[index % colors.length];
  };

  return (
    <div>
      <h4>Heart Rate Zones</h4>
      <label>
        Enter Age:
        <input type="number" value={age} onChange={handleAgeChange} />
      </label>
      {age > 0 && (
        <div>
          <h4>Heart Rate Zones for a {age}-year-old</h4>
          <br />
          <table style={{ borderCollapse: "collapse", width: "90%" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black", background: "white" }}>
                  Zone
                </th>
                <th style={{ border: "1px solid black", background: "white" }}>
                  Min BPM
                </th>
                <th style={{ border: "1px solid black", background: "white" }}>
                  Max BPM
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(heartRateZones).map(
                ([zone, { min, max }], index) => (
                  <tr
                    key={zone}
                    style={{
                      border: "1px solid black",
                      background: getColorForZone(index),
                    }}
                  >
                    <th style={{ border: "1px solid black" }}>{zone}</th>
                    <th style={{ border: "1px solid black" }}>{min}</th>
                    <th style={{ border: "1px solid black" }}>{max}</th>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

function KarvonenHeartRateZones() {
  const [age, setAge] = useState(0);
  const [restingHeartRate, setRestingHeartRate] = useState(0);

  // Function to calculate heart rate zones using the Karvonen method
  const calculateKarvonenZones = () => {
    // Constants for percentage limits of heart rate zones
    const zoneLimits = {
      Zone1: { min: 0.5, max: 0.6 },
      Zone2: { min: 0.6, max: 0.7 },
      Zone3: { min: 0.7, max: 0.8 },
      Zone4: { min: 0.8, max: 0.9 },
      Zone5: { min: 0.9, max: 1.0 },
    };

    // Calculate estimated maximum heart rate using "220 minus age" formula
    const estimatedMaxHeartRate = 220 - age;

    // Calculate heart rate reserve (HRR)
    const heartRateReserve = estimatedMaxHeartRate - restingHeartRate;

    // Calculate heart rate zones
    const heartRateZones = {};
    for (const [zone, { min, max }] of Object.entries(zoneLimits)) {
      const minBPM = Math.round(heartRateReserve * min + restingHeartRate);
      const maxBPM = Math.round(heartRateReserve * max + restingHeartRate);
      heartRateZones[zone] = { min: minBPM, max: maxBPM };
    }

    return heartRateZones;
  };

  const handleAgeChange = (event) => {
    setAge(parseInt(event.target.value, 10));
  };

  const handleRestingHeartRateChange = (event) => {
    setRestingHeartRate(parseInt(event.target.value, 10));
  };

  const heartRateZones = calculateKarvonenZones();
  const getColorForZone = (index) => {
    const colors = ["pink", "yellow", "lightblue", "orange", "lightgreen"];
    return colors[index % colors.length];
  };

  return (
    <div>
      <h4>Karvonen Heart Rate Zones</h4>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <label>
          Enter Age:
          <input type="number" value={age} onChange={handleAgeChange} />
        </label>

        <label>
          Enter Resting Heart Rate:
          <input
            type="number"
            value={restingHeartRate}
            onChange={handleRestingHeartRateChange}
          />
        </label>
      </div>
      <br /> <br />
      {age > 0 && restingHeartRate > 0 && (
        <div>
          <h6>
            Heart Rate Zones for a {age}-year-old with a resting heart rate of{" "}
            {restingHeartRate} bpm
          </h6>
          <br />
          <table style={{ borderCollapse: "collapse", width: "90%" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black", background: "white" }}>
                  Zone
                </th>
                <th style={{ border: "1px solid black", background: "white" }}>
                  Min BPM
                </th>
                <th style={{ border: "1px solid black", background: "white" }}>
                  Max BPM
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(heartRateZones).map(
                ([zone, { min, max }], index) => (
                  <tr
                    key={zone}
                    style={{
                      border: "1px solid black",
                      background: getColorForZone(index),
                    }}
                  >
                    <th style={{ border: "1px solid black" }}>{zone}</th>
                    <th style={{ border: "1px solid black" }}>{min}</th>
                    <th style={{ border: "1px solid black" }}>{max}</th>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

const HeartRateZoneCalculator = () => {
  const [age, setAge] = useState("");
  const [lthr, setLTHR] = useState("");

  const handleAgeChange = (e) => {
    setAge(e.target.value);
  };

  const handleLTHRChange = (e) => {
    setLTHR(e.target.value);
  };

  const calculateHeartRateZones = () => {
    const zoneRanges = {
      Zone1: { max: 0.8 },
      Zone2: { min: 0.81, max: 0.89 },
      Zone3: { min: 0.9, max: 0.93 },
      Zone4: { min: 0.94, max: 0.99 },
      Zone5: { min: 1.0 },
    };

    const heartRateZones = {};
    for (const [zone, range] of Object.entries(zoneRanges)) {
      const minBPM = range.min ? Math.round(lthr * range.min) : undefined;
      const maxBPM = Math.round(lthr * range.max);
      heartRateZones[zone] = { min: minBPM, max: maxBPM };
    }

    return heartRateZones;
  };

  const getColorForZone = (index) => {
    const colors = ["pink", "yellow", "lightblue", "orange", "green"];
    return colors[index % colors.length];
  };

  const ageNumber = parseInt(age, 10);
  const lthrNumber = parseInt(lthr, 10);
  const heartRateZones = calculateHeartRateZones(ageNumber, lthrNumber);

  return (
    <>
      <div>
        <h4>Heart Rate Zone Calculator: Lactate Threshold Zones</h4>
        <br />
        <form
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <label>
            Enter Age:
            <input type="number" value={age} onChange={handleAgeChange} />
          </label>
          <br />
          <label>
            Enter LTHR:
            <input type="number" value={lthr} onChange={handleLTHRChange} />
          </label>
          <br />
        </form>
      </div>
      {age && lthr && (
        <div>
          <br />
          <h6>
            Heart Rate Zones for a {ageNumber}-year-old with an LTHR of{" "}
            {lthrNumber} bpm
          </h6>
          <br />
          <table style={{ borderCollapse: "collapse", width: "90%" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black", background: "white" }}>
                  Zone
                </th>
                <th style={{ border: "1px solid black", background: "white" }}>
                  Min BPM
                </th>
                <th style={{ border: "1px solid black", background: "white" }}>
                  Max BPM
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(heartRateZones).map(
                ([zone, { min, max }], index) => (
                  <tr
                    key={zone}
                    style={{
                      border: "1px solid black",
                      background: getColorForZone(index),
                    }}
                  >
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {zone}
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {min || "-"}
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {max}
                    </th>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

function calculateZoladzZones(lthr) {
    // Constants for percentage ranges of heart rate zones
    const zoneRanges = {
      "Zone 1": { max: 0.60 },
      "Zone 2": { min: 0.60, max: 0.70 },
      "Zone 3": { min: 0.70, max: 0.80 },
      "Zone 4": { min: 0.80, max: 0.90 },
      "Zone 5": { min: 0.90 },
    };
  
    // Calculate heart rate zones
    const heartRateZones = {};
    for (const [zone, range] of Object.entries(zoneRanges)) {
      const minBPM = range.min ? Math.round(lthr * range.min) : undefined;
      const maxBPM = Math.round(lthr * range.max);
      heartRateZones[zone] = { min: minBPM, max: maxBPM };
    }
  
    return heartRateZones;
  }
  
  const LTHR = () => {
    const [lthr, setLthr] = useState('');
    const [result, setResult] = useState({});
  
    const handleInputChange = (e) => {
      setLthr(e.target.value);
    };
  
    const handleCalculate = () => {
      const parsedLthr = parseFloat(lthr);
      if (!isNaN(parsedLthr) && parsedLthr > 0) {
        const calculatedResult = calculateZoladzZones(parsedLthr);
        setResult(calculatedResult);
      } else {
        setResult({});
      }
    };
  
    return (
      <div>
        <h4>Heart Rate Zones: Zoladz Zones</h4>
        <br/>
        <label>
          Enter LTHR (in BPM):
          <input type="number" value={lthr} onChange={handleInputChange} />
        </label>
        <br/> <br/>
        <Button appearance="primary" color="violet" onClick={handleCalculate}>Calculate</Button>
        <br/> <br/>
        {Object.keys(result).length > 0 && (
        <table style={{ borderCollapse: 'collapse', width: '90%' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Zone</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Min BPM</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Max BPM</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(result).map(([zone, bpmRange]) => (
              <tr key={zone}>
                <td style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#e6f7ff' }}>{zone}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#e6f7ff' }}>{bpmRange.min || ''}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#e6f7ff' }}>{bpmRange.max}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      </div>
    );
  };
export default function ExercisePhysiologyAlgo({ isSidebarExpanded }) {
  return (
    <div
      style={{
        marginTop: "2rem",
        marginBottom: "2rem",
        transition: "margin-left 0.3s",
        marginLeft: isSidebarExpanded ? "16.5rem" : "6.5rem",
      }}
    >
      <HeartRateZones />
      <br />
      <KarvonenHeartRateZones />
      <br />
      <HeartRateZoneCalculator />
      <br/>
      <LTHR/>
    </div>
   
  );
}
