import React from 'react';
import { Bar } from 'react-chartjs-2';
import styles from "./injury.module.css"
import { Divider } from 'rsuite';

const SimpleBarChart = ({ data ,label}) => {
  // Extracting titles and counts from data
  const titles = data.map(item => item.title);
  const counts = data.map(item => item.count);

  // Generating random colors for each title
  const colors = ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)', 'rgba(75, 192, 192, 0.6)', 'rgba(153, 102, 255, 0.6)'];

  // Chart data
  const chartData = {
    labels: titles,
    datasets: [
      {
        label: `${label} Count`,
       
        backgroundColor: colors,
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 1,
        data: counts,
        // barPercentage: 0.2 ,
        barThickness: 20,
        borderRadius: 30,
      borderSkipped: "bottom",

      }
    ]
  };

  // Chart options
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div >
        <h4>{label}</h4>
      <Bar
        data={chartData}
        options={chartOptions}
      />
   
    </div>
  );
};

export default SimpleBarChart;
