import React, { useCallback, useEffect, useState } from "react";
import DashBoardTopNav from "../../../components/DashBoardTopNav";
// import Sidebar from "../../../components/CustomSideNav";
import styles from "./../../sportsscience/science.module.css";
import { HttpService } from "../../../services/http.service";
import {
    ATHLETE_PROFILE_API_URL,
    // EVALUATION_DEPARTMENTS,
} from "../../../services/API";
import Speedometer from "react-d3-speedometer";
// import { useNavigate } from "react-router-dom";
import Label from "../../../components/Label";
import {  LinearProgress } from "@mui/material";
import { Color } from "../../../utils/Colors";
// import { DataGrid } from "@mui/x-data-grid";
// import { Bar } from "react-chartjs-2";
import { FetchResultsByCatAthlete } from "../../../components/custmomhooks/CustomHooks";
import { SelectPicker } from "rsuite";
import { DataGrid } from "@mui/x-data-grid";

export default function Overall() {
    const [athletes, setAthletes] = useState([]);
    const [result, setResult] = useState([]);
    const [athlete, setAthlete] = useState([]);
    const [loading, setLoading] = useState(false);
    const [physiotherapy, setPhysiotherapy] = useState({
        painAssment: null,
        fms: null,
    });
    const [loadMonitoring, setLoadMonitoring] = useState({
        wellness: null,
    });
    const [strengthC, setStrength] = useState({

        flexibility: null,
        balance: null,
        enurance: null,
        speed: null,
        agility: null,
        strength: null,
        power: null,
        coordination: null,
        reactionTime: null,
    });
    useEffect(() => {
        const fetchAthlete = async () => {
            try {
                const response = await HttpService.get(
                    `${ATHLETE_PROFILE_API_URL}`,
                    {},
                    false
                );
                const modifiedAthlete = response.data.results.map((ath) => ({
                    value: ath.id,
                    label: ath.name,
                }));
                setAthletes(modifiedAthlete);
            } catch (error) {
                console.warn(error);
            }
        };

        fetchAthlete();
    }, []);


    const fetchResultsPhysio = useCallback(async () => {
        const pain = await FetchResultsByCatAthlete({
            dept: 1,
            cat: 3,
            athlete
        });
        setPhysiotherapy((prevPhysio) => ({
            ...prevPhysio,
            painAssment: pain,
        }));
        const func = await FetchResultsByCatAthlete({
            dept: 1,
            cat: 41,
            athlete
        });
        setPhysiotherapy((prevPhysio) => ({
            ...prevPhysio,
            fms: func,
        }));
    }, [athlete, setPhysiotherapy]);

    const fetchResultsLoad = useCallback(async () => {
        const wel = await FetchResultsByCatAthlete({
            dept: 9,
            cat: 99,
            athlete
        });
        setLoadMonitoring((prevLoad) => ({
            ...prevLoad,
            wellness: wel,
        }));
    }, [athlete, setLoadMonitoring])
    const fetchResultsSC = useCallback(async () => {
        const bc = await FetchResultsByCatAthlete({
            dept: 3,
            cat: 56,
            athlete,
        });
        setStrength((prevStrength) => ({
            ...prevStrength,
            bodyComposition: bc,
        }));
        const cardioE = await FetchResultsByCatAthlete({
            dept: 3,
            cat: 58,
            athlete,
        });
        setStrength((prevStrength) => ({
            ...prevStrength,
            cardioVascular: cardioE,
        }));
        const flex = await FetchResultsByCatAthlete({
            dept: 3,
            cat: 66,
            athlete,
        });
        setStrength((prevStrength) => ({
            ...prevStrength,
            flexibility: flex,
        }));
        const bal = await FetchResultsByCatAthlete({
            dept: 3,
            cat: 63,
            athlete,
        });
        setStrength((prevStrength) => ({
            ...prevStrength,
            balance: bal,
        }));
        const endur = await FetchResultsByCatAthlete({
            dept: 3,
            cat: 57,
            athlete,
        });
        setStrength((prevStrength) => ({
            ...prevStrength,
            enurance: endur,
        }));
        const sp = await FetchResultsByCatAthlete({
            dept: 3,
            cat: 62,
            athlete,
        });
        setStrength((prevStrength) => ({
            ...prevStrength,
            speed: sp,
        }));
        const agilityy = await FetchResultsByCatAthlete({
            dept: 3,
            cat: 61,
            athlete,
        });
        setStrength((prevStrength) => ({
            ...prevStrength,
            agility: agilityy,
        }));
        const str = await FetchResultsByCatAthlete({
            dept: 3,
            cat: 64,
            athlete,
        });
        setStrength((prevStrength) => ({
            ...prevStrength,
            strength: str,
        }));
        const po = await FetchResultsByCatAthlete({
            dept: 3,
            cat: 65,
            athlete,
        });
        setStrength((prevStrength) => ({
            ...prevStrength,
            power: po,
        }));
        const rast = await FetchResultsByCatAthlete({
            dept: 3,
            cat: 60,
            athlete,
        });
        setStrength((prevStrength) => ({
            ...prevStrength,
            rast: rast,
        }));
        const coord = await FetchResultsByCatAthlete({
            dept: 3,
            cat: 67,
            athlete,
        });
        setStrength((prevStrength) => ({
            ...prevStrength,
            coordination: coord,
        }));
        const rt = await FetchResultsByCatAthlete({
            dept: 3,
            cat: 68,
            athlete,
        });
        setStrength((prevStrength) => ({
            ...prevStrength,
            reactionTime: rt,
        }));
    }, [athlete, setStrength])
    const memoPhysio = useCallback(() => fetchResultsPhysio(athlete, setPhysiotherapy), [athlete, setPhysiotherapy, fetchResultsPhysio]);
    const memoMon = useCallback(() => fetchResultsLoad(athlete, setLoadMonitoring), [athlete, setLoadMonitoring, fetchResultsLoad]);
    const memoStr = useCallback(() => fetchResultsSC(athlete, setStrength), [athlete, setStrength, fetchResultsSC]);

    useEffect(() => {
        if (athlete?.length > 0) {
            setLoading(true);
            Promise.all([
                memoPhysio(),
                memoMon(),
                memoStr()
            ])
                .then(() => setLoading(false))
                .catch((error) => {
                    // handle error
                    setLoading(false);
                });
        }
    }, [athlete, setLoading, memoPhysio, memoMon, memoStr]);



    function calculateOverallAverage(data) {
        if (data === null || !Array.isArray(data)) {
            return;
        }

        let totalSum = 0;
        let totalCount = 0;

        // Iterate through the data array
        data.forEach((entry) => {
            const value = entry.value;

            // Add the value to the total sum
            totalSum += value;
            totalCount++;
        });

        // Calculate the overall average value
        const overallAverage = (totalSum / totalCount);

        return overallAverage;
    }
    function calculateOverallAverageW(data) {
        if (data === null || !Array.isArray(data)) {
            return;
        }

        let totalSum = 0;
        let totalCount = 0;

        // Iterate through the data array
        data.forEach((entry) => {
            const value = entry.value;

            // Add the value to the total sum
            totalSum += value;
            totalCount++;
        });

        // Calculate the overall average value
        const overallAverage = (totalSum / totalCount);

        return overallAverage;
    }

    const fms = calculateOverallAverage(physiotherapy?.fms) * 7;
    const wellness = (calculateOverallAverageW(loadMonitoring?.wellness) * 7).toFixed(2)
    const agility = calculateOverallAverage(strengthC?.agility)
    const flexibilityIndex = (((calculateOverallAverage(strengthC?.flexibility) - (-20)) / 50) * 100).toFixed(2)
    const enduranceIndex = calculateOverallAverage(strengthC?.enurance)
    const powerIndex = calculateOverallAverage(strengthC?.power)
    const wellnessScore = wellness === undefined ? null : (wellness / 70) * 100;

    const agiliyScore = (100 * Math.exp(-0.05 * agility)).toFixed(2)
    const healthIndex = ((wellnessScore + parseInt(flexibilityIndex) + ((fms / 21) * 100)) / 3).toFixed(2)
    const skillIndex = ((parseFloat(agiliyScore) + enduranceIndex + powerIndex) / 3).toFixed(2)

    function calculatePainScoresByDate(dataArray) {
        if (!Array.isArray(dataArray)) {
            // console.error('Input is not an array');
            return;
        }

        const painScoresByDate = {};
        let totalScoreObtained = 0;
        let totalFullScore = 0;

        dataArray.forEach((entry) => {
            const { date, field_name, value } = entry;

            if (!painScoresByDate[date]) {
                painScoresByDate[date] = {
                    date,
                    entries: [],
                    totalScore: 0,
                    fullScore: 0,
                };
            }

            let painScore = 0;
            let fullScore = 0;

            if (field_name === "Nature") {
                switch (value) {
                    default:
                        console.warn(`Unexpected value for 'Nature': ${value}`);
                        break;
                    case "Burning":
                        painScore += 5 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Shooting":
                        painScore += 5 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Tingling":
                        painScore += 5 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Radiating":
                        painScore += 10 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Lancinating":
                        painScore += 5 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Numbness":
                        painScore += 5 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Achy":
                        painScore += 5 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Throbbing":
                        painScore += 10 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Dull":
                        painScore += 3 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Squeezing":
                        painScore += 10 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Pressure":
                        painScore += 10 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Cramping":
                        painScore += 5 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Distention":
                        painScore += 10 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Deep":
                        painScore += 10 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Stretching":
                        painScore += 3 * 2;
                        fullScore += 10 * 2;
                        break;
                }
            } else if (field_name === "Type") {
                switch (value) {
                    default:
                        console.warn(`Unexpected value for 'Type': ${value}`);
                        break;
                    case "Neuropathic pain":
                        painScore += 5 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Somatic Pain":
                        painScore += 3 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Visceral Pain":
                        painScore += 10 * 2;
                        fullScore += 10 * 2;
                        break;
                }
            } else if (field_name === "Severity") {
                switch (value) {
                    default:
                        console.warn(`Unexpected value for 'Severity': ${value}`);
                        break;
                    case "MIld":
                        painScore += 3 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Moderate":
                        painScore += 5 * 2;
                        fullScore += 10 * 2;
                        break;
                    case "Severe":
                        painScore += 10 * 2;
                        fullScore += 10 * 2;
                        break;
                }
            } else if (field_name === "Diurnal Variation") {
                switch (value) {
                    default:
                        console.warn(`Unexpected value for 'Diurnal Variation': ${value}`);

                        break;
                }
            } else if (field_name === "Numeric Pain Rating Scale") {
                painScore += Number(value) * 3;
                fullScore += 10 * 3;
            } else if (field_name === "Duration") {
                switch (value) {
                    default:
                        console.warn(`Unexpected value for 'Duration': ${value}`);
                        break;
                    case "Intermittent":
                        painScore += 5;
                        fullScore += 10;
                        break;
                    case "Continous":
                        painScore += 10;
                        fullScore += 10;
                        break;
                }
            }

            painScoresByDate[date].entries.push({
                id: entry.id,
                field_name,
                painScore,
                fullScore,
            });
            painScoresByDate[date].totalScore += painScore;
            painScoresByDate[date].fullScore += fullScore;
            totalScoreObtained += painScore;
            totalFullScore += fullScore;
        });

        const painScoresByDateWithKey = Object.values(painScoresByDate).map(
            ({ date, entries, totalScore, fullScore }) => ({
                date,
                entries,
                totalScore,
                fullScore,
            })
        );

        setResult({
            painScoresByDate: painScoresByDateWithKey,
            totalScoreObtained,
            totalFullScore,
        });
    }
    useEffect(() => {
        calculatePainScoresByDate(physiotherapy?.painAssment);
    }, [physiotherapy.painAssment]);





    const performanceRatings = {
        Excellent: { max: 100, color: "#a5d6a7" }, // Green
        "Very Good": { min: 75, max: 99, color: "#b9f6ca" }, // Light Green
        Good: { min: 50, max: 74, color: "#ffe082" }, // Yellow
        Fair: { min: 25, max: 49, color: "#ffcc80" }, // Orange
        Poor: { min: 0, max: 24, color: "#ffcdd2" }, // Red
    };


    const excludedFieldNames = [
        "Location",
        "Aggrevating Factors",
        "Relieving Factors",
        "Diurnal Variation",
    ];
    const ScrollableLabels = ({ painScoresByDate }) => {
        if (
            !painScoresByDate ||
            !Array.isArray(painScoresByDate.painScoresByDate)
        ) {
            // Handle the case where painScoresByDate is not an array
            return null;
        }
        // const dates = painScoresByDate.painScoresByDate.map(
        //     (dateItem) => dateItem.date
        // );
        // const totalScores = painScoresByDate.painScoresByDate.map(
        //     (dateItem) => dateItem.totalScore
        // );
        // const totalScoresObtained = painScoresByDate.painScoresByDate.map(
        //     (dateItem) => dateItem.fullScore
        // );

        // Data for the bar chart
        // const chartData = {
        //     labels: dates,
        //     datasets: [
        //         {
        //             label: "Total Score",
        //             data: totalScores,
        //             backgroundColor: "rgba(75, 192, 192, 0.6)", // Adjust the color as needed
        //             barThickness: 10,
        //         },
        //         {
        //             label: "Total Score Obtained",
        //             data: totalScoresObtained,
        //             backgroundColor: "rgba(255, 99, 132, 0.6)", // Adjust the color as needed
        //             barThickness: 10,
        //         },
        //     ],
        // };
        return (
            <div>
                {painScoresByDate.painScoresByDate.map((dateItem, dateIndex) => {
                    if (!dateItem || !Array.isArray(dateItem.entries)) {
                        // Handle the case where dateItem or its entries are not as expected
                        return (
                            null
                        );
                    }

                    return (
                        <div
                            key={dateIndex}
                            className={styles.label_2}
                            style={{ display: "flex", flex: 1, justifyContent: "left" }}
                        >
                            <Label sx={{ marginTop: "1rem" }} color="warning">
                                {dateItem.date}
                            </Label>
                            {dateItem.entries.map((entryItem, entryIndex) => {
                                // Exclude specific field_names
                                if (excludedFieldNames.includes(entryItem.field_name)) {
                                    return null; // Skip rendering for excluded fields
                                }

                                return (
                                    <div key={entryIndex}>
                                        <Label color="primary">{entryItem.field_name}:</Label>
                                        <Label sx={{ marginTop: "1rem" }} color={"secondary"}>
                                            {entryItem.painScore}
                                        </Label>
                                    </div>
                                );
                            })}
                            <br />
                            <div>
                                <Label color="info">Total Score:</Label>
                                <Label sx={{ marginTop: "1rem" }} color={"secondary"}>
                                    {dateItem.totalScore}
                                </Label>
                            </div>
                            <br />
                            <div>
                                <Label color="info">Full Score:</Label>
                                <Label sx={{ marginTop: "1rem" }} color={"secondary"}>
                                    {dateItem.fullScore}
                                </Label>
                            </div>
                            <br />
                            <div>
                                <Label sx={{ marginTop: "1rem" }} color="info">
                                    %:
                                </Label>

                                <Label
                                    sx={{ marginTop: "1rem" }}
                                    color={
                                        ((dateItem.totalScore / dateItem.fullScore) * 100).toFixed(
                                            2
                                        ) >= 75
                                            ? "error"
                                            : (
                                                (dateItem.totalScore / dateItem.fullScore) *
                                                100
                                            ).toFixed(2) < 75 &&
                                                (
                                                    (dateItem.totalScore / dateItem.fullScore) *
                                                    100
                                                ).toFixed(2) > 50
                                                ? "warning"
                                                : "success"
                                    }
                                >
                                    {((dateItem.totalScore / dateItem.fullScore) * 100).toFixed(
                                        2
                                    )}
                                    %
                                </Label>
                            </div>
                        </div>
                    );
                })}
                <br />
                <div
                    style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}
                >
                    <div>
                        <Label color="info">Total Score Obtained:</Label>
                        <Label color={"success"}>
                            {painScoresByDate.totalScoreObtained}
                        </Label>
                    </div>
                    <br />
                    <div>
                        <Label color="info">Total Full Score:</Label>
                        <Label color={"success"}>{painScoresByDate.totalFullScore}</Label>
                    </div>

                    <br />
                    <div>
                        <Label color="info">%:</Label>
                        <Label
                            color={
                                (
                                    (painScoresByDate.totalScoreObtained /
                                        painScoresByDate.totalFullScore) *
                                    100
                                ).toFixed(2) >= 75
                                    ? "error"
                                    : (
                                        (painScoresByDate.totalScoreObtained /
                                            painScoresByDate.totalFullScore) *
                                        100
                                    ).toFixed(2) < 75 &&
                                        (
                                            (painScoresByDate.totalScoreObtained /
                                                painScoresByDate.totalFullScore) *
                                            100
                                        ).toFixed(2) > 50
                                        ? "warning"
                                        : "success"
                            }
                        >
                            {(
                                (painScoresByDate.totalScoreObtained /
                                    painScoresByDate.totalFullScore) *
                                100
                            ).toFixed(2)}
                            %
                        </Label>
                    </div>
                </div>

                {/* <div style={{height:"50%%", width:"50%"}}>
                    <Bar data={chartData} options={{
        indexAxis: 'y', // Display data along the y-axis
        scales: {
            x: {
                stacked: false, // Enable stacking for the x-axis
            },
            y: {
                stacked: false, // Enable stacking for the y-axis
            },
        },
    }}/>
                </div> */}
            </div>
        );
    };

    const renderScrollableLabels = (data) => {
        if (data === null) {
            return null; // Don't render anything if data is null
        }

        return <ScrollableLabels painScoresByDate={data} />;
    };

    function extractData(data) {
        if (!data || !Array.isArray(data)) {
            return [];
        }

        let idCounter = 1; // Initialize ID counter

        return data.map(item => {
            const { date, field_name, value } = item;
            const id = idCounter++; // Increment ID counter for each item
            return { id, date, field_name, value };
        });
    }

    // Extracted data
    const fmsextractedData = extractData(physiotherapy?.fms);
    function extractDataMobilty(data) {
        if (!data || !Array.isArray(data)) {
            return [];
        }

        let idCounter = 1; // Initialize ID counter

        return data.reduce((accumulator, item) => {
            const { date, field_name, value } = item;
            if (["Deep Squat", "Hurdle Step", "In-line Lunge", "Shoulder Mobility"].includes(field_name)) {
                const id = idCounter++; // Increment ID counter for each item
                accumulator.push({ id, date, field_name, value });
            }
            return accumulator;
        }, []);
    }
    function extractDataStability(data) {
        if (!data || !Array.isArray(data)) {
            return [];
        }

        let idCounter = 1; // Initialize ID counter

        return data.reduce((accumulator, item) => {
            const { date, field_name, value } = item;
            if (["Deep Squat", "Hurdle Step", "In-line Lunge", "Trunk Stability Push-up", "Rotary Stability"].includes(field_name)) {
                const id = idCounter++; // Increment ID counter for each item
                accumulator.push({ id, date, field_name, value });
            }
            return accumulator;
        }, []);
    }
    const mobility = extractDataMobilty(physiotherapy?.fms)
    const stability = extractDataStability(physiotherapy?.fms)
    const mobility_score = calculateOverallAverage(mobility)
    const stability_score = calculateOverallAverage(stability)


    const fmsScore = isNaN(fms) ? null : (fms / 21) * 100;
    let mobility__ = parseInt(((mobility_score / 3) * 100).toFixed(2))
    const mobilityRating =
        mobility_score !== null
            ? mobility__ === 100
                ? "Excellent "
                : mobility__ >= 75 && mobility__ <= 99
                    ? "Very Good"
                    : mobility__ >= 50 && mobility__ <= 74
                        ? "Good"
                        : mobility__ >= 25 && mobility__ <= 49
                            ? "Fair"
                            : "Poor"
            : null;
    let stability__ = parseInt(((stability_score / 3) * 100).toFixed(2))

    const stabilityRating =
        stability_score !== null
            ? stability__ === 100
                ? "Excellent "
                : stability__ >= 75 && stability__ <= 99
                    ? "Very Good"
                    : stability__ >= 50 && stability__ <= 74
                        ? "Good"
                        : stability__ >= 25 && stability__ <= 49
                            ? "Fair"
                            : "Poor"
            : null;
    const fmsRating =
        fmsScore !== null
            ? fmsScore === 100
                ? "Excellent "
                : fmsScore >= 75 && fmsScore <= 99
                    ? "Very Good"
                    : fmsScore >= 50 && fmsScore <= 74
                        ? "Good"
                        : fmsScore >= 25 && fmsScore <= 49
                            ? "Fair"
                            : "Poor"
            : null;
    const wellnessRating =
        wellnessScore !== null
            ? wellnessScore === 100
                ? "Excellent "
                : wellnessScore >= 75 && wellnessScore <= 99
                    ? "Very Good"
                    : wellnessScore >= 50 && wellnessScore <= 74
                        ? "Good"
                        : wellnessScore >= 25 && wellnessScore <= 49
                            ? "Fair"
                            : "Poor"
            : null;

    const healthIndexRating =
        healthIndex !== null
            ? healthIndex === 100
                ? "Excellent "
                : healthIndex >= 75 && healthIndex <= 99
                    ? "Very Good"
                    : healthIndex >= 50 && healthIndex <= 74
                        ? "Good"
                        : healthIndex >= 25 && healthIndex <= 49
                            ? "Fair"
                            : "Poor"
            : null;

    const skillIndexRating =
        skillIndex !== null
            ? skillIndex === 100
                ? "Excellent "
                : skillIndex >= 75 && skillIndex <= 99
                    ? "Very Good"
                    : skillIndex >= 50 && skillIndex <= 74
                        ? "Good"
                        : skillIndex >= 25 && skillIndex <= 49
                            ? "Fair"
                            : "Poor"
            : null;
    const columns = [
        { field: 'date', headerName: 'Date', width: 150 },
        { field: 'field_name', headerName: 'Field Name', width: 200 },
        { field: 'value', headerName: 'Value', width: 150 },
        {
            field: 'progress',
            headerName: 'Indicator',
            width: 500,
            renderCell: (params) => {

                const value = parseFloat(params.row.value); // Assuming value is numeric

                const percentage = (value / 3) * 100; // Calculate percentage
                let color;
                if (percentage < 50) {
                    color = 'error';
                } else if (percentage >= 50 && percentage < 75) {
                    color = 'warning';
                } else {
                    color = 'success';
                }

                return (
                    <div style={{ width: '100%' }}>
                        <LinearProgress variant="determinate" value={percentage} color={color} />
                        <span>{`${Math.round(percentage)}%`}</span> {/* Display percentage */}
                    </div>
                );
            },
        },
    ];
    const columnsWellness = [
        { field: 'date', headerName: 'Date', width: 150 },
        { field: 'field_name', headerName: 'Field Name', width: 200 },
        { field: 'value', headerName: 'Value', width: 150 },
        {
            field: 'progress',
            headerName: 'Indicator',
            width: 500,
            renderCell: (params) => {

                const value = parseFloat(params.row.value); // Assuming value is numeric
                const field = params.row.field_name

                const percentage = (value / 10) * 100; // Calculate percentage
                let color;

                if (field.includes("Fatigue") || field.includes("Soreness") || field.includes("Stress")) {
                    if (percentage < 50) {
                        color = 'success';
                    } else if (percentage >= 50 && percentage < 75) {
                        color = 'warning';
                    } else {
                        color = 'error';
                    }
                } else {
                    if (percentage < 50) {
                        color = 'error';
                    } else if (percentage >= 50 && percentage < 75) {
                        color = 'warning';
                    } else {
                        color = 'success';
                    }
                }


                return (
                    <div style={{ width: '100%' }}>
                        <LinearProgress variant="determinate" value={percentage} color={color} />
                        <span>{`${Math.round(percentage)}%`}</span> {/* Display percentage */}
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <div style={{ position: 'relative', zIndex: 9999 }}>
  <DashBoardTopNav header={"Human Body Performance Dashboard"} />
</div>
           
            <div style={{marginTop:"40px"}}>
                <SelectPicker

                    label={athlete?.length <= 0 ? "Select Athlete" : athlete?.name}
                    data={athletes}
                    onSelect={(value) => setAthlete(value)}
                    onClean={() => {
                        setAthlete([])
                    }}
                    style={{ width: 230, height: "22px", margin: "20px" }}
                />
            </div>

            <div className={styles.main_container}>

                {loading ? ( // Display loading indicator if loading state is true
                            <h6>Loading...</h6>
                          ) : 
                <div className={styles.evaluation_area}>
                    <div className={styles.evaluation_area_2}>

                        {Object.entries(performanceRatings).map(([rating, data], index) => (
                            <div
                                className={styles.rating}
                                key={index}
                                style={{ backgroundColor: data.color }}
                            >
                                <div className={styles.rating2}>

                                    <label className={styles.label_card}>{rating}</label>
                                    <label className={styles.label_card}>
                                        {data.min !== undefined ? data.min + "-" + data.max : data.max}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div>{physiotherapy?.painAssment === null ? null : <Label sx={{ marginLeft: "16px", marginTop: "16px", fontSize: 20 }}>Pain Score</Label>}  {physiotherapy?.painAssment === null ? null : renderScrollableLabels(result)}</div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}
                    >
                        <div style={{ marginTop: "10px", }}>
                            <Speedometer
                                value={
                                    isNaN(healthIndex)
                                        ? 0
                                        : healthIndex
                                } // Set the value of the gauge
                                minValue={0} // Set the minimum value
                                maxValue={100} // Set the maximum value
                                segmentColors={[
                                    "#ffcdd2",
                                    "#ffcc80",
                                    "#ffe082",
                                    "#b9f6ca",
                                    "#a5d6a7",
                                ]}
                                needleColor={Color.Color_D} // Set the color of the needle
                                needleHeightRatio={0.7} // Set the height ratio of the needle
                                ringWidth={30} // Set the width of the ring
                                segments={5} // Set the number of segments
                                textColor="black" // Set the color of the text
                                needleTransitionDuration={1000} // Set the transition duration of the needle
                                currentValueText="Health Related Score" // Set the text displayed below the gauge
                                customSegmentStops={[0, 25, 50, 75, 100]} // Set custom segment stops
                            />
                            <div style={{ marginTop: "-120px" }}>
                                <div className={styles.card}>
                                    <Label>
                                        Health Related Obtained:{" "}
                                        {isNaN(healthIndex) ? "No data" : healthIndex}
                                    </Label>
                                </div>
                                <div className={styles.card}>
                                    <Label>Total Health Related : 100</Label>
                                </div>
                                <div className={styles.card}>
                                    <Label>
                                        Health Related Rating:{" "}
                                        {healthIndexRating}
                                    </Label>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "10px", marginLeft: "24px" }}>
                            <Speedometer
                                value={
                                    isNaN(skillIndex)
                                        ? 0
                                        : skillIndex
                                } // Set the value of the gauge
                                minValue={0} // Set the minimum value
                                maxValue={100} // Set the maximum value
                                segmentColors={[
                                    "#ffcdd2",
                                    "#ffcc80",
                                    "#ffe082",
                                    "#b9f6ca",
                                    "#a5d6a7",
                                ]}
                                needleColor={Color.Color_D} // Set the color of the needle
                                needleHeightRatio={0.7} // Set the height ratio of the needle
                                ringWidth={30} // Set the width of the ring
                                segments={5}// Set the number of segments
                                textColor="black" // Set the color of the text
                                needleTransitionDuration={1000} // Set the transition duration of the needle
                                currentValueText="Skill Related Score" // Set the text displayed below the gauge
                                customSegmentStops={[0, 25, 50, 75, 100]} // Set custom segment stops
                            />
                            <div style={{ marginTop: "-120px" }}>
                                <div className={styles.card}>
                                    <Label>
                                        Skill Related Score Obtained:{" "}
                                        {isNaN(skillIndex) ? "No data" : skillIndex}
                                    </Label>
                                </div>
                                <div className={styles.card}>
                                    <Label>Total Skill Related Score : 100</Label>
                                </div>
                                <div className={styles.card}>
                                    <Label>
                                        Skill Related Rating:{" "}
                                        {skillIndexRating}
                                    </Label>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "10px", marginLeft: "24px" }}>
                            <Speedometer
                                value={
                                    isNaN(mobility_score)
                                        ? 0
                                        : (mobility_score / 3) * 100
                                } // Set the value of the gauge
                                minValue={0} // Set the minimum value
                                maxValue={100} // Set the maximum value
                                segmentColors={[
                                    "#ffcdd2",
                                    "#ffcc80",
                                    "#ffe082",
                                    "#b9f6ca",
                                    "#a5d6a7",
                                ]}
                                needleColor={Color.Color_D} // Set the color of the needle
                                needleHeightRatio={0.7} // Set the height ratio of the needle
                                ringWidth={30} // Set the width of the ring
                                segments={5}// Set the number of segments
                                textColor="black" // Set the color of the text
                                needleTransitionDuration={1000} // Set the transition duration of the needle
                                currentValueText="Mobility Index Score" // Set the text displayed below the gauge
                                customSegmentStops={[0, 25, 50, 75, 100]} // Set custom segment stops
                            />
                            <div style={{ marginTop: "-120px" }}>
                                <div className={styles.card}>
                                    <Label>
                                        Mobility Index Obtained:{" "}
                                        {isNaN(fms) ? "No data" : ((mobility_score / 3) * 100).toFixed(2)}
                                    </Label>
                                </div>
                                <div className={styles.card}>
                                    <Label>Total Mobility Index : 100</Label>
                                </div>
                                <div className={styles.card}>
                                    <Label>
                                        Mobility Performance Rating:{" "}
                                        {mobilityRating}
                                    </Label>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: "10px", marginLeft: "24px" }}>
                            <Speedometer
                                value={
                                    isNaN(stability_score)
                                        ? 0
                                        : ((stability_score / 3) * 100)
                                } // Set the value of the gauge
                                minValue={0} // Set the minimum value
                                maxValue={100} // Set the maximum value
                                segmentColors={[
                                    "#ffcdd2",
                                    "#ffcc80",
                                    "#ffe082",
                                    "#b9f6ca",
                                    "#a5d6a7",
                                ]}
                                needleColor={Color.Color_D} // Set the color of the needle
                                needleHeightRatio={0.7} // Set the height ratio of the needle
                                ringWidth={30} // Set the width of the ring
                                segments={5}// Set the number of segments
                                textColor="black" // Set the color of the text
                                needleTransitionDuration={1000} // Set the transition duration of the needle
                                currentValueText="Stability Index Score" // Set the text displayed below the gauge
                                customSegmentStops={[0, 25, 50, 75, 100]} // Set custom segment stops
                            />
                            <div style={{ marginTop: "-120px" }}>
                                <div className={styles.card}>
                                    <Label>
                                        Stability Index Obtained:{" "}
                                        {isNaN(wellness) ? "No data" : ((stability_score / 3) * 100).toFixed(2)}
                                    </Label>
                                </div>
                                <div className={styles.card}>
                                    <Label>Total Stability Index : 100</Label>
                                </div>
                                <div className={styles.card}>
                                    <Label>
                                        Stability Index Rating:{" "}
                                        {stabilityRating}
                                    </Label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: "30px"
                        }}
                    >

                        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                            <Speedometer
                                value={
                                    isNaN(fms)
                                        ? 0
                                        : (fms / 21) * 100
                                } // Set the value of the gauge
                                minValue={0} // Set the minimum value
                                maxValue={100} // Set the maximum value
                                segmentColors={[
                                    "#ffcdd2",
                                    "#ffcc80",
                                    "#ffe082",
                                    "#b9f6ca",
                                    "#a5d6a7",
                                ]}
                                needleColor={Color.Color_D} // Set the color of the needle
                                needleHeightRatio={0.7} // Set the height ratio of the needle
                                ringWidth={30} // Set the width of the ring
                                segments={5}// Set the number of segments
                                textColor="black" // Set the color of the text
                                needleTransitionDuration={1000} // Set the transition duration of the needle
                                currentValueText="FMS Score" // Set the text displayed below the gauge
                                customSegmentStops={[0, 25, 50, 75, 100]} // Set custom segment stops
                            />
                            <div style={{ marginTop: "-120px" }}>
                                <div className={styles.card}>
                                    <Label>
                                        Functional Movement Score Obtained:{" "}
                                        {isNaN(fms) ? "No data" : fms}
                                    </Label>
                                </div>
                                <div className={styles.card}>
                                    <Label>Total Functional Movement Score : 21</Label>
                                </div>
                                <div className={styles.card}>
                                    <Label>
                                        FMS Performance Rating:{" "}
                                        {fmsRating}
                                    </Label>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: "20px", marginRight: "20px" }} >
                            <div style={{ height: 300, width: '100%' }}>
                                <DataGrid
                                    rows={fmsextractedData}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 4,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[4]}
                                    rowsPerPageOptions={[5]}
                                    getRowId={(row) => row.id}
                                />
                            </div>

                        </div>





                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: "30px"
                        }}
                    >

                        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                            <Speedometer
                                value={
                                    isNaN(wellness)
                                        ? 0
                                        : (wellness / 70) * 100
                                } // Set the value of the gauge
                                minValue={0} // Set the minimum value
                                maxValue={100} // Set the maximum value
                                segmentColors={[
                                    "#ffcdd2",
                                    "#ffcc80",
                                    "#ffe082",
                                    "#b9f6ca",
                                    "#a5d6a7",
                                ]}
                                needleColor={Color.Color_D} // Set the color of the needle
                                needleHeightRatio={0.7} // Set the height ratio of the needle
                                ringWidth={30} // Set the width of the ring
                                segments={5}// Set the number of segments
                                textColor="black" // Set the color of the text
                                needleTransitionDuration={1000} // Set the transition duration of the needle
                                currentValueText="Wellness Score" // Set the text displayed below the gauge
                                customSegmentStops={[0, 25, 50, 75, 100]} // Set custom segment stops
                            />
                            <div style={{ marginTop: "-120px" }}>
                                <div className={styles.card}>
                                    <Label>
                                        Wellness Score Obtained:{" "}
                                        {isNaN(wellness) ? "No data" : wellness}
                                    </Label>
                                </div>
                                <div className={styles.card}>
                                    <Label>Total Wellness Score : 70</Label>
                                </div>
                                <div className={styles.card}>
                                    <Label>
                                        Wellness Rating:{" "}
                                        {wellnessRating}
                                    </Label>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: "20px", marginRight: "20px" }} >
                            <div style={{ height: 300, width: '100%' }}>
                                <DataGrid
                                    rows={extractData(loadMonitoring?.wellness)}
                                    columns={columnsWellness}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 4,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[4]}
                                    rowsPerPageOptions={[5]}
                                    getRowId={(row) => row.id}
                                  
                                />
                            </div>

                        </div>





                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}
                    >
                        <div style={{ marginTop: "10px" }}>
                            <Speedometer
                                value={
                                    isNaN(agility)
                                        ? 0
                                        : (100 * Math.exp(-0.05 * agility)).toFixed(2)
                                } // Set the value of the gauge
                                minValue={0} // Set the minimum value
                                maxValue={100} // Set the maximum value
                                segmentColors={[
                                    "#ffcdd2",
                                    "#ffcc80",
                                    "#ffe082",
                                    "#b9f6ca",
                                    "#a5d6a7",
                                ]}
                                needleColor={Color.Color_D} // Set the color of the needle
                                needleHeightRatio={0.7} // Set the height ratio of the needle
                                ringWidth={30} // Set the width of the ring
                                segments={5}// Set the number of segments
                                textColor="black" // Set the color of the text
                                needleTransitionDuration={1000} // Set the transition duration of the needle
                                currentValueText="Change of Direction Index" // Set the text displayed below the gauge
                                customSegmentStops={[0, 25, 50, 75, 100]} // Set custom segment stops
                            />
                            <div style={{ marginTop: "-120px" }}>
                                <div className={styles.card}>
                                    <Label>
                                        Change of Direction Index:{" "}
                                        {isNaN(agility) ? "No data" : (100 * Math.exp(-0.05 * agility)).toFixed(2)}
                                    </Label>
                                </div>

                            </div>
                        </div>
                        <div style={{ marginTop: "10px", marginLeft: "24px" }}>
                            <Speedometer
                                value={
                                    isNaN(flexibilityIndex)
                                        ? 0
                                        : flexibilityIndex
                                } // Set the value of the gauge
                                minValue={0} // Set the minimum value
                                maxValue={100} // Set the maximum value
                                segmentColors={[
                                    "#ffcdd2",
                                    "#ffcc80",
                                    "#ffe082",
                                    "#b9f6ca",
                                    "#a5d6a7",
                                ]}
                                needleColor={Color.Color_D} // Set the color of the needle
                                needleHeightRatio={0.7} // Set the height ratio of the needle
                                ringWidth={30} // Set the width of the ring
                                segments={5} // Set the number of segments
                                textColor="black" // Set the color of the text
                                needleTransitionDuration={1000} // Set the transition duration of the needle
                                currentValueText="Flexibility Index Score" // Set the text displayed below the gauge
                                customSegmentStops={[0, 25, 50, 75, 100]} // Set custom segment stops
                            />
                            <div style={{ marginTop: "-120px" }}>
                                <div className={styles.card}>
                                    <Label>
                                        Flexibility Index:{" "}
                                        {isNaN(flexibilityIndex) ? "No data" : flexibilityIndex}
                                    </Label>
                                </div>

                            </div>
                        </div>
                        <div style={{ marginTop: "10px", marginLeft: "24px" }}>
                            <Speedometer
                                value={

                                    isNaN(enduranceIndex)

                                        ? 0
                                        : enduranceIndex
                                } // Set the value of the gauge
                                minValue={0} // Set the minimum value
                                maxValue={100} // Set the maximum value
                                segmentColors={[
                                    "#ffcdd2",
                                    "#ffcc80",
                                    "#ffe082",
                                    "#b9f6ca",
                                    "#a5d6a7",
                                ]}
                                needleColor={Color.Color_D} // Set the color of the needle
                                needleHeightRatio={0.7} // Set the height ratio of the needle
                                ringWidth={30} // Set the width of the ring
                                segments={5}// Set the number of segments
                                textColor="black" // Set the color of the text
                                needleTransitionDuration={1000} // Set the transition duration of the needle
                                currentValueText="Endurance Index Score" // Set the text displayed below the gauge
                                customSegmentStops={[0, 25, 50, 75, 100]} // Set custom segment stops
                            />
                            <div style={{ marginTop: "-120px" }}>
                                <div className={styles.card}>
                                    <Label>
                                        Endurance Index Score :{" "}
                                        {isNaN(enduranceIndex) ? "No data" : enduranceIndex}
                                    </Label>
                                </div>

                            </div>
                        </div>
                        <div style={{ marginTop: "10px", marginLeft: "24px", marginBottom: "20px" }}>
                            <Speedometer
                                value={
                                    isNaN(powerIndex)
                                        ? 0
                                        : powerIndex
                                } // Set the value of the gauge
                                minValue={0} // Set the minimum value
                                maxValue={100} // Set the maximum value
                                segmentColors={[
                                    "#ffcdd2",
                                    "#ffcc80",
                                    "#ffe082",
                                    "#b9f6ca",
                                    "#a5d6a7",
                                ]}
                                needleColor={Color.Color_D} // Set the color of the needle
                                needleHeightRatio={0.7} // Set the height ratio of the needle
                                ringWidth={30} // Set the width of the ring
                                segments={5}// Set the number of segments
                                textColor="black" // Set the color of the text
                                needleTransitionDuration={1000} // Set the transition duration of the needle
                                currentValueText="Power Index Score" // Set the text displayed below the gauge
                                customSegmentStops={[0, 25, 50, 75, 100]} // Set custom segment stops
                            />
                            <div style={{ marginTop: "-120px" }}>
                                <div className={styles.card}>
                                    <Label>
                                        Power Index Score:{" "}
                                        {isNaN(powerIndex) ? "No data" : powerIndex}
                                    </Label>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                }
            </div>
        </div>
    );
}
