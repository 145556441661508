import React from 'react';
import { Button } from 'rsuite';

function SampleAdminData() {
  const sampleData = [
    ['Id', 'Name','Age', 'Gender', 'Date of Birth','Class', 'Mobile Number','Email ID','State','Height in cm', 'Weight in Kg', 'Primary Sports','Level', 'Medal'],
    ['12233DC','Simran', '11', 'Female','MM/DD/YYYY','6','738236xxxx','simranshakeel12@gmail.com','Telangana','125','45','','',''],
    ['CS122', 'Shilpa','12', 'Female','MM/DD/YYYY','7','759730xxxx','shilpahansrajini@gmail.com','Rajasthan','145','55','Swimming','District','Bronze'],
    ['CDS2213','Swetank', '14','Male', 'MM/DD/YYYY','8','968155xxxx','swetankpathak@gmail.com','Jharkhand','155','67','Boxing','State','Silver'],
    ['CS133','Rohan', '16', 'Male','MM/DD/YYYY','9','701938xxxx','rohank@gmail.com','Karnataka','164','52','Athletics','State','Gold'],
  ];

  const downloadSample = () => {
    const csvData = encodeURI(`data:text/csv;charset=utf-8,${sampleData.map(row => row.join(',')).join('\n')}`);
    const link = document.createElement('a');
    link.setAttribute('href', csvData);
    link.setAttribute('download', 'adminsample.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      <Button appearance='ghost' color='blue' onClick={downloadSample}>Download Sample CSV</Button>
    </div>
  );
}

export default SampleAdminData;
