import React from "react";
import "./../landing/styles.css";
import {
  Form,
  Button,
  SelectPicker,
  FlexboxGrid,
  // DatePicker,
} from "rsuite";
import { HttpService } from "../../services/http.service";
import {
  ATHLETE_PROFILE_API_URL,
  SLEEP_LOGS,
  // USER_API_URL,
} from "../../services/API";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../store/AuthContext";
import CustomNotification from "../CustomNotification";
import { useEffect ,useContext} from "react";
import { Color } from "../../utils/Colors";
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

export default function RecordSleepForm({ onSubmitSuccess,selectedDate, setCount }) {
  const [errorCount, setErrorCount] = React.useState(0);
 
  const [athlete, setAthlete] = React.useState();
  const [athletes, setAthletes] = React.useState();
  // const [user, setUser] = React.useState();
  // const [users, setUsers] = React.useState();
  // const [date, setDate] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const inputDate = new Date(selectedDate);

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');
  
  const formattedDate = `${year}-${month}-${day}`;
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const formRef = React.useRef();

  const [formValue, setFormValue] = React.useState({
    hours_slept: "",
    sleep_quality: "",
  });

  useEffect(() => {
    const fetchAthlete = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`,{}, false
        );

        const modifiedAthlete = response.data.results.map((ath) => ({
          value: ath.id,
          label: ath.name,
        }));

        setAthletes(modifiedAthlete);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthlete();
  }, [currentPage]);
  

  // console.log(user);

  const handleSubmit = async () => {
    // const d =
    //   date instanceof Date
    //     ? `${date.getFullYear()}-${(date.getMonth() + 1)
    //         .toString()
    //         .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`
    //     : "";
    try {
      const formData = {
        hours_slept: formValue.hours_slept || 0,
        sleep_quality: formValue.sleep_quality || "",
        date: formattedDate,
        athlete,
        recorded_by: decode.user_id,
      };
      const response = await HttpService.post(SLEEP_LOGS, {}, formData,false);

      setResponse(response);

      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setOpenModal(true);
        setError("Form Submitted");
        onSubmitSuccess();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        <Form ref={formRef} onChange={setFormValue} formValue={formValue}>
          <SelectPicker
            label="Select Athlete"
            data={athletes}
            onSelect={(value) => setAthlete(value)}
            style={{ width: 224 }}
          />
          <br />
          <br />
          
          <TextField name="hours_slept" label="Hours Slept" />
          <TextField name="sleep_quality" label="Sleep Quality" />

        

         
            <Button appearance="primary" onClick={handleSubmit} style={{background:Color.Color_C,color:Color.Color_A,width:300}}>
              Submit
            </Button>
          
          <CustomNotification
          errorCount={errorCount}
            open={openModal}
            setOpen={setOpenModal}
            message={
              error ? error : (res && res.data && res.data.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
