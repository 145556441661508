import React, { useState } from "react";
import { HttpService } from "../../services/http.service";
import { ATHLETE_PROFILE_API_URL } from "../../services/API";
import { Typography } from "@mui/material";
import { Button } from "rsuite";

export default function DeleteAthleteProfile({ id, email,onSucess }) {
  const [confirmEmail, setConfirmEmail] = useState();
  const [error, setError] = useState(null);
  const deleteAthleteProfile = async () => {
    try {
      const response = await HttpService.delete(
        ATHLETE_PROFILE_API_URL + id + "/",{},false
      );

      // Show success alert
      alert("Admin profile deleted successfully");
      onSucess()
    } catch (e) {
      console.warn(e);
      // Set error state for the error boundary to catch
      setError(e);
    }
  };

  // Error boundary component
  const ErrorBoundary = ({ error }) => {
    if (error) {
      return alert("An error occurred while deleting the athlete profile.");
    }
    return null;
  };

  return (
    <div>
      <ErrorBoundary error={error} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography>
          Are you sure you want to delete the athete: {email}?
        </Typography>
        <Typography>To delete, confirm the email id:</Typography>
        <br />
        <input
          type="email"
          onChange={(e) => setConfirmEmail(e.target.value)}
          onPaste={(e) => e.preventDefault()}
        />
      </div>
      <br />
      <Button
        appearance="primary"
        color={"red"}
        disabled={email !== confirmEmail}
        onClick={deleteAthleteProfile}
      >
        Delete
      </Button>
    </div>
  );
}
