import React, { useState, useEffect } from "react";
import { FetchResultsByFieldAthlete } from "../../components/custmomhooks/CustomHooks";
import CustomBarChart from "./BarChart";
import styles from "./injury.module.css";
import { Button, SelectPicker } from "rsuite";
import { HttpService } from "../../services/http.service";
import { ATHLETE_PROFILE_API_URL } from "../../services/API";
import NODATA from "./../../assets/image/noChart.svg";
import NOTSELECTED from "./../../assets/image/notSelected.jpg";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import Sidebar from "../../components/CustomSideNav";
import { Typography } from "@mui/material";
export default function MonitoringDashboardAthlete() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const [filter, setFilter] = useState(1);
  const [dataResults, setDataResults] = useState({
    sleep: { data: [], title: "Sleep" },
    fatigue: { data: [], title: "Fatigue" },
    hydration: { data: [], title: "Hydration" },
    soreness: { data: [], title: "Soreness" },
    mood: { data: [], title: "Mood" },
    stress: { data: [], title: "Stress" },
    willingness: { data: [], title: "Willingness To Train" },
    hr: { data: [], title: "Heart Rate" },
    rpe: { data: [], title: "RPE" },
    duration: { data: [], title: "Duration" },
    bout: { data: [], title: "Bout" },
    distance: { data: [], title: "Distance" },
    parts: { data: [], title: "Parts" },
    reps: { data: [], title: "Repitition" },
    sets: { data: [], title: "Sets" },
    type: { data: [], title: "Type" },
    durationSC: { data: [], title: "Duration" },
  });
  const [athlete, setAthlete] = useState([]);
  const [athletes, setAthletes] = useState([]);
  // const [selectedItem, setSelectedItem] = useState(1);
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(
          ATHLETE_PROFILE_API_URL,
          {},
          false
        );

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        // Add an "All" option

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, []);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true);
        setError(false);

        const fetchPromises = [
          FetchResultsByFieldAthlete({ dept: 9, cat: 99, field: 514, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 99, field: 515, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 99, field: 516, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 99, field: 517, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 99, field: 518, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 99, field: 519, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 99, field: 520, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 97, field: 506, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 97, field: 507, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 97, field: 508, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 96, field: 505, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 96, field: 504, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 98, field: 509, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 98, field: 510, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 98, field: 511, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 98, field: 512, athlete }),
          FetchResultsByFieldAthlete({ dept: 9, cat: 98, field: 513, athlete }),
        ];

        const results = await Promise.all(fetchPromises);

        setDataResults((prevData) => ({
          ...prevData,
          sleep: { data: results[0], title: "Sleep" },
          fatigue: { data: results[1], title: "Fatigue" },
          soreness: { data: results[2], title: "Soreness" },
          hydration: { data: results[3], title: "Hydration" },
          mood: { data: results[4], title: "Mood" },
          stress: { data: results[5], title: "Stress" },
          willingness: { data: results[6], title: "Willingness To Train" },
          distance: { data: results[7], title: "Distance" },
          duration: { data: results[8], title: "Duration" },
          bout: { data: results[9], title: "Bout" },
          hr: { data: results[10], title: "Heart Rate" },
          rpe: { data: results[11], title: "RPE" },
          parts: { data: results[12], title: "Parts" },
          reps: { data: results[13], title: "Repititon" },
          sets: { data: results[14], title: "Sets" },
          type: { data: results[15], title: "Type" },
          durationSC: { data: results[16], title: "Duration" },
        }));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (athlete) {
      fetchResults();
    }
  }, [athlete]);

  return (
    <div>
      <DashBoardTopNav header={"Dashboard"} />
      <Sidebar />
      <div className={styles.belownav}>
        <SelectPicker
          label={athlete?.length <= 0 ? "Select Athlete" : athlete?.name}
          data={athletes}
          style={{ marginLeft: "-180px" }}
          onSelect={(value) => setAthlete(value)}
          className={styles.selectpicker}
          onClean={() => {
            setAthlete([]);
          }}
        />
        <div className={styles.belownav}>
          <Button
            appearance="primary"
            style={{ backgroundColor: "cadetblue", marginLeft: "100px" }}
            className={styles.btn}
            onClick={() => window.close()}
          >
            Exit
          </Button>
        </div>
      </div>
      {loading === true ? (
        <div
          style={{
            marginTop: "100px",
            marginLeft:"240px",
            transition: "margin-left 0.3s",
          }}
        >
          <Typography variant="h4" color="darkviolet" textAlign="center">
            {athlete?.length <= 0 ? "Select Athlete" : "Loading...."}
          </Typography>
        </div>
      ) : (
        <div className={styles.box_science_sports_monitoring}>
          <div className={styles.chartcontent}>
            <div className={styles.chartwrapper}>
              {Object.entries(dataResults).map(([key, data]) => (
                <div className={styles.chartitem} key={key}>
                  <div className={styles.chartbox}>
                    {data?.data?.length <= 0 ? (
                      <div
                        style={{
                          width: "250px",
                          height: "250px",
                          margin: "0 auto",
                        }}
                      >
                        {athlete?.length > 0 ? (
                          <div>
                            <img src={NODATA} alt="No data" />
                            <h6 style={{ textAlign: "center" }}>
                              No Records Available:{data?.title}
                            </h6>
                          </div>
                        ) : (
                          <div  >
                             <h6> Athlete Not Selected</h6>
                            <img
                              src={NOTSELECTED}
                              style={{ width: "200px", height: "200px" }}
                              alt="No data"
                            />
                            
                           
                          </div>
                        )}
                      </div>
                    ) : (
                      <CustomBarChart data={data?.data} title={data?.title} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
