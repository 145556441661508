// const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

export const HTTP_SCHEME = process.env.REACT_APP_HTTP_SCHEME;
export const APP_DOMAIN = process.env.REACT_APP_BACKEND;
export const WS_SCHEME = process.env.REACT_APP_WS_SCHEME;
export const PUBLIC_URL = `${HTTP_SCHEME}://${APP_DOMAIN}/api/v1/public`;
export const WEBSOCKET_NOTIFICATION = (acc) => `${WS_SCHEME}://${acc}.${APP_DOMAIN}/ws/notification/`;
export const BASE_URL = (acc) => `${HTTP_SCHEME}://${acc}.${APP_DOMAIN}/api/v1/client`;
export const LOGIN_API_URL = PUBLIC_URL + '/login/';
export const REFRESH_TOKEN__API_URL = PUBLIC_URL + + '/token/refresh/';
export const REGISTER_API_URL = PUBLIC_URL + + '/users/register_email/';
export const LOGOUT_API_URL = '/logout/';
export const USER_API_URL ='/users/';
export const ADMIN_API_URL = '/profiles/admin/';
export const ACCOUNTS_API_URL = '/accounts/';
export const SUBSCRIPTION_API_URLS = '/subscriptions/';
export const TEAM_API_URL = '/teams/';
export const CREATE_TEAM_API_URL = '/create/teams/';
export const APP_PERMISSION = '/app-permissions/';
export const APP_PERMISSION_URL = '/app-permission-urls/';
export const ATHLETE_PROFILE_API_URL = '/profiles/athlete/';
export const CREATE_ATHLETE = '/create/users/athletes/'
export const CREATE_ADMINS = '/create/users/admins/'
export const ATTENDANCE_SESSIONS_API_URLS = '/attendance/sessions/'
export const ATTENDANCE_RECORDS_API_URLS ='/attendance/records/'
export const NUTRITION_LOGS_API_URLS = '/logs/nutrition/'
export const TREATMENT_LOGS_API_URLS ='/logs/treatment/'
export const EXERCISE_THERAPY_API_URLS = '/rehab/sessions/'
export const ELECTROTHERAPY_API_URLS ='/electrotherapy/sessions/'
export const NOTIFICATION_API_URLS = '/notifications/'
export const NOTIFICATION_RECIPENTS = '/notification-recipients/'
export const NOTIFICATION_CREATE = '/notifications-create/'
export const EVENT_API_URLS = '/events/'
export const EVENT_TYPE_API_URLS ='/event-types/'
export const VENUE_API_URLS = '/venues/'
export const VENUE_SETUP_API_URLS = '/venues-setup/'
export const GENERALISED_WORKOUT_LOGS_API_URLS = '/workout/metrics/generalised/'
export const WOKRKOUT_SESSION_LOGS_API_URLS ='/workout/sessions/'
export const RECORD_INJURY_API_URLS = '/records/injury/'
export const SLEEP_LOGS = '/metrics/sleep/'
export const SC_WORKOUT_API_URLS = '/workout/metrics/strength-conditioning/'
export const EVENT_RESULT_API_URLS ='/event-results/'
export const EVALUATION_FIELDS_DEPARTMENT_QUERY_URLS ='/evaluations/fields/?department='
export const EVALUATION_FIELDS_URLS ='/evaluations/fields/'
export const EVALUATION_RESULTS = '/evaluations/results/'
export const EVALUATION_RESULTS_CHECK = '/evaluations/results/athlete_evaluation/'
export const EVALUATION_DEPARTMENTS = '/evaluations/departments/'
export const EVALUATION_CATEGORIES = '/evaluations/categories/'
export const EVALUATION_FIELDS='/evaluations/fields/'
export const EVALUATION_REPORTS='/evaluations/reports/'
export const MEDIA_API='/media'
export const REPORT_GENERATION_PDF_API ='/media/'
export const CUSTOM_API ='/evaluations/create/categories/'
export const PERODISATION_API ='/periodisation/fields/'
export const PERODISATION_API_FIELDS = '/periodisation/field-templates/'