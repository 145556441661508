import React, { useState, useEffect } from "react";
import { Button, List, Modal, IconButton, Loader, SelectPicker } from "rsuite";
import { Typography, Tooltip, Card, CardContent } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import TrashIcon from "@rsuite/icons/Trash";
import { ATHLETE_PROFILE_API_URL } from "../../services/API";
import { HttpService } from "../../services/http.service";
import {
  Psychology,
  Sleep,
  Recovery,
} from "../storytelling/PsychologyWellness";

const RehabCard = ({
  exerciseList,
  show,
  setShow,
  type,
  exerciseSelected,
  setExerciseSelected,
  exerciseCart,
  setExerciseCart,
  addToCartModalVisible,
  setAddToCartModalVisible,
  exerciseCartModalVisible,
  setExerciseCartModalVisible,
  selectedIndices,
  setSelectedIndices,
  clearExerciseCart,
  handleRemoveFromCart,
}) => {
  const [athletes, setAthletes] = useState([]);
  const [athlete, setAthlete] = useState([]);
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(ATHLETE_PROFILE_API_URL);

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);
  const handleShowMore = () => {
    setShow(show + 20);
  };

  const handleShowLess = () => {
    setShow(20);
  };

  const handleExercise = (selectedItem, index) => {
    const indexInArray = selectedIndices.indexOf(index);

    // If the index is in the array, remove it; otherwise, add it
    if (indexInArray !== -1) {
      const updatedIndices = [...selectedIndices];
      updatedIndices.splice(indexInArray, 1); // Remove the index from the array
      setSelectedIndices(updatedIndices);
    } else {
      setSelectedIndices([...selectedIndices, index]);
    }
    setExerciseSelected(selectedItem);
    setAddToCartModalVisible(true);
  };

  const addToExerciseCart = (selectedItem, description, remarks) => {
    setExerciseCart((prevExerciseCart) => {
      const updatedCart = [
        ...prevExerciseCart,
        { rehab: selectedItem, remarks: remarks, description: description },
      ];
      return updatedCart;
    });
    setAddToCartModalVisible(false);
  };

  const showExerciseCart = () => {
    setExerciseCartModalVisible(true);
  };
  // console.log(exerciseCart);
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ margin: "1rem" }}>
        {exerciseCart?.length <= 0 ? null : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              appearance="primary"
              color="red"
              onClick={clearExerciseCart}
            >
              Clear Wellness Cart
            </Button>

            {/* <div style={{ position: "relative", display: "inline-block" }}>
              <Tooltip title="Show Wellness Cart" placement="top-start">
                <IconButton
                  icon={<ShoppingCartIcon />}
                  appearance="primary"
                  size="xs"
                  color="cyan"
                  onClick={showExerciseCart}
                />
              </Tooltip>

              <div
                style={{
                  position: "absolute",
                  top: "-10px",
                  left: "16px",
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "4px 8px",
                  fontSize: "12px",
                }}
              >
                {exerciseCart.length}
              </div>
            </div> */}
          </div>
        )}
        {athlete?.length < 0 ? (
          <Loader size="xs" content="Loading...!!" />
        ) : (
          <div style={{ marginTop: "2rem" }}>
            <SelectPicker
              label="Select Athlete"
              data={athletes}
              value={athlete}
              onSelect={(value) => setAthlete(value)}
              style={{ width: 260 }}
            />
          </div>
        )}
        {exerciseList.slice(0, show).map((item, index) => (
          <Card
            key={index}
            sx={{
              marginTop: "1rem",
              width: "40rem",
            }}
          >
            <CardContent
              onClick={() => handleExercise(item, index)}
              style={{
                backgroundColor: selectedIndices.includes(index)
                  ? type === 1
                    ? "lightgreen"
                    : type === 2
                    ? "pink"
                    : "yellow"
                  : "transparent",
                cursor: "pointer",
                fontWeight: "bold", // Add cursor style to indicate clickable items
              }}
            >
              {type === 1
                ? item.Psychology
                : type === 2
                ? item.Sleep
                : type === 3
                ? item.Recovery
                : null}
            </CardContent>
          </Card>
        ))}
        {exerciseList.length > 21 ? (
          <>
            {show < exerciseList.length ? (
              <Button
                style={{ margin: "2rem" }}
                appearance="ghost"
                onClick={handleShowMore}
              >
                Show More
              </Button>
            ) : (
              <Button
                style={{ margin: "2rem" }}
                appearance="ghost"
                onClick={handleShowLess}
              >
                Show Less
              </Button>
            )}
          </>
        ) : null}
      </div>

      <Modal
        open={addToCartModalVisible}
        onClose={() => setAddToCartModalVisible(false)}
      >
        <Modal.Header>
          <Modal.Title>
            Add Psychology & Wellness Strategies to Cart
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to add{" "}
          {exerciseSelected
            ? type === 1
              ? exerciseSelected.Psychology
              : type === 2
              ? exerciseSelected.Sleep
              : type === 3
              ? exerciseSelected.Recovery
              : null
            : null}{" "}
          to the Psychology and Wellness cart?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => addToExerciseCart(exerciseSelected)}
            appearance="primary"
          >
            Add to Cart
          </Button>
          <Button
            onClick={() => setAddToCartModalVisible(false)}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to show selected exercises */}
      {exerciseCart?.length <= 0 ? null : (
        <Card
        sx={{
          width: "60rem",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "2rem",
          marginRight: "1rem",
          marginLeft: "1rem",
          marginTop: "8rem",
          // border: "0.1px solid black",
          backgroundColor: "white",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        >
          <Typography variant="h6" textAlign={"center"}>Psychology and Wellness Cart</Typography>

          <CardContent>
            {exerciseCart?.map((cartItem, index) => (
              <List key={index}>
                <List.Item style={{ fontWeight: "bold" }}>
                  {cartItem.rehab.Psychology ||
                    cartItem.rehab.Sleep ||
                    cartItem.rehab.Recovery}
                  <Tooltip
                    title={`Delete Selected Strategies${" "}${[
                      cartItem.rehab.Psychology,
                      cartItem.rehab.Sleep,
                      cartItem.rehab.Recovery,
                    ]
                      .filter(Boolean)
                      .join(" ")}`}
                    placement="top-start"
                  >
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "40rem",
                      }}
                      appearance="primary"
                      size="xs"
                      icon={<TrashIcon />}
                      color="red"
                      onClick={() => handleRemoveFromCart(index)}
                    />
                  </Tooltip>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div>
                      <Typography
                        margin={"0.5rem"}
                        textAlign={"center"}
                        fontWeight={"bold"}
                        fontSize={12}
                      >
                        Description: {cartItem.description}
                      </Typography>

                      <input
                        style={{ marginRight: "1rem" }}
                        value={cartItem.description || ""} // Set the input value to the description property
                        onChange={(e) => {
                          const updatedCart = [...exerciseCart];
                          updatedCart[index].description = e.target.value; // Update remarks in the specific cart item
                          setExerciseCart(updatedCart);
                        }}
                      />
                    </div>
                    <div>
                      <Typography
                        margin={"0.5rem"}
                        marginLeft={"0.01rem"}
                        textAlign={"center"}
                        fontWeight={"bold"}
                        fontSize={12}
                      >
                        Remarks: {cartItem.remarks}
                      </Typography>

                      <input
                        style={{ marginRight: "1rem" }}
                        value={cartItem.remarks || ""} // Set the input value to the description property
                        onChange={(e) => {
                          const updatedCart = [...exerciseCart];
                          updatedCart[index].remarks = e.target.value; // Update remarks in the specific cart item
                          setExerciseCart(updatedCart);
                        }}
                      />
                    </div>
                  </div>
                </List.Item>
              </List>
            ))}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default function PsychologyWellnessTemplate() {
  const [showList0, setShowList0] = useState(10);
  const [showList1, setShowList1] = useState(10);
  const [showList2, setShowList2] = useState(10);
  const [exerciseSelected, setExerciseSelected] = useState([]);
  const [exerciseCart, setExerciseCart] = useState([]);
  const [type, setType] = useState(null);
  const [addToCartModalVisible, setAddToCartModalVisible] = useState(false);
  const [exerciseCartModalVisible, setExerciseCartModalVisible] =
    useState(false);
  const [clicked, setClicked] = useState([]);
  const [clicked1, setClicked1] = useState([]);
  const [clicked2, setClicked2] = useState([]);

  const handleToggle = (selectedType) => {
    const numericType = parseInt(selectedType, 10);

    if (numericType === 1) {
      setType(1);
    } else if (numericType === 2) {
      setType(2);
    } else if (numericType === 3) {
      setType(3);
    }
  };
  const clearExerciseCart = () => {
    setExerciseCart([]);
    setClicked([]);
    setClicked1([]);
    setClicked2([]);
  };
  const handleRemoveFromCart = (indexToRemove) => {
    const updatedCart = exerciseCart.filter(
      (_, index) => index !== indexToRemove
    );
    setExerciseCart(updatedCart);
    // Reset background color for the removed item based on its type
    if (type === 1) {
      setClicked(clicked.filter((i) => i !== indexToRemove));
    } else if (type === 2) {
      setClicked1(clicked1.filter((i) => i !== indexToRemove));
    } else if (type === 3) {
      setClicked2(clicked2.filter((i) => i !== indexToRemove));
    }
  };

  return (
    <>
      <Button
        onClick={() => handleToggle(1)}
        appearance="ghost"
        color="green"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Psychology
      </Button>
      <Button
        onClick={() => handleToggle(2)}
        appearance="ghost"
        color="red"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Sleep
      </Button>
      <Button
        onClick={() => handleToggle(3)}
        appearance="ghost"
        color="yellow"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Recovery
      </Button>

      {type === null ? null : type === 1 ? (
        <RehabCard
          exerciseList={Psychology}
          show={showList0}
          setShow={setShowList0}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked}
          setSelectedIndices={setClicked}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 2 ? (
        <RehabCard
          exerciseList={Sleep}
          show={showList1}
          setShow={setShowList1}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked1}
          setSelectedIndices={setClicked1}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 3 ? (
        <RehabCard
          exerciseList={Recovery}
          show={showList2}
          setShow={setShowList2}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked2}
          setSelectedIndices={setClicked2}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : null}
    </>
  );
}
