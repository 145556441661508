import React from "react";
import styles from "./landing.module.css";

import Lottie from "lottie-react";

export default function Services() {
  const data = [
    {
      name: "Assessment",

      animation: require("./../../assets/json/act.json"),
      animationSize: 1, // Adjust the size here
    },
    {
      name: "Nutrition Plan",
      animation: require("./../../assets/json/nutri_logs.json"),
      animationSize: 1, // Adjust the size here
    },
    {
      name: "Periodisation",
      animation: require("./../../assets/json/session.json"),
      animationSize: 1, // Adjust the size here
    },
  ];
  const data2 = [
    {
      name: "Data Visualisation",
      animation: require("./../../assets/json/number.json"),
      animationSize: 1, // Adjust the size here
    },

    {
      name: "Rehabilitation",
      animation: require("./../../assets/json/injury.json"),
      animationSize: 1, // Adjust the size here
    },
    {
      name: "Screening",
      animation: require("./../../assets/json/search.json"),
      animationSize: 1, // Adjust the size here
    },
  ];
  return (
    <div>
      <div className={styles.serviceContainer}>
        <h2>What You Will Get</h2>
        <p>
          Activating Maximum Potential: Sports Science and Rehab Software
          Functions That Transform Training and Rehabilitation.
        </p>
      </div>
      <div>
        <div className={styles.card}>
          {data.map((item) => (
            <div key={item.name} className={styles.selector}>
              <div>
                <label className={styles.title2}>{item.name}</label>

                <Lottie
                  animationData={item.animation}
                  loop={true}
                  size={item.animationSize}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={styles.card_right}>
          {data2.map((item) => (
            <div key={item.name} className={styles.selector}>
              <div>
                <label className={styles.title2}>{item.name}</label>

                <Lottie
                  animationData={item.animation}
                  loop={true}
                  size={item.animationSize}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
