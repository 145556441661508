import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/AuthContext";
import { SUBSCRIPTION_API_URLS, USER_API_URL } from "../../services/API";
import { Typography } from "@mui/material";
import jwt_decode from "jwt-decode";
import { HttpService } from "../../services/http.service";
import { DataGrid } from "@mui/x-data-grid";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';


export default function Permission() {
    const [subscriptionData, setSubscriptionData] = useState([]);
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;

    const [adminData, setAdminData] = useState([]);
    let decode = "";
    if (token) {
        decode = jwt_decode(token);
    } else {
        decode = "";
    }

    useEffect(() => {
        const fetchData_ = async (url, setData, bool) => {
            try {
                const response = await HttpService.get(`${url}`, {}, bool);
                setData(response.data);
            } catch (error) {}
        };

        fetchData_(
            USER_API_URL + decode.user_id + "/account_details",
            setAdminData,
            true
        );
    }, [decode.user_id]);

    useEffect(() => {
        const fetchSubscriptionData = async () => {
            try {
                if (!token) return; // Exit if token is not available
                const response = await HttpService.get(
                    SUBSCRIPTION_API_URLS,
                    {},
                    true
                );
                setSubscriptionData(response.data.results);
            } catch (error) {
                console.error("Error fetching subscription data:", error);
            }
        };
        fetchSubscriptionData();
    }, [token]);

  
    return (
        <div style={{ fontWeight: "bolder",   }}>
            {subscriptionData?.length <= 0 ? <Typography variant={"h6"} color={"red"}>Permission Denied</Typography>  : (
                <div>
                   
                   
                    <div>
                   
                      {subscriptionData?.map((item,index)=>{
                        return (
                            <div key={index} >
                            {item?.app_permissions?.map((_item, index) => {
                              const permissionLabels = {
                                1: "Login",
                                2: "User Management",
                                3: "Notification Management",
                                4: "Media Management",
                                5: "Events Management",
                                6: "Evaluation Management",
                                7: "Performance Management",
                                8: "Accounts Management",
                              };
    
                              const permissionLabel =
                                permissionLabels[_item] || "No Permission Granted";
    
                              return (
    
                                <Accordion expanded={false} key={index}>
                                <AccordionSummary
                                       aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  {permissionLabel}
                                </AccordionSummary>
                                
                              </Accordion>
                              
                              );
                            })}
                          </div>
                        )
                      })}
                     
                    </div>
                </div>
            )}
        </div>
    );
}
