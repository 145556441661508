import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomNotification = ({ title, message, errorCount }) => {
  // console.log(message,"messafe",errorCount,title,"title")
  const info = title === "Error" ? "error" : "success";
// console.log(title, message, errorCount)
  useEffect(() => {
    if (message !== "" && errorCount > 0) {
      const notify = () => {
        toast[info](message, {
          position: "bottom-center",
          autoClose: 5000,
          theme: "colored",

        });
      };
      notify();
    }
  }, [message, info, errorCount]);

  return (
    <div>
      <ToastContainer closeOnClick={true} />
    </div>
  );
};

export default CustomNotification;
