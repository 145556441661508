import React, { useState } from "react";
import styles from "./../../science.module.css";
import EvaluationFormView from "../../../../components/EvaluationFormView";

export default function ROM({ mode }) {
  const [open, setOpen] = useState(false);
  // const [placement, setPlacement] = useState();
  const [drawerTitle, setDrawerTitle] = useState();
  const [dept, setDept] = useState();
  const [cat, setCat] = useState();

  
  const data = [
   
    {
      cat: 7,
      name: "Shoulder Joint - ROM",
      dept: 1,
      cardColor: "#c5cae9",
    },
    {
      cat: 8,
      name: "Elbow Joint - ROM",
      dept: 1,
      cardColor: "#c5cae9",
    },
    {
      cat: 9,
      name: "Wrist Joint ROM",
      dept: 1,
      cardColor: "#c5cae9",
    },
    { cat: 10, name: "Neck ROM", dept: 1, cardColor: "#c5cae9" },
    { cat: 11, name: "Spinal ROM", dept: 1, cardColor: "#c5cae9" },
    {
      cat: 12,
      name: "Hip Joint - ROM",
      dept: 1,
      cardColor: "#c5cae9",
    },
    {
      cat: 13,
      name: "Knee Joint - ROM",
      dept: 1,
      cardColor: "#c5cae9",
    },
    {
      cat: 14,
      name: "Ankle Joint - ROM",
      dept: 1,
      cardColor: "#c5cae9",
    },
     
  ];

  const handleOpen = ({ name, key, dept, cat }) => {
    setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
    setDept(dept);
    setCat(cat);
  };

  return (
    < >
   
      <div className={styles.sports_main_inner}>
        <select
          className={styles.dropdown}
          onChange={(e) => {
            const selectedValue = e.target.value;
            const selectedItem = data.find(
              (item) => item.name === selectedValue
            );

            if (selectedItem) {
              handleOpen({
                name: selectedItem.name,
                dept: selectedItem.dept,
                cat: selectedItem.cat,
              });
            }
          }}
        >
          <option value="">{mode}</option>
          {data.map((item) => (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
     
    </div>


    
        <EvaluationFormView
          open={open}
          setOpen={setOpen}
          size={"lg"}
          placement={"left"}
          mode={drawerTitle}
          dept={dept}
          cat={cat}
        />
      
    </>
  );
}
