import React from "react";
import "./../landing/styles.css";
import {
  Form,
  Button,
  Schema,
  FlexboxGrid,
  SelectPicker,
} from "rsuite";
import { ACCOUNTS_API_URL } from "../../services/API";
import { HttpService } from "../../services/http.service";
import CustomNotification from "../CustomNotification";
import { Color } from "../../utils/Colors";
const { StringType } = Schema.Types;

const model = Schema.Model({
  name: StringType().isRequired("This field is required."),
  // schema_name: StringType().isRequired("This field is required."),
  
});

const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} style={{ width: '240px' }} {...rest} />
    </Form.Group>
  );
});

export default function AccountForm({ onSubmitSuccess,setCount }) {
  const formRef = React.useRef();
  const [errorCount, setErrorCount] = React.useState(0);
  const [selectedFile, setSelectedFile] = React.useState();
  // const [value, setValue] = React.useState([]);
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    name: "",
   
    ontrial:"",
    active:""
  });
  const flag = ["Yes", "No"].map((item) => ({ label: item, value: item }));
  const handleChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      console.error("Form Error", formError);
      return;
    }

    try {
     
      const formData = new FormData();

      formData.append("name", formValue.name.toLowerCase());
     
      formData.append("on_trial", formValue.ontrial === "Yes"? true:false);
      formData.append("is_active", true);
      formData.append("logo", selectedFile);
      const response = await HttpService.post(ACCOUNTS_API_URL,  { "Content-Type": "multipart/form-data" }, formData,true);

      setResponse(response);

      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setOpenModal(true);
        setError("Form Submitted");
        setFormValue({ // Clear the form data
          name: "",
          // schema_name: "",
          ontrial: "",
          active: "",
          
        });
        setSelectedFile(null);
        onSubmitSuccess()
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        <Form
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}
          model={model}
        >
            <TextField name="name" label="Name" />

        

          <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div >
            {selectedFile && (
              <div >
                {/* <img
                    height="25%"
                    width="25%"
                    src={URL.createObjectURL(selectedFile)}
                    alt=""
                  />
                  <br /> */}
                <span>{selectedFile.name}</span>
              </div>
            )}
            <label>Upload Logo</label>
            <br />
            <br />
            <input
              type="file"
              name="profile_photo"
              onChange={(e) => handleChange(e)}
            />

          </div>
          </div>
          <br />
          <br /> 
          <SelectPicker
                label="On Trail"
                data={flag}
                onSelect={(value) => setFormValue({...formValue,ontrial:value})}
                style={{ width: 400 }}
              /><br />
              <br /> 
            <Button appearance="primary" onClick={handleSubmit} style={{background:Color.Color_C,color:Color.Color_A,width:240}}>
              Create Account
            </Button>
       
          <CustomNotification
          errorCount={errorCount}
            open={openModal}
            setOpen={setOpenModal}
            message={
              error ? error : (res && res.data && res.data.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
