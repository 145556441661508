import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function SomethingBroke() {
  const location = useLocation()
 
  return (
    <div>
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
           {location?.state?.message}
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
          {location?.state?.description}
          </Typography>

         <br/>

          <Button to={location?.state?.link} size="large" variant="contained" component={RouterLink}>
          {location?.state?.tabname}
          </Button>
        </ContentStyle>
      </Container>
    </div>
  );
}
