import React, { useEffect } from "react";
import {  Modal } from "rsuite";
import { HttpService } from "../../services/http.service";
import UserCustomDrawer from "../../components/CustomDrawer";
import CustomNotification from "../../components/CustomNotification";
import {  Typography } from "@mui/material";
import {
  EXERCISE_THERAPY_API_URLS, USER_API_URL,
  
} from "../../services/API";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

export default function ViewExerciseTherapy() {
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [genWorkout, setGenWorkoutData] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [date, setDate] = React.useState();
  const [selectedEventInfo, setSelectedEventInfo] = React.useState(null);
  const [openModal2, setOpenModal2] = React.useState(false);
  const [count,setCount] = React.useState(0)
  const handleOpenModal = (eventInfo) => {
    setSelectedEventInfo(eventInfo);
    setOpenModal2(true);
  };

  const handleCloseModal = () => {
    setSelectedEventInfo(null);
    setOpenModal2(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${EXERCISE_THERAPY_API_URLS}?page=${currentPage}`,
          {},
          false
        );
        // console.log(response.data);
        const modifiedResult = await Promise.allSettled(
          response.data.results.map(async (result) => {
           
            try {
              // Fetch athlete data for each result
             
             
              const recordResponse = await HttpService.get(
                USER_API_URL + result.user + "/",
                {},
                true
              );
              const record_name = recordResponse.data.first_name;
              // console.log(sessionResponse.data.name);
              return {
                id: result.id,
             
                record: record_name,

                ...result,
              };
            } catch (error) {
              // Handle individual request error
              console.error(
                `Error fetching data for result ${result.id}:`,
                error
              );
              return null; // or handle the error in an appropriate way
            }
          })
        );

        // Extracting successful results and maintaining the order
        const filteredResults = modifiedResult
          .filter(
            (result) => result.status === "fulfilled" && result.value !== null
          )
          .map((result) => result.value);

        setGenWorkoutData(filteredResults);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        // setTotalPages(Math.ceil(response.data.count / 10));
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [currentPage,count]);
  const handleOpen = (date, key) => {
  
    setDate(date);
    setOpen(true);
    setPlacement(key);
  };

  

  return (
    <>
    
  
        
       
        <div
         
           
              
        ><h6>Exercise Therapy</h6>
          {genWorkout === undefined  ? (
            <>
              <Typography>No data</Typography>
              {error?.response?.status === 200 ? null : (
                <CustomNotification
                  open={openModal}
                  setOpen={setOpenModal}
                  message={
                    error?.response?.status === 200 ? "Loading" : error?.message
                  }
                  title={error?.response?.status === 200 ? "Loading" : "Error"}
                />
              )}
            </>
          ) : (
            <div style={{  height: "90%" }}>
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
              initialView="dayGridMonth"
              weekends={true}
              // headerToolbar={{
              //   left: 'prev,next today',
              //   center: 'title',
              //   right: 'dayGridMonth,timeGridWeek,timeGridDay'
              // }}
              events={genWorkout?.map((event) => ({
                title: event.exercise,
                start: new Date(event.date),
                repetitions: event.repetitions,
                sets: event.sets,
                notes: event.notes,
    
                  record: event.record,
                id: event.id,
              }))}
              eventContent={(eventInfo) => {
                return (
                  <div>
                    <p>
                      <Typography variant="body" fontWeight={"bold"}>
                        {eventInfo.event.extendedProps?.record}
                      </Typography>{" "}
                    </p>
                    <p></p>
                  </div>
                );
              }}
              eventClick={(info) => handleOpenModal(info)}
              dateClick={(arg) => handleOpen(arg.date, "right")}
              height={"500px"}
            />
          </div>
          )}
        </div>
  
        <Modal size="lg" open={openModal2} onClose={handleCloseModal}>
       
        {selectedEventInfo && (
          <>
            <h4>
              Exercise Therapy Details Recorded By:{" "}
              {selectedEventInfo.event.extendedProps?.record}
            </h4>

            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
              <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>
                    Exercise:
                  </th>
                  <td style={{ padding: "8px" }}>
                    {selectedEventInfo.event.extendedProps?.title}
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>
                    Notes:
                  </th>
                  <td style={{ padding: "8px" }}>
                    {selectedEventInfo.event.extendedProps?.notes}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>
                    Repetition
                  </th>
                  <td style={{ padding: "8px" }}>
                    {selectedEventInfo.event.extendedProps?.repetitions}
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>
                    Sets
                  </th>
                  <td style={{ padding: "8px" }}>
                    {selectedEventInfo.event.extendedProps?.sets}
                  </td>
                </tr>
                
              </tbody>
            </table>
          </>
        )}
        <br />
      </Modal>
      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={placement}
        mode={"AddExerciseTherapy"}
        date={date}
        setCount={setCount}
     
      />
    </>
  );
}
