import React, { useEffect, useState } from "react";
// import { Button } from "rsuite";

import styles from "./../../pages/sportsscience/science.module.css";
import { Typography } from "@mui/material";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid,GridToolbarContainer,GridToolbarExport } from "@mui/x-data-grid";
// import { Color } from "../../utils/Colors";
import { ATHLETE_PROFILE_API_URL, EVALUATION_RESULTS } from "../../services/API";
import { HttpService } from "../../services/http.service";
import { CascadingDropDown } from "../../components/CascadingDropDown";
import Label from "../../components/Label";
export default function StoryTelling() {
  const [results, setResult] = useState([]);
  const [filterResults, setFilterResults] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [athletes, setAthletes] = useState();
  // const [showGenerateButton, setShowGenerateButton] = useState(true);
  const [filter, setFilter] = useState(0);

  useEffect(()=>{
    const fetchResult = async () => {
      try {
        const response = await HttpService.get(
          `${EVALUATION_RESULTS}?department=${selectedDepartment}&categories=${selectedCategory}`,
          {},
          false
        );
  
        const modifiedResult = await Promise.all(
          response.data.results.map(async (result, index) => {
            try {
              const athleteResponse = await HttpService.get(
                ATHLETE_PROFILE_API_URL + result.athlete + "/",
                {},
                false
              );
  
              const athleteName = athleteResponse.data.name;
              const slicedCreatedAt = result.created_at.slice(0, 10);
  
              // Check if athlete details are available and not "N/A"
              if (athleteName !== "N/A") {
                const flattenedData = {
                  ...result,
                  // Add the type check for string or remarks
                  type:
                    result.string_value !== null
                      ? "text"
                      : result.remarks !== null
                        ? "text"
                        : null,
                  value:
                    result.string_value !== null
                      ? result.string_value
                      : result.remarks !== null
                        ? result.remarks
                        : null,
                  id: index,
                  athleteName,
                  athleteGender: athleteResponse.data.gender,
                  athleteInjury: athleteResponse.data.injury_status,
                  athleteSports: athleteResponse.data.sports,
                  athleteHeight: athleteResponse.data.height,
                  athleteWeight: athleteResponse.data.weight,
                  date: slicedCreatedAt,
                };
  
                return flattenedData;
              }
  
              return null; // Exclude the result when athlete is "N/A"
            } catch (athleteError) {
              console.warn(
                "Error fetching athlete details:",
                athleteError.message
              );
              // If athlete details cannot be fetched, return null to exclude the result
              return null;
            }
          })
        );
  
        // Filter out null results caused by failed athlete fetch or when athlete is "N/A"
        const filteredResults = modifiedResult.filter(
          (result) =>
            result !== null &&
            (result.type === "text" || result.type === "remarks")
        );
  
        setResult(filteredResults);
        // setShowGenerateButton(false);
        setFilter(1);
      } catch (error) {
        console.warn(error);
      }
    };
    if (selectedCategory !==null || selectedDepartment !==null){
    fetchResult()}
  },

  [selectedDepartment,selectedCategory])


  // const filterResultsByAthlete = () => {
  //   if (selectedAthlete === null) {
  //     setFilterResults(results);
  //   } else {
  //     const filteredResults = results.filter(
  //       (result) => result.athlete === selectedAthlete
  //     );
  //     setFilterResults(filteredResults);
  //   }
  //   setFilter(2);
  // };

  const resetSelection = () => {
    setSelectedDepartment(null);
    setSelectedCategory(null);
    setSelectedAthlete(null);
    setResult([]);
    setFilterResults([]);
    // setShowGenerateButton(true);
    setFilter(0);
  };

  const columns = [
    {
      field: "athleteName",
      headerName: "Name",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "athleteGender",
      headerName: "Gender",
      width: 100,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.athleteGender}`}>
            <Label
              color={params.row.athleteGender === "Male" ? "success" : "error"}
            >
              {params.row.athleteGender}
            </Label>
          </div>
        );
      },
    },
    {
      field: "athleteSports",
      headerName: "Sports",
      sortable: true,
      width: 200,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "athleteInjury",
      headerName: "Injury Status",
      sortable: true,
      width: 100,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.athleteInjury}`}>
            <Label
              color={params.row.athleteInjury === true ? "success" : "error"}
            >
              {params.row.athleteInjury === true ? "Yes" : "No"}
            </Label>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "field_name",
      headerName: "Test Name",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      headerName: "Result",
      sortable: true,
      width: 400,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "athleteHeight",
      headerName: "Height",
      sortable: true,
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "athleteWeight",
      headerName: "Weight",
      sortable: true,
      width: 100,
      headerAlign: "center",
      align: "center",
    },
  ];
  

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }

  
  return (
    <div>
       <div
          className={styles.belownav}
        >
          <CascadingDropDown
            selectedDepartment={selectedDepartment}
            setSelectedDepartment={setSelectedDepartment}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            departments={departments}
            setDepartments={setDepartments}
            categories={categories}
            setCategories={setCategories}
            athletes={athletes}
            setAthletes={setAthletes}
            results={results}
            selectedAthlete={selectedAthlete}
            setSelectedAthlete={setSelectedAthlete}
            
            onClean={resetSelection}
          /></div>
   {filter === 1 ? (
        <>
          {results?.length === 0 ? (
            <Typography variant="h6" textAlign={"center"}>
              No Data
            </Typography>
          ) : (
            <div
            style={{marginLeft:"30px",marginTop:"30px"}}
            >
              <Box key={Math.random().toLocaleString()}>
                <DataGrid
                  getRowId={(row) => row.id}
                  rows={results}
                  columns={columns}
                  pagination
                  paginationMode="client"
                  pageSize={10}
                  slots={{toolbar:CustomToolbar}}
                />
              </Box>
             
            </div>
          )}
        </>
      ) : filter === 2 ? (
        <>
          {filterResults?.length === 0 ? (
            <Typography variant="h6" textAlign={"center"}>
              No Data
            </Typography>
          ) : (
            <Container
              maxWidth="lg"
              style={{ marginTop: "4rem", marginBottom: "2rem" }}
            >
              <Box key={Math.random().toLocaleString()}>
                <DataGrid
                  getRowId={(row) => row.id}
                  rows={filterResults}
                  columns={columns}
                  pagination
                  paginationMode="client"
                  pageSize={10}
                />
              </Box>
             
            </Container>
          )}
        </>
      ) : null}
    </div>
  );
}
