import React, { useEffect,useLayoutEffect,useState, useRef } from "react";
import {
  PlayCircleOutline,
  Stop,
  FastForward,
  FastRewind,
  Add,
  ArrowForward,
  ArrowBack,
} from "@mui/icons-material"; // Import icons
import styles from "./navigation.module.css";
import { useLocation } from "react-router-dom";

import rough from "roughjs/bundled/rough.esm";
import getStroke from "perfect-freehand";
import "./DrawingTools.css"; 
import DashBoardTopNav from "../../components/DashBoardTopNav";
import Sidebar from "../../components/CustomSideNav";
import AssessmentType from "./AssessmentType";
import { Button } from "rsuite";

const generator = rough.generator();

const createElement = (id, x1, y1, x2, y2, type) => {
    console.log(type)
    switch (type) {
        case "line":
        case "rectangle":
            const roughElement =
                type === "line"
                    ? generator.line(x1, y1, x2, y2)
                    : generator.rectangle(x1, y1, x2 - x1, y2 - y1);
            return { id, x1, y1, x2, y2, type, roughElement };
        case "pencil":
            return { id, type, points: [{ x: x1, y: y1 }] };
        case "text":
            return { id, type, x1, y1, x2, y2, text: "" };
        case "circle":
            const radius = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)) / 2;
            const centerX = (x1 + x2) / 2;
            const centerY = (y1 + y2) / 2;
            const roughCircle = generator.circle(centerX, centerY, radius);
            return { id, x1: centerX - radius, y1: centerY - radius, x2: centerX + radius, y2: centerY + radius, type, roughElement: roughCircle };
        case "angle":
            return { id, x1, y1, x2, y2, x3: x2, y3: y2, type };

        default:
            throw new Error(`Type not recognised: ${type}`);
    }
};


const nearPoint = (x, y, x1, y1, name) => {
    return Math.abs(x - x1) < 5 && Math.abs(y - y1) < 5 ? name : null;
};

const onLine = (x1, y1, x2, y2, x, y, maxDistance = 1) => {
    const a = { x: x1, y: y1 };
    const b = { x: x2, y: y2 };
    const c = { x, y };
    const offset = distance(a, b) - (distance(a, c) + distance(b, c));
    return Math.abs(offset) < maxDistance ? "inside" : null;
}; const drawAngle = (roughCanvas, context, element) => {
    const { x1, y1, x2, y2, x3, y3 } = element;

    // Draw lines between the three points
    roughCanvas.line(x1, y1, x2, y2);
    roughCanvas.line(x2, y2, x3, y3);

    // Calculate and display the angle value near the midpoint or as desired
    const angleRad1 = Math.atan2(y2 - y1, x2 - x1);
    const angleRad2 = Math.atan2(y3 - y1, x3 - x1);
    let angleRad = angleRad2 - angleRad1;
    if (angleRad < 0) angleRad += 2 * Math.PI; // Ensure positive angle

    const angleDeg = (angleRad * 180) / Math.PI;
    const midX = (x1 + x2 + x3) / 3; // Calculate the midpoint for displaying the angle value
    const midY = (y1 + y2 + y3) / 3;
    const offset = 20;
    context.font = "14px sans-serif";
    context.fillText(`${angleDeg.toFixed(2)}°`, midX + offset, midY - offset);
};

// Calculate angle between three points





const positionWithinElement = (x, y, element) => {
    const { type, x1, x2, y1, y2, x3, y3 } = element;
    switch (type) {
        case "line":
            const on = onLine(x1, y1, x2, y2, x, y);
            const start = nearPoint(x, y, x1, y1, "start");
            const end = nearPoint(x, y, x2, y2, "end");
            return start || end || on;
        case "rectangle":
            const topLeft = nearPoint(x, y, x1, y1, "tl");
            const topRight = nearPoint(x, y, x2, y1, "tr");
            const bottomLeft = nearPoint(x, y, x1, y2, "bl");
            const bottomRight = nearPoint(x, y, x2, y2, "br");
            const inside = x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "inside" : null;
            return topLeft || topRight || bottomLeft || bottomRight || inside;
        case "pencil":
            const betweenAnyPoint = element.points.some((point, index) => {
                const nextPoint = element.points[index + 1];
                if (!nextPoint) return false;
                return (
                    onLine(point.x, point.y, nextPoint.x, nextPoint.y, x, y, 5) != null
                );
            });
            return betweenAnyPoint ? "inside" : null;
        case "text":
            return x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "inside" : null;
        case "circle":
            const distanceFromCenter = Math.sqrt(
                Math.pow(x - x1, 2) + Math.pow(y - y1, 2)
            );
            const onCircle =
                Math.abs(
                    distanceFromCenter -
                    Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
                ) < 5;
            return onCircle ? "on" : null;
        case "angle":
            // Check if the point (x, y) is within the angle triangle using a simple formula
            const area = Math.abs(
                (x1 * (y2 - y3) + x2 * (y3 - y1) + x3 * (y1 - y2)) / 2
            );
            const area1 = Math.abs(
                (x * (y1 - y2) + x1 * (y2 - y) + x2 * (y - y1)) / 2
            );
            const area2 = Math.abs(
                (x * (y2 - y3) + x2 * (y3 - y) + x3 * (y - y2)) / 2
            );
            const area3 = Math.abs(
                (x1 * (y3 - y) + x3 * (y - y1) + x * (y1 - y3)) / 2
            );
            // If the sum of the areas of the three triangles is equal to the area of the original triangle, the point is inside the triangle
            if (area === area1 + area2 + area3) {
                return "inside";
            } else {
                return null;
            }
        default:
            throw new Error(`Type not recognised: ${type}`);
    }
};

const distance = (a, b) =>
    Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));

const getElementAtPosition = (x, y, elements) => {
    return elements
        .map((element) => ({
            ...element,
            position: positionWithinElement(x, y, element),
        }))
        .find((element) => element.position !== null);
};

const adjustElementCoordinates = (element) => {
    const { type, x1, y1, x2, y2 } = element;
    if (type === "rectangle") {
        const minX = Math.min(x1, x2);
        const maxX = Math.max(x1, x2);
        const minY = Math.min(y1, y2);
        const maxY = Math.max(y1, y2);
        return { x1: minX, y1: minY, x2: maxX, y2: maxY };
    } else {
        if (x1 < x2 || (x1 === x2 && y1 < y2)) {
            return { x1, y1, x2, y2 };
        } else {
            return { x1: x2, y1: y2, x2: x1, y2: y1 };
        }
    }
};

const cursorForPosition = (position) => {
    switch (position) {
        case "tl":
        case "br":
        case "start":
        case "end":
            return "nwse-resize";
        case "tr":
        case "bl":
            return "nesw-resize";
        default:
            return "move";
    }
};

const resizedCoordinates = (clientX, clientY, position, coordinates) => {
    const { x1, y1, x2, y2 } = coordinates;
    switch (position) {
        case "tl":
        case "start":
            return { x1: clientX, y1: clientY, x2, y2 };
        case "tr":
            return { x1, y1: clientY, x2: clientX, y2 };
        case "bl":
            return { x1: clientX, y1, x2, y2: clientY };
        case "br":
        case "end":
            return { x1, y1, x2: clientX, y2: clientY };
        default:
            return null; //should not really get here...
    }
};

const useHistory = (initialState) => {
    const [index, setIndex] = useState(0);
    const [history, setHistory] = useState([initialState]);

    const setState = (action, overwrite = false) => {
        const newState =
            typeof action === "function" ? action(history[index]) : action;
        if (overwrite) {
            const historyCopy = [...history];
            historyCopy[index] = newState;
            setHistory(historyCopy);
        } else {
            const updatedState = [...history].slice(0, index + 1);
            setHistory([...updatedState, newState]);
            setIndex((prevState) => prevState + 1);
        }
    };

    const undo = () => index > 0 && setIndex((prevState) => prevState - 1);
    const redo = () =>
        index < history.length - 1 && setIndex((prevState) => prevState + 1);

    return [history[index], setState, undo, redo];
};

const getSvgPathFromStroke = (stroke) => {
    if (!stroke.length) return "";

    const d = stroke.reduce(
        (acc, [x0, y0], i, arr) => {
            const [x1, y1] = arr[(i + 1) % arr.length];
            acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
            return acc;
        },
        ["M", ...stroke[0], "Q"]
    );

    d.push("Z");
    return d.join(" ");
};

const drawElement = (roughCanvas, context, element) => {
    switch (element.type) {
        case "line":
        case "rectangle":
        case "circle": // Added support for "circle" type
            roughCanvas.draw(element.roughElement);
            break;
        case "pencil":
            const stroke = getSvgPathFromStroke(getStroke(element.points));
            context.lineWidth = 0.5;
            context.strokeStyle = "#000"; 
            context.fill(new Path2D(stroke));
            break;
        case "text":
            context.textBaseline = "top";
            context.font = "24px sans-serif";
            context.fillText(element.text, element.x1, element.y1);
            break;
        case "angle":
            // Draw angle logic
            drawAngle(roughCanvas, context, element);
            break
        default:
            throw new Error(`Type not recognised: ${element.type}`);
    }
};

const adjustmentRequired = (type) => ["line", "rectangle", "circle"].includes(type);

const usePressedKeys = () => {
    const [pressedKeys, setPressedKeys] = useState(new Set());

    useEffect(() => {
        const handleKeyDown = (event) => {
            setPressedKeys((prevKeys) => new Set(prevKeys).add(event.key));
        };

        const handleKeyUp = (event) => {
            setPressedKeys((prevKeys) => {
                const updatedKeys = new Set(prevKeys);
                updatedKeys.delete(event.key);
                return updatedKeys;
            });
        };

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    return pressedKeys;
};
export default function SpatialParameter() {
const [drawerTitle, setDrawerTitle] = useState("");
const [selectedItem, setSelectedItem] = useState("");
const [modalTitle, setModalTitle] = useState("");
const [shotData, setShotData] = useState({});
  const videoRef = useRef(null);
  const location = useLocation();
  const selectedFile = location.state && location.state.file;
  const videoSource = selectedFile ? URL.createObjectURL(selectedFile) : "";
  const [elements, setElements, undo, redo] = useHistory([]);
  const [action, setAction] = useState("none");
  const [tool, setTool] = useState("rectangle");
  const [selectedElement, setSelectedElement] = useState(null);
  const [panOffset, setPanOffset] = React.useState({ x: 0, y: 0 });
  const [startPanMousePosition, setStartPanMousePosition] = React.useState({
      x: 0,
      y: 0,
  });
  const textAreaRef = useRef();
  const pressedKeys = usePressedKeys();

  useLayoutEffect(() => {
      const canvas = document.getElementById("canvas");
      const context = canvas.getContext("2d");
      const roughCanvas = rough.canvas(canvas);

      context.clearRect(0, 0, canvas.width, canvas.height);

      context.save();
      context.translate(panOffset.x, panOffset.y);

      // Add guard clause to check if elements is defined
      if (elements) {
          elements.forEach((element) => {
              if (action === "writing" && selectedElement && selectedElement.id === element.id) return;
              drawElement(roughCanvas, context, element);
          });
      }
      context.restore();
  }, [elements, action, selectedElement, panOffset]);

  useEffect(() => {
      const undoRedoFunction = (event) => {
          if ((event.metaKey || event.ctrlKey) && event.key === "z") {
              if (event.shiftKey) {
                  redo();
              } else {
                  undo();
              }
          }
      };

      document.addEventListener("keydown", undoRedoFunction);
      return () => {
          document.removeEventListener("keydown", undoRedoFunction);
      };
  }, [undo, redo]);

  useEffect(() => {
      const panFunction = (event) => {
          setPanOffset((prevState) => ({
              x: prevState.x - event.deltaX,
              y: prevState.y - event.deltaY,
          }));
      };

      document.addEventListener("wheel", panFunction);
      return () => {
          document.removeEventListener("wheel", panFunction);
      };
  }, []);

  useEffect(() => {
      const textArea = textAreaRef.current;
      if (action === "writing") {
          setTimeout(() => {
              textArea.focus();
              textArea.value = selectedElement.text;
          }, 0);
      }
  }, [action, selectedElement]);

  const updateElement = (id, x1, y1, x2, y2, type, options) => {
      const elementsCopy = [...elements];

      switch (type) {
          case "line":
          case "rectangle":
              elementsCopy[id] = createElement(id, x1, y1, x2, y2, type);
              break;
          case "pencil":
              elementsCopy[id].points = [
                  ...elementsCopy[id].points,
                  { x: x2, y: y2 },
              ];
              break;
          case "text":
              const textWidth = document
                  .getElementById("canvas")
                  .getContext("2d")
                  .measureText(options.text).width;
              const textHeight = 24;
              elementsCopy[id] = {
                  ...createElement(id, x1, y1, x1 + textWidth, y1 + textHeight, type),
                  text: options.text,
              };
              break;

          case "angle":
              // Check if options object is provided
              const angleElement = elements[id];
              const updatedAngleElement = {
                  ...angleElement,
                  x3: x2, // Assuming x2 is the x-coordinate of the end of the second line
                  y3: y2, // Assuming y2 is the y-coordinate of the end of the second line
              };
              elementsCopy[id] = updatedAngleElement;
              break;
          case "circle":

              const newRadius = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)) / 2;
              const newCenterX = (x1 + x2) / 2;
              const newCenterY = (y1 + y2) / 2;

              elementsCopy[id] = {
                  ...createElement(id, newCenterX - newRadius,
                      newCenterY - newRadius,
                      newCenterX + newRadius,
                      newCenterY + newRadius, type),
              };
              break;
          default:
              throw new Error(`Type not recognised: ${type}`);
      }

      setElements(elementsCopy, true);
  };


  const getMouseCoordinates = (event) => {
      const canvas = document.getElementById("canvas");
      const rect = canvas.getBoundingClientRect(); // Get canvas position
      const clientX = event.clientX - rect.left; // Adjust mouse x-coordinate
      const clientY = event.clientY - rect.top; // Adjust mouse y-coordinate
      return { clientX, clientY };
  };

  const handleMouseDown = (event) => {
      if (action === "writing") return;

      const { clientX, clientY } = getMouseCoordinates(event);

      if (event.button === 1 || pressedKeys.has(" ")) {
          setAction("panning");
          setStartPanMousePosition({ x: clientX, y: clientY });
          return;
      }
      if (tool === "angle") {
          // Create a new angle element with initial coordinates
          const id = elements.length;
          const element = createElement(
              id,
              clientX,
              clientY,
              clientX,
              clientY,
              tool
          );
          setElements((prevState) => [...prevState, element]);
          setSelectedElement(element);
          setAction("angle-drawing");
          return;
      }
      else if (tool === "selection") {
          const element = getElementAtPosition(clientX, clientY, elements);
          if (element) {
              if (element.type === "pencil") {
                  const xOffsets = element.points.map((point) => clientX - point.x);
                  const yOffsets = element.points.map((point) => clientY - point.y);
                  setSelectedElement({ ...element, xOffsets, yOffsets });
              } else {
                  const offsetX = clientX - element.x1;
                  const offsetY = clientY - element.y1;
                  setSelectedElement({ ...element, offsetX, offsetY });
              }
              setElements((prevState) => prevState);

              if (element.position === "inside") {
                  setAction("moving");
              } else {
                  setAction("resizing");
              }
          }
      } else {
          const id = elements.length;
          const element = createElement(
              id,
              clientX,
              clientY,
              clientX,
              clientY,
              tool
          );
          setElements((prevState) => [...prevState, element]);
          setSelectedElement(element);

          setAction(tool === "text" ? "writing" : "drawing");
      }
  };

  const handleMouseMove = (event) => {
      const { clientX, clientY } = getMouseCoordinates(event);

      if (action === "panning") {
          const deltaX = clientX - startPanMousePosition.x;
          const deltaY = clientY - startPanMousePosition.y;
          setPanOffset({
              x: panOffset.x + deltaX,
              y: panOffset.y + deltaY,
          });
          return;
      }

      if (tool === "selection") {
          const element = getElementAtPosition(clientX, clientY, elements);
          event.target.style.cursor = element
              ? cursorForPosition(element.position)
              : "default";
      }
      if (action === "angle-drawing" && tool === "angle") {
          const index = elements.length - 1;
          const newElement = { ...elements[index], x2: clientX, y2: clientY };
          setElements((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = newElement;
              return updatedState;
          });
          return;
      }

      if (action === "drawing") {
          const index = elements.length - 1;
          const { x1, y1 } = elements[index];
          updateElement(index, x1, y1, clientX, clientY, tool);
      } else if (action === "moving") {
          if (selectedElement.type === "pencil") {
              const newPoints = selectedElement.points.map((_, index) => ({
                  x: clientX - selectedElement.xOffsets[index],
                  y: clientY - selectedElement.yOffsets[index],
              }));
              const elementsCopy = [...elements];
              elementsCopy[selectedElement.id] = {
                  ...elementsCopy[selectedElement.id],
                  points: newPoints,
              };
              setElements(elementsCopy, true);
          } else {
              const { id, x1, x2, y1, y2, type, offsetX, offsetY } = selectedElement;
              const width = x2 - x1;
              const height = y2 - y1;
              const newX1 = clientX - offsetX;
              const newY1 = clientY - offsetY;
              const options = type === "text" ? { text: selectedElement.text } : {};
              updateElement(
                  id,
                  newX1,
                  newY1,
                  newX1 + width,
                  newY1 + height,
                  type,
                  options
              );
          }
      } else if (action === "resizing") {
          const { id, type, position, ...coordinates } = selectedElement;
          const { x1, y1, x2, y2 } = resizedCoordinates(
              clientX,
              clientY,
              position,
              coordinates
          );
          updateElement(id, x1, y1, x2, y2, type);
      }
  };

  const handleMouseUp = (event) => {
      const { clientX, clientY } = getMouseCoordinates(event);
      if (selectedElement) {
          if (
              selectedElement.type === "text" &&
              clientX - selectedElement.offsetX === selectedElement.x1 &&
              clientY - selectedElement.offsetY === selectedElement.y1
          ) {
              setAction("writing");
              return;
          }

          const index = selectedElement.id;
          const { id, type } = elements[index];
          if (
              (action === "drawing" || action === "resizing") &&
              adjustmentRequired(type)
          ) {
              const { x1, y1, x2, y2 } = adjustElementCoordinates(elements[index]);
              updateElement(id, x1, y1, x2, y2, type);
          }
      }
      if (action === "angle-drawing" && tool === "angle") {
          // Update the angle element with final coordinates
          const index = elements.length - 1;
          const { id, type } = elements[index];
          const { x1, y1, x2, y2 } = elements[index];
          const options = {};
          updateElement(id, x1, y1, x2, y2, type, options);
          setAction("none");
          setSelectedElement(null);
          return;
      }

      if (action === "writing") return;

      setAction("none");
      setSelectedElement(null);
  };

  const handleClearCanvas = () => {
      setElements([]); // Clears all elements by setting an empty array
  };
  const handleBlur = (event) => {
      const { id, x1, y1, type } = selectedElement;
      setAction("none");
      setSelectedElement(null);
      updateElement(id, x1, y1, null, null, type, { text: event.target.value });
  };

  const handleFastForward = () => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.playbackRate = 1;
      const currentTime = videoRef.current.currentTime;
      videoRef.current.currentTime = currentTime + 2;
    }
  };

  const handleSlowForward = () => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.playbackRate = 1;
      const currentTime = videoRef.current.currentTime;
      videoRef.current.currentTime = Math.min(
        videoRef.current.duration,
        currentTime + 1
      );
    }
  };

  const handleFastRewind = () => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.playbackRate = 1;
      const currentTime = videoRef.current.currentTime;
      videoRef.current.currentTime = Math.max(0, currentTime - 2);
    }
  };

  const handleSlowRewind = () => {
    if (videoRef.current && videoRef.current.paused) {
      let playbackRate = videoRef.current.playbackRate;
      if (playbackRate > 0.1) {
        playbackRate -= 0.1;
      }
      videoRef.current.playbackRate = playbackRate;
      const currentTime = videoRef.current.currentTime;
      videoRef.current.currentTime = Math.max(0, currentTime - 1);
    }
  };

  const handlePlay = () => {
    const currentTime = videoRef.current.currentTime;

    videoRef.current.play();
  };

  const handleStop = () => {
    const currentTime = videoRef.current.currentTime;

    videoRef.current.pause();
  };
  const roleActions = {
    Gait: [
      "Initial Contact", "Loading Response", "Midstance", "Terminal Stance", "Preswing", "Initial Swing", "Midswing", "Terminal Swing"
    ],
    Running: [
      "Initial Contact", "Midstance", "Toe Off", "Initial Swing", "Midswing", "Terminal Swing"
    ],
    Archery: [
      "Pre Shot", "Set Up", "Draw", "Anchor", "Release"
    ],
    Sprinting: [
      "Start Phase", "Acceleration Phase", "Maximum Velocity Phase", "Deceleration Phase"
    ],
    Swimming: [
      "Start", "Pull", "Push", "Recovery", "Breathing"
    ],
    Jumping: [
      "Preparation", "Takeoff", "Flight", "Landing"
    ],
    Throwing: [
      "Preparation", "Windup", "Arm Cocking", "Arm Acceleration", "Release", "Follow-Through"
    ],
    Cycling: [
      "Pedal Upstroke", "Top Dead Center", "Power Phase", "Bottom Dead Center", "Recovery Phase"
    ],
    Badminton: [
      "Ready Position", "Footwork", "Stroke Preparation", "Stroke Execution", "Recovery"
    ],
    "Table Tennis": [
      "Ready Position", "Footwork", "Stroke Preparation", "Stroke Execution", "Follow-Through"
    ],
    "Lawn Tennis": [
      "Ready Position", "Footwork", "Backswing", "Forward Swing", "Contact", "Follow-Through"
    ],
    Shooting: [
      "Stance", "Grip", "Aiming", "Breath Control", "Trigger Squeeze", "Follow-Through"
    ],
  };
  
  let data = [];
  
  for (const [roleKey, actions] of Object.entries(roleActions)) {
    if (selectedItem?.includes(roleKey)) {
      data = actions.map(action => ({ name: action }));
      break;
    }
  }
  const handleOpenModal = (title) => {
    setModalTitle(title);
    if (!shotData[title]) {
      setShotData((prevState) => {
        return {
          ...prevState,
          [title]: [],
        };
      });
    }
  };
console.log()
  return (
    <>
    <DashBoardTopNav header={"Spatial"}/>
    <Sidebar/>
    <AssessmentType drawerTitle={drawerTitle} setDrawerTitle={setDrawerTitle} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
          <div className={styles["belownav"]}>
          {data.map((item, index) => (
            <Button
              key={index}
              appearance="primary"
              style={{ background: "cadetblue" }}
              className={styles.btn}
              onClick={() => handleOpenModal(item.name)}
            >
              {item.name}
            </Button>
          ))}
        </div>
   {modalTitle &&<div className="toolbox">
            <div className="toolbox-section">
                <input
                    type="radio"
                    id="selection"
                    checked={tool === "selection"}
                    onChange={() => setTool("selection")}
                /> <label htmlFor="selection">Selection</label>
                <div className="tool-radio">
                    
                <input
                    type="radio"
                    id="line"
                    checked={tool === "line"}
                    onChange={() => setTool("line")}
                />  <label htmlFor="line">Line</label>
                </div>
               <div className="tool-radio">
              
                <input
                    type="radio"
                    id="rectangle"
                    checked={tool === "rectangle"}
                    onChange={() => setTool("rectangle")}
                />
               </div> <label htmlFor="rectangle">Rectangle</label>
                <div className="tool-radio">
                    
                <input
                    type="radio"
                    id="circle"
                    checked={tool === "circle"}
                    onChange={() => setTool("circle")}
                />
                </div>
 <label htmlFor="circle">Circle</label>
               <div className="tool-radio">

                <input
                    type="radio"
                    id="pencil"
                    checked={tool === "pencil"}
                    onChange={() => setTool("pencil")}
                />
               </div> <label htmlFor="pencil">Pencil</label>
               <div className="tool-radio">
                <input
                    type="radio"
                    id="angle"
                    checked={tool === "angle"}
                    onChange={() => setTool("angle")}
                />
                <label htmlFor="angle">Angle</label>
               </div>
               
                <div className="tool-radio">
                    <input
                    type="radio"
                    id="text"
                    checked={tool === "text"}
                    onChange={() => setTool("text")}
                />
                <label htmlFor="text">Text</label>
                </div>
                
            </div>
            <div className="toolbox-section">
                <button onClick={undo}>Undo</button>
                <button onClick={redo}>Redo</button>
                <button onClick={handleClearCanvas}>Clear All</button>
            </div>
            {action === "writing" ? (
                <textarea
                    ref={textAreaRef}
                    onBlur={handleBlur}
                    style={{
                        position: "fixed",
                        top: selectedElement.y1 - 2 + panOffset.y,
                        left: selectedElement.x1 + panOffset.x,
                        font: "24px sans-serif",
                        margin: 0,
                        padding: 0,
                        border: 0,
                        outline: 0,
                        resize: "auto",
                        overflow: "hidden",
                        whiteSpace: "pre",
                        background: "transparent",
                        zIndex: 2,
                    }}
                />
            ) : null}
            
        </div>}
     <div className={styles["toolbar-container"]}>
        <div className={styles["video"]}>
          <video
            style={{ borderRadius: "8px" }}
            ref={videoRef}
            // controls
            width="96%"
            height="400"
            // onTimeUpdate={handleTimeUpdate}
            onClick={(e) => e.stopPropagation()}
          >
            <source src={videoSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <canvas
         id="canvas"
            // className={styles["drawing-canvas"]}
            width={videoRef.current ? videoRef.current.offsetWidth : 0}
            height={videoRef.current ? videoRef.current.offsetHeight : 0}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={{ position: "absolute", zIndex: 1, cursor: "pointer", borderRadius: "8px" }}
          
          />
        </div>
        {modalTitle && 
        <div className={styles["modal-container"]}>
          <div className={styles["modal"]}>
            <div className={styles["modal-header"]}></div>
            <div className={styles["modal-content"]}>
              <div className={styles["button-container"]}>
                <button onClick={handlePlay}>
                  <PlayCircleOutline />
                </button>
                <button onClick={handleStop}>
                  <Stop />
                </button>
                <button onClick={handleSlowForward}>
                  <ArrowForward />
                </button>
                <button onClick={handleFastForward}>
                  <FastForward />
                </button>
                <button onClick={handleSlowRewind}>
                  <ArrowBack />
                </button>
                <button onClick={handleFastRewind}>
                  <FastRewind />
                </button>
              </div>
            </div>
          </div>
        </div>}
      </div>
     
    </>
  );
}
