import React, { useState, useEffect } from "react";
import { SelectPicker } from "rsuite";
import { HttpService } from "../services/http.service";
import {
  EVALUATION_DEPARTMENTS,
  EVALUATION_CATEGORIES,
  ATHLETE_PROFILE_API_URL,
  EVALUATION_FIELDS,
} from "../services/API";

function CascadingDropDownAthlete({
  selectedCategory,
  setSelectedCategory,
  selectedDepartment,
  setSelectedDepartment,
  departments,
  setDepartments,
  categories,
  setCategories,
 
  setAthletes,
 }) {
  const [filteredCategories, setFilteredCategories] = useState([]);
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(ATHLETE_PROFILE_API_URL,{},false);

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await HttpService.get(EVALUATION_DEPARTMENTS,{},false);

        const modifiedDept = response.data.results.map((dept) => ({
          value: dept.id,
          label: dept.name,
        }));

        setDepartments(modifiedDept);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchDepartment();
  }, [setDepartments]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await HttpService.get(EVALUATION_CATEGORIES,{},false);

        const modifiedCategories = response.data.results.map((cat) => ({
          value: cat.id,
          label: cat.name,
          department: cat.department,
        }));

        setCategories(modifiedCategories);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchCategories();
  }, [setCategories]);

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    // Filter categories based on the selected department
    const filteredCategories = categories?.filter(
      (category) => category.department === value
    );
    setFilteredCategories(filteredCategories); // Store filtered categories in a separate state
    // Reset the selected category
    setSelectedCategory(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-evenly",
      }}
    >
      <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
        {/* <Typography textAlign={"center"} fontWeight={"bold"}>
          Department:
        </Typography>{" "} */}
        <SelectPicker
          data={departments}
          value={selectedDepartment}
          onSelect={handleDepartmentChange}
          placeholder="Select Department"
          style={{ width: 200, marginTop: "1rem" }}
        />
      </div>
      {selectedDepartment === null ? null : (
        <>
          <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
            {/* <Typography textAlign={"center"} fontWeight={"bold"}>
              Category:
            </Typography>{" "} */}
            <SelectPicker
              data={filteredCategories}
              value={selectedCategory}
              onSelect={(value) => setSelectedCategory(value)}
              placeholder="Select Category"
              style={{ width: 200, marginTop: "1rem" }}
            />
          </div>
        </>
      )}
     
    </div>
  );
}

function CascadingDropDownNumericAthlete({
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  selectedDepartment,
  setSelectedDepartment,
  departments,
  setDepartments,
  subCategories,
  setSubCategories,
  categories,
  setCategories,
  athletes,
  setAthletes,
  results,
  selectedAthlete,
  setSelectedAthlete,
}) {
  const [filteredCategories, setFilteredCategories] = useState([]);
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(ATHLETE_PROFILE_API_URL,{},false);

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await HttpService.get(EVALUATION_DEPARTMENTS,{},false);

        const modifiedDept = response.data.results.map((dept) => ({
          value: dept.id,
          label: dept.name,
        }));

        setDepartments(modifiedDept);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchDepartment();
  }, [setDepartments]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await HttpService.get(EVALUATION_CATEGORIES,{},false);

        const modifiedCategories = response.data.results.map((cat) => ({
          value: cat.id,
          label: cat.name,
          department: cat.department,
        }));

        setCategories(modifiedCategories);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchCategories();
  }, [setCategories]);
  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        if (selectedCategory) {
          // Fetch sub-categories only if a category is selected
          const response = await HttpService.get(
            `${EVALUATION_FIELDS}?categories=${selectedCategory}`,{},false
          );

          const numericFields = response.data.results.filter(
            (subCat) =>
              subCat.field_type === "Int" || subCat.field_type === "Range" ||subCat.field_type === "Float" || subCat.field_type === "int" || subCat.field_type === "integer" 
          );

          if (numericFields.length > 0) {
            const modifiedSubCategories = numericFields.map((subCat) => ({
              value: subCat.id,
              label: subCat.name,
            }));

            setSubCategories(modifiedSubCategories);
          } else {
            // No numeric fields found, display "No numeric data available"
            setSubCategories([
              {
                value: "no_numeric_data",
                label: "No numeric data available",
              },
            ]);
          }
        } else {
          // Reset sub-categories when no category is selected
          setSubCategories([]);
        }
      } catch (error) {
        console.warn(error);
      }
    };

    fetchSubCategories();
  }, [selectedCategory, setSubCategories]);

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    // Filter categories based on the selected department
    const filteredCategories = categories?.filter(
      (category) => category.department === value
    );
    setFilteredCategories(filteredCategories); // Store filtered categories in a separate state
    // Reset the selected category
    setSelectedCategory(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-evenly",
      }}
    >
      <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
        {/* <Typography textAlign={"center"} fontWeight={"bold"}>
          Department:
        </Typography>{" "} */}
        <SelectPicker
          data={departments}
          value={selectedDepartment}
          onSelect={handleDepartmentChange}
          placeholder="Select Department"
          style={{ width: 200, marginTop: "1rem" }}
        />
      </div>
      {selectedDepartment === null ? null : (
        <>
          <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
            {/* <Typography textAlign={"center"} fontWeight={"bold"}>
              Category:
            </Typography>{" "} */}
            <SelectPicker
              data={filteredCategories}
              value={selectedCategory}
              onSelect={(value) => setSelectedCategory(value)}
              placeholder="Select Category"
              style={{ width: 200, marginTop: "1rem" }}
            />
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {selectedCategory === null ? null : (
          <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
            <SelectPicker
              data={subCategories}
              value={selectedSubCategory}
              onSelect={(value) => setSelectedSubCategory(value)}
              placeholder="Select Fields"
              style={{ width: 200, marginTop: "1rem" }}
            />
          </div>
        )}
      
      </div>
    </div>
  );
}

function CascadingDropDownCategoricalAthlete({
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  selectedDepartment,
  setSelectedDepartment,
  departments,
  setDepartments,
  subCategories,
  setSubCategories,
  categories,
  setCategories,
  athletes,
  setAthletes,
  results,
  selectedAthlete,
  setSelectedAthlete,
}) {
  const [filteredCategories, setFilteredCategories] = useState([]);
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(ATHLETE_PROFILE_API_URL,{},false);

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await HttpService.get(EVALUATION_DEPARTMENTS,{},false);

        const modifiedDept = response.data.results.map((dept) => ({
          value: dept.id,
          label: dept.name,
        }));

        setDepartments(modifiedDept);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchDepartment();
  }, [setDepartments]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await HttpService.get(EVALUATION_CATEGORIES,{},false);

        const modifiedCategories = response.data.results.map((cat) => ({
          value: cat.id,
          label: cat.name,
          department: cat.department,
        }));

        setCategories(modifiedCategories);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchCategories();
  }, [setCategories]);
  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        if (selectedCategory) {
          // Fetch sub-categories only if a category is selected
          const response = await HttpService.get(
            `${EVALUATION_FIELDS}?categories=${selectedCategory}`,{},false
          );

          const numericFields = response.data.results.filter(
            (subCat) => subCat.field_type === "Choice" || subCat.field_type === "choice"
          );

          if (numericFields.length > 0) {
            const modifiedSubCategories = numericFields.map((subCat) => ({
              value: subCat.id,
              label: subCat.name,
            }));

            setSubCategories(modifiedSubCategories);
          } else {
            // No numeric fields found, display "No numeric data available"
            setSubCategories([
              {
                value: "no_categorical_data",
                label: "No categorical data available",
              },
            ]);
          }
        } else {
          // Reset sub-categories when no category is selected
          setSubCategories([]);
        }
      } catch (error) {
        console.warn(error);
      }
    };

    fetchSubCategories();
  }, [selectedCategory, setSubCategories]);

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    // Filter categories based on the selected department
    const filteredCategories = categories?.filter(
      (category) => category.department === value
    );
    setFilteredCategories(filteredCategories); // Store filtered categories in a separate state
    // Reset the selected category
    setSelectedCategory(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-evenly",
      }}
    >
      <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
        {/* <Typography textAlign={"center"} fontWeight={"bold"}>
          Department:
        </Typography>{" "} */}
        <SelectPicker
          data={departments}
          value={selectedDepartment}
          onSelect={handleDepartmentChange}
          placeholder="Select Department"
          style={{ width: 200, marginTop: "1rem" }}
        />
      </div>
      {selectedDepartment === null ? null : (
        <>
          <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
            {/* <Typography textAlign={"center"} fontWeight={"bold"}>
              Category:
            </Typography>{" "} */}
            <SelectPicker
              data={filteredCategories}
              value={selectedCategory}
              onSelect={(value) => setSelectedCategory(value)}
              placeholder="Select Category"
              style={{ width: 200, marginTop: "1rem" }}
            />
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {selectedCategory === null ? null : (
          <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
            <SelectPicker
              data={subCategories}
              value={selectedSubCategory}
              onSelect={(value) => setSelectedSubCategory(value)}
              placeholder="Select Fields"
              style={{ width: 200, marginTop: "1rem" }}
            />
          </div>
        )}
       
      </div>
    </div>
  );
}

function CascadingDropDownBivariateAthlete({
  selectedCategory,
  setSelectedCategory,
  selectedDepartment,
  setSelectedDepartment,
  departments,
  setDepartments,
  subCategories,
  setSubCategories,
  categories,
  setCategories,
  athletes,
  setAthletes,
  results,
  selectedAthlete,
  setSelectedAthlete,
  selectedFields,
  setSelectedFields,
  availableFieldsForFirstDropdown,
  setAvailableFieldsForFirstDropdown,
  availableFieldsForSecondDropdown,
  setAvailableFieldsForSecondDropdown,
}) {
  const [filteredCategories, setFilteredCategories] = useState([]);

  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(ATHLETE_PROFILE_API_URL,{},false);

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await HttpService.get(EVALUATION_DEPARTMENTS,{},false);

        const modifiedDept = response.data.results.map((dept) => ({
          value: dept.id,
          label: dept.name,
        }));

        setDepartments(modifiedDept);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchDepartment();
  }, [setDepartments]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await HttpService.get(EVALUATION_CATEGORIES,{},false);

        const modifiedCategories = response.data.results.map((cat) => ({
          value: cat.id,
          label: cat.name,
          department: cat.department,
        }));

        setCategories(modifiedCategories);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchCategories();
  }, [setCategories]);

  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        if (selectedCategory) {
          // Fetch sub-categories only if a category is selected
          const response = await HttpService.get(
            `${EVALUATION_FIELDS}?categories=${selectedCategory}`,{},false
          );

          const numericFields = response.data.results.filter(
            (subCat) =>
              subCat.field_type === "Int" || subCat.field_type === "Range"||subCat.field_type === "Float"|| subCat.field_type === "int" || subCat.field_type === "integer"
          );

          const numericFieldIds = numericFields.map((subCat) => subCat.id);

          setAvailableFieldsForFirstDropdown(numericFieldIds); // Update available fields for the first dropdown
          setAvailableFieldsForSecondDropdown(numericFieldIds); // Update available fields for the second dropdown

          if (numericFields.length > 0) {
            const modifiedSubCategories = numericFields.map((subCat) => ({
              value: subCat.id,
              label: subCat.name,
            }));

            setSubCategories(modifiedSubCategories);
          } else {
            // No numeric fields found, display "No numeric data available"
            setSubCategories([
              {
                value: "no_numeric_data",
                label: "No numeric data available",
              },
            ]);
          }
        } else {
          // Reset sub-categories when no category is selected
          setSubCategories([]);
        }
      } catch (error) {
        console.warn(error);
      }
    };

    fetchSubCategories();
  }, [selectedCategory, setSubCategories,setAvailableFieldsForFirstDropdown,setAvailableFieldsForSecondDropdown]);

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    // Filter categories based on the selected department
    const filteredCategories = categories?.filter(
      (category) => category.department === value
    );
    setFilteredCategories(filteredCategories); // Store filtered categories in a separate state
    // Reset the selected category and fields
    setSelectedCategory(null);
    setSelectedFields([]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
        <SelectPicker
          data={departments}
          value={selectedDepartment}
          onSelect={handleDepartmentChange}
          placeholder="Select Department"
          style={{ width: 200, marginTop: "1rem" }}
        />
      </div>
      {selectedDepartment === null ? null : (
        <>
          <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
            <SelectPicker
              data={filteredCategories}
              value={selectedCategory}
              onSelect={(value) => {
                setSelectedCategory(value);
                // Reset selected fields when the category changes
                setSelectedFields([]);
              }}
              placeholder="Select Category"
              style={{ width: 200, marginTop: "1rem" }}
            />
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {selectedCategory === null ? null : (
          <>
            <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
              <SelectPicker
                data={subCategories.filter((field) =>
                  availableFieldsForFirstDropdown.includes(field.value)
                )}
                value={selectedFields[0]}
                onSelect={(value) =>
                  setSelectedFields([value, selectedFields[1]])
                }
                placeholder="Select Field 1"
                style={{ width: 200, marginTop: "1rem" }}
              />
            </div>
            <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
              <SelectPicker
                data={subCategories.filter(
                  (field) =>
                    availableFieldsForSecondDropdown.includes(field.value) &&
                    field.value !== selectedFields[0]
                )}
                value={selectedFields[1]}
                onSelect={(value) =>
                  setSelectedFields([selectedFields[0], value])
                }
                placeholder="Select Field 2"
                style={{ width: 200, marginTop: "1rem" }}
              />
            </div>
          </>
        )}
       
      </div>
    </div>
  );
}
function CascadingDropDownMultivariateAthlete({
  selectedCategory,
  setSelectedCategory,
  selectedDepartment,
  setSelectedDepartment,
  departments,
  setDepartments,
  categories,
  setCategories,
  athletes,
  setAthletes,
  results,
  selectedAthlete,
  setSelectedAthlete,
}) {
  const [filteredCategories, setFilteredCategories] = useState([]);
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(ATHLETE_PROFILE_API_URL,{},false);

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await HttpService.get(EVALUATION_DEPARTMENTS,{},false);

        const modifiedDept = response.data.results.map((dept) => ({
          value: dept.id,
          label: dept.name,
        }));

        setDepartments(modifiedDept);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchDepartment();
  }, [setDepartments]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await HttpService.get(EVALUATION_CATEGORIES,{},false);

        const modifiedCategories = response.data.results.map((cat) => ({
          value: cat.id,
          label: cat.name,
          department: cat.department,
        }));

        setCategories(modifiedCategories);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchCategories();
  }, [setCategories]);
 

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    // Filter categories based on the selected department
    const filteredCategories = categories?.filter(
      (category) => category.department === value
    );
    setFilteredCategories(filteredCategories); // Store filtered categories in a separate state
    // Reset the selected category
    setSelectedCategory(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-evenly",
      }}
    >
      <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
        {/* <Typography textAlign={"center"} fontWeight={"bold"}>
          Department:
        </Typography>{" "} */}
        <SelectPicker
          data={departments}
          value={selectedDepartment}
          onSelect={handleDepartmentChange}
          placeholder="Select Department"
          style={{ width: 200, marginTop: "1rem" }}
        />
      </div>
      {selectedDepartment === null ? null : (
        <>
          <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
            {/* <Typography textAlign={"center"} fontWeight={"bold"}>
              Category:
            </Typography>{" "} */}
            <SelectPicker
              data={filteredCategories}
              value={selectedCategory}
              onSelect={(value) => setSelectedCategory(value)}
              placeholder="Select Category"
              style={{ width: 200, marginTop: "1rem" }}
            />
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
       
      
      </div>
    </div>
  );
}
export {
  CascadingDropDownAthlete,
  CascadingDropDownNumericAthlete,
  CascadingDropDownCategoricalAthlete,
  CascadingDropDownBivariateAthlete,
  CascadingDropDownMultivariateAthlete
};
