import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/AuthContext"; // Import AuthContext
import { ButtonToolbar, Loader, Button, Modal } from "rsuite";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { HttpService } from "../../services/http.service";
import { BASE_URL, USER_API_URL } from "../../services/API";
import { Typography } from "@mui/material";
import style from "./../../pages/sportsscience/science.module.css";
// import OffRoundIcon from "@rsuite/icons/OffRound";
import { Color } from "../../utils/Colors";
import CopyIcon from "@rsuite/icons/Copy";
// import HelpIcon from "@mui/icons-material/Help";
// import PageIcon from "@rsuite/icons/Page";
// import PeoplesIcon from "@rsuite/icons/Peoples";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import UserCustomDrawer from "../../components/CustomDrawer";
import CustomNotification from "../../components/CustomNotification";
export default function Information({ setCopied, setCount }) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const authLogout = authCtx.logout;
  const [errorCount, setErrorCount] = useState(0);
  const [error, setError] = useState();
  const [res, setResponse] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [imageProfile, setImageProfile] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    confirmed: false,
  });
  const superuser = process.env.REACT_APP_SUPER_USERS;
// console.log(superuser)
  const superUsersArray = superuser?.split(",");

  // Check if the user_email is in the superUsersArray
  const isSuperUser = superUsersArray?.includes(user?.email);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setCount((prev) => prev + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${USER_API_URL}${decode.user_id}/`,
          {},
          true
        );
        setUser(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [decode.user_id]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const baseURL = BASE_URL(decode.account);
        const mediaURL = `${baseURL}/media`;
        const imageUrl = `${mediaURL}${user.profile_photo_url}`;
        const response = await fetch(imageUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const blob = await response.blob();
          const objectURL = URL.createObjectURL(blob);
          setImageProfile(objectURL);
          setIsImageLoaded(true);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };
    if(user?.profile_photo_url?.length >0){
    fetchImageUrls()}
  }, [user, decode.account, token]);
  const handleLogout = async () => {
    await authLogout();
    navigate("/");
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  function stringAvatarFirst(name) {
   
   
    return name?.length <= 0 || name === undefined
      ? null
      : {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(" ")[0][0]}`,
        };
  }

  const handlePasswordChange = (e, field) => {
    const { value } = e.target;
    setPassword((prevState) => ({
      ...prevState,
      [field]: value,
      confirmed: prevState.newPassword === value, // check if newPassword matches confirmPassword
    }));
  };

  const handleChangePassword = async () => {
    try {
      const formData = {
        old_password: password.oldPassword,
        new_password: password.newPassword,
      };
      const response = await HttpService.post(
        `${USER_API_URL}${decode.user_id}/change_password/`,
        {},
        formData,
        true
      );

      setResponse(response);

      if (response.status === 200) {
        setErrorCount((prevCount) => prevCount + 1);
        setError("Form Submitted"); // Update error state to indicate success
        setOpenModal(true);
        setPasswordModal(!passwordModal);
        return; // Exit early to prevent further execution
      }
    } catch (e) {
      let errorMessage = "An error occurred"; // Default error message

      if (e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setError(errorMessage);

      if (e.response && e.response.status !== 201) {
        setErrorCount((prevCount) => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };
  // console.log(res)
  return (
    <>
      <div
        style={{
          height: "214px",
          width: "75%",
          margin: "0 auto",
          position: "absolute",
          top: "200px",
          transform: "translateY(-50%)",
          left: "56px",
        }}
      >
        {user ? (
          <>
            <div
              style={{
                position: "absolute",
                top: "-50px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1,
              }}
            >
              {isImageLoaded === true ? (
                <Avatar
                  src={imageProfile}
                  sx={{ width: 100, height: 100, borderRadius: "50%" }}
                  alt={`${user.first_name} ${user.last_name}`}
                />
              ) : (
                 <>
                  {user?.last_name?.length !== 0? <Avatar
                
                {...stringAvatar(`${user.first_name} ${user.last_name}`)}
                sx={{
                  width: 100, height: 100, borderRadius: "50%"
                }}
              />: <Avatar
                
              {...stringAvatarFirst(`${user.first_name} ${user.last_name}`)}
              sx={{
                width: 100, height: 100, borderRadius: "50%"
              }}
            />}
                  </>
              )}
            </div>
            <div
              style={{
                backgroundColor: Color.Color_C,
                marginTop: "16px",
                padding: "16px",
                borderRadius: "8px",
                zIndex: 0,
              }}
            >
              <div
                style={{
                  marginTop: "30px",
                  textAlign: "center",
                  color: Color.Color_AA,
                }}
              >
                <Typography variant="h6">
                  {user.first_name + " " + user.last_name}
                </Typography>
                <Typography fontSize={12}>
                  {user.email}
                  <CopyIcon
                    onClick={() => copyToClipboard(user.email)}
                    className={style.copy}
                  />
                </Typography>
              </div>
              <div style={{ marginTop: "16px" }}>
                <ButtonToolbar
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {decode.roles === "Athlete" ? null : (
                    <Button
                      style={{
                        backgroundColor: Color.Color_A,
                        color: Color.Color_D,
                        width: "100px",
                        cursor: "pointer",
                      }}
                      appearance="primary"
                      size="xs"
                      onClick={() => navigate("/account_details")}
                    >
                      Account
                    </Button>
                  )}

                  <Button
                    style={{
                      backgroundColor: Color.Color_A,
                      color: Color.Color_D,
                      width: "100px",
                      cursor: "pointer",
                    }}
                    appearance="primary"
                    size="xs"
                    onClick={() => {
                      setPasswordModal(!passwordModal);
                    }}
                  >
                    Password
                  </Button>
                </ButtonToolbar>
              </div>
              <div style={{ marginTop: "16px" }}>
                <ButtonToolbar
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: Color.Color_D,
                      width: "100px",
                      cursor: "pointer",
                    }}
                    appearance="primary"
                    size="xs"
                    onClick={handleLogout}
                  >
                    Sign Out
                  </Button>
                </ButtonToolbar>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      <div
        style={{
          height: "214px",
          width: "75%",
          margin: "0 auto",
          position: "absolute",
          top: "420px",
          transform: "translateY(-50%)",
          left: "56px",
        }}
      >
        <div
          style={{
            backgroundColor: Color.Color_C,
            marginTop: "16px",
            padding: "16px",
            borderRadius: "8px",
            zIndex: 0,
          }}
        >
          <div
            style={{
              // marginTop: "30px",
              textAlign: "center",
              color: Color.Color_AA,
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={{ cursor: "pointer" }} variant="body1">
              Blogs
            </Typography>
            <Typography style={{ cursor: "pointer" }} variant="body1">
              Community
            </Typography>
            <Typography style={{ cursor: "pointer" }} variant="body1">
              Help
            </Typography>
          </div>
        </div>
      </div>
      {isSuperUser === true ? (
        <>
          {" "}
          <div
            style={{
              height: "214px",
              width: "75%",
              margin: "0 auto",
              position: "absolute",
              top: "700px",
              transform: "translateY(-50%)",
              left: "56px",
            }}
          >
            <div
              style={{
                backgroundColor: Color.Color_C,
                marginTop: "16px",
                padding: "16px",
                borderRadius: "8px",
                zIndex: 0,
              }}
            >
              <div
                style={{
                  // marginTop: "30px",
                  textAlign: "center",
                  color: Color.Color_AA,
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpen(true);
                    setDrawerTitle("Account");
                  }}
                >
                  Create Account
                </label>
                <label
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpen(true);
                    setDrawerTitle("Subscription");
                  }}
                >
                  Create Subscription
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "214px",
              width: "75%",
              margin: "0 auto",
              position: "absolute",
              top: "800px",
              transform: "translateY(-50%)",
              left: "56px",
            }}
          >
            <div
              style={{
                backgroundColor: Color.Color_C,
                marginTop: "16px",
                padding: "16px",
                borderRadius: "8px",
                zIndex: 0,
              }}
            >
              <div
                style={{
                  // marginTop: "30px",
                  textAlign: "center",
                  color: Color.Color_AA,
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpen(true);
                    setDrawerTitle("APPPermission");
                  }}
                >
                  App Permission
                </label>
                <label
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpen(true);
                    setDrawerTitle("APPPermissionURL");
                  }}
                >
                  App Permission Url
                </label>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div
        style={{
          height: "214px",
          width: "75%",
          margin: "0 auto",
          position: "absolute",
          top: "500px",
          transform: "translateY(-50%)",
          left: "56px",
        }}
      >
        <div
          style={{
            backgroundColor: Color.Color_A,
            marginTop: "16px",
            padding: "16px",
            borderRadius: "8px",
            zIndex: 0,
          }}
        >
          <div
            style={{
              // marginTop: "30px",
              textAlign: "left",
              color: Color.Color_D,
              flexDirection: "column",
              display: "flex",
              justifyContent: "left",
              alignContent: "center",
            }}
          >
            <Typography variant="body1">
              Follow us for latest updates!
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
              }}
            >
              <LinkedInIcon />
              <Typography variant="body1">LinkedIn</Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
              }}
            >
              <InstagramIcon />{" "}
              <Typography variant="body1">Instagram </Typography>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
              }}
            >
              <FacebookIcon /> <Typography variant="body1">Facebook</Typography>
            </div>
          </div>
        </div>
      </div>
      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={"right"}
        mode={drawerTitle}
      />
      <Modal
        open={passwordModal}
        onClose={() => setPasswordModal(!passwordModal)}
      >
        <Modal.Header style={{ fontWeight: "bolder", textAlign: "center" }}>
          Change Password
        </Modal.Header>
        <div>
          <div style={{ margin: "5px", padding: "8px" }}>
            <label style={{ display: "block", marginBottom: "5px" }}>
              Old Password
            </label>
            <input
              type="password"
              style={{
                padding: "5px",
                borderRadius: "3px",
                border: "1px solid #ccc",
                width: "100%",
              }}
              value={password.oldPassword}
              onChange={(e) => handlePasswordChange(e, "oldPassword")}
            />
          </div>
          <div style={{ margin: "5px", padding: "8px" }}>
            <label style={{ display: "block", marginBottom: "5px" }}>
              New Password
            </label>
            <input
              type="password"
              style={{
                padding: "5px",
                borderRadius: "3px",
                border: "1px solid #ccc",
                width: "100%",
              }}
              value={password.newPassword}
              onChange={(e) => handlePasswordChange(e, "newPassword")}
            />
          </div>
          <div style={{ margin: "5px", padding: "8px" }}>
            <label style={{ display: "block", marginBottom: "5px" }}>
              Confirm Password
            </label>
            <input
              type="password"
              style={{
                padding: "5px",
                borderRadius: "3px",
                border:password.confirmPassword?.length > 0? password.confirmed
                  ? "1px solid green"
                  : "1px solid red":"1px solid #ccc", // change border color based on confirmation status
                width: "100%",
              }}
              value={password.confirmPassword}
              onChange={(e) => handlePasswordChange(e, "confirmPassword")}
            />
          </div>

          {password.confirmPassword?.length > 0 ? password.confirmed ? (
            <p style={{ textAlign: "center" }}>Passwords match!</p>
          ) : (
            <p style={{ textAlign: "center", color: "red" }}>
              Passwords do not match...!!
            </p>
          ):null}
        </div>

        <Button
          disabled={password.confirmed === false}
          appearance="primary"
          size="xs"
          style={{ marginLeft: "16px", backgroundColor: Color.Color_D }}
          onClick={handleChangePassword}
        >
          Submit
        </Button>
      </Modal>
      <CustomNotification
        open={openModal}
        errorCount={errorCount}
        setOpen={setOpenModal}
        message={error ? error : (res && res.data && res.data.message) || ""}
        title={error === "Form Submitted" ? "Success" : "Error"}
      />
    </>
  );
}
