import React from "react";
import "./../landing/styles.css";
import { Form, Button, SelectPicker, FlexboxGrid } from "rsuite";
import { HttpService } from "../../services/http.service";
import {
  EVENT_RESULT_API_URLS,
  TEAM_API_URL,
  ATHLETE_PROFILE_API_URL,
  // USER_API_URL,
  EVENT_API_URLS,
} from "../../services/API";
import CustomNotification from "../CustomNotification";
import { useEffect, useContext } from "react";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import { Color } from "../../utils/Colors";
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter}style={{width:"240px"}} {...rest} />
    </Form.Group>
  );
});

export default function EventResult({  onSubmitSuccess,setCount}) {
  const [errorCount, setErrorCount] = React.useState(0);
  const [athlete, setAthlete] = React.useState();
  const [athletes, setAthletes] = React.useState();
  const [team, setTeam] = React.useState();
  const [teams, setTeams] = React.useState();
  const [event, setEvent] = React.useState();
  const [events, setEvents] = React.useState();
  // const [endTime, setEndTime] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number

  const formRef = React.useRef();
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const [formValue, setFormValue] = React.useState({
    position: "",
    description: "",
    result_status: "",
  });

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const response = await HttpService.get(
          `${TEAM_API_URL}?page=${currentPage}`,
          {},
          false
        );

        const modifiedTeams = response.data.results.map((t) => ({
          value: t.id,
          label: t.name,
        }));

        setTeams(modifiedTeams);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchTeam();
  }, [currentPage]);
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await HttpService.get(
          `${EVENT_API_URLS}?page=${currentPage}`,
          {},
          false
        );

        const modifiedEvents = response.data.results.map((e) => ({
          value: e.id,
          label: e.name,
        }));

        setEvents(modifiedEvents);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchEvent();
  }, [currentPage]);
  useEffect(() => {
    const fetchAthlete = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`,
          {},
          false
        );

        const modifiedAthlete = response.data.results.map((ath) => ({
          value: ath.id,
          label: ath.name,
        }));

        setAthletes(modifiedAthlete);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthlete();
  }, [currentPage]);

  // console.log(user);

  const handleSubmit = async () => {
    try {
      const formData = {
        ...formValue,
        account: decode.account,
        event,
        team,
        athlete,
        created_by: decode.user_id,
      };
      const response = await HttpService.post(
        EVENT_RESULT_API_URLS,
        {},
        formData,
        false
      );

      setResponse(response);

      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setError("Form Submitted"); // Update error state to indicate success
        setOpenModal(true);
        setFormValue({ position: "" ,description:"",result_status:""});
        onSubmitSuccess()
        return; // Exit early to prevent further execution
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        // If there's a response indicating a server error (status other than 201), open the modal
        setErrorCount(prevCount => prevCount + 1);
       
        setOpenModal(true);
      }
    }
  };

  return (
    <div>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={12}>
          <Form ref={formRef} onChange={setFormValue} formValue={formValue}>
            <SelectPicker
              label="Select Team"
              data={teams}
              onSelect={(value) => setTeam(value)}
              style={{ width: 300 }}
            />
            <br />
            <br />
            <SelectPicker
              label="Select Event"
              data={events}
              onSelect={(value) => setEvent(value)}
              style={{ width: 300 }}
            />
            <br />
            <br />
            <SelectPicker
              label="Select Athlete"
              data={athletes}
              onSelect={(value) => setAthlete(value)}
              style={{ width: 300 }}
            />
            <br />
            <br />

            <TextField name="result_status" label="Result Status" />
            <TextField name="description" label="Description" />
            <TextField name="position" label="Position" />
            {/* <label>Date</label> */}

            <Button
              appearance="primary"
              onClick={handleSubmit}
              style={{
                background: Color.Color_C,
                color: Color.Color_A,
                width: 240,
              }}
            >
               Submit Result
            </Button>

            <CustomNotification
            errorCount={errorCount}
              open={openModal}
              setOpen={setOpenModal}
              nav={"/event/setup"}
              message={
                error ? error : (res && res.data && res.data.message) || ""
              }
              title={error === "Form Submitted" ? "Success" : "Error"}
            />
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
}
