import React, { useEffect } from "react";
import { HttpService } from "../../services/http.service";
import { SelectPicker, Loader, Button } from "rsuite";
import { DataGrid } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import {
  EVALUATION_RESULTS,
  ATHLETE_PROFILE_API_URL,
} from "../../services/API";
import { Bar } from "react-chartjs-2";
import styles from "./injury.module.css";
import { Color } from "../../utils/Colors";
export default function Statistics() {
  const [evaluationData, setEvaluationData] = React.useState([]);
  const [athlete, setAthlete] = React.useState([]);
  const [athletes, setAthletes] = React.useState([]);
  const [evaluations, setEvaluations] = React.useState([]);
  const [evaluation, setEvaluation] = React.useState([]);
  const [showStatistics, setShowStatistics] = React.useState(false);

  const handleGenerateStatistics = () => {
    setShowStatistics(true);
  };
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(
          ATHLETE_PROFILE_API_URL,
          {},
          false
        );

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, []);
  useEffect(() => {
    const fetchEvaluations = async () => {
      if (athlete) {
        try {
          const response = await HttpService.get(EVALUATION_RESULTS, {}, false);

          const uniqueFieldsSet = new Set();
          const modifiedEvaluations = response.data.results.map((evaluation) => {
            const field = evaluation.field;

            // Check if string_value or remarks is not null, and exclude the evaluation
            if (evaluation.string_value !== null || evaluation.remarks !== null) {
              return null;
            }

            // Check if the field is already in the set
            if (!uniqueFieldsSet.has(field)) {
              uniqueFieldsSet.add(field);

              return {
                value: field,
                label: evaluation.field_name,
              };
            }

            // If the field is already in the set, return null or an empty object
            return null;
          });

          // Filter out null or empty objects
          const filteredEvaluations = modifiedEvaluations.filter(
            (evaluation) => evaluation !== null
          );

          setEvaluations(filteredEvaluations);
        } catch (error) {
          console.warn(error);
        }
      }
    };
    fetchEvaluations()
  }, [athlete])

  useEffect(() => {
    const fetchEvaluationsData = async () => {
      try {
        if (athlete.length <=0 || evaluation.length<=0) {
          // Display an alert if either athlete or evaluation is not selected
        
          return null
        }
  
        const response = await HttpService.get(
          `${EVALUATION_RESULTS}?athlete=${athlete}&field=${evaluation}`,
          {}, // You may need to pass additional parameters here if required
          false
        );
  
        const modifiedEvaluationsData = response.data.results.map((result, index) => ({
          id: index,
          date: result.created_at.slice(0, 10),
          field_name: result.field_name,
          type: result.boolean_value !== false
            ? "boolean"
            : result.choice_value !== null
            ? "choice"
            : result.date_value !== null
            ? "date"
            : result.float_value !== null
            ? "number"
            : result.integer_value !== null
            ? "number"
            : result.remarks !== null
            ? "text"
            : result.string_value !== null
            ? "text"
            : null,
          value:
            result.boolean_value !== false
              ? result.boolean_value
              : result.choice_value !== null
              ? result.choice_value
              : result.date_value !== null
              ? result.date_value
              : result.float_value !== null
              ? result.float_value
              : result.integer_value !== null
              ? result.integer_value
              : result.remarks !== null
              ? result.remarks
              : result.string_value !== null
              ? result.string_value
              : null,
        }));
  
        setEvaluationData(modifiedEvaluationsData);
      } catch (error) {
        console.warn(error);
      }
    };
  
    fetchEvaluationsData();
  }, [athlete, evaluation]);
   console.log(athlete)
  const columns = [
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "field_name",
      headerName: "Test Name",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      headerName: "Result",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
  ];
  const customColors = [
    "rgba(54, 162, 235, 0.6)",
    "rgba(255, 99, 132, 0.6)",
    "rgba(255, 205, 86, 0.6)",
    "rgba(75, 192, 192, 0.6)",
    "rgba(153, 102, 255, 0.6)",
    "rgba(255, 159, 64, 0.6)",
    "rgba(201, 203, 207, 0.6)",
  ]
  function generateBarDatasets(data) {
    const uniqueFieldNames = Array.from(
      new Set(data.map((result) => result.field_name))
    );

    // Define an array of colors to assign to datasets
    const backgroundColors = uniqueFieldNames.map((fieldName, index) => {
      const colorIndex = index % customColors.length;
      return customColors[colorIndex];
    });

    return uniqueFieldNames.map((fieldName, index) => {
      const datasetData = data
        .filter((result) => result.field_name === fieldName)
        .map((result) => ({
          x: result.date.slice(0, 10),
          y: result.value,
        }));

      return {
        label: fieldName,
        data: datasetData,
        backgroundColor: backgroundColors[index], // Assign a unique color to the dataset
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        barPercentage: 0.2,
        barThickness: 20,
        borderRadius: 30,
      borderSkipped: "bottom",
      };
    });
  }

  const calculateStatistics = () => {
    if (evaluationData == null || evaluationData.length === 0) {
      return "No Data";
    }

    const values = evaluationData.map((result) => result.value);

    if (evaluationData[0]?.type === "text") {
      // If the type is 'text', render 'Not Allowed'
      return "Not Allowed";
    }

    if (evaluationData[0]?.type === "choice") {
      // If the type is 'choice', calculate mode
      const counts = values.reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
      }, {});

      const mode = Object.keys(counts).reduce((a, b) =>
        counts[a] > counts[b] ? a : b
      );

      return { mode };
    }

    // Calculate average, mean, and standard deviation

    const mean = (values.reduce((acc, val) => acc + val, 0) / values.length).toFixed(2);
    const sortedValues = [...values].sort((a, b) => a - b);
    const middle = Math.floor(sortedValues.length / 2);
    const median =
      sortedValues.length % 2 === 0
        ? (sortedValues[middle - 1] + sortedValues[middle]) / 2
        : sortedValues[middle];
    const counts = values.reduce((acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {});

    const mode = Object.keys(counts).reduce((a, b) =>
      counts[a] > counts[b] ? a : b
    );

    const squaredDifferences = values.map((val) => Math.pow(val - mean, 2));
    const sumSquaredDifferences = squaredDifferences.reduce(
      (acc, val) => acc + val,
      0
    );
    const variance = sumSquaredDifferences / values.length;
    const standardDeviation = Math.sqrt(variance).toFixed(2);

    return { mean, standardDeviation, median, mode };
  };

  const statistics = calculateStatistics();

  return (
    <div>
      <div  >
       
          <div className={styles.belownav}  >
            <SelectPicker
              label={athlete?.length <= 0 ? "Select Athlete" : athlete?.name}
              data={athletes}
              value={athlete}
              onSelect={(value) => setAthlete(value)}
              className={styles.selectpicker}
              onClean={()=>{
                setAthlete([])
                setEvaluation([])
                }}
            />
           



            <SelectPicker
            disabled={athlete?.length <=0 && evaluation?.length <=0}
              label={
                evaluation?.length <= 0 ? "Select Evaluation" : evaluation?.name
              }
              className={styles.selectpicker}
              data={evaluations}
              value={evaluation}
              onSelect={(value) => setEvaluation(value)}
              onClean={()=>{
              setEvaluationData([])
              setShowStatistics(false)
              }}
            />
           
            <Button
              style={{ backgroundColor: "cadetblue" }}
              disabled={evaluationData?.length <= 0}
              className={styles.btn}
              appearance="primary"
              onClick={handleGenerateStatistics}
            >
              Generate Statistics
            </Button>

          </div>
       
      </div>
      <div className={styles.box_science_sports7}>
        {evaluationData?.length <= 0 ? (
          <h6
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "center",
              padding: "2rem",
            }}
          >
            No Evaluation Data
          </h6>
        ) : (
          <div>
            <div>
              <div>
                <DataGrid
                  style={{ borderRadius: "1rem" }}
                  key={Math.random().toLocaleString()}
                  getRowId={(row) => row.id}
                  rows={evaluationData}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 4,
                      },
                    },
                  }}
                  pageSizeOptions={[4]}
                />
              </div>{" "}
              <div>
                {evaluationData[0]?.type === "text" ||
                  evaluationData[0]?.type === "choice" ? (
                  <div style={{ marginTop: "2rem", padding: "2rem" }}>
                    <Typography variant="body1" align="center">
                      No Chart
                    </Typography>
                  </div>
                ) : (
                  <Bar
                    style={{ padding: "12px" }}
                    data={{
                      labels: evaluationData?.map((result) => result.date),
                      datasets: generateBarDatasets(evaluationData), // Pass customColors array
                    }}
                    options={{
                      scales: {
                        x: {
                          stacked: false,
                          title: {
                            display: true,
                            text: 'Date', // Label for the x-axis
                            font: {
                              size: 16,
                              weight: 'bold'
                            }
                          }
                        },
                        y: {
                          stacked: false,
                          title: {
                            display: true,
                            text: 'Value', // Label for the y-axis
                            font: {
                              size: 16,
                              weight: 'bold'
                            }
                          }
                        }
                      },
                      plugins: {
                        title: {
                          display: true,
                          text: evaluationData?.field_name,
                        },
                        legend: {
                          display: true,
                          position: "bottom",
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {showStatistics ? (
          evaluationData[0]?.type === "text" ||
            evaluationData[0]?.type === "choice" ? (
            <div style={{ marginTop: "2rem", padding: "2rem" }}>
              <Typography variant="body1" align="center">
                Mode: {statistics.mode}
              </Typography>
            </div>
          ) : (
            <div>
              <div>
                <div>
                  <DataGrid
                    style={{ borderRadius: "2rem" }}
                    rows={[
                      { id: 1, statistic: "Mean", value: statistics.mean },
                      { id: 2, statistic: "Median", value: statistics.median },
                      {
                        id: 3,
                        statistic: "Standard Deviation",
                        value: statistics.standardDeviation,
                      },
                      { id: 4, statistic: "Mode", value: statistics.mode },
                    ]}
                    columns={[
                      {
                        field: "statistic",
                        headerName: "Statistic",
                        width: 200,
                        headerAlign: "center",
                        align: "center",
                      },
                      {
                        field: "value",
                        headerName: "Value",
                        width: 200,
                        headerAlign: "center",
                        align: "center",
                      },
                    ]}
                    pageSize={4}
                  />
                </div>
                <div>
                  <Bar
                    data={{
                      labels: ["Mean", "Median", "Mode"],
                      datasets: [
                        {
                          label: "Statistics",
                          data: [
                            statistics.mean,
                            statistics.median,
                            statistics.mode,
                          ],
                          backgroundColor: [
                            "rgba(54, 162, 235, 0.6)",
                            "rgba(255, 99, 132, 0.6)",
                            "rgba(255, 205, 86, 0.6)",
                            "rgba(75, 192, 192, 0.6)",
                          ],
                          borderColor: [
                            "rgba(54, 162, 235, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(255, 205, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
                          ],
                          borderWidth: 1,
                          barPercentage: 0.2,
                          barThickness: 20,
        borderRadius: 30,
      borderSkipped: "bottom",
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        title: {
                          display: true,
                          text: "Statistics Comparison",
                        },
                        legend: {
                          display: true,
                          position: "bottom",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}
