import React, { useEffect, useState, useContext } from "react";

import { HttpService } from "../services/http.service";
import { EVALUATION_RESULTS } from "../services/API";
import {  Typography } from "@mui/material";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Label from "../components/Label";
import { AuthContext } from "../store/AuthContext";
import jwt_decode from "jwt-decode";
import { Bar } from "react-chartjs-2";
export default function LoadMonitoringInsightsAthlete() {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const [groupedResults, setGroupedResults] = useState([]);
  const [loading, setLoading] = useState(true);
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }

  const athleteId = decode.athlete_id;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all([
          HttpService.get(`${EVALUATION_RESULTS}?department=9&categories=96&athlete=${athleteId}`,{},false),
          HttpService.get(`${EVALUATION_RESULTS}?department=9&categories=97&athlete=${athleteId}`,{},false),
          HttpService.get(`${EVALUATION_RESULTS}?department=9&categories=98&athlete=${athleteId}`,{},false),
        ]);

        const evaluationData = responses.map((response) => response.data.results).flat();

        const groupedData = {};

        evaluationData.forEach((result, index) => {
          const values = {
            [result.field_name]: [
              result.boolean_value,
              result.choice_value,
              result.date_value,
              result.float_value,
              result.integer_value,
              result.remarks,
              result.string_value,
            ].filter(Boolean),
          };

          const rowId = `${athleteId}-${result.created_at}-${index}`;

          if (!groupedData[rowId]) {
            groupedData[rowId] = {
              id: rowId,
              index,  // Add an index property
              athlete_id: athleteId,
              athlete_name: result.athlete_name, // Assuming this field is present in the response
              date: result.created_at.slice(0, 10),
              ...values,
            };
          } else {
            // Merge values for existing row
            Object.keys(values).forEach((key) => {
              if (!groupedData[rowId][key]) {
                groupedData[rowId][key] = [];
              }
              groupedData[rowId][key].push(...values[key]);
            });
          }
        });

        const processedData = Object.values(groupedData);
        setGroupedResults(processedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [athleteId]);

  const groupedData = {};

  groupedResults.forEach((item) => {
    const { athlete_id, athlete_name, date, ...fields } = item;
    const key = `${athlete_id}-${date}`;
    if (!groupedData[key]) {
      groupedData[key] = {
        athlete_id,
        athlete_name,
        date,
        ...fields,
      };
    } else {
      Object.keys(fields).forEach((field) => {
        if (!groupedData[key][field]) {
          groupedData[key][field] = [];
        }
        if (Array.isArray(groupedData[key][field])) {
          groupedData[key][field].push(
            ...(Array.isArray(fields[field]) ? fields[field] : [fields[field]])
          );
        } else {
          groupedData[key][field] = [groupedData[key][field]];
          groupedData[key][field].push(
            ...(Array.isArray(fields[field]) ? fields[field] : [fields[field]])
          );
        }
      });
    }
  });
  
  const finalGroupedResults = Object.values(groupedData);

  const columns = [
    {
      field: "athlete_name",
      headerName: "Name",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "date",
      headerName: "Date",
      width: 100,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
   
    {
      field: "Daily Load",
      headerName: "Daily Load",
      width: 200,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let HR = parseFloat(params.row["HR"]) || 0;
        let D = parseFloat(params.row["Duration"]) || 0;
        let dailyLoad = HR * D;
        return (
          <div className={`cellWithStatus ${dailyLoad}`}>
            <Label color={"success"}>{dailyLoad.toFixed(2)}</Label>
          </div>
        );
      },
    },
    {
      field: "Acute Load",
      headerName: "Acute Load",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const currentDate = new Date(params.row.date);
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 7);

    const filteredRows = finalGroupedResults.filter((row) => {
      const rowDate = new Date(row.date);
      return rowDate >= sevenDaysAgo && rowDate <= currentDate && row.athlete_id === params.row.athlete_id;
    });

    const acuteLoad = filteredRows.reduce((sum, row) => {
      let HR = parseFloat(row["HR"]) || 0;
      let D = parseFloat(row["Duration"]) || 0;
      return sum + HR * D;
    }, 0);

  
        return <Label color={"success"}>{acuteLoad.toFixed(2)}</Label>;
      },
    },
  
    {
      field: "Chronic Load",
      headerName: "Chronic Load",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const numDays = 28; // Number of days to consider for the rolling average (adjust as needed)
    const athleteData = finalGroupedResults.filter(row => row.athlete_id === params.row.athlete_id);
    const numEntries = Math.min(numDays, athleteData.length);

    const acuteLoadValues = athleteData.slice(0, numEntries).map((row) => {
      let HR = parseFloat(row["HR"]) || 0;
      let D = parseFloat(row["Duration"]) || 0;
      return HR * D;
    });

    const chronicLoad = acuteLoadValues.reduce((sum, value) => sum + value, 0) / numEntries;
   return <Label color={"success"}>{chronicLoad.toFixed(2)}</Label>;
      },
    },
  
    {
      field: "ACWR",
      headerName: "ACWR",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const currentDate = new Date();
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
      
        // Filter rows for the specific athlete
        const athleteData = finalGroupedResults.filter(row => row.athlete_id === params.row.athlete_id);
    
        // Calculate acute load for the last 7 days
        const acuteLoad = athleteData.reduce((sum, row) => {
          const rowDate = new Date(row.date);
          if (rowDate >= sevenDaysAgo && rowDate <= currentDate) {
            let HR = parseFloat(row["HR"]) || 0;
            let D = parseFloat(row["Duration"]) || 0;
            return sum + HR * D;
          }
          return sum;
        }, 0);
    
        // Calculate chronic load for the last 28 days
        const numDays = 28; // Number of days to consider for the rolling average (adjust as needed)
        const chronicLoad = athleteData.slice(0, numDays).reduce((sum, row) => {
          let HR = parseFloat(row["HR"]) || 0;
          let D = parseFloat(row["Duration"]) || 0;
          return sum + HR * D;
        }, 0) / numDays;
    
        // Calculate ACWR
        const acwr = (acuteLoad / chronicLoad).toFixed(2);
        return <Label color={"success"}>{acwr}</Label>;
      },
    },
  
    {
      field: "Daily Strain",
      headerName: "Daily Strain",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const currentDate = new Date();
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        
        // Filter rows for the specific athlete
        const athleteData = finalGroupedResults.filter(row => row.athlete_id === params.row.athlete_id);
    
        // Calculate daily strain for the last 7 days
        const dailyStrain = athleteData.reduce((sum, row) => {
          const rowDate = new Date(row.date);
          if (rowDate >= sevenDaysAgo && rowDate <= currentDate) {
            let HR = parseFloat(row["HR"]) || 0;
            let D = parseFloat(row["Duration"]) || 0;
            return sum + HR * D;
          }
          return sum;
        }, 0).toFixed(2);
    
        return <Label color={"success"}>{dailyStrain}</Label>;
      },
    },
    {
      field: "Monotony Index",
      headerName: "Monotony Index",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // Filter rows for the specific athlete
        const athleteData = finalGroupedResults.filter(row => row.athlete_id === params.row.athlete_id);
    
        const dailyLoads = athleteData.map((row) => {
          let HR = parseFloat(row["HR"]) || 0;
          let D = parseFloat(row["Duration"]) || 0;
          return HR * D;
        });
    
        const nonZeroDailyLoads = dailyLoads.filter(load => load !== 0);
        const meanLoad = nonZeroDailyLoads.length > 0 ? nonZeroDailyLoads.reduce((sum, value) => sum + value, 0) / nonZeroDailyLoads.length : 0;
        const standardDeviation = nonZeroDailyLoads.length > 1 ? Math.sqrt(
          nonZeroDailyLoads.reduce((sum, value) => sum + Math.pow(value - meanLoad, 2), 0) / (nonZeroDailyLoads.length - 1)
        ) : 0;
        const monotonyIndex = standardDeviation !== 0 ? (meanLoad / standardDeviation).toFixed(2) : 0;
        
        return <Label color={"success"}>{monotonyIndex}</Label>;
      },
    },
    
    {
      field: "HR",
      headerName: "HR",
      sortable: true,
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "RPE",
      headerName: "RPE",
      sortable: true,
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Duration",
      headerName: "Duration",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Distance",
      headerName: "Distance",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Bouts",
      headerName: "Bouts",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Reps",
      headerName: "Reps",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Sets",
      headerName: "Sets",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Parts",
      headerName: "Parts",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Type",
      headerName: "Type",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
   
  ]; 
  
  const labels = finalGroupedResults
  .map(item => item?.Duration ? item.date : null) // Use null for items where Duration is undefined
  .filter(label => label !== null); // Filter out null labels

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Daily Load',
        data: finalGroupedResults?.map(item => {
          // Calculate the total daily load by summing up the values from different fields
          
          if (item?.Duration && item?.Duration.length === item?.HR.length) {
            // Calculate the total daily load by summing up the values from different fields
            const dailyLoad = item?.HR.reduce((acc, value, index) => {
              const hr = parseFloat(value) || 0;
              const duration = parseFloat(item.Duration[index]) || 0;
              return acc + (hr * duration);
            }, 0);
            return dailyLoad;
          } else {
            return 0; // Return 0 if item.Duration is undefined or has different length
          }
        }),
        borderWidth: 1
        
      }
    ]
  };
  const options = {
    scales: {
      y: {
        beginAtZero: true // Start y-axis from zero
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top'
      }
    },
    layout: {
      padding: {
        left: 50,
        right: 50,
        top: 0,
        bottom: 0
      }
    },
    // responsive: true,
    // maintainAspectRatio: false, // Allow chart to resize based on container size
    barThickness: 30 // Adjust bar width
  };
 
  return (
    <div>
  
          <Typography variant="h6" textAlign={"center"}>
            Load Monitoring Analysis
          </Typography>
     

      {groupedResults?.length === 0 ? (
        <Typography variant="h6" textAlign={"center"}>
          No Data
        </Typography>
      ) : (
        <div
          style={{
            display: "flex",

            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          <Container
            maxWidth="lg"
            style={{ marginTop: "4rem", marginBottom: "2rem" }}
          >
            <Box key={Math.random().toLocaleString()}>
              <DataGrid
                rows={finalGroupedResults}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 50]}
                disableSelectionOnClick
                autoHeight
                getRowId={(row) => `${row.athlete_id}-${row.date}-${row.index}`}
              />
            </Box>
          </Container>
          <Bar data={chartData}  options={options}/>
          
        </div>
      )}
    </div>
  );
}

