import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
export default function Logs() {
  const data = [
    {
      name: "View Nutrition Logs",
      text: "This Tab enables user to view nutrition logs",
      link: "/logs/nutrition",
      animation: require("./../../assets/json/nutri_logs.json"),
      animationSize: 1, // Adjust the size here
    },
    // {
    //   name: "View Treatment Logs",
    //   text: "This Tab enables user to view treatment logs",
    //   link: "/logs/treatment",
    //   animation: require("./../../assets/json/treatment_logs.json"),
    //   animationSize: 1, // Adjust the size here
    // },
  ];
  const navigate = useNavigate();

  return (
    <div>
      
      {data.map((item) => (
        <Card
          key={item.name} // Add a unique key
          sx={{
            width: "100%",
            height: "20rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            cursor: "pointer",
            marginBottom: "2rem",
            marginTop: "1rem",
          }}
        >
          {" "}
          <CardActions  onClick={() => navigate(item.link)}>
            <CardContent>
              <Typography
                variant="h4"
                fontSize={18}
                fontWeight={"bold"}
                textAlign={"center"}
                color={"#4dabf5"}
              >
                {item.name}
              </Typography>
              <br/>
              <Typography variant="span" textAlign={"center"}>
                {item.text}
              </Typography>
              <Lottie
                animationData={item.animation}
                loop={true}
                size={item.animationSize}
              />
            </CardContent>
          </CardActions>
        </Card>
      ))}
    </div>
  );
}
