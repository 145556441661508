import React, { useState, useEffect } from "react";
import { FetchResultsByFieldAthlete } from "../../components/custmomhooks/CustomHooks";
import CustomBarChart from "./BarChart";
import styles from "./injury.module.css";
import {   SelectPicker } from "rsuite";
import { ATHLETE_PROFILE_API_URL } from "../../services/API";
import { HttpService } from "../../services/http.service";
import Label from "../../components/Label";
import { Typography } from "@mui/material";
import NODATA from "./../../assets/image/noChart.svg";
import NOTSELECTED from "./../../assets/image/notSelected.jpg";
// import CustomPieChart from "./CustomPieChart";
export default function SCProfiling() {
  const [athlete, setAthlete] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [loading, setLoading] = useState(false)
  // const [filter, setFilter] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataResults, setDataResults] = useState({
    bmi: { data: [], title: "Body Mass Index" },
    lbm: { data: [], title: "Lean Body Mass" },
    skeletalMass: { data: [], title: "Skeletal Mass" },
    boneMass: { data: [], title: "Bone Mass" },
    subCutaneous: { data: [], title: "Subcutaneous Fat" },
    visceralFat: { data: [], title: "Visceral Fat" },
    totalbodyfat: { data: [], title: "Total Body Fat" },
    hydration: { data: [], title: "Hydration" },
    restingHR: { data: [], title: "Resting Heart Rate" },
    maxHR: { data: [], title: "Maximum Heart Rate" },
    recoveryHR: { data: [], title: "Recovery Heart Rate" },
    respRate: { data: [], title: "Respiratory Rate" },
    maxOxygen: { data: [], title: "VO2 Max" },
    breathingPattern: { data: [], title: "Breathing Pattern" },
    rulFlexibility: { data: [], title: "Right Upper Limb Flexibility" },
    lulFlexibility: { data: [], title: "Left Upper Limb Flexibility" },
    rlBalance: { data: [], title: "Right Leg Balance" },
    lllBalance: { data: [], title: "Left Leg Balance" },
    blBalance: { data: [], title: "Bilateral Balance" },
    upperlimbEndurance: { data: [], title: "Upper Limb Endurance" },
    lowerlimbEndurance: { data: [], title: "Lower Limb Endurance" },
    coreEndurance: { data: [], title: "Core Endurance" },
    speed: { data: [], title: "Speed" },
    tagility: { data: [], title: "T - Test" },
    fiveOAgility: { data: [], title: "505 Agility" },
    spider: { data: [], title: "Spider Test" },
    illinios: { data: [], title: "Illinios Test" },
    hexa: { data: [], title: "Hexagon Test" },
    coordination: { data: [], title: "Co-ordination Test" },
    reactionTime: { data: [], title: "Reaction Time" },
    upperBodyPower: { data: [], title: "Upper Body Power" },
    lowerBodyPower: { data: [], title: "Lower Body Power" },
    upperBodyStrength: { data: [], title: "Upper Body Strength" },
    lowerBodyStrength: { data: [], title: "Lower Body Strength" },
    firstLap: { data: [], title: "RAST - First Lap" },
    secondLap: { data: [], title: "RAST - Second Lap" },
    thirdLap: { data: [], title: "RAST - Third Lap" },
    fourthLap: { data: [], title: "RAST - Fourth Lap" },
    fifthLap: { data: [], title: "RAST - Fifth Lap" },
    sixthLap: { data: [], title: "RAST - Sixth Lap" },
  });
  const [weight, setWeight] = useState([]);
  const [maxPower, setMaxPower] = useState([]);
  const [minPower, setMinPower] = useState([]);
  const [avgPower, setAvgPower] = useState([]);
  const [fatigueIndex, setFatigueIndex] = useState([]);
  const [relativePeakPower, setRelativePeakPower] = useState([]);
  const [anaerobicCapacity, setAnaerobicCapacity] = useState([]);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true);
        const fetchPromises = [
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 56,
        field: 306,
        athlete,
      }),
    FetchResultsByFieldAthlete({
        dept: 3,
        cat: 56,
        field: 307,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 3,
        cat: 56,
        field: 308,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 56,
        field: 309,
        athlete,
      }),
       FetchResultsByFieldAthlete({
        dept: 3,
        cat: 56,
        field: 310,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 3,
        cat: 56,
        field: 311,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 56,
        field: 312,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 56,
        field: 313,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 58,
        field: 317,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 3,
        cat: 58,
        field: 318,
        athlete,
      }),
       FetchResultsByFieldAthlete({
        dept: 3,
        cat: 58,
        field: 319,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 58,
        field: 320,
        athlete,
      }), FetchResultsByFieldAthlete({
        dept: 3,
        cat: 58,
        field: 321,
        athlete,
      }), FetchResultsByFieldAthlete({
        dept: 3,
        cat: 58,
        field: 322,
        athlete,
      }), FetchResultsByFieldAthlete({
        dept: 3,
        cat: 66,
        field: 348,
        athlete,
      }),FetchResultsByFieldAthlete({
        dept: 3,
        cat: 66,
        field: 347,
        athlete,
      }),FetchResultsByFieldAthlete({
        dept: 3,
        cat: 66,
        field: 349,
        athlete,
      }), await FetchResultsByFieldAthlete({
        dept: 3,
        cat: 63,
        field: 340,
        athlete,
      }),FetchResultsByFieldAthlete({
        dept: 3,
        cat: 63,
        field: 341,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 3,
        cat: 63,
        field: 342,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 57,
        field: 314,
        athlete,
      }) ,FetchResultsByFieldAthlete({
        dept: 3,
        cat: 57,
        field: 315,
        athlete,
      }), FetchResultsByFieldAthlete({
        dept: 3,
        cat: 57,
        field: 316,
        athlete,
      }),
       FetchResultsByFieldAthlete({
        dept: 3,
        cat: 62,
        field: 339,
        athlete,
      }),
    FetchResultsByFieldAthlete({
        dept: 3,
        cat: 61,
        field: 334,
        athlete,
      }),
    FetchResultsByFieldAthlete({
        dept: 3,
        cat: 61,
        field: 335,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 3,
        cat: 61,
        field: 336,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 61,
        field: 337,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 3,
        cat: 61,
        field: 338,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 67,
        field: 350,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 68,
        field: 351,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 65,
        field: 345,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 65,
        field: 346,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 64,
        field: 343,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 64,
        field: 344,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 60,
        field: 328,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 60,
        field: 329,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 3,
        cat: 60,
        field: 330,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 60,
        field: 331,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 3,
        cat: 60,
        field: 332,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 3,
        cat: 60,
        field: 333,
        athlete,
      }),
    ];
    const results = await Promise.all(fetchPromises);

    setDataResults((prevData) => ({
      ...prevData,
      bmi: { data: results[0], title: "Body Mass Index" },
    lbm: { data: results[1], title: "Lean Body Mass" },
    skeletalMass: { data: results[2], title: "Skeletal Mass" },
    boneMass: { data: results[3], title: "Bone Mass" },
    subCutaneous: { data: results[4], title: "Subcutaneous Fat" },
    visceralFat: { data: results[5], title: "Visceral Fat" },
    totalbodyfat: { data: results[6], title: "Total Body Fat" },
    hydration: { data: results[7], title: "Hydration" },
    restingHR: { data: results[8], title: "Resting Heart Rate" },
    maxHR: { data: results[9], title: "Maximum Heart Rate" },
    recoveryHR: { data: results[10], title: "Recovery Heart Rate" },
    respRate: { data: results[11], title: "Respiratory Rate" },
    maxOxygen: { data: results[12], title: "VO2 Max" },
    breathingPattern: { data: results[13], title: "Breathing Pattern" },
    rulFlexibility: { data: results[14], title: "Right Upper Limb Flexibility" },
    lulFlexibility: { data: results[15], title: "Left Upper Limb Flexibility" },
    rlBalance: { data: results[16], title: "Right Leg Balance" },
    lllBalance: { data: results[17], title: "Left Leg Balance" },
    blBalance: { data: results[18], title: "Bilateral Balance" },
    upperlimbEndurance: { data: results[19], title: "Upper Limb Endurance" },
    lowerlimbEndurance: { data: results[20], title: "Lower Limb Endurance" },
    coreEndurance: { data: results[21], title: "Core Endurance" },
    speed: { data: results[22], title: "Speed" },
    tagility: { data: results[23], title: "T - Test" },
    fiveOAgility: { data: results[24], title: "505 Agility" },
    spider: { data: results[25], title: "Spider Test" },
    illinios: { data: results[26], title: "Illinios Test" },
    hexa: { data: results[27], title: "Hexagon Test" },
    coordination: { data: results[28], title: "Co-ordination Test" },
    reactionTime: { data: results[29], title: "Reaction Time" },
    upperBodyPower: { data: results[30], title: "Upper Body Power" },
    lowerBodyPower: { data: results[31], title: "Lower Body Power" },
    upperBodyStrength: { data: results[32], title: "Upper Body Strength" },
    lowerBodyStrength: { data: results[33], title: "Lower Body Strength" },
    firstLap: { data: results[34], title: "RAST - First Lap" },
    secondLap: { data: results[35], title: "RAST - Second Lap" },
    thirdLap: { data: results[36], title: "RAST - Third Lap" },
    fourthLap: { data: results[37], title: "RAST - Fourth Lap" },
    fifthLap: { data: results[38], title: "RAST - Fifth Lap" },
    sixthLap: { data: results[39], title: "RAST - Sixth Lap" },
    }));

    setLoading(false);
  } catch (error) {
    console.error("Error fetching data:", error);
    setLoading(false);
  }
};

if (athlete) {
  fetchResults();
}
}, [athlete]);
  useEffect(() => {
    const fetchAthlete = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`, {}, false
        );
        const modifiedAthlete = response.data.results.map((ath) => ({
          value: ath.id,
          label: ath.name,
        }));
        setAthletes(modifiedAthlete);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthlete();
  }, [currentPage]);

  useEffect(() => {
    const RASTCalc = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL + athlete}/`, {}, false
        );

        if (!response.data) {
          console.error("Results array is undefined.");
          return;
        }

        if (response.data.length === 0) {
          console.warn("Results array is empty.");
          return;
        }
        setWeight(response.data.weight);

        const sprintTimes = [
          dataResults.firstLap.data ?  dataResults.firstLap.data[0].value : 0,
          dataResults.secondLap.data ?  dataResults.secondLap.data[0].value : 0,
          dataResults.thirdLap.data ?  dataResults.thirdLap.data[0].value : 0,
          dataResults.fourthLap.data ?  dataResults.fourthLap.data[0].value : 0,
          dataResults.fifthLap.data ?  dataResults.fifthLap.data[0].value : 0,
          dataResults.sixthLap.data ?  dataResults.sixthLap.data[0].value : 0,
          
        ];

        // Calculate PPO for each sprint time using the correct weight value
        const sprintPPOs = sprintTimes.map((lap) => {
          return (weight * 35 ** 2) / lap ** 3;
        });

        // Calculate metrics
        const maxPower = Math.max(...sprintPPOs);
        const minPower = Math.min(...sprintPPOs);
        const avgPower = sprintPPOs.reduce((sum, ppo) => sum + ppo, 0) / 6;
        const totalTime = sprintTimes.reduce((sum, lap) => sum + lap, 0);
        const fatigueIndex = (maxPower - minPower) / totalTime;
        const relativePeakPower = maxPower / weight;
        const anaerobicCapacity = sprintPPOs.reduce((sum, ppo) => sum + ppo, 0);

        // Update your state with the calculated metrics
        setMaxPower(maxPower.toFixed(2));
        setMinPower(minPower.toFixed(2));
        setAvgPower(avgPower.toFixed(2));
        setFatigueIndex(fatigueIndex.toFixed(2));
        setRelativePeakPower(relativePeakPower.toFixed(2));
        setAnaerobicCapacity(anaerobicCapacity.toFixed(2));
      } catch (error) {
        console.warn(error);
      }
    };
    if (athlete!==null) {
      RASTCalc();
    }

  }, [
    athlete,
    athletes,
    weight,
    dataResults.firstLap,
    dataResults.secondLap,
    dataResults.thirdLap,
    dataResults.fourthLap,
   dataResults.fifthLap,
    dataResults.sixthLap,
  ]);

  const ScrollableLabels = ({ data }) => {
    const labelValues = {};

    // Collect values for each label
    Object.entries(data).forEach(([key, value]) => {
      const labelKey = value.field_name;

      if (!labelValues[labelKey]) {
        labelValues[labelKey] = [];
      }

      labelValues[labelKey].push(value.value);
    });

    return (
      <div className={styles.scroll_container}>
        {Object.entries(labelValues).map(([label, values], index) => (
          <div key={index} className={styles.label}>
            <strong style={{ marginLeft: 20 }}>{label}:</strong>{" "}
            {values.join(", ")}
          </div>
        ))}
      </div>
    );
  };

  const RASTRender = ()=>{
  return (
    <div>
       {dataResults.sixthLap?.length <= 0 ? (
                null
              ) : (
                <div className={styles.labelsummary}>
                  <Label color="success">RAST Report Summary</Label>
                  <div className={styles.labelinnersum}>
                    <br />
                    <Label sx={{m:"1px"}} color="error">Max Power: {maxPower}</Label>
                    <br />
                    <Label sx={{m:"1px"}} color="secondary">Min Power: {minPower}</Label>
                    <br />
                    <Label sx={{m:"1px"}} color="success">Average Power: {avgPower}</Label>
                    <br />
                    <Label sx={{m:"1px"}} color="warning">Fatigue Index: {fatigueIndex}</Label>
                    <br />
                    <Label sx={{m:"1px"}} color="secondary">
                      Relative Peak Power: {relativePeakPower}
                    </Label>
                    <br />
                    <Label sx={{m:"1px"}} color="info">
                      Anaerobic Capacity: {anaerobicCapacity}
                    </Label>
                    <br />
                    <br />
                  </div>

                </div>
              )}
    
    </div>
  )
}
  return (
    <div>
      <div className={styles.belownav}>
        <SelectPicker
          label={ "Select Athlete" }
          data={athletes}
          onSelect={(value) => setAthlete(value)}
          className={styles.selectpicker}
          onClean={() => {
            setAthlete([]);
          }}
        />
      </div>
      {loading === true ? (
        <div
          style={{
            marginTop: "100px",
            marginLeft:"240px",
            transition: "margin-left 0.3s",
          }}
        >
          <Typography variant="h4" color="darkviolet" textAlign="center">
            {athlete !==null? "Select Athlete" : "Loading...."}
          </Typography>
        </div>
      ) : (
        <div className={styles.box_science_sports7}>
          <div className={styles.chartcontent}>
            <div className={styles.chartwrapper}>
              {Object.entries(dataResults).map(([key, data]) => (
                <div className={styles.chartitem} key={key}>
                  <div className={styles.chartbox}>
                    {data?.data?.length <= 0 ? (
                      <div
                        style={{
                          width: "250px",
                          height: "250px",
                          margin: "0 auto",
                        }}
                      >
                       
                        {athlete!==null ? (
                          <>
                            <img src={NODATA} alt="No data" />
                            <h6 style={{ textAlign: "center" }}>
                              No Records Available:{data?.title}
                            </h6>
                          </>
                        ) : (
                          <>
                            <h6> Athlete Not Selected</h6>
                            <img
                              src={NOTSELECTED}
                              style={{ width: "200px", height: "200px" }}
                              alt="No data"
                            />
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        {data?.title === "Breathing Pattern" ? 
                         <ScrollableLabels data={data?.data} />
                         : (
                          
                          <CustomBarChart
                          data={data?.data}
                          title={data?.title}
                        />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <RASTRender/>
          </div>
        
        </div>
      )}
    </div>
  );
}
