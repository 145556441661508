import React, { useState, useEffect } from "react";
import { Button, List, Modal, IconButton, Loader, SelectPicker } from "rsuite";
import { Typography, Tooltip, Card, CardContent } from "@mui/material";
import MinusIcon from "@rsuite/icons/Minus";
import PlusIcon from "@rsuite/icons/Plus";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import TrashIcon from "@rsuite/icons/Trash";
import { ATHLETE_PROFILE_API_URL } from "../../services/API";
import { HttpService } from "../../services/http.service";
import {
  CardioExercises,
  FlexibilityExercise,
  UpperBodyStrengthExercise,
  LowerBodyStrengthExercise,
  PlyometricExercises,
  CoreExercises,
  MobilityExercise,
  OlympicLifts,
  LumboPelvicHip,
  PosturalControls,
  SwissBall,
  TRX,
  JLT,
  MBE,
  AE,
  KB,
  CET,
  NMP,
} from "../storytelling/ExericseList";
const ExerciseCard = ({
  exerciseList,
  show,
  setShow,
  type,
  exerciseSelected,
  setExerciseSelected,
  exerciseCart,
  setExerciseCart,
  addToCartModalVisible,
  setAddToCartModalVisible,
  exerciseCartModalVisible,
  setExerciseCartModalVisible,
  selectedIndices,
  setSelectedIndices,
  clearExerciseCart,
  handleRemoveFromCart,
}) => {
  const [athletes, setAthletes] = useState([]);
  const [athlete, setAthlete] = useState([]);
  useEffect(() => {
    // Fetch the list of athletes when the component mounts
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(ATHLETE_PROFILE_API_URL);

        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));

        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, [setAthletes]);
  const handleShowMore = () => {
    setShow(show + 20);
  };

  const handleShowLess = () => {
    setShow(20);
  };

  const handleExercise = (selectedItem, index) => {
    const indexInArray = selectedIndices.indexOf(index);

    // If the index is in the array, remove it; otherwise, add it
    if (indexInArray !== -1) {
      const updatedIndices = [...selectedIndices];
      updatedIndices.splice(indexInArray, 1); // Remove the index from the array
      setSelectedIndices(updatedIndices);
    } else {
      setSelectedIndices([...selectedIndices, index]);
    }
    setExerciseSelected(selectedItem);
    setAddToCartModalVisible(true);
  };

  const addToExerciseCart = (selectedItem, sets, reps, freq,remarks) => {
    setExerciseCart((prevExerciseCart) => {
      const updatedCart = [
        ...prevExerciseCart,
        { exercise: selectedItem, reps, freq, sets,remarks },
      ];
      return updatedCart;
    });
    setAddToCartModalVisible(false);
  };

  const showExerciseCart = () => {
    setExerciseCartModalVisible(true);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ margin: "1rem" }}>
        {exerciseCart?.length <= 0 ? null : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              appearance="primary"
              color="red"
              onClick={clearExerciseCart}
            >
              Clear Exercise Cart
            </Button>

            {/* <div style={{ position: "relative", display: "inline-block" }}>
              <Tooltip title="Show Exercise Cart" placement="top-start">
                <IconButton
                  icon={<ShoppingCartIcon />}
                  appearance="primary"
                  size="xs"
                  color="cyan"
                  onClick={showExerciseCart}
                />
              </Tooltip>

              <div
                style={{
                  position: "absolute",
                  top: "-10px",
                  left: "16px",
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "4px 8px",
                  fontSize: "12px",
                }}
              >
                {exerciseCart.length}
              </div>
            </div> */}
          </div>
        )}
        {athlete?.length < 0 ? (
          <Loader size="xs" content="Loading...!!" />
        ) : (
          <div style={{ marginTop: "2rem" }}>
            <SelectPicker
              label="Select Athlete"
              data={athletes}
              value={athlete}
              onSelect={(value) => setAthlete(value)}
              style={{ width: 260 }}
            />
          </div>
        )}
        {exerciseList.slice(0, show).map((item, index) => (
          <Card
            key={index}
            sx={{
              marginTop: "1rem",
              width: "40rem",
            }}
            
          >
            <CardContent
              onClick={() => handleExercise(item, index)}
              style={{
                backgroundColor: selectedIndices.includes(index)
                  ? type === 1
                    ? "lightgreen"
                    : type === 2
                    ? "pink"
                    : type === 3
                    ? "yellow"
                    : type === 4
                    ? "skyblue"
                    : type === 5
                    ? "orange"
                    : type === 6
                    ? "lightgreen"
                    : type === 7
                    ? "pink"
                    : type === 8
                    ? "yellow"
                    : type === 9
                    ? "skyblue"
                    : type === 10
                    ? "orange"
                    : type === 11
                    ? "lightgreen"
                    : type === 12
                    ? "pink"
                    : type === 13
                    ? "yellow"
                    : type === 14
                    ? "skyblue"
                    : type === 15
                    ? "orange"
                    : type === 16
                    ? "orange"
                    : "violet"
                  : "transparent",
                cursor: "pointer",
                fontWeight: "bold", // Add cursor style to indicate clickable items
              }}
            >
              {type === 1
                ? item["Cardio Exercises"]
                : type === 2
                ? item["Flexibility Exercises"]
                : type === 3
                ? item["Upper Body Strength Exercises"]
                : type === 4
                ? item["Lower Body Strength Exercises"]
                : type === 5
                ? item["Plyometric Exercises"]
                : type === 6
                ? item["Core Exercises"]
                : type === 7
                ? item["Mobility Exercise"]
                : type === 8
                ? item["Olympic Lifts"]
                : type === 9
                ? item["Lumbo Pelvic Hip Exercises"]
                : type === 10
                ? item["Postural Controls"]
                : type === 11
                ? item["Swiss Ball"]
                : type === 12
                ? item["TRX"]
                : type === 13
                ? item["Jumping - Landing Techniques"]
                : type === 14
                ? item["Medicine Ball Exercises"]
                : type === 15
                ? item["Activation Exercises"]
                : type === 16
                ? item["Kettlebell Exercises"]
                : type === 17
                ? item["Corrective Exercise Training (CET)"]
                : type === 18
                ? item["Neuromuscular Movement Preparation"]
                : null}
            </CardContent>
          </Card>
        ))}
        {exerciseList.length > 21 ? (
          <>
            {show < exerciseList.length ? (
              <Button
                style={{ margin: "2rem" }}
                appearance="ghost"
                onClick={handleShowMore}
              >
                Show More
              </Button>
            ) : (
              <Button
                style={{ margin: "2rem" }}
                appearance="ghost"
                onClick={handleShowLess}
              >
                Show Less
              </Button>
            )}
          </>
        ) : null}
      </div>

      <Modal
        open={addToCartModalVisible}
        onClose={() => setAddToCartModalVisible(false)}
      >
        <Modal.Header>
          <Modal.Title>Add Exercise to Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to add{" "}
          {exerciseSelected
            ? type === 1
              ? exerciseSelected["Cardio Exercises"]
              : type === 2
              ? exerciseSelected["Flexibility Exercises"]
              : type === 3
              ? exerciseSelected["Upper Body Strength Exercises"]
              : type === 4
              ? exerciseSelected["Lower Body Strength Exercises"]
              : type === 5
              ? exerciseSelected["Plyometric Exercises"]
              : type === 6
              ? exerciseSelected["Core Exercises"]
              : type === 7
              ? exerciseSelected["Mobility Exercise"]
              : type === 8
              ? exerciseSelected["Olympic Lifts"]
              : type === 9
              ? exerciseSelected["Lumbo Pelvic Hip Exercises"]
              : type === 10
              ? exerciseSelected["Postural Controls"]
              : type === 11
              ? exerciseSelected["Swiss Ball"]
              : type === 12
              ? exerciseSelected["TRX"]
              : type === 13
              ? exerciseSelected["Jumping - Landing Techniques"]
              : type === 14
              ? exerciseSelected["Medicine Ball Exercises"]
              : type === 15
              ? exerciseSelected["Activation Exercises"]
              : type === 16
              ? exerciseSelected["Kettlebell Exercises"]
              : type === 17
              ? exerciseSelected["Corrective Exercise Training (CET)"]
              : type === 18
              ? exerciseSelected["Neuromuscular Movement Preparation"]
              : null
            : null}
          to the exercise cart?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => addToExerciseCart(exerciseSelected)}
            appearance="primary"
          >
            Add to Cart
          </Button>
          <Button
            onClick={() => setAddToCartModalVisible(false)}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to show selected exercises */}
      {exerciseCart?.length <= 0 ? null : (
        <Card
          sx={{
            width: "60rem",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
            marginRight: "1rem",
            marginLeft: "1rem",
            marginTop: "8rem",
            // border: "0.1px solid black",
            backgroundColor: "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h6" textAlign={"center"} marginTop={"2rem"}>
            Exercise Cart
          </Typography>
          <CardContent>
            {exerciseCart?.map((cartItem, index) => (
              <List key={index}>
                <List.Item style={{ fontWeight: "bold" }}>
                  {cartItem.exercise["Cardio Exercises"] ||
                    cartItem.exercise["Flexibility Exercises"] ||
                    cartItem.exercise["Upper Body Strength Exercises"] ||
                    cartItem.exercise["Lower Body Strength Exercises"] ||
                    cartItem.exercise["Plyometric Exercises"] ||
                    cartItem.exercise["Core Exercises"] ||
                    cartItem.exercise["Mobility Exercise"] ||
                    cartItem.exercise["Olympic Lifts"] ||
                    cartItem.exercise["Lumbo Pelvic Hip Exercises"] ||
                    cartItem.exercise["Postural Controls"] ||
                    cartItem.exercise["Swiss Ball"] ||
                    cartItem.exercise["TRX"] ||
                    cartItem.exercise["Jumping - Landing Techniques"] ||
                    cartItem.exercise["Medicine Ball Exercises"] ||
                    cartItem.exercise["Activation Exercises"] ||
                    cartItem.exercise["Kettlebell Exercises"] ||
                    cartItem.exercise["Corrective Exercise Training (CET)"] ||
                    cartItem.exercise["Neuromuscular Movement Preparation"]}
                  <Tooltip
                    title={`Delete Selected Exercise${" "}${[
                      cartItem.exercise["Cardio Exercises"],
                      cartItem.exercise["Flexibility Exercises"],
                      cartItem.exercise["Upper Body Strength Exercises"],
                      cartItem.exercise["Lower Body Strength Exercises"],
                      cartItem.exercise["Plyometric Exercises"],
                      cartItem.exercise["Core Exercises"],
                      cartItem.exercise["Mobility Exercise"],
                      cartItem.exercise["Olympic Lifts"],
                      cartItem.exercise["Lumbo Pelvic Hip Exercises"],
                      cartItem.exercise["Postural Controls"],
                      cartItem.exercise["Swiss Ball"],
                      cartItem.exercise["TRX"],
                      cartItem.exercise["Jumping - Landing Techniques"],
                      cartItem.exercise["Medicine Ball Exercises"],
                      cartItem.exercise["Activation Exercises"],
                      cartItem.exercise["Kettlebell Exercises"],
                      cartItem.exercise["Corrective Exercise Training (CET)"],
                      cartItem.exercise["Neuromuscular Movement Preparation"],
                    ]
                      .filter(Boolean)
                      .join(" ")}`}
                    placement="top-start"
                  >
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "40rem",
                      }}
                      appearance="primary"
                      size="xs"
                      icon={<TrashIcon />}
                      color="red"
                      onClick={() => handleRemoveFromCart(index)}
                    />
                  </Tooltip>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div>
                      <Typography
                        margin={"0.5rem"}
                        textAlign={"center"}
                        fontWeight={"bold"}
                        fontSize={12}
                      >
                        Reps: {cartItem.reps}
                      </Typography>
                      <Tooltip title="Increase Repetition" placement="bottom">
                        <IconButton
                          appearance="primary"
                          style={{ marginRight: "1rem" }}
                          size="xs"
                          icon={<PlusIcon />}
                          color="green"
                          onClick={() => {
                            const updatedCart = [...exerciseCart];
                            updatedCart[index].reps =
                              (updatedCart[index].reps || 0) + 1;
                            setExerciseCart(updatedCart);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Decrease Repetition" placement="bottom">
                        <IconButton
                          appearance="primary"
                          size="xs"
                          icon={<MinusIcon />}
                          color="yellow"
                          onClick={() => {
                            // Decrease the sets for this specific exercise, but not below 1
                            const updatedCart = [...exerciseCart];
                            updatedCart[index].reps = Math.max(
                              (updatedCart[index].reps || 0) - 1,
                              1
                            );
                            setExerciseCart(updatedCart);
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div>
                      <Typography
                        margin={"0.5rem"}
                        marginLeft={"0.01rem"}
                        textAlign={"center"}
                        fontWeight={"bold"}
                        fontSize={12}
                      >
                        Frequency: {cartItem.freq}
                      </Typography>
                      <Tooltip title="Increase Frequency" placement="bottom">
                        <IconButton
                          appearance="primary"
                          style={{ marginRight: "1rem" }}
                          size="xs"
                          color="green"
                          icon={<PlusIcon />}
                          onClick={() => {
                            const updatedCart = [...exerciseCart];
                            updatedCart[index].freq =
                              (updatedCart[index].freq || 0) + 1;
                            setExerciseCart(updatedCart);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Decrease Frequency" placement="bottom">
                        <IconButton
                          appearance="primary"
                          size="xs"
                          color="yellow"
                          icon={<MinusIcon />}
                          onClick={() => {
                            // Decrease the sets for this specific exercise, but not below 1
                            const updatedCart = [...exerciseCart];
                            updatedCart[index].freq = Math.max(
                              (updatedCart[index].freq || 0) - 1,
                              1
                            );
                            setExerciseCart(updatedCart);
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div>
                      <Typography
                        margin={"0.5rem"}
                        textAlign={"center"}
                        fontWeight={"bold"}
                        fontSize={12}
                      >
                        Sets: {cartItem.sets}
                      </Typography>
                      <Tooltip title="Increase Sets" placement="bottom">
                        {" "}
                        <IconButton
                          appearance="primary"
                          style={{ marginRight: "1rem" }}
                          size="xs"
                          color="green"
                          icon={<PlusIcon />}
                          onClick={() => {
                            const updatedCart = [...exerciseCart];
                            updatedCart[index].sets =
                              (updatedCart[index].sets || 0) + 1;
                            setExerciseCart(updatedCart);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Decrease Sets" placement="bottom">
                        {" "}
                        <IconButton
                          appearance="primary"
                          size="xs"
                          color="yellow"
                          icon={<MinusIcon />}
                          onClick={() => {
                            // Decrease the sets for this specific exercise, but not below 1
                            const updatedCart = [...exerciseCart];
                            updatedCart[index].sets = Math.max(
                              (updatedCart[index].sets || 0) - 1,
                              1
                            );
                            setExerciseCart(updatedCart);
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div>
                      <Typography
                        margin={"0.5rem"}
                        marginLeft={"0.01rem"}
                        textAlign={"center"}
                        fontWeight={"bold"}
                        fontSize={12}
                      >
                        Remarks: {cartItem.remarks}
                      </Typography>

                      <input
                        style={{ marginRight: "1rem" }}
                        value={cartItem.remarks || ""} // Set the input value to the description property
                        onChange={(e) => {
                          const updatedCart = [...exerciseCart];
                          updatedCart[index].remarks = e.target.value; // Update remarks in the specific cart item
                          setExerciseCart(updatedCart);
                        }}
                      />
                    </div>
                  </div>
                </List.Item>
              </List>
            ))}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default function StrengthTrainingTemplate() {
  const [showList0, setShowList0] = useState(10);
  const [showList1, setShowList1] = useState(10);
  const [showList2, setShowList2] = useState(10);
  const [showList3, setShowList3] = useState(10);
  const [showList4, setShowList4] = useState(10);
  const [showList5, setShowList5] = useState(10);
  const [showList6, setShowList6] = useState(10);
  const [showList7, setShowList7] = useState(10);
  const [showList8, setShowList8] = useState(10);
  const [showList9, setShowList9] = useState(10);
  const [showList10, setShowList10] = useState(10);
  const [showList11, setShowList11] = useState(10);
  const [showList12, setShowList12] = useState(10);
  const [showList13, setShowList13] = useState(10);
  const [showList14, setShowList14] = useState(10);
  const [showList15, setShowList15] = useState(10);
  const [showList16, setShowList16] = useState(10);
  const [showList17, setShowList17] = useState(10);

  const [exerciseSelected, setExerciseSelected] = useState([]);
  const [exerciseCart, setExerciseCart] = useState([]);
  const [type, setType] = useState(null);
  const [addToCartModalVisible, setAddToCartModalVisible] = useState(false);
  const [exerciseCartModalVisible, setExerciseCartModalVisible] =
    useState(false);
  const [clicked, setClicked] = useState([]);
  const [clicked1, setClicked1] = useState([]);
  const [clicked2, setClicked2] = useState([]);
  const [clicked3, setClicked3] = useState([]);
  const [clicked4, setClicked4] = useState([]);
  const [clicked5, setClicked5] = useState([]);
  const [clicked6, setClicked6] = useState([]);
  const [clicked7, setClicked7] = useState([]);
  const [clicked8, setClicked8] = useState([]);
  const [clicked9, setClicked9] = useState([]);
  const [clicked10, setClicked10] = useState([]);
  const [clicked11, setClicked11] = useState([]);
  const [clicked12, setClicked12] = useState([]);
  const [clicked13, setClicked13] = useState([]);
  const [clicked14, setClicked14] = useState([]);
  const [clicked15, setClicked15] = useState([]);
  const [clicked16, setClicked16] = useState([]);
  const [clicked17, setClicked17] = useState([]);

  const handleToggle = (selectedType) => {
    const numericType = parseInt(selectedType, 10);

    if (numericType === 1) {
      setType(1);
    } else if (numericType === 2) {
      setType(2);
    } else if (numericType === 3) {
      setType(3);
    } else if (numericType === 4) {
      setType(4);
    } else if (numericType === 5) {
      setType(5);
    } else if (numericType === 6) {
      setType(6);
    } else if (numericType === 7) {
      setType(7);
    } else if (numericType === 8) {
      setType(8);
    } else if (numericType === 9) {
      setType(9);
    } else if (numericType === 10) {
      setType(10);
    } else if (numericType === 11) {
      setType(11);
    } else if (numericType === 12) {
      setType(12);
    } else if (numericType === 13) {
      setType(13);
    } else if (numericType === 14) {
      setType(14);
    } else if (numericType === 15) {
      setType(15);
    } else if (numericType === 16) {
      setType(16);
    } else if (numericType === 17) {
      setType(17);
    } else if (numericType === 18) {
      setType(18);
    }
  };
  const clearExerciseCart = () => {
    setExerciseCart([]);
    setClicked([]);
    setClicked1([]);
    setClicked2([]);
    setClicked3([]);
    setClicked4([]);
    setClicked5([]);
    setClicked6([]);
    setClicked7([]);
    setClicked8([]);
    setClicked9([]);
    setClicked10([]);
    setClicked11([]);
    setClicked12([]);
    setClicked13([]);
    setClicked14([]);
    setClicked15([]);
    setClicked16([]);
    setClicked17([]);
  };
  const handleRemoveFromCart = (indexToRemove) => {
    const updatedCart = exerciseCart.filter(
      (_, index) => index !== indexToRemove
    );
    setExerciseCart(updatedCart);
    // Reset background color for the removed item based on its type
    if (type === 1) {
      setClicked(clicked.filter((i) => i !== indexToRemove));
    } else if (type === 2) {
      setClicked1(clicked1.filter((i) => i !== indexToRemove));
    } else if (type === 3) {
      setClicked2(clicked2.filter((i) => i !== indexToRemove));
    } else if (type === 4) {
      setClicked3(clicked3.filter((i) => i !== indexToRemove));
    } else if (type === 5) {
      setClicked4(clicked4.filter((i) => i !== indexToRemove));
    } else if (type === 6) {
      setClicked5(clicked5.filter((i) => i !== indexToRemove));
    } else if (type === 7) {
      setClicked6(clicked6.filter((i) => i !== indexToRemove));
    } else if (type === 8) {
      setClicked7(clicked7.filter((i) => i !== indexToRemove));
    } else if (type === 9) {
      setClicked8(clicked8.filter((i) => i !== indexToRemove));
    } else if (type === 10) {
      setClicked9(clicked9.filter((i) => i !== indexToRemove));
    } else if (type === 11) {
      setClicked10(clicked10.filter((i) => i !== indexToRemove));
    } else if (type === 12) {
      setClicked11(clicked11.filter((i) => i !== indexToRemove));
    } else if (type === 13) {
      setClicked12(clicked12.filter((i) => i !== indexToRemove));
    } else if (type === 14) {
      setClicked13(clicked13.filter((i) => i !== indexToRemove));
    } else if (type === 15) {
      setClicked14(clicked14.filter((i) => i !== indexToRemove));
    } else if (type === 16) {
      setClicked15(clicked15.filter((i) => i !== indexToRemove));
    } else if (type === 17) {
      setClicked16(clicked16.filter((i) => i !== indexToRemove));
    } else if (type === 18) {
      setClicked17(clicked17.filter((i) => i !== indexToRemove));
    }
  };

  return (
    <>
      <Button
        onClick={() => handleToggle(1)}
        appearance="ghost"
        color="green"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Cardio Exercise
      </Button>
      <Button
        onClick={() => handleToggle(2)}
        appearance="ghost"
        color="red"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Flexibility Exercise
      </Button>
      <Button
        onClick={() => handleToggle(3)}
        appearance="ghost"
        color="yellow"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Upper Body Strength Exercise
      </Button>
      <Button
        onClick={() => handleToggle(4)}
        appearance="ghost"
        color="cyan"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Lower Body Strength Exercise
      </Button>
      <Button
        onClick={() => handleToggle(5)}
        appearance="ghost"
        color="orange"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Plyometric Exercise
      </Button>
      <Button
        onClick={() => handleToggle(6)}
        appearance="ghost"
        color="violet"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Core Exercise
      </Button>

      <Button
        onClick={() => handleToggle(7)}
        appearance="ghost"
        color="green"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Mobility Exercise
      </Button>
      <Button
        onClick={() => handleToggle(8)}
        appearance="ghost"
        color="red"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Olympic Lifts
      </Button>
      <Button
        onClick={() => handleToggle(9)}
        appearance="ghost"
        color="yellow"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Lumbo-Pelvic-Hip Exercise
      </Button>
      <Button
        onClick={() => handleToggle(10)}
        appearance="ghost"
        color="cyan"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Postural Controls Exercise
      </Button>
      <Button
        onClick={() => handleToggle(11)}
        appearance="ghost"
        color="orange"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Swiss Ball Exercise
      </Button>
      <Button
        onClick={() => handleToggle(12)}
        appearance="ghost"
        color="violet"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        TRX Exercise
      </Button>
      <Button
        onClick={() => handleToggle(13)}
        appearance="ghost"
        color="green"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Jumping Landing Techniques
      </Button>

      <Button
        onClick={() => handleToggle(14)}
        appearance="ghost"
        color="red"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Medicine Ball Exercise
      </Button>
      <Button
        onClick={() => handleToggle(15)}
        appearance="ghost"
        color="yellow"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Activation Exercises
      </Button>
      <Button
        onClick={() => handleToggle(16)}
        appearance="ghost"
        color="cyan"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Kettlebell Exercise
      </Button>
      <Button
        onClick={() => handleToggle(17)}
        appearance="ghost"
        color="orange"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Corrective Exercise
      </Button>
      <Button
        onClick={() => handleToggle(18)}
        appearance="ghost"
        color="violet"
        style={{ width: 200, marginTop: "1rem", marginLeft: "2rem" }}
      >
        Neuromuscular Preparation
      </Button>

      {type === null ? null : type === 1 ? (
        <ExerciseCard
          exerciseList={CardioExercises}
          show={showList0}
          setShow={setShowList0}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked}
          setSelectedIndices={setClicked}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 2 ? (
        <ExerciseCard
          exerciseList={FlexibilityExercise}
          show={showList1}
          setShow={setShowList1}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked1}
          setSelectedIndices={setClicked1}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 3 ? (
        <ExerciseCard
          exerciseList={UpperBodyStrengthExercise}
          show={showList2}
          setShow={setShowList2}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked2}
          setSelectedIndices={setClicked2}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 4 ? (
        <ExerciseCard
          exerciseList={LowerBodyStrengthExercise}
          show={showList3}
          setShow={setShowList3}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked3}
          setSelectedIndices={setClicked3}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 5 ? (
        <ExerciseCard
          exerciseList={PlyometricExercises}
          show={showList4}
          setShow={setShowList4}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked4}
          setSelectedIndices={setClicked4}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 6 ? (
        <ExerciseCard
          exerciseList={CoreExercises}
          show={showList5}
          setShow={setShowList5}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked5}
          setSelectedIndices={setClicked5}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 7 ? (
        <ExerciseCard
          exerciseList={MobilityExercise}
          show={showList6}
          setShow={setShowList6}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked6}
          setSelectedIndices={setClicked6}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 8 ? (
        <ExerciseCard
          exerciseList={OlympicLifts}
          show={showList7}
          setShow={setShowList7}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked7}
          setSelectedIndices={setClicked7}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 9 ? (
        <ExerciseCard
          exerciseList={LumboPelvicHip}
          show={showList8}
          setShow={setShowList8}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked8}
          setSelectedIndices={setClicked8}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 10 ? (
        <ExerciseCard
          exerciseList={PosturalControls}
          show={showList9}
          setShow={setShowList9}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked9}
          setSelectedIndices={setClicked9}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 11 ? (
        <ExerciseCard
          exerciseList={SwissBall}
          show={showList10}
          setShow={setShowList10}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked10}
          setSelectedIndices={setClicked10}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 12 ? (
        <ExerciseCard
          exerciseList={TRX}
          show={showList11}
          setShow={setShowList11}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked11}
          setSelectedIndices={setClicked11}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 13 ? (
        <ExerciseCard
          exerciseList={JLT}
          show={showList12}
          setShow={setShowList12}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked12}
          setSelectedIndices={setClicked12}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 14 ? (
        <ExerciseCard
          exerciseList={MBE}
          show={showList13}
          setShow={setShowList13}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked13}
          setSelectedIndices={setClicked13}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 15 ? (
        <ExerciseCard
          exerciseList={AE}
          show={showList14}
          setShow={setShowList14}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked14}
          setSelectedIndices={setClicked14}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 16 ? (
        <ExerciseCard
          exerciseList={KB}
          show={showList15}
          setShow={setShowList15}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked15}
          setSelectedIndices={setClicked15}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 17 ? (
        <ExerciseCard
          exerciseList={CET}
          show={showList16}
          setShow={setShowList16}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked16}
          setSelectedIndices={setClicked16}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : type === 18 ? (
        <ExerciseCard
          exerciseList={NMP}
          show={showList17}
          setShow={setShowList17}
          type={type}
          exerciseSelected={exerciseSelected}
          setExerciseSelected={setExerciseSelected}
          exerciseCart={exerciseCart}
          setExerciseCart={setExerciseCart}
          addToCartModalVisible={addToCartModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          exerciseCartModalVisible={exerciseCartModalVisible}
          setExerciseCartModalVisible={setExerciseCartModalVisible}
          selectedIndices={clicked17}
          setSelectedIndices={setClicked17}
          clearExerciseCart={clearExerciseCart}
          handleRemoveFromCart={handleRemoveFromCart}
        />
      ) : null}
    </>
  );
}
