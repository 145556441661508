import React, { useState, useEffect } from "react";
import { FetchResultsByFieldAthlete } from "../../components/custmomhooks/CustomHooks";
import CustomBarChart from "./BarChart";
import styles from "./injury.module.css";
import {  SelectPicker } from "rsuite";
import { ATHLETE_PROFILE_API_URL } from "../../services/API";
import { HttpService } from "../../services/http.service";
import NODATA from "./../../assets/image/noChart.svg";
import NOTSELECTED from "./../../assets/image/notSelected.jpg";
import { Typography } from "@mui/material";

export default function NutritionProfiling() {
  const [athlete, setAthlete] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataResults, setDataResults] = useState({
    calories: { data: [], title: "Calories" },
    basalMetabolicRate: { data: [], title: "BMR" },
    totalCarbs: { data: [], title: "Total Carbohydrates" },
    totalProtein: { data: [], title: "Total Protein" },
    totalFat: { data: [], title: "Total Fat" },
    minerals: { data: [], title: "Minerals" },
    vitamins: { data: [], title: "Vitamin" },
    
  });


  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true)
       
  
        const fetchPromises = [
  FetchResultsByFieldAthlete({
        dept: 10,
        cat: 100,
        field: 522,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 10,
        cat: 100,
        field: 523,
        athlete,
      }),
       FetchResultsByFieldAthlete({
        dept: 10,
        cat: 100,
        field: 524,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 10,
        cat: 100,
        field: 525,
        athlete,
      }),
     FetchResultsByFieldAthlete({
        dept: 10,
        cat: 100,
        field: 526,
        athlete,
      }),
      FetchResultsByFieldAthlete({
        dept: 10,
        cat: 100,
        field: 527,
        athlete,
      }),
       FetchResultsByFieldAthlete({
        dept: 10,
        cat: 100,
        field: 528,
        athlete,
      })
      
    ]
     
    const results = await Promise.all(fetchPromises);

    setDataResults((prevData) => ({
      ...prevData,
      calories: { data: results[0], title: "Calories" },
    basalMetabolicRate: { data: results[1], title: "BMR" },
    totalCarbs: { data: results[2], title: "Total Carbohydrates" },
    totalProtein: { data: results[3], title: "Total Protein" },
    totalFat: { data: results[4], title: "Total Fat" },
    minerals: { data: results[5], title: "Minerals" },
    vitamins: { data: results[6], title: "Vitamin" },
     
    }));

    setLoading(false);
  } catch (error) {
    console.error("Error fetching data:", error);
    setLoading(false);
  }
};

if (athlete) {
  fetchResults();
}
}, [athlete]);

  useEffect(() => {
    const fetchAthlete = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`,{}, false
        );
        const modifiedAthlete = response.data.results.map((ath) => ({
          value: ath.id,
          label: ath.name,
        }));
        setAthletes(modifiedAthlete);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthlete();
  }, [currentPage]);

  return (  <div>
    <div className={styles.belownav}>
      <SelectPicker
        label={"Select Athlete"}
        data={athletes}
        onSelect={(value) => setAthlete(value)}
        className={styles.selectpicker}
        onClean={() => {
          setAthlete([]);
        }}
      />
    </div>
    {loading === true ? (
      <div
        style={{
          marginTop: "100px",
          marginLeft:"240px",
          transition: "margin-left 0.3s",
        }}
      >
        <Typography variant="h4" color="darkviolet" textAlign="center">
          {athlete=== null ? "Select Athlete" : "Loading...."}
        </Typography>
      </div>
    ) : (
      <div className={styles.box_science_sports7}>
        <div className={styles.chartcontent}>
          <div className={styles.chartwrapper}>
            {Object.entries(dataResults).map(([key, data]) => (
              <div className={styles.chartitem} key={key}>
                <div className={styles.chartbox}>
                  {data?.data?.length <= 0 ? (
                    <div
                      style={{
                        width: "250px",
                        height: "250px",
                        margin: "0 auto",
                      }}
                    >
                     
                      {athlete!==null? (
                        <>
                          <img src={NODATA} alt="No data" />
                          <h6 style={{ textAlign: "center" }}>
                            No Records Available:{data?.title}
                          </h6>
                        </>
                      ) : (
                        <>
                          <h6> Athlete Not Selected</h6>
                          <img
                            src={NOTSELECTED}
                            style={{ width: "200px", height: "200px" }}
                            alt="No data"
                          />
                        </>
                      )}
                    </div>
                  ) : (
                    <div>
                     
                        
                        <CustomBarChart
                        data={data?.data}
                        title={data?.title}
                      />
                     
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
         
        </div>
      
      </div>
    )}
  </div>
);
}
