import React from "react";
import { Typography } from "@mui/material";
import FooterCard from "./FooterCard";
export default function Footer() {
  return (
    <>
     
      <FooterCard />
      <footer>
        <Typography
          variant="subtitle1"
          textAlign={"center"}
          fontWeight={"bold"}
        >
          &copy; THESPORTSANALYTICS.COM 2024 . All rights reserved
        </Typography>
        {/* Add links to privacy policy, terms of service, etc. */}
      </footer>
    </>
  );
}
