import React, { useContext,useEffect } from "react";
import {
  Form,
  Button,
  // SelectPicker,
  FlexboxGrid,
  // Placeholder,
  // Modal,
  // Schema,
  CheckPicker
} from "rsuite";
import { USER_API_URL } from '../../services/API';
import { HttpService } from '../../services/http.service';
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import CustomNotification from '../../components/CustomNotification';
import { Color } from "../../utils/Colors";
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

const AppointmentDetails = ({ appointment }) => {
 
  return (
    <div>
      <p>Title: {appointment.name}</p>
      <p>Description: {appointment.description}</p>
      <p>Date: {appointment.date}</p>
      <p>Time: {appointment.time}</p>
      


      <hr />
    </div>
  );
};
export default function Appointment({onSubmitSuccess}) {
  const formRef = React.useRef();
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const [users, setUser] = React.useState();
  const [record, setRecord] = React.useState();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    name: "",
    description: "",
    date: "",
    time: "",
    to: "",

  });
  const [errorCount, setErrorCount] = React.useState(0);
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await HttpService.get(
          `${USER_API_URL}`, {}, true
        );

        const modifiedUsers = response.data.results.map((user) => ({
          value: user.id,
          label: user.first_name + " " + user.last_name,
        }));

        setUser(modifiedUsers);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchUser();
  }, []);

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      console.error("Form Error", formError);
      return;
    }
    const recipients = Array.isArray(record) ? record.map(item => ({ recipient: item })) : [{ recipient: record }];

    try {
      const formData = {
        name: formValue.name,
        description: formValue.description,
        date: formValue.date,
        time: formValue.time,
        to: recipients,

        created_by: decode.user_id,
      };

    
      // const response = await HttpService.post(
      //   NOTIFICATION_CREATE,
      //   {},
      //   formData,false
      // );

      setResponse(formData);

      if (res.status === 201) {
        setErrorCount(prevCount => prevCount + 1);

        setOpenModal(true);
        setError("Form Submitted");
        onSubmitSuccess();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };
  
  return (
    <div>
      {res!==undefined&& (
                <div style={{ marginTop: 20 }}>
                  <h5>Added Appointment</h5>
                  
                    <AppointmentDetails  appointment={res} />
                  
                </div>
              )}
      <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        <Form
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}

        >
          <TextField name="name" label="Appointment Name" />
          <TextField name="description" label="Description" />
          <input
            type="date"
            value={formValue.date}
            onChange={(e) => setFormValue({ ...formValue, date: e.target.value })}

            required
          />
          <br/>
          <br/>
          <input
            type="time"
            placeholder="Enter preferred time"
            value={formValue.time}
            onChange={(e) => setFormValue({ ...formValue, time: e.target.value })}

            required
          />


          <br />
          <br />
          <CheckPicker
            label="To"
            data={users}
            onSelect={(value) => setRecord(value)}
            style={{ width: 300 }}
          />

          <Button appearance="primary" onClick={handleSubmit}
            style={{ background: Color.Color_C, color: Color.Color_A, width: 300, marginTop: "2rem" }}>
            Submit
          </Button>
          <CustomNotification
            errorCount={errorCount}
            open={openModal}
            setOpen={setOpenModal}
            message={
              error ? error : (res && res.data && res.data.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>
    </div>
    
  )
}
