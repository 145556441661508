import React from "react";
import styles from "./../pages/sportsscience/science.module.css"
import EvaluationForm from "../pages/sportsscience/EvaluationForm";
import ExternalLoad from "../pages/sportsscience/subcategories/loadmonitoring/ExtrenalLoad";
import SportSpecificTID from "../pages/sportsscience/subcategories/talentidentification/SportSpecificTID";
import UploadDocuments from "../pages/sportsscience/UploadDocuments";
const formConfigurations = {
  Assessment: { component: EvaluationForm, title: "Assessment" },
  Plan: { component: EvaluationForm, title: "Plan" },
  "Injury Incidence Rate": {
    component: EvaluationForm,
    title: "Injury Incidence",
  },
  Biochemistry: { component: EvaluationForm, title: "Biochemistry" },
  "Pain Assessment": { component: EvaluationForm, title: "Pain Assessment" },
  "On Observation": { component: EvaluationForm, title: "On Observation" },
  "On Palpation": { component: EvaluationForm, title: "On Palpation" },
  "Sensory Examination": {
    component: EvaluationForm,
    title: "Sensory Examination",
  },
  "Shoulder Joint - ROM": {
    component: EvaluationForm,
    title: "Shoulder Joint - ROM",
  },
  "Elbow Joint - ROM": {
    component: EvaluationForm,
    title: "Elbow Joint - ROM",
  },
  "Wrist Joint ROM": { component: EvaluationForm, title: "Wrist Joint ROM" },
  "Neck ROM": { component: EvaluationForm, title: "Neck ROM" },
  "Spinal ROM": { component: EvaluationForm, title: "Spinal ROM" },
  "Hip Joint - ROM": { component: EvaluationForm, title: "Hip Joint - ROM" },
  "Knee Joint - ROM": { component: EvaluationForm, title: "Knee Joint - ROM" },
  "Ankle Joint - ROM": {
    component: EvaluationForm,
    title: "Ankle Joint - ROM",
  },
  "Shoulder Joint - MMT": {
    component: EvaluationForm,
    title: "Shoulder Joint - MMT",
  },
  "Elbow Joint - MMT": {
    component: EvaluationForm,
    title: "Elbow Joint - MMT",
  },
  "Wrist Joint - MMT": {
    component: EvaluationForm,
    title: "Wrist Joint - MMT",
  },
  "Cervical Joint - MMT": {
    component: EvaluationForm,
    title: "Cervical Joint - MMT",
  },
  "Spinal Joint - MMT": {
    component: EvaluationForm,
    title: "Spinal Joint - MMT",
  },
  "Hip Joint - MMT": { component: EvaluationForm, title: "Hip Joint - MMT" },
  "Knee Joint - MMT": { component: EvaluationForm, title: "Knee Joint - MMT" },
  "Ankle Joint - MMT": {
    component: EvaluationForm,
    title: "Ankle Joint - MMT",
  },
  "Spinal Examination": {
    component: EvaluationForm,
    title: "Spinal Examination",
  },
  "Shoulder Joint - RIC": {
    component: EvaluationForm,
    title: "Shoulder Joint - RIC",
  },
  "Elbow Joint - RIC": {
    component: EvaluationForm,
    title: "Elbow Joint - RIC",
  },
  "Wrist Joint - RIC": {
    component: EvaluationForm,
    title: "Wrist Joint - RIC",
  },
  "Neck - RIC": { component: EvaluationForm, title: "Neck - RIC" },
  "Spine - RIC": { component: EvaluationForm, title: "Spine - RIC" },
  "Hip Joint - RIC": { component: EvaluationForm, title: "Hip Joint - RIC" },
  "Knee Joint - RIC": { component: EvaluationForm, title: "Knee Joint - RIC" },
  "Ankle Joint - RIC": {
    component: EvaluationForm,
    title: "Ankle Joint - RIC",
  },
  "Mckenzie Examination": {
    component: EvaluationForm,
    title: "Mckenzie Examination",
  },
  "Shoulder Joint - Special Test": {
    component: EvaluationForm,
    title: "Shoulder Joint - Special Test",
  },
  "Elbow Joint - Special Test": {
    component: EvaluationForm,
    title: "Elbow Joint - Special Test",
  },
  "Wrist Joint - Special Test": {
    component: EvaluationForm,
    title: "Wrist Joint - Special Test",
  },
  "Cervical - Special Test": {
    component: EvaluationForm,
    title: "Cervical - Special Test",
  },
  "Spine & Hip Joint - Special Test": {
    component: EvaluationForm,
    title: "Spine & Hip Joint - Special Test",
  },
  "Knee Joint - Special Test": {
    component: EvaluationForm,
    title: "Knee Joint - Special Test",
  },
  "Ankle Joint - Special Test": {
    component: EvaluationForm,
    title: "Ankle Joint - Special Test",
  },
  "Functional Examination": {
    component: EvaluationForm,
    title: "Functional Examination",
  },
  "Functional Movement Screening": {
    component: EvaluationForm,
    title: "Functional Movement Screening",
  },
  Tightness: { component: EvaluationForm, title: "Tightness" },
  Demographic: { component: EvaluationForm, title: "Demographic" },
  History: { component: EvaluationForm, title: "History" },
  
  "Musculoskeletal History": {
    component: EvaluationForm,
    title: "Musculoskeletal History",
  },
  "General History": { component: EvaluationForm, title: "General History" },
  "COVID History": { component: EvaluationForm, title: "COVID History" },
  "Heart Related History": {
    component: EvaluationForm,
    title: "Heart Related History",
  },
  "Other History": { component: EvaluationForm, title: "Other History" },
  "Physical Examination": {
    component: EvaluationForm,
    title: "Physical Examination",
  },
  "Cardiovascular Examination": {
    component: EvaluationForm,
    title: "Cardiovascular Examination",
  },
  "Neurological Examination": {
    component: EvaluationForm,
    title: "Neurological Examination",
  },
  "Musculoskeletal Examination": {
    component: EvaluationForm,
    title: "Musculoskeletal Examination",
  },
  "Blood Examination": {
    component: EvaluationForm,
    title: "Blood Examination",
  },
  "Body Composition Analysis": { component: EvaluationForm, title: "Body Composition Analysis" },
 
  Cardiovascular: { component: EvaluationForm, title: "Cardiovascular" },
  Flexibility: { component: EvaluationForm, title: "Flexibility" },
  Balance: { component: EvaluationForm, title: "Balance" },
  "Musculoskeletal Endurance": {
    component: EvaluationForm,
    title: "Musculoskeletal Endurance",
  },
  "Speed Test": { component: EvaluationForm, title: "Speed Test" },
  "Agility Test": { component: EvaluationForm, title: "Agility Test" },
  Strength: { component: EvaluationForm, title: "Strength" },
  Power: { component: EvaluationForm, title: "Power" },
  "Running Based Anaerobic Sprint Test": {
    component: EvaluationForm,
    title: "Running Based Anaerobic Sprint Test",
  },
  Coordination: { component: EvaluationForm, title: "Coordination Test" },
  "Reaction Time": { component: EvaluationForm, title: "Reaction Time" },
  "Lactate Analysis": { component: EvaluationForm, title: "Anaerobic" },
  "Cooper's Test": { component: EvaluationForm, title: "Cooper's Test" },
  "Harvard Step Test": {
    component: EvaluationForm,
    title: "Harvard Step Test",
  },
  "Yo Yo Intermittent Recovery Test": {
    component: EvaluationForm,
    title: "Yo Yo Intermittent Recovery Test",
  },
  Somatotype: { component: EvaluationForm, title: "Somatotype" },
  "Growth & Devlopment": {
    component: EvaluationForm,
    title: "Growth & Devlopment",
  },
  "Body Composition": { component: EvaluationForm, title: "Body Composition" },
  "Motivational Quality": {
    component: EvaluationForm,
    title: "Motivational Quality",
  },
  Anxiety: { component: EvaluationForm, title: "Anxiety" },
  "Psychological Performance Inventory": {
    component: EvaluationForm,
    title: "Psychological Performance Inventory",
  },
  Isokinetic: { component: EvaluationForm, title: "Isokinetic" },
  Spatial: { component: EvaluationForm, title: "Spatial" },
  Temporal: { component: EvaluationForm, title: "Temporal" },
  "Squat Jump": { component: EvaluationForm, title: "Squat Jump" },
  "Drop Jump": { component: EvaluationForm, title: "Drop Jump" },
  "Counter Movement Jump": {
    component: EvaluationForm,
    title: "Counter Movement Jump",
  },
  
  "Isometric Mid - Thigh Pull": {
    component: EvaluationForm,
    title: "Isometric Mid - Thigh Pull",
  },
  "Athletic Shoulder Test": {
    component: EvaluationForm,
    title: "Athletic Shoulder Test",
  },
  "Shoulder Joint - IP": { component: EvaluationForm, title: "Shoulder Joint" },
  "Elbow Joint - IP": { component: EvaluationForm, title: "Elbow Joint" },
  "Wrist Joint - IP": { component: EvaluationForm, title: "Wrist Joint" },
  "Neck Joint - IP": { component: EvaluationForm, title: "Cervical" },
  "Lower Back - IP": { component: EvaluationForm, title: "Low Back" },
  "Hip Joint - IP": { component: EvaluationForm, title: "Hip Joint" },
  "Knee Joint - IP": { component: EvaluationForm, title: "Knee Joint" },
  "Ankle Joint - IP": { component: EvaluationForm, title: "Temporal" },
  Archery: { component: EvaluationForm, title: "Archery" },
  Badminton: { component: EvaluationForm, title: "Badminton" },
  Boxing: { component: EvaluationForm, title: "Boxing" },
  Swimming: { component: EvaluationForm, title: "Swimming" },
  Shooting: { component: EvaluationForm, title: "Shooting" },
  "Lawn Tennis": { component: EvaluationForm, title: "Lawn Tennis" },
  "Table Tennis": { component: EvaluationForm, title: "Table Tennis" },
  "Weight Lifting": { component: EvaluationForm, title: "Weight Lifting" },
  Wrestling: { component: EvaluationForm, title: "Wrestling" },
  Cricket: { component: EvaluationForm, title: "Cricket" },
  Football: { component: EvaluationForm, title: "Football" },
  "Athletic Sprint KPI": {
    component: EvaluationForm,
    title: "Athletic Sprint KPI",
  },
  "Athletic Middle Distance KPI": {
    component: EvaluationForm,
    title: "Athletic Middle Distance KPI",
  },
  "Athletic Long Distance KPI": {
    component: EvaluationForm,
    title: "Athletic Long Distance KPI",
  },
  "Athletic Jump KPI": {
    component: EvaluationForm,
    title: "Athletic Jump KPI",
  },
  "Athletic Throw KPI": {
    component: EvaluationForm,
    title: "Athletic Throw KPI",
  },
  "Dietry Allowance": { component: EvaluationForm, title: "Dietry Allowance" },
  "Internal Load": { component: EvaluationForm, title: "Internal Load" },
  "Wellness Monitoring": {
    component: EvaluationForm,
    title: "Wellness Monitoring",
  },
  "External Load": { component: ExternalLoad, title: "External Load" },
  "Skill Related": { component: EvaluationForm, title: "Skill Related" },
  "Strength & Conditioning Related": {
    component: EvaluationForm,
    title: "Strength & Conditioning Related",
  },
  "Motor Quality": { component: EvaluationForm, title: "Motor Quality" },
  "Sports Specific Skills": {
    component: SportSpecificTID,
    title: "Sports Specific Skills",
  },
  "Athletics Talent Identification": {
    component: EvaluationForm,
    title: "Athletics",
  },
  "Archery Talent Identification": {
    component: EvaluationForm,
    title: "Archery",
  },
  "Badminton Talent Identification": {
    component: EvaluationForm,
    title: "Badminton",
  },
  "Boxing Talent Identification": {
    component: EvaluationForm,
    title: "Boxing",
  },
  "Swimming Talent Identification": {
    component: EvaluationForm,
    title: "Swimming",
  },
  "Shooting Talent Identification": {
    component: EvaluationForm,
    title: "Shooting",
  },
  "Lawn Tennis Talent Identification": {
    component: EvaluationForm,
    title: "Lawn Tennis",
  },
  "Table Tennis Talent Identification": {
    component: EvaluationForm,
    title: "Table Tennis",
  },
  "Weight Lifting Talent Identification": {
    component: EvaluationForm,
    title: "Weight Lifting",
  },
  "Wrestling Talent Identification": {
    component: EvaluationForm,
    title: "Wrestling",
  },
  "Young Age": { component: EvaluationForm, title: "Young Age" },
  Adult: { component: EvaluationForm, title: "Adult" },
  "Old Age": { component: EvaluationForm, title: "Old Age" },
  Condition: { component: EvaluationForm, title: "Condition" },
  "Key Information": { component: EvaluationForm, title: "Key Information" },
   "Upload Documents": {
    component: UploadDocuments,
    title: "Upload Documents",
  }
};

const EvaluationFormView = ({
 
 
  mode,
  dept,
  cat,
  athlete,
  session,
  date
}) => {
  const { component: FormComponent } = formConfigurations[mode] || {};

  
  return (
    <div className={styles.box_science_sports6}>
      
        
          {FormComponent && (
            <FormComponent
              dept={dept}
              cat={cat}
              mode={mode}
              athlete_id={athlete}
              session_id={session}
             
              selectedDate = {date}
            />
          )}

       
    
    </div>
  );
};

export default EvaluationFormView;
