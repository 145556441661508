import React, { useState } from "react";
import ExternalLoad from "./subcategories/loadmonitoring/ExtrenalLoad";
import styles from "./science.module.css";
import PlaceHolder from "./PlaceHolder";
import EvaluationFormView from "../../components/EvaluationFormView";
export default function LoadMonitoring({label}) {
  const [open, setOpen] = useState(false);
  // const [placement, setPlacement] = useState();
  const [drawerTitle, setDrawerTitle] = useState();
  const [dept, setDept] = useState();
  const [cat, setCat] = useState();
  
  const data = [
    {
      dept: 9,
      name: "Internal Load",
      cat: 96,
    
    },
    {
      name: "External Load",

    
    },
    {
      dept: 9,
      name: "Wellness Monitoring",
      cat: 99,
    
    },
  ];

  const handleOpen = ({ name, key, dept, cat }) => {
    setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
    setDept(dept);
    setCat(cat);
  };

  return (
    <div className={styles.sport_main} >
    
      <div className={styles.sports_main_inner}>
          <select
            className={styles.dropdown}
            onChange={(e) => {
              const selectedValue = e.target.value;
              const selectedItem = data.find(
                (item) => item.name === selectedValue
              );

              if (selectedItem) {
                handleOpen({
                  name: selectedItem.name,
                  dept: selectedItem.dept,
                  cat: selectedItem.cat,
                });
              }
            }}
          >
            <option value="">{label}</option>
            {data.map((item) => (
              <option key={item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
     

      {drawerTitle===undefined?<PlaceHolder/> :drawerTitle === "External Load" ? (
        <ExternalLoad mode={drawerTitle}/>
      ) : (
        <EvaluationFormView
          open={open}
          setOpen={setOpen}
          size={"lg"}
          placement={"left"}
          mode={drawerTitle}
          dept={dept}
          cat={cat}
        />
      )}
    </div>
  );
}
