import React, {  useEffect } from "react";
import { Button, IconButton, Modal, } from "rsuite";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CustomNotification from "../../components/CustomNotification";
// import { GetTeamData } from "../../components/custmomhooks/CustomHooks";
import styles from "./../sportsscience/science.module.css"
import { Color } from "../../utils/Colors";
import UserCustomDrawer from "../../components/CustomDrawer";
import { HttpService } from "../../services/http.service";
import {  TEAM_API_URL } from "../../services/API";
// import { AuthContext } from "../../store/AuthContext";
// import jwt_decode from "jwt-decode";
import TrashIcon from "@rsuite/icons/Trash";
import DeleteTeam from "./DeleteTeam";

export default function Team() {
  const [teamData, setTeamData] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1); // Total number of pages
  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState(0)
  const [editModal, setEditModal] = React.useState(false)
  // const authCtx = useContext(AuthContext);
  const [mode, setMode] = React.useState(null);
  const [teamID, setTeamID] = React.useState();
  const [name, setName] = React.useState()
  // const token = authCtx.token;
  // let decode = "";
  // if (token) {
  //   decode = jwt_decode(token);
  // } else {
  //   decode = "";
  // }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${TEAM_API_URL}?page=${currentPage}`, {}, false
        );
        const modifiedTeamData = response.data.results.map(team => ({
          id: team.id,
          name: team.name,
          date: team.created_at.slice(0, 10)
        }));
        setTeamData(modifiedTeamData);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setTeamData,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
    count
  ])
 



  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1); // Page is 0-based, API expects 1-based
  };
  const columns = [

    {
      field: "date",
      headerName: "Date",
      width: 500,
      // editable: true,
      headerAlign: "center",
      align: "center",
      headerClassName: 'super-app-theme--header',

    },
    {
      field: "name",
      headerName: "Name",
      width: 500,
      // editable: true,
      headerAlign: "center",
      align: "center",
      headerClassName: 'super-app-theme--header',

    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <IconButton          onClick={() => handleOpen2(params.row.id, true, "delete",params.row.name)}
          color="red"
          appearance="primary"
          icon={<TrashIcon />}
        ></IconButton>
      ),
    },


  ];
  const handleOpen2 = (id, status, mode,email) => {
    setTeamID(id);
    setEditModal(status);
    setMode(mode);
    setName(email);
    
  };
  const handleClose2 = () => {
    setTeamID(null);
    setEditModal(false);
    setMode(null);
    setName(null)
  };

  return (
    <div >
      <div className={styles.belownav}>
         <Button

          appearance="primary"
          onClick={()=>setOpen(true)}
          className={styles.btn}
          style={{ background: "cadetblue" }}

        >
          Create Team
        </Button> 
      </div>
      <div className={styles.tablular}>

        {teamData === undefined ? (
          <>
           <label>No Data</label>
            {error?.response?.status === 200 ? null : (
              <CustomNotification
                open={openModal}
                setOpen={setOpenModal}
                message={
                  error?.response?.status === 200
                    ? "Loading"
                    : error?.message
                }
                title={
                  error?.response?.status === 200
                    ? "Loading"
                    : "Error"
                }
              />
            )}
          </>
        ) : (
          <Box>
            <DataGrid
              sx={{

                '& .super-app-theme--header': {
                  color: Color.Color_C

                },
              }}
              getRowId={(row) => row.id || null}
              rows={teamData}
              columns={columns}
              pagination
              paginationMode="server"
              rowCount={totalPages * 10} // Total number of rows (assuming 10 per page)
              onPageChange={handlePageChange} // Handle page changes
              pageSize={10} // Number of rows per page
              page={currentPage - 1} // Current page (0-based)
              paginationComponentProps={{
                // Customize the appearance of the pagination component
                disableNext: currentPage === totalPages,
                disablePrev: currentPage === 1,
              }}
            />
          </Box>
        )}

      </div>
      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={"right"}
        mode={"Team"}
        setCount={setCount}
      />
  <Modal size="lg" open={editModal} onClose={handleClose2}>
        {mode === "edit" ? (
        null
        ) : mode === "delete" ? (
          <DeleteTeam id={teamID} name = {name} onSucess={handleClose2} setCount={setCount} />
        ):null}
      </Modal>
    </div>

  );
}
