import React from "react";
import {
    Form,
    Button,
    // SelectPicker,
    ButtonToolbar,
    FlexboxGrid,
    // DatePicker,
} from "rsuite";
import { HttpService } from "../../services/http.service";
import { ADMIN_API_URL } from "../../services/API";

import { useEffect } from "react";

const TextField = React.forwardRef((props, ref) => {
    const { name, label, accepter,placeholder, ...rest } = props;
    return (
        <Form.Group controlId={`${name}-4`} ref={ref}>
            <Form.ControlLabel>{label} </Form.ControlLabel>
            <Form.Control name={name} accepter={accepter} placeholder={placeholder} {...rest} />
        </Form.Group>
    );
});

export default function EditAdminProfile({ id }) {
    const [formError, setFormError] = React.useState({});
    const [res, setResponse] = React.useState();
    const [data, setData] = React.useState();
    const formRef = React.useRef();
    const [formValue, setFormValue] = React.useState({
        department: "",
        designation: "",
    });

    // console.log(teams)
    useEffect(() => {
        const fetchAdmin = async () => {
            try {
                const response = await HttpService.get(`${ADMIN_API_URL}${id}/`,{}, false);

                setData(response.data);
            } catch (error) {
                console.warn(error);
            }
        };

        fetchAdmin();
    }, [id]);



    const handleSubmit = async () => {
        if (!formRef.current.check()) {
            console.error("Form Error", formError);
            return;
        }
        const { ...admindata } = formValue;

        try {
            const adminProfile = {
                department: admindata.department,
                designation: admindata.designation,
            };
            const formData = new FormData();

            //   formData.append("teams");
            formData.append("admin_profile.department", adminProfile.department?.length <= 0 ? data?.department : adminProfile.department);
            formData.append("admin_profile.designation", adminProfile.designation?.length <= 0 ? data?.designation : adminProfile.designation);

            // console.log(formData);

            const response = await HttpService.patch(ADMIN_API_URL+data.id+"/", {}, formData,false);

            setResponse(response);

            if (response.status === 201) {
                alert("Form Submitted");
            }
        } catch (error) {
            if (error?.response && error?.response?.data) {
                if (typeof error.response.data === "object") {
                    const errorMessages = Object.values(error?.response?.data).join("\n");
                    alert(errorMessages || "An error occurred");
                } else {
                    alert(error?.response?.data?.message || "An error occurred");
                }
            } else {
                alert("An error occurred");
            }
        }
    };

    return (
        <FlexboxGrid>
            <FlexboxGrid.Item colspan={12}>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    encType="multipart/form-data"
                >
                    <TextField name="department" label="Department" placeholder={data?.department} />
                    <TextField name="designation" label="Designation" placeholder={data?.designation} />

                    <ButtonToolbar>
                        <Button appearance="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </ButtonToolbar>
                </Form>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
}
