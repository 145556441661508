import React from "react";
import "./../landing/styles.css";
import {
  Form,
  Button,
  FlexboxGrid,
  SelectPicker,
} from "rsuite";
import { HttpService } from "../../services/http.service";
import { APP_PERMISSION, APP_PERMISSION_URL } from "../../services/API";
import CustomNotification from "../CustomNotification";
import { useContext } from "react";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import { Color } from "../../utils/Colors";
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

export default function AppPermissionURL({  onSubmitSuccess,setCount}) {
  const [errorCount, setErrorCount] = React.useState(0);
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [urls, setURLS] = React.useState();
  const [url, setURL] = React.useState();
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const formRef = React.useRef();
  React.useEffect(() => {
    const fetchTeam = async () => {
      try {
        const response = await HttpService.get(
          `${APP_PERMISSION}`,
          {},
          true
        );

        const modURL = response.data.results.map((t) => ({
          value: t.id,
          label: t.name,
        }));

        setURLS(modURL);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchTeam();
  }, []);
  const [formValue, setFormValue] = React.useState({
    url: "",
    app_permission:url
  });


  const handleSubmit = async () => {
    try {
      const formData = {
        ...formValue,
        
      };
      const response = await HttpService.post(APP_PERMISSION_URL, {}, formData, true);

      setResponse(response);
      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setError("Form Submitted"); // Update error state to indicate success
        setOpenModal(true);
        setFormValue({ name: "" });
        onSubmitSuccess()
        return; // Exit early to prevent further execution
      }
    } catch (e) {

      let errorMessage = "An error occurred"; // Default error message

      if (e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setError(errorMessage);

      if (e.response && e.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
   
      <FlexboxGrid>
        <FlexboxGrid.Item >

          <Form ref={formRef} onChange={setFormValue} formValue={formValue}>
            <TextField name="name" label="Name" />

            <SelectPicker
                label="Select URL"
                data={urls}
                onSelect={(value) => setURL(value)}
                style={{ width: 300 }}
              />
              <br/>

<br/>            <Button appearance="primary" onClick={handleSubmit} style={{ background: Color.Color_C, color: Color.Color_A, width: 300 }}
            >
              Setup Permission Url
            </Button>

            <CustomNotification
              open={openModal}
              errorCount={errorCount} 
              setOpen={setOpenModal}
              nav={"/profile_management"}
              message={
                error ? error : (res && res.data && res.data.message) || ""
              }
              title={error === "Form Submitted" ? "Success" : "Error"}
            />
          </Form>

        </FlexboxGrid.Item>
      </FlexboxGrid>
   

  );
}
