// CustomTestBattery.js
import React, { useState, forwardRef } from "react";
import styles from "./science.module.css";
import { Button, Modal } from "rsuite";
import Label from "../../components/Label";
import TrashIcon from "@rsuite/icons/Trash";
import { Badge, Tooltip } from "@mui/material";
import MinusIcon from "@rsuite/icons/Minus";
import PlusIcon from "@rsuite/icons/Plus";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { CUSTOM_API } from "../../services/API";
import { HttpService } from "../../services/http.service";
import { Color } from "../../utils/Colors";
import CustomNotification from "../../components/CustomNotification";
const ForwardedTooltip = forwardRef((props, ref) => {
  return <Tooltip ref={ref} {...props} />;
});
const TextField = ({
  title,
  onChange,
  value,
  fieldName,
  onFieldNameChange,
}) => (
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
    <Label
      sx={{ marginRight: "1rem", fontSize: 12, width: "4rem" }}
      color="secondary"
    >
      {" "}
      <ForwardedTooltip title={title} placement="left-end">
        {"Text"}
      </ForwardedTooltip>
    </Label>
    <div style={{ marginRight: "1rem" }}>
      <input
        type="text"
        style={{ width: "150px" }}
        value={fieldName}
        onChange={(e) => onFieldNameChange(e.target.value)}
        placeholder="Field Name"
      />
    </div>
  </div>
);

const NumberField = ({
  title,
  onChange,
  value,
  fieldName,
  onFieldNameChange,
}) => (
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
    <Label
      sx={{ marginRight: "1rem", fontSize: 12, width: "4rem" }}
      color="secondary"
    >
      {" "}
      <ForwardedTooltip title={title} placement="left-end">
        {"Number"}
      </ForwardedTooltip>
    </Label>
    <div style={{ marginRight: "1rem" }}>
      <input
        type="text"
        style={{ width: "150px" }}
        value={fieldName}
        onChange={(e) => onFieldNameChange(e.target.value)}
        placeholder="Field Name"
      />
    </div>
  </div>
);

const DateField = ({
  title,
  onChange,
  value,
  fieldName,
  onFieldNameChange,
}) => (
  <div style={{ display: "flex", flexDirection: "row" }}>
    <Label
      sx={{ marginRight: "1rem", fontSize: 12, width: "4rem" }}
      color="secondary"
    >
      <ForwardedTooltip title={title} placement="left-end">
        {"Date"}
      </ForwardedTooltip>
    </Label>
    <div style={{ marginRight: "1rem" }}>
      <input
        type="text"
        value={fieldName}
        style={{ width: "150px" }}
        onChange={(e) => onFieldNameChange(e.target.value)}
        placeholder="Field Name"
      />
    </div>
  </div>
);
const RangeField = ({
  title,

  fieldName,
  onFieldNameChange,
  handleMaxChange,
  handleMinChange,
  handleStepChange,
  minValue,
  maxValue,
  stepValue,
}) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Label
        sx={{ marginRight: "1rem", fontSize: 12, width: "4rem" }}
        color="secondary"
      >
        {" "}
        <ForwardedTooltip title={title} placement="left-end">
          {"Range"}
        </ForwardedTooltip>
      </Label>
      <div style={{ marginRight: "1rem" }}>
        <input
          style={{ width: "150px" }}
          type="text"
          value={fieldName}
          onChange={(e) => onFieldNameChange(e.target.value)}
          placeholder="Field Name"
        />
      </div>
      <div
        style={{ marginRight: "1rem", display: "flex", flexDirection: "row" }}
      >
        <Label>Min:</Label>
        <input
          style={{ width: "150px" }}
          type="text"
          id="minInput"
          value={minValue}
          onChange={(e) => handleMinChange(e.target.value)}
        />
      </div>
      <div
        style={{ marginRight: "1rem", display: "flex", flexDirection: "row" }}
      >
        <Label>Max:</Label>
        <input
          style={{ width: "150px" }}
          type="text"
          id="maxInput"
          value={maxValue}
          onChange={(e) => handleMaxChange(e.target.value)}
        />
      </div>
      <div
        style={{ marginRight: "1rem", display: "flex", flexDirection: "row" }}
      >
        <Label>Step:</Label>
        <input
          style={{ width: "150px" }}
          type="text"
          id="stepInput"
          value={stepValue}
          onChange={(e) => handleStepChange(e.target.value)}
        />
      </div>
    </div>
  );
};
const OptionField = ({
  title,
  options,
  onOptionChange,
  onRemoveOption,
  fieldName,
  onFieldNameChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [newOption, setNewOption] = useState("");

  const handleAddOption = () => {
    if (newOption.trim() !== "" && !options.includes(newOption)) {
      onOptionChange(options.length, { target: { value: newOption } });
      setNewOption("");
    }
  };

  const handleRemoveOption = () => {
    if (selectedOption && options.includes(selectedOption)) {
      const indexToRemove = options.indexOf(selectedOption);
      onRemoveOption(indexToRemove);
      setSelectedOption(null);
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Label
        sx={{ marginRight: "1rem", fontSize: 12, width: "4rem" }}
        color="secondary"
      >
        <ForwardedTooltip title={title} placement="left-end">
          {"Option"}
        </ForwardedTooltip>
      </Label>
      <div style={{ marginRight: "1rem" }}>
        <input
          type="text"
          style={{ width: "150px" }}
          value={fieldName}
          onChange={(e) => onFieldNameChange(e.target.value)}
          placeholder="Field Name"
        />
        <input
          type="text"
          style={{ width: "150px", marginLeft: "1rem" }}
          value={newOption}
          onChange={(e) => setNewOption(e.target.value)}
          placeholder="New Option"
        />
        <Button
          size="sm"
          appearance="primary"
          color="green"
          style={{ marginLeft: "1rem" }}
          onClick={handleAddOption}
        >
          <PlusIcon />
        </Button>
      </div>
      <div style={{ marginRight: "1rem" }}></div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <select
          style={{ height: "30px" }}
          value={selectedOption !== null ? selectedOption : ""}
          onChange={(e) =>
            setSelectedOption(e.target.value !== "" ? e.target.value : null)
          }
        >
          <option value="">Modify Existing Option</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        <Button
          size="sm"
          appearance="primary"
          color="red"
          style={{ marginLeft: "1rem" }}
          onClick={handleRemoveOption}
        >
          <MinusIcon />
        </Button>
      </div>
    </div>
  );
};
const fieldComponents = {
  text: TextField,
  date: DateField,
  number: NumberField,
  option: OptionField,
  range: RangeField,
};

const SubmittedTestBattery = ({ formData, onSubmit }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h6>Submitted Test Battery: Preview</h6>
      <Label sx={{ margin: "1rem" }} color="success">
        Form Title: {formData?.formTitle}
      </Label>
      {formData?.formFields.map((item, index) => {
        return (
          <div style={{ marginTop: "1rem" }} key={index}>
            {item.fieldType === "text" ? (
              <div>
                <Label color="secondary" sx={{ width: "100px", mr: "1rem" }}>
                  {item.fieldName}
                </Label>
                <input
                  readOnly
                  style={{ width: "150px" }}
                  type="text"
                  defaultValue={""}
                />
              </div>
            ) : item.fieldType === "number" ? (
              <div>
                <Label color="secondary" sx={{ width: "100px", mr: "1rem" }}>
                  {item.fieldName}
                </Label>
                <input
                  defaultValue={""}
                  readOnly
                  style={{ width: "150px" }}
                  type="number"
                />
              </div>
            ) : item.fieldType === "date" ? (
              <div>
                <Label color="secondary" sx={{ width: "100px", mr: "1rem" }}>
                  {item.fieldName}
                </Label>
                <input
                  defaultValue={""}
                  readOnly
                  style={{ width: "155px", height: "26px" }}
                  type="date"
                />
              </div>
            ) : item.fieldType === "range" ? (
              <div>
                <Label color="secondary" sx={{ width: "100px", mr: "1rem" }}>
                  {item.fieldName}
                </Label>
                <input
                  style={{ width: "150px" }}
                  readOnly
                  defaultValue={""}
                  type="range"
                  max={item.maxValue}
                  min={item.minValue}
                  step={item.stepValue}
                />
              </div>
            ) : item.fieldType === "option" ? (
              <div>
                <Label color="secondary" sx={{ width: "100px", mr: "1rem" }}>
                  {item.fieldName}
                </Label>
                <select
                  style={{ width: "160px", height: "30px" }}
                  defaultValue={item.value || ""}
                >
                  <option defaultValue={""} value="" disabled hidden>
                    Select an option
                  </option>
                  {item.options.map((option, optionIndex) => (
                    <option key={optionIndex} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
          </div>
        );
      })}
      <Button
        appearance="ghost"
        color="violet"
        size="sm"
        style={{ margin: "0.5rem" }}
        onClick={() => onSubmit(formData)} // Call handleSubmitToCart when button is clicked
      >
        Submit to Cart
      </Button>
    </div>
  );
};

const CustomTestBattery = ({ setModal }) => {
  const [errorCount, setErrorCount] = React.useState(0);
  const [error, setError] = React.useState();
  const [res, setResponse] = React.useState();

  const [formFields, setFormFields] = useState([]);
  const [formTitle, setFormTitle] = useState("");
  const [selectedFieldType, setSelectedFieldType] = useState(null);
  const [submittedData, setSubmittedData] = useState(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const [btn, setBtn] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const onSubmitToCart = (formData) => {
    // Update cartItems state with the new form data
    setCartItems([...cartItems, formData]);
    setIsFlipped((prev) => !prev);
  };
  const clearCart = () => {
    setCartItems([]);
    setOpenCart(false)
  };
  const handleFlipClick = () => {
    setIsFlipped((prev) => !prev);
  };
  const handleDrop = (e) => {
    e.preventDefault();

    if (selectedFieldType) {
      const newField = {
        id: new Date().getTime(),
        fieldType: selectedFieldType,
        title: `Field ${formFields.length + 1}`,
        value: "",
        fieldName: "",
        options: [""],
        minValue: "",
        maxValue: "",
        stepValue: "",
      };

      setFormFields([...formFields, newField]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const removeField = (id) => {
    const updatedFormFields = formFields.filter((field) => field.id !== id);
    setFormFields(updatedFormFields);
  };

  const updateField = (id, updatedField) => {
    const updatedFormFields = formFields.map((field) =>
      field.id === id ? { ...field, ...updatedField } : field
    );

    setFormFields(updatedFormFields);
  };
  const handleFinalSubmit = async () => {
    const transformedArray = cartItems.flatMap((entry) => {
      const formFields = entry.formFields;

      const formattedFields = formFields.map((field) => {
        let formattedField = {
          name: field.fieldName,
          field_type:
            field.fieldType === "text"
              ? "string"
              : field.fieldType === "number"
              ? "integer"
              : field.fieldType === "date"
              ? "datefield"
              : field.fieldType === "option"
              ? "choice"
              : field.fieldType,
          field_value: field.value,
          field_options: Array.isArray(field.options)
            ? field.options.filter((option) => option !== "") // Filter out blank options
            : [],
        };

        // Handle additional properties for range fields
        if (field.fieldType === "range") {
          formattedField.minValue = field.minValue;
          formattedField.maxValue = field.maxValue;
          formattedField.stepValue = field.stepValue;
        }

        return formattedField;
      });

      return formattedFields;
    });

    const requestData = {
      department: 15, // Assuming department ID is fixed
      name: formTitle, // You can set a specific name here
      fields: transformedArray,
    };
    // console.log(requestData)
try{
    // Send a POST request to the API endpoint with the formatted data
    const response = await  HttpService.post(CUSTOM_API, {}, requestData, false)
    setResponse(response);
    if (response.status === 201) {
      setErrorCount(prevCount => prevCount + 1);
      setError("Form Submitted"); // Update error state to indicate success
      setModal(false)
      
      return; // Exit early to prevent further execution
    }
  } catch (e) {

    let errorMessage = "An error occurred"; // Default error message

    if (e.response && e.response.data && e.response.data.message) {
      errorMessage = e.response.data.message;
    }

    setError(errorMessage);

    if (e.response && e.response.status !== 201) {
      setErrorCount(prevCount => prevCount + 1);
      // If there's a response indicating a server error (status other than 201), open the modal
      setModal(true);
    }
  }
};
  const renderFieldOptions = () => {
    return Object.keys(fieldComponents).map((fieldType) => (
      <div
        key={fieldType}
        className={styles["field-option"]}
        draggable
        onDragStart={(e) => {
          e.dataTransfer.setData("text/plain", ""); // Necessary for some browsers
          setSelectedFieldType(fieldType);
        }}
        onDragEnd={() => {
          setBtn(true);
          setSelectedFieldType(null);
        }}
        style={{ transform: "none" }}
      >
        <Label color="secondary" sx={{ width: "180px" }}>
          <ForwardedTooltip title="Drag Me" placement="right">
            {fieldType?.toUpperCase()}
          </ForwardedTooltip>
        </Label>
      </div>
    ));
  };

  const renderFormFields = () => {
    if (!isFlipped) {
      return formFields.map((field) => {
        const FieldComponent = fieldComponents[field.fieldType];

        return (
          <div key={field.id} className={styles["form-field"]}>
            <FieldComponent
              title={field.title}
              options={field.options}
              value={field.value}
              minValue={field.minValue}
              maxValue={field.maxValue}
              stepValue={field.stepValue}
              fieldName={field.fieldName}
              onFieldNameChange={(value) =>
                updateField(field.id, { fieldName: value })
              }
              onChange={(value) => updateField(field.id, { value })}
              onOptionChange={(index, e) =>
                updateField(field.id, {
                  options: [
                    ...field.options.slice(0, index),
                    e.target.value,
                    ...field.options.slice(index + 1),
                  ],
                })
              }
              onAddOption={() =>
                updateField(field.id, { options: [...field.options, ""] })
              }
              onRemoveOption={(index) =>
                updateField(field.id, {
                  options: [
                    ...field.options.slice(0, index),
                    ...field.options.slice(index + 1),
                  ],
                })
              }
              previewMode={isFlipped}
              handleMinChange={(value) =>
                updateField(field.id, { minValue: value })
              }
              handleMaxChange={(value) =>
                updateField(field.id, { maxValue: value })
              }
              handleStepChange={(value) =>
                updateField(field.id, { stepValue: value })
              }
            />
            <Button
              appearance="primary"
              color="red"
              size="sm"
              style={{ marginLeft: "1rem", width: "50px" }}
              onClick={() => removeField(field.id)}
            >
              <TrashIcon />
            </Button>
          </div>
        );
      });
    }

    return null;
  };

  const handleSubmit = () => {
    const formData = {
      formTitle,
      formFields,
    };
    setSubmittedData(formData);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          className={styles["input-component"]}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <Label sx={{ marginBottom: "1rem", fontSize: 16, color: "purple" }}>
            Field Options
          </Label>

          {renderFieldOptions()}
          <Button
            appearance="ghost"
            color="violet"
            size="sm"
            style={{ width: "180px", marginLeft: "1rem" }}
            onClick={handleFlipClick}
            disabled={submittedData === null}
          >
            {isFlipped ? "Exit Preview" : "Preview"}
          </Button>
        </div>
        <div
          className={`${styles["custom-test-battery"]} ${
            isFlipped ? styles["custom-test-battery-preview"] : ""
          }`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {isFlipped ? null : (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {btn && (
                <>
                  {cartItems?.length > 0 ? (
                    <Button
                      appearance="primary"
                      onClick={() => setOpenCart(true)}
                      style={{
                        backgroundColor: "inherit",
                        color: Color.Color_C,
                        position: "fixed",
                        top: "24px",
                        right: "10px",
                      }}
                    >
                      <Badge
                        badgeContent={cartItems.length}
                        overlap="circular"
                        color="secondary"
                      >
                        <ShoppingCartIcon />
                      </Badge>
                    </Button>
                  ) : (
                    <Button
                      disabled={cartItems?.length <= 0}
                      appearance="primary"
                      onClick={() => setOpenCart(true)}
                      style={{
                        backgroundColor: "inherit",
                        color: Color.Color_C,
                        position: "fixed",
                        top: "20px",
                        right: "10px",
                      }}
                    >
                      <ShoppingCartIcon />
                    </Button>
                  )}
                  <Label color="success" sx={{ mr: "1rem", width: "4rem" }}>
                    Form Title
                  </Label>
                  <input
                    type="text"
                    id="form_title"
                    value={formTitle}
                    style={{ width: "150px" }}
                    onChange={(e) => setFormTitle(e.target.value)}
                  />
                </>
              )}
              <Modal
              style={{marginTop:"1.6rem"}} 
                open={openCart}
                size={"md"}
                onClose={() => setOpenCart(false)}
              >
                <Modal.Header>
                  {" "}
                  <h6>Submitted Test Battery: Cart</h6>
                </Modal.Header>
                <Modal.Body>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {cartItems?.map((item, index) => {
                      return (
                        <div style={{ marginTop: "1rem" }} key={index}>
                          <Label sx={{ margin: "1rem" }} color="success">
                            Form Title: {item?.formTitle}
                          </Label>
                          {item?.formFields?.map((item, index) => {
                            return (
                              <div style={{ marginTop: "1rem" }} key={index}>
                                {item.fieldType === "text" ? (
                                  <div>
                                    <Label
                                      color="secondary"
                                      sx={{ width: "100px", mr: "1rem" }}
                                    >
                                      {item.fieldName}
                                    </Label>
                                    <input
                                      readOnly
                                      style={{ width: "150px" }}
                                      type="text"
                                      defaultValue={""}
                                    />
                                  </div>
                                ) : item.fieldType === "number" ? (
                                  <div>
                                    <Label
                                      color="secondary"
                                      sx={{ width: "100px", mr: "1rem" }}
                                    >
                                      {item.fieldName}
                                    </Label>
                                    <input
                                      defaultValue={""}
                                      readOnly
                                      style={{ width: "150px" }}
                                      type="number"
                                    />
                                  </div>
                                ) : item.fieldType === "date" ? (
                                  <div>
                                    <Label
                                      color="secondary"
                                      sx={{ width: "100px", mr: "1rem" }}
                                    >
                                      {item.fieldName}
                                    </Label>
                                    <input
                                      defaultValue={""}
                                      readOnly
                                      style={{ width: "150px", height: "22px" }}
                                      type="date"
                                    />
                                  </div>
                                ) : item.fieldType === "range" ? (
                                  <div>
                                    <Label
                                      color="secondary"
                                      sx={{ width: "100px", mr: "1rem" }}
                                    >
                                      {item.fieldName}
                                    </Label>
                                    <input
                                      style={{ width: "150px" }}
                                      readOnly
                                      defaultValue={""}
                                      type="range"
                                      max={item.maxValue}
                                      min={item.minValue}
                                      step={item.stepValue}
                                    />
                                  </div>
                                ) : item.fieldType === "option" ? (
                                  <div>
                                    <Label
                                      color="secondary"
                                      sx={{ width: "100px", mr: "1rem" }}
                                    >
                                      {item.fieldName}
                                    </Label>
                                    <select
                                      style={{ width: "160px", height: "30px" }}
                                      defaultValue={item.value || ""}
                                    >
                                      <option
                                        defaultValue={""}
                                        value=""
                                        disabled
                                        hidden
                                      >
                                        Select an option
                                      </option>
                                      {item.options.map(
                                        (option, optionIndex) => (
                                          <option
                                            key={optionIndex}
                                            value={option}
                                          >
                                            {option}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>

                  <Button
                    appearance="primary"
                    onClick={clearCart}
                    style={{
                      marginTop: "1rem",
                      marginRight: "2rem",
                      backgroundColor: Color.Color_C,width:150
                    }}
                  >
                    Clear Cart
                  </Button>
                  <Button
                    disabled={cartItems?.length <= 0}
                    appearance="primary"
                    onClick={handleFinalSubmit}
                    style={{
                      marginTop: "1rem",
                      backgroundColor: Color.Color_C, width:150
                    }}
                  >
                    Submit
                  </Button>
                </Modal.Body>
              </Modal>
              <CustomNotification
              errorCount={errorCount} 
              message={
                error ? error : (res && res.data && res.data.message) || ""
              }
              title={error === "Form Submitted" ? "Success" : "Error"}
            />
            </div>
          )}

          <div className={styles["form-fields-container"]}>
            {!isFlipped ? (
              <div>
                {renderFormFields()}
                {!isFlipped && btn && (
                  <Button
                    appearance="ghost"
                    color="violet"
                    size="sm"
                    style={{ margin: "0.5rem" }}
                    onClick={handleSubmit}
                    disabled={
                      formTitle?.trim().length <= 0 ||
                      formFields.some(
                        (field) => field.fieldName.trim().length <= 0
                      )
                    }
                  >
                    Submit
                  </Button>
                )}
              </div>
            ) : (
              <SubmittedTestBattery
                onSubmit={onSubmitToCart}
                formData={submittedData}
              />
            )}
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default CustomTestBattery;
