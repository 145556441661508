import React, { useState,useContext } from "react";
import { Button } from "rsuite"; // Import SelectPicker
import { HttpService } from "../services/http.service";
import {
  EVALUATION_RESULTS,
  
} from "../services/API";
import { Card, CardContent, Typography } from "@mui/material";
import Chart from "chart.js/auto";
import { Scatter } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Papa from "papaparse";
import { AuthContext } from "../store/AuthContext";
import jwt_decode from "jwt-decode";
import { CascadingDropDownBivariateAthlete } from "./CascadingDropDownAthlete";
Chart.register(CategoryScale);

export default function BivariateAthlete() {
  const [results, setResult] = useState([]);
  // const [filterResults, setFilterResults] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAthlete, setSelectedAthlete] = useState(null); // Add state for selected athlete
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [athletes, setAthletes] = useState(); // State to store athlete options
  // const [showGenerateButton, setShowGenerateButton] = useState(true);
  const [filter, setFilter] = useState(0);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFieldsForFirstDropdown, setAvailableFieldsForFirstDropdown] =
    useState([]);
  const [
    availableFieldsForSecondDropdown,
    setAvailableFieldsForSecondDropdown,
  ] = useState([]);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const athleteId = decode.athlete_id;
  const fetchResult = async () => {
    try {
      const response = await HttpService.get(
        `${EVALUATION_RESULTS}?department=${selectedDepartment}&categories=${selectedCategory}&athlete=${athleteId}`,{},false
      );
  
      const modifiedResult = response.data.results.map((result, index) => ({
        id: index,
        athlete_id: athleteId, // Use the athleteId directly
        value:
          result.boolean_value !== false
            ? result.boolean_value
            : result.choice_value !== null
            ? result.choice_value
            : result.date_value !== null
            ? result.date_value
            : result.float_value !== null
            ? result.float_value
            : result.integer_value !== null
            ? result.integer_value
            : result.remarks !== null
            ? result.remarks
            : result.string_value !== null
            ? result.string_value
            : null,
        date: result.created_at.slice(0, 10),
        field_name: result.field_name,
        field_id: result.field,
      }));
  
      setResult(modifiedResult);
      // setShowGenerateButton(false);
      setFilter(1);
    } catch (error) {
      console.warn(error);
    }
  };

  const customColors = [
    "rgba(75, 192, 192, 0.6)",
    "rgba(255, 99, 132, 0.6)",
    "rgba(255, 159, 64, 0.6)",
    "rgba(255, 205, 86, 0.6)",
    "rgba(54, 162, 235, 0.6)",
    "rgba(153, 102, 255, 0.6)",
    "rgba(201, 203, 207, 0.6)",
  ];

 

  const resetSelection = () => {
    setSelectedDepartment(null);
    setSelectedCategory(null);
    setSelectedAthlete(null);
    setResult([]);
    // setFilterResults([]);
    // setShowGenerateButton(true);
    setFilter(0);
  };

  function filterScatterData(data, selectedFields) {
    // Initialize an empty array for datasets
    const datasets = [];

    // Filter the data based on selected field_ids
    const filteredData = data.filter((result) =>
      selectedFields.includes(result.field_id)
    );

    // Create a map to store data for each field_id
    const fieldDataMap = new Map();

    // Populate the map with data for each field_id
    filteredData.forEach((result) => {
      const { field_id, field_name, value } = result;
      if (!fieldDataMap.has(field_id)) {
        fieldDataMap.set(field_id, { label: field_name, data: [] });
      }
      fieldDataMap.get(field_id).data.push(value);
    });

    // Check if both X and Y fields are selected
    if (selectedFields.length === 2) {
      const [xField, yField] = selectedFields;
      const xData = fieldDataMap.get(xField).data;
      const yData = fieldDataMap.get(yField).data;

      const backgroundColor =
        customColors[datasets.length % customColors.length];

      const scatterData = xData.map((xValue, index) => ({
        x: xValue, // X-axis value (e.g., value from Field 1)
        y: yData[index], // Y-axis value (e.g., value from Field 2)
      }));

      datasets.push({
        label: `${fieldDataMap.get(xField).label} vs ${
          fieldDataMap.get(yField).label
        }`,
        data: scatterData,
        backgroundColor,
      });
    }

    return datasets;
  }
  const columns = [
    
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "field_name",
      headerName: "Test Name",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      headerName: "Result",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
  ];
  function handleExportCSV(data, title) {
    const csvData = Papa.unparse(data); // Convert data to CSV format using PapaParse
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${title}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  }
  return (
    <div>
         <Typography variant="h6" textAlign={"center"}>
         Bivariate Analytics
          </Typography>
          
      <div>
        <CascadingDropDownBivariateAthlete
          selectedDepartment={selectedDepartment}
          setSelectedDepartment={setSelectedDepartment}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          departments={departments}
          setDepartments={setDepartments}
          categories={categories}
          setCategories={setCategories}
          athletes={athletes}
          setAthletes={setAthletes}
          results={results}
          selectedAthlete={selectedAthlete}
          setSelectedAthlete={setSelectedAthlete}
          subCategories={subCategories}
          setSubCategories={setSubCategories}
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
          availableFieldsForFirstDropdown={availableFieldsForFirstDropdown}
          setAvailableFieldsForFirstDropdown={
            setAvailableFieldsForFirstDropdown
          }
          availableFieldsForSecondDropdown={availableFieldsForSecondDropdown}
          setAvailableFieldsForSecondDropdown={
            setAvailableFieldsForSecondDropdown
          }
        />
      </div>
      {selectedCategory === null ? null : (
        <>
          
            <Button
              style={{ marginLeft: "2rem", marginTop: "1rem" }}
              appearance="primary"
              color="green"
              onClick={() => fetchResult()}
              disabled={selectedFields[1] === undefined}
            >
              Generate Results
            </Button>
          
            <Button
              style={{ marginLeft: "2rem", marginTop: "1rem" }}
              appearance="primary"
              color="yellow"
              onClick={() => resetSelection()}
            >
              Reset Results
            </Button>
         
        </>
      )}

      
      {filter === 1 ? (
        <>
          {results?.length === 0 ? (
            <Typography variant="h6" textAlign={"center"}>
              No Data
            </Typography> // Use results.length to check if there are no results
          ) : (
            <div
              style={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              <Container
                maxWidth="lg"
                style={{ marginTop: "4rem", marginBottom: "2rem" }}
              >
                <Box key={Math.random().toLocaleString()}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={results}
                    columns={columns}
                    pagination
                    paginationMode="client"
                    pageSize={10}
                  />
                </Box>
                <Button
                  style={{ marginTop: "2rem" }}
                  color="cyan"
                  appearance="primary"
                  onClick={() => handleExportCSV(results, "Results")}
                >
                  Export Data
                </Button>
              </Container>
              <Card
                sx={{
                  width: "40rem",
                  height: "20rem",
                  marginBottom: "2rem",
                  margin: "1rem",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Scatter
                    data={{
                      datasets: filterScatterData(results, selectedFields),
                    }}
                    options={{
                      scales: {
                        x: {
                          title: {
                            display: true,
                            text:
                              selectedFields.length > 0
                                ? results.find(
                                    (result) =>
                                      result.field_id === selectedFields[0]
                                  )?.field_name
                                : "X-Axis Label",
                          },
                        },
                        y: {
                          title: {
                            display: true,
                            text:
                              selectedFields.length > 1
                                ? results.find(
                                    (result) =>
                                      result.field_id === selectedFields[1]
                                  )?.field_name
                                : "Y-Axis Label",
                          },
                        },
                      },
                      plugins: {
                        title: {
                          display: true,
                          text:
                            selectedFields.length === 2
                              ? `${
                                  results.find(
                                    (result) =>
                                      result.field_id === selectedFields[0]
                                  )?.field_name
                                } vs ${
                                  results.find(
                                    (result) =>
                                      result.field_id === selectedFields[1]
                                  )?.field_name
                                }`
                              : "Scatter Plot Title",
                        },
                        legend: {
                          display: true,
                          position: "bottom",
                        },
                      },
                    }}
                  />
                </CardContent>
              </Card>
            </div>
          )}
        </>
      )  : null}
    </div>
  );
}
