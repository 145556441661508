import React from "react";
import { useNavigate } from "react-router";
import styles from "./landing.module.css";
import { Button } from "rsuite";
import { Color } from "../../utils/Colors";

export default function Header() {
  const navigate = useNavigate();
  return (
    

      <div className={styles.titleContainer}>
        <h2 className={styles.title}>
          Analyze Sports and Rehab Science Data <br />
          <span className={styles.titleLineBreak}>with Confidence</span>
        </h2>
        <p className={styles.textmuted}>
          The Sports Science and Rehab Analytics platform is a remote user
          research tool that utilizes athlete data to derive decisions
        </p>
        <p className={styles.textmuted}>aimed at
          enhancing performance and minimizing injuries.
        </p>
       
        <Button  onClick={() => navigate("/login")} appearance="primary" className={styles.btn} style={{backgroundColor:Color.Color_C}}>Sign in →</Button>
      </div>
   
  );
}
