import React, { useState, useEffect } from "react";
import { FetchResultsByFieldAthlete } from "../../components/custmomhooks/CustomHooks";
import CustomBarChart from "./BarChart";
import styles from "./injury.module.css";
import { SelectPicker } from "rsuite";
import { ATHLETE_PROFILE_API_URL } from "../../services/API";
import { HttpService } from "../../services/http.service";
import Label from "../../components/Label";
import { Typography } from "@mui/material";
import NODATA from "./../../assets/image/noChart.svg";
import NOTSELECTED from "./../../assets/image/notSelected.jpg";

export default function AnthropometryProfiling() {
  const [athlete, setAthlete] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [weight, setWeight] = useState([]);
  const [height, setHeight] = useState([]);
  const [somatotype, setSomatotype] = useState([]);
  const [endomorphy, setEndomorphy] = useState([])
  const [mesomorphy, setMesomorphy] = useState([])
  const [ectomorphy, setEctomorphy] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [dataResults, setDataResults] = useState({
    bmi: { data: [], title: "Body Mass Index" },
    lbm: { data: [], title: "Lean Body Mass" },
    skeletalMass: { data: [], title: "Skeletal Mass" },
    boneMass: { data: [], title: "Bone Mass" },
    subCutaneous: { data: [], title: "Subcutaneous Fat" },
    visceralFat: { data: [], title: "Visceral Fat" },
    totalbodyfat: { data: [], title: "Total Body Fat" },
    hydration: { data: [], title: "Hydration" },
    abdominalHorizontal: { data: [], title: "Abdominal Horizontal Skinfold" },
    medialCalf: { data: [], title: "Medial Calf Skinfold" },
    tricep: { data: [], title: "Tricep Skinfold" },
    biceps: { data: [], title: "Biceps Skinfold" },
    pectorals: { data: [], title: "Pectoral Skoinfold" },
    subscapular: { data: [], title: "Subscapular Skinfold" },
    supraspinale: { data: [], title: "Supraspinale Skinfold" },
    abdVertical: { data: [], title: "Abdominal Vertical Skinfold" },
    iliacCrest: { data: [], title: "Iliac Crest Skinfold" },
    femurBreadth: { data: [], title: "Femur Breadth" },
    humerusBreadth: { data: [], title: "Humerus Breadth" },
    armGirth: { data: [], title: "Arm Girth" },
    calfGirth: { data: [], title: "Standing Calf Girth" },
    armSpan: { data: [], title: "Arm Span" },
    sittingHeight: { data: [], title: "Sitting Height" },
    palmWidth: { data: [], title: "Palm Width" },
    waistCircumference: { data: [], title: "Waist Circumference" },

  });


  useEffect(() => {

    const fetchResults = async () => {
      try {
        setLoading(true);
        const fetchPromises = [
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 75,
            field: 393,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 75,
            field: 394,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 75,
            field: 395,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 75,
            field: 396,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 75,
            field: 397,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 75,
            field: 398,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 75,
            field: 399,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 75,
            field: 400,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 379,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 380,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 373,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 374,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 375,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 376,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 377,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 378,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 381,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 383,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 382,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 384,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 385,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 386,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 387,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 388,
            athlete,
          }),
          FetchResultsByFieldAthlete({
            dept: 5,
            cat: 73,
            field: 389,
            athlete,
          }),

        ]
        const results = await Promise.all(fetchPromises);

        setDataResults((prevData) => ({
          ...prevData,
          bmi: { data: results[0], title: "Body Mass Index" },
          lbm: { data: results[1], title: "Lean Body Mass" },
          skeletalMass: { data: results[2], title: "Skeletal Mass" },
          boneMass: { data: results[3], title: "Bone Mass" },
          subCutaneous: { data: results[4], title: "Subcutaneous Fat" },
          visceralFat: { data: results[5], title: "Visceral Fat" },
          totalbodyfat: { data: results[6], title: "Total Body Fat" },
          hydration: { data: results[7], title: "Hydration" },
          abdominalHorizontal: { data: results[8], title: "Abdominal Horizontal Skinfold" },
          medialCalf: { data: results[9], title: "Medial Calf Skinfold" },
          tricep: { data: results[10], title: "Tricep Skinfold" },
          biceps: { data: results[11], title: "Biceps Skinfold" },
          pectorals: { data: results[12], title: "Pectoral Skoinfold" },
          subscapular: { data: results[13], title: "Subscapular Skinfold" },
          supraspinale: { data: results[14], title: "Supraspinale Skinfold" },
          abdVertical: { data: results[15], title: "Abdominal Vertical Skinfold" },
          iliacCrest: { data: results[16], title: "Iliac Crest Skinfold" },
          femurBreadth: { data: results[17], title: "Femur Breadth" },
          humerusBreadth: { data: results[18], title: "Humerus Breadth" },
          armGirth: { data:results[19], title: "Arm Girth" },
          calfGirth: { data: results[20], title: "Standing Calf Girth" },
          armSpan: { data: results[21], title: "Arm Span" },
          sittingHeight: { data: results[22], title: "Sitting Height" },
          palmWidth: { data: results[23], title: "Palm Width" },
          waistCircumference: { data: results[24], title: "Waist Circumference" },

        }));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (athlete) {
      fetchResults();
    }
  }, [athlete]);

  useEffect(() => {
    const fetchAthlete = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`, {}, false
        );
        const modifiedAthlete = response.data.results.map((ath) => ({
          value: ath.id,
          label: ath.name,
        }));
        setAthletes(modifiedAthlete);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthlete();
  }, [currentPage]);

  useEffect(() => {
    const Somatotype = async () => {

      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL + athlete}/`, {}, false
        );

        if (!response.data) {
          console.error("Results array is undefined.");
          return;
        }

        if (response.data.length === 0) {
          console.warn("Results array is empty.");
          return;
        }
        setWeight(response.data.weight);
        setHeight(response.data.height);

        const skinfold = dataResults.tricep.data[0].value + dataResults.subscapular.data[0].value + dataResults.supraspinale.data[0].value;

        const endo =
          -0.7182 +
          0.1451 * skinfold -
          0.00068 * Math.pow(skinfold, 2) +
          0.0000014 * Math.pow(skinfold, 3);

        const CAG = dataResults.armGirth.data[0].value - dataResults.tricep.data[0].value;
        const CCG = dataResults.calfGirth.data[0].value - dataResults.medialCalf.data[0].value;
        const meso =
          (0.858 * dataResults.humerusBreadth.data[0].value) +
          (0.601 * dataResults.femurBreadth.data[0].value) +
          (0.188 * CAG) +
          (0.161 * CCG) -
          (0.131 * height) +
          4.5;
        const W = weight;
        let PI = height / Math.pow(W, 1 / 3);
        let ecto = 0;

        if (PI > 40.74) {
          ecto = 0.732 * PI - 28.58;
        } else if (PI > 39.65 && PI <= 40.74) {
          ecto = 0.463 * PI - 17.615;
        } else if (PI <= 39.65) {
          ecto = 0.5;
        }
        let result = "";
        if (endo >= meso && endo >= ecto) {
          result += "Endomorphic ";
          if (meso >= ecto) {
            result += "Mesomorph";
          } else {
            result += "Ectomorph";
          }
        } else if (meso >= endo && meso >= ecto) {
          result += "Mesomorphic ";
          if (endo >= ecto) {
            result += "Endomorph";
          } else {
            result += "Ectomorph";
          }
        } else if (ecto >= endo && ecto >= meso) {
          result += "Ectomorphic ";
          if (endo >= meso) {
            result += "Endomorph";
          } else {
            result += "Mesomorph";
          }
        }
        setEndomorphy(endo.toFixed(2))
        setMesomorphy(meso.toFixed(2))
        setEctomorphy(ecto.toFixed(2))
        setSomatotype(result);

      } catch (error) {
        console.warn(error);
      }
    };
    if (athlete!==null) {

      Somatotype();
    }
  }, [
    athlete,
    athletes,
    weight,
    height,
    dataResults.tricep,
    dataResults.supraspinale,
    dataResults.subscapular,
    dataResults.armGirth,
    dataResults.calfGirth,
    dataResults.humerusBreadth,
    dataResults.femurBreadth, dataResults.medialCalf
  ]);

  const SomatotypeRender = () => {
    return (
      <div>
        {dataResults.calfGirth?.length <= 0 ? (
          null
        ) : (
          <div className={styles.labelsummary}>
            <Label color="success">Somatotype</Label>
            <div className={styles.labelinnersum}>
              <Label sx={{ m: "1px" }} color="error">Endmorphy: {endomorphy}</Label>
              <br />
              <Label sx={{ m: "1px" }} color="secondary">Mesomorphy: {mesomorphy}</Label>
              <br />
              <Label sx={{ m: "1px" }} color="success">Ectomorphy: {ectomorphy}</Label>
              <br />
              <Label sx={{ m: "1px" }} color="warning">Somatotype: {somatotype}</Label>
              <br />
            </div>
            <br />



          </div>
        )}

      </div>
    )
  }
  return (
    <div>
      <div className={styles.belownav}>
        <SelectPicker
          label={"Select Athlete" }
          data={athletes}
          onSelect={(value) => setAthlete(value)}
          className={styles.selectpicker}
          onClean={() => {
            setAthlete([]);
          }}
        />
      </div>
      {loading === true ? (
        <div
          style={{
            marginTop: "100px",
            marginLeft: "240px",
            transition: "margin-left 0.3s",
          }}
        >
          <Typography variant="h4" color="darkviolet" textAlign="center">
            {athlete===null? "Select Athlete" : "Loading...."}
          </Typography>
        </div>
      ) : (
        <div className={styles.box_science_sports7}>
          <div className={styles.chartcontent}>
            <div className={styles.chartwrapper}>
              {Object.entries(dataResults).map(([key, data]) => (
                <div className={styles.chartitem} key={key}>
                  <div className={styles.chartbox}>
                    {data?.data?.length <= 0 ? (
                      <div
                        style={{
                          width: "250px",
                          height: "250px",
                          margin: "0 auto",
                        }}
                      >

                        {athlete!==null ? (
                          <>
                            <img src={NODATA} alt="No data" />
                            <h6 style={{ textAlign: "center" }}>
                              No Records Available:{data?.title}
                            </h6>
                          </>
                        ) : (
                          <>
                            <h6> Athlete Not Selected</h6>
                            <img
                              src={NOTSELECTED}
                              style={{ width: "200px", height: "200px" }}
                              alt="No data"
                            />
                          </>
                        )}
                      </div>
                    ) : (
                      <div>


                        <CustomBarChart
                          data={data?.data}
                          title={data?.title}
                        />

                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <SomatotypeRender />
          </div>

        </div>
      )}
    </div>
  );
}