import React, { useEffect, useContext } from "react";
import { Divider, Loader } from "rsuite";
import { Avatar, CardContent, Tooltip } from "@mui/material";
import Ava from "./../../assets/image/avatar1.jpg";
import Ava1 from "./../../assets/image/avatar2.jpg";
import Ava2 from "./../../assets/image/avatar3.jpg";
import Ava4 from "./../../assets/image/avatar4.jpg";
import Ava5 from "./../../assets/image/avatar5.jpg";
import Ava6 from "./../../assets/image/avatar6.jpg";
import Label from "../../components/Label";
import { NOTIFICATION_RECIPENTS, USER_API_URL } from "../../services/API";
import { HttpService } from "../../services/http.service";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../store/AuthContext";

export default function Notification({ count, websocketData }) {
  const [notification, setNotification] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [clickedIndex, setClickedIndex] = React.useState(-1); // State to store the index of the clicked notification

  const [isRead, setIsRead] = React.useState(false);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const decode = token ? jwt_decode(token) : "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const notificationResponse = await HttpService.get(
          `${NOTIFICATION_RECIPENTS}user-notifications/?recipient_id=${
            decode.user_id
          }&is_read=${isRead === false ? "False" : "True"}&is_delivered=True`,
          {},
          false
        );

        const notifications = notificationResponse.data;

        // Fetch user data for each notification
        const notificationsWithUsers = await Promise.all(
          notifications.map(async (notification) => {
            // Fetch user data for the creator of this notification
            const userResponse = await HttpService.get(
              `${USER_API_URL}${notification.created_by}`,
              {},
              true
            );
            const creator =
              userResponse.data.first_name + " " + userResponse.data.last_name;
            const photo = userResponse.data.profile_photo_url;

            // Merge user data into notification object
            return { ...notification, creator, photo };
          })
        );

        notificationsWithUsers.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        // Update state with sorted notifications containing user data
        setNotification(notificationsWithUsers);
        setIsLoading(false);
      } catch (error) {
        console.warn(error);
      }
    };
    const fetchDataWithoutCreatedBy = async () => {
      try {
        // Fetch notification data
        setIsLoading(true);
        const notificationResponse = await HttpService.get(
          `${NOTIFICATION_RECIPENTS}user-notifications/?recipient_id=${decode.user_id}`,
          {},
          false
        );
        const notifications = notificationResponse.data;

        // Update state with sorted notifications containing user data
        setNotification(
          notifications.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )
        );
        setIsLoading(false);
      } catch (error) {
        console.warn(error);
      }
    };

    decode?.roles?.includes("Athlete")
      ? fetchDataWithoutCreatedBy()
      : fetchData();
  }, [setNotification, decode.roles, count, decode.user_id,isRead]);

  // console.log(isLoading);
  const avatars = [Ava, Ava1, Ava2, Ava4, Ava5, Ava6];
  const avatarMap = {};
  const colors = ["pink", "lightgreen"]; // Define colors array
  const handleClick = (index) => {
    // Toggle clickedIndex state to change background color
    setIsRead(true);
    setClickedIndex(index === clickedIndex ? -1 : index);
  };

  return (
    <div>
      <div
        style={{
          marginTop: "2rem",
        }}
      >
        {websocketData && (
          <div>
            {websocketData.message && (
              <div>
                {/* Parse the JSON string to access its properties */}
                {websocketData.message && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Label color="success">New</Label>
                    <Label color="secondary">
                      {JSON.parse(websocketData.message).created?.slice(0, 11)}
                    </Label>
                    <Label color="info">
                      {JSON.parse(websocketData.message).notification_content}
                    </Label>
                  </div>
                )}
                <Divider />
              </div>
            )}
          </div>
        )}
      </div>
      {isLoading === true ? (
        <Loader />
      ) : (
        <div>
          {notification?.length <= 0 ? (
            <label style={{ marginLeft: "5px", fontWeight: "bolder" }}>
              No Data
            </label>
          ) : (
            <CardContent>
              {notification?.map((notify, index) => {
                let avatarSrc = avatarMap[notify.creator];
                if (!avatarSrc) {
                  avatarSrc =
                    avatars[Math.floor(Math.random() * avatars.length)];
                  avatarMap[notify.creator] = avatarSrc;
                }
                return (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",

                        // backgroundColor: clickedIndex === index ? colors[1] : colors[0],
                      }}
                      onClick={() => handleClick(index)}
                    >
                      {decode?.roles?.includes("Athlete") ? (
                        <Avatar src={avatarSrc} />
                      ) : (
                        <Tooltip
                          title={`Sent By - ${notify.creator}`}
                          placement="top"
                        >
                          <Avatar src={avatarSrc} />
                        </Tooltip>
                      )}

                      <Label color="secondary">
                        {notify.created_at?.slice(0, 10)}
                      </Label>
                      <Label>{notify.content}</Label>
                    </div>
                    <Divider />
                  </div>
                );
              })}
            </CardContent>
          )}
        </div>
      )}
    </div>
  );
}
