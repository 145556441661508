import React, { useEffect,useContext } from "react";
import "../../components/landing/styles.css";
import {
  Form,
  Button,
  SelectPicker,
  FlexboxGrid,
  Placeholder,
} from "rsuite";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import { HttpService } from "../../services/http.service";
import {
  USER_API_URL,
  ATTENDANCE_RECORDS_API_URLS,
} from "../../services/API";
import CustomNotification from "../../components/CustomNotification";
import { Color } from "../../utils/Colors";
// const TextField = React.forwardRef((props, ref) => {
//   const { name, label, accepter, ...rest } = props;
//   return (
//     <Form.Group controlId={`${name}-4`} ref={ref}>
//       <Form.ControlLabel>{label} </Form.ControlLabel>
//       <Form.Control name={name} accepter={accepter} {...rest} />
//     </Form.Group>
//   );
// });

export default function AddAttendance({ onSubmitSuccess,session_id ,setCount}) {
  // const [teams, setTeams] = React.useState();
  // const [team,setTeam]= React.useState()
  const [users, setUser] = React.useState();
  const [user, setUsers] = React.useState();
  // const [sessions, setSessions] = React.useState();
  // const [session, setSession] = React.useState();
  // const [record, setRecord] = React.useState();
  const [errorCount, setErrorCount] = React.useState(0);
  const [status, setStatus] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
 

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const data = ["Present", "Absent"].map((item) => ({
    label: item,
    value: item,
  }));
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await HttpService.get(
          `${USER_API_URL}?page=${currentPage}`,{}, true
        );

        const modifiedUsers = response.data.results.map((user) => ({
          value: user.id,
          label: user.first_name +" " + user.last_name,
        }));

        setUser(modifiedUsers);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchUser();
  }, [currentPage]);

 

  const handleSubmit = async () => {
    try {
      const formData = {
        status: status === "Present" ? "present" : "absent",
        user,
        session:session_id,
        recorded_by: decode.user_id,
      };
      const response = await HttpService.post(ATTENDANCE_RECORDS_API_URLS, {}, formData,false);

      setResponse(response);

      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setOpenModal(true);
        setError("Form Submitted");
        onSubmitSuccess();
      }
    }  catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        {users === undefined ? (
          <Placeholder.Paragraph style={{ marginTop: 30 }} />
        ) : (
          <Form>
            <SelectPicker
              label="Select Status"
              data={data}
              onSelect={(value) => setStatus(value)}
              style={{ width: 224 }}
            />
            <br />
            <br />
            <SelectPicker
              label="Select User"
              data={users}
              onSelect={(value) => setUsers(value)}
              style={{ width: 224 }}
            />
            <br />
            <br />
           
            
            <Button appearance="primary" onClick={handleSubmit} style={{background:Color.Color_C,color:Color.Color_A,width:300}}>
              Submit
            </Button>
            <CustomNotification
                errorCount={errorCount}
                open={openModal}
                setOpen={setOpenModal}
                message={
                  error ? error : (res && res.data && res.data.message) || ""
                }
                title={error === "Form Submitted" ? "Success" : "Error"}/>
          </Form>
        )}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
