import React,{useEffect} from "react";
import { HttpService } from "../../services/http.service";
import { Button,  Loader, Modal } from "rsuite";
import UserCustomDrawer from "../../components/CustomDrawer";
// import CustomSideNavbar from "../../components/CustomSideNav";
// import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
import CustomNotification from "../../components/CustomNotification";
// import { GetNutritionLogsData } from "../../components/custmomhooks/CustomHooks";
import { Container, Typography } from "@mui/material";
import { NUTRITION_LOGS_API_URLS,ATHLETE_PROFILE_API_URL,USER_API_URL } from "../../services/API";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; 
import timeGridPlugin from '@fullcalendar/timegrid'
export default function ViewNutritionLogs() {
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  // const [isSidebarExpanded, setIsSidebarExpanded] = React.useState(false);
  const [nutritionLogs, setNutritionLogs] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  // const [totalPages, setTotalPages] = React.useState(1); // Total number of pages
  const [date, setDate] = React.useState()
  const [selectedEventInfo, setSelectedEventInfo] = React.useState(null);
  const [openModal2, setOpenModal2] = React.useState(false);
  const [count,setCount] = React.useState(0)
  const handleOpenModal = (eventInfo) => {
    setSelectedEventInfo(eventInfo);
    setOpenModal2(true);
  };

  const handleCloseModal = () => {
    setSelectedEventInfo(null);
    setOpenModal2(false);
  };
  // const handleSidebarToggle = () => {
  //   setIsSidebarExpanded(!isSidebarExpanded);
  // };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${NUTRITION_LOGS_API_URLS}?page=${currentPage}`,{}, false
        );
        // console.log(response.data);
        const modifiedResult = await Promise.allSettled(
          response.data.results.map(async (result) => {
            try {
              // Fetch athlete data for each result
              const athleteResponse = await HttpService.get(
                ATHLETE_PROFILE_API_URL + result.athlete + "/",{}, false
              );
              const athlete_name = athleteResponse.data.name;
              const recordResponse = await HttpService.get(
                USER_API_URL + result.recorded_by + "/",{}, true
              );
              const record_name = recordResponse.data.first_name;
              // console.log(sessionResponse.data.name);
              return {
                id: result.id,
                athlete_name: athlete_name,
                record: record_name,

                ...result,
              };
            } catch (error) {
              // Handle individual request error
              console.error(
                `Error fetching data for result ${result.id}:`,
                error
              );
              return null; // or handle the error in an appropriate way
            }
          })
        );

        // Extracting successful results and maintaining the order
        const filteredResults = modifiedResult
          .filter(
            (result) => result.status === "fulfilled" && result.value !== null
          )
          .map((result) => result.value);

          setNutritionLogs(filteredResults);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        // setTotalPages(Math.ceil(response.data.count / 10));
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [currentPage,count]);
  

  // console.log(error)
  const handleOpen = (date, key) => {
    setDate(date)
    setOpen(true);
    setPlacement(key);
  };
  

  return (
    <>
     
     
      <Container maxWidth="lg" >
       
           
          {nutritionLogs === undefined ? (
            <>
            <Loader content={"Loading"} />
            {error?.response?.status === 200 ? null : (
              <CustomNotification
                open={openModal}
                setOpen={setOpenModal}
                message={
                  error?.response?.status === 200 ? "Loading" : error?.message
                }
                title={error?.response?.status === 200 ? "Loading" : "Error"}
              />
            )}
          </>
          ) :  <FullCalendar

          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          // headerToolbar={{
          //   left: 'prev,next today',
          //   center: 'title',
          //   right: 'dayGridMonth,timeGridWeek,timeGridDay'
          // }}
          events={nutritionLogs?.map((event) => ({
            meal_type: event.meal_type,
            start: new Date(event.date),
            calories_consumed: event.calories_consumed,
            protein: event.protein,
            carbohydrates: event.carbohydrates,
            fat: event.fat,
             athlete: event.athlete_name,
            recordedBy: event.record,
            id: event.id,
          }))}
          eventContent={(eventInfo) => {
            return (
              <div>
                <p>
                  <Typography variant="body" fontWeight={"bold"}>
                    {eventInfo.event.extendedProps?.athlete}
                  </Typography>{" "}
                </p>
                <p>

                </p>
              </div>
            );
          }}
          eventClick={(info) => handleOpenModal(info)}
          dateClick={(arg) => handleOpen(arg.date, "right")}
          height="600px"
        />}
       
      </Container>

      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={placement}
        mode={"AddNutrtion"}
        date={date}
        setCount={setCount}
      />
       <Modal size="lg" open={openModal2} onClose={handleCloseModal}  >
        {selectedEventInfo && (
          <>
            <h4>Nutrition Value Details: {selectedEventInfo.event.extendedProps?.athlete} </h4>

            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Meal Type:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.meal_type}</td>
                </tr>


              
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Calories Consumed:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.calories_consumed}</td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Carbohdrates:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.carbohydrates}</td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Fats:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.fat}</td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Protein:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.protein}</td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Recorded By:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.recordedBy}</td>
                </tr>

              </tbody>
            </table>
          </>
        )}
        <br />
        <Modal.Footer>
          <Button onClick={handleCloseModal} appearance="primary">
            Ok
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}
