import React, { useState, useEffect, useContext } from "react";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import Sidebar from "../../components/CustomSideNav";
import { HttpService } from "../../services/http.service";
import { EVALUATION_DEPARTMENTS } from "../../services/API";
import { useNavigate } from "react-router-dom";
import styles from "./science.module.css";
import SportMedicine from "./SportMedicine";
import Physiotherapy from "./Physiotherapy";
import StrengthConditioning from "./StrengthConditioning";
import SportsPsychology from "./SportsPsychology";
import SportsNutrition from "./SportsNutrition";
import Anthropometry from "./Anthropometry";
import ExercisePhysiology from "./ExercisePhysiology";
import Biomechanics from "./Biomechanics";
import LoadMonitoring from "./LoadMonitoring";
import TalentIdentification from "./TalentIdentification";
import GeneralFitness from "./GeneralFitness";
import SportSpecific from "./SportSpecific";
import CentralInjury from "./CentralInjury";
import EvaluationFormView from "../../components/EvaluationFormView";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
// import { Tooltip } from "@mui/material";
// import PlusIcon from "@rsuite/icons/Plus";
import CustomTestBattery from "./CustomTestBattery";
import { Button, Modal } from "rsuite";
// import { Color } from "../../utils/Colors";
import MyCustomBattery from "./MyCustomBattery";
export default function SportScience() {
  const navigate = useNavigate();
  const [res, setRes] = useState();
  const [open, setOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("Upload Documents");
  const [selectedItem, setSelectedItem] = useState("Upload Documents");
  const [modal, setModal] = useState(false);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          EVALUATION_DEPARTMENTS,
          {},
          false
        );
        setRes(response.data.results);
      } catch (error) {
        // console.error("Error fetching fields:", error);
      }
    };

    fetchData();
  }, []);

  const handleOpen = ({ name, key }) => {
    res === undefined
      ? navigate("/error", {
          state: {
            link: "/sportscience",
            tabname: "Try Again",
            message: "Something Went Wrong...!!",
            description: "We are fixing, please try again...!!!",
          },
        })
      : setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
    setSelectedItem(name);
  };

  let role = decode.roles;
  const roleActions = {
    Admin: [
      "Upload Documents",
      "My Test Battery",
      "Monitoring",
      "Sports Medicine",
      "Injury Surveillance",
      "Physiotherapy",
      "Strength & Conditioning",
      "Sports Psychology",
      "Sports Nutrition",
      "Anthropometry",
      "Exercise Physiology",
      "Biomechanics",
      "Talent Identification",
      "General Fitness",
      "Sports Specific KPI"
    ],
    Coach: [
      "Upload Documents",
      "My Test Battery",
      "Monitoring",
      "Sports Medicine",
      "Injury Surveillance",
      "Physiotherapy",
      "Strength & Conditioning",
      "Sports Psychology",
      "Sports Nutrition",
      "Anthropometry",
      "Exercise Physiology",
      "Biomechanics",
      "Talent Identification",
      "General Fitness",
      "Sports Specific KPI"
    ],
    Physiotherapist: [
      "Upload Documents",
      "My Test Battery",
      "Injury Surveillance",
      "Physiotherapy",
      "Biomechanics",
      "General Fitness"
    ],
    Doctor: [
      "Upload Documents",
      "My Test Battery",
      "Injury Surveillance",
      "Sports Medicine"
    ],
    "Strength and Conditioning Specialist": [
      "Upload Documents",
      "My Test Battery",
      "Monitoring",
      "Strength & Conditioning",
      "Biomechanics",
      "General Fitness",
      "Sports Specific KPI"
    ],
    "Exercise Physiology": [
      "Upload Documents",
      "My Test Battery",
      "Exercise Physiology",
      "General Fitness",
      "Sports Specific KPI"
    ],
    Anthropometrist: [
      "Upload Documents",
      "My Test Battery",
      "Anthropometry"
    ],
    Psychologist: [
      "Upload Documents",
      "My Test Battery",
      "General Fitness",
      "Sports Psychology",
      "Monitoring"
    ],
    "Biomechanics Specialist": [
      "Upload Documents",
      "My Test Battery",
      "Biomechanics",
      "Sports Specific KPI"
    ],
    Nutritionist: [
      "Upload Documents",
      "My Test Battery",
      "Sports Nutrition",
      "General Fitness",
      "Sports Specific KPI",
      "Anthropometry"
    ]
  };
  
  let data = [];
  
  for (const [roleKey, actions] of Object.entries(roleActions)) {
    if (role?.includes(roleKey)) {
      data = actions.map(action => ({ name: action }));
      break;
    }
  }

  return (
    <>
      <DashBoardTopNav header={"Evaluation"} />
      <Sidebar />

      <div
        style={{
          margin: "2rem",
        }}
      >
        <div className={styles.box_science_sports}>
          {data.map((item, index) => (
            <label
              className={`${styles.labelItem_sports} ${
                selectedItem === item.name ? styles.selected : ""
              }`}
              onClick={() =>
                handleOpen({
                  name: item.name,
                  key: "left",
                })
              }
              key={item.name}
            >
              {item.name}
            </label>
          ))}
        </div>
      </div>

      {drawerTitle === "Sports Medicine" ? (
        <div className={styles.belownav}>
          <SportMedicine label={drawerTitle} />
        </div>
      ) : drawerTitle === "Physiotherapy" ? (
        <div className={styles.belownav}>
          <Physiotherapy label={drawerTitle} />
        </div>
      ) : drawerTitle === "Strength & Conditioning" ? (
        <div className={styles.belownav}>
          <StrengthConditioning label={drawerTitle} />
        </div>
      ) : drawerTitle === "Sports Psychology" ? (
        <div className={styles.belownav}>
          <SportsPsychology label={drawerTitle} />
        </div>
      ) : drawerTitle === "Sports Nutrition" ? (
        <div className={styles.belownav}>
          <SportsNutrition label={drawerTitle} />
        </div>
      ) : drawerTitle === "Anthropometry" ? (
        <div className={styles.belownav}>
          <Anthropometry label={drawerTitle} />
        </div>
      ) : drawerTitle === "Exercise Physiology" ? (
        <div className={styles.belownav}>
          <ExercisePhysiology label={drawerTitle} />
        </div>
      ) : drawerTitle === "Biomechanics" ? (
        <div className={styles.belownav}>
          <Biomechanics label={drawerTitle} />
        </div>
      ) : drawerTitle === "Monitoring" ? (
        <div className={styles.belownav}>
          <LoadMonitoring label={drawerTitle} />
        </div>
      ) : drawerTitle === "Talent Identification" ? (
        <div className={styles.belownav}>
          <TalentIdentification label={drawerTitle} />
        </div>
      ) : drawerTitle === "Sports Specific KPI" ? (
        <div className={styles.belownav}>
          <SportSpecific label={drawerTitle} />
        </div>
      ) : drawerTitle === "General Fitness" ? (
        <div className={styles.belownav}>
          <GeneralFitness label={drawerTitle} />
        </div>
      ) : drawerTitle === "Injury Surveillance" ? (
        <div className={styles.belownav}>
          <CentralInjury label={drawerTitle} />
        </div>
      ) : drawerTitle === "My Test Battery" ? (
        <div className={styles.belownav}>
          <MyCustomBattery label={drawerTitle} />{" "}
          <Button
            appearance="primary"
            onClick={() => setModal(true)}
            style={{ background: "cadetblue" }}
            className={styles.btn}
          >
            Create Test Battery
          </Button>
        </div>
      ) : drawerTitle === "Upload Documents" ? (
        <EvaluationFormView
          open={open}
          setOpen={setOpen}
          size={"lg"}
          placement={"left"}
          mode={drawerTitle}
        />
      ) : null}

      <Modal
        style={{ marginTop: "1.6rem", marginLeft: "26px", marginRight: "26px" }}
        size={"full"}
        open={modal}
        onClose={() => setModal(false)}
        backdrop={true}
      >
        <Modal.Header></Modal.Header>

        <Modal.Body style={{ overflowY: "hidden" }}>
          <CustomTestBattery setModal={setModal} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
