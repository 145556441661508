import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import { Color } from "../../utils/Colors";
import { DateRangePicker } from 'rsuite';
export default function TrainingObjectives({ setSubmittedTrainingData }) {
    const [startDate, setStartDate] = useState(null);
    const [tdata, setData] = useState([]);
    const [trainingData, setTrainingData] = useState([
        {
            performance: "",
            testsStandards: "",
            physicalPrep: "",
            technicalPrep: "",
            tacticalPrep: "",
            psychologicalPrep: "",
        },
    ]);


    const period = 1;
    const phase = 1;
    const microcycle = 48;

    const handleSubmit = () => {
        const newData = []
            let data = {};
            Object.entries(trainingData[0]).forEach(([template, target_value]) => {

                switch (template) {
                    case "performance":
                    case "testsStandards":
                    case "physicalPrep":
                    case "technicalPrep":
                    case "tacticalPrep":
                    case "psychologicalPrep":
                        data = {
                            period,
                            phase,
                            microcycle,
                            template:
                                template === "performance"
                                    ? 1
                                    : template === "testsStandards"
                                        ? 2
                                        : template === "physicalPrep"
                                            ? 3
                                            : template === "technicalPrep" ? 4 : template === "tacticalPrep" ? 5 : template === "psychologicalPrep" ? 6 : null,
                            start_date: startDate[0],
                            end_date: startDate[1],
                            target_value,
                        };
                        
                        newData.push(data);
                        break;
                    default:
                        break;
                }
            });
            
       
        setData((prevData) => [...prevData, ...newData]);
    };

    // Define variables here
    console.log(startDate);
    return (
        <div
            style={{
                marginTop: "30px",
                fontFamily: "Arial, sans-serif",
                display: "grid",
                gap: "10px",
                gridTemplateColumns: "1fr 1fr",
                gridTemplateRows: "auto auto auto auto auto auto",
              
               
            }}
        >
            <h6 style={{ marginBottom: "10px", gridColumn: "1 / -1" }}>
                Fill up the training objectives
            </h6>
            <label style={{ marginRight: "10px" }}>Select Start & End Date</label>
         
            <DateRangePicker value={startDate}
                onChange={setStartDate} format="yyyy-MM-dd"/>
             
            <label style={{ marginRight: "10px" }}>Performance</label>
            <textarea
                value={trainingData[0].performance}
                style={{
                    padding: "5px",
                    width: "calc(100% - 10px)",
                    gridColumn: "1 / -1",
                }}
                onChange={(e) =>
                    setTrainingData([
                        {
                            ...trainingData[0],
                            performance: e.target.value,
                        },
                    ])
                }
            />

            <label style={{ marginRight: "10px" }}>Tests/Standards</label>
            <textarea
                value={trainingData[0].testsStandards}
                style={{
                    padding: "5px",
                    width: "calc(100% - 10px)",
                    gridColumn: "1 / -1",
                }}
                onChange={(e) =>
                    setTrainingData([
                        {
                            ...trainingData[0],
                            testsStandards: e.target.value,
                        },
                    ])
                }
            />

            <label style={{ marginRight: "10px" }}>Physical Preparation</label>
            <textarea
                value={trainingData[0].physicalPrep}
                style={{
                    padding: "5px",
                    width: "calc(100% - 10px)",
                    gridColumn: "1 / -1",
                }}
                onChange={(e) =>
                    setTrainingData([
                        {
                            ...trainingData[0],
                            physicalPrep: e.target.value,
                        },
                    ])
                }
            />

            <label style={{ marginRight: "10px" }}>Technical Preparation</label>
            <textarea
                value={trainingData[0].technicalPrep}
                style={{
                    padding: "5px",
                    width: "calc(100% - 10px)",
                    gridColumn: "1 / -1",
                }}
                onChange={(e) =>
                    setTrainingData([
                        {
                            ...trainingData[0],
                            technicalPrep: e.target.value,
                        },
                    ])
                }
            />

            <label style={{ marginRight: "10px" }}>Tactical Preparation</label>
            <textarea
                value={trainingData[0].tacticalPrep}
                style={{
                    padding: "5px",
                    width: "calc(100% - 10px)",
                    gridColumn: "1 / -1",
                }}
                onChange={(e) =>
                    setTrainingData([
                        {
                            ...trainingData[0],
                            tacticalPrep: e.target.value,
                        },
                    ])
                }
            />

            <label style={{ marginRight: "10px" }}>Psychological Preparation</label>
            <textarea
                value={trainingData[0].psychologicalPrep}
                style={{
                    padding: "5px",
                    width: "calc(100% - 10px)",
                    gridColumn: "1 / -1",
                }}
                onChange={(e) =>
                    setTrainingData([
                        {
                            ...trainingData[0],
                            psychologicalPrep: e.target.value,
                        },
                    ])
                }
            />
            <Button appearance="primary" style={{  width: "calc(100% - 1px)",gridColumn: "1 / -1", backgroundColor:Color.Color_C}} onClick={handleSubmit}>Save</Button>
        </div>
    );
}
