import React, { useState, useEffect, useCallback } from "react";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import Sidebar from "../../components/CustomSideNav";
import { HttpService } from "../../services/http.service";
import { FetchResultsByCatAthlete } from "../../components/custmomhooks/CustomHooks";
import {
  ATHLETE_PROFILE_API_URL,
  EVALUATION_DEPARTMENTS,
  EVALUATION_RESULTS_CHECK,
} from "../../services/API";
import Accordion from "@mui/material/Accordion";
// import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styles from "./../sportsscience/science.module.css";
import { Button, Modal, SelectPicker } from "rsuite";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import Label from "../../components/Label";
import { Bar } from "react-chartjs-2";
// import MyDocument from "./reports/pdf_logic/PDFTemplate";
import { Color } from "../../utils/Colors";
import Report from "./reports/Report";
import { DataGrid ,GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";

export default function SportScienceDashboard() {
  const [loading, setLoading] = useState(false);

  const [athlete, setAthlete] = useState([]);
  const [open, setOpen] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const [department, setDepartment] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [res, setResponse] = useState([]);
  const [result, setResult] = useState({
    painScoresByDate: [],
    totalScoreObtained: 0,
    totalFullScore: 0,
  });
  const [medicine, setMedicine] = useState({
    msk_history: null,
    generalHistory: null,
    covidHistory: null,
    heartRelatedHistory: null,
    otherHistory: null,
    physicalExam: null,
    mskExam: null,
    cardioExam: null,
    neuroExam: null,
    // bloodExam: null,
  });
  const [physiotherapy, setPhysiotherapy] = useState({
    demographic: null,
    history: null,
    painAssment: null,
    fms: null,
    tightness: null,
    assess: null,
    plan: null,
  });
  const [strengthC, setStrength] = useState({
    bodyComposition: null,
    cardioVascular: null,
    flexibility: null,
    balance: null,
    enurance: null,
    speed: null,
    agility: null,
    strength: null,
    power: null,
    rast: null,
    coordination: null,
    reactionTime: null,
  });
  const [psychology, setPsychology] = useState({
    motivation: null,
    anxiety: null,
    ppi: null,
  });
  const [nutrition, setNutrition] = useState({
    dietry: null,
  });
  const [anthropometry, setAnthropometry] = useState({
    somatotype: null,
  });
  const [exercisePhysiology, setExercisePhysiology] = useState({
    cooperTest: null,
    harvardTest: null,
    yoyo: null,
    lactate: null,
  });
  const [biomechanics, setBiomechanics] = useState({
    squatJump: null,
    dropJump: null,
    cmj: null,
    isokinetic: null,
    spatial: null,
    temporal: null,
  });
  const [loadMonitoring, setLoadMonitoring] = useState({
    internalLoad: null,
    externalLoadSkil: null,
    externalLoadSC: null,
    wellness: null,
  });
  const [injurySur, setInjurySur] = useState({
    key: null,
    condition: null,
  });
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const fetchResultsPhysio = useCallback(async () => {
    const demo = await FetchResultsByCatAthlete({
      dept: 1,
      cat: 1,
      athlete,
    });
    setPhysiotherapy((prevPhysio) => ({
      ...prevPhysio,
      demographic: {
        data: demo,
        catName: "Demographic",
        dept: "Physiotherapy",
      },
    }));

    const his = await FetchResultsByCatAthlete({
      dept: 1,
      cat: 2,
      athlete,
    });
    setPhysiotherapy((prevPhysio) => ({
      ...prevPhysio,
      history: { data: his, catName: "History", dept: "Physiotherapy" },
    }));

    const pain = await FetchResultsByCatAthlete({
      dept: 1,
      cat: 3,
      athlete,
    });
    setPhysiotherapy((prevPhysio) => ({
      ...prevPhysio,
      painAssment: {
        data: pain,
        catName: "Pain Assessment",
        dept: "Physiotherapy",
      },
    }));

    const func = await FetchResultsByCatAthlete({
      dept: 1,
      cat: 41,
      athlete,
    });
    setPhysiotherapy((prevPhysio) => ({
      ...prevPhysio,
      fms: {
        data: func,
        catName: "Functional Movement Screening",
        dept: "Physiotherapy",
      },
    }));

    const tight = await FetchResultsByCatAthlete({
      dept: 1,
      cat: 42,
      athlete,
    });
    setPhysiotherapy((prevPhysio) => ({
      ...prevPhysio,
      tightness: { data: tight, catName: "Tightness", dept: "Physiotherapy" },
    }));

    const assessment = await FetchResultsByCatAthlete({
      dept: 1,
      cat: 43,
      athlete,
    });
    setPhysiotherapy((prevPhysio) => ({
      ...prevPhysio,
      assess: {
        data: assessment,
        catName: "Assessment",
        dept: "Physiotherapy",
      },
    }));

    const plan = await FetchResultsByCatAthlete({
      dept: 1,
      cat: 44,
      athlete,
    });
    setPhysiotherapy((prevPhysio) => ({
      ...prevPhysio,
      plan: { data: plan, catName: "Plan", dept: "Physiotherapy" },
    }));
  }, [athlete, setPhysiotherapy]);

  const fetchResultsInury = useCallback(async () => {
    const cond = await FetchResultsByCatAthlete({
      dept: 13,
      cat: 115,
      athlete,
    });
    setInjurySur((previnjury) => ({
      ...previnjury,
      condition: { data: cond, catName: "Condition" },
    }));
    const keyy = await FetchResultsByCatAthlete({
      dept: 13,
      cat: 116,
      athlete,
    });
    setInjurySur((previnjury) => ({
      ...previnjury,
      key: { data: keyy, catName: "Key" },
    }));
  }, [athlete, setInjurySur]);
  const fetchResultsPsycho = useCallback(async () => {
    const mot = await FetchResultsByCatAthlete({
      dept: 7,
      cat: 77,
      athlete,
    });
    setPsychology((prevPyscho) => ({
      ...prevPyscho,
      motivation: { data: mot, catName: "Motivation" },
    }));
    const anx = await FetchResultsByCatAthlete({
      dept: 7,
      cat: 78,
      athlete,
    });
    setPsychology((prevPyscho) => ({
      ...prevPyscho,
      anxiety: { data: anx, catName: "Anxiety" },
    }));
    const pp = await FetchResultsByCatAthlete({
      dept: 7,
      cat: 79,
      athlete,
    });
    setPsychology((prevPyscho) => ({
      ...prevPyscho,
      ppi: { data: pp, catName: "Psychology Performance Inventory" },
    }));
  }, [athlete, setPsychology]);
  const fetchResultsNutriton = useCallback(async () => {
    const diet = await FetchResultsByCatAthlete({
      dept: 10,
      cat: 100,
      athlete,
    });
    setNutrition((prevNutri) => ({
      ...prevNutri,
      dietry: { data: diet, catName: "Dietry Allowance" },
    }));
  }, [athlete, setNutrition]);
  const fetchResultsAnthro = useCallback(async () => {
    const somato = await FetchResultsByCatAthlete({
      dept: 5,
      cat: 73,
      athlete,
    });
    setAnthropometry((prevAnthro) => ({
      ...prevAnthro,
      somatotype: { data: somato, catName: "Somatotype" },
    }));
  }, [athlete, setAnthropometry]);
  const fetchResultsBiomechanics = useCallback(async () => {
    const sq = await FetchResultsByCatAthlete({
      dept: 8,
      cat: 80,
      athlete,
    });
    setBiomechanics((prevBiomech) => ({
      ...prevBiomech,
      squatJump: { data: sq, catName: "Squat Jump" },
    }));
    const dj = await FetchResultsByCatAthlete({
      dept: 8,
      cat: 81,
      athlete,
    });
    setBiomechanics((prevBiomech) => ({
      ...prevBiomech,
      dropJump: { data: dj, catName: "Drop Jump" },
    }));
    const cmj = await FetchResultsByCatAthlete({
      dept: 8,
      cat: 82,
      athlete,
    });
    setBiomechanics((prevBiomech) => ({
      ...prevBiomech,
      cmj: { data: cmj, catName: "Counter Movement Jump" },
    }));
    const iso = await FetchResultsByCatAthlete({
      dept: 8,
      cat: 93,
      athlete,
    });
    setBiomechanics((prevBiomech) => ({
      ...prevBiomech,
      isokinetic: { data: iso, catName: "Isometric Profiling" },
    }));
    const spa = await FetchResultsByCatAthlete({
      dept: 8,
      cat: 94,
      athlete,
    });
    setBiomechanics((prevBiomech) => ({
      ...prevBiomech,
      spatial: { data: spa, catName: "Spatial Assessment" },
    }));
    const temp = await FetchResultsByCatAthlete({
      dept: 8,
      cat: 95,
      athlete,
    });
    setBiomechanics((prevBiomech) => ({
      ...prevBiomech,
      temporal: { data: temp, catName: "Temporal Assessment" },
    }));
  }, [athlete, setBiomechanics]);
  const fetchResultsMonitoring = useCallback(async () => {
    const intL = await FetchResultsByCatAthlete({
      dept: 9,
      cat: 96,
      athlete,
    });
    setLoadMonitoring((prevLoad) => ({
      ...prevLoad,
      internalLoad: { data: intL, catName: "Internal Load" },
    }));
    const extSC = await FetchResultsByCatAthlete({
      dept: 9,
      cat: 98,
      athlete,
    });
    setLoadMonitoring((prevLoad) => ({
      ...prevLoad,
      externalLoadSC: {
        data: extSC,
        catName: "External Load - Strength Conditioning",
      },
    }));
    const extSk = await FetchResultsByCatAthlete({
      dept: 9,
      cat: 97,
      athlete,
    });
    setLoadMonitoring((prevLoad) => ({
      ...prevLoad,
      externalLoadSkil: { data: extSk, catName: "External Load - Skill" },
    }));
    const wel = await FetchResultsByCatAthlete({
      dept: 9,
      cat: 99,
      athlete,
    });
    setLoadMonitoring((prevLoad) => ({
      ...prevLoad,
      wellness: { data: wel, catName: "Wellness Assessment" },
    }));
  }, [athlete, setLoadMonitoring]);
  const fetchResultsExP = useCallback(async () => {
    const coop = await FetchResultsByCatAthlete({
      dept: 4,
      cat: 69,
      athlete,
    });
    setExercisePhysiology((prevExer) => ({
      ...prevExer,
      cooperTest: { data: coop, catName: "Cooper Test" },
    }));
    const har = await FetchResultsByCatAthlete({
      dept: 4,
      cat: 70,
      athlete,
    });
    setExercisePhysiology((prevExer) => ({
      ...prevExer,
      harvardTest: { data: har, catName: "Harvard Step Test" },
    }));
    const yo = await FetchResultsByCatAthlete({
      dept: 4,
      cat: 71,
      athlete,
    });
    setExercisePhysiology((prevExer) => ({
      ...prevExer,
      yoyo: { data: yo, catName: "Yo Yo Test Assessment" },
    }));
    const lac = await FetchResultsByCatAthlete({
      dept: 4,
      cat: 72,
      athlete,
    });
    setExercisePhysiology((prevExer) => ({
      ...prevExer,
      lactate: { data: lac, catName: "Lactate Assessment" },
    }));
  }, [athlete, setExercisePhysiology]);
  const fetchResultsSC = useCallback(async () => {
    const bc = await FetchResultsByCatAthlete({
      dept: 3,
      cat: 56,
      athlete,
    });
    setStrength((prevStrength) => ({
      ...prevStrength,
      bodyComposition: {
        data: bc,
        catName: "Body Composition",
        dept: "Strength & Conditioning",
      },
    }));
    const cardioE = await FetchResultsByCatAthlete({
      dept: 3,
      cat: 58,
      athlete,
    });
    setStrength((prevStrength) => ({
      ...prevStrength,
      cardioVascular: {
        data: cardioE,
        catName: "Cardiovascular Assessment",
        dept: "Strength & Conditioning",
      },
    }));
    const flex = await FetchResultsByCatAthlete({
      dept: 3,
      cat: 66,
      athlete,
    });
    setStrength((prevStrength) => ({
      ...prevStrength,
      flexibility: {
        data: flex,
        catName: "Flexibility Assessment",
        dept: "Strength & Conditioning",
      },
    }));
    const bal = await FetchResultsByCatAthlete({
      dept: 3,
      cat: 63,
      athlete,
    });
    setStrength((prevStrength) => ({
      ...prevStrength,
      balance: {
        data: bal,
        catName: "Balance Assessment",
        dept: "Strength & Conditioning",
      },
    }));
    const endur = await FetchResultsByCatAthlete({
      dept: 3,
      cat: 57,
      athlete,
    });
    setStrength((prevStrength) => ({
      ...prevStrength,
      enurance: {
        data: endur,
        catName: "Musculoskeletal Endurance Assessment",
        dept: "Strength & Conditioning",
      },
    }));
    const sp = await FetchResultsByCatAthlete({
      dept: 3,
      cat: 62,
      athlete,
    });
    setStrength((prevStrength) => ({
      ...prevStrength,
      speed: {
        data: sp,
        catName: "Speed Assessment",
        dept: "Strength & Conditioning",
      },
    }));
    const agilityy = await FetchResultsByCatAthlete({
      dept: 3,
      cat: 61,
      athlete,
    });
    setStrength((prevStrength) => ({
      ...prevStrength,
      agility: {
        data: agilityy,
        catName: "Agility Assessment",
        dept: "Strength & Conditioning",
      },
    }));
    const str = await FetchResultsByCatAthlete({
      dept: 3,
      cat: 64,
      athlete,
    });
    setStrength((prevStrength) => ({
      ...prevStrength,
      strength: {
        data: str,
        catName: "Strength Assessment",
        dept: "Strength & Conditioning",
      },
    }));
    const po = await FetchResultsByCatAthlete({
      dept: 3,
      cat: 65,
      athlete,
    });
    setStrength((prevStrength) => ({
      ...prevStrength,
      power: {
        data: po,
        catName: "Power Assessment",
        dept: "Strength & Conditioning",
      },
    }));
    const rast = await FetchResultsByCatAthlete({
      dept: 3,
      cat: 60,
      athlete,
    });
    setStrength((prevStrength) => ({
      ...prevStrength,
      rast: {
        data: rast,
        catName: "RAST Assessment",
        dept: "Strength & Conditioning",
      },
    }));
    const coord = await FetchResultsByCatAthlete({
      dept: 3,
      cat: 67,
      athlete,
    });
    setStrength((prevStrength) => ({
      ...prevStrength,
      coordination: {
        data: coord,
        catName: "Coordination",
        dept: "Strength & Conditioning",
      },
    }));
    const rt = await FetchResultsByCatAthlete({
      dept: 3,
      cat: 68,
      athlete,
    });
    setStrength((prevStrength) => ({
      ...prevStrength,
      reactionTime: {
        data: rt,
        catName: "Reaction Time",
        dept: "Strength & Conditioning",
      },
    }));
  }, [athlete, setStrength]);

  const fetchResultsMedicine = useCallback(async () => {
    const msk = await FetchResultsByCatAthlete({
      dept: 2,
      cat: 46,
      athlete,
    });
    setMedicine((prevMedicine) => ({
      ...prevMedicine,
      msk_history: {
        data: msk,
        catName: "Musculoskeletal History",
        dept: "Medicine",
      },
    }));
    const general = await FetchResultsByCatAthlete({
      dept: 2,
      cat: 47,
      athlete,
    });
    setMedicine((prevMedicine) => ({
      ...prevMedicine,
      generalHistory: {
        data: general,
        catName: "General History",
        dept: "Medicine",
      },
    }));
    const covid = await FetchResultsByCatAthlete({
      dept: 2,
      cat: 48,
      athlete,
    });
    setMedicine((prevMedicine) => ({
      ...prevMedicine,
      covidHistory: { data: covid, catName: "Covid History", dept: "Medicine" },
    }));
    const hr = await FetchResultsByCatAthlete({
      dept: 2,
      cat: 49,
      athlete,
    });
    setMedicine((prevMedicine) => ({
      ...prevMedicine,
      heartRelatedHistory: {
        data: hr,
        catName: "Heart Related History",
        dept: "Medicine",
      },
    }));
    const other = await FetchResultsByCatAthlete({
      dept: 2,
      cat: 50,
      athlete,
    });
    setMedicine((prevMedicine) => ({
      ...prevMedicine,
      otherHistory: { data: other, catName: "Other History", dept: "Medicine" },
    }));
    const phe = await FetchResultsByCatAthlete({
      dept: 2,
      cat: 51,
      athlete,
    });
    setMedicine((prevMedicine) => ({
      ...prevMedicine,
      physicalExam: {
        data: phe,
        catName: "Physical Examination",
        dept: "Medicine",
      },
    }));
    const cardio = await FetchResultsByCatAthlete({
      dept: 2,
      cat: 52,
      athlete,
    });
    setMedicine((prevMedicine) => ({
      ...prevMedicine,
      cardioExam: {
        data: cardio,
        catName: "Cardio Examination",
        dept: "Medicine",
      },
    }));
    const neuro = await FetchResultsByCatAthlete({
      dept: 2,
      cat: 53,
      athlete,
    });
    setMedicine((prevMedicine) => ({
      ...prevMedicine,
      neuroExam: {
        data: neuro,
        catName: "Neuro Examination",
        dept: "Medicine",
      },
    }));
    const mskExm = await FetchResultsByCatAthlete({
      dept: 2,
      cat: 54,
      athlete,
    });
    setMedicine((prevMedicine) => ({
      ...prevMedicine,
      mskExam: {
        data: mskExm,
        catName: "Musculoskeletal Examination",
        dept: "Medicine",
      },
    }));
  }, [athlete, setMedicine]);
  const memoPhysio = useCallback(
    () => fetchResultsPhysio(athlete, setPhysiotherapy),
    [athlete, setPhysiotherapy, fetchResultsPhysio]
  );
  const memoAnthro = useCallback(
    () => fetchResultsAnthro(athlete, setAnthropometry),
    [athlete, setAnthropometry, fetchResultsAnthro]
  );
  const memoMed = useCallback(
    () => fetchResultsMedicine(athlete, setMedicine),
    [athlete, setMedicine, fetchResultsMedicine]
  );
  const memoNut = useCallback(
    () => fetchResultsNutriton(athlete, setNutrition),
    [athlete, setNutrition, fetchResultsNutriton]
  );
  const memoExercise = useCallback(
    () => fetchResultsExP(athlete, setExercisePhysiology),
    [athlete, setExercisePhysiology, fetchResultsExP]
  );
  const memoInjury = useCallback(
    () => fetchResultsInury(athlete, setInjurySur),
    [athlete, setInjurySur, fetchResultsInury]
  );
  const memoBiomech = useCallback(
    () => fetchResultsBiomechanics(athlete, setBiomechanics),
    [athlete, setBiomechanics, fetchResultsBiomechanics]
  );
  const memoPsyc = useCallback(
    () => fetchResultsPsycho(athlete, setPsychology),
    [athlete, setPsychology, fetchResultsPsycho]
  );
  const memoMon = useCallback(
    () => fetchResultsMonitoring(athlete, setLoadMonitoring),
    [athlete, setLoadMonitoring, fetchResultsMonitoring]
  );
  const memoStr = useCallback(
    () => fetchResultsSC(athlete, setStrength),
    [athlete, setStrength, fetchResultsSC]
  );

  const handlefunc = (item) => {
    const memoizationFunctions = {
      Anthropometry: memoAnthro,
      "Central Athlete Injury Management System": memoInjury,
      "Biochemistry": () => { console.log(); },
      "Custom": () => { console.log(); },
      "Exercise Physiology": memoExercise,
      "Fit India": () => { console.log(); },
      "Medicine": memoMed,
      "Monitoring": memoMon,
      "Movement Science": memoBiomech,
      "Nutrition": memoNut,
      "Physiotherapy": memoPhysio,
      "Psychology": memoPsyc,
      "Sport Specific": () => { console.log(); },
      "Strength & Conditioning": memoStr,
    };
  
    const selectedFunction = memoizationFunctions[item];
    if (selectedFunction) {
      selectedFunction();
    }
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }
  // useEffect(() => {
  //   if (athlete?.length > 0) {
  //     setLoading(true);
  //     Promise.all([
  //       memoPhysio(),
  //       memoAnthro(),
  //       memoMed(),
  //       memoNut(),
  //       memoExercise(),
  //       memoInjury(),
  //       memoBiomech(),
  //       memoPsyc(),
  //       memoMon(),
  //       memoStr()
  //     ])
  //       .then(() => setLoading(false))
  //       .catch((error) => {
  //         // handle error
  //         setLoading(false);
  //       });
  //   }
  // }, [athlete, setLoading, memoPhysio, memoAnthro, memoMed, memoNut, memoExercise, memoInjury, memoBiomech, memoPsyc, memoMon, memoStr]);

  function calculatePainScoresByDate(dataArray) {
    if (!Array.isArray(dataArray)) {
      // console.error('Input is not an array');
      return;
    }

    const painScoresByDate = {};
    let totalScoreObtained = 0;
    let totalFullScore = 0;

    dataArray.forEach((entry) => {
      const { date, field_name, value } = entry;

      if (!painScoresByDate[date]) {
        painScoresByDate[date] = {
          date,
          entries: [],
          totalScore: 0,
          fullScore: 0,
        };
      }

      let painScore = 0;
      let fullScore = 0;

      if (field_name === "Nature") {
        switch (value) {
          default:
            console.warn(`Unexpected value for 'Nature': ${value}`);
            break;
          case "Burning":
            painScore += 5 * 2;
            fullScore += 10 * 2;
            break;
          case "Shooting":
            painScore += 5 * 2;
            fullScore += 10 * 2;
            break;
          case "Tingling":
            painScore += 5 * 2;
            fullScore += 10 * 2;
            break;
          case "Radiating":
            painScore += 10 * 2;
            fullScore += 10 * 2;
            break;
          case "Lancinating":
            painScore += 5 * 2;
            fullScore += 10 * 2;
            break;
          case "Numbness":
            painScore += 5 * 2;
            fullScore += 10 * 2;
            break;
          case "Achy":
            painScore += 5 * 2;
            fullScore += 10 * 2;
            break;
          case "Throbbing":
            painScore += 10 * 2;
            fullScore += 10 * 2;
            break;
          case "Dull":
            painScore += 3 * 2;
            fullScore += 10 * 2;
            break;
          case "Squeezing":
            painScore += 10 * 2;
            fullScore += 10 * 2;
            break;
          case "Pressure":
            painScore += 10 * 2;
            fullScore += 10 * 2;
            break;
          case "Cramping":
            painScore += 5 * 2;
            fullScore += 10 * 2;
            break;
          case "Distention":
            painScore += 10 * 2;
            fullScore += 10 * 2;
            break;
          case "Deep":
            painScore += 10 * 2;
            fullScore += 10 * 2;
            break;
          case "Stretching":
            painScore += 3;
            fullScore += 10;
            break;
        }
      } else if (field_name === "Type") {
        switch (value) {
          default:
            console.warn(`Unexpected value for 'Type': ${value}`);
            break;
          case "Neuropathic pain":
            painScore += 5 * 2;
            fullScore += 10 * 2;
            break;
          case "Somatic Pain":
            painScore += 3 * 2;
            fullScore += 10 * 2;
            break;
          case "Visceral Pain":
            painScore += 10 * 2;
            fullScore += 10 * 2;
            break;
        }
      } else if (field_name === "Severity") {
        switch (value) {
          default:
            console.warn(`Unexpected value for 'Severity': ${value}`);
            break;
          case "MIld":
            painScore += 3 * 2;
            fullScore += 10 * 2;
            break;
          case "Moderate":
            painScore += 5 * 2;
            fullScore += 10 * 2;
            break;
          case "Severe":
            painScore += 10 * 2;
            fullScore += 10 * 2;
            break;
        }
      } else if (field_name === "Diurnal Variation") {
        switch (value) {
          default:
            console.warn(`Unexpected value for 'Diurnal Variation': ${value}`);

            break;
        }
      } else if (field_name === "Numeric Pain Rating Scale") {
        painScore += Number(value) * 3;
        fullScore += 10 * 3;
      } else if (field_name === "Duration") {
        switch (value) {
          default:
            console.warn(`Unexpected value for 'Duration': ${value}`);
            break;
          case "Intermittent":
            painScore += 5;
            fullScore += 10;
            break;
          case "Continous":
            painScore += 10;
            fullScore += 10;
            break;
        }
      }

      painScoresByDate[date].entries.push({
        id: entry.id,
        field_name,
        painScore,
        fullScore,
      });
      painScoresByDate[date].totalScore += painScore;
      painScoresByDate[date].fullScore += fullScore;
      totalScoreObtained += painScore;
      totalFullScore += fullScore;
    });

    const painScoresByDateWithKey = Object.values(painScoresByDate).map(
      ({ date, entries, totalScore, fullScore }) => ({
        date,
        entries,
        totalScore,
        fullScore,
      })
    );

    setResult({
      painScoresByDate: painScoresByDateWithKey,
      totalScoreObtained,
      totalFullScore,
    });
  }

  useEffect(() => {
    const fetchAthletes = async () => {
      try {
        const response = await HttpService.get(
          ATHLETE_PROFILE_API_URL,
          {},
          false
        );
        const modifiedAthletes = response.data.results.map((athlete) => ({
          value: athlete.id,
          label: athlete.name,
        }));
        setAthletes(modifiedAthletes);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthletes();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (athlete?.length <= 0) {
          const response = await HttpService.get(
            EVALUATION_DEPARTMENTS,
            {},
            false
          );
          setDepartment(response.data.results);
          setDepartments(
            response.data.results.map((dept) => ({
              value: dept.name,
              label: dept.name,
            }))
          );

          return;
        }

        // Check if the selected athlete has the department
        const response = await HttpService.post(
          EVALUATION_RESULTS_CHECK,
          {},
          { athlete_id: athlete },
          false
        );
        setResponse(response.data);
      } catch (error) {
        console.error("Error checking department existence:", error);
      }
    };

    fetchData();
  }, [athlete]);

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
  };
  const excludedFieldNames = [
    "Location",
    "Aggrevating Factors",
    "Relieving Factors",
    "Diurnal Variation",
  ];

  const ScrollableLabels = ({ painScoresByDate }) => {
    if (
      !painScoresByDate ||
      !Array.isArray(painScoresByDate.painScoresByDate)
    ) {
      // Handle the case where painScoresByDate is not an array
      return <div>Error: Invalid data format</div>;
    }
    const dates = painScoresByDate.painScoresByDate.map(
      (dateItem) => dateItem.date
    );
    const totalScores = painScoresByDate.painScoresByDate.map(
      (dateItem) => dateItem.totalScore
    );
    const totalScoresObtained = painScoresByDate.painScoresByDate.map(
      (dateItem) => dateItem.fullScore
    );

    // Data for the bar chart
    const chartData = {
      labels: dates,
      datasets: [
        {
          label: "Total Score",
          data: totalScores,
          backgroundColor: "rgba(75, 192, 192, 0.6)", // Adjust the color as needed
        },
        {
          label: "Total Score Obtained",
          data: totalScoresObtained,
          backgroundColor: "rgba(255, 99, 132, 0.6)", // Adjust the color as needed
        },
      ],
    };
    return (
      <div>
        {painScoresByDate.painScoresByDate.map((dateItem, dateIndex) => {
          if (!dateItem || !Array.isArray(dateItem.entries)) {
            // Handle the case where dateItem or its entries are not as expected
            return (
              <div key={dateIndex}>Error: Invalid data format for a date</div>
            );
          }

          return (
            <div
              key={dateIndex}
              className={styles.label_2}
              style={{ display: "flex", flex: 1, justifyContent: "center" }}
            >
              <Label color="warning">{dateItem.date}</Label>
              {dateItem.entries.map((entryItem, entryIndex) => {
                // Exclude specific field_names
                if (excludedFieldNames.includes(entryItem.field_name)) {
                  return null; // Skip rendering for excluded fields
                }

                return (
                  <div key={entryIndex}>
                    <Label color="primary">{entryItem.field_name}:</Label>
                    <Label sx={{ marginTop: "1rem" }} color={"secondary"}>
                      {entryItem.painScore}
                    </Label>
                  </div>
                );
              })}
              <br />
              <div>
                <Label color="info">Total Score:</Label>
                <Label sx={{ marginTop: "1rem" }} color={"secondary"}>
                  {dateItem.totalScore}
                </Label>
              </div>
              <br />
              <div>
                <Label color="info">Full Score:</Label>
                <Label sx={{ marginTop: "1rem" }} color={"secondary"}>
                  {dateItem.fullScore}
                </Label>
              </div>
              <br />
              <div>
                <Label color="info">%:</Label>
                <br />

                <Label
                  sx={{ marginTop: "1rem" }}
                  color={
                    ((dateItem.totalScore / dateItem.fullScore) * 100).toFixed(
                      2
                    ) >= 75
                      ? "error"
                      : (
                          (dateItem.totalScore / dateItem.fullScore) *
                          100
                        ).toFixed(2) < 75 &&
                        (
                          (dateItem.totalScore / dateItem.fullScore) *
                          100
                        ).toFixed(2) > 50
                      ? "warning"
                      : "success"
                  }
                >
                  {((dateItem.totalScore / dateItem.fullScore) * 100).toFixed(
                    2
                  )}
                  %
                </Label>
              </div>
            </div>
          );
        })}
        <br />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <Label color="info">Total Score Obtained:</Label>
            <Label color={"success"}>
              {painScoresByDate.totalScoreObtained}
            </Label>
          </div>
          <br />
          <div>
            <Label color="info">Total Full Score:</Label>
            <Label color={"success"}>{painScoresByDate.totalFullScore}</Label>
          </div>

          <br />
          <div>
            <Label color="info">%:</Label>
            <Label
              color={
                (
                  (painScoresByDate.totalScoreObtained /
                    painScoresByDate.totalFullScore) *
                  100
                ).toFixed(2) >= 75
                  ? "error"
                  : (
                      (painScoresByDate.totalScoreObtained /
                        painScoresByDate.totalFullScore) *
                      100
                    ).toFixed(2) < 75 &&
                    (
                      (painScoresByDate.totalScoreObtained /
                        painScoresByDate.totalFullScore) *
                      100
                    ).toFixed(2) > 50
                  ? "warning"
                  : "success"
              }
            >
              {(
                (painScoresByDate.totalScoreObtained /
                  painScoresByDate.totalFullScore) *
                100
              ).toFixed(2)}
              %
            </Label>
          </div>
        </div>
        <br />
        <div>
          <Bar data={chartData} />
        </div>
      </div>
    );
  };

  const renderScrollableLabels = (data) => {
    if (data === null) {
      return null; // Don't render anything if data is null
    }

    return <ScrollableLabels painScoresByDate={data} />;
  };

  const ScrollableLabels2 = ({ data, catName }) => {
    if (data === null) {
      return;
    }

    // Filter data based on startDate and endDate
    const filteredData =
      data &&
      data.filter((item) => {
        const itemDate = new Date(item.date);
        return (
          (!startDate || itemDate >= startDate) &&
          (!endDate || itemDate <= endDate)
        );
      });

    // Group filtered data by date
    const groupedData = filteredData?.reduce((accumulator, currentValue) => {
      const itemDate = new Date(currentValue.date).toDateString();
      if (!accumulator[itemDate]) {
        accumulator[itemDate] = [];
      }
      accumulator[itemDate].push(currentValue);
      return accumulator;
    }, {});

    const rows = [];
    const columns = [
      {
        field: "date",
        headerName: "Date",
        width: 300,
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        align: "center",
      },
      {
        field: "catName",
        headerName: "Category",
        width: 250,
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        align: "center",
      }, // New column for category

      {
        field: "fieldName",
        headerName: "Test",
        width: 450,
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        align: "center",
        renderCell: (params) => (
          <div
            style={{ overflowX: "auto", maxWidth: "100%", textAlign: "left" }}
          >
            {params.value}
          </div>
        ),
      },
      {
        field: "value",
        headerName: "Result",
        width: 350,
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        align: "center",
      },
    ];

    // Prepare rows for DataGrid
    Object.entries(groupedData).forEach(([date, values], index) => {
      values.forEach((item, itemIndex) => {
        rows.push({
          id: `${index}-${itemIndex}`,
          date: date,
          catName: catName,
          fieldName: item.field_name,
          value: item.value,
        });
      });
    });

    const renderResultCell = (params) => {
      return (
        <div style={{ textAlign: "center" }}>
          <Label
            sx={{ marginTop: "1rem" }}
            color={
              params.value === "Yes"
                ? "error"
                : params.value === "No"
                ? "success"
                : "secondary"
            }
          >
            {params.value}
          </Label>
        </div>
      );
    };
    if (!data || data.length === 0) {
      return null; // Don't render anything if no data
    }
    return (
      <div style={{ width: "100%" }}>
        <DataGrid
          sx={{
            "& .super-app-theme--header": {
              color: Color.Color_C,
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          rows={rows}
          columns={columns.map((column) => ({
            ...column,
            align: "center", // Align all columns to center
            renderCell: (params) =>
              column.field === "value"
                ? renderResultCell(params)
                : params.value,
            // Apply custom renderer for Result column
          }))}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 4,
              },
            },
          }}
          pageSizeOptions={[4]}
          checkboxSelection={false} // Depending on your use case
        />
      </div>
    );
  };
  const renderScrollableLabels2 = (data, catName) => {
    if (data === null) {
      return null; // Don't render anything if data is null
    }

    return <ScrollableLabels2 data={data} catName={catName} />;
  };
  const dataArray = Object.entries(res).map(([name, value]) => ({
    name,
    value,
  }));

  let departmentValue = "";
  let boolValue = "";
  return (
    <>
      <DashBoardTopNav header={"Overview"} />
      <Sidebar />

      <div className={styles.belownav2}>
        <div>
          <SelectPicker
            label={athlete?.length <= 0 ? "Select Athlete" : athlete?.name}
            data={athletes}
            onSelect={(value) => setAthlete(value)}
            style={{ width: 230 }}
          />
          {/* <div className={styles.datefilter}>
            <div>
              <label className={styles.label}>Start Date:</label>
              <input
                type="date"
                className={styles.dateinput}
                onChange={(e) => setStartDate(new Date(e.target.value))}
              />
            </div>
            <div style={{ marginLeft: "12px" }}>
              <label className={styles.label}>End Date:</label>
              <input
                type="date"
                className={styles.dateinput}
                onChange={(e) => setEndDate(new Date(e.target.value))}
              />
            </div>
          </div>
          <Button
            className={styles.ssb}
            style={{ marginRight: "12px" ,background: "cadetblue",}}
            appearance="primary"
            onClick={resetDates}
          >
            Reset Dates
          </Button> */}
          <Button
            // className={styles.ssb}
            disabled={athlete?.length <= 0}
            style={{
              marginLeft: "12px",
              marginRight: "12px",
              background: "cadetblue",
            }}
            appearance="primary"
            onClick={openModal}
          >
            {" "}
            <FileDownloadIcon sx={{ mr: 1, color: Color.Color_A }} /> Report
          </Button>
          {/* <Button
            className={styles.ssb}
            style={{ marginRight: "12px",background: "cadetblue", }}
            appearance="primary"
            onClick={() => setOpen(true)}
            disabled={physiotherapy.painAssment === null}
          >
            Recommendation
          </Button> */}
        </div>
      </div>
      <div>
        <div className={styles.box_science_sports_12}>
          <div>
            {res?.length <= 0 ? (
              <div>
                {department.map((item, index) => (
                  <Accordion key={index}>
                    <AccordionSummary
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                    >
                      {item.name}
                    </AccordionSummary>
                  </Accordion>
                ))}
              </div>
            ) : (
              <div>
                {dataArray.map((item, index) => {
                  departmentValue = item.name;
                  boolValue = item.value;

                  return (
                    <Accordion key={index}>
                      <AccordionSummary
                        disabled={!item.value}
                        onClick={() => handlefunc(item.name)}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                      >
                        {item.name}
                        {item.value ? (
                          <CheckCircleIcon
                            className={styles.icon}
                            color="success"
                          />
                        ) : (
                          <CancelIcon className={styles.icon} color="error" />
                        )}
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ maxHeight: "400px", overflowY: "auto" }}
                      >
                        <div>
                          {loading ? ( // Display loading indicator if loading state is true
                            <h6>Loading...</h6>
                          ) : (
                            <div>
                              {departmentValue === "Medicine" &&
                              boolValue === true ? (
                                <div>
                                  {medicine.msk_history === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        medicine.msk_history?.data,
                                        medicine?.msk_history.catName
                                      )}
                                    </>
                                  )}
                                  {medicine.generalHistory === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        medicine.generalHistory?.data,
                                        medicine?.generalHistory.catName
                                      )}
                                    </>
                                  )}
                                  {medicine.covidHistory === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        medicine.covidHistory?.data,
                                        medicine?.covidHistory.catName
                                      )}
                                    </>
                                  )}
                                  {medicine.heartRelatedHistory ===
                                  null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        medicine.heartRelatedHistory?.data,
                                        medicine?.heartRelatedHistory.catName
                                      )}
                                    </>
                                  )}
                                  {medicine.otherHistory === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        medicine.otherHistory?.data,
                                        medicine?.otherHistory.catName
                                      )}
                                    </>
                                  )}
                                  {medicine.physicalExam === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        medicine.physicalExam?.data,
                                        medicine?.physicalExam.catName
                                      )}
                                    </>
                                  )}
                                  {medicine.mskExam === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        medicine.mskExam?.data,
                                        medicine?.mskExam.catName
                                      )}
                                    </>
                                  )}
                                  {medicine.cardioExam === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        medicine.cardioExam?.data,
                                        medicine?.cardioExam.catName
                                      )}
                                    </>
                                  )}
                                  {medicine.neuroExam === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        medicine.neuroExam?.data,
                                        medicine?.neuroExam.catName
                                      )}
                                    </>
                                  )}

                                  {/* {renderScrollableLabels2(medicine.bloodExam?.data,medicine?.bloodExam.catName)} */}
                                </div>
                              ) : departmentValue ===
                                  "Central Athlete Injury Management System" &&
                                boolValue === true ? (
                                <div>
                                  {injurySur.condition === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        injurySur.condition?.data,
                                        injurySur?.condition.catName
                                      )}
                                    </>
                                  )}
                                  {injurySur.key === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        injurySur.key?.data,
                                        injurySur?.key.catName
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : departmentValue === "Physiotherapy" &&
                                boolValue === true ? (
                                <div>
                                  {physiotherapy.demographic === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        physiotherapy.demographic?.data,
                                        physiotherapy?.demographic.catName
                                      )}
                                    </>
                                  )}

                                  {physiotherapy.history === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        physiotherapy.history?.data,
                                        physiotherapy?.history.catName
                                      )}
                                    </>
                                  )}

                                  {physiotherapy.painAssment === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        physiotherapy.painAssment?.data,
                                        physiotherapy?.painAssment.catName
                                      )}
                                    </>
                                  )}

                                  {physiotherapy.fms === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        physiotherapy.fms?.data,
                                        physiotherapy?.fms.catName
                                      )}
                                    </>
                                  )}

                                  {physiotherapy.tightness === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        physiotherapy.tightness?.data,
                                        physiotherapy?.tightness.catName
                                      )}
                                    </>
                                  )}

                                  {physiotherapy.assess === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        physiotherapy.assess?.data,
                                        physiotherapy?.assess.catName
                                      )}
                                    </>
                                  )}

                                  {physiotherapy.plan === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        physiotherapy.plan?.data,
                                        physiotherapy?.plan?.catName
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : departmentValue ===
                                  "Strength & Conditioning" &&
                                boolValue === true ? (
                                <div>
                                  {strengthC.bodyComposition === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        strengthC.bodyComposition?.data,
                                        strengthC?.bodyComposition.catName
                                      )}
                                    </>
                                  )}
                                  {strengthC.cardioVascular === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        strengthC.cardioVascular?.data,
                                        strengthC?.cardioVascular.catName
                                      )}
                                    </>
                                  )}
                                  {strengthC.flexibility === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        strengthC.flexibility?.data,
                                        strengthC?.flexibility.catName
                                      )}
                                    </>
                                  )}
                                  {strengthC.balance === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        strengthC.balance?.data,
                                        strengthC?.balance.catName
                                      )}
                                    </>
                                  )}
                                  {strengthC.enurance === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        strengthC.enurance?.data,
                                        strengthC?.enurance.catName
                                      )}{" "}
                                    </>
                                  )}
                                  {strengthC.speed === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        strengthC.speed?.data,
                                        strengthC?.speed.catName
                                      )}
                                    </>
                                  )}
                                  {strengthC.agility === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        strengthC.agility?.data,
                                        strengthC?.agility.catName
                                      )}
                                    </>
                                  )}
                                  {strengthC.strength === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        strengthC.strength?.data,
                                        strengthC?.strength.catName
                                      )}
                                    </>
                                  )}
                                  {strengthC.power === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        strengthC.power?.data,
                                        strengthC?.power.catName
                                      )}
                                    </>
                                  )}
                                  {strengthC.rast === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        strengthC.rast?.data,
                                        strengthC?.rast.catName
                                      )}
                                    </>
                                  )}
                                  {strengthC.coordination === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        strengthC.coordination?.data,
                                        strengthC?.coordination.catName
                                      )}
                                    </>
                                  )}
                                  {strengthC.reactionTime === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        strengthC.reactionTime?.data,
                                        strengthC?.reactionTime.catName
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : departmentValue === "Psychology" &&
                                boolValue === true ? (
                                <div>
                                  {psychology.motivation === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        psychology.motivation?.data,
                                        psychology?.motivation.catName
                                      )}
                                    </>
                                  )}
                                  {psychology.anxiety === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        psychology.anxiety?.data,
                                        psychology?.anxiety.catName
                                      )}
                                    </>
                                  )}
                                  {psychology.ppi === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        psychology.ppi?.data,
                                        psychology?.ppi.catName
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : departmentValue === "Nutrition" &&
                                boolValue === true ? (
                                <div>
                                  {nutrition.dietry === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        nutrition.dietry?.data,
                                        nutrition?.dietry.catName
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : departmentValue === "Anthropometry" &&
                                boolValue === true ? (
                                <div>
                                  {anthropometry?.somatotype === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        anthropometry?.somatotype?.data,
                                        anthropometry?.somatotype.catName
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : departmentValue === "Exercise Physiology" &&
                                boolValue === true ? (
                                <div>
                                  {exercisePhysiology.cooperTest ===
                                  null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        exercisePhysiology.cooperTest?.data,
                                        exercisePhysiology?.cooperTest.catName
                                      )}
                                    </>
                                  )}
                                  {exercisePhysiology.harvardTest ===
                                  null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        exercisePhysiology.harvardTest?.data,
                                        exercisePhysiology?.harvardTest.catName
                                      )}
                                    </>
                                  )}
                                  {exercisePhysiology.yoyo === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        exercisePhysiology.yoyo?.data,
                                        exercisePhysiology?.yoyo.catName
                                      )}
                                    </>
                                  )}
                                  {exercisePhysiology.lactate ===
                                  null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        exercisePhysiology.lactate?.data,
                                        exercisePhysiology?.lactate.catName
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : departmentValue === "Movement Science" &&
                                boolValue === true ? (
                                <div>
                                  {biomechanics.squatJump === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        biomechanics.squatJump?.data,
                                        biomechanics?.squatJump.catName
                                      )}
                                    </>
                                  )}
                                  {biomechanics.dropJump === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        biomechanics.dropJump?.data,
                                        biomechanics?.dropJump.catName
                                      )}
                                    </>
                                  )}
                                  {biomechanics.cmj === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        biomechanics.cmj?.data,
                                        biomechanics?.cmj.catName
                                      )}
                                    </>
                                  )}
                                  {biomechanics.isokinetic === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        biomechanics.isokinetic?.data,
                                        biomechanics?.isokinetic.catName
                                      )}
                                    </>
                                  )}
                                  {biomechanics.spatial === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        biomechanics.spatial?.data,
                                        biomechanics?.spatial.catName
                                      )}
                                    </>
                                  )}
                                  {biomechanics.temporal === null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        biomechanics.temporal?.data,
                                        biomechanics?.temporal.catName
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : departmentValue === "Monitoring" &&
                                boolValue === true ? (
                                <div>
                                  {loadMonitoring.internalLoad ===
                                  null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        loadMonitoring.internalLoad?.data,
                                        loadMonitoring?.internalLoad.catName
                                      )}
                                    </>
                                  )}
                                  {loadMonitoring.externalLoadSkil ===
                                  null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        loadMonitoring.externalLoadSkil?.data,
                                        loadMonitoring?.externalLoadSkil.catName
                                      )}
                                    </>
                                  )}
                                  {loadMonitoring.externalLoadSC ===
                                  null ? null : (
                                    <>
                                      {renderScrollableLabels2(
                                        loadMonitoring.externalLoadSC?.data,
                                        loadMonitoring?.externalLoadSC.catName
                                      )}
                                    </>
                                  )}
                                  {loadMonitoring.wellness === null ? null : (
                                    <>
                                      {" "}
                                      {renderScrollableLabels2(
                                        loadMonitoring.wellness?.data,
                                        loadMonitoring?.wellness.catName
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : (
                                <h6>No Data</h6>
                              )}
                            </div>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        size={"lg"}
        open={open}
        backdrop={true}
        onClose={() => setOpen(false)}
      >
        <Modal.Header>
          {" "}
          <Button
            disabled={physiotherapy?.painAssment === null}
            appearance="primary"
            onClick={() =>
              calculatePainScoresByDate(physiotherapy?.painAssment)
            }
            style={{
              marginRight: "3rem",
              background: Color.Color_C,
              color: Color.Color_A,
            }}
          >
            Pain Insights
          </Button>
          {/* <Button  color="violet"
          appearance="primary" onClick={openModal}>Open PDF</Button> */}
        </Modal.Header>
        <Modal.Body>{renderScrollableLabels(result)}</Modal.Body>
      </Modal>
      <Modal size={"md"} open={isOpen} backdrop={true} onClose={closeModal}>
        <Modal.Header>Reports</Modal.Header>
        <Modal.Body>
          <Report
            athlete={athlete}
            departments={departments}
            res={res}
            result={result}
            physio={physiotherapy}
            medicine={medicine}
            strength={strengthC}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
