export const ExerciseTherapy = [
  {
    id: 1,
    "Exercise Therapy": "Maitland Mobilisation - Grade 1",
  },
  {
    id: 2,
    "Exercise Therapy": "Maitland Mobilisation - Grade 2",
  },
  {
    id: 3,
    "Exercise Therapy": "Maitland Mobilisation - Grade 3",
  },
  {
    id: 4,
    "Exercise Therapy": "Maitland Mobilisation - Grade 4",
  },
  {
    id: 5,
    "Exercise Therapy": "Movement With Mobilistaion",
  },
  {
    id: 6,
    "Exercise Therapy": "Kaltenborn Traction - Grade 1",
  },
  {
    id: 7,
    "Exercise Therapy": "Kaltenborn Traction - Grade 2",
  },
  {
    id: 8,
    "Exercise Therapy": "Kaltenborn Traction - Grade 3",
  },
  {
    id: 9,
    "Exercise Therapy": "Static Stretching",
  },
  {
    id: 10,
    "Exercise Therapy": "Dynamic Stretching",
  },
  {
    id: 11,
    "Exercise Therapy": "Ballistic Stretching",
  },
  {
    id: 12,
    "Exercise Therapy": "Proprioceptive Neuromuscular Facilitation",
  },
  {
    id: 13,
    "Exercise Therapy": "Free Exercises",
  },
  {
    id: 14,
    "Exercise Therapy": "Isometric Strength Training",
  },
  {
    id: 15,
    "Exercise Therapy": "Concentric Strength Training",
  },
  {
    id: 16,
    "Exercise Therapy": "Eccentric Strength Training",
  },
  {
    id: 17,
    "Exercise Therapy": "Isokinetic Strength Training",
  },
  {
    id: 18,
    "Exercise Therapy": "Mobility Exercises",
  },
  {
    id: 19,
    "Exercise Therapy": "Gait Training",
  },
];

export const ElectroTherapy = [
  {
    id: 1,
    Electrotherapy: "Current Therapy",
  },
  {
    id: 2,
    Electrotherapy: "Ultrasound Therapy",
  },
  {
    id: 3,
    Electrotherapy: "Infrared Radiation",
  },
  {
    id: 4,
    Electrotherapy: "Ultraviolet Radiation",
  },
  {
    id: 5,
    Electrotherapy: "Electromagnetic Radiation",
  },
];

export const AdjunctTherapy = [
  {
    id: 1,
    "Adjunct Therapy": "Dry Needling",
  },
  {
    id: 2,
    "Adjunct Therapy": "Cupping",
  },
  {
    id: 3,
    "Adjunct Therapy": "Taping",
  },
];
