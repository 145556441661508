import React, { useEffect } from "react";
import {
  Form,
  Button,
  FlexboxGrid,
  SelectPicker,
  CheckPicker,
} from "rsuite";
import { HttpService } from "../../services/http.service";
import { ACCOUNTS_API_URL, APP_PERMISSION, SUBSCRIPTION_API_URLS } from "../../services/API";
import CustomNotification from "../CustomNotification";
import { Color } from "../../utils/Colors";



const SubscriptionForm = ({ onSubmitSuccess, setCount }) => {
  const formRef = React.useRef();
  const [errorCount, setErrorCount] = React.useState(0);
  const [formError, setFormError] = React.useState({});
  const [pauseDate, setPauseDate] = React.useState(null);
  const [maxProfile, setMaxProfile] = React.useState();
  const [maxTeam, setMaxTeam] = React.useState();
  const [maxAthlete, setMaxAthlete] = React.useState();
  const [startDate, setStartDate] = React.useState(null);
  const [expDate, setExpDate] = React.useState(null);
  const [maxUser, setMaxUser] = React.useState();
  const [accounts, setAccounts] = React.useState([]);
  const [account, setAccount] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [urls, setURLS] = React.useState();
  const [url, setURL] = React.useState();

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const response = await HttpService.get(
          `${ACCOUNTS_API_URL}?page=${currentPage}`, {}, true
        );

        const modifiedUsers = response.data.results.map((user) => ({
          value: user.id,
          label: user.name,
        }));

        setAccounts(modifiedUsers);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAccount();
  }, [currentPage]);
  React.useEffect(() => {
    const fetchUrl = async () => {
      try {
        const response = await HttpService.get(
          `${APP_PERMISSION}`,
          {},
          true
        );

        const modURL = response.data.results.map((t) => ({
          value: t.id,
          label: t.name,
        }));

        setURLS(modURL);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchUrl();
  }, []);

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      console.error("Form Error", formError);
      return;
    }
    const sd =
      startDate instanceof Date
        ? `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${startDate
            .getDate()
            .toString()
            .padStart(2, "0")}`
        : null;
    const ed =
      expDate instanceof Date
        ? `${expDate.getFullYear()}-${(expDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${expDate.getDate().toString().padStart(2, "0")}`
        : null;
    const pd =
      pauseDate instanceof Date 
        ? `${pauseDate.getFullYear()}-${(pauseDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${pauseDate
            .getDate()
            .toString()
            .padStart(2, "0")}`
        : null; // Set to null if pauseDate is not valid
   
    try {
      const formData = {
        max_users: maxUser,
        max_teams: maxTeam,
        max_admin_profiles: maxProfile,
        max_athlete_profiles: maxAthlete,
        start_date: sd,
        expiration_date: ed,
        pause_data:  pd,
        app_permissions: url || [],
        account,
      };
  
      const response = await HttpService.post(
        SUBSCRIPTION_API_URLS,
        {},
        formData, true
      );

      setResponse(response);

      if (response.status === 201) {
        setOpenModal(true);
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setError("Form Submitted");
        setPauseDate("")
        setMaxProfile("")
        setMaxTeam("")
        setMaxAthlete("")
        setStartDate("")
        setExpDate("")
        setMaxUser("")
        onSubmitSuccess();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

 

  const selectPickerContainerStyle = {
    display: "flex",
    maxHeight: "70vh",
    overflowY: "auto",
    // justifyContent: "space-between",
  };

  return (
    <FlexboxGrid>
    <FlexboxGrid.Item colspan={12}>
      <Form ref={formRef} onCheck={setFormError}>
        <div style={{ marginBottom: '20px' }}>
          <label>Maximum User</label>
          <br />
          <input style={{ width: '240px', height: '30px', marginBottom: '10px' }} type="number" onChange={(e) => setMaxUser(e.target.value)} />
        </div>
  
        <div style={{ marginBottom: '20px' }}>
          <label>Maximum Team</label>
          <br />
          <input style={{ width: '240px', height: '30px', marginBottom: '10px' }} type="number" onChange={(e) => setMaxTeam(e.target.value)} />
        </div>
  
        <div style={{ marginBottom: '20px' }}>
          <label>Maximum Admin Profile</label>
          <br />
          <input style={{ width: '240px', height: '30px', marginBottom: '10px' }} type="number" onChange={(e) => setMaxProfile(e.target.value)} />
        </div>
  
        <div style={{ marginBottom: '20px' }}>
          <label>Maximum Athlete Profile</label>
          <br />
          <input style={{ width: '240px', height: '30px', marginBottom: '10px' }} type="number" onChange={(e) => setMaxAthlete(e.target.value)} />
        </div>
  
      
        
          <SelectPicker
            label="Select Account"
            data={accounts}
            onSelect={(value) => setAccount(value)}
            style={{ width: '400px' }}
          />
        
  <br/> <br/>
        <CheckPicker
          label="Select App Permission"
          data={urls}
          value={url}
          onChange={(value) => setURL(value)}
          style={{ width: '400px', marginRight: '3rem', marginBottom: '20px' }}
        />
  
        <div style={{ marginBottom: '20px' }}>
          <label>Start Date</label>
          <br />
          <input type="date" onChange={(e) => setStartDate(new Date(e.target.value))} style={{ width: '240px', height: '30px', marginBottom: '10px' }} />
        </div>
  
        <div style={{ marginBottom: '20px' }}>
          <label>Expiry Date</label>
          <br />
          <input type="date" onChange={(e) => setExpDate(new Date(e.target.value))} style={{ width: '240px', height: '30px', marginBottom: '10px' }} />
        </div>
  
        <div style={{ marginBottom: '20px' }}>
          <label>Pause Date</label>
          <br />
          <input type="date" onChange={(e) => setPauseDate(new Date(e.target.value))} style={{ width: '240px', height: '30px', marginBottom: '10px' }} />
        </div>
  
        <Button appearance="primary" onClick={handleSubmit} style={{ background: Color.Color_C, color: Color.Color_A, width: '240px', marginBottom: '20px' }}>
          Create Subscription
        </Button>
  
        <CustomNotification
          errorCount={errorCount}
          open={openModal}
          setOpen={setOpenModal}
          message={error ? error : (res && res.data && res.data.message) || ""}
          title={error === "Form Submitted" ? "Success" : "Error"}
        />
      </Form>
    </FlexboxGrid.Item>
  </FlexboxGrid>
  
  );
};

export default SubscriptionForm;
