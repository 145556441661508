import React, { useState, useRef } from "react";
import {
  PlayCircleOutline,
  Stop,
  FastForward,
  FastRewind,
  Add,
  ArrowForward,
  ArrowBack,
} from "@mui/icons-material"; // Import icons
import styles from "./navigation.module.css";
import { useLocation } from "react-router-dom";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import Sidebar from "../../components/CustomSideNav";
import AssessmentType from "./AssessmentType";
import { Button } from "rsuite";

export default function TemporalParameter() {
  const [modalTitle, setModalTitle] = useState("");
  const [shotData, setShotData] = useState({});
  const [remarks, setRemarks] = useState("");
  const [editingRow, setEditingRow] = useState(null);
  const videoRef = useRef(null);
  const location = useLocation();
  const [progress, setProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const selectedFile = location.state && location.state.file;
  const videoSource = selectedFile ? URL.createObjectURL(selectedFile) : "";
  const [drawerTitle, setDrawerTitle] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
 
  const handleOpenModal = (title) => {
    setModalTitle(title);
    if (!shotData[title]) {
      setShotData((prevState) => {
        return {
          ...prevState,
          [title]: [],
        };
      });
    }
  };

  const handleTimeUpdate = () => {
    if (!isDragging) {
      const currentTime = videoRef.current.currentTime;
      const duration = videoRef.current.duration;
      const progressPercent = (currentTime / duration) * 100;
      setProgress(progressPercent);
    }
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    handleMouseMove(event);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const progressBar = event.target;
      const rect = progressBar.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const progressBarWidth = rect.width;
      const newProgress = (offsetX / progressBarWidth) * 100;
      setProgress(Math.min(100, Math.max(0, newProgress)));
      const newTime = (newProgress / 100) * videoRef.current.duration;
      videoRef.current.currentTime = newTime;
    }
  };

  const handleFastForward = () => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.playbackRate = 1;
      const currentTime = videoRef.current.currentTime;
      videoRef.current.currentTime = currentTime + 2;
    }
  };

  const handleSlowForward = () => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.playbackRate = 1;
      const currentTime = videoRef.current.currentTime;
      videoRef.current.currentTime = Math.min(
        videoRef.current.duration,
        currentTime + 1
      );
    }
  };

  const handleFastRewind = () => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.playbackRate = 1;
      const currentTime = videoRef.current.currentTime;
      videoRef.current.currentTime = Math.max(0, currentTime - 2);
    }
  };

  const handleSlowRewind = () => {
    if (videoRef.current && videoRef.current.paused) {
      let playbackRate = videoRef.current.playbackRate;
      if (playbackRate > 0.1) {
        playbackRate -= 0.1;
      }
      videoRef.current.playbackRate = playbackRate;
      const currentTime = videoRef.current.currentTime;
      videoRef.current.currentTime = Math.max(0, currentTime - 1);
    }
  };

  const handleAddRow = () => {
    const newShotNumber = shotData[modalTitle].length + 1;
    const newRow = {
      title: modalTitle,
      shotNumber: newShotNumber,
      startTime: "",
      endTime: "",
      diffTime: "",
      remarks: remarks,
    };
    setShotData((prevState) => {
      return {
        ...prevState,
        [modalTitle]: [...prevState[modalTitle], newRow],
      };
    });
  };

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  const handlePlay = () => {
    const currentTime = videoRef.current.currentTime;
    if (modalTitle) {
      const lastShotIndex = shotData[modalTitle].length - 1;
      if (lastShotIndex >= 0 && !shotData[modalTitle][lastShotIndex].endTime) {
        const updatedShotData = [...shotData[modalTitle]];
        updatedShotData[lastShotIndex].startTime = currentTime.toFixed(2);
        setShotData((prevState) => {
          return {
            ...prevState,
            [modalTitle]: updatedShotData,
          };
        });
      }
    }
    videoRef.current.play();
  };

  const handleStop = () => {
    const currentTime = videoRef.current.currentTime;
    const lastShotIndex = shotData[modalTitle].length - 1;
    if (lastShotIndex >= 0 && !shotData[modalTitle][lastShotIndex].endTime) {
      const updatedShotData = [...shotData[modalTitle]];
      updatedShotData[lastShotIndex].endTime = currentTime.toFixed(2);
      const diffTime = (
        updatedShotData[lastShotIndex].endTime -
        updatedShotData[lastShotIndex].startTime
      ).toFixed(2);
      updatedShotData[lastShotIndex].diffTime = diffTime;
      setShotData((prevState) => {
        return {
          ...prevState,
          [modalTitle]: updatedShotData,
        };
      });
    }
    videoRef.current.pause();
  };

  const handleFinalSubmit = () => {
    const allData = Object.values(shotData).flat();
    console.log(allData);
    // setShotData(allData);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleEditRow = (title, row) => {
    setModalTitle(title);
    setEditingRow(row);
  };

  const handleSaveChanges = () => {
    const updatedData = { ...shotData };
    const { title, shotNumber } = editingRow;
    const rowIndex = updatedData[title].findIndex(
      (row) => row.shotNumber === shotNumber
    );

    updatedData[title][rowIndex] = { ...editingRow };
    setShotData(updatedData);
    setEditingRow(null);
  };

  const handleDeleteRow = (title, shotNumber) => {
    const updatedData = { ...shotData };
    updatedData[title] = updatedData[title].filter(
      (row) => row.shotNumber !== shotNumber
    );
    setShotData(updatedData);
  };
console.log(selectedItem)
const roleActions = {
  Gait: [
    "Initial Contact", "Loading Response", "Midstance", "Terminal Stance", "Preswing", "Initial Swing", "Midswing", "Terminal Swing"
  ],
  Running: [
    "Initial Contact", "Midstance", "Toe Off", "Initial Swing", "Midswing", "Terminal Swing"
  ],
  Archery: [
    "Pre Shot", "Set Up", "Draw", "Anchor", "Release"
  ],
  Sprinting: [
    "Start Phase", "Acceleration Phase", "Maximum Velocity Phase", "Deceleration Phase"
  ],
  Swimming: [
    "Start", "Pull", "Push", "Recovery", "Breathing"
  ],
  Jumping: [
    "Preparation", "Takeoff", "Flight", "Landing"
  ],
  Throwing: [
    "Preparation", "Windup", "Arm Cocking", "Arm Acceleration", "Release", "Follow-Through"
  ],
  Cycling: [
    "Pedal Upstroke", "Top Dead Center", "Power Phase", "Bottom Dead Center", "Recovery Phase"
  ],
  Badminton: [
    "Ready Position", "Footwork", "Stroke Preparation", "Stroke Execution", "Recovery"
  ],
  "Table Tennis": [
    "Ready Position", "Footwork", "Stroke Preparation", "Stroke Execution", "Follow-Through"
  ],
  "Lawn Tennis": [
    "Ready Position", "Footwork", "Backswing", "Forward Swing", "Contact", "Follow-Through"
  ],
  Shooting: [
    "Stance", "Grip", "Aiming", "Breath Control", "Trigger Squeeze", "Follow-Through"
  ],
};

let data = [];

for (const [roleKey, actions] of Object.entries(roleActions)) {
  if (selectedItem?.includes(roleKey)) {
    data = actions.map(action => ({ name: action }));
    break;
  }
}
  return (
    <>
      <DashBoardTopNav header={"Temporal"} />
      <Sidebar />
      <AssessmentType drawerTitle={drawerTitle} setDrawerTitle={setDrawerTitle} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
      <div className={styles["toolbar-container"]}>
        <div className={styles["belownav"]}>
          {data.map((item, index) => (
            <Button
              key={index}
              appearance="primary"
              style={{ background: "cadetblue" }}
              className={styles.btn}
              onClick={() => handleOpenModal(item.name)}
            >
              {item.name}
            </Button>
          ))}
        </div>
       
       
        {modalTitle && (
           <><div className={styles["video"]}>
            <video
              style={{ borderRadius: "8px" }}
              ref={videoRef}
              controls
              width="96%"
              height="400"
              onTimeUpdate={handleTimeUpdate}
            >
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div
              className={styles["progress-bar"]}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
            >
              <div
                className={styles["progress-line"]}
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div><div className={styles["modal-container"]}>
              <div className={styles["modal"]}>
                <div className={styles["modal-header"]}>
                  <h2>{modalTitle}</h2>
                  <div className={styles["modal-content"]}>
                    <div className={styles["button-container"]}>
                      <button
                        onClick={() => handleAddRow(videoRef.current.currentTime.toFixed(2))}
                      >
                        <Add />
                      </button>
                      <button onClick={handlePlay}>
                        <PlayCircleOutline />
                      </button>
                      <button onClick={handleStop}>
                        <Stop />
                      </button>
                      <button onClick={handleSlowForward}>
                        <ArrowForward />
                      </button>
                      <button onClick={handleFastForward}>
                        <FastForward />
                      </button>
                      <button onClick={handleSlowRewind}>
                        <ArrowBack />
                      </button>
                      <button onClick={handleFastRewind}>
                        <FastRewind />
                      </button>
                      <input
                        type="text"
                        placeholder="Remarks"
                        value={remarks}
                        onChange={handleRemarksChange} />
                    </div>
                  </div>
                </div>
                <div className={styles["modal-content"]}>
                  {editingRow && (
                    <>
                      <div className={styles["button-container"]}>
                        <button onClick={handleSaveChanges}>Save Changes</button>
                      </div>
                      <input
                        type="text"
                        placeholder="Shot Number"
                        value={editingRow.shotNumber}
                        onChange={(e) => setEditingRow({
                          ...editingRow,
                          shotNumber: e.target.value,
                        })} />
                      <input
                        type="text"
                        placeholder="Start Time (in seconds)"
                        value={editingRow.startTime}
                        onChange={(e) => setEditingRow({
                          ...editingRow,
                          startTime: e.target.value,
                        })} />
                      <input
                        type="text"
                        placeholder="End Time (in seconds)"
                        value={editingRow.endTime}
                        onChange={(e) => setEditingRow({
                          ...editingRow,
                          endTime: e.target.value,
                        })} />
                      <input
                        type="text"
                        placeholder="Diff Time (in seconds)"
                        value={editingRow.diffTime}
                        onChange={(e) => setEditingRow({
                          ...editingRow,
                          diffTime: e.target.value,
                        })} />
                      <input
                        type="text"
                        placeholder="Remarks"
                        value={editingRow.remarks}
                        onChange={(e) => setEditingRow({
                          ...editingRow,
                          remarks: e.target.value,
                        })} />
                    </>
                  )}
                </div>
              </div>
            </div>  <div className={styles["table-container"]}>
          <table className={styles["shot-table"]}>
            <thead>
              <tr>
                <th>Title</th>
                <th>Shot Number</th>
                <th>Start Time (in seconds)</th>
                <th>End Time (in seconds)</th>
                <th>Diff Time (in seconds)</th>
                <th>Remarks</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(shotData).map(([title, data], index) =>
                data?.map((row, rowIndex) => (
                  <tr key={`${index}-${rowIndex}`}>
                    <td>{title}</td>
                    <td>{row.shotNumber}</td>
                    <td>{row.startTime}</td>
                    <td>{row.endTime}</td>
                    <td>{row.diffTime}</td>
                    <td>{row.remarks}</td>
                    <td>
                      <button onClick={() => handleEditRow(title, row)}>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button onClick={() => handleDeleteRow(title, row)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className={styles["submit-button-container"]}>
            <button
              className={styles["submit-button"]}
              onClick={handleFinalSubmit}
            >
              Final Submit
            </button>
          </div>
        </div></>
        )}
       
      </div>
    </>
  );
}
