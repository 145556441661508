import React from "react";
import styles from "./landing.module.css";
import LandingNav from "./TopNav";
import Header from "./Header";
import AboutUs from "./AboutUs";
import Services from "./Services";
import DataDriven from "./DataDriven";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

export default function Landing() {
  
    const targetSection = (sectionId)=> document.getElementById(sectionId);
    if (targetSection) {
      window.scrollTo({
        top: targetSection.offsetTop,
        behavior: "smooth",
      });
    }
  

  return (
    <>
      <div className={styles.container}>
        <LandingNav />
        <Header />
      </div>
      <div className={styles.sub_container} id="feature">
        <AboutUs />
      </div>
      <div className={styles.sub_container} id="data-driven">
        <DataDriven />
      </div>
      <div className={styles.sub_container} id="services">
        <Services />
      </div>
      <div className={styles.contact_container} id="contact">
        <ContactUs />
      </div>
      <div className={styles.footer_container}>
        <Footer />
      </div>
    </>
  );
}
