export const Cereals = [
  {
    id: 1,
    Cereals: "Rice",
  },
  {
    id: 2,
    Cereals: "Wheat flour",
  },
  {
    id: 3,
    Cereals: "Dalia",
  },
  {
    id: 4,
    Cereals: "Sago",
  },
  {
    id: 5,
    Cereals: "White flour",
  },
  {
    id: 6,
    Cereals: "Bread",
  },
  {
    id: 7,
    Cereals: "Chapati",
  },
  {
    id: 8,
    Cereals: "Jowar roti",
  },
  {
    id: 9,
    Cereals: "Ragi",
  },
  {
    id: 10,
    Cereals: "Rice flakes",
  },
  {
    id: 11,
    Cereals: "Oat meal",
  },
  {
    id: 12,
    Cereals: "Vermicelli",
  },
  {
    id: 13,
    Cereals: "Corn flakes",
  },
  {
    id: 14,
    Cereals: "Maize dry",
  },
  {
    id: 15,
    Cereals: "Marie biscuit",
  },
  {
    id: 16,
    Cereals: "Monaco biscuit",
  },
  {
    id: 17,
    Cereals: "Idlis",
  },
  {
    id: 18,
    Cereals: "Poha",
  },
  {
    id: 19,
    Cereals: "Upma",
  },
  {
    id: 20,
    Cereals: "Dosa ordinary",
  },
];

export const Fats = [
  {
    id: 1,
    Fats: "Butter",
  },
  {
    id: 2,
    Fats: "Ghee",
  },
  {
    id: 3,
    Fats: "Hydrogenated fat [Vanaspati]",
  },
  {
    id: 4,
    Fats: "Oil [Coconut, Mustard Sunflower, Corn, Groundnut, Cotton seed, Til, Palm]",
  },
  {
    id: 5,
    Fats: "Cashewnuts",
  },
  {
    id: 6,
    Fats: "Groundnuts, roasted",
  },
  {
    id: 7,
    Fats: "Walnuts",
  },
  {
    id: 8,
    Fats: "Pistachio",
  },
  {
    id: 9,
    Fats: "Almonds",
  },
];

export const Milk_Milk_Products = [
  {
    id: 1,
    "Milk & Milk Products": "Curd",
  },
  {
    id: 2,
    "Milk & Milk Products": "Butter Milk",
  },
  {
    id: 3,
    "Milk & Milk Products": "Cheese",
  },
  {
    id: 4,
    "Milk & Milk Products": "Milk [Buffalo]",
  },
  {
    id: 5,
    "Milk & Milk Products": "Milk [Cow]",
  },
  {
    id: 6,
    "Milk & Milk Products": "Milk, Skimmed*",
  },
  {
    id: 7,
    "Milk & Milk Products": "Milk, Skimmed, powder*",
  },
  {
    id: 8,
    "Milk & Milk Products": "Coffee Nescafe+75 ml milk",
  },
  {
    id: 9,
    "Milk & Milk Products": "[without sugar]",
  },
  {
    id: 10,
    "Milk & Milk Products": "Tea + 75 ml milk",
  },
  {
    id: 11,
    "Milk & Milk Products": "Khoya",
  },
];
export const VegetablesCooked = [
  {
    id: 1,
    "Vegetables Cooked": "Beetroot [Chukander]",
  },
  {
    id: 2,
    "Vegetables Cooked": "Carrot",
  },
  {
    id: 3,
    "Vegetables Cooked": "Colocasia [arbi]",
  },
  {
    id: 4,
    "Vegetables Cooked": "Onion [big]",
  },
  {
    id: 5,
    "Vegetables Cooked": "Onion [small]",
  },
  {
    id: 6,
    "Vegetables Cooked": "Potato",
  },
  {
    id: 7,
    "Vegetables Cooked": "Sweet potato",
  },
  {
    id: 8,
    "Vegetables Cooked": "Tapioca",
  },
  {
    id: 9,
    "Vegetables Cooked": "Yam [Zimikand]",
  },
  {
    id: 10,
    "Vegetables Cooked": "Broad beans",
  },
  {
    id: 11,
    "Vegetables Cooked": "Cluster beans",
  },
  {
    id: 12,
    "Vegetables Cooked": "Double beans",
  },
  {
    id: 13,
    "Vegetables Cooked": "Jack, Tender",
  },
  {
    id: 14,
    "Vegetables Cooked": "Jackfruit seeds",
  },
  {
    id: 15,
    "Vegetables Cooked": "Leeks",
  },
  {
    id: 16,
    "Vegetables Cooked": "Peas",
  },
  {
    id: 17,
    "Vegetables Cooked": "Singhara",
  },
  {
    id: 18,
    "Vegetables Cooked": "Sambar",
  },
  {
    id: 19,
    "Vegetables Cooked": "Cooked vegetable",
  },
];
export const Fruits = [
  {
    id: 1,
    Fruits: "Apple",
  },
  {
    id: 2,
    Fruits: "Amla",
  },
  {
    id: 3,
    Fruits: "Banana",
  },
  {
    id: 4,
    Fruits: "Cashew fruit",
  },
  {
    id: 5,
    Fruits: "Custard apple",
  },
  {
    id: 6,
    Fruits: "Dates",
  },
  {
    id: 7,
    Fruits: "Figs",
  },
  {
    id: 8,
    Fruits: "Grapes",
  },
  {
    id: 9,
    Fruits: "Grape fruit",
  },
  {
    id: 10,
    Fruits: "Jack fruit",
  },
  {
    id: 11,
    Fruits: "Mango",
  },
  {
    id: 12,
    Fruits: "Melon",
  },
  {
    id: 13,
    Fruits: "Orange",
  },
  {
    id: 14,
    Fruits: "Lemon",
  },
  {
    id: 15,
    Fruits: "Papaya",
  },
  {
    id: 16,
    Fruits: "Peach",
  },
  {
    id: 17,
    Fruits: "Pear",
  },
  {
    id: 18,
    Fruits: "Plums",
  },
  {
    id: 19,
    Fruits: "Pineapple",
  },
  {
    id: 20,
    Fruits: "Strawberry",
  },
  {
    id: 21,
    Fruits: "Sweetlime",
  },
  {
    id: 22,
    Fruits: "Tomato",
  },
  {
    id: 23,
    Fruits: "Water melon",
  },
];
export const PusleUncooked = [
  {
    id: 1,
    "Pulse [uncooked]": "Bengal gram",
  },
  {
    id: 2,
    "Pulse [uncooked]": "Bengal gram, roasted",
  },
  {
    id: 3,
    "Pulse [uncooked]": "Bengal gram-flour [Besan]",
  },
  {
    id: 4,
    "Pulse [uncooked]": "Cow gram",
  },
  {
    id: 5,
    "Pulse [uncooked]": "Horse gram",
  },
  {
    id: 6,
    "Pulse [uncooked]": "Kabuli Channa [white gram]",
  },
  {
    id: 7,
    "Pulse [uncooked]": "Lentils",
  },
  {
    id: 8,
    "Pulse [uncooked]": "Moth beans",
  },
  {
    id: 9,
    "Pulse [uncooked]": "Peas, dried",
  },
  {
    id: 10,
    "Pulse [uncooked]": "Rajmah [kidney beans]",
  },
  {
    id: 11,
    "Pulse [uncooked]": "Red gram",
  },
];
export const NonVeg = [
  {
    id: 1,
    "Flesh Foods": "Egg Hen",
  },
  {
    id: 2,
    "Flesh Foods": "Fish",
  },
  {
    id: 3,
    "Flesh Foods": "Liver",
  },
  {
    id: 4,
    "Flesh Foods": "Mutton, muscle",
  },
  {
    id: 5,
    "Flesh Foods": "Pork",
  },
  {
    id: 6,
    "Flesh Foods": "Prawn",
  },
  {
    id: 7,
    "Flesh Foods": "Chicken",
  },
  {
    id: 8,
    "Flesh Foods": "Crab",
  },
  {
    id: 9,
    "Flesh Foods": "Beef",
  },
];
export const Vegetables = [
  {
    id: 1,
    Vegetables: "Bitter gourd [Karela]",
  },
  {
    id: 2,
    Vegetables: "Amaranth",
  },
  {
    id: 3,
    Vegetables: "Brussels sprouts",
  },
  {
    id: 4,
    Vegetables: "Cabbage",
  },
  {
    id: 5,
    Vegetables: "Coriander leaves",
  },
  {
    id: 6,
    Vegetables: "Curry leaves",
  },
  {
    id: 7,
    Vegetables: "Fenugreek leaves",
  },
  {
    id: 8,
    Vegetables: "Mint",
  },
  {
    id: 9,
    Vegetables: "Spinach",
  },
  {
    id: 10,
    Vegetables: "Brinjal",
  },
  {
    id: 11,
    Vegetables: "Cauliflower",
  },
  {
    id: 12,
    Vegetables: "Drumstic",
  },
  {
    id: 13,
    Vegetables: "Frenchbeans",
  },
  {
    id: 14,
    Vegetables: "Mango, green",
  },
  {
    id: 15,
    Vegetables: "Onion stalks",
  },
  {
    id: 16,
    Vegetables: "Pumpkin",
  },
  {
    id: 17,
    Vegetables: "Tinda",
  },
  {
    id: 18,
    Vegetables: "Tomato, Green",
  },
];

export const Drinks = [
  {
    id: 1,
    Drinks: "Orange juice",
  },
  {
    id: 2,
    Drinks: "Tomato juice",
  },
  {
    id: 3,
    Drinks: "Apple juice",
  },
  {
    id: 4,
    Drinks: "Grape juice",
  },
  {
    id: 5,
    Drinks: "Mango juice",
  },
];

export const FastFood = [
  {
    id: 1,
    "Fast Food": "Soft drink",
  },
  {
    id: 2,
    "Fast Food": "Potato wafers",
  },
  {
    id: 3,
    "Fast Food": "Samosa",
  },
  {
    id: 4,
    "Fast Food": "Veg. cutlet",
  },
  {
    id: 5,
    "Fast Food": "Vada",
  },
  {
    id: 6,
    "Fast Food": "Pizza",
  },
  {
    id: 7,
    "Fast Food": "Burger",
  },
];

export const Desserts = [
  {
    id: 1,
    Desserts: "Custard",
  },
  {
    id: 2,
    Desserts: "Fruit salad",
  },
  {
    id: 3,
    Desserts: "Fruit salad with cream",
  },
  {
    id: 4,
    Desserts: "Icecream",
  },
  {
    id: 5,
    Desserts: "Carrot halwa",
  },
  {
    id: 6,
    Desserts: "Badami halwa",
  },
  {
    id: 7,
    Desserts: "Kaju Barfi",
  },
  {
    id: 8,
    Desserts: "Coconut burfi",
  },
  {
    id: 9,
    Desserts: "Gulabjamun",
  },
  {
    id: 10,
    Desserts: "Laddo",
  },
  {
    id: 11,
    Desserts: "Rasgulla",
  },
  {
    id: 12,
    Desserts: "Jam",
  },
  {
    id: 13,
    Desserts: "Honey",
  },
];
