import React, { useEffect, useState } from "react";
// import { Button } from "rsuite";
import styles from "./../../pages/sportsscience/science.module.css";

import { Card, CardContent, Typography } from "@mui/material";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid,GridToolbarContainer,GridToolbarExport } from "@mui/x-data-grid";
// import { Color } from "../../utils/Colors";
import { ATHLETE_PROFILE_API_URL, EVALUATION_RESULTS } from "../../services/API";
import { HttpService } from "../../services/http.service";
import { CascadingDropDownNumeric } from "../../components/CascadingDropDown";
Chart.register(CategoryScale);

export default function Univariate() {
  const [results, setResultUni] = useState([]);
  const [filterResults, setFilterResultsUni] = useState([]);
  const [selectedDepartmentUni, setSelectedDepartmentUni] = useState(null);
  const [selectedCategoryUni, setSelectedCategoryUni] = useState(null);
  const [selectedAthleteUni, setSelectedAthleteUni] = useState(null); // Add state for selected athlete
  const [departmentsUni, setDepartmentsUni] = useState([]);
  const [categoriesUni, setCategoriesUni] = useState([]);
  const [athletesUni, setAthletesUni] = useState(); // State to store athlete options
  // const [showGenerateButtonUni, setShowGenerateButtonUni] = useState(true);
  const [filter, setFilterUni] = useState(0);
  const [subCategoriesUni, setSubCategoriesUni] = useState([]);
  const [selectedSubCategoryUni, setSelectedSubCategoryUni] = useState([]);
useEffect(()=>{

  const fetchResultUni = async () => {
    try {
      const response = await HttpService.get(
        `${EVALUATION_RESULTS}?department=${selectedDepartmentUni}&categories=${selectedCategoryUni}&field=${selectedSubCategoryUni}`,
        {},
        false
      );

      const modifiedResult = await Promise.all(
        response.data.results.map(async (result, index) => {
          try {
            // Fetch athlete data for each result
            const athleteResponse = await HttpService.get(
              ATHLETE_PROFILE_API_URL + result.athlete + "/",
              {},
              false
            );

            const athleteName = athleteResponse.data.name;
            const athleteId = athleteResponse.data.id;
            const value = determineValue(result);

            // Only include the result if athlete details are available
            if (athleteName !== "N/A") {
              return {
                id: index,
                athlete: athleteName,
                athlete_id: athleteId,
                value,
                date: result.created_at.slice(0, 10),
                field_name: result.field_name,
                field_id: result.field,
              };
            }

            return null; // Exclude the result when athlete is "N/A"
          } catch (athleteError) {
            console.warn(
              "Error fetching athlete details:",
              athleteError.message
            );
            // If athlete details cannot be fetched, return null to exclude the result
            return null;
          }
        })
      );

      // Filter out null results caused by failed athlete fetch or when athlete is "N/A"
      const filteredResults = modifiedResult.filter(
        (result) => result !== null
      );

      setResultUni(filteredResults);
      // setShowGenerateButtonUni(false);
      setFilterUni(1);
    } catch (error) {
      console.warn(error);
    }
  };
  if (selectedCategoryUni !==null || selectedDepartmentUni !==null || selectedSubCategoryUni?.length>0){
    fetchResultUni()}
  
},[selectedDepartmentUni,selectedCategoryUni,selectedSubCategoryUni])
 

  const determineValue = (result) => {
    return result.boolean_value !== false
      ? result.boolean_value
      : result.choice_value !== null
        ? result.choice_value
        : result.date_value !== null
          ? result.date_value
          : result.float_value !== null
            ? result.float_value
            : result.integer_value !== null
              ? result.integer_value
              : result.remarks !== null
                ? result.remarks
                : result.string_value !== null
                  ? result.string_value
                  : null;
  };

  const customColors = [
    "rgba(75, 192, 192, 0.2)",
    "rgba(255, 99, 132, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(255, 205, 86, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(201, 203, 207, 0.2)",
  ];

  // const filterResultsByAthleteUni = () => {
  //   if (selectedAthleteUni === null) {
  //     // If no athlete is selected, reset the filterResults to the original results
  //     setFilterResultsUni(resultsUni);
  //   } else {
  //     // Filter the original results based on the selected athlete
  //     const filteredResults = resultsUni.filter(
  //       (result) => result.athlete_id === selectedAthleteUni
  //     );
  //     setFilterResultsUni(filteredResults);
  //   }
  //   setFilterUni(2);
  // };

  const resetSelectionUni = () => {
    setSelectedDepartmentUni(null);
    setSelectedCategoryUni(null);
    setSelectedAthleteUni(null);
    setResultUni([]);
    setFilterResultsUni([]);
    // setShowGenerateButtonUni(true);
    setFilterUni(0);
  };

  function generateBarDatasets(data) {
    const uniqueFieldNames = Array.from(
      new Set(data.map((result) => result.field_name))
    );

    // Define an array of colors to assign to datasets
    const backgroundColors = uniqueFieldNames.map((fieldName, index) => {
      const colorIndex = index % customColors.length;
      return customColors[colorIndex];
    });

    return uniqueFieldNames.map((fieldName, index) => {
      const datasetData = data
        .filter((result) => result.field_name === fieldName)
        .map((result) => ({
          x: result.date.slice(0, 10),
          y: result.value,
        }));

      return {
        label: fieldName,
        data: datasetData,
        backgroundColor: backgroundColors[index], // Assign a unique color to the dataset
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        barPercentage: 0.2

      };
    });
  }

  const columns = [
    {
      field: "athlete",
      headerName: "Name",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "field_name",
      headerName: "Test Name",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      headerName: "Result",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }
  
  return (
    <div>
    
    <div
      className={styles.belownav}
    >
            <CascadingDropDownNumeric
                selectedDepartment={selectedDepartmentUni}
                setSelectedDepartment={setSelectedDepartmentUni}
                selectedCategory={selectedCategoryUni}
                setSelectedCategory={setSelectedCategoryUni}
                departments={departmentsUni}
                setDepartments={setDepartmentsUni}
                categories={categoriesUni}
                setCategories={setCategoriesUni}
                athletes={athletesUni}
                setAthletes={setAthletesUni}
                results={results}
                selectedAthlete={selectedAthleteUni}
                setSelectedAthlete={setSelectedAthleteUni}
                subCategories={subCategoriesUni}
                setSubCategories={setSubCategoriesUni}
                selectedSubCategory={selectedSubCategoryUni}
                setSelectedSubCategory={setSelectedSubCategoryUni}
               
                onClean={resetSelectionUni}
              />
    </div>
      {filter === 1 ? (
        <>
          {selectedSubCategoryUni?.length === 0 ? (
            <Typography variant="h6" textAlign={"center"}>
              No Data
            </Typography> // Use results.length to check if there are no results
          ) : (
            <div
              style={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
               <Card
                sx={{
                  width: "40rem",
                  height: "20rem",
                  marginBottom: "2rem",
                  margin: "1rem",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Bar
                    data={{
                      labels: results.map((result) => result.date.slice(0, 10)),
                      datasets: generateBarDatasets(results), // Pass customColors array
                    }}
                    options={{
                      scales: {
                        x: {
                          stacked: false,
                          title: {
                            display: true,
                            text: 'Date', // Label for the x-axis
                            font: {
                              size: 16,
                              weight: 'bold'
                            }
                          }
                        },
                        y: {
                          stacked: false,
                          title: {
                            display: true,
                            text: 'Value', // Label for the y-axis
                            font: {
                              size: 16,
                              weight: 'bold'
                            }
                          }
                        }
                      },
                      plugins: {
                        title: {
                          display: true,
                          text: results.find(
                            (result) => result.field_id === setSubCategoriesUni
                          )?.field_name,
                        },
                        legend: {
                          display: true,
                          position: "bottom",
                        },
                      },
                    }}
                  />
                </CardContent>
              </Card>
              <div
            style={{marginLeft:"30px",marginTop:"30px"}}
              >

                <Box key={Math.random().toLocaleString()}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={results}
                    columns={columns}
                    pagination
                    paginationMode="client"
                    pageSize={10}
                    slots={{toolbar:CustomToolbar}}
                  />
                </Box>
                {/* <Button
                  style={{
                    marginTop: "2rem",
                   
                    background: Color.Color_C,
                    color: Color.Color_A,
                  }}
                  appearance="primary"
                  onClick={() => handleExportCSV(results, "Results")}
                >
                  Export Data
                </Button> */}
              </div>
             
              
            </div>
          )}
        </>
      ) : filter === 2 ? (
        <>
          {filterResults.length === 0 ? (
            <Typography variant="h6" textAlign={"center"}>
              No Data
            </Typography> // Use filterResults.length to check if there are no results
          ) : (
            <div
              style={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              <Container
                maxWidth="lg"
                style={{ marginTop: "4rem", marginBottom: "2rem" }}
              >
                <Box key={Math.random().toLocaleString()}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={filterResults}
                    columns={columns}
                    pagination
                    paginationMode="client"
                    pageSize={10}
                  />
                </Box>
               
              </Container>
              <Card
                sx={{
                  width: "40rem",
                  height: "20rem",
                  marginBottom: "2rem",
                  margin: "1rem",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Bar
                    data={{
                      labels: filterResults.map((result) =>
                        result.date.slice(0, 10)
                      ),
                      datasets: generateBarDatasets(filterResults), // Pass customColors array
                    }}
                    options={{
                      plugins: {
                        title: {
                          display: true,
                          text: filterResults.find(
                            (result) => result.field_id === setSubCategoriesUni
                          )?.field_name,
                        },
                        legend: {
                          display: true,
                          position: "bottom",
                        },
                      },
                    }}
                  />
                </CardContent>
              </Card>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}
