import React, { useState,useContext } from "react";
import styles from "./../../pages/sportsscience/science.module.css";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import Sidebar from "../../components/CustomSideNav";
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ViewAttendanceSession from "../attendance/ViewAttendanceSession";
import ViewInjury from "./injury/ViewInjury";
import ViewSession from "./session/ViewSession";
import ViewNutritionLogs from "../logs/ViewNutritionLogs";
import ViewSleep from "./sleep/ViewSleep";
import AttributionIcon from '@mui/icons-material/Attribution';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
export default function OtherMetrics() {
  const [selectedItem, setSelectedItem] = useState(1);
  const [filter, setFilter] = useState(1);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  let role = decode.roles;
  

  const roleActions = {
    Admin: [
       {
      title: "Attendance",
      icon: <AttributionIcon/>,
      filter: 1,
     
    },
    {
      title: "Illness Logs",
      icon: <MedicalServicesIcon/>,
      filter: 2,
     
    },
    {
      title: "Nutrition Logs",
      icon: <EmojiFoodBeverageIcon/>,
      filter: 3
      
    },
    {
      title: "Session Logs",
      icon: <RunCircleIcon/>,
      filter: 4
    
    },
    {
      title: "Sleep Logs",
      icon: <BedtimeIcon/>,
      filter:5
    
    },
    ],
    Coach: [
      {
        title: "Attendance",
        icon: <AttributionIcon/>,
        filter: 1,
       
      },
      {
        title: "Injury Logs",
        icon: <MedicalServicesIcon/>,
        filter: 2,
       
      },
      {
        title: "Nutrition Logs",
        icon: <EmojiFoodBeverageIcon/>,
        filter: 3
        
      },
      {
        title: "Session Logs",
        icon: <RunCircleIcon/>,
        filter: 4
      
      },
      {
        title: "Sleep Logs",
        icon: <BedtimeIcon/>,
        filter:5
      
      },
    ],
    Physiotherapist: [
      {
        title: "Attendance",
        icon: <AttributionIcon/>,
        filter: 1,
       
      },
      {
        title: "Injury Logs",
        icon: <MedicalServicesIcon/>,
        filter: 2,
       
      },
     
      {
        title: "Sleep Logs",
        icon: <BedtimeIcon/>,
        filter:5
      
      },
    ],
    Doctor: [
      {
        title: "Attendance",
        icon: <AttributionIcon/>,
        filter: 1,
       
      },
      {
        title: "Injury Logs",
        icon: <MedicalServicesIcon/>,
        filter: 2,
       
      },
     
      {
        title: "Sleep Logs",
        icon: <BedtimeIcon/>,
        filter:5
      
      },
    ],
    "Strength and Conditioning Specialist": [
      {
        title: "Attendance",
        icon: <AttributionIcon/>,
        filter: 1,
       
      },
     
      {
        title: "Session Logs",
        icon: <RunCircleIcon/>,
        filter: 4
      
      },
     
    ],
    "Exercise Physiology": [
      {
        title: "Attendance",
        icon: <AttributionIcon/>,
        filter: 1,
       
      },
     
    ],
    Anthropometrist: [
      {
        title: "Attendance",
        icon: <AttributionIcon/>,
        filter: 1,
       
      },
     
    ],
    Psychologist: [
      {
        title: "Attendance",
        icon: <AttributionIcon/>,
        filter: 1,
       
      },
      {
        title: "Injury Logs",
        icon: <MedicalServicesIcon/>,
        filter: 2,
       
      },
     
    ],
    "Biomechanics Specialist": [
      {
        title: "Attendance",
        icon: <AttributionIcon/>,
        filter: 1,
       
      },
    
    ],
    Nutritionist: [
      {
        title: "Attendance",
        icon: <AttributionIcon/>,
        filter: 1,
       
      },
      
      {
        title: "Nutrition Logs",
        icon: <EmojiFoodBeverageIcon/>,
        filter: 3
        
      },
      
    ]
  };
  
  let filtersdata = [
   
  ];
  
  for (const [roleKey, actions] of Object.entries(roleActions)) {
    if (role?.includes(roleKey)) {
      filtersdata = actions
      break;
    }
  }

 
  const handleLabelClick = (labelFilter) => {
   
    setFilter(labelFilter);
    setSelectedItem(labelFilter);
   
  };

  return (
    <>
      <DashBoardTopNav header={"Metrics"} />
      <Sidebar />
      
      <div className={styles.box_science_sports}>
        {filtersdata.map(({ title, icon, filter }) => (
          <label
            style={{ justifyContent: "space-between" }}
            className={`${styles.labelItem_sports} ${
              selectedItem === filter ? styles.selected : ""
            }`}
            key={filter}
            onClick={() => handleLabelClick(filter)}
          >
            
            {title}{icon}
            
          </label>
        ))}
      </div>
      <div className={styles.box_science_sports_m}>
        {
          filter === 1 ? (
            <ViewAttendanceSession />
          ) : filter === 2 ? (
            <ViewInjury />
          ) : filter === 3? (
            <ViewNutritionLogs />
          ):filter === 4 ? (<ViewSession/>):<ViewSleep/>
          }
      </div>
    </>
  );
}








