import React from 'react';
import { useNavigate ,useLocation} from 'react-router-dom';
import styles from './videoupload.module.css';

const SelectMode = ({file}) => {
  const navigate = useNavigate();


  const onButtonClick = (selectedMode) => {
    navigate(`/${selectedMode?.toLowerCase()}`, { state: { file } });
  };

  const Mode = ["Temporal"];

  return (
    <div className={styles["mainContainer"]}>
      <div className={styles["titleContainer"]}>
        <div className={styles["selectTitle"]}>Select Mode!</div>
        <div className={styles["buttonContainer"]}>
          {Mode.map((item, index) => (
            <button
              key={index}
              type="button"
              className={styles["modeButton"]}
              onClick={() => onButtonClick(item)}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectMode;
