import React, { useEffect, useState, useContext } from "react";
import { Button } from "rsuite";
import { HttpService } from "../services/http.service";
import { EVALUATION_RESULTS } from "../services/API";
import { Card, CardContent, Typography } from "@mui/material";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Label from "../components/Label";
import { AuthContext } from "../store/AuthContext";
import jwt_decode from "jwt-decode";

export default function WellnessInsightsAthlete() {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const [groupedResults, setGroupedResults] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(true);
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const athleteId = decode.athlete_id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const evaluationResponse = await HttpService.get(
          `${EVALUATION_RESULTS}?department=9&categories=99&athlete=${athleteId}`,{},false
        );
        const evaluationData = evaluationResponse.data.results;

        const groupedData = {};

        evaluationData.forEach((result, index) => {
          const values = {
            [result.field_name]: [
              result.boolean_value,
              result.choice_value,
              result.date_value,
              result.float_value,
              result.integer_value,
              result.remarks,
              result.string_value,
            ].filter(Boolean),
          };

          const rowId = `${athleteId}-${result.created_at}-${index}`;

          if (!groupedData[rowId]) {
            groupedData[rowId] = {
              id: rowId,
              athlete_id: athleteId,
              athlete_name: result.athlete_name, // Assuming this field is present in the response
              date: result.created_at.slice(0, 10),
              ...values,
            };
          } else {
            // Merge values for existing row
            Object.keys(values).forEach((key) => {
              if (!groupedData[rowId][key]) {
                groupedData[rowId][key] = [];
              }
              groupedData[rowId][key].push(...values[key]);
            });
          }
        });

        const processedData = Object.values(groupedData);

        setGroupedResults(processedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [athleteId]);

  const groupedData = {};

  groupedResults.forEach((item) => {
    const { athlete_id, athlete_name, date, ...fields } = item;
    if (!groupedData[date]) {
      groupedData[date] = {
        athlete_id,
        athlete_name,
        date,
        ...fields,
      };
    } else {
      Object.keys(fields).forEach((field) => {
        if (!groupedData[date][field]) {
          groupedData[date][field] = [];
        }
        if (Array.isArray(groupedData[date][field])) {
          groupedData[date][field].push(
            ...(Array.isArray(fields[field]) ? fields[field] : [fields[field]])
          );
        } else {
          groupedData[date][field] = [groupedData[date][field]];
          groupedData[date][field].push(
            ...(Array.isArray(fields[field]) ? fields[field] : [fields[field]])
          );
        }
      });
    }
  });

  const finalGroupedResults = Object.values(groupedData);

  function calculateAverageScore(row, fields) {
    const totalScore = fields.reduce((accumulator, field) => {
      const trimmedField = String(field).trim(); // Ensure field is a string before calling trim
      const fieldValue =
        row[trimmedField] && row[trimmedField][0] ? row[trimmedField][0] : 0;
      return accumulator + fieldValue;
    }, 0);

    const averageScore = fields.length > 0 ? totalScore / fields.length : 0;
    return averageScore;
  }
  // Function to normalize the score to be in the range [0, 1]
  function getNormalizedScore(score) {
    // Check if the score is defined and convert it to a number
    const numericScore = score && score[0] ? Number(score[0]) : 0;

    // Normalize the score to be in the range [0, 1]
    const normalizedScore = numericScore / 10;

    // Ensure the normalized score is within the valid range [0, 1]
    return Math.min(1, Math.max(0, normalizedScore));
  }

  const metricsData = [
    {
      name: "Overall Wellness",
      definition:
        "Overall Wellness provides a holistic view of an athlete's physical and mental well-being. It takes into account various factors such as sleep quality, hydration level, mood, soreness, stress, fatigue, and willingness to train. A higher overall wellness score indicates better overall health and readiness for physical activities.",
      calculation:
        "Normalized and averaged scores of multiple factors including Sleep, Hydration, Mood, Soreness, Stress, Fatigue, and Willingness to train.",
    },
    {
      name: "Physical Wellness",
      definition:
        "Physical Wellness assesses the athlete's physical condition, focusing on factors directly related to the body's state. It considers sleep duration and quality, hydration level, soreness, and fatigue. A higher physical wellness score indicates better physical health and recovery.",
      calculation:
        "Normalized and averaged scores of Sleep, Hydration, Soreness, and Fatigue.",
    },
    {
      name: "Training Readiness",
      definition:
        "Training Readiness measures an athlete's preparedness for physical training activities. It evaluates the quality of sleep, hydration level, and the athlete's willingness to engage in training. A higher training readiness score suggests the athlete is well-prepared for training.",
      calculation:
        "Average score of Sleep, Hydration, and Willingness to train.",
    },
    {
      name: "Positive Metrics",
      definition:
        "Positive Metrics represent factors that positively influence an athlete's well-being and readiness. It considers mood and the athlete's eagerness to engage in training activities. A higher positive metrics score indicates a positive mental state and high motivation for training.",
      calculation: "Average score of Mood and Willingness to train.",
    },
    {
      name: "Negative Metrics",
      definition:
        "Negative Metrics reflect factors that negatively impact an athlete's well-being. It includes soreness, stress, and fatigue. A higher negative metrics score indicates higher levels of discomfort, stress, or tiredness, potentially affecting training performance.",
      calculation: "Average score of Soreness, Stress, and Fatigue.",
    },
    {
      name: "Freshness Metrics",
      definition:
        "Freshness Metrics assess the athlete's freshness level for training activities. It is calculated by subtracting the negative metrics score from the positive metrics score and then multiplying it by the average training readiness score. A higher freshness metrics score suggests the athlete is in a fresh and optimal state for training.",
      calculation:
        "(Positive Metrics - Negative Metrics) * Average Training Readiness Score.",
    },
    {
      name: "Resilience",
      definition:
        "Resilience measures an athlete's ability to recover and adapt to challenges. It considers various factors including sleep quality, hydration level, willingness to train, soreness, stress, and fatigue. A higher resilience score indicates a higher ability to bounce back from physical and mental stressors.",
      calculation:
        "Normalized and averaged scores of Sleep, Hydration, Willingness to train, Soreness, Stress, and Fatigue.",
    },
    {
      name: "Energy Level",
      definition:
        "Energy Level indicates the athlete's available energy for physical activities. It is derived from the Fatigue metric, reflecting the athlete's level of tiredness. A higher energy level score suggests the athlete is less tired and has more energy available for training.",
      calculation: "Score from Fatigue.",
    },
    {
      name: "Mood State",
      definition:
        "Mood State evaluates the athlete's emotional well-being and mental state. It is determined by the athlete's reported mood. A higher mood state score indicates a positive emotional state and mental well-being.",
      calculation: "Score from Mood.",
    },
    {
      name: "Hydration Level",
      definition:
        "Hydration Level assesses the athlete's level of hydration. It is derived from the Hydration metric and represents the athlete's hydration status. A higher hydration level score suggests the athlete is well-hydrated, which is essential for overall health and performance.",
      calculation: "Normalized score from Hydration.",
    },
    // Add more metrics as needed
  ];
  const handleShow = () => {
    setShowMore(true);
  };
  const handleDoNotShow = () => {
    setShowMore(false);
  };
  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 100,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "overallWellness",
      headerName: "Overall Wellness",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // Define the fields and their corresponding weights (adjust these as needed)
        const fieldWeights = {
          Sleep: 1,
          Hydration: 1,
          Mood: 1,
          Soreness: 1,
          Stress: 1,
          "Fatigue ": 1, // Field name with extra space
          "Willingness to train": 1,
        };

        // Clean up the field names by removing extra spaces
        const cleanedParams = {};
        Object.keys(params.row).forEach((key) => {
          cleanedParams[key.trim()] = params.row[key];
        });

        // Calculate overall wellness score based on the provided fields and their weights
        const totalWeight = Object.values(fieldWeights).reduce(
          (sum, weight) => sum + weight,
          0
        );
        const overallScore = Object.keys(fieldWeights).reduce((sum, field) => {
          const fieldValue = cleanedParams[field]
            ? cleanedParams[field][0] / 10
            : 0; // Normalize score to be in the range [0, 1]
          return sum + (fieldWeights[field] / totalWeight) * fieldValue;
        }, 0);

        // Convert the overall score to a percentage and display it
        const overallWellnessPercentage = (overallScore * 100).toFixed(0) + "%";

        return <Label color="primary">{overallWellnessPercentage}</Label>;
      },
    },
    {
      field: "physicalWellness",
      headerName: "Physical Wellness",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const trimmedParams = { ...params };

        // Check if Fatigue and Soreness values are defined and non-empty before trimming
        trimmedParams.Fatigue = Array.isArray(params["Fatigue "])
          ? params["Fatigue "].map((value) =>
              value ? parseFloat(value.trim()) : 0
            )
          : [];
        trimmedParams.Soreness = Array.isArray(params.Soreness)
          ? params.Soreness.map((value) =>
              value ? parseFloat(value.trim()) : 0
            )
          : [];

        const sleep = parseFloat(trimmedParams.Sleep) || 0; // Handle undefined or NaN case
        const hydration = parseFloat(trimmedParams.Hydration) || 0; // Handle undefined or NaN case
        const averageSoreness = calculateAverage(trimmedParams.Soreness);
        const averageFatigue = calculateAverage(trimmedParams.Fatigue);

        // Function to calculate average from an array of numbers
        function calculateAverage(values) {
          if (Array.isArray(values) && values.length > 0) {
            const total = values.reduce((acc, val) => acc + val, 0);
            return total / values.length;
          }
          return 0;
        }

        // Calculate physical wellness score
        const physicalWellnessScore =
          (sleep + hydration + (5 - averageSoreness) + (5 - averageFatigue)) /
          4;

        // Convert the physical wellness score to a percentage and display it
        const physicalWellnessPercentage =
          ((physicalWellnessScore / 5) * 100).toFixed(0) + "%";

        return <Label color="success">{physicalWellnessPercentage}</Label>;
      },
    },
    {
      field: "trainingReadiness",
      headerName: "Training Readiness",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const scoreKeys = ["Sleep", "Hydration", "Willingness to train"];

        // Calculate the average of dynamically accessed scores
        const totalScore = scoreKeys.reduce((sum, key) => {
          const score =
            params.row[key] && params.row[key][0] ? params.row[key][0] : 0;
          return sum + score;
        }, 0);

        const averageScore = (totalScore / scoreKeys.length).toFixed(2);
        return <Label color="error">{averageScore}</Label>;
      },
    },
    {
      field: "positiveMetrics",
      headerName: "Positive Metrics",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const fieldsToInclude = ["Mood", "Willingness to train"];

        // Calculate the total score for included fields and count the number of fields
        const { totalScore, numberOfFields } = fieldsToInclude.reduce(
          (accumulator, field) => {
            const fieldValue =
              params.row[field] && params.row[field][0]
                ? params.row[field][0]
                : 0;
            accumulator.totalScore += fieldValue;
            accumulator.numberOfFields++;
            return accumulator;
          },
          { totalScore: 0, numberOfFields: 0 }
        );

        // Calculate the average score
        const averageScore =
          numberOfFields > 0 ? (totalScore / numberOfFields).toFixed(2) : 0;

        return <Label color="warning">{averageScore}</Label>;
      },
    },
    {
      field: "negativeMetrics",
      headerName: "Negative Metrics",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const fieldsToInclude = ["Soreness", "Stress", "Fatigue "]; // Note the space in the field name

        // Calculate the total score for included fields and count the number of fields
        const { totalScore, numberOfFields } = fieldsToInclude.reduce(
          (accumulator, field) => {
            // Remove spaces from field names and access the field values
            const trimmedField = field.trim();
            const fieldValue =
              params.row[trimmedField] && params.row[trimmedField][0]
                ? params.row[trimmedField][0]
                : 0;
            accumulator.totalScore += fieldValue;
            accumulator.numberOfFields++;
            return accumulator;
          },
          { totalScore: 0, numberOfFields: 0 }
        );

        // Calculate the average score
        const averageScore =
          numberOfFields > 0 ? (totalScore / numberOfFields).toFixed(2) : 0;

        return <Label color="primary">{averageScore}</Label>;
      },
    },
    {
      field: "freshnessMetrics",
      headerName: "Freshness Metrics",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // Define arrays of fields to include in positiveMetrics, negativeMetrics, and Training Readiness calculations
        const positiveFields = ["Mood", "Willingness to train"];
        const negativeFields = ["Soreness", "Stress", "Fatigue "]; // Note the space in the field name
        const scoreKeys = ["Sleep", "Hydration", "Willingness to train"];

        // Calculate the average of dynamically accessed scores
        const totalScore = scoreKeys.reduce((sum, key) => {
          const score =
            params.row[key] && params.row[key][0] ? params.row[key][0] : 0;
          return sum + score;
        }, 0);

        const averageScore = (totalScore / scoreKeys.length).toFixed(2);
        // Calculate positive metrics
        const positiveMetrics = calculateAverageScore(
          params.row,
          positiveFields
        );

        // Calculate negative metrics
        const negativeMetrics = calculateAverageScore(
          params.row,
          negativeFields
        );

        // Calculate Freshness Metrics
        const freshnessMetrics = (
          (positiveMetrics - negativeMetrics) *
          averageScore
        ).toFixed(2);

        return <Label color="success">{freshnessMetrics}</Label>;
      },
    },
    {
      field: "resilience",
      headerName: "Resilience",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // Define an array of fields to include in the resilience calculation
        const resilienceFields = [
          "Sleep",
          "Hydration",
          "Willingness to train",
          "Soreness",
          "Stress",
          "Fatigue ",
        ]; // Note the space in the field name

        // Calculate total score for the included fields
        const totalScore = resilienceFields.reduce((accumulator, field) => {
          const trimmedField = field.trim();
          const fieldValue =
            params.row[trimmedField] && params.row[trimmedField][0]
              ? params.row[trimmedField][0]
              : 0;

          // Normalize scores for soreness, stress, and fatigue to be in the range [0, 1]
          const normalizedValue =
            trimmedField === "Soreness" ||
            trimmedField === "Stress" ||
            trimmedField === "Fatigue "
              ? 1 - fieldValue / 10 // Assuming the scale is out of 10
              : fieldValue / 10; // Assuming the scale is out of 10

          return accumulator + normalizedValue;
        }, 0);

        // Calculate average score
        const averageScore = totalScore / resilienceFields.length;

        // Normalize the average score to be in the range [0, 1] and then scale it to be out of 10
        const resilience = ((averageScore * 10) / 10).toFixed(2);

        return <Label color="error">{resilience}</Label>;
      },
    },

    {
      field: "energyLevel",
      headerName: "Energy Level",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // Extract and normalize the Fatigue value
        const fatigueValue =
          params.row["Fatigue "] && params.row["Fatigue "][0]
            ? Number(params.row["Fatigue "][0])
            : 0;

        // Calculate average score for the included fields
        const averageScore = fatigueValue / 10; // Assuming the scale is out of 10

        // Normalize the average score to be in the range [0, 1] and then scale it to be a percentage
        const energyLevelPercentage = (averageScore * 100).toFixed(0) + "%";

        return <Label color="warning">{energyLevelPercentage}</Label>;
      },
    },

    {
      field: "moodState",
      headerName: "Mood State",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // Define an array of fields to include in the mood state calculation
        const moodStateFields = ["Mood"];

        // Calculate average score for the included fields
        const averageScore = calculateAverageScore(params.row, moodStateFields);

        // Normalize the average score to be in the range [0, 1] and then scale it to be a percentage
        const moodStatePercentage =
          ((averageScore / 10) * 100).toFixed(0) + "%";

        return <Label color="success">{moodStatePercentage}</Label>;
      },
    },

    {
      field: "hydrationLevel",
      headerName: "Hydration Level",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // Define the field name for hydration (ensure it matches your data structure)
        const hydrationFieldName = "Hydration";

        // Calculate hydration level based on the specified field
        const hydrationScore = getNormalizedScore(
          params.row[hydrationFieldName]
        );

        // Calculate the hydration level as a percentage
        const hydrationLevelPercentage =
          (hydrationScore * 100).toFixed(0) + "%";

        return <Label color="primary">{hydrationLevelPercentage}</Label>;
      },
    },

    {
      field: "Sleep",
      headerName: "Sleep",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Hydration",
      headerName: "Hydration",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Mood",
      headerName: "Mood",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Soreness",
      headerName: "Soreness",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Stress",
      headerName: "Stress",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Fatigue ",
      headerName: "Fatigue",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Willingness to train",
      headerName: "Willingness to train",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
    },
  ];
  
  return (
    <div>
      <Typography variant="h6" textAlign={"center"}>
        Wellness Insights
      </Typography>

      {loading ? (
        <Typography variant="h6" textAlign={"center"}>
          Loading...
        </Typography>
      ) : groupedResults?.length === 0 ? (
        <Typography variant="h6" textAlign={"center"}>
          No Data
        </Typography>
      ) : (
        <div
          style={{
            display: "flex",

            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          <Container
            maxWidth="lg"
            style={{ marginTop: "4rem", marginBottom: "2rem" }}
          >
            <Box key={Math.random().toLocaleString()}>
              <DataGrid
                rows={finalGroupedResults}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 50]}
                disableSelectionOnClick
                autoHeight
                getRowId={(row) => row.id}
              />
            </Box>
            {showMore === false ? (
              <Button
                style={{ marginTop: "2rem" }}
                appearance="ghost"
                color="green"
                onClick={handleShow}
              >
                Show Detials
              </Button>
            ) : (
              <Button
                style={{ marginTop: "2rem" }}
                appearance="ghost"
                color="red"
                onClick={handleDoNotShow}
              >
                Hide Detials
              </Button>
            )}
            {showMore === true ? (
              <>
                {metricsData.map((item) => {
                  return (
                    <Card
                      sx={{ marginTop: "2rem" }}
                      key={Math.random().toLocaleString()}
                    >
                      <CardContent>
                        <Typography variant="h5">{item.name}</Typography>
                        <Typography variant="body1" color="text.secondary">
                          <strong>Definition:</strong> {item.definition}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          <strong>Calculation:</strong> {item.calculation}
                        </Typography>
                      </CardContent>
                    </Card>
                  );
                })}
              </>
            ) : null}
          </Container>
        </div>
      )}
    </div>
  );
}
