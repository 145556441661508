export const Psychology = [
  {
    id: 1,
    Psychology: "Counselling",
  },
  {
    id: 2,
    Psychology: "Cognitive Behavioural Therapy",
  },
  {
    id: 3,
    Psychology: "Mental Imaginary",
  },
  {
    id: 4,
    Psychology: "Relaxation Therapy",
  },
  {
    id: 5,
    Psychology: "Transcendental Meditation",
  },
  {
    id: 6,
    Psychology: "Coping Strategies",
  },
  {
    id: 7,
    Psychology: "Concentration",
  },
  {
    id: 8,
    Psychology: "Self Talk",
  },
  {
    id: 9,
    Psychology: "Biofeedback",
  },
];

export const Sleep = [
  {
    id: 1,
    Sleep: "Sleep hygiene",
  },
  {
    id: 2,
    Sleep: "Bright light therapy",
  },
  {
    id: 3,
    Sleep: "Medications",
  },
  {
    id: 4,
    Sleep: "Melatonin",
  },
  {
    id: 5,
    Sleep: "Decrease intake of caffeine",
  },
];

export const Recovery = [
  {
    id: 1,
    Recovery: "Cryotherapy",
  },
  {
    id: 2,
    Recovery: "Load Monitoring Stratgeis",
  },
  {
    id: 3,
    Recovery: "Sauna Bath",
  },
  {
    id: 4,
    Recovery: "Steam Bath",
  },
  {
    id: 5,
    Recovery: "Massage",
  },
  {
    id: 6,
    Recovery: "Supplementation",
  },
];
