import React from "react";
import "./../landing/styles.css";
import {
  Form,
  Button,
  FlexboxGrid, RadioGroup, Radio
} from "rsuite";
import { HttpService } from "../../services/http.service";
import { CREATE_TEAM_API_URL, } from "../../services/API";
import CustomNotification from "../CustomNotification";
import { useContext } from "react";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import { Color } from "../../utils/Colors";
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

export default function TeamForm({  onSubmitSuccess,setCount}) {
  const [errorCount, setErrorCount] = React.useState(0);
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [isTeamManager,setIsTeamManager] = React.useState(false)

  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const formRef = React.useRef();

  const [formValue, setFormValue] = React.useState({
    name: "",
  
  });
  const teamManger = [
    'Yes',
    'No',
    
  ].map((item) => ({ label: item, value: item }));

  const handleSubmit = async () => {
    try {
      const formData = {
        ...formValue,
        is_team_manager: isTeamManager === "Yes"?true:false,
        account: decode?.account,
      };
      
      const response = await HttpService.post(CREATE_TEAM_API_URL, {}, formData, false);

      setResponse(response);
      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setError("Form Submitted"); // Update error state to indicate success
        setOpenModal(true);
        setFormValue({ name: "" });
        onSubmitSuccess();
        return; // Exit early to prevent further execution
      }
    } catch (e) {
      // console.warn(e)

      let errorMessage = "An error occurred"; // Default error message

      if ((e.response && e.response.data && e.response.data?.message) || e.response?.data?.detail) {
        errorMessage = e.response.data?.message || e.response?.data?.detail
      }

      setError(errorMessage);

      if (e.response && e.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
    <div >
      <FlexboxGrid>
        <FlexboxGrid.Item >

          <Form ref={formRef} onChange={setFormValue} formValue={formValue}>
            <TextField name="name" label="Team Name" />

            {decode.is_account_admin === true ?
        <div style={{ margin: "20px" }}>
          <label> Team Manager</label>
          <RadioGroup
            name="is_team_manager"
            
            style={{ width: 300 }}
            appearance="default"
            value={isTeamManager}
            onChange={value => setIsTeamManager(value)}
          >
            {teamManger.map(option => (
              <Radio  color={option.value==="Yes"?"green":"red"} key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </RadioGroup>
        </div>:null}
            <Button appearance="primary" onClick={handleSubmit} style={{ background: Color.Color_C, color: Color.Color_A, width: 300 }}
            >
              Create Team
            </Button>

            <CustomNotification
              open={openModal}
              errorCount={errorCount} 
              setOpen={setOpenModal}
              nav={"/profile_management"}
              message={
                error ? error : (res && res.data && res.data.message) || ""
              }
              title={error === "Form Submitted" ? "Success" : "Error"}
            />
          </Form>

        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>

  );
}
