import React, { useEffect } from "react";
import { Container, Typography } from "@mui/material";
import { Button, Modal, ButtonToolbar, Loader } from "rsuite";
import UserCustomDrawer from "../../../components/CustomDrawer";
import { HttpService } from "../../../services/http.service";
import {
  ATHLETE_PROFILE_API_URL,
  WOKRKOUT_SESSION_LOGS_API_URLS,
  USER_API_URL,
} from "../../../services/API";
import ViewScWorkout from "../strengthconditioningworkout/ViewScWorkout";
import ViewGeneralisedWorkout from "../generalisedworkout/ViewGeneralisedWorkout";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; 
import timeGridPlugin from '@fullcalendar/timegrid'
import CustomNotification from "../../../components/CustomNotification";
import { Color } from "../../../utils/Colors";
export default function ViewSession() {
  const [date, setDate] = React.useState()
  const [filter, setFilter] = React.useState(1);
  const [selectedSessionId, setSelectedSessionId] = React.useState(null);
  const [selectedEventInfo, setSelectedEventInfo] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openModal2, setOpenModal2] = React.useState([]);
  // const [isSidebarExpanded, setIsSidebarExpanded] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [session, setSession] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  // const [totalPages, setTotalPages] = React.useState(1); // Total number of pages
  const [count,setCount] = React.useState(0)
  const handleOpenModal2 = (eventInfo, selected) => {
    setSelectedEventInfo(eventInfo);
    setOpenModal2(selected);
  };

  const handleCloseModal2 = () => {
    setSelectedEventInfo(null);
    setOpenModal2(null);
  };

  const handleOpenModal = (sessionId, selected) => {
    setSelectedSessionId(sessionId);
    setOpenModal2(selected);
  };
  const handleCloseModal = () => {
    setSelectedSessionId(null);
    setOpenModal2(null);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${WOKRKOUT_SESSION_LOGS_API_URLS}?page=${currentPage}`,{}, false
        );

        const modifiedResult = await Promise.allSettled(
          response.data.results.map(async (result) => {
            try {
              // Fetch athlete data for each result
              const athleteResponse = await HttpService.get(
                ATHLETE_PROFILE_API_URL + result.athlete + "/",{}, false
              );
              const athleteName = athleteResponse.data.name;
              // console.log("Athlete Name:", athleteName);
              const userResponse = await HttpService.get(
                USER_API_URL + result.recorded_by + "/",{}, true
              );

              return {
                id: result.id,
                athlete_name: athleteName,
                user: userResponse.data.first_name,
                ...result,
              };
            } catch (error) {
              // Handle individual request error
              console.error(
                `Error fetching data for result ${result.id}:`,
                error
              );
              return null; // or handle the error in an appropriate way
            }
          })
        );

        // Extracting successful results and maintaining the order
        const filteredResults = modifiedResult
          .filter(
            (result) => result.status === "fulfilled" && result.value !== null
          )
          .map((result) => result.value);

        setSession(filteredResults);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        // setTotalPages(Math.ceil(response.data.count / 10));
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [currentPage,count]);

  const handleOpen = (date, key) => {
    setDate(date)
    setOpen(true);
    setPlacement(key);
  };

  



  return (
    <>
      
     
      <Container
       
        maxWidth="lg"
      >
        {session === undefined ? (
          <>
            <Loader content={"Loading"} />
            {error?.response?.status === 200 ? null : (
              <CustomNotification
                open={openModal}
                setOpen={setOpenModal}
                message={
                  error?.response?.status === 200 ? "Loading" : error?.message
                }
                title={error?.response?.status === 200 ? "Loading" : "Error"}
              />
            )}
          </>
        ) :
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin,timeGridPlugin]}
            initialView="dayGridMonth"
            weekends={true}
            // headerToolbar={{
            //   left: 'prev,next today',
            //   center: 'title',
            //   right: 'dayGridMonth,timeGridWeek,timeGridDay'
            // }}
            events={session?.map((event) => ({
              title: event.name,
              start: new Date(event.date),
              start_time: event.start_time,
              end_time: event.end_time,
              // end: new Date(event.date),
              description: event.description,
              athlete: event.athlete_name,
              recordedBy: event.user,
              workoutType: event.workout_type,
              id: event.id,
            }))}
            eventContent={(eventInfo) => {
              return (
                <div>
                  <p>
                    <Typography variant="body" fontWeight={"bold"}>
                    {eventInfo.event.title}
                    </Typography>{" "}
                   
                  </p>
                  <p>
                    <Typography
                      variant="body"
                      fontWeight={"bold"}
                      color={"red"}
                      textAlign={"center"}
                      onMouseEnter={() => {
                        setTimeout(() => handleOpenModal2(eventInfo, 2), 500); // Adjust the delay time as needed
                      }}

                    >
                      Description
                    </Typography>
                  </p>
                </div>
              );
            }}
            eventClick={(info) => handleOpenModal(info.event.id, 1)}
            dateClick={(arg) => handleOpen(arg.date, "right")}
            height="600px"
          />}
      </Container>

      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={placement}
        mode={"View SessionWorkout"}
        date={date}
        setCount={setCount}
      />
      <Modal size="lg" open={openModal2 === 1} onClose={handleCloseModal}>
        <ButtonToolbar style={{marginLeft:"2.5rem"}}>
          <Button appearance="default" style={{backgroundColor:Color.Color_C,color:Color.Color_AA}}onClick={() => setFilter(1)}>
            Strength & Conditioning Workout
          </Button>
          <Button appearance="default"style={{backgroundColor:Color.Color_C,color:Color.Color_AA}} onClick={() => setFilter(2)}>
            {" "}
            Generalised Workout
          </Button>
        </ButtonToolbar>

        {filter === 1 ? (
          <ViewScWorkout session={selectedSessionId} />
        ) : filter === 2 ? (
          <ViewGeneralisedWorkout session={selectedSessionId} />
        ) : null}
      </Modal>
      <Modal size="lg" open={openModal2 === 2} onClose={handleCloseModal2}  >
        {selectedEventInfo && (
          <>
            <h4> {selectedEventInfo.event.extendedProps?.athlete}</h4>

            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
              <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Session Name:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo?.event.title}</td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Description:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.description}</td>
                </tr>

                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Workout Type:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.workoutType}</td>
                </tr>
               
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Start Time:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.start_time}</td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>End Time:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.end_time}</td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>Recorded By:</th>
                  <td style={{ padding: "8px" }}>{selectedEventInfo.event.extendedProps?.recordedBy}</td>
                </tr>

              </tbody>
            </table>
          </>
        )}
        <br />
        <Modal.Footer>
          <Button onClick={handleCloseModal2} appearance="primary">
            Ok
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}
