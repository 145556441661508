import React from "react";
import { Form, Button, SelectPicker,FlexboxGrid,DatePicker } from "rsuite";
import { HttpService } from "../../services/http.service";
import {  EVENT_API_URLS,VENUE_API_URLS } from "../../services/API";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode"
import CustomNotification from "../CustomNotification";
import { useEffect,useContext } from "react";
import { Color } from "../../utils/Colors";
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} style={{width:"240px"}} {...rest} />
    </Form.Group>
  );
});

export default function AddEventForm({  onSubmitSuccess,setCount}) {
  const [errorCount, setErrorCount] = React.useState(0);
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [startDate, setStartDate] = React.useState();
  const [expDate, setExpDate] = React.useState();
  const [venues, setVenues] = React.useState();
  const [venue, setVenue] = React.useState();
  // const [user, setUser] = React.useState();
  // const [createdBy, setCreatedBy] = React.useState();
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const formRef = React.useRef();

  const [formValue, setFormValue] = React.useState({
    name: "",
    description:"",
    


  });
  useEffect(() => {
    const fetchVenue = async () => {
      try {
        const response = await HttpService.get(
          `${VENUE_API_URLS}?page=${currentPage}`,{}, false
        );

        const modifiedVenue = response.data.results.map((venue) => ({
          value: venue.id,
          label: venue.name,
        }));

        setVenues(modifiedVenue);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchVenue();
  }, [currentPage]);

 
  // console.log(user);

  const handleSubmit = async () => {
    const sd =
    startDate instanceof Date
      ? `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${startDate
          .getDate()
          .toString()
          .padStart(2, "0")}`
      : "";
  const ed =
    expDate instanceof Date
      ? `${expDate.getFullYear()}-${(expDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${expDate.getDate().toString().padStart(2, "0")}`
      : "";
    try {
      const formData = {
        ...formValue,
        account:decode.account,
        start_date:sd,
        end_date:ed,
        venue,
        created_by:decode.user_id
      };
      const response = await HttpService.post(EVENT_API_URLS, {}, formData,false);

      setResponse(response);

      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setError("Form Submitted"); // Update error state to indicate success
        setOpenModal(true);
        setFormValue({ // Clear the form values
          name: "",
          description: "",
        });
        onSubmitSuccess()
        
        return; // Exit early to prevent further execution
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
    <div >
      <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        <Form ref={formRef} onChange={setFormValue} formValue={formValue}>
       
        
          <SelectPicker
            label="Venue Name"
            data={venues}
            onSelect={(value) => setVenue(value)}
            style={{ width: 300 }}
          />
          <br />
          <br />
          
        <TextField name="name" label="Event Name" />
          <TextField name="description" label="Description" />
           
         
          <label>Start Date</label>
         
          <br />
          <DatePicker  style={{ width: 300 }} onChange={(value) => setStartDate(value)} />
          <br />
          <br />
          <label>End Date</label>
         
          <br />
          <DatePicker  style={{ width: 300 }} onChange={(value) => setExpDate(value)} />
          <br />
          <br />
          

            <Button appearance="primary" onClick={handleSubmit} style={{background:Color.Color_C,color:Color.Color_A,width:240}}>
            Create Event
            </Button>
          
          <CustomNotification
            open={openModal}
            setOpen={setOpenModal}
            nav={"/event/setup"}
            errorCount={errorCount}
            message={
              error ? error : (res && res.data && res.data.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>
    </div>
    
  );
}
