import React, { useContext,useEffect } from "react";
import "./../../components/landing/styles.css";
import {
  Form,
  Button,
  Schema,
  FlexboxGrid,
  SelectPicker,
  CheckPicker,
  // Message,
} from "rsuite";
import { NOTIFICATION_CREATE, USER_API_URL } from "../../services/API";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import { HttpService } from "../../services/http.service";
import CustomNotification from "../../components/CustomNotification";
import { Color } from "../../utils/Colors";
const { StringType } = Schema.Types;

const model = Schema.Model({
  content: StringType().isRequired("This field is required."),
});

const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

export default function AddNotification({ onSubmitSuccess,setCount }) {
  const formRef = React.useRef();
  const [errorCount, setErrorCount] = React.useState(0);
 
  const [users, setUser] = React.useState();
  const [record, setRecord] = React.useState();
  const [notificationType, setNotificationType] = React.useState([]);
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  const [formError, setFormError] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [formValue, setFormValue] = React.useState({
    content: "",
  });
  const data = ["Email", "SMS", "Push"].map((item) => ({
    label: item,
    value: item,
  }));
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await HttpService.get(
          `${USER_API_URL}?page=${currentPage}`,{}, true
        );

        const modifiedUsers = response.data.results.map((user) => ({
          value: user.id,
          label: user.first_name + " " + user.last_name,
        }));

        setUser(modifiedUsers);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchUser();
  }, [currentPage]);

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      console.error("Form Error", formError);
      return;
    }
    const recipients = Array.isArray(record) ? record.map(item => ({ recipient: item })) : [{ recipient: record }];

    try {
      const formData = {
        ...formValue,
        notification_type: notificationType.toLowerCase(),
        notification_recipients: recipients
        ,
        created_by: decode.user_id,
      };
      const response = await HttpService.post(
        NOTIFICATION_CREATE,
        {},
        formData,false
      );

      setResponse(response);

      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
       setCount(prevCount=>prevCount+1)
        setOpenModal(true);
        setError("Form Submitted");
        onSubmitSuccess();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        <Form
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}
          model={model}
        >
          <TextField name="content" label="Content" />

          <SelectPicker
            label="Select Notification Type"
            data={data}
            onSelect={(value) => setNotificationType(value)}
            style={{ width: 300 }}
          />
          <br />
          <br />
          <CheckPicker
            label="Select Recipient"
            data={users}
            onSelect={(value) => setRecord(value)}
            style={{ width: 300 }}
          />
        
            <Button appearance="primary" onClick={handleSubmit} 
              style={{background:Color.Color_C,color:Color.Color_A,width:300, marginTop:"2rem"}}>
              Submit
            </Button>
          <CustomNotification
          errorCount={errorCount}
            open={openModal}
            setOpen={setOpenModal}
            message={
              error ? error : (res && res.data && res.data.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
