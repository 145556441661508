import React from "react";
import "./../landing/styles.css";
import {
  Form,
  Button,
  Schema,
  FlexboxGrid,
  SelectPicker,
  DatePicker,
  
} from "rsuite";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import { HttpService } from "../../services/http.service";
import { USER_API_URL } from "../../services/API";
import CustomNotification from "../CustomNotification";
import { Color } from "../../utils/Colors";

const { StringType } = Schema.Types;
const model = Schema.Model({
  first_name: StringType().isRequired("This field is required."),
  last_name: StringType().isRequired("This field is required."),
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),

  password: StringType().isRequired("This field is required."),
  verifyPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }
      return true;
    }, "The two passwords do not match")
    .isRequired("This field is required."),
});

const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

export default function AppUserForm({ onSubmitSuccess,setCount }) {
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const [role, setRole] = React.useState();
  const [date, setDate] = React.useState();
  const [formValue, setFormValue] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    password: "",
    verifyPassword: "",
  });
  const [errorCount, setErrorCount] = React.useState(0);
  
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  // const data = ["YES", "NO"].map((item) => ({ label: item, value: item }));
  const roleData = ["Admin", "Athlete", "Staff", "Coach"].map((item) => ({
    label: item,
    value: item,
  }));
  const handleSubmit = async () => {
    const isValid = formRef.current.check();
    if (!isValid) {
      console.error("Form Error", formError);
      return;
    }

    try {
      const { verifyPassword, ...formDataWithoutVerify } = formValue;
      const dob =
        date instanceof Date
          ? `${date.getFullYear()}-${(date.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`
          : "";

      const formData = {
        ...formDataWithoutVerify,
        is_active: true,
        role,
        date_of_birth: dob,
      };
      // console.log(formData);
      const response = await HttpService.post(USER_API_URL, {}, formData,true);

      setResponse(response);

      if (response.status === 201) {
        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setOpenModal(true);
        setError("Form Submitted");
        onSubmitSuccess();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        <Form
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}
          model={model}
          autoComplete="off"
        >
          <TextField name="first_name" label="First Name" />

          <TextField name="last_name" label="Last Name" />

          <TextField name="email" label="Email" />

          <TextField name="mobile_no" label="Mobile Number" />

          <label>Date of Birth</label>
          <br />
          <br />
          <DatePicker
            format="yyyy-MM-dd" // Set the desired format
            onChange={(value) => setDate(value)}
          />
          <br />
          <br />
          <SelectPicker
            label="Role"
            data={roleData}
            onSelect={(value) => setRole(value)}
            style={{ width: 224 }}
          />
          <br />
          <br />
          <TextField name="password" label="Password"  />

          <TextField
            name="verifyPassword"
            label="Verify Password"
          
          />

          <br />
          <br />

          
            <Button appearance="primary" onClick={handleSubmit} style={{background:Color.Color_C,color:Color.Color_A,width:300}}>
            Create User
            </Button>
          <CustomNotification
            open={openModal}
            errorCount={errorCount}
            setOpen={setOpenModal}
            message={
              error ? error : (res && res.data && res.data.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
