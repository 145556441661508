import React, { useState } from 'react';
import axios from 'axios';
import { Button } from 'rsuite';
import { Typography } from '@mui/material';

const apiKey = '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';
// const apiUrl = 'https://api.openai.com/v1/engines/text-davinci-003/completions';




const SportChatGPT = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  

  const sendMessage = async () => {
    // Send user message
    setMessages([...messages, { role: 'user', content: input }]); // Updated structure

    // Call OpenAI GPT-3 API directly
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/engines/davinci/completions',
        {
          prompt: messages.map((message) => message.content).join('\n'),
          temperature: 0.7,
          max_tokens: 150,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
          },
        }
      );

      // Add OpenAI response to messages
      setMessages([...messages, { role: 'assistant', content: response.data.choices[0].text.trim() }]);
    } catch (error) {
      console.error('Error calling OpenAI:', error.message || error);
    }
  };

  return (
    <div>
      <div>
        {messages.map((message, index) => (
          <Typography key={index} >
            {message.text}
          </Typography>
        ))}
      </div>
      <div>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
        />
        <br/> <br/>
        <Button appearance='primary' size='xs' color='cyan' onClick={sendMessage}>Send</Button>
      </div>
    </div>
  );
};




export default SportChatGPT;
