import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Landing from "../components/landing/Landing";
import LoginScreen from "../utils/Login";
import { AuthContext } from "../store/AuthContext";
import Page404 from "../components/Page404";
import Dashboard from "../pages/dashboard/Dashboard";
import UserInfo from "../pages/userinfo/UserInfo";
import AdminProfile from "../pages/adminprofile/AdminProfile";
import AthleteProfile from "../pages/athleteprofile/AthleteProfile";
import Team from "../pages/team/Team";
import AccountDetails from "../pages/account/Account";
import SubscriptionDetails from "../pages/subscription/Subscription";
import ViewAttendanceSession from "../pages/attendance/ViewAttendanceSession";
import RecordAttendance from "../pages/attendance/RecordAttendance";
import SomethingBroke from "../components/SomethingBroke";
import SportScience from "../pages/sportsscience/SportScience";
import Report from "../pages/storytelling/reports/Report";
import Template from "../pages/builder/Template";
import AthleteDashboard from "../athlete/AthleteDashboard";
import DeleteAccount from "../components/DeleteAccount";
import Algotrithm from "../components/algorithm/Algotrithm";
import SportScienceDashboard from "../pages/storytelling/SportScienceDashboard";
import PageContainer from "../pages/PageContainer";
import CreateEvent from "../pages/event/CreateEvent";
import OtherMetrics from "../pages/othermetrics/OtherMetrics";
import Story from "../pages/storytelling/Story";
import Overall from "../pages/storytelling/reports/Overall";
import Periodisation from "../pages/periodisation/Periodisation";
import MonitoringDashboardAthlete from "../pages/dashboard/MonitoringDashboardAthlete";
import AccountInfo from "../pages/account/AccountInfo";
import BiomechAssessment from "../pages/assessment/BiomechAssessment";
import TemporalParameter from "../pages/assessment/TemporalParameter";
import SpatialParameter from "../pages/assessment/SpatialParameter";
function Navigation() {
  const authCtx = useContext(AuthContext);
  const user_role = authCtx.role;
 const token = authCtx.token


  return (
    <BrowserRouter >
      <>
        {token !== null ? (
          user_role?.includes("Athlete") ? (
            <Routes>
              <Route path="/dashboard" element={<AthleteDashboard />} />
              <Route path="/delete" element={<DeleteAccount />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="*" element={<Page404  loc={"/dashboard"} />}></Route>
              <Route path="/error" element={<SomethingBroke />}></Route>
              <Route path="/" element={<Landing />} />
             
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="*" element={<Page404  loc={"/dashboard"}/>}></Route>
              <Route path="/delete" element={<DeleteAccount />} />
              <Route path="/periodisation" element={<Periodisation />} />
              <Route path="/algorithm" element={<Algotrithm />} />
              <Route path="/overall" element={<Overall />} />
              <Route path="/error" element={<SomethingBroke />}></Route>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/monitoring" element={<MonitoringDashboardAthlete />} />
              <Route path="/user" element={<UserInfo />} />
              <Route path="/profile/admin" element={<AdminProfile />} />
              <Route path="/profile_management" element={<PageContainer />} />
              <Route path="/profile/athlete" element={<AthleteProfile />} />
              <Route path="/team" element={<Team />} />
              <Route path="/event/setup" element={<CreateEvent />} />
              <Route path="/account" element={<AccountDetails />} />
              <Route path="/subscription" element={<SubscriptionDetails />} />
              <Route path="/session" element={<ViewAttendanceSession />} />
              <Route path="/record" element={<RecordAttendance />} />
              <Route path="/logs" element={<OtherMetrics />} />
              <Route path="/account_details" element={<AccountInfo />} />
              <Route
                path="/story_telling/"
                element={<Story />}
              />
               <Route
                path="/biomechanics"
                element={<BiomechAssessment />}
              />
                <Route
                path="/temporal"
                element={<TemporalParameter />}
              />
               <Route
                path="/spatial"
                element={<SpatialParameter />}
              />
              <Route path="/reports" element={<Report />} />
              <Route path="/sportscience" element={<SportScience />} />
              <Route path="/sports_science_dashboard" element={<SportScienceDashboard />} />
              <Route path="/template_builder" element={<Template />} />
            </Routes>
          )
        ) : (
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/dashboard" element={<Landing />} />
            <Route path="/delete" element={<DeleteAccount />} />
            
            <Route path="/login" element={<LoginScreen />} />
            <Route path="*" element={<Page404  loc={"/"} />}></Route>
            <Route path="/error" element={<SomethingBroke />}></Route>
          </Routes>
        )}
      </>
    </BrowserRouter>
  );
}

export default function Root() {
  const [isTryingLogin, setIsTryingLogin] = useState(true);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    function fetchToken() {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        authCtx.authenticate(storedToken);
      }else {
        authCtx.logout(); // Log out if token is null
      }

      setIsTryingLogin(false);
    }

    fetchToken();
  }, [authCtx]);

  if (isTryingLogin) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "gray",
          zIndex: 999,
        }}
      >
        Loading...
      </div>
    );
  }

  return <Navigation />;
}
