import React from "react";
import "./../userinfo/UserInfo.css";
import { Button,  Loader, Modal } from "rsuite";
import UserCustomDrawer from "../../components/CustomDrawer";
import CustomNotification from "../../components/CustomNotification";
import { GetViewSessionData } from "../../components/custmomhooks/CustomHooks";
import { Container, Typography } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import RecordAttendance from "./RecordAttendance";
import { ATTENDANCE_SESSIONS_API_URLS } from "../../services/API";
import { HttpService } from "../../services/http.service";
export default function ViewAttendanceSession() {
  const [date, setDate] = React.useState();
  const [selectedEventInfo, setSelectedEventInfo] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedSessionId, setSelectedSessionId] = React.useState(null);
  const [openModal2, setOpenModal2] = React.useState([]);
  const [placement, setPlacement] = React.useState();
  const [viewSession, setViewSession] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1); // Total number of pages
  const [count,setCount] = React.useState(0)
  // const [isSidebarExpanded, setIsSidebarExpanded] = React.useState(false);
  // const handleSidebarToggle = () => {
  //   setIsSidebarExpanded(!isSidebarExpanded);
  // };
  const handleOpenModal = (sessionId, selected) => {
    setSelectedSessionId(sessionId);
    setOpenModal2(selected);
  };
  const handleCloseModal = () => {
    setSelectedSessionId(null);
    setOpenModal2(null);
  };
  const handleOpenModal2 = (eventInfo, selected) => {
    setSelectedEventInfo(eventInfo);
    setOpenModal2(selected);
  };

  const handleCloseModal2 = () => {
    setSelectedEventInfo(null);
    setOpenModal2(null);
  };
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${ATTENDANCE_SESSIONS_API_URLS}?page=${currentPage}`,{}, false
        );
        setViewSession(response.data.results);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [
    setViewSession,
    setCurrentPage,
    setTotalPages,
    setError,
    setOpenModal,
    currentPage,
    count
  ])

  // console.log(error)
  const handleOpen = (date, key) => {
    setDate(date)
    setOpen(true);
    setPlacement(key);
  };
  // const handlePageChange = (params) => {
  //   setCurrentPage(params.page + 1); // Page is 0-based, API expects 1-based
  // };

  // // console.log(viewSession)
  // const columns = [
  //   // { field: "id", headerName: "ID", width: 90 },
  //   {
  //     field: "name",
  //     headerName: "Name",
  //     width: 150,
  //     editable: true,
  //     headerAlign: "center",
  //     align: "center",
  //   },
  //   {
  //     field: "description",
  //     headerName: "Description",
  //     width: 150,
  //     editable: true,
  //     headerAlign: "center",
  //     align: "center",
  //   },
  //   {
  //     field: "date",
  //     headerName: "Date",
  //     width: 150,
  //     editable: true,
  //     headerAlign: "center",
  //     align: "center",
  //   },

  //   {
  //     field: "start_time",
  //     headerName: "Start Time",
  //     type: "time",
  //     width: 260,
  //     editable: true,
  //     headerAlign: "center",
  //     align: "center",
  //   },
  //   {
  //     field: "end_time",
  //     headerName: "End Time",
  //     sortable: true,
  //     width: 110,
  //     headerAlign: "center",
  //     align: "center",
  //   },
  //   {
  //     field: "actions",
  //     headerName: "Record Attendance",
  //     width: 150,
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params) => (
  //       <IconButton
  //         size="xs"
  //         icon={<PlusIcon />}
  //         color="red"
  //         appearance="primary"
  //         onClick={() => handleOpenModal(params.row.id)}
  //       ></IconButton>
  //     ),
  //   },
  // ];
// console.log(viewSession)
  return (
    <>
     

     
      <Container maxWidth="lg">
       
        {viewSession === undefined ? (
          <>
            <Loader content={"Loading"} />
            {error?.response?.status === 200 ? null : (
              <CustomNotification
                open={openModal}
                setOpen={setOpenModal}
                message={
                  error?.response?.status === 200 ? "Loading" : error?.message
                }
                title={error?.response?.status === 200 ? "Loading" : "Error"}
              />
            )}
          </>
        ) : (
         <FullCalendar

              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
              initialView="dayGridMonth"
              weekends={true}
              // headerToolbar={{
              //   left: 'prev,next today',
              //   center: 'title',
              //   right: 'dayGridMonth,timeGridWeek,timeGridDay'
              // }}
              events={viewSession?.map((event) => ({
                title: event.name,
                start: new Date(event.date),
                start_time: event.start_time,
                end_time: event.end_time,
                // end: new Date(event.date),
                description: event.description,
                // recordedBy: event.user,
                id: event.id,
              }))}
              eventContent={(eventInfo) => {
                return (
                  <div>
                    <p>
                      <Typography variant="body" fontWeight={"bold"}>
                        {eventInfo.event.title}
                      </Typography>{" "}

                    </p>
                    <p>
                      <Typography
                        variant="body"
                        fontWeight={"bold"}
                        color={"red"}
                        textAlign={"center"}
                        onMouseEnter={() => {
                          setTimeout(() => handleOpenModal2(eventInfo, 2), 500); // Adjust the delay time as needed
                        } }
                      >
                        Description
                      </Typography>
                    </p>
                  </div>
                );
              } }
              eventClick={(info) => handleOpenModal(info.event.id, 1)}
              dateClick={(arg) => handleOpen(arg.date, "right")}
              height="600px" />
        )}
      </Container>

      <UserCustomDrawer
        open={open}
        setOpen={setOpen}
        size={"sm"}
        placement={placement}
        mode={"Session"}
        date={date}
        setCount={setCount}
      />
      <Modal size="lg" open={openModal2===1} onClose={handleCloseModal}>
        <RecordAttendance session={selectedSessionId} />
      </Modal>
      <Modal size="lg" open={openModal2 === 2} onClose={handleCloseModal2}>
        {selectedEventInfo && (
          <>
            <h1>{selectedEventInfo?.event.title}</h1>

            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>
                    Description:
                  </th>
                  <td style={{ padding: "8px" }}>
                    {selectedEventInfo.event.extendedProps?.description}
                  </td>
                </tr>

                
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>
                    Start Time:
                  </th>
                  <td style={{ padding: "8px" }}>
                    {selectedEventInfo.event.extendedProps?.start_time}
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ padding: "8px", textAlign: "left" }}>
                    End Time:
                  </th>
                  <td style={{ padding: "8px" }}>
                    {selectedEventInfo.event.extendedProps?.end_time}
                  </td>
                </tr>
               
              </tbody>
            </table>
          </>
        )}
        <br />
        <Modal.Footer>
          <Button onClick={handleCloseModal2} appearance="primary">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
