import React from "react";
import "./landing/styles.css";
import { Drawer } from "rsuite";
import AppUserForm from "./form/AppUserForm";
import AdminForm from "./form/AdminForm";
import AthleteForm from "./form/AthleteForm";
import TeamForm from "./form/TeamForm";
import AccountForm from "./form/AccountForm";
import SubscriptionForm from "./form/SubscriptionForm";
import Notification from "../pages/notifications/Notification";
import Logs from "../pages/logs/Logs";
import Information from "../pages/userinformaton/Information";
import Attendance from "../pages/attendance/Attendance";
import AddAttendance from "../pages/attendance/AddAttendance";
import AddSession from "../pages/attendance/AddSession";
import AddNutritionLogs from "../pages/logs/AddNutritionLogs";
import AddTreatmentLogs from "../pages/logs/AddTreatmentLogs";
import CreateNotification from "../pages/notifications/CreateNotification";
import CreateEvent from "../pages/event/CreateEvent";
import AddNotification from "../pages/notifications/AddNotification";
import SendNotification from "../pages/notifications/SendNotification";
import ViewNotificationRecipents from "../pages/notifications/ViewNotificationRecipents";
import AddEventForm from "./form/AddEventForm";
import AddEventTypeForm from "./form/AddEventTypeForm";
import AddVenueForm from "./form/AddVenueForm";
import AddVenueSetupForm from "./form/AddVenueSetupForm";
import OtherMetrics from "../pages/othermetrics/OtherMetrics";
import RecordGenWorkoutForm from "./form/RecordGenWorkoutForm";
import RecordInjuryForm from "./form/RecordInjuryForm";
import RecordWorkoutSession from "./form/RecordWorkoutSession";
import RecordSleepForm from "./form/RecordSleepForm";
import RecordSCWorkoutForm from "./form/RecordSCWorkoutForm";
import EventResult from "./form/EventResult";
import EvaluationForm from "../pages/sportsscience/EvaluationForm";
import Subjective from "../pages/sportsscience/subcategories/physio/Subjective";
import Objective from "../pages/sportsscience/subcategories/physio/Objective";
import SpecialTest from "../pages/sportsscience/subcategories/physio/SpecialTest";
import ROM from "../pages/sportsscience/subcategories/physio/ROM";
import MMT from "../pages/sportsscience/subcategories/physio/MMT";
import RIC from "../pages/sportsscience/subcategories/physio/RIC";
import Health from "../pages/sportsscience/subcategories/strengthandconditioning/Health";
import Skill from "../pages/sportsscience/subcategories/strengthandconditioning/Skill";
import Aerobic from "../pages/sportsscience/subcategories/exercisephysiology/Aerobic";
import Kinematics from "../pages/sportsscience/subcategories/biomechanics/Kinematics";
import Kinetics from "../pages/sportsscience/subcategories/biomechanics/Kinetics";
import Isometric from "../pages/sportsscience/subcategories/biomechanics/Isometric";
import Dynamic from "../pages/sportsscience/subcategories/biomechanics/Dynamic";
import IsometricProfiling from "../pages/sportsscience/subcategories/biomechanics/IsometricProfiling";
import Athletic from "../pages/sportsscience/subcategories/sportspecific/Athletics";
import ExternalLoad from "../pages/sportsscience/subcategories/loadmonitoring/ExtrenalLoad";
import SportSpecificTID from "../pages/sportsscience/subcategories/talentidentification/SportSpecificTID";
import SportMedicine from "../pages/sportsscience/SportMedicine";
import Physiotherapy from "../pages/sportsscience/Physiotherapy";
import StrengthConditioning from "../pages/sportsscience/StrengthConditioning";
import SportsPsychology from "../pages/sportsscience/SportsPsychology";
import SportsNutrition from "../pages/sportsscience/SportsNutrition";
import Anthropometry from "../pages/sportsscience/Anthropometry";
import ExercisePhysiology from "../pages/sportsscience/ExercisePhysiology";
import Biomechanics from "../pages/sportsscience/Biomechanics";
import LoadMonitoring from "../pages/sportsscience/LoadMonitoring";
import TalentIdentification from "../pages/sportsscience/TalentIdentification";
import GeneralFitness from "../pages/sportsscience/GeneralFitness";
import SportSpecific from "../pages/sportsscience/SportSpecific";
import CentralInjury from "../pages/sportsscience/CentralInjury";
import Story from "../pages/storytelling/Story";
import UploadDocuments from "../pages/sportsscience/UploadDocuments";
import SportChatGPT from "../ai/SportsChatGpt";
import ExercisTherapyForm from "./form/ExercisTherapyForm";
import ElectrotherapyForm from "./form/ElectrotherapyForm";
import Appointment from "../pages/appointment/Appointment";
import AppPermissionURL from "./form/AppPermissionURL";
import AppPermissionForm from "./form/AppPermissionForm";
import RecordSCWorkoutFormActual from "./form/RecordSCWorkoutFormActual";

const styles = {
  drawerContentScrollable: {
    maxHeight: "100vh", // Adjust the value to control the maximum height
    overflowY: "auto",
  },
};

const formConfigurations = {
  User: { component: AppUserForm, title: "Create User Profile" },
  Athlete: { component: AthleteForm, title: "Create Athlete Profile" },
  Admin: { component: AdminForm, title: "Create Admin Profile" },
  Team: { component: TeamForm, title: "Create Team" },
  Account: { component: AccountForm, title: "Create Account" },
  Subscription: { component: SubscriptionForm, title: "Add Subscription" },
  Notification: { component: Notification, title: "Notification" },
  Logs: { component: Logs, title: "Logs" },
  UserInfo: { component: Information, title: "Information" },
  Attendance: { component: Attendance, title: "Attendance" },
  Record: { component: AddAttendance, title: "Record Attendance" },
  Session: { component: AddSession, title: "Add Session" },
  AddNutrtion: { component: AddNutritionLogs, title: "Add Nutrtion Logs" },
  AddTreatment: { component: AddTreatmentLogs, title: "Add Treatment Logs" },
  "Create Notification": {
    component: CreateNotification,
    title: "Notification Handler",
  },
  Event: { component: CreateEvent, title: "Event" },
  AddNotification: { component: AddNotification, title: "Add Notification" },
  PostNotification: { component: SendNotification, title: "Send Notification" },
  ViewLogs: {
    component: ViewNotificationRecipents,
    title: "Notification Logs",
  },
  "Add Event": { component: AddEventForm, title: "Add Event" },
  "Add Event Type": { component: AddEventTypeForm, title: "Add Event Type" },
  "Add Venue": { component: AddVenueForm, title: "Add Venue" },
  "Setup Venue": { component: AddVenueSetupForm, title: "Setup Venue" },
  OtherMetrics: { component: OtherMetrics, title: "Metrics" },
  "View Gen": {
    component: RecordGenWorkoutForm,
    title: "Add Generalised Workout",
  },
  "View Injury": { component: RecordInjuryForm, title: "Record injury" },
  "View SessionWorkout": {
    component: RecordWorkoutSession,
    title: "Record Session Workout",
  },
  "View Sleep": { component: RecordSleepForm, title: "Record Sleep" },
  "View SnC": {
    component: RecordSCWorkoutForm,
    title: "Record Strength & Conditioning Workout",
  },
  "Add Event Result": { component: EventResult, title: "Add Event Result" },
  Subjective: { component: Subjective, title: "Subjective" },
  Objective: { component: Objective, title: "Objective" },
  Assessment: { component: EvaluationForm, title: "Assessment" },
  Plan: { component: EvaluationForm, title: "Plan" },
  "Injury Incidence Rate": {
    component: EvaluationForm,
    title: "Injury Incidence",
  },
  Biochemistry: { component: EvaluationForm, title: "Biochemistry" },
  "Pain Assessment": { component: EvaluationForm, title: "Pain Assessment" },
  "On Observation": { component: EvaluationForm, title: "On Observation" },
  "On Palpation": { component: EvaluationForm, title: "On Palpation" },
  "Sensory Examination": {
    component: EvaluationForm,
    title: "Sensory Examination",
  },
  "Shoulder Joint - ROM": {
    component: EvaluationForm,
    title: "Shoulder Joint - ROM",
  },
  "Elbow Joint - ROM": {
    component: EvaluationForm,
    title: "Elbow Joint - ROM",
  },
  "Wrist Joint ROM": { component: EvaluationForm, title: "Wrist Joint ROM" },
  "Neck ROM": { component: EvaluationForm, title: "Neck ROM" },
  "Spinal ROM": { component: EvaluationForm, title: "Spinal ROM" },
  "Hip Joint - ROM": { component: EvaluationForm, title: "Hip Joint - ROM" },
  "Knee Joint - ROM": { component: EvaluationForm, title: "Knee Joint - ROM" },
  "Ankle Joint - ROM": {
    component: EvaluationForm,
    title: "Ankle Joint - ROM",
  },
  "Shoulder Joint - MMT": {
    component: EvaluationForm,
    title: "Shoulder Joint - MMT",
  },
  "Elbow Joint - MMT": {
    component: EvaluationForm,
    title: "Elbow Joint - MMT",
  },
  "Wrist Joint - MMT": {
    component: EvaluationForm,
    title: "Wrist Joint - MMT",
  },
  "Cervical Joint - MMT": {
    component: EvaluationForm,
    title: "Cervical Joint - MMT",
  },
  "Spinal Joint - MMT": {
    component: EvaluationForm,
    title: "Spinal Joint - MMT",
  },
  "Hip Joint - MMT": { component: EvaluationForm, title: "Hip Joint - MMT" },
  "Knee Joint - MMT": { component: EvaluationForm, title: "Knee Joint - MMT" },
  "Ankle Joint - MMT": {
    component: EvaluationForm,
    title: "Ankle Joint - MMT",
  },
  "Spinal Examination": {
    component: EvaluationForm,
    title: "Spinal Examination",
  },
  "Shoulder Joint - RIC": {
    component: EvaluationForm,
    title: "Shoulder Joint - RIC",
  },
  "Elbow Joint - RIC": {
    component: EvaluationForm,
    title: "Elbow Joint - RIC",
  },
  "Wrist Joint - RIC": {
    component: EvaluationForm,
    title: "Wrist Joint - RIC",
  },
  "Neck - RIC": { component: EvaluationForm, title: "Neck - RIC" },
  "Spine - RIC": { component: EvaluationForm, title: "Spine - RIC" },
  "Hip Joint - RIC": { component: EvaluationForm, title: "Hip Joint - RIC" },
  "Knee Joint - RIC": { component: EvaluationForm, title: "Knee Joint - RIC" },
  "Ankle Joint - RIC": {
    component: EvaluationForm,
    title: "Ankle Joint - RIC",
  },
  "Mckenzie Examination": {
    component: EvaluationForm,
    title: "Mckenzie Examination",
  },
  "Shoulder Joint - Special Test": {
    component: EvaluationForm,
    title: "Shoulder Joint - Special Test",
  },
  "Elbow Joint - Special Test": {
    component: EvaluationForm,
    title: "Elbow Joint - Special Test",
  },
  "Wrist Joint - Special Test": {
    component: EvaluationForm,
    title: "Wrist Joint - Special Test",
  },
  "Cervical - Special Test": {
    component: EvaluationForm,
    title: "Cervical - Special Test",
  },
  "Spine & Hip Joint - Special Test": {
    component: EvaluationForm,
    title: "Spine & Hip Joint - Special Test",
  },
  "Knee Joint - Special Test": {
    component: EvaluationForm,
    title: "Knee Joint - Special Test",
  },
  "Ankle Joint - Special Test": {
    component: EvaluationForm,
    title: "Ankle Joint - Special Test",
  },
  "Functional Examination": {
    component: EvaluationForm,
    title: "Functional Examination",
  },
  "Functional Movement Screening": {
    component: EvaluationForm,
    title: "Functional Movement Screening",
  },
  Tightness: { component: EvaluationForm, title: "Tightness" },
  Demographic: { component: EvaluationForm, title: "Demographic" },
  History: { component: EvaluationForm, title: "History" },
  "Special Test": { component: SpecialTest, title: "Special Test" },
  "Range Of Motion": { component: ROM, title: "Range Of Motion" },
  "Manual Muscle Testing": { component: MMT, title: "Manual Muscle Testing" },
  "Resisted Isometric Contraction": {
    component: RIC,
    title: "Resisted Isometric Contraction",
  },
  "Musculoskeletal History": {
    component: EvaluationForm,
    title: "Musculoskeletal History",
  },
  "General History": { component: EvaluationForm, title: "General History" },
  "COVID History": { component: EvaluationForm, title: "COVID History" },
  "Heart Related History": {
    component: EvaluationForm,
    title: "Heart Related History",
  },
  "Other History": { component: EvaluationForm, title: "Other History" },
  "Physical Examination": {
    component: EvaluationForm,
    title: "Physical Examination",
  },
  "Cardiovascular Examination": {
    component: EvaluationForm,
    title: "Cardiovascular Examination",
  },
  "Neurological Examination": {
    component: EvaluationForm,
    title: "Neurological Examination",
  },
  "Musculoskeletal Examination": {
    component: EvaluationForm,
    title: "Musculoskeletal Examination",
  },
  "Blood Examination": {
    component: EvaluationForm,
    title: "Blood Examination",
  },
  "Health & Fitness": { component: Health, title: "Health & Fitness" },
  Skill: { component: Skill, title: "Skill" },
  "Body Composition Analysis": {
    component: EvaluationForm,
    title: "Body Composition Analysis",
  },
  Cardiovascular: { component: EvaluationForm, title: "Cardiovascular" },
  Flexibility: { component: EvaluationForm, title: "Flexibility" },
  Balance: { component: EvaluationForm, title: "Balance" },
  "Musculoskeletal Endurance": {
    component: EvaluationForm,
    title: "Musculoskeletal Endurance",
  },
  "Speed Test": { component: EvaluationForm, title: "Speed Test" },
  "Agility Test": { component: EvaluationForm, title: "Agility Test" },
  Strength: { component: EvaluationForm, title: "Strength" },
  Power: { component: EvaluationForm, title: "Power" },
  "Running Based Anaerobic Sprint Test": {
    component: EvaluationForm,
    title: "Running Based Anaerobic Sprint Test",
  },
  Coordination: { component: EvaluationForm, title: "Coordination Test" },
  "Reaction Time": { component: EvaluationForm, title: "Reaction Time" },
  Aerobic: { component: Aerobic, title: "Aerobic" },
  "Lactate Analysis": { component: EvaluationForm, title: "Anaerobic" },
  "Cooper's Test": { component: EvaluationForm, title: "Cooper's Test" },
  "Harvard Step Test": {
    component: EvaluationForm,
    title: "Harvard Step Test",
  },
  "Yo Yo Intermittent Recovery Test": {
    component: EvaluationForm,
    title: "Yo Yo Intermittent Recovery Test",
  },
  Somatotype: { component: EvaluationForm, title: "Somatotype" },
  "Growth & Devlopment": {
    component: EvaluationForm,
    title: "Growth & Devlopment",
  },
  "Body Composition": { component: EvaluationForm, title: "Body Composition" },
  "Motivational Quality": {
    component: EvaluationForm,
    title: "Motivational Quality",
  },
  Anxiety: { component: EvaluationForm, title: "Anxiety" },
  "Psychological Performance Inventory": {
    component: EvaluationForm,
    title: "Psychological Performance Inventory",
  },
  Kinetics: { component: Kinetics, title: "Kinetics" },
  Kinematics: { component: Kinematics, title: "Kinematics" },
  Isokinetic: { component: EvaluationForm, title: "Isokinetic" },
  Spatial: { component: EvaluationForm, title: "Spatial" },
  Temporal: { component: EvaluationForm, title: "Temporal" },
  Isometric: { component: Isometric, title: "Isometric Profiling" },
  Dynamic: { component: Dynamic, title: "Dynamic Profiling" },
  "Squat Jump": { component: EvaluationForm, title: "Squat Jump" },
  "Drop Jump": { component: EvaluationForm, title: "Drop Jump" },
  "Counter Movement Jump": {
    component: EvaluationForm,
    title: "Counter Movement Jump",
  },
  "Isometric Profiling": {
    component: IsometricProfiling,
    title: "Isometric Profiling",
  },
  "Isometric Mid - Thigh Pull": {
    component: EvaluationForm,
    title: "Isometric Mid - Thigh Pull",
  },
  "Athletic Shoulder Test": {
    component: EvaluationForm,
    title: "Athletic Shoulder Test",
  },
  "Shoulder Joint - IP": { component: EvaluationForm, title: "Shoulder Joint" },
  "Elbow Joint - IP": { component: EvaluationForm, title: "Elbow Joint" },
  "Wrist Joint - IP": { component: EvaluationForm, title: "Wrist Joint" },
  "Neck Joint - IP": { component: EvaluationForm, title: "Cervical" },
  "Lower Back - IP": { component: EvaluationForm, title: "Low Back" },
  "Hip Joint - IP": { component: EvaluationForm, title: "Hip Joint" },
  "Knee Joint - IP": { component: EvaluationForm, title: "Knee Joint" },
  "Ankle Joint - IP": { component: EvaluationForm, title: "Temporal" },
  Athletics: { component: Athletic, title: "Athletics" },
  Archery: { component: EvaluationForm, title: "Archery" },
  Badminton: { component: EvaluationForm, title: "Badminton" },
  Boxing: { component: EvaluationForm, title: "Boxing" },
  Swimming: { component: EvaluationForm, title: "Swimming" },
  Shooting: { component: EvaluationForm, title: "Shooting" },
  "Lawn Tennis": { component: EvaluationForm, title: "Lawn Tennis" },
  "Table Tennis": { component: EvaluationForm, title: "Table Tennis" },
  "Weight Lifting": { component: EvaluationForm, title: "Weight Lifting" },
  Wrestling: { component: EvaluationForm, title: "Wrestling" },
  Cricket: { component: EvaluationForm, title: "Cricket" },
  Football: { component: EvaluationForm, title: "Football" },
  "Athletic Sprint KPI": {
    component: EvaluationForm,
    title: "Athletic Sprint KPI",
  },
  "Athletic Middle Distance KPI": {
    component: EvaluationForm,
    title: "Athletic Middle Distance KPI",
  },
  "Athletic Long Distance KPI": {
    component: EvaluationForm,
    title: "Athletic Long Distance KPI",
  },
  "Athletic Jump KPI": {
    component: EvaluationForm,
    title: "Athletic Jump KPI",
  },
  "Athletic Throw KPI": {
    component: EvaluationForm,
    title: "Athletic Throw KPI",
  },
  "Dietry Allowance": { component: EvaluationForm, title: "Dietry Allowance" },
  "Internal Load": { component: EvaluationForm, title: "Internal Load" },
  "Wellness Monitoring": {
    component: EvaluationForm,
    title: "Wellness Monitoring",
  },
  "External Load": { component: ExternalLoad, title: "External Load" },
  "Skill Related": { component: EvaluationForm, title: "Skill Related" },
  "Strength & Conditioning Related": {
    component: EvaluationForm,
    title: "Strength & Conditioning Related",
  },
  "Motor Quality": { component: EvaluationForm, title: "Motor Quality" },
  "Sports Specific Skills": {
    component: SportSpecificTID,
    title: "Sports Specific Skills",
  },
  "Athletics Talent Identification": {
    component: EvaluationForm,
    title: "Athletics",
  },
  "Archery Talent Identification": {
    component: EvaluationForm,
    title: "Archery",
  },
  "Badminton Talent Identification": {
    component: EvaluationForm,
    title: "Badminton",
  },
  "Boxing Talent Identification": {
    component: EvaluationForm,
    title: "Boxing",
  },
  "Swimming Talent Identification": {
    component: EvaluationForm,
    title: "Swimming",
  },
  "Shooting Talent Identification": {
    component: EvaluationForm,
    title: "Shooting",
  },
  "Lawn Tennis Talent Identification": {
    component: EvaluationForm,
    title: "Lawn Tennis",
  },
  "Table Tennis Talent Identification": {
    component: EvaluationForm,
    title: "Table Tennis",
  },
  "Weight Lifting Talent Identification": {
    component: EvaluationForm,
    title: "Weight Lifting",
  },
  "Wrestling Talent Identification": {
    component: EvaluationForm,
    title: "Wrestling",
  },
  "Young Age": { component: EvaluationForm, title: "Young Age" },
  Adult: { component: EvaluationForm, title: "Adult" },
  "Old Age": { component: EvaluationForm, title: "Old Age" },
  Condition: { component: EvaluationForm, title: "Condition" },
  "Key Information": { component: EvaluationForm, title: "Key Information" },
  "Sports Medicine": { component: SportMedicine, title: "Sports Medicine" },
  Physiotherapy: { component: Physiotherapy, title: "Physiotherapy" },
  "Strength & Conditioning": {
    component: StrengthConditioning,
    title: "Strength & Conditioning",
  },
  "Sports Psychology": {
    component: SportsPsychology,
    title: "Sports Psychology",
  },
  "Sports Nutrition": { component: SportsNutrition, title: "Sports Nutrition" },
  Anthropometry: { component: Anthropometry, title: "Anthropometry" },
  "Exercise Physiology": {
    component: ExercisePhysiology,
    title: "Exercise Physiology",
  },
  Biomechanics: { component: Biomechanics, title: "Biomechanics" },
  "Load Monitoring": { component: LoadMonitoring, title: "Load Monitoring" },
  "Talent Identification": {
    component: TalentIdentification,
    title: "Talent Identification",
  },
  "General Fitness": { component: GeneralFitness, title: "General Fitness" },
  "Sports Specific KPI": {
    component: SportSpecific,
    title: "Sports Specific KPI",
  },
  "Injury Surveillance": {
    component: CentralInjury,
    title: "Injury Surveillance",
  },
  "Story": {
    component: Story,
    title: "Story",
  }, "Upload Documents": {
    component: UploadDocuments,
    title: "Upload Documents",
  },"GPT": {
    component: SportChatGPT,
    title: "Ask Me Anything Related To Sports",
  },
  "AddExerciseTherapy": {
    component: ExercisTherapyForm,
    title: "Exercise Therapy Form",
  },
  "AddElectroTherapy": {
    component: ElectrotherapyForm,
    title: "Electrotherapy Form",
  },
  "Appointment": {
    component: Appointment,
    title: "Appointment",
  },
  "APPPermissionURL": {
    component: AppPermissionURL,
    title: "App Permission URL",
  },"APPPermission": {
    component: AppPermissionForm,
    title: "App Permission",
  },
  "View SnCActual": {
    component: RecordSCWorkoutFormActual,
    title: "Actual Record Strength & Conditioning Workout",
  },
};

const UserCustomDrawer = ({
  size,
  placement,
  open,
  setOpen,
  mode,
  dept,
  cat,
  athlete,
  session,
  date,
  setCount
}) => {
  const { component: FormComponent, title } = formConfigurations[mode] || {};

  const handleFormSuccess = () => {
    setOpen(false);
  };
  // console.log("drawer", athlete)
  return (
    <>
      <Drawer
        size={size}
        placement={placement}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Drawer.Header>
          <Drawer.Title>{title}</Drawer.Title>

        </Drawer.Header>
        <Drawer.Body style={styles.drawerContentScrollable}>
          {FormComponent && (
            <FormComponent
              dept={dept}
              cat={cat}
              mode={mode}
              athlete_id={athlete}
              session_id={session}
              onSubmitSuccess={handleFormSuccess}
              selectedDate = {date}
              setCount={setCount}
            />
          )}

        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default UserCustomDrawer;
