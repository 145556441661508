import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/AuthContext";
import { BASE_URL, USER_API_URL } from "../../services/API";
import jwt_decode from "jwt-decode";
import { HttpService } from "../../services/http.service";
import { DataGrid } from "@mui/x-data-grid";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box } from "@mui/material";

export default function UserDetails() {
  const [user, setUser] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);
  const authCtx = useContext(AuthContext);
 

  const token = authCtx.token;
  let decode = "";

  if (token) {
    decode = jwt_decode(token);
  }
  const status = 'connected'
  function CustomFooterStatusComponent(props) {
    return (
      <Box sx={{ p: 1, display: 'flex' }}>
        <FiberManualRecordIcon
          fontSize="small"
          sx={{
            mr: 1,
            color: props.status === 'connected' ? '#4caf50' : '#d9182e',
          }}
        />
        Status {props.status}
      </Box>
    );
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${USER_API_URL}${decode.user_id}/`,
          {},
          true
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [decode.user_id]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const baseURL = BASE_URL(decode.account);
        const mediaURL = `${baseURL}/media`;
        const imageUrl = `${mediaURL}${user.profile_photo_url}`;
        const response = await fetch(imageUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const blob = await response.blob();
          const objectURL = URL.createObjectURL(blob);
          setImageProfile(objectURL);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };
    if (user && decode.account && token) {
      fetchImageUrls();
    }
  }, [user, decode.account, token]);

  const capitalize = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const columns = [
    // { field: "image", headerName: "Image", width: 150, renderCell: (params) => <img src={params.value} alt="photo" /> },

    { field: "name", headerName: "Name", width: 150 },
    { field: "email", headerName: "Email", width: 350 },
    { field: "date_of_birth", headerName: "Date of Birth", width: 150 },
    { field: "mobile_no", headerName: "Mobile No", width: 150 },
    { field: "is_active", headerName: "Is Active", width: 150, renderCell: (params) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FiberManualRecordIcon
          fontSize="small"
          sx={{
            mr: 1,
            color: params.value === 'Yes' ? '#4caf50' : '#d9182e',
          }}
        />
        {params.value}
      </Box>
    )
  },
  { field: "is_active", headerName: "Is Active", width: 150, renderCell: (params) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FiberManualRecordIcon
        fontSize="small"
        sx={{
          mr: 1,
          color: params.value === 'Yes' ? '#4caf50' : '#d9182e',
        }}
      />
      {params.value}
    </Box>
  )
},
    { field: "role", headerName: "Role", width: 150 },
  ];

  const rows = [
    {
      id: 1,
      //   image: imageProfile,
      name: user?.first_name + " " + user?.last_name,
      email: user?.email,
      date_of_birth: user?.date_of_birth,
      mobile_no: user?.mobile_no,
      is_active: user?.is_active ? "Yes" : "No",
      is_account_admin: user?.is_account_admin ? "Yes" : "No",
      role: user?.role,
    },
  ];

  return (
    <>

      <div>

        <div >
          <DataGrid rows={rows} columns={columns} slots={{
            footer: CustomFooterStatusComponent,
          }}
            slotProps={{
              footer: { status },
            }} />
        </div>
      </div>
    </>
  );
}
