import React, { useState, useEffect, useContext } from "react";
import styles2 from "./injury.module.css";
import SimpleBarChart from "./SimpleBar";
// import CircularProgress from "./CircularProgress";
import { HttpService } from "../../services/http.service";
import {
  ATHLETE_PROFILE_API_URL,
  BASE_URL,
  WOKRKOUT_SESSION_LOGS_API_URLS,
  RECORD_INJURY_API_URLS,
  SC_WORKOUT_API_URLS,
  GENERALISED_WORKOUT_LOGS_API_URLS,
  TREATMENT_LOGS_API_URLS,
  NUTRITION_LOGS_API_URLS,
  EVALUATION_RESULTS,
  TEAM_API_URL,
  USER_API_URL,
  EVALUATION_CATEGORIES,
  ATTENDANCE_RECORDS_API_URLS
} from "../../services/API";
import UserInfoIcon from '@rsuite/icons/UserInfo';
import AdminIcon from '@rsuite/icons/Admin';
import PeoplesIcon from '@rsuite/icons/Peoples';
import MemberIcon from '@rsuite/icons/Member';
import HealingIcon from '@mui/icons-material/Healing';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import PercentIcon from '@mui/icons-material/Percent';
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../store/AuthContext";
import { Button, Modal } from "rsuite";
import { Color } from "../../utils/Colors";
// import Brand from "./../../assets/image/brand2.png";
import { Doughnut } from "react-chartjs-2";
const BarplotDatasetTransitionDemo = ({
  data,
  data2,
  label,
  label2,
  downloadData,
  injuryData,
  LineChart
}) => {
  const [imageUrlMap, setImageUrlMap] = useState({});
  const [athleteData, setAthleteData] = useState([]);
  const [session, setSessionData] = useState([]);
  const [injury, setInjuryData] = useState([]);
  const [sc, setSC] = useState([]);
  const [gen, setGen] = useState([]);
  const [treatment, setTreatment] = useState([]);
  const [nutriton, setNutriton] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [attendance,setAttendance] =useState([])
const [team,setTeam] = useState([])
const [users,setUser] = useState([])
const [customTest,setCustomTest]=useState([])
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const decode = token ? jwt_decode(token) : "";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${USER_API_URL}`,
          {},
          true
        );

        setUser(response.data.results);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${ATTENDANCE_RECORDS_API_URLS}`,
          {},
          false
        );

        setAttendance(response.data.results);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchData();
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${TEAM_API_URL}`,
          {},
          false
        );

        setTeam(response.data.results);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}`,
          {},
          false
        );

        setAthleteData(response.data.results);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await HttpService.get(
          `${WOKRKOUT_SESSION_LOGS_API_URLS}`,
          {},
          false
        );

        setSessionData(response.data.results);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchSessionData();
  }, []);

  useEffect(() => {
    const fetchInjuryData = async () => {
      try {
        const response = await HttpService.get(
          `${RECORD_INJURY_API_URLS}`,
          {},
          false
        );

        setInjuryData(response.data.results);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchInjuryData();
  }, []);
  useEffect(() => {
    const fetchSCData = async () => {
      try {
        const response = await HttpService.get(
          `${SC_WORKOUT_API_URLS}`,
          {},
          false
        );

        setSC(response.data.results);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchSCData();
  }, []);
  useEffect(() => {
    const fetchGen = async () => {
      try {
        const response = await HttpService.get(
          `${GENERALISED_WORKOUT_LOGS_API_URLS}`,
          {},
          false
        );

        setGen(response.data.results);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchGen();
  }, []);
  useEffect(() => {
    const fetchTreatment = async () => {
      try {
        const response = await HttpService.get(
          `${TREATMENT_LOGS_API_URLS}`,
          {},
          false
        );

        setTreatment(response.data.results);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchTreatment();
  }, []);
  useEffect(() => {
    const fetchNutriton = async () => {
      try {
        const response = await HttpService.get(
          `${NUTRITION_LOGS_API_URLS}`,
          {},
          false
        );

        setNutriton(response.data.results);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchNutriton();
  }, []);
  useEffect(() => {
    const fetchEValuation = async () => {
      try {
        const response = await HttpService.get(
          `${EVALUATION_RESULTS}`,
          {},
          false
        );

        setEvaluation(response.data.results);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchEValuation();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
         EVALUATION_CATEGORIES,{}, false
        );
        const filteredObjects = response.data.results
        .filter(obj => obj.id > 132)
        .map(obj => {
          return {
            cat: obj.id,
            name: obj.name,
            dept: obj.department
          };
        });
      
      setCustomTest(filteredObjects);
      } catch (error) {
        console.error("Error fetching fields:", error);
      }
    };

    fetchData();
  },[]);
  useEffect(() => {
    if (athleteData.length > 0) {
      const fetchImageUrls = async () => {
        const promises = athleteData.map(async (item) => {
          try {
            const baseURL = BASE_URL(decode.account);
            const mediaURL = `${baseURL}/media`;
            const imageUrl = `${mediaURL}${item.profile_photo_url}`;

            const response = await fetch(imageUrl, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (response.ok) {
              const blob = await response.blob();
              const objectURL = URL.createObjectURL(blob);
              return { [item.id]: objectURL };
            } else {
              throw new Error("Network response was not ok.");
            }
          } catch (error) {
            console.error(
              "There was a problem with the fetch operation:",
              error
            );
            return { [item.id]: null };
          }
        });

        const imageUrlMap = await Promise.all(promises);
        const mergedImageUrlMap = Object.assign({}, ...imageUrlMap);
        setImageUrlMap(mergedImageUrlMap);
      };
      fetchImageUrls();
    }
  }, [athleteData, decode.account, token]);
  const activeUserCount = users.filter(user => user.is_active).length;
  // Count the number of inactive users
  const inactiveUserCount = users.length - activeUserCount;

  // Data for the donut chart
  const data_active = {
    labels: ['Active Users', 'Inactive Users'],
    datasets: [
      {
        data: [activeUserCount, inactiveUserCount],
        backgroundColor: ['green', 'red'], // Green for active users, Red for inactive users
        hoverBackgroundColor: ['green', 'red'], // Use same colors for hover effect
      },
    ],
  };
  const adminUserCount = users.filter(user => user.is_account_admin).length;
  // Count the number of non-admin users
  const nonAdminUserCount = users.length - adminUserCount;

  // Data for the donut chart
  const data_admin = {
    labels: ['Admin Users', 'Non-Admin Users'],
    datasets: [
      {
        data: [adminUserCount, nonAdminUserCount],
        backgroundColor: ['lightblue', 'orange'], // lightblue for admin users, Orange for non-admin users
        hoverBackgroundColor: ['lightblue', 'orange'], // Use same colors for hover effect
      },
    ],
  };
  const AthletePhoto = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedAthletes, setSelectedAthletes] = useState([]);
    const [isInjuredModal, setIsInjuredModal] = useState(false);

    // Sort athleteData based on injury status
    const sortedAthletes = athleteData.sort((a, b) => {
      return a.injury_status - b.injury_status;
    });

    const openModal = (injured) => {
      setSelectedAthletes(
        injured
          ? sortedAthletes.filter((athlete) => athlete.injury_status)
          : sortedAthletes.filter((athlete) => !athlete.injury_status)
      );
      setIsInjuredModal(injured);
      setShowModal(true);
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          width: "60%",
        }}
      >
        <div>
          {sortedAthletes
            .filter((athlete) => athlete.injury_status)
            .slice(0, 2)
            .map((athlete, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: 10,
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={imageUrlMap[athlete.id]}
                    alt="Profile"
                    height={50}
                    width={50}
                    style={{ borderRadius: "50%", marginLeft: "20px" }}
                  />
                  <label
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 10,
                      background: "red",
                      color: "white",
                      borderRadius: "2rem",
                      marginTop: 5,
                      width: 100,
                    }}
                  >
                    {athlete.name}
                  </label>
                </div>
              </div>
            ))}
          {sortedAthletes.filter((athlete) => athlete.injury_status).length >
            2 && (
            <Button
              appearance="primary"
              size="xs"
              style={{
                backgroundColor: "red",
                marginLeft: "25px",
                marginBottom: "10px",
              }}
              onClick={() => openModal(true)}
            >
              See More
            </Button>
          )}
        </div>

        <div>
          {sortedAthletes
            .filter((athlete) => !athlete.injury_status)
            .slice(0, 2)
            .map((athlete, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: 10,
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={imageUrlMap[athlete.id]}
                    alt="Profile"
                    height={50}
                    width={50}
                    style={{ borderRadius: "50%", marginLeft: "20px" }}
                  />
                  <label
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 10,
                      background: "green",
                      color: "white",
                      borderRadius: "2rem",
                      marginTop: 5,
                      width: 100,
                    }}
                  >
                    {athlete.name}
                  </label>
                </div>
              </div>
            ))}
          {sortedAthletes.filter((athlete) => !athlete.injury_status).length >
            2 && (
            <Button
              appearance="primary"
              size="xs"
              style={{
                backgroundColor: "green",
                marginLeft: "25px",
                marginBottom: "10px",
              }}
              onClick={() => openModal(false)}
            >
              See More
            </Button>
          )}
        </div>

        {showModal && (
          <Modal
            overflow={true}
            open={showModal}
            size={"sm"}
            onClose={() => setShowModal(false)}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Modal.Header>
              {isInjuredModal ? "Injured Athletes" : "Non-Injured Athletes"}
            </Modal.Header>
            {selectedAthletes
              ?.reduce((rows, item, index) => {
                if (index % 3 === 0) {
                  rows.push([]);
                }
                rows[rows.length - 1].push(item);
                return rows;
              }, [])
              .map((row, rowIndex) => (
                <div
                  key={rowIndex}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    // marginLeft: 20,
                  }}
                >
                  {row.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 20,
                        padding: 10,
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={imageUrlMap[item.id]} // Use item.id instead of params.row.id
                        alt="Profile"
                        height={100}
                        width={100}
                        style={{ borderRadius: "10%" }}
                      />
                      <label
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: 10,
                          background:
                            item.injury_status === true ? "pink" : "lightgreen",
                          color: Color.Color_C,
                          borderRadius: "2rem",
                          marginTop: 5,
                        }}
                      >
                        {item.name}
                      </label>
                    </div>
                  ))}
                </div>
              ))}
          </Modal>
        )}
      </div>
    );
  };

  return (
    <div className={styles2.box_science_sports9}>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          // backgroundColor: "#e1f5fe",
          borderRadius: "8px",

          marginBottom: "10px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
          }}
        >
          <img
            src={Brand}
            height={100}
            style={{
              padding: "10px",
              borderRadius: "18px",
              marginRight: "80px",
            }}
            width={100}
            alt="Your Company Logo"
          />
          <h4 style={{ marginTop: "14px", marginRight: "80px" }}>
            Company Name
          </h4>
          <h6 style={{ marginTop: "20px", marginRight: "80px" }}>
            Super Administrator Email Address
          </h6>
          <h6 style={{ marginTop: "20px", marginRight: "20px" }}>Plan</h6>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
          }}
        >
          <h6 style={{ marginTop: "20px", marginLeft: "280px" }}>
            Subscription Duration
          </h6>
          <h6 style={{ marginTop: "20px", marginLeft: "120px" }}>
            Renewal Date
          </h6>
        </div>
      </div> */}
      <div
        style={{
          display: "flex",
          marginTop:"-80px",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid white",
          minHeight: "200px", 
          borderRadius: "8px",
          marginBottom: "10px",
         
        }}
      >
        <div
          style={{
           
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid red",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"300px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}
        >

       <UserInfoIcon/>   Users <label>{data[0].count}</label> 
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid green",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"300px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
            
          }}
        >
 <AdminIcon/>   Admin <label>{data[1].count}</label> 
          
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid orange",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"300px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
            
          }}
        >

<PeoplesIcon/>   Athletes <label>{data[2].count}</label> 
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid purple",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"300px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
            
          }}
        >

<MemberIcon/>   Teams <label>{team === undefined ? 0 : team?.length}</label> 
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop:"-80px",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid white",
          minHeight: "200px", 
          borderRadius: "8px",
          marginBottom: "10px",
         
        }}
      >
        <div
          style={{
           
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid green",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"500px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}
        >

       <DirectionsRunIcon/>   {injuryData[0].title}<label>{injuryData[0].count}</label> 
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid red",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"500px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
            
          }}
        >
 <HealingIcon/>   {injuryData[1].title}<label>{injuryData[1].count}</label> 
          
        </div>
        <div
          style={{
           
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid green",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"500px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}
        >

       <PercentIcon/>   Injury Index<label>{injuryData[1].count !==undefined ? `${(parseInt(injuryData[1].count)/parseInt(injuryData[1].total)*100).toFixed(2)}%` : 0 }</label> 
        </div>
       
      </div>
      <div style={{display:"flex", flexDirection:"row"}}>
      <div style={  {
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            borderBottom: "1px solid black",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"700px",
            height:"350px",
            marginTop:"-20px",
            marginRight:"20px",
            padding:"5px",
           
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}>
            <label>User Traffic</label>
      {LineChart}
      </div>
      <div style={  {
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            borderBottom: "1px solid black",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"300px",
            height:"350px",
            marginTop:"-20px",
            marginRight:"20px",
           
            padding:"5px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}>
            <label>Organizer User Summary</label>
            <Doughnut data={data_active} width={"100px"} height={"100px"}/>
      </div>
      <div style={  {
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            borderBottom: "1px solid black",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"300px",
            height:"350px",
            marginTop:"-20px",
            marginRight:"20px",
            padding:"5px",
           
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}>
            <label>Super Admin Summary</label>
            <Doughnut data={data_admin} width={"100px"} height={"100px"}/>
      </div>
      </div>
      
      
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#e1f5fe",
          borderRadius: "8px",
        }}
      >
       
        <AthletePhoto />
        <CircularProgress data={injuryData} type={3} />
      </div>
      <CircularProgress data={data} type={1} downloadData={downloadData} /> */}

      <div
        style={{
          display: "flex",
          marginTop:"0px",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid white",
          minHeight: "200px", 
          borderRadius: "8px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
           
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid red",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"500px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}
        >
        
          <label >Total Session Planned</label>  <label >
            {session?.length > 0 ? session?.length : 0}
          </label>
        </div>
        <div
          style={{
           
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid blue",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"500px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}
        >
         
          <label >Total Injuries Recorded</label> <label >
            {injury?.length > 0 ? injury?.length : 0}
          </label>
        </div>
        <div
          style={{
           
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid green",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"500px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}
        >
         
          <label >Total S & C Session Logs</label> <label >
            {sc?.length > 0 ? sc?.length : 0}
          </label>
        </div>
        </div>
        <div
        style={{
          display: "flex",
          marginTop:"-80px",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid white",
          minHeight: "200px", 
          borderRadius: "8px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
           
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid red",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"500px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}
        >
         
          <label >
            Total Generalised Workout Logs
          </label> <label >
            {gen?.length > 0 ? gen?.length : 0}
          </label>
        </div>
        <div
          style={{
           
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid blue",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"500px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}
        >
         
          <label >Total Treatment Logs</label> <label >
            {treatment?.length > 0 ? treatment?.length : 0}
          </label>
        </div>
        <div
          style={{
           
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid green",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"500px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}
        >
         
          <label >Total Nutrition Logs</label> <label >
            {nutriton?.length > 0 ? nutriton?.length : 0}
          </label>
        </div>
        </div>
        <div
        style={{
          display: "flex",
          marginTop:"-80px",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid white",
          minHeight: "200px", 
          borderRadius: "8px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
           
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid red",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"500px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}
        >
         
          <label >Total Parameter Tested</label> <label >
            {evaluation?.length > 0 ? evaluation?.length : 0}
          </label>
        </div>
        <div
          style={{
           
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid blue",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"500px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}
        >
         
          <label >Total Custom Test Battery</label> <label >
            {customTest?.length > 0 ? customTest?.length : 0}
          </label>
        </div>
        <div
          style={{
           
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid green",
            fontSize:"20px",
            borderRadius:"8px",
            alignItems:"center",
            width:"500px",
            height:"40px",
            marginTop:"20px",
            marginRight:"20px",
            padding:"10px",
            boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            
          }}
        >
         
          <label >Total Attendance Recorded</label> <label >
            {attendance?.length > 0 ? attendance?.length : 0}
          </label>
        </div>
      </div>
   
      <SimpleBarChart data={data2} label={label2} />

      <div className={styles2.mobile}>
        <SimpleBarChart data={data} label={label} />

        <SimpleBarChart data={data2} label={label2} />

        <SimpleBarChart data={injuryData} label={"Injury Startification"} />
      </div>
    </div>
  );
};

export default BarplotDatasetTransitionDemo;
