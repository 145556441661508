import React, { useEffect } from "react";
import { HttpService } from "../../services/http.service";
import { SelectPicker } from "rsuite";
import { DataGrid } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import {
  SC_WORKOUT_API_URLS,
  WOKRKOUT_SESSION_LOGS_API_URLS,
  USER_API_URL,
  ATHLETE_PROFILE_API_URL,
} from "../../services/API";
import { Bar, Line } from "react-chartjs-2";
import styles from "./injury.module.css";

export default function Progression({ isSidebarExpanded }) {
  const [chart, setChart] = React.useState(1);
  const [selectedField, setSelectedField] = React.useState("heart_rate");
  const [selectedWorkoutType, setSelectedWorkoutType] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [athlete, setAthlete] = React.useState([]);
  const [athletes, setAthletes] = React.useState([]);
  const [scSession, setSCSession] = React.useState();
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1); // Total number of pages
// console.log("hi")
  useEffect(() => {
    const fetchAthlete = async () => {
      try {
        const response = await HttpService.get(
          `${ATHLETE_PROFILE_API_URL}?page=${currentPage}`,
          {},
          false
        );

        const modifiedAthlete = response.data.results.map((ath) => ({
          value: ath.id,
          label: ath.name,
        }));

        setAthletes(modifiedAthlete);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAthlete();
  }, [currentPage]);
  console.log(scSession)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${SC_WORKOUT_API_URLS}?page=${currentPage}`,
          {},
          false
        );

        const modifiedResult = await Promise.allSettled(
          response.data.results.map(async (result) => {
            try {
              // Fetch athlete data for each result
              const sessionResponse = await HttpService.get(
                WOKRKOUT_SESSION_LOGS_API_URLS + result.session + "/",
                {},
                false
              );

              const sessionName = sessionResponse.data.name;
              const userResponse = await HttpService.get(
                USER_API_URL + result.recorded_by + "/",
                {},
                true
              );
              let athlete_id = "";
              if (athlete === sessionResponse.data.athlete) {
                athlete_id = athlete;
              }
              const athleteResponse = await HttpService.get(
                ATHLETE_PROFILE_API_URL + athlete_id + "/",
                {},
                false
              );

              return {
                id: result.id,
                session_name: sessionName,
                user: userResponse.data.first_name,
                athlete_name: athleteResponse.data.name,
                created_at: result.created.slice(0, 10),
                ...result,
              };
            } catch (error) {
              // Handle individual request error
              console.error(
                `Error fetching data for result ${result.id}:`,
                error
              );
              return null; // or handle the error in an appropriate way
            }
          })
        );

        // Extracting successful results and maintaining the order
        const filteredResults = modifiedResult
          .filter(
            (result) => result.status === "fulfilled" && result.value !== null
          )
          .map((result) => result.value);

        setSCSession(filteredResults);
        setCurrentPage(response.data.next ? currentPage + 1 : currentPage);
        setTotalPages(Math.ceil(response.data.count / 10));
        setError(null);
      } catch (error) {
        setError(error);
        setOpenModal(true);
      }
    };

    fetchData();
  }, [currentPage, athlete]);

  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1); // Page is 0-based, API expects 1-based
  };

  function getUniqueWorkout(data) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [{ value: null, label: "Select Workout Type" }];
    }

    const uniqueWorkouts = Array.from(
      new Set(data.map((result) => result.workout_type))
    ).map((w) => ({
      value: w,
      label: w,
    }));

    // Add a null option for resetting the selection
    return [{ value: null, label: "Select Workout Type" }, ...uniqueWorkouts];
  }
  function getUniqueDates(data) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [{ value: null, label: "Select Date" }];
    }

    const uniqueDates = Array.from(
      new Set(data.map((result) => result.created_at.slice(0, 10)))
    ).map((date) => ({
      value: date,
      label: date,
    }));

    // Add a null option for resetting the selection
    return [{ value: null, label: "Select Date" }, ...uniqueDates];
  }

  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "session_name",
      headerName: "Session Name",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "created_at",
      headerName: "Date",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "athlete_name",
      headerName: "Athlete Name",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "workout_type",
      headerName: "Exercise Name",
      width: 350,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "heart_rate",
      headerName: "HR",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_heart_rate",
      headerName: " Achieved HR",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "rate_of_percieved_exertion",
      headerName: "RPE",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_rate_of_percieved_exertion",
      headerName: "Achieved RPE",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "distance",
      headerName: "Distance",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_distance",
      headerName: "Achieved Distance",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_duration",
      headerName: "Achieved Duration",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "calories_burned",
      headerName: "Calories Burned",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_calories_burned",
      headerName: "Achieved Calories Burned",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },


    {
      field: "frequency",
      headerName: "Sets",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_frequency",
      headerName: "Achieved Sets",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "intensity",
      headerName: "Intensity",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_intensity",
      headerName: "Achieved Intensity",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "repetition",
      headerName: "Repetition",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_repetition",
      headerName: "Achieved Repetition",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "volume",
      headerName: "Weight in kg",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_volume",
      headerName: "Achieved Weight in Kg",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "user",
      headerName: "Recorded By",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
  ];
  const actualColumns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "workout_type",
      headerName: "Exercise Name",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_heart_rate",
      headerName: "HR",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_rate_of_percieved_exertion",
      headerName: "RPE",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_distance",
      headerName: "Distance",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_duration",
      headerName: "Duration",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "achieved_calories_burned",
      headerName: "Calories Burned",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "session_name",
      headerName: "Session Name",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_frequency",
      headerName: "Sets",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_intensity",
      headerName: "Intensity",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_repetition",
      headerName: "Repetition",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "achieved_volume",
      headerName: "Weight in Kg",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "user",
      headerName: "Recorded By",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
  ];
  const getRandomDarkColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 8)]; // Generate darker colors
    }
    return color;
  };
  function generateBarDatasets(
    data,
    selectedField,
    selectedWorkoutType,
    selectedDate
  ) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [];
    }

    const filteredData = data.filter((result) => {
      const matchDate =
        selectedDate === null ||
        new Date(result.created_at).toLocaleDateString() ===
        new Date(selectedDate).toLocaleDateString();

      const matchWorkoutType =
        selectedWorkoutType === null ||
        result.workout_type.trim().toLowerCase() ===
        selectedWorkoutType.trim().toLowerCase();

      return matchDate && matchWorkoutType;
    });

    const dateColors = {};
    const uniqueDates = Array.from(
      new Set(filteredData.map((result) => result.created_at.slice(0, 10)))
    );


    uniqueDates.forEach((date) => {
      // Use dark blue for target values
      dateColors[date] = "#1a237e";
    });

    const datasetData = filteredData
      .filter((result) => result[selectedField] !== undefined)
      .map((result) => ({
        x: result.created_at.slice(0, 10),
        [selectedField]: result[selectedField],
        [`achieved_${selectedField}`]: result[`achieved_${selectedField}`], // Include achieved values
      }));

    const targetColor = "#1a237e"; // Blue for target values
    const achievedColor = "#e91e63"; // Red for achieved values

    return [
      {
        label:
          selectedField === "frequency"
            ? "Sets"
            : selectedField === "volume"
              ? "Weight in kg"
              : selectedField === "heart_rate"
                ? "Heart Rate"
                : selectedField === "rate_of_percieved_exertion"
                  ? "RPE"
                  : selectedField === "repetition"
                    ? "Repetition" : selectedField === "intensity"
                      ? "Intensity"
                      : selectedField, // Label for target values
        data: datasetData.map((result) => result[selectedField]),
        backgroundColor: targetColor,
        borderColor: targetColor,
        borderWidth: 1,
        barThickness: 20,
        borderRadius: 30,
        borderSkipped: "bottom",
      },
      {
        label: `Achieved ${selectedField === "frequency"
            ? "Sets"
            : selectedField === "volume"
              ? "Weight in kg"
              : selectedField === "heart_rate"
                ? "Heart Rate"
                : selectedField === "rate_of_percieved_exertion"
                  ? "RPE"
                  : selectedField === "repetition"
                    ? "Repetition" : selectedField === "intensity"
                      ? "Intensity"
                      : selectedField}`, // Label for achieved values
        data: datasetData.map((result) => result[`achieved_${selectedField}`]),
        backgroundColor: achievedColor,
        borderColor: achievedColor,
        borderWidth: 1,
        barThickness: 20, // Maintain same thickness as target values
        borderRadius: 30,
        borderSkipped: "bottom",
      },
    ];
  }

  function generateLineDatasets(
    data,
    selectedField,
    selectedWorkoutType,
    selectedDate
  ) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [];
    }

    const filteredData = data.filter((result) => {
      const matchDate =
        selectedDate === null ||
        new Date(result.created_at).toLocaleDateString() ===
        new Date(selectedDate).toLocaleDateString();

      const matchWorkoutType =
        selectedWorkoutType === null ||
        result.workout_type.trim().toLowerCase() ===
        selectedWorkoutType.trim().toLowerCase();

      return matchDate && matchWorkoutType;
    });

    const dateColors = {};
    const uniqueDates = Array.from(
      new Set(filteredData.map((result) => result.created_at.slice(0, 10)))
    );

    uniqueDates.forEach((date) => {
      dateColors[date] = getRandomDarkColor(); // Use getRandomDarkColor() for border color
    });

    const datasetData = filteredData
      .filter((result) => result[selectedField] !== undefined)
      .map((result) => {
        // Add this log to inspect the result object
        return {
          x: result.created_at.slice(0, 10),
          y: result[selectedField],
          achievedY: result[`achieved_${selectedField}`], // Include achieved values
        };
      });
    const targetColor = "#1a237e"; // Blue for target values
    const achievedColor = "#e91e63"; // Red for achieved values
    return [
      {
        label:
          selectedField === "frequency"
            ? "Sets"
            : selectedField === "volume"
              ? "Weight in kg"
              : selectedField === "heart_rate"
                ? "Heart Rate"
                : selectedField === "rate_of_percieved_exertion"
                  ? "RPE"
                  : selectedField === "repetition"
                    ? "Repetition"
                    : selectedField,
        data: datasetData.map((result) => ({ x: result.x, y: result.y })),
        backgroundColor: achievedColor, // Blue color for actual data
        borderColor: achievedColor, // Blue color for actual data
        borderWidth: 1,
        fill: false, // Set to false to make it a line chart
      },
      {
        label: `Achieved ${selectedField === "frequency"
            ? "Sets"
            : selectedField === "volume"
              ? "Weight in kg"
              : selectedField === "heart_rate"
                ? "Heart Rate"
                : selectedField === "rate_of_percieved_exertion"
                  ? "RPE"
                  : selectedField === "repetition"
                    ? "Repetition"
                    : selectedField}`,
        data: datasetData.map((result) => ({ x: result.x, y: result.achievedY })),
        backgroundColor: targetColor, // Red color for achieved data
        borderColor: targetColor, // Red color for achieved data
        borderWidth: 1,
        fill: false, // Set to false to make it a line chart
      },
    ];
  }

  const fieldOptions = [
    { value: "volume", label: "Weight in kg" },
    { value: "heart_rate", label: "Heart Rate" },
    { value: "rate_of_percieved_exertion", label: "RPE" },
    { value: "distance", label: "Distance" },
    // { value: "duration", label: "Duration of Training" },
    { value: "calories_burned", label: "Calories Burned" },
    { value: "intensity", label: "Intensity" },
    { value: "repetition", label: "Repitition" },
    { value: "frequency", label: "Sets" },
  ];
  const chartOption = [
    { value: 1, label: "Bar Chart" },
    { value: 2, label: "Line Chart" },
  ];
  return (
    <div >
      <div className={styles.belownav}>

        <SelectPicker
          label={athlete?.length <= 0 ? "Select Athlete" : athlete?.name}
          data={athletes}
          onSelect={(value) => setAthlete(value)}
          className={styles.selectpicker3}
        />
        {athlete?.length <= 0 ? null :
          <><SelectPicker
            label={selectedField?.length <= 0 ? "Select Field" : selectedField?.label}
            data={fieldOptions}
            onSelect={(value) => setSelectedField(value)}
            className={styles.selectpicker3} /><SelectPicker
              label={selectedDate?.length <= 0 ? "Select Date" : selectedDate?.label}
              data={getUniqueDates(scSession)}
              onSelect={(value) => setSelectedDate(value)}
              className={styles.selectpicker3} /><SelectPicker
              label={selectedWorkoutType?.length <= 0
                ? "Select Workout"
                : selectedWorkoutType?.label}
              data={getUniqueWorkout(scSession)}
              onSelect={(value) => setSelectedWorkoutType(value)}
              className={styles.selectpicker3} /><SelectPicker
              label={chart?.length <= 0 ? "Select Chart" : chart?.label}
              data={chartOption}
              onSelect={(value) => setChart(value)}
              className={styles.selectpicker3} /></>}

      </div>


      {scSession === undefined || scSession?.length <= 0 ? (
        <div className={styles.box_science_sports7}>
          <Typography variant="body1" align="center">
            No Data
          </Typography>
        </div>
      ) : (
        <div className={styles.box_science_sports7}>
          <div>
            <DataGrid
              style={{ borderRadius: "1rem" }}
              key={Math.random().toLocaleString()}
              rows={scSession}
              columns={columns}
              pagination
              paginationMode="server"
              onPageChange={handlePageChange} // Handle page changes
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 4,
                  },
                },
              }}
              pageSizeOptions={[4]} // Number of rows per page
              page={currentPage - 1} // Current page (0-based)
              paginationComponentProps={{
                // Customize the appearance of the pagination component
                disableNext: currentPage === totalPages,
                disablePrev: currentPage === 1,
              }}
            />

          </div>
          <div>
            {chart === 1 ? (
              <Bar
                style={{ display: "flex", justifyContent: "center", flex: 1 }}
                data={{
                  labels: scSession
                    ? scSession.map((result) => result.created_at.slice(0, 10))
                    : [],
                  datasets: generateBarDatasets(
                    scSession,
                    selectedField,
                    selectedWorkoutType,
                    selectedDate
                  ),
                }}
                options={{
                  scales: {
                    x: {
                      stacked: false,
                      title: {
                        display: true,
                        text: 'Date', // Label for the x-axis
                        font: {
                          size: 16,
                          weight: 'bold'
                        }
                      }
                    },
                    y: {
                      stacked: false,
                      title: {
                        display: true,
                        text: 'Value', // Label for the y-axis
                        font: {
                          size: 16,
                          weight: 'bold'
                        }
                      }
                    }
                  },
                  plugins: {
                    title: {
                      display: true,
                      text: "Progression",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                  },
                }}
              />
            ) : chart === 2 ? (
              <Line
                data={{
                  labels: scSession
                    ? scSession.map((result) => result.created_at.slice(0, 10))
                    : [],
                  datasets: generateLineDatasets(
                    scSession,
                    selectedField,
                    selectedWorkoutType,
                    selectedDate
                  ),
                }}
                options={{
                  scales: {
                    x: {
                      stacked: false,
                      title: {
                        display: true,
                        text: 'Date', // Label for the x-axis
                        font: {
                          size: 16,
                          weight: 'bold'
                        }
                      }
                    },
                    y: {
                      stacked: false,
                      title: {
                        display: true,
                        text: 'Value', // Label for the y-axis
                        font: {
                          size: 16,
                          weight: 'bold'
                        }
                      }
                    }
                  },
                  plugins: {
                    title: {
                      display: true,
                      text: "Progression",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                  },
                }}
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}
