import React, { useContext, useEffect } from "react";
import { Form, Button, FlexboxGrid, SelectPicker } from "rsuite";
import { HttpService } from "../../services/http.service";
import { SC_WORKOUT_API_URLS } from "../../services/API";
import CustomNotification from "../CustomNotification";
import { AuthContext } from "../../store/AuthContext";
import jwt_decode from "jwt-decode";
import { Color } from "../../utils/Colors";
const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} style={{width:"240px"}} {...rest} />
    </Form.Group>
  );
});

export default function RecordSCWorkoutFormActual({ onSubmitSuccess, session_id ,setCount}) {
  const [errorCount, setErrorCount] = React.useState(0);
  const [workType, setWorkType] = React.useState([])
  const [workTypes, setWorkTypes] = React.useState([])
  const [prevSC,setPrevSc]=React.useState([])
  const [error, setError] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [res, setResponse] = React.useState();
  // const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  // const [formError, setFormError] = React.useState({});

  let decode = "";
  if (token) {
    decode = jwt_decode(token);
  } else {
    decode = "";
  }
  const formRef = React.useRef();

  const [formValue, setFormValue] = React.useState({
    achieved_heart_rate: "",
    achieved_rate_of_percieved_exertion: "",
    achieved_distance: "",
    achieved_duration: "",
    achieved_calories_burned: "",
    achieved_frequency: "",
    achieved_intensity: "",
    achieved_repetition: "",
    achieved_volume: ""
  });
  

  useEffect(() => {
    const fetchSC = async () => {
      try {
        const response = await HttpService.get(SC_WORKOUT_API_URLS+workType+"/", {}, false)
       
       
        setPrevSc(response.data)
      } catch (e) {
        console.warn(e)
      }

    }
    fetchSC()

  }, [workType])
  useEffect(() => {
    const fetchSC = async () => {
      try {
        const response = await HttpService.get(SC_WORKOUT_API_URLS, {}, false)
       
        const modifiedResults = response.data.results.map((t) => ({
          value: t.id,
          label: t.workout_type,
        }));
        setWorkTypes(modifiedResults)
      } catch (e) {
        console.warn(e)
      }

    }
    fetchSC()

  }, [])


  const handleSubmit = async () => {
    

 
    try {
      const formData = {
        achieved_heart_rate: formValue.achieved_heart_rate || prevSC.achieved_heart_rate,
        achieved_rate_of_percieved_exertion: formValue.achieved_rate_of_percieved_exertion ||prevSC.achieved_rate_of_percieved_exertion,
        achieved_distance: formValue.achieved_distance || prevSC.achieved_distance,
        achieved_duration: formValue.achieved_duration || prevSC.achieved_duration,
        achieved_calories_burned: formValue.achieved_calories_burned || prevSC.achieved_calories_burned,
        achieved_frequency: formValue.achieved_frequency || prevSC.achieved_calories_burned,
        achieved_intensity: formValue.achieved_intensity || prevSC.achieved_calories_burned,
        achieved_repetition: formValue.achieved_repetition || prevSC.achieved_repetition,
        achieved_volume: formValue.achieved_volume || prevSC.achieved_volume ,
       
       
      };
    
      const response = await HttpService.patch(SC_WORKOUT_API_URLS+workType+"/", {}, formData,false);

      setResponse(response);

      if (response.status === 200) {

        setErrorCount(prevCount => prevCount + 1);
        setCount(prevCount => prevCount + 1)
        setOpenModal(true);
        setError("Form Submitted");
        onSubmitSuccess()
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (typeof error.response.data === "object") {
          const errorMessages = Object.values(error.response.data).join("\n");
          setError(errorMessages || "An error occurred");
        } else {
          setError(error.response.data.message || "An error occurred");
        }
      } else {
        setError("An error occurred");
      }
      if (error.response && error.response.status !== 201) {
        setErrorCount(prevCount => prevCount + 1);
        // If there's a response indicating a server error (status other than 201), open the modal
        setOpenModal(true);
      }
    }
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={12}>
        <Form ref={formRef} onChange={setFormValue} formValue={formValue}  >

          <SelectPicker
            value={workType}
            data={workTypes}
            onSelect={(value) => setWorkType(value)}
            style={{ width: 400 }}

          />
            <br />
          <br />
          <TextField name="achieved_frequency" label="Sets" />
          <TextField name="achieved_intensity" label="Intensity" />
          <TextField name="achieved_repetition" label="Rep" />
          <TextField name="achieved_volume" label="Weight in Kg" />
          <TextField name="achieved_heart_rate" label="Target Heart Rate" />
          <TextField name="achieved_rate_of_percieved_exertion" label="RPE" />
          <TextField name="achieved_distance" label="Distance" />
          <TextField name="achieved_duration" label="Duration" />
          <TextField name="achieved_calories_burned" label="Calories Burned" />



          <br />
          <br />


          <Button appearance="primary" onClick={handleSubmit} style={{ background: Color.Color_C, color: Color.Color_A, width: 300 }}>
            Submit
          </Button>

          <CustomNotification
            errorCount={errorCount}
            open={openModal}
            setOpen={setOpenModal}
            message={
              error ? error : (res && res.data && res.data.message) || ""
            }
            title={error === "Form Submitted" ? "Success" : "Error"}
          />
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
