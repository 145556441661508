import React, { useState } from "react";
import styles from "./../../science.module.css";
import EvaluationFormView from "../../../../components/EvaluationFormView";

export default function Health({ mode }) {
  const [open, setOpen] = useState(false);
  // const [placement, setPlacement] = useState();
  const [drawerTitle, setDrawerTitle] = useState();
  const [dept, setDept] = useState();
  const [cat, setCat] = useState();

  
  const data = [
    
    {
      cat: 56,
      name: "Body Composition Analysis",
      dept: 3,
      cardColor: "#c5cae9",
    },
    {
      cat: 58,
      name: "Cardiovascular",
      dept: 3,
      cardColor: "#c5cae9",
    },
    {
      cat: 66,
      name: "Flexibility",
      dept: 3,
      cardColor: "#c5cae9",
    },
    {
      cat: 63,
      name: "Balance",
      dept: 3,
      cardColor: "#c5cae9",
    }
    
  ];

  const handleOpen = ({ name, key, dept, cat }) => {
    setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
    setDept(dept);
    setCat(cat);
  };

  return (
    <>
     
    <div className={styles.sports_main_inner}>
        <select
          className={styles.dropdown}
          onChange={(e) => {
            const selectedValue = e.target.value;
            const selectedItem = data.find(
              (item) => item.name === selectedValue
            );

            if (selectedItem) {
              handleOpen({
                name: selectedItem.name,
                dept: selectedItem.dept,
                cat: selectedItem.cat,
              });
            }
          }}
        >
          <option value="">{mode}</option>
          {data.map((item) => (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      
    </div>
  
      <EvaluationFormView
        open={open}
        setOpen={setOpen}
        size={"lg"}
        placement={"left"}
        mode={drawerTitle}
        dept={dept}
        cat={cat}
      />
    
  </>
  );
}
