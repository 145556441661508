import React, { useState } from "react";
import DashBoardTopNav from "../../components/DashBoardTopNav";
import Sidebar from "../../components/CustomSideNav";

import VideoUpload from "./VideoUpload";
import SelectMode from "./SelectMode";
export default function BiomechAssessment() {
 
  const [selectedFile, setSelectedFile] = useState(null);


  return (
    <div>
      <DashBoardTopNav header={"Biomechanics Assessment"} />
      <Sidebar />

      {selectedFile === null ?
        <VideoUpload selectedFile={selectedFile} setSelectedFile={setSelectedFile} /> :
        <><SelectMode file={selectedFile} /></>
        }
    </div>
  );
}
