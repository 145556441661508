import React, { useEffect, useState } from "react";
// import { Button } from "rsuite";
import { HttpService } from "../../services/http.service";
import {
  EVALUATION_RESULTS,
  ATHLETE_PROFILE_API_URL,
} from "../../services/API";
import { Typography } from "@mui/material";
// import { Container } from "@mui/material";
// import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import Label from "../../components/Label";

export default function FMSInsights() {
  const [groupedResults, setGroupedResults] = useState([]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HttpService.get(
          `${EVALUATION_RESULTS}?department=1&categories=41`,{}, false
        );
        const data = response.data.results;
  
        const processedData = await Promise.all(
          data.map(async (result, index) => {
            try {
              const athleteResponse = await HttpService.get(
                ATHLETE_PROFILE_API_URL + result.athlete + "/",{}, false
              );
  
              const athleteName = athleteResponse.data.name;
  
              // Check if athlete details are available and not "N/A"
              if (athleteName !== "N/A") {
                const values = {
                  [result.field_name]: [
                    result.boolean_value,
                    result.choice_value,
                    result.date_value,
                    result.float_value,
                    result.integer_value,
                    result.remarks,
                    result.string_value,
                  ].filter(Boolean),
                };
  
                return {
                  id: index,
                  athlete_id: athleteResponse.data.id,
                  athlete_name: athleteName,
                  date: result.created_at.slice(0, 10),
                  ...values,
                };
              }
  
              return null; // Exclude the result when athlete is "N/A"
            } catch (athleteError) {
              console.warn("Error fetching athlete details:", athleteError.message);
              // If athlete details cannot be fetched, return null to exclude the result
              return null;
            }
          })
        );
  
        // Filter out null results caused by failed athlete fetch or when athlete is "N/A"
        const filteredResults = processedData.filter(result => result !== null);
  
        setGroupedResults(filteredResults);
      } catch (error) {
        console.warn(error);
      }
    };
  
    fetchData();
  }, []);
  
  const columns = [
    {
      field: "athlete_name",
      headerName: "Name",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Total Score",
      headerName: "Total Score",
      sortable: true,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let valueH = parseFloat(params.row["Deep Squat"]);
        let valueD = parseFloat(params.row["Hurdle Step"]);
        let valueI = parseFloat(params.row["In-line Lunge"]);
        let valueA = parseFloat(params.row["Active Straight-leg Raise"]);
        let valueT = parseFloat(params.row["Trunk Stability Push-up"]);
        let valueR = parseFloat(params.row["Rotary Stability"]);
        let valueS = parseFloat(params.row["Shoulder Mobility"]);

        let score =
          valueH + valueD + valueI + valueA + valueT + valueR + valueS;

        let color = "primary";

        if (score === 21) {
          color = "success";
        } else if (score >= 14 && score < 21) {
          color = "warning";
        } else if (score > 0 && score < 14) {
          color = "error";
        }

        return (
          <div className={`cellWithStatus ${score}`}>
            <Label color={color}>{score}</Label>
          </div>
        );
      },
    },
    {
      field: "Outcome",
      headerName: "Outcome",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let valueH = parseFloat(params.row["Deep Squat"]);
        let valueD = parseFloat(params.row["Hurdle Step"]);
        let valueI = parseFloat(params.row["In-line Lunge"]);
        let valueA = parseFloat(params.row["Active Straight-leg Raise"]);
        let valueT = parseFloat(params.row["Trunk Stability Push-up"]);
        let valueR = parseFloat(params.row["Rotary Stability"]);
        let valueS = parseFloat(params.row["Shoulder Mobility"]);

        let score =
          valueH + valueD + valueI + valueA + valueT + valueR + valueS;

        let insight = "";
        let color = "primary";

        if (score === 21) {
          color = "success";
          insight = "No Risk of Injury";
        } else if (score >= 14 && score < 21) {
          color = "warning";
          insight = "Reduced Risk of Injury with Physical Activity";
        } else if (score > 0 && score < 14) {
          color = "error";
          insight = "Increased Risk of Injury with Physical Activity";
        }

        return (
          <div className={`cellWithStatus ${insight}`}>
            <Label color={color}>{insight}</Label>
          </div>
        );
      },
    },

    {
      field: "Deep Squat",
      headerName: "Deep Squat",
      sortable: true,
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let color = "error";
        const vValue = parseFloat(params.row["Deep Squat"]);

        if (!isNaN(vValue)) {
          if (vValue === 3) {
            color = "success";
          } else if (vValue >= 1 && vValue <= 2) {
            color = "warning";
          }
        }

        return (
          <div className={`cellWithStatus ${params.row["Deep Squat"]}`}>
            <Label color={color}>
              {Array.isArray(params.row["Deep Squat"])
                ? params.row["Deep Squat"].join(", ")
                : params.row["Deep Squat"]}
            </Label>
          </div>
        );
      },
    },

    {
      field: "Hurdle Step",
      headerName: "Hurdle Step",
      sortable: true,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let color = "error";
        const vValue = parseFloat(params.row["Hurdle Step"]);

        if (!isNaN(vValue)) {
          if (vValue === 3) {
            color = "success";
          } else if (vValue >= 1 && vValue <= 2) {
            color = "warning";
          }
        }

        return (
          <div className={`cellWithStatus ${params.row["Hurdle Step"]}`}>
            <Label color={color}>
              {Array.isArray(params.row["Hurdle Step"])
                ? params.row["Hurdle Step"].join(", ")
                : params.row["Hurdle Step"]}
            </Label>
          </div>
        );
      },
    },
    {
      field: "In-line Lunge",
      headerName: "In-line Lunge",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let color = "error";
        const vValue = parseFloat(params.row["In-line Lunge"]);

        if (!isNaN(vValue)) {
          if (vValue === 3) {
            color = "success";
          } else if (vValue >= 1 && vValue <= 2) {
            color = "warning";
          }
        }

        return (
          <div className={`cellWithStatus ${params.row["In-line Lunge"]}`}>
            <Label color={color}>
              {Array.isArray(params.row["In-line Lunge"])
                ? params.row["In-line Lunge"].join(", ")
                : params.row["In-line Lunge"]}
            </Label>
          </div>
        );
      },
    },
    {
      field: "Active Straight-leg Raise",
      headerName: "Active SLR",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let color = "error";
        const vValue = parseFloat(params.row["Active Straight-leg Raise"]);

        if (!isNaN(vValue)) {
          if (vValue === 3) {
            color = "success";
          } else if (vValue >= 1 && vValue <= 2) {
            color = "warning";
          }
        }

        return (
          <div
            className={`cellWithStatus ${params.row["Active Straight-leg Raise"]}`}
          >
            <Label color={color}>
              {Array.isArray(params.row["Active Straight-leg Raise"])
                ? params.row["Active Straight-leg Raise"].join(", ")
                : params.row["Active Straight-leg Raise"]}
            </Label>
          </div>
        );
      },
    },
    {
      field: "Trunk Stability Push-up",
      headerName: "Trunk Stability Push-up",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let color = "error";
        const vValue = parseFloat(params.row["Trunk Stability Push-up"]);

        if (!isNaN(vValue)) {
          if (vValue === 3) {
            color = "success";
          } else if (vValue >= 1 && vValue <= 2) {
            color = "warning";
          }
        }

        return (
          <div
            className={`cellWithStatus ${params.row["Trunk Stability Push-up"]}`}
          >
            <Label color={color}>
              {Array.isArray(params.row["Trunk Stability Push-up"])
                ? params.row["Trunk Stability Push-up"].join(", ")
                : params.row["Trunk Stability Push-up"]}
            </Label>
          </div>
        );
      },
    },
    {
      field: "Rotary Stability",
      headerName: "Rotary Stability",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let color = "error";
        const vValue = parseFloat(params.row["Rotary Stability"]);

        if (!isNaN(vValue)) {
          if (vValue === 3) {
            color = "success";
          } else if (vValue >= 1 && vValue <= 2) {
            color = "warning";
          }
        }

        return (
          <div className={`cellWithStatus ${params.row["Rotary Stability"]}`}>
            <Label color={color}>
              {Array.isArray(params.row["Rotary Stability"])
                ? params.row["Rotary Stability"].join(", ")
                : params.row["Rotary Stability"]}
            </Label>
          </div>
        );
      },
    },
    {
      field: "Shoulder Mobility",
      headerName: "Shoulder Mobility",
      sortable: true,
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let color = "error";
        const vValue = parseFloat(params.row["Shoulder Mobility"]);

        if (!isNaN(vValue)) {
          if (vValue === 3) {
            color = "success";
          } else if (vValue >= 1 && vValue <= 2) {
            color = "warning";
          }
        }

        return (
          <div className={`cellWithStatus ${params.row["Shoulder Mobility"]}`}>
            <Label color={color}>
              {Array.isArray(params.row["Shoulder Mobility"])
                ? params.row["Shoulder Mobility"].join(", ")
                : params.row["Shoulder Mobility"]}
            </Label>
          </div>
        );
      },
    },
  ];
  const groupedData = {};

  groupedResults.forEach((item) => {
    const { athlete_id, athlete_name, date, ...fields } = item;
    if (!groupedData[date]) {
      groupedData[date] = {
        athlete_id,
        athlete_name,
        date,
        ...fields,
      };
    } else {
      Object.keys(fields).forEach((field) => {
        if (!groupedData[date][field]) {
          groupedData[date][field] = [];
        }
        if (Array.isArray(groupedData[date][field])) {
          groupedData[date][field].push(
            ...(Array.isArray(fields[field]) ? fields[field] : [fields[field]])
          );
        } else {
          groupedData[date][field] = [groupedData[date][field]];
          groupedData[date][field].push(
            ...(Array.isArray(fields[field]) ? fields[field] : [fields[field]])
          );
        }
      });
    }
  });

  const finalGroupedResults = Object.values(groupedData);

  return (
    <div   style={{marginLeft:"30px",marginTop:"30px"}} >
      
      {groupedResults?.length === 0 ? (
        <Typography variant="h6" textAlign={"center"}>
          No Data
        </Typography>
      ) : (
       
          <div>
           
              <DataGrid
                rows={finalGroupedResults}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 50]}
                disableSelectionOnClick
                autoHeight
                getRowId={(row) => `${row.athlete_id}-${row.date}-${row.index}`}
                slots={{toolbar:CustomToolbar}}
              />
           
            <Typography>
              The most studies suggest is that a score of 14 or lower gives a
              person a 1.5 times higher risk for injury than a person who gets a
              score higher than 14. This does not mean that if you score lower
              than a 14, you should be frightened; again, most studies done are
              with a specific population.
            </Typography>
         
        </div>
      )}
    </div>
  );
}
