import React, { useState } from "react";
import styles from "./science.module.css";
import PlaceHolder from "./PlaceHolder";
import EvaluationFormView from "../../components/EvaluationFormView";
export default function SportsPsychology({label}) {
  const [open, setOpen] = useState(false);
  // const [placement, setPlacement] = useState();
  const [drawerTitle, setDrawerTitle] = useState();
  const [dept,setDept] = useState()
  const [cat,setCat] = useState()
  
  const data = [
    {
      cat: 77,
      name: "Motivational Quality",
      dept: 7,
      cardColor: "#c5cae9",
    },
    {
      cat: 78,
      name: "Anxiety",
      dept: 7,
      cardColor: "#c5cae9",
    },
    {
      cat: 79,
      name: "Psychological Performance Inventory",
      dept: 7,
      cardColor: "#c5cae9",
    },
    
  ];

  const handleOpen = ({ name, key, dept, cat }) => {
    setOpen(true);
    // setPlacement(key);
    setDrawerTitle(name);
    setDept(dept);
    setCat(cat)
  };

  return (
    <div className={styles.sport_main} >
    
      <div className={styles.sports_main_inner}>
        <select
          className={styles.dropdown}
          onChange={(e) => {
            const selectedValue = e.target.value;
            const selectedItem = data.find(
              (item) => item.name === selectedValue
            );

            if (selectedItem) {
              handleOpen({
                name: selectedItem.name,
                dept: selectedItem.dept,
                cat: selectedItem.cat,
              });
            }
          }}
        >
          <option value="">{label}</option>
          {data.map((item) => (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
     
    </div>

     {drawerTitle===undefined?<PlaceHolder/> : <EvaluationFormView
          open={open}
          setOpen={setOpen}
          size={"lg"}
          placement={"left"}
          mode={drawerTitle}
          dept={dept}
          cat={cat}
        />
     }
       
    </div>
);
}
